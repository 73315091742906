<div class="flex-nowrap flex gap-2 align-items-center mt-3" *ngIf="questionPageHiddenLog > 0">
    <img src="assets/vinter/icons/cheating-icon.svg">
    <p class="font-medium page-hidden">{{"Key.SwitchTab" | translate: {value: questionPageHiddenLog } }}</p>
</div>
    
<div class="flex">
    <img src="assets/vinter/icons/text-questioncoktan-secmeli-cevap-icon-arrow-back-up.svg">
    <h5 class="text-justify answer-title font-semibold text-lg ml-2">
        {{"Key.Answer" | translate}}
    </h5>
</div>
<div class="answer-container ml-5 border-1 border-solid border-round-md">
    <div class="flex">
        <div class="flex flex-column gap-2">
            <div *ngFor="let choice of question?.choiceContents; let i = index"
                class="radio-button-card field-checkbox p-3 mb-0 border-round-md"
                [ngClass]="{'correct-answer': isCorrectAnswer(choice.id),
                'wrong-answer': isWrongAnswer(choice.id),
                'correct-choice': choice.isCorrect && selectedRadioValue !== null,
                'empty-correct-choice': choice.isCorrect && selectedRadioValue === null,
                'background-white': choice.id !== answer?.choiceAnswer?.choiceContentId,
                'background-blue': choice.id === answer?.choiceAnswer?.choiceContentId}">
                <div class="flex">
                    <p-radioButton [disabled]="true" [value]="choice"
                        [(ngModel)]="selectedAnswerChoice"></p-radioButton>
                    <label [for]="choice" class="ml-2" [innerHTML]="choice.choiceText"></label>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="ml-5">
    <div class="flex" *ngIf="answer?.choiceAnswer && checkAnswer(answer?.choiceAnswer?.choiceContentId) === true">
        <img src="assets/vinter/icons/correct-answer.svg">
        <p class="ml-2 correct-answer-text">{{"Key.CorrectAnswer" | translate}}</p>
    </div>
    <div class="flex" *ngIf="answer?.choiceAnswer && (answer?.choiceAnswer?.choiceContentId ?? false) && checkAnswer(answer?.choiceAnswer?.choiceContentId) === false">
        <img src="assets/vinter/icons/wrong-answer.svg">
        <p class="ml-2 wrong-answer-text">{{"Key.WrongAnswer" | translate}}</p>
    </div>
    <div class="flex" *ngIf="answer?.choiceAnswer == null || answer?.choiceAnswer == undefined">
        <img src="assets/vinter/icons/not-answered.svg">
        <p class="ml-2 empty-answer-text">{{"Key.QuestionNotAnswered" | translate}}</p>
    </div>
</div>