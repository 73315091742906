import { ConditionOperatorEnum, ECVAnalyzeAutomationRuleWhenSourceType, EUserConsentType } from "./enums";

export const credentialKey = 'vinter.credentials';
export const cryptedCredentialKey = 'vinter.cryptedCredentials';
export const sideNavMenuKey = "vinter.sideNavMenu";
export const cachePersistenceKey = 'vinter.httpCache';
export const languageKey = 'vinter.selectedLanguage';
export const mailKey = 'vinter.mail';
export const rememberKey = 'vinter.remember';
export const pinnedTabIndex = 'vinter.pinnedTabIndex';
export const defaultGuid: string = "00000000-0000-0000-0000-000000000000";
export const mainOrganizationGuid: string = "ea1bf6a1-af29-46d2-aff7-f120bdb4f553";
export const vinterOrganizationGuid: string = "ea1bf6a1-af29-46d2-aff7-f120bdb4f553";
export const vinterLogoPath: string = "assets/vinter/images/NORM_HOLDING_VINTER_LOGO-01.png";
// export const vinterLogoWithoutTextPath: string = "assets/vinter/images/vinter_logo_withoutText.png";
export const vinterPureLogoPath: string = "assets/vinter/images/NORM_HOLDING_VINTER_LOGO-02.png";
export const vinterPureLogoRotatedPath: string = "assets/vinter/images/rotated_logo_vinter.png";
export const flagTRPath: string = "assets/vinter/images/tr.png";
export const flagTRSvgPath: string = "assets/vinter/images/tr_flag.svg";
export const flagENPath: string = "assets/vinter/images/en.jpg";
export const trShortText: string = "tr-TR";
export const enShortText: string = "en-US";

export const invitationTemplateFilePath: string = "assets/vinter/files/VinterMailListTemplate.xls";
export const VINTERADMIN: string = "VINTERADMIN";
export const ORGADMIN: string = "ORGADMIN";
export const HR: string = "HR";
export const APPLICANT: string = "APPLICANT";
export const passwordMinCharcount: number = 8;
export const paginatorRowCount: number = 8;
export const maxImageSize: number = 20000000;

export const ExistingEmail: string = "ExistingEmail";
export const ExistingManager: string = "ExistingManager";
export const LicenseUsageError: string = "LicenseUsageError";
export const UserFilledFormAlready: string = "UserFilledFormAlready";

export const AttributeList: { name: string; code: string }[] = [
    { name: 'PositionTitle', code: '{positionTitle}' },
    { name: 'StepName', code: '{stepName}' },
    { name: 'ApplicantName', code: '{applicantName}' },
    { name: 'OrganizationTitle', code: '{organizationTitle}' }
  ];

export const UserConsentTypeStrings = {
    [EUserConsentType.TermsOfUse]: "Key.TermsOfUse",
    [EUserConsentType.PrivacyPolicy]: "Key.PrivacyPolicy",
    [EUserConsentType.CookiePolicy]: "Key.CookiePolicy",
    [EUserConsentType.HrDisclosure]: "Key.HrDisclosure",
    [EUserConsentType.TEIOM]: "Key.TEIOM",
    [EUserConsentType.ApplicantDisclosure]: "Key.ApplicantDisclosure"
};



export const CVAnalyzeRuleConditionList = {
    [ECVAnalyzeAutomationRuleWhenSourceType.Degree]: {name: 'Degree', operatorList:[ConditionOperatorEnum.Equals, ConditionOperatorEnum.LessThan, ConditionOperatorEnum.GreaterThan]},
    [ECVAnalyzeAutomationRuleWhenSourceType.WorkExperience]:{ name: 'WorkExperience', operatorList:[ConditionOperatorEnum.Equals, ConditionOperatorEnum.LessThan, ConditionOperatorEnum.GreaterThan]},
    [ECVAnalyzeAutomationRuleWhenSourceType.GPAMean]:{ name: 'GPAMean', operatorList:[ConditionOperatorEnum.Equals, ConditionOperatorEnum.LessThan, ConditionOperatorEnum.GreaterThan]},

}
export const ThreeHoursInMS = 3 * 60 * 60 * 1000;
export const HowToCalculateScoreAndRating = "HowToCalculateScoreAndRating";
export const ExplanationOfCalculatingScoreAndRating = "ExplanationOfCalculatingScoreAndRating";
export const QuesitonWeightIcon = "assets/vinter/icons/weight-icon.svg";
export const QuestionWeightToolTipIcon = "assets/vinter/icons/tooltip-info-icon.svg";
export const SpeakingIcon = "assets/vinter/icons/speaking-icon-circled.svg";
export const WritingIconPath = "assets/vinter/icons/writing-icon-circled.svg";
export const ListeningIconPath = "assets/vinter/icons/listening-icon-circled.svg";
export const ReadingIconPath = "assets/vinter/icons/reading-icon-circled.svg";
export const VocabularyIconPath = "assets/vinter/icons/vocabulary-icon-circled.svg";
export const GrammerIconPath = "assets/vinter/icons/grammer-icon-circled.svg";
export const AICommentsIconPath = "assets/vinter/icons/text-grammar-error-regular.svg"
export const unifiedWorkspaceID: string = "66e93d1eb2e44db1f5edbd09";
export const RefreshIcon = "assets/vinter/icons/green-refresh-icon.svg"
export const HyundaiGUID  = "809e3ecc-13cd-4c60-a194-17ec05b59653";
export const FetchApplicantsIcon = "assets/vinter/icons/fetch-applicants.svg";
export const positionStepEmptyIcon: string = "assets/vinter/icons/positionStepEmpty.svg";
export const positionStepListIcon: string = "assets/vinter/icons/pozisyon-detay-icon-park-outline-list-success.svg";
export const positionStepTextIcon: string = "assets/vinter/icons/pozisyon-detay-icon-park-text-style-one.svg";
export const positionStepVideoIcon: string = "assets/vinter/icons/pozisyon-detay-tabler-video.svg";
export const positionStepCodingQuestionIcon : string = "assets/vinter/icons/pozisyon-detay-tabler-coding-question.svg";
export const flagENSvgPath: string = "assets/vinter/images/en_flag.svg";
export const vinterLogoWithoutTextPath: string = "assets/vinter/images/vinter_logo.svg";
export const timeIconOutline : string = "assets/vinter/icons/time-icon-outline.svg";
export const eyeIconOutline: string = "assets/vinter/icons/eye-icon.svg";
export const VinterAICommentIcon = "assets/vinter/icons/ai-comment-icon.svg";
