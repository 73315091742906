import { Component, Input } from '@angular/core';
import { LocalizationService } from '@app/core';
import { EntityStateEnum, OfferRejectedReasons } from '@app/shared/common/enums';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { ApplicationOfferItem } from '@app/vinter/models/application-offer-item';
import { ApplicationOfferService } from '@app/vinter/services/application-offer.service';

@Component({
  selector: 'app-applicant-step-offer',
  templateUrl: './applicant-step-offer.component.html',
  styleUrls: ['./applicant-step-offer.component.scss']
})
export class ApplicantStepOfferComponent {
    selectedAnswer?: boolean;
    selectedReason: number;
    reasonNote: string;
    rejectCategories: any[];
    applicationOffer: ApplicationOfferItem = new ApplicationOfferItem();
    xmin: string;
    id: string;

    @Input() applicationId: string;

    constructor(
        private localizationService: LocalizationService,
        private applicationOfferService: ApplicationOfferService,
        private appInfoService: AppInfoService,
        ) { }

    ngOnInit(){
        this.rejectCategories = this.appInfoService.getOfferRejectTypes();

        this.applicationOfferService.GetDataByFilter({ApplicationID: this.applicationId}).subscribe(data => {
            if(data != null){
                this.selectedAnswer = data.offerAccepted;
                this.selectedReason = data.rejectionReason;
                this.reasonNote = data.note;
                this.xmin = data.xmin;
                this.id = data.id;
            }
        })
    }

    getTranslate(value: string) {
        return this.localizationService.translatedTextList[value];
    }

    radioButtonCheck(){
        if(this.selectedAnswer){
            this.reasonNote = null;
            this.selectedReason = null;
        }
    }

    save(){
        this.applicationOffer.note = this.reasonNote;
        this.applicationOffer.offerAccepted = this.selectedAnswer;
        this.applicationOffer.rejectionReason = this.selectedReason;
        if(this.xmin != null){
            this.applicationOffer.entityState = EntityStateEnum.Modified;
            this.applicationOffer.xmin = this.xmin;
            this.applicationOffer.id = this.id;
        }
        else
            this.applicationOffer.entityState = EntityStateEnum.Added;

        this.applicationOffer.applicationID = this.applicationId;
        this.applicationOfferService.SaveAsync(this.applicationOffer).subscribe(response => {
            if(response)
                window.location.reload();
        });
    }
}
