
export class StepPerTestResultListItem {
    id: string;
    o: number;
    c: number;
    e: number;
    a: number;
    n: number;
    reportTextO: string;
    reportTextC: string;
    reportTextE: string;
    reportTextA: string;
    reportTextN: string;
    achievementStriving?: number;
    activityLevel?: number;
    adventurousness?: number;
    altruism?: number;
    anger?: number;
    anxiety?: number;
    artisticInterests?: number;
    assertiveness?: number;
    cautiousness?: number;
    cheerfulness?: number;
    cooperation?: number;
    depression?: number;
    dutifulness?: number;
    emotionality?: number;
    excitementSeeking?: number;
    friendliness?: number;
    gregariousness?: number;
    imagination?: number;
    immoderation?: number;
    intellect?: number;
    liberalism?: number;
    modesty?: number;
    morality?: number;
    orderliness?: number;
    selfConsciousness?: number;
    selfDiscipline?: number;
    selfEfficacy?: number;
    sympathy?: number;
    trust?: number;
    vulnerability?: number;
}

