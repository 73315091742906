import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { LocalizationService } from '@app/core';
import { ApplicationStatus, ApplicationStepStatus, EQuestionCollectionSubCategory, PositionStepKanbanStaticTypes, PositionStepType, QuestionCollectionCategoryEnum } from '@app/shared/common/enums';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { AnswerContentListItem } from '@app/vinter/models/answer-content-list-item';
import { AnswerWithContentAndChoiceListItem } from '@app/vinter/models/answer-list-item';
import { ChoiceAnswerListItem } from '@app/vinter/models/choice-answer-list-item';
import { ApplicationStepService } from '@app/vinter/services/application-step.service';
import { ApplicationService } from '@app/vinter/services/application.service';
import { GeneralApplicationQuestion } from '@app/vinter/services/generalApplicationQuestion.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-hiring-process-tab',
  templateUrl: './hiring-process-tab.component.html',
  styleUrls: ['./hiring-process-tab.component.scss']
})
export class HiringProcessTabComponent implements OnInit {

  //Input & Outputs
  @Input() applicationId: string;

  @Output() changeActiveStepInfo: EventEmitter<any> = new EventEmitter<any>();
  
  activeIndex = 0;
  applicationSteps: any[] = [];
  currentStepId: string;
  positionStepTypes: any[] = [];
  positionStepTypeEnum: any[] = [];
  applicationStepId: string;
  popupOpen: boolean = false;
  isLoading: boolean = true;
  applicant: any;
  openOceanSidebar: boolean = false;

  //Answer Types
  answerID: string;
  answer: AnswerWithContentAndChoiceListItem;
  choiceAnswers: ChoiceAnswerListItem;
  answerContent: AnswerContentListItem;
  answerText: string;
  answerType: number;
  answerId: string;

  //General Application
  generalApplicationFormId : any;
  generalApplicationTabVisible: boolean = false;
  generalApplicationFormQuestions: any = [];

  //Enums
  positionStepKanbanStaticTypes = PositionStepKanbanStaticTypes;
  applicationStepStatus = ApplicationStepStatus;
  questionCollectionCategoryEnum = QuestionCollectionCategoryEnum;
  questionCollectionSubCategory = EQuestionCollectionSubCategory;
  applicationStatusEnum = ApplicationStatus;
  constructor(
    private generalApplicationQuestionService: GeneralApplicationQuestion,
    private applicationStepService: ApplicationStepService,
    private applicationService: ApplicationService,
    private localizationService: LocalizationService,
    private appInfoService: AppInfoService,
    private elem: ElementRef
  ) {
  }

  ngOnInit(): void {
    this.getApplicationProfileData();
   var test = this.applicant;
    this.applicationService.GetCurrentApplicationStepId(this.applicationId);
    this.positionStepTypeEnum = this.appInfoService.getPositionStepTypeArray();
    this.getApplicationSteps(0);
  }

  async getApplicationProfileData(){
    this.applicant = await firstValueFrom(this.applicationService.GetApplicationUserInfoData({id: this.applicationId}));
    this.applicationStepId = this.applicant.currentStepId;
  }

  getApplicationSteps(activeIndex) {
    if (this.applicationId ?? false) {
      this.activeIndex = activeIndex;
      this.applicationStepService.GetApplicationStepsForApplicantDetail({ applicationId: this.applicationId }).subscribe(result => {
        this.applicationSteps = result;    
        this.applicationSteps.sort((a, b) => a.order - b.order);
        const ApplicationStatus = result[0].applicationStatus;
        if (ApplicationStatus == this.applicationStatusEnum.HROffer) {
          const newApplicationStep = {
            order: this.positionStepKanbanStaticTypes.Offer,
            positionStepTitle: this.getTranslate("Offer"),
            positionStep: {
              id: this.positionStepKanbanStaticTypes.Offer,
              order: this.positionStepKanbanStaticTypes.Offer,
            }
          };
          this.applicationSteps.push(newApplicationStep);
        }
        let index = this.applicationSteps.findIndex(element => element.id === this.applicationStepId);
  
        if (ApplicationStatus == this.applicationStatusEnum.HROffer) {
          index = this.applicationSteps.findIndex(element => element.order.toString() === this.positionStepKanbanStaticTypes.Offer);
        }
  
        if (index != -1) {
          this.activeIndex = index;
        }

        this.generalApplicationFormId = this.applicationSteps.find(x => x.generalApplicationFormId !== null)?.generalApplicationFormId;
  
        this.generalApplicationQuestionService.getListByPositionID(this.applicationSteps.find(x => x.positionId !== null)?.positionId).subscribe(data => {
          this.generalApplicationFormQuestions = data;
        });

        this.isLoading = false;
      });
    }
  }
 
  getTranslate(value: string) {
    return this.localizationService.translatedTextList[value];
  }

  resetAnswerPage() {
    this.answerType = null;
    this.answerContent = null;
    this.answerID = null;
    this.answerId = null;
    this.answerText = null;
    this.answer = null;
    this.choiceAnswers = null;
  }

  checkRedoStepVisible(item){
    if(item.status === this.applicationStepStatus.InProgress && item.isTimesUp){
      return true;
    }
    else if(item.status === this.applicationStepStatus.ApplicantCompleted || item.status === this.applicationStepStatus.HRCompleted){
      return true;
    }
    else{
      return false;
    }
  }

  showRedoPopup() {
    this.popupOpen = true;
  }

  closeRedoPopup() {
    this.popupOpen = false
  }

  ngAfterViewInit() {
    this.updateNavigationVisibility();
  }

  private updateNavigationVisibility() {
    this.checkNextIconVisibility();
    this.checkPrevIconVisibility();
  }
  confirmRedo() {
    this.applicationStepService.redoStep({ id: this.applicationSteps[this.activeIndex].id }).subscribe(x => {
      this.closeRedoPopup();
      setTimeout(() => {
        window.location.reload();
      }, 500);
    });
  }

  //open ocean detail sidebar
  viewAnalysisSidebar(){
    this.openOceanSidebar = !this.openOceanSidebar;
  }


  // Small Screen Scrollable Tab List Functions
  checkNextIconVisibility(): string {
    const tabNav = document.getElementsByClassName("p-tabview-nav")[0] as HTMLElement;
    const nextButton = document.getElementsByClassName("p-tabview-nav-next")[0] as HTMLElement;
  
    if (!tabNav || !nextButton) {
      return 'hidden';
    }
      const listItems = tabNav.getElementsByTagName('li');
    let totalWidth = 0;
      for (let i = 0; i < listItems.length; i++) {
      totalWidth += listItems[i].offsetWidth;
    }
    // const containerWidth = tabNav.offsetWidth;
    const toleransValue = 397;
    const screenWidth = window.innerWidth - toleransValue;
    if (totalWidth > screenWidth) {
      nextButton.style.display = 'block'; 
      return 'flex';  
    } else {
      nextButton.style.display = 'none';
      return 'hidden'; 
    }
  }
  
  

checkPrevIconVisibility(): string {
  var result = document.getElementsByClassName("p-tabview-nav-content");
  var resultBtn = document.getElementsByClassName("p-tabview-nav-prev");

  if (!(((result.item(0) as HTMLElement) ?? false) && ((resultBtn.item(0) as HTMLElement) ?? false))) {
    return 'hidden';
  }

  let tabToDisplay = (result.item(0) as HTMLElement).getBoundingClientRect();
  let button = (resultBtn.item(0) as HTMLElement).getBoundingClientRect();
  let elements = this.elem.nativeElement.querySelector('.p-tabview-nav-prev');

  if (tabToDisplay.left >= button.left) {
    if (elements.classList.contains('bg-transparent')) {
      elements.classList.remove('bg-transparent');
    }
    if (elements.classList.contains('hidden')) {
      elements.classList.remove('hidden');
    }
    return '';
  }

  if (!elements.classList.contains('bg-transparent')) {
    elements.classList.add('bg-transparent');
  }
  if (!elements.classList.contains('hidden')) {
    elements.classList.add('hidden');
  }
  return 'hidden'; 
}


  @HostListener('window:resize', ['$event'])
  onResize() {
    setTimeout(() => {
      this.updateNavigationVisibility();
    }, 100);
  }

  changeActiveIndex(event: number) {
    this.activeIndex = event;
    setTimeout(() => {
      this.updateNavigationVisibility();
    }, 100);
  }
}
