import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService, HttpBaseService, LocalizationService, NotifyService } from '@app/core';
import { ApiControllerEnum } from '@app/shared/common/enums';
import { IntegrationPositionListItem } from '../models/integration/integration-position-list-item';
import { Observable } from 'rxjs';
import { OrganizationIntegrationItem } from '../models/integration/organization-integration-item';

@Injectable({
  providedIn: 'root'
})
export class IntegrationPositionService extends HttpBaseService  {

  constructor(private http: HttpClient
    , private notifyServ: NotifyService
    , private localizationService: LocalizationService
    , private configService: ConfigurationService) {
      super(http, ApiControllerEnum.IntegrationPosition, notifyServ, localizationService, configService);
    }

    getIntegrationJobsAsync(integrationID: string,connectionID: string,organizationIntegrationID:string): Observable<IntegrationPositionListItem[]>{
      let params = new HttpParams();
      params = params.append('integrationID', integrationID);
      params = params.append('connectionID', connectionID);
      params = params.append('organizationIntegrationID', organizationIntegrationID);
      return this.getWithParams("GetIntegrationJobsAsync", params);
    }

    getPositionIntegrationList(filter: any): Observable<OrganizationIntegrationItem[]>{
      return this.post("GetPositionIntegrationList",filter,true);
    }


}