<div class="col-12 grid p-0 height-fit">
    <div class="xl:col-3 lg:col-4 md:col-6 pl-0 pt-1 flex align-items-center">
        <div *ngIf="!isGeneralEnglishTest" class="flex">
            <div class="applicant-label pl-0">
                <h5 class="text-justify score-text ml-2 m-3">
                    {{item.averageVinterScore | number : '1.2-2'}}
                </h5>
            </div>
        </div>
        <div *ngIf="isGeneralEnglishTest" class="flex">
            <div class="applicant-label pl-0" style="display: flex;align-items: center;">
                <h3 *ngIf="item.generalEnglishTestResult?.generalLevel ?? false" class="text-justify score-text ml-2 m-3" style="    margin-right: 0 !important;">
                    {{item.generalEnglishTestResult?.generalLevel}}
                    <span>-</span>
                </h3>
                <h5 class="text-justify score-text ml-2 m-3">
                    {{item.averageVinterScore | number : '1.2-2'}}
                </h5>
            </div>
        </div>

        <div class="vertical-line-score ml-0"></div>
        <div class="col-4 flex">
            <div class="grid">
                <div class="applicant-label ml-1 flex flex-nowrap">
                    <h5 class="text-justify contact-typo mr-2">
                        {{'Key.VinterScore' | translate}}
                    </h5>
                    <span [pTooltip]="getWeightAvarageScoreTooltip()" tooltipPosition="bottom" tooltipStyleClass="tooltip-score" [escape]="false">
                        <img alt="" [src]="questionWeightToolTipIconPath" width="15" >
                    </span>
                </div>

                <div class="col-12 pb-0 pt-3">
                    <div class="progress w-12rem">
                        <div class="progress-bar" [style.width]="item?.averageVinterScore + '%'"
                            [style.backgroundColor]="getProgressBarColor(item?.averageVinterScore)"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="xl:col-3 lg:col-3 md:col-6  pt-1 flex align-items-center custom-score">
        <div class="flex">
            <div class="applicant-label">
                <h5 class="text-justify score-text ml-2 m-3">
                    {{item.averageRating | number : '1.2-2'}}
                </h5>
            </div>
        </div>
        <div class="vertical-line-score ml-0"></div>
        <div class="col-3 flex">
            <div class="grid">
                <div class="applicant-label ml-1 flex flex-nowrap">
                    <h5 class="text-justify contact-typo mr-2">
                        {{'Key.Score' | translate}}
                    </h5>
                    <span [pTooltip]="getWeightAvarageScoreTooltip()" tooltipPosition="bottom" tooltipStyleClass="tooltip-score" [escape]="false">
                        <img alt="" [src]="questionWeightToolTipIconPath" width="15" >
                    </span>
                </div>

                <div *ngIf="item.averageRating ?? false" class="col-12 pb-0 pt-2">
                    <app-vinter-rating [starNumber]="5" [disabled]="true" size="md" [value]="item.averageRating"></app-vinter-rating>
                </div>
            </div>
        </div>
    </div>

    <div class="xl:col-6 lg:col-5 md:col-12 pt-1 pr-0 mr-0 flex custom-checkbox align-items-center mb-5">
        <div class="custom-checkbox-pr flex">
            <div class="mr-2 custom-check-margin">
                <p-checkbox [(ngModel)]="checkedOpenEnded" [binary]="true" (onChange)="filterList()" inputId="binary"></p-checkbox>
            </div>
            <span class="custom-chip-margin">
                <app-custom-chip [label]="item.positionStep.questionCollection.expectedAnswerOpenEndedCount"
                            [svgUrl]="'assets/vinter/icons/icon-park_text-style-open-ended.svg'" [alt]="'OpenEnded'"
                            [bgColorClass]="'light-blue'" [imgSize]="'16px'"></app-custom-chip>
            </span>

        </div>
        <div class="custom-checkbox-pr flex">
            <div class="mr-2 custom-check-margin">
                <p-checkbox [(ngModel)]="checkedMultipleChoice" [binary]="true" (onChange)="filterList()" inputId="binary"></p-checkbox>
            </div>
            <span class="custom-chip-margin">
                <app-custom-chip [label]="item.positionStep.questionCollection.expectedAnswerMultipleChoiceCount"
                            [svgUrl]="'assets/vinter/icons/icon-park-outline_choice.svg'" [alt]="'MultipleChoice'"
                            [bgColorClass]="'purple'" [imgSize]="'16px'"></app-custom-chip>
            </span>
        </div>
        <div class="flex">
            <div class="mr-2 custom-check-margin">
                <p-checkbox [(ngModel)]="checkedVideo" [binary]="true" (onChange)="filterList()" inputId="binary"></p-checkbox>
            </div>
            <span class="custom-chip-margin">
                <app-custom-chip [label]="item.positionStep.questionCollection.expectedAnswerVideoCount"
                            [svgUrl]="'assets/vinter/icons/icon-park_text-tabler_video.svg'" [alt]="'VideoQuestion'"
                            [bgColorClass]="'dark-blue'" [imgSize]="'16px'"></app-custom-chip>
            </span>
        </div>
    </div>
</div>
