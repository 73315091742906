import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ImageModule } from 'primeng/image';
import { BadgeModule } from 'primeng/badge';
import { TagModule } from 'primeng/tag';
import { QuestionDetailComponent } from '@app/shared/components/question-detail/question-detail.component';



@NgModule({
  declarations: [
    QuestionDetailComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    ImageModule,
    BadgeModule,
    TagModule,
    TranslateModule
  ],
  exports: [
    QuestionDetailComponent,
    ]
})
export class SharedQuestionDetailModule { }
