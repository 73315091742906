<div class="grid align-items-start mt-3">
    <div class="col-12 md:col-6 ml-2">
        <app-applicant-work-experience [applicationId]="applicationId"></app-applicant-work-experience>
    </div>
    <div class="col-12 md:col-6 -ml-1">
        <app-applicant-education-info [applicationId]="applicationId"></app-applicant-education-info>
    </div>
</div>


