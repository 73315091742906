
<div class="grid ml-0">
    <div class="pt-3 w-auto mr-2">
        <label class="custom-label">{{"Key.CodeLanguage" | translate}}</label>
    </div>
    <div class="mb-3" style="width: 80%">
        <p-dropdown *ngIf="isSelectionEnabled" class="w-full flex pb-1" [options]="codeModes" [(ngModel)]="selectedCodeMode"
                (ngModelChange)="onCodeLanguageChanged($event)" optionLabel="name" [autoDisplayFirst]="true" [readonly]="dropdownReadonly" [disabled]="dropdownReadonly">
        </p-dropdown>
    </div>
</div>
<div class="app-ace-editor" #editor [style.height.px]="height"></div>


