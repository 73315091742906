import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from '@app/core/configuration.service';
import { HttpBaseService } from '@app/core/http/http-base.service';
import { LocalizationService } from '@app/core/localization.service';
import { NotifyService } from '@app/core/notify.service';
import { ApiControllerEnum} from '@app/shared/common/enums';
import { Observable } from 'rxjs/internal/Observable';
import { ISession } from '../models/membership/membership-items';
import {loadStripe} from '@stripe/stripe-js';
import { StripePaymentReturnDto, StripeReturnDto } from '../models/license-plan-list-item';

@Injectable({
  providedIn: 'root'
})
export class MembershipService extends HttpBaseService {

  constructor(private http: HttpClient
    , private notifyServ: NotifyService
    , private localizationService: LocalizationService
    , private configService: ConfigurationService) {
    super(http, ApiControllerEnum.Payment, notifyServ, localizationService, configService);
  }

    requestMemberSession(obj: any): Observable<ISession> {
        return this.post("CreateCheckoutSession", obj, true);
    }

    checkPaymentStatus(obj: any): Observable<StripePaymentReturnDto> {
      return this.post("CheckPaymentStatus", obj, true);
  }

    requestMemberSessionUpgradePlan(obj: any): Observable<ISession> {
      return this.post("CreateCheckoutSessionUpgradePlan", obj, true);
    }

    requestMemberSessionAdditionalPackage(obj: any): Observable<ISession> {
      return this.post("CreateCheckoutSessionForAdditionalPackage", obj, true);
    }
    cancelSubsriptionAndUpdateLicenseOrganizationInfo(filter: any): Observable<StripeReturnDto> {
      return this.post("CancelSubsriptionAndUpdateLicenseOrganizationInfo", filter, true);
    }

    async redirectToCheckout(session: ISession) {
        const stripe = await loadStripe(session.publicKey);
        stripe.redirectToCheckout({
            sessionId: session.sessionId,
        });
    }

}
