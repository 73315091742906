import {Component, AfterViewInit, ViewChild, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Chart, registerables} from 'chart.js';

Chart.register(...registerables);

@Component({
    selector: 'app-spider-chart',
    templateUrl: './spider-chart.component.html',
    styleUrls: ['./spider-chart.component.scss']
})
export class SpiderChartComponent implements AfterViewInit {
    @ViewChild('spiderCanvas') spiderCanvas!: ElementRef;
    chart: any;
    @Input() chartData: number[] = [60, 80, 40, 50, 70];
    @Input() labels: string[] = ['Reading','Writing', 'Listening', 'Grammar/Vocabulary', 'Speaking'];



    constructor() {
        Chart.register(...registerables);
    }

    ngAfterViewInit(): void {
        this.createChart();
    }

    createChart() {
        const ctx = this.spiderCanvas.nativeElement.getContext('2d');

        this.chart = new Chart(ctx, {
            type: 'radar',
            data: {
                labels: this.labels,
                datasets: [
                {
                    label: 'Score',
                    data: this.chartData,
                    fill: true,
                    backgroundColor: '#1549D652',
                    borderColor: '#2962FF',
                    pointRadius: 0,
                    pointHoverRadius: 0,
                }
                ],
            },
            options: {
                scales: {
                    r: {
                        angleLines: {
                        display: true,
                        },
                        suggestedMin: 0,
                        suggestedMax: 100,
                        ticks: {
                            stepSize: 20,
                            display: true,
                            font: {
                                size: 6,
                            },
                            color: '#040D19',
                            showLabelBackdrop: false,
                            z: 10,
                        },
                    },
                },
                elements: {
                    line: {
                        borderWidth: 2
                    }
                },
                plugins: {
                    legend: {
                        display: false
                    },
                },
            },
        });
    }

}