import { Component, Input } from '@angular/core';
import { QuestionCollectionCategoryEnum } from '@app/shared/common/enums';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent {
  @Input() barData: any[] = [];
  @Input() showPercentage: boolean = true;
  @Input() stepType: any;

  QuestionCollectionCategoryEnum = QuestionCollectionCategoryEnum;

}