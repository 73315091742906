
<div class="flex gap-2 my-4 align-items-center">
    <div *ngIf="question?.expectedAnswerType === QuestionExpectedAnswerType.Video">
        <img class="custom-svg-question-icon"
            src="/assets/vinter/icons/videoQuestion.svg" />
    </div>
    <div *ngIf="question?.expectedAnswerType === QuestionExpectedAnswerType.OpenEnded">
        <img class="custom-svg-question-icon"
            src="/assets/vinter/icons/openEndedQuestion.svg" />
    </div>
    <div *ngIf="question?.expectedAnswerType === QuestionExpectedAnswerType.MultipleChoice">
        <img class="custom-svg-question-icon"
            src="/assets/vinter/icons/Quiz.svg" />
    </div>
    <div *ngIf="question?.expectedAnswerType === QuestionExpectedAnswerType.Coding">
        <img class="custom-svg-question-icon"
            src="/assets/vinter/icons/codingQuestionIcon.svg" />
    </div>

    <div *ngIf="question.questionCollectionCategory !== QuestionCollectionCategoryEnum.ForeignLanguage" >
        <img *ngIf="question.questionCategory === EQuestionCategories.Writing"
        class="w-2rem h-2rem" [src]="writingIconPath" />
        <img *ngIf="question.questionCategory === EQuestionCategories.Listening"
            class="w-2rem h-2rem" [src]="listeningIconPath" />
        <img *ngIf="question.questionCategory === EQuestionCategories.Reading"
            class="w-2rem h-2rem" [src]="readingIconPath" />
        <img *ngIf="question.questionCategory === EQuestionCategories.Vocabulary"
            class="w-2rem h-2rem" [src]="vocabularyIconPath" />
        <img *ngIf="question.questionCategory === EQuestionCategories.Grammer"
            class="w-2rem h-2rem" [src]="grammerIconPath" />
        <img *ngIf="question?.questionCategory === EQuestionCategories.Speaking"
            class="w-2rem h-2rem" [src]="speakingIconPath" />
    </div>
    <span class="text-gray-900 font-semibold text-2xl ml-2">
        {{"Key.QuestionOrder" | translate: { value1: question?.order } }}
    </span>
</div>

<div [ngSwitch]="question?.questionContent?.contentURLType">
    <div *ngSwitchCase="QuestionContentType.Text">
        <div class="border-round-md p-4 border-1 border-solid border-gray-100">
            <div *ngIf="question.questionCollectionCategory !== QuestionCollectionCategoryEnum.ForeignLanguage" class="flex flex-nowrap gap-2 align-items-center">
                <span *ngIf="question?.langShort"
                    class="font-medium text-sm">
                    {{ question?.langShort}}
                </span>
                <div *ngIf="question?.questionConcept"
                    class="vertical-line-weight-sidebar border-left-1 border-blue-50"></div>
                <div class="text-sm text-blue-600">
                    {{ question?.questionConcept?.name}}
                </div>
                <div *ngIf="question?.weight ?? false"
                    class="vertical-line-weight-sidebar border-left-1 border-blue-50"></div>
                <div *ngIf="question?.weight ?? false"
                    class="flex flex-nowrap align-items-center">
                    <img alt="" [src]="questionWeightIconPath" class="w-1rem">
                    <span class="text-blue-800 font-medium text-sm ml-2">{{question?.weight}}</span>
                </div>
            </div>
            <div class="text-lg text-gray-900 font-medium" [ngClass]="{ 'mt-3': question.questionCollectionCategory !== QuestionCollectionCategoryEnum.ForeignLanguage }"
            [innerHTML]="question?.questionContent?.questionText">
            </div>
        </div>
    </div>
    <div *ngSwitchCase="QuestionContentType.Image">
        <div class="border-round-md p-4 border-1 border-solid border-gray-100 flex">
            <div class="col-6 p-0">
                <p-image src={{question?.questionContent?.contentURL}} [imageStyle]="{'width': '100%'}"
                    [preview]="true"></p-image>
            </div>
            <div class="col-6">
                <div  *ngIf="question.questionCollectionCategory !== QuestionCollectionCategoryEnum.ForeignLanguage" class="flex flex-nowrap gap-2 align-items-center">
                    <span *ngIf="question?.langShort"
                        class="font-medium text-sm">
                        {{ question?.langShort}}
                    </span>
                    <div *ngIf="question?.questionConcept"
                        class="vertical-line-weight-sidebar border-left-1 border-blue-50"></div>
                    <div class="text-sm text-blue-600">
                        {{ question?.questionConcept?.name}}
                    </div>
                    <div *ngIf="question?.weight ?? false"
                        class="vertical-line-weight-sidebar border-left-1 border-blue-50"></div>
                    <div *ngIf="question?.weight ?? false"
                        class="flex flex-nowrap align-items-center">
                        <img alt="" [src]="questionWeightIconPath" class="w-1rem">
                        <span class="text-blue-800 font-medium text-sm ml-2">{{question?.weight}}</span>
                    </div>
                </div>
                <div class="text-lg text-gray-900 font-medium" [ngClass]="{ 'mt-3': question.questionCollectionCategory !== QuestionCollectionCategoryEnum.ForeignLanguage }"
                [innerHTML]="question?.questionContent?.questionText"></div>
            </div>
        </div>
    </div>
    <div *ngSwitchCase="QuestionContentType.Audio">
        <div class="border-round-md p-4 border-1 border-solid border-gray-100 flex">
            <div class="col-6 p-0">
                <audio src={{question?.questionContent?.contentURL}} style="width: 100%;" controls
                    controlslist="nodownload"></audio>
            </div>
            <div class="col-6">
                <div *ngIf="question.questionCollectionCategory !== QuestionCollectionCategoryEnum.ForeignLanguage" class="flex flex-nowrap gap-2 align-items-center">
                    <span *ngIf="question?.langShort"
                        class="font-medium text-sm">
                        {{ question?.langShort}}
                    </span>
                    <div *ngIf="question?.questionConcept"
                        class="vertical-line-weight-sidebar border-left-1 border-blue-50"></div>
                    <div class="text-sm text-blue-600">
                        {{ question?.questionConcept?.name}}
                    </div>
                    <div *ngIf="question?.weight ?? false"
                        class="vertical-line-weight-sidebar border-left-1 border-blue-50"></div>
                    <div *ngIf="question?.weight ?? false"
                        class="flex flex-nowrap align-items-center">
                        <img alt="" [src]="questionWeightIconPath" class="w-1rem">
                        <span class="text-blue-800 font-medium text-sm ml-2">{{question?.weight}}</span>
                    </div>
                </div>
                <div class="text-lg text-gray-900 font-medium" [ngClass]="{ 'mt-3': question.questionCollectionCategory !== QuestionCollectionCategoryEnum.ForeignLanguage }"
                [innerHTML]="question?.questionContent?.questionText"></div>
            </div>
        </div>
    </div>
    <div *ngSwitchCase="QuestionContentType.Video">
        <div class="border-round-md p-4 border-1 border-solid border-gray-100 flex">
            <div class="col-6 p-0">
                <video src={{question?.questionContent?.contentURL}} style="border-radius:1rem;width: 100%;" controls
                    controlslist="nodownload" disablepictureinpicture></video>
            </div>
            <div class="col-6 font-medium">
                <div *ngIf="question.questionCollectionCategory !== QuestionCollectionCategoryEnum.ForeignLanguage" class="flex flex-nowrap gap-2 align-items-center">
                    <span *ngIf="question?.langShort"
                        class="font-medium text-sm">
                        {{ question?.langShort}}
                    </span>
                    <div *ngIf="question?.questionConcept"
                        class="vertical-line-weight-sidebar border-left-1 border-blue-50"></div>
                    <div class="text-sm text-blue-600">
                        {{ question?.questionConcept?.name}}
                    </div>
                    <div *ngIf="question?.weight ?? false"
                        class="vertical-line-weight-sidebar border-left-1 border-blue-50"></div>
                    <div *ngIf="question?.weight ?? false"
                        class="flex flex-nowrap align-items-center">
                        <img alt="" [src]="questionWeightIconPath" class="w-1rem">
                        <span class="text-blue-800 font-medium text-sm ml-2">{{question?.weight}}</span>
                    </div>
                </div>
                <div class="text-lg text-gray-900 font-medium" [ngClass]="{ 'mt-3': question.questionCollectionCategory !== QuestionCollectionCategoryEnum.ForeignLanguage }"
                 [innerHTML]="question?.questionContent?.questionText"></div>
            </div>
        </div>
    </div>
    <div *ngIf="question?.keywords?.length > 0" class="border-round-md keyword-container border-1 border-solid border-gray-100 mt-3">
        <div class="flex mb-2 align-items-center gap-2 ml-1">
            <span class="text-blue-500 text-sm font-medium">{{'Key.Keywords' | translate}}</span>
            <div class="keyword-badge">
                <p-badge ngClass="radius" value={{question?.keywords.length}}></p-badge>
            </div>
        </div>
        <div class="w-full flex flex-wrap">
            <div class="tag-container mx-1" *ngFor="let item of question?.keywords">
                <p-tag class="keyword-tag keyword-value-color" value={{item.name}}></p-tag>
            </div>
        </div>
    </div>
</div>