import { Component, Input, OnInit } from '@angular/core';
import { EQuestionCategories, EQuestionCollectionSubCategory } from '@app/shared/common/enums';

@Component({
  selector: 'app-applicant-step-language-score-avg',
  templateUrl: './applicant-step-language-score-avg.component.html',
  styleUrls: ['./applicant-step-language-score-avg.component.scss']
})
export class ApplicantStepLanguageScoreAvgComponent implements OnInit {

  @Input() item: any;
  writingScore: number = 0;
  listeningScore: number = 0;
  readingScore: number = 0;
  grammerScore: number = 0;
  vocabularyScore: number = 0;
  speakingScore: number = 0;
  checkSpeakingCount: number = 0;


  generalEnglishListeningScore: number = 0;
  generalEnglishReadingScore: number = 0;
  generalEnglishGrammerVocabularyScore: number = 0;
  generalEnglishGrammerScore: number = 0;
  generalEnglishWritingScore: number = 0;
  generalEnglishSpeakingScore: number = 0;




  isGeneralEnglishTest: boolean = false;

  constructor() { }

  ngOnInit(): void {
    if (this.item?.positionStep?.questionCollection?.subCategory == EQuestionCollectionSubCategory.GeneralEnglish)
      this.isGeneralEnglishTest = true;

    let writingCount = 0;
    let listeningCount = 0;
    let readingCount = 0;
    let grammerCount = 0;
    let vocabularyCount = 0;
    let speakingCount = 0;


    let writingTotal = 0;
    let listeningTotal = 0;
    let readingTotal = 0;
    let grammerTotal = 0;
    let vocabularyTotal = 0;
    let speakingTotal = 0;


    if (this.item != null) {
      let qRelations = this.item?.positionStep?.questionCollection?.questionCollectionQuestionRelations;
      if (qRelations) {
        qRelations.forEach(element => {
          if (element.question.questionCategory == EQuestionCategories.Writing) {
            writingCount++;
            writingTotal += element.question.gptScore;
          }
          else if (element.question.questionCategory == EQuestionCategories.Listening) {
            listeningCount++;
            listeningTotal += element.question.gptScore;
          }
          else if (element.question.questionCategory == EQuestionCategories.Reading) {
            readingCount++;
            readingTotal += element.question.gptScore;
          }
          else if (element.question.questionCategory == EQuestionCategories.Grammer) {
            grammerCount++;
            grammerTotal += element.question.gptScore;
          }
          else if (element.question.questionCategory == EQuestionCategories.Vocabulary) {
            vocabularyCount++;
            vocabularyTotal += element.question.gptScore;
          }
          else if (element.question.questionCategory == EQuestionCategories.Speaking) {
            speakingCount++;
            speakingTotal += element.question.gptScore;
          }
        });
      }
    }

    this.checkSpeakingCount = speakingCount;

    if (!this.isGeneralEnglishTest) {
      if (writingCount != 0)
        this.writingScore = writingTotal / writingCount;
      if (listeningCount != 0)
        this.listeningScore = listeningTotal / listeningCount;
      if (readingCount != 0)
        this.readingScore = readingTotal / readingCount;
      if (grammerCount != 0)
        this.grammerScore = grammerTotal / grammerCount;
      if (vocabularyCount != 0)
        this.vocabularyScore = vocabularyTotal / vocabularyCount;
    }
    else {
      if (writingCount != 0)
        this.generalEnglishWritingScore = writingTotal / writingCount;
      if (listeningCount != 0)
        this.generalEnglishListeningScore = listeningTotal / listeningCount;
      if (readingCount != 0)
        this.generalEnglishReadingScore = readingTotal / readingCount;
      if (grammerCount != 0)
        this.generalEnglishGrammerScore = grammerTotal / grammerCount;
      if ((grammerCount + vocabularyCount) != 0)
        this.generalEnglishGrammerVocabularyScore = (grammerTotal + vocabularyTotal) / (grammerCount + vocabularyCount);
      if (speakingCount != 0)
        this.generalEnglishSpeakingScore = speakingTotal / speakingCount;

    }




  }
}
