<div *ngIf="questionKeywords.length > 0" class="question-container-sideBar-text">
    <div class="flex mb-3">
        <div class="keyword-text">
            {{'Key.Keywords' | translate}}
        </div>
        <div>
            <p-badge class="radius" value={{questionKeywords.length}}></p-badge>
        </div>
    </div>
    <div>
        <div class="inline -ml-2" *ngFor="let item of questionKeywords">
            <p-tag class="keyword-tag keyword-value-color" value={{item.name}}></p-tag>
        </div>
    </div>
</div>
