import { Component, ElementRef, Input, OnInit, Pipe, PipeTransform, SimpleChanges, ViewChild } from '@angular/core';
import { AICommentsIconPath } from '@app/shared/common/constants';
import { EApplicantBehaviourEventType, GPTEvalStatus } from '@app/shared/common/enums';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { ForeignLanguageVideoAnswerItem } from '@app/vinter/models/answer/foreign-language-video-answer-item';
import { ApplicantBehaviourEventLogService } from '@app/vinter/services/applicant-behaviour-event-log.service';
import { VgApiService } from '@videogular/ngx-videogular/core';

@Component({
  selector: 'app-foreign-language-video-answer',
  templateUrl: './foreign-language-video-answer.component.html',
  styleUrls: ['./foreign-language-video-answer.component.scss']
})
export class ForeignLanguageVideoAnswerComponent implements OnInit{

  @Input() answer: ForeignLanguageVideoAnswerItem;
  GPTEvalStatusEnum = GPTEvalStatus;
  AICommentPathIcon = AICommentsIconPath;
  speakingWords = [];
  emotionArray: any[] = [];
  emotionScores: { [emotion: string]: number } = {};
  impressionArray: any[] = [];
  voiceSpeechSpeedTypes: any[];
  voiceArray: any[] = [];
  subtitles: any[] = [];
  discourseOrgEval: string;
  fluencyEval: string;
  grammarEval: string;
  pronunciationEval: string;
  vocabAccEval: string;
  @ViewChild('speakingDiv') speakingDiv: ElementRef;
  @ViewChild('speakingVideo') target: ElementRef;
  @ViewChild('transcriptDiv') transcriptDiv: ElementRef;
  speechSpeed = "speechSpeed";
  questionPageHiddenLog: number;
  emotionColors = {
    angry: '#FE5F55',
    disgust: '#3AB1C8',
    fear: '#7ECA9C',
    happy: '#FFA372',
    sad: '#1597E5',
    surprise: '#9E67BF',
    neutral: '#BEBBAD',
    facenotfound: '#FFFFFF'
};

constructor(
  private eventLogService: ApplicantBehaviourEventLogService,
  private appInfoService: AppInfoService,
  public api: VgApiService,
){}

ngOnChanges(changes: SimpleChanges): void {
  if (this.answer) {
    this.initializeEntries();
  }
  this.voiceSpeechSpeedTypes = this.appInfoService.getVoiceSpeechSpeedTypes();
}

ngAfterViewInit(): void {
  if (this.answer?.answerContent?.contentURL && this.target) {
    this.target.nativeElement.load();
    this.target.nativeElement.addEventListener('timeupdate', () => {
      this.highlightSubtitle(this.target.nativeElement); 
    });
  }
  if (this.answer?.answerContent?.subtitle) {
    this.setSubTitles();
  }
}


private initializeEntries(): void {
  this.setEmotionEntries();
  this.setImpressionEntries();
  this.setVoiceEntries();
  this.getPageHiddenLog();
  this.setSpeechAnalysisParameter(); 
}
  ngOnInit(): void {
    if(this.answer){
      if (this.answer?.answerContent?.subtitle != null) {
        this.setSubTitles();
      }
      this.setSpeechAnalysisParameter();
      this.getPageHiddenLog();
      this.setImpressionEntries()
      this.setEmotionEntries()
      this.setVoiceEntries()
    }
    this.voiceSpeechSpeedTypes = this.appInfoService.getVoiceSpeechSpeedTypes();
  }

  setSpeechAnalysisParameter(){
    if (this.answer?.speechAnalysis != null && this.answer?.speechAnalysis?.analysisResultDetail) {
      let all = (JSON.parse(this.answer?.speechAnalysis.analysisResultDetail.toString()));
      this.discourseOrgEval = all.eval_texts.discourse_org_eval;
      this.fluencyEval = all.eval_texts.fluency_eval;
      this.grammarEval = all.eval_texts.grammar_eval;
      this.pronunciationEval = all.eval_texts.pronunciation_eval;
      this.vocabAccEval = all.eval_texts.vocab_acc_eval;
    }
  }

  setSubTitles() {
    let all = (JSON.parse(this.answer?.answerContent?.subtitle.toString()));
    this.subtitles = all.segments;
    const emotionTotals: { [emotion: string]: number } = {};
    const totalSubtitles = this.subtitles.reduce((sum, item) => sum + item.emotion_score, 0);
  
    this.subtitles.forEach((subtitle) => {
      const emotion = subtitle.emotion.toLowerCase();
      emotionTotals[emotion] = (emotionTotals[emotion] || 0) + subtitle.emotion_score;
    });
  
    for (const emotion in emotionTotals) {
      if (emotionTotals.hasOwnProperty(emotion)) {
        this.emotionScores[emotion] = (emotionTotals[emotion] / totalSubtitles) * 100;
      }
    }
  }

  jumpToSecond(start: number) {
    if (this.target) {
      this.target.nativeElement.currentTime = start + 0.00001;
      this.highlightSubtitle(this.target.nativeElement);
      this.target.nativeElement.play();
    }
  }

  highlightSubtitle(target: any) {
    const currentTime = target.currentTime; 
    let subtitleChanged = false;
  
    this.subtitles.forEach((subtitle, index) => {
      const isHighlighted = currentTime >= subtitle.start && currentTime < subtitle.end;
  
      if (subtitle.highlighted !== isHighlighted) {
        subtitle.highlighted = isHighlighted; 
        subtitle.id = 's' + index; 
        subtitleChanged = true;
      }
    });
  
    if (subtitleChanged) {
      this.highlightSubtitleInView(); 
    }
  }
  

  highlightSubtitleInView() {
    let activeSubtitle = this.subtitles.find(subtitle => subtitle.highlighted);
    if (activeSubtitle) {
      let highlightedElement = document.querySelector(`#s${activeSubtitle.id}`);
      if (highlightedElement) {
        highlightedElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest"
        });
      }
    }
  }

  setImpressionEntries(): [string, number][] {
    const impressionAnalysis = this.answer?.impressionAnalysis || {};
    this.impressionArray = Object.entries(impressionAnalysis)
    .filter(([_, value]) => value != null)
    .map(([name, value]) => ({ name, value }));
    return this.impressionArray;
  }
  setEmotionEntries(): [string, number][] {
    const emotionAnalysis = this.answer?.emotionAnalysis || {};
    this.emotionArray = Object.entries(emotionAnalysis)
    .filter(([_, value]) => value != null)
    .map(([name, value]) => ({ name, value }));
    return this.emotionArray;
  }

  setVoiceEntries(): [string, any][] {
    const voiceAnalysis = this.answer?.voiceAnalysis || {};
    this.voiceArray = Object.entries(voiceAnalysis)
    .filter(([_, value]) => value != null)
    .map(([name, value]) => ({ name, value }));
    return this.voiceArray;
  }

  highlightSpeaking(target: any) {
    this.speakingWords.forEach((word, index) => {
        word.index = 'p' + index;
        word.highlighted = target.currentTime >= word.start && target.currentTime < word.end;
    });

    this.highlightSpeakingInView();
}

getColor(emotion: string, score: number): string {
  const alpha = Math.min(1, score / 100);
  const originalColor = this.emotionColors[emotion.toLowerCase()] || '#FFFFFF';
  const computedColor = (score !== -1) ? (originalColor.slice(0, 7) + Math.round(alpha * 255).toString(16).toUpperCase()) : (originalColor.slice(0, 7));
  return computedColor;
}

highlightSpeakingInView() {
    let activeWord = this.speakingWords.find(word => word.highlighted);

    if (activeWord) {
        let highlightedElement = this.speakingDiv.nativeElement.querySelector(`#p${activeWord.index}`);

        if (highlightedElement) {
            highlightedElement.scrollIntoView({
                behavior: "smooth",
                block: "nearest"
            });
        }
    }
}

getPageHiddenLog(){
  let eventLogFilter: any = {};
  eventLogFilter.applicationStepId = this.answer.applicationStepId;
  eventLogFilter.eventType = EApplicantBehaviourEventType.PageHidden;
  eventLogFilter.questionId = this.answer.questionId;
  this.eventLogService.getListByFilter(eventLogFilter).subscribe(eventLog => {
      this.questionPageHiddenLog = eventLog.length;
  });
}

}

@Pipe({
  name: 'voiceSpeechSpeedTypes',
})
export class VoiceSpeechSpeedTypesPipe implements PipeTransform {
  transform(row: any, list: any[]): any {
      return list.find(x => x.id === row).name;
  }
}
