<div [style.width.px]="width">
    <span class="gauge-info-text">{{ label }}</span>
    <svg viewBox="-5 -5 79 48" [ngClass]="['gauge-chart', gaugeClass]">
      <path class="gauge-bg" transform="scale(-1,1) translate(-69, 0)"
            d="M66,34.5 C66,17.1030304 51.8969696,3 34.5,3 C17.1030304,3 3,17.1030304 3,34.5" />
      <path class="gauge" id="gauge-path"
            [attr.stroke-dasharray]="value + ', 100'"
            transform="scale(-1,1) translate(-69, 0)"
            d="M66,34.5 C66,17.1030304 51.8969696,3 34.5,3 C17.1030304,3 3,17.1030304 3,34.5" />
      <text class="gauge-text" x="34.5" y="30" text-anchor="middle" fill="black">{{ score ? score : '%' + value }}</text>

    </svg>
    <span *ngIf="score" class="gauge-value-text">{{ value }}</span>
</div>
