<div class="logo"></div>
<div class="flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden background">
    <div class="grid justify-content-center p-2 lg:p-0" style="min-width: 80%">
        <div class="col-12 xl:col-6">
            <div class="h-full w-full m-0 py-5 px-5 loginCard">
                <div class="w-full md:w-10 mx-auto">
                    <div class="header-container mb-5">
                        <div class="logo-container">
                            <div>
                                <img src="{{ vinterLogoWithoutTextPath }}" alt="Vinter logo" class="w-full" />
                            </div>
                        </div>
                        <p-toggleButton [(ngModel)]="isTurkishSelected" onLabel="EN" offLabel="TR"
                            (onChange)="onLanguageToggle(true)"></p-toggleButton>
                    </div>
                    <div class="mb-5">
                        <div class="pageTitleLogin mb-2">
                            {{ "Key.Register" | translate }}
                        </div>
                        <span class="subTitleTextLogin">{{
                            "Key.RegisterText" | translate
                            }}</span>
                    </div>

                    <form [formGroup]="form" class="form">
                        <div class="p-fluid w-full">
                            <!--Name Surname-->
                            <div class="grid">
                                <div class="col-12 md:col-6 lg:col-6 paddingRight">
                                    <div class="flex mb-2">
                                        <label class="text-title">{{"Key.FirstName" | translate}}</label>
                                        <div class="error-message flex">
                                            <p class="line-height-1"
                                                *ngIf="form.controls.firstName.touched && form.controls.firstName.errors?.required">
                                                *</p>
                                        </div>
                                    </div>
                                    <input type="text" pInputText formControlName="firstName" required />
                                </div>
                                <div class="col-12 md:col-6 lg:col-6 paddingLeft">
                                    <div class="flex mb-2">
                                        <label class="text-title">{{"Key.LastName" | translate}}</label>
                                        <div class="error-message flex">
                                            <p class="line-height-1"
                                                *ngIf="form.controls.lastName.touched && form.controls.lastName.errors?.required">
                                                *</p>
                                        </div>
                                    </div>
                                    <input type="text" pInputText formControlName="lastName" required />
                                </div>
                            </div>

                            <!--Mail-->
                            <div class="mb-3">
                                <div class="flex mb-2">
                                    <label class="text-title">{{ "Key.Mail" | translate }}</label>
                                    <div class="error-message flex">
                                        <p class="line-height-1" *ngIf="this.checkFormValidity('email')">
                                            *</p>
                                    </div>
                                </div>
                                <input type="email" pInputText formControlName="email" required />
                            </div>

                            <!--Company-->
                            <div class="mb-3">
                                <div class="flex mb-2">
                                    <label class="text-title">{{ "Key.Company" | translate }}</label>
                                    <div class="error-message flex">
                                        <p class="line-height-1"
                                            *ngIf="form.controls.company.touched && form.controls.company.errors?.required">
                                            *</p>
                                    </div>
                                </div>
                                <input [ngClass]="{'surface-200': company??false}" type="text" pInputText
                                    formControlName="company" required [readOnly]="company??false" />
                            </div>

                            <!--Country-->
                            <div class="grid">
                                <div class="col-12 md:col-6 lg:col-6 paddingRight">
                                    <div class="flex mb-2">
                                        <label class="text-title">{{ "Key.Country" | translate }}</label>
                                        <div class="error-message flex">
                                            <p class="line-height-1"
                                                *ngIf="form.controls.country.touched && form.controls.country.errors?.required">
                                                *</p>
                                        </div>
                                    </div>
                                    <p-autoComplete class="w-full input-border" formControlName="country"
                                        [dropdown]="true" [suggestions]="filteredCountries"
                                        (completeMethod)="filterCountry($event)" field="name"
                                        placeholder="{{'Key.Country' | translate}}">
                                    </p-autoComplete>
                                </div>
                                <div class="col-12 md:col-6 lg:col-6 paddingLeft">
                                    <div class="flex mb-2">
                                        <label class="text-title">{{"Key.City" | translate}}</label>
                                        <div class="error-message flex">
                                            <p class="line-height-1"
                                                *ngIf="form.controls.city.touched && form.controls.city.errors?.required">
                                                *</p>
                                        </div>
                                    </div>
                                    <input type="text" pInputText formControlName="city" required />
                                </div>
                            </div>
                            <!--Adress Line - PostalCode-->
                            <div class="grid">
                                <div class="col-12 md:col-9 lg:col-9 paddingRight">
                                    <div class="flex mb-2">
                                        <label class="text-title">{{"Key.AdressLine" | translate}}</label>
                                        <div class="error-message flex">
                                            <p class="line-height-1"
                                                *ngIf="form.controls.adressLine.touched && form.controls.adressLine.errors?.required">
                                                *</p>
                                        </div>
                                    </div>
                                    <input type="text" pInputText formControlName="adressLine" required />
                                </div>
                                <div class="col-12 md:col-3 lg:col-3 paddingLeft">
                                    <div class="flex mb-2">
                                        <label class="text-title">{{"Key.PostCode" | translate}}</label>
                                        <div class="error-message flex">
                                            <p class="line-height-1"
                                                *ngIf="form.controls.postalCode.touched && form.controls.postalCode.errors?.required">
                                                *</p>
                                        </div>
                                    </div>
                                    <input type="text" pInputText formControlName="postalCode" required />
                                </div>
                            </div>

                            <!--Phone-->
                            <div class="mb-3">
                                <div class="flex mb-2">
                                    <label class="text-title">{{"Key.Phone" | translate}}</label>
                                    <div class="error-message flex">
                                        <p class="line-height-1"
                                            *ngIf="form.controls.phone.touched && form.controls.phone.errors?.required">
                                            *</p>
                                    </div>
                                </div>
                                <input id="phone" placeholder=" " type="tel" pInputText (input)="validateInput($event)"
                                    (keydown)="validateKeydown($event)" formControlName="phone" />
                                <div class="alert mt-2 min-height">
                                    <p *ngIf="showFormValidation">{{"Key.InvalidNumber" | translate}}</p>
                                </div>
                            </div>

                            <!--Passwords-->
                            <div class="grid mb-5">
                                <div class="col-12 md:col-6 lg:col-6 paddingRight">
                                    <div class="flex mb-2">
                                        <label class="text-title">{{"Key.Password" | translate}}</label>
                                        <div class="error-message flex">
                                            <p class="line-height-1"
                                                *ngIf="form.controls.password.touched && form.controls.password.errors?.required">
                                                *</p>
                                        </div>
                                    </div>
                                    <p-password required formControlName="password" [toggleMask]="true"
                                        [feedback]="false"></p-password>
                                </div>
                                <div class="col-12 md:col-6 lg:col-6 paddingLeft">
                                    <div class="flex mb-2">
                                        <label class="text-title">{{"Key.PasswordAgain" | translate}}</label>
                                        <div class="error-message flex">
                                            <p class="line-height-1"
                                                *ngIf="form.controls.passwordAgain.touched && form.controls.passwordAgain.errors?.required">
                                                *</p>
                                        </div>
                                    </div>
                                    <p-password required formControlName="passwordAgain" [toggleMask]="true"
                                        [feedback]="false"></p-password>
                                    <div *ngIf="this.checkFormValidity('passwordAgain')" class="alert mt-2">
                                        {{ this.getFormValidityMessage("passwordAgain") | translate: { charNum:
                                        passwordMinCount } }}
                                    </div>
                                </div>
                            </div>

                            <!--Turkish Checkboxs-->
                            <div *ngIf="isTurkishSelected" class="w-full grid justify-content-between mt-5">
                                <div class="ml-3 align-items-start flex">
                                    <p-checkbox formControlName="acceptDisclosure" value="disclosureAgreement"
                                        inputId="disclosureAgreement" [binary]="true"></p-checkbox>
                                    <p class="agreementText">
                                        <a (click)="toggleUserAgreementDialog(EUserConsentType.HrDisclosure)"
                                            class="agreementLink cursor-pointer">Abone/Alıcı Aydınlatma Metni</a>'ni ve
                                        <a (click)="toggleUserAgreementDialog(EUserConsentType.TermsOfUse)"
                                            class="agreementLink cursor-pointer">Kullanım Sözleşmesi</a>'ni okudum ve
                                        kabul ediyorum.
                                    </p>
                                </div>
                                <div class="ml-3 align-items-start flex">
                                    <p-checkbox formControlName="acceptTEIOM" value="TEIOMAgreement"
                                        inputId="TEIOMAgreement" [binary]="true"></p-checkbox>
                                    <p class="agreementText">
                                        Vinter Al Recruitment Ltd ('Şirket') ile paylaşacağım iletişim bilgilerime
                                        Şirket tarafından tanıtım, reklam, kampanya, promosyon, e-bülten gönderimi ve
                                        benzeri pazarlama faaliyetleri çerçevesinde e-posta kanalıyla ileti
                                        gönderilmesine
                                        <a (click)="toggleUserAgreementDialog(EUserConsentType.TEIOM)"
                                            class="agreementLink cursor-pointer">Ticari Elektronik İleti Onay Metni</a>
                                        kapsamında onay veriyorum.
                                    </p>
                                </div>
                            </div>

                            <!--English Checkboxs-->
                            <div *ngIf="!isTurkishSelected" class="w-full grid justify-content-between mt-5">
                                <div class="ml-3 align-items-start flex">
                                    <p-checkbox formControlName="acceptGDPR" value="GDPRAgreement"
                                        inputId="GDPRAgreement" [binary]="true"></p-checkbox>
                                    <p class="agreementText">By checking this box, I accept your
                                        <a (click)="toggleUserAgreementDialog(EUserConsentType.TermsOfUse)"
                                            class="agreementLink cursor-pointer">Terms of Use </a>
                                        and have read and understood the
                                        <a (click)="toggleUserAgreementDialog(EUserConsentType.PrivacyPolicy)"
                                            class="agreementLink cursor-pointer">Privacy Policy</a>.
                                    </p>
                                </div>
                                <div class="ml-3 align-items-start flex">
                                    <p-checkbox formControlName="acceptMail" value="mailAgreement"
                                        inputId="mailAgreement" [binary]="true"></p-checkbox>
                                    <p class="agreementText">
                                        I would like to sign up to receive offers, promotions, and other commercial
                                        messages via email.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div class="flex flex-wrap">
                        <re-captcha [ngClass]="{ hidden: !isRecaptchaActive }" #captchaRef="reCaptcha" size="invisible"
                            class="mt-5" (resolved)="resolved($event)" [siteKey]="recaptchaKey"></re-captcha>
                        <button pButton [disabled]="form.invalid" pRipple label="{{ 'Key.CreateAccount' | translate }}"
                            class="buttonFontLogin bg-indigo-500 w-full p-3 login-Button"
                            (click)="checkRecaptchaAndRegister(captchaRef)" icon="pi pi-arrow-right"
                            iconPos="right"></button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<p-toast position="bottom-center"></p-toast>
<!-- onScroll="{props.onScroll}" -->
<app-background-mask [maskActive]=userAgreementVisible></app-background-mask>
<p-dialog header=" {{ selectedConsentName | translate }}" [(visible)]="userAgreementVisible" [draggable]="false"
    [resizable]="false">
    <app-user-consent [userAgreementVisible]="userAgreementVisible" [consentType]="selectedConsentType"
        [languageID]="selectedLanguage.id"></app-user-consent>
    <ng-template pTemplate="footer"></ng-template>
</p-dialog>