import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LocalizationService } from '@app/core';
import { GrammerIconPath, ListeningIconPath, QuesitonWeightIcon, ReadingIconPath, SpeakingIcon, VocabularyIconPath, WritingIconPath } from '@app/shared/common/constants';
import { EQuestionCategories, EQuestionContentURLType, QuestionCollectionCategoryEnum, QuestionExpectedAnswerType } from '@app/shared/common/enums';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { QuestionListItem } from '@app/vinter/models/question-list-item';

@Component({
  selector: 'app-question-detail',
  templateUrl: './question-detail.component.html',
  styleUrls: ['./question-detail.component.scss']
})
export class QuestionDetailComponent implements OnInit, OnChanges {
 
  @Input() question : QuestionListItem;
  QuestionExpectedAnswerType = QuestionExpectedAnswerType;
  QuestionContentType=EQuestionContentURLType;
  questionWeightIconPath = QuesitonWeightIcon;
  questionCollectionLanguageList: any[] = [];
  QuestionCollectionCategoryEnum = QuestionCollectionCategoryEnum;
  EQuestionCategories = EQuestionCategories;
  speakingIconPath = SpeakingIcon;
  writingIconPath = WritingIconPath;
  listeningIconPath = ListeningIconPath;
  readingIconPath = ReadingIconPath;
  vocabularyIconPath = VocabularyIconPath;
  grammerIconPath = GrammerIconPath;

constructor( private localizationService: LocalizationService,
  private appInfoService: AppInfoService)
{}
  ngOnChanges(changes: SimpleChanges): void {
    if(this.question?.languageID ?? false){
      this.getShortLangText();
    }
  }
  ngOnInit(): void {
    if(this.question?.languageID ?? false){
      this.getShortLangText();
    }
  }
  getShortLangText(){
    this.questionCollectionLanguageList = this.appInfoService.getQuestionCollectionLanguageList();
    const keyName = this.questionCollectionLanguageList.find(x=> x.id === this.question.languageID)?.name;
    this.question.langShort = keyName ?? false ? this.localizationService.translatedTextList['QCollectionShort'+keyName] : '';
}
}
