<div class="col-12 flex p-0">
    <div class="col-7 p-0 flex align-items-center">
        <div class="flex justify-content-center p-0">
            <p-button styleClass="p-button-rounded p-button-secondary p-button-text" (onClick)="onButtonClick()"
                icon="pi pi-arrow-left"></p-button>
        </div>
        <div class="backToButton">
            {{"Key.BackToKanbanMessage1" | translate}}
            <span class="text-blue-500 font-semibold ">{{positionTitle}}</span>
            {{"Key.BackToKanbanMessage2" | translate}}
        </div>
    </div>
    <div class="col-5 py-1 pr-0 flex justify-content-end">
        <div class="vertical-line mx-5" style="height: 25px;"></div>
        <div class="flex align-items-center justify-content-center">
            <div
                class="flex align-items-center justify-content-center align-content-center bg-white border-1 border-solid border-round-sm border-gray-50 p-1">
                <img class="cursor-pointer mr-2" src="assets/vinter/icons/suggest_icon.svg"
                    (click)="suggestApplicant()" />
                <span class="cursor-pointer mr-2 text-gray-800 font-medium" (click)="suggestApplicant()">{{"Key.Suggest"
                    |
                    translate}}</span>
            </div>
        </div>

        <div class="vertical-line mx-1" style="height: 25px;"></div>
        <div class="flex align-items-center justify-content-center">
            <div
                class="flex align-items-center justify-content-center align-content-center bg-white border-1 border-solid border-round-sm border-gray-50 p-1">
                <img class="cursor-pointer -mt-1 mr-2" src="assets/vinter/icons/download-icon.svg"
                    (click)="pdfDownload()" />
                <span class="cursor-pointer mr-2 backToButton text-gray-900" (click)="pdfDownload()">{{"Key.Download" |
                    translate}}</span>
            </div>

            <p-overlay [(visible)]="pdfDownloadOverlayVisible" contentStyleClass="surface-overlay shadow-2 border-round p-3 pb-1" (onAnimationStart)="onBeforeShowHandler($event)">
                <a class="w-full flex mb-3 cursor-pointer backToButton text-gray-900" (click)="checkLicenseLockAndGenerateDetailPdf()">
                    {{'Key.DetailPdfDownload' | translate}}
                    <img class="icon-width" src="assets/vinter/icons/download-icon.svg">
                </a>
                <a class="w-full flex mb-3 cursor-pointer backToButton text-gray-900" (click)="checkLicenseLockAndGenerateSummaryPdf()">
                    {{'Key.SummaryPdfDownload' | translate}}
                    <img class="icon-width" src="assets/vinter/icons/download-icon.svg">
                </a>

                <div class="flex align-items-center flex-nowrap" (click)="isAIReportLicenseValid ? checkLicenseLockAndRequestAIBasedPdf() : openLicensePlanPopup()">
                    <img src="assets/vinter/icons/crown.svg" width="18px" height="18px" *ngIf="!isAIReportLicenseValid && showRequestAIReportBtn" class="-mt-3 -ml-1 mb-1">
                    <a *ngIf="showRequestAIReportBtn" class="w-full flex mb-3 cursor-pointer backToButton text-gray-900 ml-2" >
                        {{'Key.AIBasedRequest' | translate}}
                    </a>
                </div>
                <a *ngIf="showPendingAIReportBtn" class="w-full flex mb-3 cursor-pointer backToButton text-gray-900" style="cursor: default !important;">
                    {{'Key.AIBasedPending' | translate}}
                </a>
                <div class="flex align-items-center flex-nowrap" (click)="isAIReportLicenseValid ? checkLicenseLockAndDownloadAIBasedPdf() : openLicensePlanPopup()">
                    <img src="assets/vinter/icons/crown.svg" width="18px" height="18px" *ngIf="!isAIReportLicenseValid && showDownloadAIReportBtn" class="-mt-3 -ml-1 mb-1">
                    <a *ngIf="showDownloadAIReportBtn" class="w-full flex mb-3 cursor-pointer backToButton text-gray-900 ml-1" >
                        {{'Key.AIBasedDownload' | translate}}
                        <img class="icon-width" src="assets/vinter/icons/download-icon.svg" *ngIf="isAIReportLicenseValid">
                    </a>
                </div>
            </p-overlay>
        </div>
        <div class="vertical-line ml-2">
            <p-avatar [pTooltip]="responsibleUserFirstName +' '+ responsibleUserLastName" tooltipPosition="left"
                *ngIf="!(responsibleUserImageUrl ?? false)" class="responsibleAvatar-blue" shape="circle"  styleClass="w-2rem h-2rem border-4 border-blue-200 mt-1">
                <span class="text-lg text-blue-900 font-semibold">{{responsibleUserFirstName[0]?.toUpperCase() + responsibleUserLastName[0]?.toUpperCase()}}</span>
            </p-avatar>
            <p-avatar [pTooltip]="responsibleUserFirstName +' '+ responsibleUserLastName" tooltipPosition="left" *ngIf="responsibleUserImageUrl ?? false" class="responsibleAvatar-blue ml-2"
                [image]="responsibleUserImageUrl" shape="circle" styleClass="w-2rem h-2rem border-4 border-blue-200 mt-1">
            </p-avatar>
        </div>
    </div>

    <div>
        <app-suggest-applicant-v2 *ngIf="suggestSidebarVisible" [sidebarVisible]="suggestSidebarVisible"
            [applicationId]="applicationId">
        </app-suggest-applicant-v2>
    </div>

    <div *ngIf="downloadPdfReport">
        <app-pdf-report [applicationId]="applicationId" [reportType]="pdfReportType" [positionTitle]="positionTitle" [organizationImageUrl]="organizationImageUrl" [organizationName]="organizationName">
        </app-pdf-report>
    </div>

</div>


<p-dialog [(visible)]="showAiReportAddedNewStepInfo" [draggable]="false" [modal]="true" showEffect="fade"
    [style]="{width: '30vw'}" [breakpoints]="{'960px': '75vw'}" class="dialogPage" [closable]="false">
    <div class="grid">
        <div class="col-12 flex justify-content-center">
            <img class="w-4rem" src="../../../../../assets/vinter/icons/AIBasedPdfDialog.svg">
        </div>
        <div class="col-12 complete-dialog-header flex justify-content-center font-bold text-blue-500 text-center ">
            {{'Key.AiReportAddedNewStepInfoHeader' | translate}}
        </div>

        <div class="col-12 complete-dialog-text pt-0 flex justify-content-center text-gray-500 text-center">
            {{'Key.AiReportAddedNewStepInfoMessage' | translate}}
        </div>
        <div class="col-12 complete-dialog-header flex justify-content-center font-bold text-blue-500 text-center ">
            <div class="col-6 flex justify-content-center">
                <p-button label="{{'Key.Ok' | translate}}" (onClick)="toggleShowAiReportAddedNewStepInfo()"
                    styleClass="mr-2 mb-2">
                </p-button>
            </div>

        </div>
    </div>
</p-dialog>

<p-dialog [(visible)]="showAiReportPendingInfo" [draggable]="false" [modal]="true" showEffect="fade"
    [style]="{width: '30vw'}" [breakpoints]="{'960px': '75vw'}" class="dialogPage" [closable]="false">
    <div class="grid">
        <div class="col-12 flex justify-content-center">
            <img class="w-4rem" src="../../../../../assets/vinter/icons/AIBasedPdfDialog.svg">
        </div>
        <div class="col-12 complete-dialog-header flex justify-content-center font-bold text-blue-500 text-center ">
            {{'Key.AiReportPendingInfoHeader' | translate}}
        </div>

        <div class="col-12 complete-dialog-text pt-0 flex justify-content-center text-gray-500 text-center">
            {{'Key.AiReportPendingInfoMessage' | translate}}
        </div>
        <div class="col-12 complete-dialog-header flex justify-content-center font-bold text-blue-500 text-center ">
            <div class="col-6 flex justify-content-center">
                <p-button label="{{'Key.Ok' | translate}}" (onClick)="toggleShowAiReportPendingInfo()"
                    styleClass="mr-2 mb-2">
                </p-button>
            </div>

        </div>
    </div>
</p-dialog>

<p-dialog class="license-plan-popup" [blockScroll]="true" showEffect="fade" [draggable]="false" 
          [resizable]="false" [(visible)]="isShowLicensePlanPopup" [modal]="false" [style]="{width: '95%'}">
    
          <app-license-plan-dialog [suggestedPackageType]="suggestedPackageType"></app-license-plan-dialog>
</p-dialog>