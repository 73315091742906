import { Component, Input, OnInit } from '@angular/core';
import { VgApiService } from '@videogular/ngx-videogular/core';

@Component({
  selector: 'app-question-keyword-answer-language',
  templateUrl: './question-keyword.component.html',
  styleUrls: ['./question-keyword.component.scss']
})
export class ApplicantAnswerQuestionKeyword implements OnInit{

    @Input() questionKeywords: any[] = []; // Filtrelenmiş liste için kullanılacak
  constructor(

    public api: VgApiService) { }

  ngOnInit(): void {
  }

}
