import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { QuestionExpectedAnswerType } from '@app/shared/common/enums';
import { AnswerContentHRScoreItems, AnswerContentHRScoreResponse } from '@app/vinter/models/answer-content-form-item';
import { AnswerContentHRScoreService } from '@app/vinter/services/answer-content-hr-score.service';
import { B } from '@fullcalendar/core/internal-common';

@Component({
  selector: 'app-change-vinter-score-sidebar',
  templateUrl: './change-vinter-score-sidebar.component.html',
  styleUrls: ['./change-vinter-score-sidebar.component.scss']
})
export class ChangeVinterScoreSidebarComponent implements OnInit{

  @Input() scoreChangeSidebarVisible: boolean = false;
  @Input() changeScoreSidebarVisible: boolean = false;
  @Output() changeScoreSidebarVisibleChange = new EventEmitter<boolean>();
  @Input() scoreChange!: number; 
  @Output() scoreChangeChange = new EventEmitter<number>();
  @Input() isScoreChanged: boolean = false;
  @Output() isScoreChangedChange = new EventEmitter<boolean>();
  @Output() sidebarClosed = new EventEmitter<void>();
  @Output() questionDetail = new EventEmitter<void>();
  @Input() vinterScore: number;
  @Input() answerContentID: string;
  @Input() applicantID: string;

  questionText : any;
  answerText : any;
  hrImageUrl: string;
  questionType : any;
  order : any;
  hrScoreDetails:  AnswerContentHRScoreResponse[];
  QuestionExpectedAnswerType = QuestionExpectedAnswerType;
  choices : any [];
  selectedAnswer: any;
  newHRScore: number;
  // changeScoreSidebarVisible: boolean = true
  selectedRadioValue: any;
  questionAnswered : any;
  questionId : any;
  value: number;
  firstAIScore: number;
  content: string = ""
  firstName:string;
  lastName: string;
  isEditorHidden: boolean = true;
  initialValue: number;
  isCommentAdded: boolean
  lastChangedScore: number;
  fullValue: number;
  valueToAdded: number;
  fractionValue: number = 0;

  difference: number = 0;
  hrDepartment: string;
  savedScoreDifference: string;
  sortAscending: boolean = true;
  isDataChanged: boolean = false;

  formats=[
    'bold',
    'italic',
    'underline',
    'list',
  ];
  text: string = '<div>Hello World!</div><div>PrimeNG <b>Editor</b> Rocks</div><div><br></div>';

  colorRanges = [
    { min: 50, max: 100, color: '#32C52F' },
    { min: 40, max: 50, color: '#AEEC00' },
    { min: 30, max: 40, color: '#F2DA01' },
    { min: 20, max: 30, color: '#F9B300' },
    { min: 0, max: 20, color: '#F91E00' }
  ];

  constructor(
    private router: Router,
    private answerContentHRScoreService : AnswerContentHRScoreService
  ){

  }
  ngOnInit() {
    this.fullValue = Math.floor(this.vinterScore);
    this.fractionValue = this.vinterScore - this.fullValue;
    this.valueToAdded = 1 - this.fractionValue;
    this.initialValue = this.vinterScore;
    this.value = this.vinterScore;   
    this.firstAIScore = this.vinterScore;
    this.getChangedScoreData(this.answerContentID, this.applicantID);
  }

  sortByCreatedAt() {
    this.hrScoreDetails.sort((a, b) => {
      const dateA = new Date(a.createdAt).getTime();
      const dateB = new Date(b.createdAt).getTime();
      return this.sortAscending ? dateA - dateB : dateB - dateA;
    });
    this.sortAscending = !this.sortAscending;
  }
  onScoreDifferenceChange(newDifference: number) {
    this.scoreChange = newDifference; 
    this.scoreChangeChange.emit(this.scoreChange); 
  }
  IsAddedHRComment(){
    this.isCommentAdded = !this.content;
    this.isDataChanged = this.isCommentAdded;
    return this.isCommentAdded;
  }
  onSliderChange(event: any): void {
      this.value = event.value;
      this.difference = this.value - this.initialValue;
      const isScoreChanged = this.lastChangedScore !== this.value;
      this.isDataChanged = isScoreChanged;
      this.updateSliderStyles();
  }

getSliderColor(value: number): string {
  const range = this.colorRanges.find(r => value >= r.min && value <= r.max);
  return range ? range.color : '#ECECEC';
}

private updateSliderStyles(): void {
  const sliderElement: HTMLElement | null = document.querySelector('.custom-slider .p-slider-range');
  if (sliderElement) {
      const color = this.getSliderColor(this.value);
      sliderElement.style.backgroundColor = color;
  }
}
hideSidebarEmit() {
  this.scoreChangeSidebarVisible = false;
  this.sidebarClosed.emit();
}
hideSidebar() {
  this.sidebarClosed.emit();
}
showEditor(){
  this.isEditorHidden = false;
}
previousButton() {
  this.onScoreDifferenceChange(this.difference);
  this.changeScoreSidebarVisible = false;
  this.changeScoreSidebarVisibleChange.emit(this.changeScoreSidebarVisible);
}
saveButton(){
  if(this.isDataChanged){
    const postData: AnswerContentHRScoreItems = {
      scoreChange: this.difference,
      hrComment: this.cleanHtmlTags(this.content),
      answerContentID: this.answerContentID,
      applicantID: this.applicantID,
  
    };
    this.answerContentHRScoreService.setAsnwerContentHRScoreChange(postData).subscribe(() => {
      const filter = { answerContentID: this.answerContentID, applicantID: this.applicantID };
      this.getChangedScoreData(this.answerContentID, this.applicantID);
      this.isScoreChanged = true;
      this.isScoreChangedChange.emit(this.isScoreChanged);
    });
  }
}
checkEditorContent(): void {
  const cleanContent = this.cleanHtmlTags(this.content);
  this.isCommentAdded = !!cleanContent && cleanContent.trim().length > 0 && this.content != null;
  this.isDataChanged = this.isCommentAdded;
}

cleanHtmlTags(input: string): string {
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return (doc.body.textContent ?? false) && doc.body.textContent != 'undefined' ? doc.body.textContent : '';
}

calculateValue(scoreChange): number {
  if ((this.vinterScore < this.vinterScore + scoreChange) && this.fractionValue != 0) {
    return this.vinterScore + scoreChange + this.valueToAdded;
  } else {
    return this.vinterScore + scoreChange - this.fractionValue;
  }
}

getChangedScoreData(answerContentID, applicantID){
  const filter = { answerContentID: answerContentID, applicantID: applicantID };
  this.answerContentHRScoreService.getChangedScoreData(filter).subscribe((response: AnswerContentHRScoreResponse[]) => {
    this.hrScoreDetails = response;
    if (this.hrScoreDetails && this.hrScoreDetails.length > 0) {
      if((this.vinterScore < this.vinterScore + this.hrScoreDetails[0].scoreChange) && this.fractionValue != 0){
        this.value = this.vinterScore + this.hrScoreDetails[0].scoreChange + this.valueToAdded;
      }
      else{
        this.value = this.vinterScore + this.hrScoreDetails[0].scoreChange - this.fractionValue;
      }
      this.lastChangedScore = this.value;
    }
  });
}
isCorrectAnswer(questionChoices, answer){
  return questionChoices.find(x => x.id === answer).isCorrect;
}

}
