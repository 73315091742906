import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-circle-chart',
  templateUrl: './circle-chart.component.html',
  styleUrls: ['./circle-chart.component.scss']
})
export class CircleChartComponent {
  @Input() calculatedProgressValue: number = 0;
  @Input() strokeDasharray: number = 0;
  @Input() progressValue: number = 0;
  @Input() strokeColor: string;


}