<div class="col-12 flex pt-0 pb-0 pl-0">
    <div class="col-8 pt-0 pl-0 pb-0 flex">
        <div class="col-2 pt-0 pr-0 pl-0 question-icon-width">
            <div class="flex">
                <img class="custom-svg-question-icon align-self-center"
                    src="/assets/vinter/icons/openEndedQuestion.svg" />
                <img *ngIf="question?.questionCategory === eQuestionCategories.Writing" width="30px" height="30px"
                    class="align-self-center ml-2" src="assets/vinter/icons/pdfReport/writingCategoryColor.svg" />
            </div>
        </div>
        <div class="col-10 pt-0 pl-1 ">
            <h5 style="margin-top: 0.6rem">
                {{"Key.QuestionOrder" | translate: { value1: question?.order } }}
            </h5>

        </div>
    </div>
</div>
<app-applicant-answer-sidebar-question-area-language [question]="question" [questionKeywords]="questionKeywords"
    [behaviourEventLogs]="behaviourEventLogs"></app-applicant-answer-sidebar-question-area-language>
<div class="answer-text">
    <ng-container>
        <div class="flex">
            <img src="assets/vinter/icons/text-questioncoktan-secmeli-cevap-icon-arrow-back-up.svg">
            <h5 class="text-justify answer-title text-lg ml-2 mb-2 m-3 h5-font">
                {{'Key.Answer' | translate}}
            </h5>
        </div>

        <div *ngIf="answerContent ?? false" class="answer-container flex">
            <div class="answer-area col-6">
                <span *ngFor="let word of erroredDataText" [style.background-color]="word.class" class="question-answer line-height-3"
                    tooltipPosition="top"
                    [ngClass]="{'text-white errored-text': word.isError,'whiteSpace': word.word.trim() === ''}"
                    (mouseover)="errorPopup(word, op,$event)" (mouseleave)="op.hide()"
                    tooltipStyleClass="pronunciationTooltip">
                    {{ word.word }}
                </span>
                <p-overlayPanel #op>
                    <ng-template pTemplate="content">
                        <div class="flex flex-wrap">
                            <div *ngFor="let error of errorPopupData" class="w-full">
                                <span class="text-gray-600 text-s font-semibold">{{'Key.'+error.errorCategory |
                                    translate}}</span>
                                <p class="text-gray-600 text-xs font-normal mb-1">
                                    {{"("+error.ruleID+")"}}</p>
                                <p class="text-red-500 text-xs font-normal line-through font-italic mb-0 mt-3">
                                    {{error.wrongText}}</p>
                                <p class="text-blue-500 text-xs font-normal mb-0">{{error.description}}</p>
                            </div>
                        </div>
                    </ng-template>
                </p-overlayPanel>
            </div>
            <div class="vertical-line-rating"></div>

            <div class="answer-area col-6 flex flex-wrap">
                <div class="grid w-full flex flex-wrap">
                    <div class="w-full">
                        <div class="w-full flex">
                            <div class="col-21">
                                <p class=" font-bold text-gray-700"> {{'Key.Errors' | translate}}</p>
                            </div>
                        </div>
                        <ng-container *ngIf="isErrorNotFound">
                            <div class="flex w-h-fill-error absolute z-5 align-items-center ">
                                <div class="col-3">
                                </div>
                                <div
                                    class="col-6 border-round-md h-10rem p-5 bg-white flex align-items-center justify-content-center flex-wrap  ">
                                    <img src="assets/vinter/icons/text-grammar-error-regular.svg" width="18px"
                                        height="18px">
                                    <span class="text-gray-700 font-bold text-center ">
                                        {{'Key.ErrorNotFound' | translate}}</span>
                                </div>
                                <div class="col-3"></div>
                            </div>
                        </ng-container>
                        <div class="w-full flex" [ngClass]="isErrorNotFound ? 'blur':'' "
                            *ngFor="let item of filterErrorArray">
                            <div class="col-10 flex">
                                <p-badge class="pr-1" [ngClass]="item.label" [value]="item.number"></p-badge>
                                <p class="font-semibold" [ngClass]="item.isSelected ? 'text-gray-600':'text-gray-400'">
                                    {{'Key.'+item.label | translate}}</p>
                            </div>
                            <div class="col-2">
                                <p-inputSwitch [(ngModel)]="item.isSelected" (onChange)="getErroredAnswerText()">
                                </p-inputSwitch>
                            </div>

                        </div>


                    </div>
                    <hr class="w-full">
                    <div class="ml-5 flex" *ngIf="questionPageHiddenLog > 0">
                        <img src="assets/vinter/icons/cheating-icon.svg">
                        <p class="ml-2" style="color: #F2A105;">{{"Key.SwitchTab" | translate: {value: questionPageHiddenLog} }}</p>
                    </div>
                    <hr *ngIf="!isWritingAnalysisEmpty" class="w-full">
                    <div class="w-full">
                        <div class="col-12 align-content-center custom-display flex flex-wrap">
                            <div class="w-full">
                                <div class="flex w-full" [ngClass]="isWritingAnalysisEmpty ? 'blur':'' ">
                                    <div class="col-6 pl-0 pr-0 font-bold text-gray-700">
                                        {{'Key.WritingScore' | translate}}
                                    </div>
                                    <div class="col-5 pr-0 flex align-items-center">
                                        <p-progressBar class="blue-600 w-full"
                                            [value]="answerContent?.writingAnalysis ?? false ? answerContent?.writingAnalysis?.finalScore : 0"
                                            [showValue]="false"></p-progressBar>
                                    </div>
                                    <div class="col-1 pl-1 pr-0 font-bold	text-blue-700 text-xs">
                                        <p>{{(answerContent?.writingAnalysis?.finalScore ?? false) ? answerContent?.writingAnalysis?.finalScore?.toFixed(0):0}}
                                        </p>
                                    </div>
                                </div>
                                <div class="w-full pl-1 flex align-items-center justify-content-center">

                                    <div *ngIf="this.answerContent.gptEvalStatus === gptEvalStatusEnum.Retry" class="retry-container w-h-fill absolute z-5 align-items-center">
                                        <div class="grid">
                                            <div class="col-12 flex justify-content-center">
                                                <img src="../../../../../assets/vinter/icons/gpt-sleep.svg" class="w-4rem pt-3" >
                                            </div>
                                            <div class="col-12 flex justify-content-center text-gray-700 font-medium pt-0 text-center">
                                                {{"Key.GptRetryMessage" | translate}}
                                            </div>
                                            <div class="col-12 flex justify-content-center">
                                                <img src="../../../../../assets/vinter/icons/retry-icon.svg" (click)="setGptStatus()" class="w-1rem mr-2 cursor-pointer">
                                                <p class="text-blue-500 cursor-pointer" (click)="setGptStatus()">{{"Key.Retry" | translate}}</p>
                                            </div>

                                        </div>
                                    </div>

                                    <div *ngIf="this.answerContent.gptEvalStatus !== gptEvalStatusEnum.Retry && isWritingAnalysisEmpty" class="flex w-h-fill absolute z-5 align-items-center ">
                                        <div class="col-4">
                                        </div>
                                        <div
                                            class="col-4 w-h-min p-5 bg-white flex align-items-center justify-content-center flex-wrap  ">
                                            <img src="assets/vinter/icons/text-grammar-error-regular.svg" width="25px"
                                                height="25px">
                                            <span class="text-gray-700 font-bold text-center pt-2">
                                                <div [ngSwitch]="answerContent.gptEvalStatus">
                                                    <div *ngSwitchCase="gptEvalStatusEnum.ClientFail">
                                                        {{ answerContent.aiFinalLog }}
                                                    </div>
                                                    <div *ngSwitchCase="gptEvalStatusEnum.Fail">
                                                        {{"Key.GptFailStatusMessage" | translate}}
                                                    </div>
                                                    <div *ngSwitchCase="gptEvalStatusEnum.Waiting">
                                                        {{"Key.WaitForAIComment" | translate}}
                                                    </div>
                                                    <div *ngSwitchCase="gptEvalStatusEnum.VideoFail">
                                                        {{"Key.WaitForAIComment" | translate}}
                                                    </div>
                                                    <div *ngSwitchDefault>
                                                        {{'Key.ErrorNotFound' | translate}}
                                                    </div>

                                                </div>
                                            </span>
                                        </div>
                                        <div class="col-4"></div>
                                    </div>
                                    <p-accordion [ngClass]="isWritingAnalysisEmpty ? 'blur':'' " class="w-full"
                                        [activeIndex]="0">
                                        <ng-container *ngFor="let item of writingSubtitles; let i = index">
                                            <p-accordionTab>
                                                <ng-template pTemplate="header">
                                                    <div class="flex align-items-center w-full">
                                                        <div class="col-6 flex ">
                                                            <div
                                                                class="flex justify-content-center align-items-center col-1 ">
                                                                <img src="assets/vinter/icons/info-fill.svg "
                                                                    [pTooltip]="item.info" width="12px" height="12px">
                                                            </div>
                                                            <div class="col-11 text-sm -ml-2">{{item.title}}</div>
                                                        </div>
                                                        <div
                                                            class="col-5 pr-0 pl-1 flex align-items-center progress-sub">
                                                            <p-progressBar class="blue-600 w-full"
                                                                [value]="item?.score ?? false ? item?.score:0"
                                                                [showValue]="false"></p-progressBar>
                                                        </div>
                                                        <div class="col-1 pl-1 pr-0 font-bold	 text-xs text-blue-700">
                                                            <p>{{item?.score?.toFixed(0)}}</p>
                                                        </div>

                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="content">
                                                    <p class="m-0">
                                                        {{item?.evalText}}
                                                    </p>
                                                </ng-template>
                                            </p-accordionTab>
                                        </ng-container>
                                    </p-accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>



        <div class="ml-5 flex" *ngIf="answerContent === null">
            <img src="assets/vinter/icons/not-answered.svg">
            <p class="ml-2" style="color: #686E75;">{{"Key.QuestionNotAnswered" | translate}}</p>
        </div>
    </ng-container>

</div>
