import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocalizationService } from '@app/core';
import { vinterPureLogoRotatedPath } from '@app/shared/common/constants';
import { EApplicantBehaviourEventType, ERatingType, QuestionExpectedAnswerType } from '@app/shared/common/enums';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { AnswerService } from '@app/vinter/services/answer.service';
import { ApplicantBehaviourEventLogService } from '@app/vinter/services/applicant-behaviour-event-log.service';
import { ApplicationStepService } from '@app/vinter/services/application-step.service';
import { QuestionService } from '@app/vinter/services/question.service';
import { RatingService } from '@app/vinter/services/rating.service';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-applicant-step-language',
  templateUrl: './applicant-step-language.component.html',
  styleUrls: ['./applicant-step-language.component.scss']
})
export class ApplicantStepLanguageComponent implements OnInit{

  @Input() item: any;
  @Input() applicantId: any;
  @Input() applicationId: any;
  filteredList: any[] = []; // Filtrelenmiş liste için kullanılacak
  originalList: any[] = []; // Filtrelemeden önceki orijinal liste
  QuestionExpectedAnswerType = QuestionExpectedAnswerType;
  cheatingQuestionLog: any[]
  stepBehaviourEventLogs=[];
  selectedStars: any[];
  @Output() sendAverageRating: EventEmitter<number> = new EventEmitter<number>();
  

  constructor(
    private eventLogService:ApplicantBehaviourEventLogService,
    private questionService: QuestionService,
    public api: VgApiService) { }

  ngOnInit(): void {
    this.originalList = this.item.positionStep.questionCollection.questionCollectionQuestionRelations;
    this.filteredList = this.originalList;
    this.getApplicantBehaviourEventLog(this.item.id);
  }

  filterItems(event){
    this.filteredList = event;
  }

    setCheatingLogs(event){
        this.cheatingQuestionLog = event;
    }


  getApplicantBehaviourEventLog(applicantStepId){
    this.stepBehaviourEventLogs = [];
    let eventLogFilter: any = {};
    eventLogFilter.applicationStepId = applicantStepId;
    this.eventLogService.getListByFilter(eventLogFilter).subscribe((logResult) => {
      this.stepBehaviourEventLogs = logResult;
      let questionCollectionRelation = this.item.positionStep.questionCollection.questionCollectionQuestionRelations;
      this.cheatingQuestionLog = questionCollectionRelation
        .map(item1 => ({
          ...item1,
          eventTypes: this.stepBehaviourEventLogs
            .filter(item2 => item1.questionID === item2.questionId && (item2.eventType === EApplicantBehaviourEventType.PageHidden))
            .map(log => log.eventType)
        }))
        .filter(item => item.eventTypes.length > 0);

      this.selectedStars = this.item.positionStep.questionCollection.questionCollectionQuestionRelations.map(item => {
        return {
          questionId: item.questionID,
          answerId: item.question.answerId,
          score: item.question.ratingScore
        }
      });

    });
}

updateAverageSteps() {
    this.sendAverageRating.emit(0);
  }
}
