import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LocalizationService } from '@app/core';
import { EQuestionCollectionLanguages, EQuestionContentURLType, EntityStateEnum, QuestionCollectionCategoryEnum, QuestionExpectedAnswerType } from '@app/shared/common/enums';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { QuestionCollectionFormItem } from '@app/vinter/models/question-collection-form';
import { QuestionListItem } from '@app/vinter/models/question-list-item';
import { QuestionCollectionQuestionCustomItem } from '@app/vinter/models/shared/question-collection-question-custom-item';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-your-question-list-preview',
    templateUrl: './your-question-list-preview.component.html',
    styleUrls: ['./your-question-list-preview.component.scss'],
    providers: [MessageService]
})
export class YourQuestionListPreviewComponent {
    previewQuestions: QuestionListItem[] = [];
    questionCollectionLanguageList: any[] = [];

    VideoAnswerType: number;
    MultipleChoiceAnswerType: number;
    OpenEndedAnswerType: number;
    isDragDrop: boolean = true;
    QuestionContentType = EQuestionContentURLType;
    questionExpectedAnswerTypeEnum = QuestionExpectedAnswerType;
    selectedRadioValue: any;
    language: string;
    eQuestionCollectionType = QuestionCollectionCategoryEnum;
    @Output() goBackOneStep: EventEmitter<any> = new EventEmitter<any>();
    @Output() closeDialog: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() selectedQuestionsFromQuestionCollection: QuestionListItem[];
    @Input() createdQuestions: QuestionListItem[];
    @Input() createdQuestionCollection: QuestionCollectionFormItem;
    @Output() callCloseDialog: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSaveDialog: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() saveQuestionCollectionId: EventEmitter<string> = new EventEmitter<string>();
    @Output() onSaveQuestionCollectionQuestions: EventEmitter<QuestionCollectionQuestionCustomItem> = new EventEmitter<QuestionCollectionQuestionCustomItem>();
    @Input() saveQuestionListCategory;

    constructor(
        private messageService: MessageService,
        private localizationService: LocalizationService,
        private appInfoService: AppInfoService,
        private translateService: LocalizationService
    ) { }

    ngOnInit(): void {
        this.selectedQuestionsFromQuestionCollection;
        this.VideoAnswerType = QuestionExpectedAnswerType.Video;
        this.MultipleChoiceAnswerType = QuestionExpectedAnswerType.MultipleChoice;
        this.OpenEndedAnswerType = QuestionExpectedAnswerType.OpenEnded;
        this.questionCollectionLanguageList = this.appInfoService.getQuestionCollectionLanguageList();
        this.language = this.questionCollectionLanguageList.find(x => x.id === this.createdQuestionCollection.languageId).name;
        this.connectQuestions();
    }


    getShortLangText(languageID){
        const keyName = this.questionCollectionLanguageList.find(x=> x.id === languageID)?.name;
        const text = keyName ?? false ? this.translateService.translatedTextList['QCollectionShort'+keyName] : '';
        return text;
    }

    connectQuestions() {
        this.selectedQuestionsFromQuestionCollection.forEach(element => {
            element.langShort = this.getShortLangText(element.languageID);
            this.previewQuestions.push(element);
        });

        if (this.saveQuestionListCategory === QuestionCollectionCategoryEnum.ForeignLanguage
            || this.saveQuestionListCategory === QuestionCollectionCategoryEnum.CognitiveAbility
            || this.saveQuestionListCategory === QuestionCollectionCategoryEnum.Pronunciation
            || this.saveQuestionListCategory === QuestionCollectionCategoryEnum.PersonalityTest) {
            this.isDragDrop = false;
        }
    }

    saveAllData() {
        if (this.previewQuestions?.length > 0) {
            this.previewQuestions = this.previewQuestions.map((item, i) => {
                return { ...item, order: i + 1 };
            });

            this.buildQuestionStructure();
            let questionCollectionQuestionCustom = new QuestionCollectionQuestionCustomItem();
            questionCollectionQuestionCustom.questionCollection = this.createdQuestionCollection;
            questionCollectionQuestionCustom.questionCollection.isTemprature = true;
            questionCollectionQuestionCustom.questions = this.previewQuestions;
            this.onSaveQuestionCollectionQuestions.emit(questionCollectionQuestionCustom)

        }
        else {
            this.messageService.add({
                severity: 'error',
                summary: this.localizationService.translatedTextList.Error,
                detail: this.localizationService.translatedTextList.CanNotSaveQuestionCollectionWithoutQuestions,
                life: 3000
            });
        }
    }

    buildQuestionStructure() {
        this.previewQuestions.forEach(element => {
            delete element['xmin'];
            delete element['id'];
            element.entityState = EntityStateEnum.Added;

            delete element.questionContent['id'];
            delete element.questionContent['xmin'];
            delete element.questionContent['questionID'];
            element.questionContent.entityState = EntityStateEnum.Added;

            if (element.expectedAnswerType == this.MultipleChoiceAnswerType) {
                element.choiceContents.forEach(x => {
                    delete x['id'];
                    delete x['xmin'];
                    delete x['questionId'];
                    x.entityState = EntityStateEnum.Added;
                });
            }
            if (element.expectedAnswerType == this.OpenEndedAnswerType || element.expectedAnswerType == this.VideoAnswerType) {
                element.keywords.forEach(x => {
                    delete x['id'];
                    delete x['xmin'];
                    delete x['questionId'];
                    delete x['positionId'];
                    x.entityState = EntityStateEnum.Added;
                });
            }
        });
    }

    toggleDisplay(question:any) {
        question.showMoreText = !question.showMoreText;
    }
}
