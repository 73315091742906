import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService, HttpBaseService, LocalizationService, NotifyService } from '@app/core';
import { ApiControllerEnum } from '@app/shared/common/enums';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeneralApplicationFormService extends HttpBaseService{

  constructor(private http: HttpClient
    , private notifyServ: NotifyService
    , private localizationService: LocalizationService
    , private configService: ConfigurationService) {
    super(http, ApiControllerEnum.GeneralApplicationForm, notifyServ, localizationService, configService);
  }

  //old version
  getApplicantFormWithQuestionAndAnswers(formId): Observable<any> {
    let params = new HttpParams();
    params = params.append('formId', formId);
    return this.getWithParams("GetApplicantFormWithQuestionAndAnswers", params);
  }

  //applicant detail v2
  getApplicationFormWithQuestionAndAnswers(applicationId: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('applicationId', applicationId);
    return this.getWithParams("GetApplicantGeneralApplicationFormWithQuestionAndAnswers", params);
  }

}
