import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { EntityStateEnum, UserGroupEnum } from '@app/shared/common/enums';
import { InviteApplicantComponent } from '@app/vinter/components/invite-applicant/invite-applicant/invite-applicant.component';
import { InvitationPostItem } from '@app/vinter/models/Invitation/invitation-post-item';
import { InvitationService } from '@app/vinter/services/invitation.service';
import { PositionService } from '@app/vinter/services/position.service';
import { GeneralApplicationInvitationService } from '@app/vinter/services/general-application-invitation.service';


@Component({
  selector: 'app-suggest-applicant-v2',
  templateUrl: './suggest-applicant.component.html',
  styleUrls: ['./suggest-applicant.component.scss']
})
export class SuggestApplicantComponentV2 {

  @Input() sidebarVisible: boolean;
  @Input() applicationId: string;

  openPositions: any[] = [];
  columns: any[] = [];
  selectedPosition: any;
  userGroupID: string = UserGroupEnum.APPLICANT;
  invitationList: InvitationPostItem[] = [];
  invitedMessage: boolean = false;
  organizationId: string;

  @ViewChild(InviteApplicantComponent) inviteApplicantChild: InviteApplicantComponent;
  @Output() suggestedApplicant: EventEmitter<any> = new EventEmitter<any>();




  constructor(
    private generalApplicationInvitationService: GeneralApplicationInvitationService,
    private positionService: PositionService,
  ) { }

  ngOnInit(): void {
    this.getPositionList();
    this.setTableColumns();
  }

  suggestApplicant() {
    this.organizationId = this.openPositions.find(x => x.id === this.selectedPosition).organizationID;

    let invitationPostDto: InvitationPostItem = {
      entityState: EntityStateEnum.Added,
      email: "asd",
      organizationID: this.organizationId,
      positionID: this.selectedPosition,
      userGroupID: this.userGroupID
    }
    this.invitationList.push(invitationPostDto);


    this.generalApplicationInvitationService.createGeneralApplicationInvitation(this.invitationList).subscribe((response: any) => {
      if (response) {

      }
      else{
        this.sidebarVisible = false;
        this.invitedMessage = true;
        this.suggestedApplicant.emit(true);
      }

    });

  }
  closeSidebar(){
    this.selectedPosition = null;
  }

  closeDialog() {
    this.invitedMessage = !this.invitedMessage;
  }


  setTableColumns() {
    this.columns = [
      { field: 'count', header: '#', width: '5%' },
      { field: 'title', header: 'Position', width: '25%' },
      { field: 'organizationName', header: 'Department', width: '20%' },
      { field: 'activatedDate', header: 'StartDate', width: '20%' },
      { field: 'responsibleName', header: 'Responsible', width: '25%' }
    ];
  }

  getPositionList() {
    this.positionService.GetListWithOrganizationAndResponsibleForSuggest({applicationId: this.applicationId}).subscribe(resp => {
      let positions = resp;
      positions.forEach(element => {
        element.isActive = element.isActive ? true : false;
      });

      this.openPositions = positions.filter(item => item.isClosed === false && item.isActive === true).map((item, index) => { return { ...item, overlayVisible: false, count: index + 1 }; });
    })
  }


}
