<div class="grid">
    <div class="answer-text col-9">
        <ng-container>
            <div class="step-container">
                {{"Key.StepComment" | translate}}
            </div>
        </ng-container>
    </div>
    <div class="col-3">
        <div class="formgroup-inline justify-content-end">
            <div class="field-radiobutton">
                <h5 class="h5-font inline">{{"Key.Rating" | translate}}: {{item.averageRating}}</h5>
            </div>
            <div class="field-radiobutton">
                <i class=" pi pi-star-fill text-4xl rate-color -mt-2 cursor-pointer"></i>
            </div>
        </div>
    </div>
</div>
