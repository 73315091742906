

<p-sidebar [(visible)]="sidebarVisible"  (onHide)="hideSidebarEmit()" position="right" [showCloseIcon]="false">



    <div class="col-12 flex pl-0 pb-0">
        <div class="col-6 flex pl-0 pt-1">
            <p-button class="back-button" icon="pi pi-arrow-left" (onClick)="getAnswerByQuestionId(questionOrder - 1)"></p-button>
            <p-button class="next-button" icon="pi pi-arrow-right" (onClick)="getAnswerByQuestionId(questionOrder+1)"></p-button>
        </div>

        <div class="col-6 flex justify-content-end pt-1">
            <div *ngIf="answerType ===QuestionExpectedAnswerType.MultipleChoice " class="question-container-rating pt-4">
                <div *ngIf="choiceAnswers === null || choiceAnswers === undefined">
                    <div class="progress margin-top-04">
                        <div class="progress-bar" [style.width]="0 + '%'"
                            [style.backgroundColor]="getProgressBarColor(0)"></div>
                    </div>
                    <h5 class="h5-font flex justify-content-center trust-vinter-text" style="color: #363D47; margin-top: 0.75rem">
                        {{0}}</h5>
                </div>
                <div *ngIf="choiceAnswers !== null && choiceAnswers !== undefined">
                    <div *ngIf="choiceAnswers.isCorrect === true">
                        <div class="progress margin-top-04">
                            <div class="progress-bar" [style.width]="100 + '%'"
                                [style.backgroundColor]="getProgressBarColor(100)"></div>
                        </div>
                        <h5 class="h5-font flex justify-content-center trust-vinter-text" style="color: #363D47; margin-top: 0.75rem">
                            {{100}}</h5>
                    </div>
                    <div *ngIf="choiceAnswers.isCorrect === false">
                        <div class="progress margin-top-04">
                            <div class="progress-bar" [style.width]="0 + '%'"
                                [style.backgroundColor]="getProgressBarColor(0)"></div>
                        </div>
                        <h5 class="h5-font flex justify-content-center trust-vinter-text" style="color: #363D47; margin-top: 0.75rem">
                            {{0}}</h5>
                    </div>
                </div>
            </div>

            <div *ngIf="answerType ===QuestionExpectedAnswerType.OpenEnded && answerContent ?? false" class="flex">
                <div *ngIf="answerContent?.gptEvalStatus !== gptEvalStatusEnum.Waiting" class="col-4 flex question-container-rating" style="min-width: 11rem;">

                    <div *ngIf="questionData.ratingType !== RatingType.VinterTrust" class="flex w-full flex-wrap justify-content-center ">
                        <div class="w-full flex justify-content-center">
                            <img src='{{vinterLogo}}' alt="Vinter logo" class="w-2">
                        </div>
                        <h5 class="h5-font mt-1 trust-vinter-text  w-full cursor-pointer text-center"
                        (click)="getAnswerForRatingByQuestionId(questionData,false, RatingType.VinterTrust)">{{'Key.TrustVinter' | translate}}</h5>
                    </div>
                    <div *ngIf="questionData.ratingType === RatingType.VinterTrust">
                        <i class="pi pi-check text-xl ml-6" style="color: #2962FF"></i>
                        <h5 class="h5-font mt-1 trust-vinter-text ml-1">{{'Key.VinterTrusted' | translate}}</h5>
                    </div>

                </div>

                <div *ngIf="answerContent?.gptEvalStatus !== gptEvalStatusEnum.Waiting" class="vertical-line-rating"></div>

                <div *ngIf="answerContent?.gptEvalStatus !== gptEvalStatusEnum.Waiting" class="col-3 flex flex-wrap question-container-rating">

                    <app-vinter-rating
                    class="w-full pr-1"
                    tooltipPosition="top"
                    pTooltip='{{"Key.HRPoint" | translate}}'
                    [starNumber]="1"
                    [disabled]="true" size="sm" [value]="questionData?.ratingScore === 0 ? 0 : 1"
                    (click)="getAnswerForRatingByQuestionId(questionData,true, RatingType.HrPoint)"></app-vinter-rating>

                    <h5 *ngIf="questionData?.ratingScore === 0" class="w-full h5-font-list mt-2 trust-vinter-text-list flex rate-text-list justify-content-center"
                    (click)="getAnswerForRatingByQuestionId(questionData,true, RatingType.HrPoint)"
                    style="color: #363D47;">
                    {{'Key.Rate' | translate}}
                    </h5>
                    <h5 *ngIf="questionData?.ratingScore !== 0" class="w-full h5-font-list mt-2 trust-vinter-text-list rate-text-list flex justify-content-center" style="color: #363D47;">{{questionData?.ratingScore}}
                    </h5>

                </div>

                <div *ngIf="answerContent?.gptEvalStatus !== gptEvalStatusEnum.Waiting" class="vertical-line-rating"></div>

                <div class="col-4 question-container-rating">
                    <div class="progress margin-top-04">
                        <div class="progress-bar" [style.width]="gptEvalScore + '%'"
                            [style.backgroundColor]="getProgressBarColor(gptEvalScore)"></div>
                    </div>
                    <h5 class="h5-font flex justify-content-center trust-vinter-text" style="color: #363D47; margin-top: 0.75rem">
                        {{gptEvalScore.toFixed(2)}}</h5>
                </div>

            </div>

            <div *ngIf="answerType ===QuestionExpectedAnswerType.Video" class="flex">
                <div *ngIf="answerContent?.gptEvalStatus !== gptEvalStatusEnum.Waiting" class="col-4 flex question-container-rating" style="min-width: 11rem;">

                    <!-- <div *ngIf="questionRatingType !== RatingType.VinterTrust">
                        <img src='{{vinterLogo}}' alt="Vinter logo" class="w-2" style="margin-left: 3.5rem">
                        <h5 class="h5-font mt-1 trust-vinter-text ml-3 cursor-pointer"
                            (click)="trustVinter(answerContent.id)">{{"Key.TrustVinter" | translate}}</h5>
                    </div>
                    <div *ngIf="questionRatingType === RatingType.VinterTrust">
                        <i class="pi pi-check text-xl ml-6" style="color: #2962FF"></i>
                        <h5 class="h5-font mt-1 trust-vinter-text ml-1">{{"Key.VinterTrusted" | translate}}</h5>
                    </div> -->

                </div>

                <div *ngIf="answerContent?.gptEvalStatus !== gptEvalStatusEnum.Waiting" class="vertical-line-rating"></div>

                <div *ngIf="answerContent?.gptEvalStatus !== gptEvalStatusEnum.Waiting" class="col-3 flex question-container-rating">

                    <!-- <div *ngIf="selectedStar === 0">
                        <app-vinter-rating
                            tooltipPosition="top"
                            pTooltip='{{"Key.HRPoint" | translate}}'
                            (click)="showPopup($event,answer?.id)"
                            [starNumber]="1"
                            [disabled]="true" size="sm" [value]="1"></app-vinter-rating>
                        <h5 class="h5-font mt-2 trust-vinter-text" style="color: #363D47;text-align: right;">{{"Key.Rate" | translate}}</h5>
                    </div>
                    <div *ngIf="selectedStar !== 0" class="ml-4">
                        <app-vinter-rating
                        tooltipPosition="top"
                        pTooltip='{{"Key.HRPoint" | translate}}'
                        (click)="showPopup($event,answer?.id)"
                        [starNumber]="1"
                        [disabled]="true" size="sm" [value]="0"></app-vinter-rating>
                        <h5 class="h5-font mt-2 ml-1 trust-vinter-text" style="color: #363D47;">{{selectedStar}}
                            </h5>
                    </div> -->

                </div>

                <div *ngIf="answerContent?.gptEvalStatus !== gptEvalStatusEnum.Waiting" class="vertical-line-rating"></div>

                <div class="col-4 question-container-rating">
                    <div class="progress">
                        <div class="progress-bar" [style.width]="gptEvalScore + '%'"
                            [style.backgroundColor]="getProgressBarColor(gptEvalScore)"></div>
                    </div>
                    <h5 class="h5-font flex justify-content-center trust-vinter-text" style="color: #363D47; margin-top: 0.75rem">
                        {{gptEvalScore.toFixed(2)}}</h5>
                </div>
            </div>
        </div>

    </div>

    <ng-container *ngIf="answerType ===QuestionExpectedAnswerType.MultipleChoice">
        <app-applicant-answer-sidebar-multiple-choice-language
        *ngIf="questionData ?? false"
        [question]="questionData"
        [choiceAnswers]="choiceAnswers"
        [behaviourEventLogs]="behaviourEventLogs"
        [questionKeywords]="questionKeywords"
        [choiceContentId]="choiceContentId"></app-applicant-answer-sidebar-multiple-choice-language>
    </ng-container>

    <ng-container *ngIf="answerType ===QuestionExpectedAnswerType.OpenEnded" >
       <app-applicant-answer-sidebar-open-ended-language
       *ngIf="questionData ?? false"
        [question]="questionData"
        [answerContent]="answerContent"
        [behaviourEventLogs]="behaviourEventLogs"
        [questionKeywords]="questionKeywords"></app-applicant-answer-sidebar-open-ended-language>
    </ng-container>

    <ng-container *ngIf="answerType ===QuestionExpectedAnswerType.Video">
        <app-applicant-answer-sidebar-video-language
        *ngIf="questionData ?? false"
        [question]="questionData"
        [answerContent]="answerContent"></app-applicant-answer-sidebar-video-language>

    </ng-container>


</p-sidebar>
