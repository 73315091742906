import { Component, EventEmitter, Input, OnInit,Output} from '@angular/core';
import { MembershipService } from '@app/vinter/services/membership.service';
import { CredentialsService } from '@app/core';
import { LicenseUnsubscribeReasonsService } from '@app/vinter/services/unsubscribe-reason.service';
import { EOrganizationSettingsMenu, LanguageEnum } from '@app/shared/common/enums';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-license-plan-unsubscribe-dialog',
  templateUrl: './license-plan-unsubscribe-dialog.component.html',
  styleUrls: ['./license-plan-unsubscribe-dialog.component.scss']
})
export class LicensePlanUnsubscribeDialogComponent implements OnInit {
  unsubscriptionReasonList: any[] = [];
  selectedReason: any;
  isSuccessfull: boolean;
  isFailed: boolean = true;
  isClicked: boolean;
  expirationDate: Date;
  isBeforeEndOfTheLicense: boolean
  currentLanguageId:string;
  LanguageEnum = LanguageEnum;
  activeTabIndex: number;

  @Input() isShow: boolean;
  @Output() isShowChange = new EventEmitter<boolean>()
  @Input() licenseOrgInfoId: any;

  
  constructor(
    public credentialService: CredentialsService,
    private licenseUnsubscribe: LicenseUnsubscribeReasonsService,
    private paymentService: MembershipService,
    private route: ActivatedRoute, 
    private router: Router
  ) { }

  @Output() dialogVisibiltyEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isUnsubscribed = new EventEmitter<any>();
  @Output() isUnsubscriptionSuccessful = new EventEmitter<any>();
  @Output() licenseExpirationDate = new EventEmitter<any>();
  @Output() refreshPage: EventEmitter<void> = new EventEmitter<void>();
  @Output() isFailedEvent = new EventEmitter<boolean>();


  ngOnInit(): void {
    this.getReasonDatas();
    this.currentLanguageId = this.credentialService.getLanguage();
  }
  onReasonChange(event: any){
    this.selectedReason= event.value;
}

getReasonDatas(){
  this.licenseUnsubscribe.getUnsubscribeReasonData(this.credentialService.getLanguage()).then((reasons) => {
    this.unsubscriptionReasonList = reasons;
});
}
  unsubsucribeLicense(licenseOrganizationInfoID) {
    this.paymentService.cancelSubsriptionAndUpdateLicenseOrganizationInfo({ licenseOrganizationInfoID }).subscribe(
        (x) => {
            this.isFailed = x.isFailed;
            this.expirationDate = x.expirationDate;
            if (this.expirationDate != null && !this.isFailed) {
                this.isUnsubscriptionSuccessful.emit(true);
            }
            this.licenseExpirationDate.emit(this.expirationDate);
            this.isFailedEvent.emit(this.isFailed);
            this.isClicked = true; 
            this.closeUnsubscriptionDialog(); 
        },
        (error) => {
            this.isFailed = true; 
            this.isClicked = true; 
            this.isFailedEvent.emit(this.isFailed);
            this.closeUnsubscriptionDialog(); 
        }
    );
}


  closeUnsubscriptionDialog() {
    this.isShowChange.emit(false);  
  }

  closeSuccessfullPopup() {
    this.isClicked = false;
    this.activeTabIndex = EOrganizationSettingsMenu.Plan;
    this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { activeTab: this.activeTabIndex },
        queryParamsHandling: 'merge'
    }).then(() => {
        window.location.reload();
    });
}


  unsubscribe(){
    this.unsubsucribeLicense(this.licenseOrgInfoId);
  }
  }
