<div class="flex flex-wrap">
    <ng-container *ngFor="let orgIntegration of positionIntegrationList; let i = index">
        <div class="flex gap-2 mr-5 align-items-center">
            <p-checkbox [binary]="true" (onChange)="checkIfAnyIntegrationChoosed(orgIntegration)" [(ngModel)]="orgIntegration.isChoosed" inputId="binary"></p-checkbox>
            <img [src]="orgIntegration.fullLogoURL" class="max-h-3rem">
        </div>
    </ng-container>
</div>

<div *ngIf="!fetchedApplicantData" class="flex justify-content-center mt-8">
    <div class="flex flex-column justify-content-center align-item-center bg-blue-50 border-round-xl gap-2 py-3 px-4 fetch-applicant-button"
        [ngClass]="{ 'fetch-applicant-button-disabled': !anyChoosedOrgIntegration }"
        (click)="getApplicantList(false)">
        <img [src]="fetchApplicantsIcon" class="h-4rem">
        <span class="text-blue-500 font-semibold text-sm">{{"Key.GetApplicants" | translate}}</span>
    </div>
</div>

<div class="w-full flex justify-content-between mt-3">
    <div class="font-semibold flex gap-1 align-items-center">
        <span *ngIf="fetchedApplicantData" class="text-gray-900">{{"Key.Applicants" | translate}}</span>
        <span *ngIf="fetchedApplicantData" class="text-blue-400">({{integrationApplicantList?.length}})</span>
        <img *ngIf="fetchedApplicantData && anyChoosedOrgIntegration" class="cursor-pointer ml-2" (click)="getApplicantList(true)" [src]="refreshIcon">
    </div>
    <div *ngIf="fetchedApplicantData && integrationApplicantList?.length > 1" class="font-medium choose-all">
        <span *ngIf="isAllChoosed" (click)="removeAllApplicant()" class="text-red-500 cursor-pointer">{{"Key.RemoveAll" | translate}}</span>
        <span *ngIf="!isAllChoosed" (click)="chooseAllApplicant()" class="text-blue-500 cursor-pointer">{{"Key.ChooseAll" | translate}}</span>
    </div>
</div>


<div *ngIf="fetchedApplicantData && anyChoosedOrgIntegration && integrationApplicantList?.length > 0" class="mt-3">
    <span class="p-input-icon-left w-full">
        <i class="pi pi-search"></i>
        <input type="text" class="border-round-md w-full search-bar" pInputText [(ngModel)]="searchTerm"
         placeholder="{{'Key.Search' | translate}}"  (ngModelChange)="onSearchChange()" />
    </span>
</div>

<div class="mt-3 w-full flex flex-column gap-2">
    <ng-container *ngFor="let applicant of integrationApplicantList; let i = index">
        <div class="flex align-items-center border-2 border-gray-50 border-round-lg p-2 justify-content-between"
        [ngClass]="{ 'border-red-100': applicant.mailItem?.isMailHasError && applicant.isChoosed}"
        [pTooltip]="applicant.mailItem?.errorMessage"  [tooltipDisabled]="!applicant.mailItem?.isMailHasError || !applicant.isChoosed" tooltipPosition="top">
             <div class="flex gap-2 align-items-center">
                <p-avatar [label]="this.getFirstAndLastLetter(applicant.applicantName)" shape="circle" styleClass="border-2 surface-border mb-1 surface-ground" class="applicantNameAvatar"></p-avatar>
                <div class="flex flex-column">
                    <span class="text-gray-900 font-medium text-lg">{{applicant.applicantName}}</span>
                    <span class="text-gray-300 text-sm">{{applicant.applicantEmail}}</span>
                </div>
             </div>
            <div class="flex gap-2 align-items-center">
                <img [src]="applicant.OrganizationIntegrationLogoURL" class="small-logo">
                <p-checkbox [binary]="true" (onChange)="addItem(applicant)" [(ngModel)]="applicant.isChoosed" inputId="binary"></p-checkbox>
            </div>
        </div>
    </ng-container>
</div>

<p-toast class="dialogToaster" position="bottom-center"></p-toast> 
