import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AICommentsIconPath } from '@app/shared/common/constants';
import { EApplicantBehaviourEventType, GPTEvalStatus } from '@app/shared/common/enums';
import { ForeignLanguageVideoAnswerItem } from '@app/vinter/models/answer/foreign-language-video-answer-item';
import { ApplicantBehaviourEventLogService } from '@app/vinter/services/applicant-behaviour-event-log.service';

@Component({
  selector: 'app-foreign-language-video-answer',
  templateUrl: './foreign-language-video-answer.component.html',
  styleUrls: ['./foreign-language-video-answer.component.scss']
})
export class ForeignLanguageVideoAnswerComponent implements OnInit{

  @Input() answer: ForeignLanguageVideoAnswerItem;
  GPTEvalStatusEnum = GPTEvalStatus;
  AICommentPathIcon = AICommentsIconPath;
  speakingWords = [];
  discourseOrgEval: string;
  fluencyEval: string;
  grammarEval: string;
  pronunciationEval: string;
  vocabAccEval: string;
  @ViewChild('speakingDiv') speakingDiv: ElementRef;
  questionPageHiddenLog: number;

constructor(private eventLogService: ApplicantBehaviourEventLogService){}

ngOnChanges(changes: SimpleChanges): void {
  if(this.answer){
    this.setSpeechAnalysisParameter();
    this.getPageHiddenLog();
  }
}

  ngOnInit(): void {
    if(this.answer){
      this.setSpeechAnalysisParameter();
      this.getPageHiddenLog();
    }
    
  }

  highlightSpeaking(target: any) {
    this.speakingWords.forEach((word, index) => {
        word.index = 'p' + index;
        word.highlighted = target.currentTime >= word.start && target.currentTime < word.end;
    });

    this.highlightSpeakingInView();
}

highlightSpeakingInView() {
    let activeWord = this.speakingWords.find(word => word.highlighted);

    if (activeWord) {
        let highlightedElement = this.speakingDiv.nativeElement.querySelector(`#p${activeWord.index}`);

        if (highlightedElement) {
            highlightedElement.scrollIntoView({
                behavior: "smooth",
                block: "nearest"
            });
        }
    }
}

setSpeechAnalysisParameter(){
  if (this.answer?.speechAnalysis != null && this.answer?.speechAnalysis?.analysisResultDetail) {
    let all = (JSON.parse(this.answer?.speechAnalysis.analysisResultDetail.toString()));
    this.discourseOrgEval = all.eval_texts.discourse_org_eval;
    this.fluencyEval = all.eval_texts.fluency_eval;
    this.grammarEval = all.eval_texts.grammar_eval;
    this.pronunciationEval = all.eval_texts.pronunciation_eval;
    this.vocabAccEval = all.eval_texts.vocab_acc_eval;
  }
}

getPageHiddenLog(){
  let eventLogFilter: any = {};
  eventLogFilter.applicationStepId = this.answer.applicationStepId;
  eventLogFilter.eventType = EApplicantBehaviourEventType.PageHidden;
  eventLogFilter.questionId = this.answer.questionId;
  this.eventLogService.getListByFilter(eventLogFilter).subscribe(eventLog => {
      this.questionPageHiddenLog = eventLog.length;
  });
}

}
