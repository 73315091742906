    <div class="col-12 flex pt-0 pb-0 pl-0">
        <div class="col-6 flex pt-0 pb-0 pl-0">
            <div class="col-2 pt-0 pr-0 pl-0 question-icon-width flex mr-4">
                <img class="custom-svg-question-icon align-self-center" src="/assets/vinter/icons/Quiz.svg" />
                <img *ngIf="question.questionCategory === questionCategoryType.Writing" width="30px" height="30px"
                    class="align-self-center ml-2" src="assets/vinter/icons/writingCategoryColor.svg" />
                <img *ngIf="question.questionCategory === questionCategoryType.Listening" width="30px" height="30px"
                    class="align-self-center ml-2" src="assets/vinter/icons/listening-icon-circled.svg" />
                <img *ngIf="question.questionCategory === questionCategoryType.Reading" width="30px" height="30px"
                    class="align-self-center ml-2" src="assets/vinter/icons/reading-icon-circled.svg" />
                <img *ngIf="question.questionCategory === questionCategoryType.Vocabulary" width="30px" height="30px"
                    class="align-self-center ml-2" src="assets/vinter/icons/vocabulary-icon-circled.svg" />
                <img *ngIf="question.questionCategory === questionCategoryType.Grammer" width="30px" height="30px"
                    class="align-self-center ml-2" src="assets/vinter/icons/grammer-icon-circled.svg" />
            </div>
            <div class="col-7 pt-0 pl-0">
                <h5 style="margin-top: 0.6rem">
                    {{"Key.QuestionOrder" | translate: { value1: question?.order } }}
                </h5>

            </div>
        </div>
    </div>
    <div [ngSwitch]="question?.questionContent?.contentURLType">

        <app-applicant-answer-sidebar-question-area-language [question]="question" [questionKeywords]="questionKeywords" [behaviourEventLogs]="behaviourEventLogs"></app-applicant-answer-sidebar-question-area-language>

        <div class="answer-text">
            <div class="flex">
                <img src="assets/vinter/icons/text-questioncoktan-secmeli-cevap-icon-arrow-back-up.svg">
                <h5 class="text-justify answer-title text-lg ml-2 m-3 h5-font">
                    {{"Key.Answer" | translate}}
                </h5>
            </div>
            <div class="answer-container">
                <div class="flex">
                    <div class="flex flex-column gap-2">
                        <div *ngFor="let choice of question.choiceContents; let i = index"
                            class="radio-button-card field-checkbox "
                            [ngClass]="
                            {'correct-answer': isCorrectAnswer(choice.id), 'wrong-answer': isWrongAnswer(choice.id),
                            'correct-choice': choice.isCorrect&& selectedRadioValue!==null,
                            'empty-correct-choice': choice.isCorrect&&selectedRadioValue===null,
                            'background-white': choice.id !== choiceContentId,
                            'background-blue': choice.id === choiceContentId} ">
                            <div class="flex">
                                <p-radioButton [disabled]="true" [value]="choice"
                                    [(ngModel)]="selectedAnswerChoice"></p-radioButton>
                                <label [for]="choice" class="ml-2" [innerHTML]="choice.choiceText"></label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ml-5">
                <div class="flex" *ngIf="checkAnswer(selectedAnswer) === true">
                    <img src="assets/vinter/icons/correct-answer.svg">
                    <p class="ml-2" style="color: #3A9B18;">{{"Key.CorrectAnswer" | translate}}</p>
                </div>
                <div class="flex" *ngIf="(selectedAnswer ?? false) && checkAnswer(selectedAnswer) === false">
                    <img src="assets/vinter/icons/wrong-answer.svg">
                    <p class="ml-2" style="color: #F20505;">{{"Key.WrongAnswer" | translate}}</p>
                </div>
                <div class="flex" *ngIf="selectedAnswer === null || selectedAnswer === undefined">
                    <img src="assets/vinter/icons/not-answered.svg">
                    <p class="ml-2" style="color: #686E75;">{{"Key.QuestionNotAnswered" | translate}}</p>
                </div>
            </div>

            <div class="ml-5 flex" *ngIf="questionPageHiddenLog > 0">
                <img src="assets/vinter/icons/cheating-icon.svg">
                <p class="ml-2" style="color: #F2A105;">{{"Key.SwitchTab" | translate: {value: questionPageHiddenLog} }}</p>
            </div>


        </div>


    </div>