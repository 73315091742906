<div class="flex gap-3 flex-wrap mt-3">
    <div class="flex-nowrap flex gap-2 align-items-center" *ngIf="questionPageHiddenLog > 0">
        <img src="assets/vinter/icons/cheating-icon.svg">
        <p class="font-medium page-hidden">{{"Key.SwitchTab" | translate: {value: questionPageHiddenLog } }}</p>
    </div>
    <div *ngIf="answer?.answerContent?.isFaceDetected" class="flex-nowrap flex gap-2 align-items-center">
        <img src="assets/vinter/icons/face-detection-icon.svg" class="detect-img">
        <p class="font-medium face-detected">{{"Key.FaceDetected" | translate }}</p>
    </div>
    <div *ngIf="answer?.answerContent?.isMultiFaceDetected" class="flex-nowrap flex gap-2 align-items-center">
        <img src="assets/vinter/icons/multiface-detected-icon.svg" class="detect-img">
        <p class="font-medium multiface-detected">{{"Key.MultiFaceDetected" | translate }}</p>
    </div>
    <div *ngIf="answer?.answerContent?.improprietyAnalysis?.isImproprietySpeech"
        class="flex-nowrap flex gap-2 align-items-center">
        <img src="assets/vinter/icons/impropriety-speech-warning.svg" class="detect-img">
        <p class="font-medium impropriety-speech">{{"Key.ImproprietySpeechAnalysis" | translate }}</p>
    </div>
</div>

<div class="flex">
    <img src="assets/vinter/icons/text-questioncoktan-secmeli-cevap-icon-arrow-back-up.svg">
    <h5 class="text-justify answer-title font-semibold text-lg ml-2">
        {{"Key.Answer" | translate}}
    </h5>
</div>

<div class="flex ml-5" *ngIf="answer == null">
    <img src="assets/vinter/icons/not-answered.svg">
    <p class="ml-2 empty-answer-text">{{"Key.QuestionNotAnswered" | translate}}</p>
</div>

<div *ngIf="answer?.answerContent ?? false" class="answer-container">
    <div class="grid flex-wrap p-4 border-gray-50 border-round-lg border-1 border-solid card-container">
        <div class="custom-col-6 flex height-fit">
            <vg-player *ngIf="answer?.answerContent?.contentURL ?? false" style="border-radius: 15px;">
                <video #targetVideo [vgMedia]="$any(media)" #media id="singleVideo" preload="auto" controls
                    (timeupdate)="highlightSubtitle($event.target)">
                    <source [src]="answer?.answerContent?.contentURL" type="video/webm">
                    <source [src]="answer?.answerContent?.contentURL" type="video/mp4">
                </video>
            </vg-player>
        </div>

        <div class="custom-col-6 flex"
            [ngClass]="{ 'client-fail-panel': (answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.Retry || answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.ClientFail)}">
            <p-tabView class="w-full" [(activeIndex)]="activeIndex">
                <p-tabPanel header='{{"Key.Transcript" | translate}}'>
                    <div #transcriptDiv *ngIf="answer?.answerContent?.transcriptStatus === TranscriptStatus.Succesful">
                        <div *ngIf="subtitles.length > 0" class="text-gray-700 text-justify">
                            <span *ngFor="let subtitle of subtitles" [id]="'s' + subtitle.id"
                                (mouseover)="subtitle.hover=true" (mouseleave)="subtitle.hover=false"
                                (click)="jumpToSecond(subtitle.start);" class="subtitleText"
                                pTooltip="{{ subtitle.emotion_score !== -1 ? ('%' + (subtitle.emotion_score | number:'2.1-2') + ' ' + ('Key.'+subtitle.emotion | translate)) : ('Key.'+subtitle.emotion | translate) }}"
                                tooltipPosition="left"
                                [ngStyle]="(subtitle.highlighted || subtitle.hover) ? { 'background-color': getColor(subtitle.emotion, subtitle.emotion_score),'color':'var(--gray-900)'}: { 'background-color': 'transparent' }">
                                {{ subtitle.text }}
                            </span>
                        </div>
                        <div *ngIf="subtitles.length === 0" class="text-gray-400 text-justify">
                            <span>
                                {{"Key.NoTranscriptMessage" | translate}}
                            </span>
                        </div>
                    </div>
                    <div *ngIf="(answer?.answerContent?.transcriptStatus === TranscriptStatus.Waiting || answer?.answerContent?.transcriptStatus === TranscriptStatus.Fail) &&
                             (answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.Waiting)">
                        {{"Key.WaitForTranscript" | translate}}
                    </div>

                    <div *ngIf="answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.ClientFail">
                        <div class="client-fail-container border-2 border-solid w-15rem bg-white">
                            <div class="grid">
                                <div class="col-12 pb-0 flex justify-content-center">
                                    <img src="../../../../../assets/vinter/icons/text-grammar-error-regular.svg"
                                        class="w-2rem pt-3">
                                </div>

                                <div
                                    class="col-12 flex justify-content-center answer-text-label text-justify text-center text-base font-medium">
                                    {{ answer?.answerContent?.aiFinalLog }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="retry-container border-2 border-solid h-13rem w-15rem bg-white"
                        *ngIf="answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.Retry">
                        <div class="grid">
                            <div class="col-12 flex justify-content-center">
                                <img src="../../../../../assets/vinter/icons/gpt-sleep.svg" class="w-4rem pt-3">
                            </div>
                            <div
                                class="col-12 flex justify-content-center text-gray-700 text-sm font-medium py-0 text-center">
                                {{"Key.GptRetryMessage" | translate}}
                            </div>
                            <div class="col-12 pt-4 flex justify-content-center">
                                <img src="../../../../../assets/vinter/icons/retry-icon.svg" (click)="setGptStatus()"
                                    class="w-1rem mr-2 cursor-pointer">
                                <p class="text-blue-500 cursor-pointer" (click)="setGptStatus()">{{"Key.Retry" |
                                    translate}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="answer-text-label text-justify"
                        *ngIf="answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.Fail">
                        {{"Key.GptFailStatusMessage" | translate}}
                    </div>
                    <div *ngIf="answer.answerContent === null" class="no-questions-message font-italic text-center">
                        {{"Key.NoAnswer" | translate}}
                    </div>
                </p-tabPanel>
                <p-tabPanel header='{{"Key.VinterComment" | translate}}'>
                    <ng-container
                        *ngIf="answer.answerContent ?? false || answer?.answerContent?.gptEvalStatus !== gptEvalStatusEnum.Retry">
                        <div class="ai-answer-container p-0 border-round-md border-none ml-0 text-blue-800 text-justify"
                            *ngIf="answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.Succesful">
                            {{ answer?.answerContent?.gptEvalText }}
                        </div>
                        <div class="ai-answer-container p-0 border-round-md border-none ml-0 text-gray-400 text-justify"
                            *ngIf="answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.Waiting ||
                             answer?.answerContent?.gptEvalStatus === 99">
                            {{"Key.WaitForAIComment" | translate}}
                        </div>
                    </ng-container>
                    <div class="retry-container border-2 border-solid h-13rem w-15rem bg-white"
                        *ngIf="answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.Retry">
                        <div class="grid">
                            <div class="col-12 flex justify-content-center">
                                <img src="../../../../../assets/vinter/icons/gpt-sleep.svg" class="w-4rem pt-3">
                            </div>
                            <div
                                class="col-12 flex justify-content-center text-gray-700 text-sm font-medium py-0 text-center">
                                {{"Key.GptRetryMessage" | translate}}
                            </div>
                            <div class="col-12 pt-4 flex justify-content-center">
                                <img src="../../../../../assets/vinter/icons/retry-icon.svg" (click)="setGptStatus()"
                                    class="w-1rem mr-2 cursor-pointer">
                                <p class="text-blue-500 cursor-pointer" (click)="setGptStatus()">{{"Key.Retry" |
                                    translate}}</p>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.ClientFail">
                        <div class="client-fail-container w-15rem bg-white border-2 border-solid">
                            <div class="grid">
                                <div class="col-12 pb-0 flex justify-content-center">
                                    <img src="../../../../../assets/vinter/icons/text-grammar-error-regular.svg"
                                        class="w-2rem pt-3">
                                </div>

                                <div
                                    class="col-12 flex justify-content-center answer-text-label text-justify text-center text-base font-medium">
                                    {{ answer?.answerContent?.aiFinalLog }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="answer-text-label text-justify"
                        *ngIf="answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.Fail">
                        {{"Key.GptFailStatusMessage" | translate}}
                    </div>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>

    <hr *ngIf="answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.Succesful">

    <div *ngIf="answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.Succesful" class="grid">
        <!-- emotion analysis -->
        <div class="custom-col-6 pr-0 emotionAnalysis-container">
            <div *ngIf="answer?.emotionAnalysis"
                class="p-4 border-gray-50 border-round-lg border-1 border-solid card-container">
                <div>
                    <h5>{{ "Key.FacialExpressions" | translate }}</h5>
                </div>
                <div class="w-full flex gap-3 align-items-center" *ngFor="let emotionEntry of emotionArray">
                    <div class="flex w-10 gap-3 align-items-center">
                        <img [pTooltip]="'Key.' + emotionEntry.name | translate" tooltipPosition="left"
                            class="py-2 h-3rem"
                            [src]="'../../../../../../assets/vinter/icons/emotion-icons/' + emotionEntry.name + '.svg'">
                        <p-progressBar [class]="emotionEntry.name" class="w-full" [showValue]="false"
                            [value]="emotionEntry.value"></p-progressBar>
                    </div>
                    <div class="w-2">
                        <span class="font-semibold white-space-nowrap" [class]="'emotion-' + emotionEntry.name">%
                            {{emotionEntry.value.toFixed(2)}}</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- impression analysis -->
        <div class="custom-col-6 pr-0 impressionAnalysis-container">
            <div *ngIf="this.answer.impressionAnalysis ?? false"
                class="p-4 h-full border-gray-50 border-round-lg border-1 border-solid card-container flex flex-column gap-3">
                <div>
                    <h5>{{ "Key.ImpressionAnalysis" | translate }}</h5>
                </div>
                <div class="w-full flex gap-3 align-items-center" *ngFor="let impressionEntry of impressionArray">
                    <div class="flex w-10 gap-3 align-items-center">
                        <span [class]="impressionEntry.name + '-icon'"
                            [pTooltip]="'Key.' + impressionEntry.name | translate" tooltipPosition="left"
                            class="border-circle p-2 font-medium ocean-icon flex justify-content-center align-items-center text-white h-2rem">{{impressionEntry.name?.slice(0,1).toUpperCase()}}</span>
                        <p-progressBar [class]="impressionEntry.name" class="w-full" [showValue]="false"
                            [value]="impressionEntry.value"></p-progressBar>
                    </div>
                    <div class="w-2">
                        <span class="font-semibold white-space-nowrap" [class]="'impression-' + impressionEntry.name">%
                            {{impressionEntry.value.toFixed(2)}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr *ngIf="answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.Succesful">
    <!-- voice analaysis -->
    <div class="grid">
        <div class="custom-col-6 voiceAnalysis-container">
            <div *ngIf="answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.Succesful"
                class="flex flex-column w-full p-4 border-gray-50 border-round-lg border-1 border-solid card-container">
                <div>
                    <h5>
                        {{"Key.PRAATVoiceAnalysis" | translate}}
                    </h5>
                </div>

                <div *ngIf="this.answer.voiceAnalysis!=null && this.answer.voiceAnalysis.speechSpeed !=null">
                    <div class="flex w-full align-items-center justify-content-between">
                        <div class="praat-text font-medium">
                            {{"Key.speechSpeed" | translate}}
                            <img class="rating-info ml-2" src="assets/vinter/icons/info-icon.svg"
                                pTooltip="{{'Key.SpeechSpeedInfo' | translate}}" tooltipPosition="top" />
                        </div>
                        <div class="flex gap-3 align-items-center justify-content-center">
                            <p-rating class="voice" [(ngModel)]="this.answer.voiceAnalysis.speechSpeed"
                                [readonly]="true" [cancel]="false">
                                <ng-template pTemplate="onicon">
                                    <img src="assets/vinter/icons/rating-onIcon.svg" class="praat-rating-img" />
                                </ng-template>
                                <ng-template pTemplate="officon">
                                    <img src="assets/vinter/icons/rating-offIcon.svg" class="praat-rating-img" />
                                </ng-template>
                            </p-rating>
                            <span class="praat-value font-medium text-lg mb-1">{{this.answer.voiceAnalysis.speechSpeed |
                                voiceSpeechSpeedTypes:voiceSpeechSpeedTypes }}</span>
                        </div>
                    </div>
                </div>

                <ng-container *ngFor="let voiceEntry of voiceArray">
                    <div *ngIf="voiceEntry.name !== speechSpeed"
                        class="flex w-full justify-content-between align-items-center mt-3">
                        <div class="praat-text font-medium">
                            {{"Key." + voiceEntry.name | translate}}
                        </div>
                        <div class="praat-value font-semibold">
                            {{voiceEntry.value}}
                            <span
                                *ngIf="voiceEntry.name === 'speechDuration' || voiceEntry.name === 'originalDuration'">
                                s</span>
                        </div>
                    </div>
                </ng-container>

                <div *ngIf="this.answer.voiceAnalysis.speechDuration !=null && this.answer.voiceAnalysis.originalDuration !=null"
                    class="flex w-full justify-content-between align-items-center mt-3">
                    <div class="praat-text font-medium">
                        {{"Key.SpeechRatio" | translate}}
                    </div>
                    <div class="praat-value font-semibold">
                        {{"Key.SpeechRatioValue" | translate: {value: (this.answer.voiceAnalysis.originalDuration !== 0
                        ? ((this.answer.voiceAnalysis.speechDuration /
                        this.answer.voiceAnalysis.originalDuration * 100).toFixed(2)) : 0)} }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>