import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-applicant-profile-detail-tab',
  templateUrl: './applicant-profile-detail-tab.component.html',
  styleUrls: ['./applicant-profile-detail-tab.component.scss']
})
export class ApplicantProfileDetailTabComponent {
  @Input() applicationId: string;

}
