import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-cognitive-test-card',
    templateUrl: './cognitive-test-card.component.html',
    styleUrls: ['./cognitive-test-card.component.scss']
})
export class CognitiveTestCardComponent implements OnInit {
    @Input() step: any;
    @Input() report: any;
    @Input() pageNumber: number = 0;
    @Input() answeredQuestionsWithId;
    
    totalQuestions = 0;
    correctAnswers = 0;
    incorrectAnswers = 0;
    notAnswerCount = 0;

    conceptList: any[] = [];

    questions: string[] = []; 
    gridTemplateColumns: string = '';

    columnCount: number = 0;


    ngOnInit() {

    }
    ngAfterViewInit(): void {
        this.createQuestionDots();
        this.conceptList = this.analyzeQuestionConcepts();
    }

    analyzeQuestionConcepts() {
        const questions = this.step.positionStep.questionCollection.questionCollectionQuestionRelations;
        const conceptMap = new Map<string, any>();
        const progressData: any[] = [];

        questions.forEach((relation: any) => {
            const question = relation.question;
            const concept = question.questionConcept;
            if (!concept) return;

            const conceptName = concept.name || concept.description;

            if (!conceptMap.has(conceptName)) {
                conceptMap.set(conceptName, {
                    conceptName: conceptName,
                    totalQuestions: 0,
                    answeredQuestions: 0,
                    correctAnswers: 0,
                    incorrectAnswers: 0,
                    notAnsweredQuestions: 0,
                    totalWeight: 0,
                    totalScore: 0
                });
            }

            const currentConcept = conceptMap.get(conceptName);
            currentConcept.totalQuestions++;

            if (question.isAnswered) {
                currentConcept.answeredQuestions++;
                if (question.answerId && question.gptScore > 0) {
                    currentConcept.correctAnswers++;
                    currentConcept.totalScore += question.gptScore;
                }
                if (question.answerId && question.gptScore === 0) {
                    currentConcept.incorrectAnswers++;
                }
            } else {
                currentConcept.notAnsweredQuestions++;
            }
        });

        conceptMap.forEach((value, key) => {
            const percentage = value.totalQuestions > 0 ? (value.totalScore / value.totalQuestions) : 0; // Yüzde olarak hesaplama
            progressData.push({
                label: key,
                value: Math.round(percentage),
                color: this.getProgressBarColor(percentage) 
            });
        });

        return progressData;
    }


    getProgressBarColor(score: number): string {
        if (score <= 10) return '#DC2626';
        if (score <= 20) return '#DC2667';
        if (score <= 30) return '#DC2688';
        if (score <= 40) return '#DC269E';
        if (score <= 50) return '#AD26DC';
        if (score <= 60) return '#8126DC';
        if (score <= 70) return '#6026DC';
        if (score <= 80) return '#3F26DC';
        if (score <= 90) return '#1549D6';
        return '#2962FF';
    }

    createQuestionDots() {
        let totalAnswer = this.answeredQuestionsWithId.find(x => x.positionStepId === this.step.positionStepId);
        this.totalQuestions = totalAnswer.questionCount;
        this.correctAnswers = totalAnswer.correctAnsweredQuestionsCount;
        this.incorrectAnswers = totalAnswer.incorrectAnsweredQuestionsCount;
        this.notAnswerCount = totalAnswer.notAnsweredQuestionsCount;  

        this.initializeQuestions();
        this.calculateGrid();
    }

    initializeQuestions(): void {
        this.questions = [
          ...Array(this.correctAnswers).fill('correct'),
          ...Array(this.incorrectAnswers).fill('incorrect'),
          ...Array(this.notAnswerCount).fill('not-answer')
        ];
      }
    
      calculateColumnCount(totalQuestions: number) {
        let columns: number;
       
        if (totalQuestions <= 5) {
          columns = 5;
        } else if (totalQuestions <= 24) {
          columns = 6; 
        } else if (totalQuestions > 24 && totalQuestions <= 60) {
          columns = 15; 
        } else if (totalQuestions > 60) {
          columns = 17; 
        } else {
          columns = Math.ceil(Math.sqrt(totalQuestions)); 
        }
        return columns ;
      }
     
      calculateGrid(): void {
        this.columnCount =this.calculateColumnCount(this.totalQuestions);
        this.gridTemplateColumns = `repeat(${this.columnCount}, 1fr)`;
     
        document.documentElement.style.setProperty(
          '--grid-template-columns',
          this.gridTemplateColumns
        );
      }
}