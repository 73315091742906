import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { LocalizationService } from '@app/core';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { ApplicantBehaviourEventLogService } from '@app/vinter/services/applicant-behaviour-event-log.service';
import { ApplicationStepService } from '@app/vinter/services/application-step.service';
import { QuestionService } from '@app/vinter/services/question.service';
import { RatingService } from '@app/vinter/services/rating.service';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-applicant-answer-rate-popup',
  templateUrl: './applicant-answer-rate-popup.component.html',
  styleUrls: ['./applicant-answer-rate-popup.component.scss']
})
export class ApplicantAnswerRatePopup implements OnInit{

    @Input() answer: any = null; // Filtrelenmiş liste için kullanılacak
    ratingId: string;
    ratingXmin: number;
    selectedStar = 0;
    ratingComment: string;
    questionRatingType: any = 0;
    popupOpen = true;
    @Output() onPopupClose: EventEmitter<any> = new EventEmitter<any>();
    @Output() onRated: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private applicationStepService: ApplicationStepService,
    private eventLogService:ApplicantBehaviourEventLogService,
    private messageService: MessageService,
    private localizationService: LocalizationService,
    private appInfoService: AppInfoService,
    private ratingService: RatingService,
    private questionService: QuestionService,
    public api: VgApiService) { }

  ngOnInit(): void {
    this.setRatingInfo();
  }


  closePopup(){
    this.onPopupClose.emit(true);
  }

  rate(rating: any) {
    this.selectedStar = rating;
  }

  onRateEnded(){
    this.onRated.emit({
        ratingScore:this.selectedStar,
        id:this.ratingId,
        xmin:this.ratingXmin,
        ratingType:this.questionRatingType,
        comment: this.ratingComment});
  }

  setRatingInfo(){
     if (this.answer?.rating ?? false) {
          this.ratingId = this.answer?.rating?.id;
          this.ratingXmin = this.answer?.rating?.xmin;
          this.selectedStar = this.answer?.rating?.ratingScore;
          this.ratingComment = this.answer?.rating?.comment;
          this.questionRatingType = this.answer?.rating.ratingType;
        }
        else {
          this.ratingId = null;
          this.selectedStar = 0;
          this.ratingComment = "";
          this.questionRatingType = 0;

        }
  }


}
