<div class="grid" style="border: none;">
    <div class="col-3 mr-1 ml-4 px-0 py-2 align-content-center custom-display" [ngStyle]="{'width': (oceanParent) ? '16%' : '15%', 'background-color':(oceanParent) ? '#FCFCFC' : 'none'}">
        <p-progressBar [ngClass]="{
            'o-color-first': oceanCategory === 'o',
            'c-color-first': oceanCategory === 'c',
            'e-color-first': oceanCategory === 'e',
            'a-color-first': oceanCategory === 'a',
            'n-color-first': oceanCategory === 'n'}"
            [value]="(progressBarOceanScore * 5)" [showValue]="false"></p-progressBar>
    </div>
    <div class="col-3 mx-1 px-0 py-2 align-content-center custom-display" [ngStyle]="{'width': (oceanParent) ? '16%' : '15%', 'background-color':(oceanParent) ? '#F8F8F8' : 'none'}">
        <p-progressBar [ngClass]="{
            'o-color-second': oceanCategory === 'o',
            'c-color-second': oceanCategory === 'c',
            'e-color-second': oceanCategory === 'e',
            'a-color-second': oceanCategory === 'a',
            'n-color-second': oceanCategory === 'n'}"  [value]="(progressBarOceanScore * 5) - 100" [showValue]="false"></p-progressBar>
    </div>
    <div class="col-3 mx-1 px-0 py-2 align-content-center custom-display" [ngStyle]="{'width': (oceanParent) ? '16%' : '15%', 'background-color':(oceanParent) ? '#F3F3F3' : 'none'}">
        <p-progressBar [ngClass]="{
            'o-color-third': oceanCategory === 'o',
            'c-color-third': oceanCategory === 'c',
            'e-color-third': oceanCategory === 'e',
            'a-color-third': oceanCategory === 'a',
            'n-color-third': oceanCategory === 'n'}"  [value]="(progressBarOceanScore * 5) - 200" [showValue]="false"></p-progressBar>
    </div>
    <div class="col-3 mx-1 px-0 py-2 align-content-center custom-display" [ngStyle]="{'width': (oceanParent) ? '16%' : '15%', 'background-color':(oceanParent) ? '#F8F8F8' : 'none'}">
        <p-progressBar [ngClass]="{
            'o-color-fourth': oceanCategory === 'o',
            'c-color-fourth': oceanCategory === 'c',
            'e-color-fourth': oceanCategory === 'e',
            'a-color-fourth': oceanCategory === 'a',
            'n-color-fourth': oceanCategory === 'n'}"  [value]="(progressBarOceanScore * 5) - 300" [showValue]="false"></p-progressBar>
    </div>
    <div class="col-3 mx-1 px-0 py-2 align-content-center custom-display" [ngStyle]="{'width': (oceanParent) ? '16%' : '15%', 'background-color':(oceanParent) ? '#FCFCFC' : 'none'}">
        <p-progressBar [ngClass]="{
            'o-color-fifth': oceanCategory === 'o',
            'c-color-fifth': oceanCategory === 'c',
            'e-color-fifth': oceanCategory === 'e',
            'a-color-fifth': oceanCategory === 'a',
            'n-color-fifth': oceanCategory === 'n'}"  [value]="(progressBarOceanScore * 5) - 400" [showValue]="false"></p-progressBar>
    </div>
    <div class="col-1 p-0 ml-2 flex align-content-center flex-wrap" style="width: 3%">
        <p [ngClass]="{
            'o-color': oceanCategory === 'o',
            'c-color': oceanCategory === 'c',
            'e-color': oceanCategory === 'e',
            'a-color': oceanCategory === 'a',
            'n-color': oceanCategory === 'n'}" class="font-semibold text-xs">{{oceanScore}}</p>
    </div>
    <div *ngIf="oceanParent" class="flex col-12 p-0 pb-3">
        <div class="col-3 pt-0 pb-1 mr-1 ml-4 px-0 flex justify-content-center font-semibold rating-text-size" style="width: 16% ; background-color: #FCFCFC">{{"Key.VeryLow" | translate}}</div>
        <div class="col-3 pt-0 pb-1 mx-1 px-0 flex justify-content-center font-semibold rating-text-size" style="width: 16% ; background-color: #F8F8F8">{{"Key.Low" | translate}}</div>
        <div class="col-3 pt-0 pb-1 mx-1 px-0 flex justify-content-center font-semibold rating-text-size" style="width: 16% ; background-color: #F3F3F3">{{"Key.Medium" | translate}}</div>
        <div class="col-3 pt-0 pb-1 mx-1 px-0 flex justify-content-center font-semibold rating-text-size" style="width: 16% ; background-color: #F8F8F8">{{"Key.High" | translate}}</div>
        <div class="col-3 pt-0 pb-1 mx-1 px-0 flex justify-content-center font-semibold rating-text-size" style="width: 16% ; background-color: #FCFCFC">{{"Key.VeryHigh" | translate}}</div>
    </div>
</div>
