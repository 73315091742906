import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService, HttpBaseService, LocalizationService, NotifyService } from '@app/core';
import { ApiControllerEnum } from '@app/shared/common/enums';
import { AnswerContentFormItem, AnswerContentHRScoreItems } from '../models/answer-content-form-item';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnswerContentService extends HttpBaseService{

  constructor(private http: HttpClient
    , private notifyServ: NotifyService
    , private localizationService: LocalizationService
    , private configService: ConfigurationService) {
    super(http, ApiControllerEnum.AnswerContent, notifyServ, localizationService, configService);
  }

  SaveAsync(filter: any): Observable<AnswerContentFormItem> {
    return this.post("SaveAsync", filter, true);
  }
  setAsnwerContentHRScoreChange(filter: any): Observable<AnswerContentHRScoreItems> {
    return this.post("setAsnwerContentHRScoreChange", filter, true);
  }
}
