import { SharedCustomChipVinterModule } from '@app/shared/module/shared/shared-custom-chip.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ApplicantRoutingModule } from './applicant-routing.module';
import { ApplicantDetailComponent } from './applicant-detail/applicant-detail.component';
import { ApplicantInfoComponent } from './applicant-detail/applicant-info/applicant-info.component';
import {  ApplicantDetailsComponent } from './applicant-detail/applicant-details/applicant-details.component';
import { RatingModule } from 'primeng/rating';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { TagModule } from 'primeng/tag';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { TranslateModule } from '@ngx-translate/core';
import { KnobModule } from 'primeng/knob';
import { Slider, SliderModule } from 'primeng/slider';
import { TooltipModule } from 'primeng/tooltip';
import {InputSwitchModule} from 'primeng/inputswitch';
import {ProgressBarModule} from 'primeng/progressbar';
import { TimelineModule } from 'primeng/timeline';
import { ChipModule } from 'primeng/chip';
import { CardModule } from 'primeng/card';
import { ApplicantBehaviourEventLogTypesPipe, ApplicantStepQuestionListComponent, DeviceTypesPipe, VoiceSpeechSpeedTypesPipe } from './applicant-detail/applicant-details/applicant-step-question-list/applicant-step-question-list.component';
import { ApplicantStepVideoMeetingComponent } from './applicant-detail/applicant-details/applicant-step-video-meeting/applicant-step-video-meeting.component';
import { ApplicantStepPersonalityOceanComponent } from './applicant-detail/applicant-details/applicant-step-personality-ocean/applicant-step-personality-ocean.component';
import { BadgeModule } from 'primeng/badge';
import { SidebarModule } from 'primeng/sidebar';
import { AvatarModule } from 'primeng/avatar';
import { ImageModule } from 'primeng/image';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { SharedVinterModule } from '@app/shared/module/shared/sharedvinter.module';
import { SharedRatingVinterModule } from '@app/shared/module/shared/shared-rating-vinter.module';
import { ApplicantStepOfferComponent } from './applicant-detail/applicant-details/applicant-step-offer/applicant-step-offer.component';
import { StepBehaiviourLogInfo } from './applicant-detail/applicant-details/step-behaiviour-log-info/step-behaiviour-log-info.component';
import { ApplicantStepLanguageComponent } from './applicant-detail/applicant-details/applicant-step-language/applicant-step-language.component';
import { ApplicantStepScoreInfoLanguage } from './applicant-detail/applicant-details/applicant-step-language/applicant-step-score-info-language/applicant-step-score-info-language.component';
import { ApplicanyStepQuestionListLanguage } from './applicant-detail/applicant-details/applicant-step-language/applicant-step-question-list-language/applicant-step-question-list-language.component';
import { ApplicantAnswerRatePopup } from './applicant-detail/applicant-details/applicant-step-language/applicant-answer-rate-popup/applicant-answer-rate-popup.component';
import { ApplicantAnswerSidebarLanguage } from './applicant-detail/applicant-details/applicant-step-language/applicant-answer-sidebar-language/applicant-answer-sidebar-language.component';
import { ApplicantAnswerSidebarMultipleChoiceLanguage } from './applicant-detail/applicant-details/applicant-step-language/applicant-answer-sidebar-language/answer-sidebar-multiple-choice/answer-sidebar-multiple-choice.component';
import { ApplicantAnswerSidebarOpenEndedLanguage } from './applicant-detail/applicant-details/applicant-step-language/applicant-answer-sidebar-language/answer-sidebar-open-ended/answer-sidebar-open-ended.component';
import { ApplicantAnswerSidebarVideoLanguage } from './applicant-detail/applicant-details/applicant-step-language/applicant-answer-sidebar-language/applicant-answer-sidebar-video/applicant-answer-sidebar-video.component';
import { ApplicantAnswerQuestionKeyword } from './applicant-detail/applicant-details/applicant-step-language/applicant-answer-sidebar-language/answer-sidebar-question-area/question-keyword/question-keyword.component';
import { ApplicantAnswerSidebarQuestionAreaLanguage } from './applicant-detail/applicant-details/applicant-step-language/applicant-answer-sidebar-language/answer-sidebar-question-area/answer-sidebar-question-area.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ApplicantStepLanguageScoreAvgComponent } from './applicant-detail/applicant-details/applicant-step-language/applicant-step-language-score-avg/applicant-step-language-score-avg.component';
import { ApplicantPdfReportComponent, DeviceTypePipe } from './applicant-detail/applicant-info/applicant-pdf-report/applicant-pdf-report.component';
import { SuggestApplicantComponent } from './applicant-detail/applicant-info/suggest-applicant/suggest-applicant.component';
import { TableModule } from 'primeng/table';
import { CheatingAnalysisComponent } from './applicant-detail/applicant-details/cheating-analysis/cheating-analysis.component';
import { ApplicantGeneralApplicationFormComponent } from './applicant-detail/applicant-details/applicant-general-application-form/applicant-general-application-form.component';
import { ApplicantGeneralApplicationSidebarComponent } from './applicant-detail/applicant-details/applicant-general-application-form/applicant-general-application-sidebar/applicant-general-application-sidebar.component';
import { QuestionBehaiviourInfoComponent } from './applicant-detail/applicant-details/question-behaiviour-info/question-behaiviour-info.component';
import { SharedVinterCodeEditorModule } from '@app/shared/module/shared/shared-code-editor-vinter.module';
import { BackgroundMaskModule } from '@app/shared/module/shared/background-mask.module';
import { SharedDialogModule } from '@app/shared/module/shared/shared-dialog.module';
import { ApplicantDetailTabListComponent } from './applicant-detail-v2/applicant-detail-tab-list/applicant-detail-tab-list.component';
import { ApplicantProfileDetailTabComponent } from './applicant-detail-v2/applicant-detail-tab-list/applicant-profile-detail-tab/applicant-profile-detail-tab.component';
import { HiringProcessTabComponent } from './applicant-detail-v2/applicant-detail-tab-list/hiring-process-tab/hiring-process-tab.component';
import { NotesTabComponent } from './applicant-detail-v2/applicant-detail-tab-list/notes-tab/notes-tab.component';
import { ResumeTabComponent } from './applicant-detail-v2/applicant-detail-tab-list/resume-tab/resume-tab.component';
import { ApplicantDetailV2Component } from './applicant-detail-v2/applicant-detail-v2.component';
import { ApplicantHeaderComponent } from './applicant-detail-v2/applicant-header/applicant-header.component';
import { ApplicantProfileComponent } from './applicant-detail-v2/applicant-profile/applicant-profile.component';
import { ApplicantWorkExperienceComponent, DateDifferencePipe } from './applicant-detail-v2/applicant-detail-tab-list/applicant-profile-detail-tab/applicant-work-experience/applicant-work-experience.component';
import { ApplicantEducationInfoComponent } from './applicant-detail-v2/applicant-detail-tab-list/applicant-profile-detail-tab/applicant-education-info/applicant-education-info.component';
import { SkeletonModule } from 'primeng/skeleton';
import { TechnicalStepResultComponent } from './applicant-detail-v2/applicant-detail-tab-list/hiring-process-tab/technical-step-result/technical-step-result.component';
import { SharedStepQuestionResultListModule } from '@app/shared/module/shared/shared-step-question-result-list.module';
import { CompetenceStepResultComponent } from './applicant-detail-v2/applicant-detail-tab-list/hiring-process-tab/competence-step-result/competence-step-result.component';
import { CognitiveStepResultComponent } from './applicant-detail-v2/applicant-detail-tab-list/hiring-process-tab/cognitive-step-result/cognitive-step-result.component';
import { PronunciationStepResultComponent } from './applicant-detail-v2/applicant-detail-tab-list/hiring-process-tab/pronunciation-step-result/pronunciation-step-result.component';
import { ForeignLanguageStepResultComponent } from './applicant-detail-v2/applicant-detail-tab-list/hiring-process-tab/foreign-language-step-result/foreign-language-step-result.component';
import { OceanStepResultComponent } from './applicant-detail-v2/applicant-detail-tab-list/hiring-process-tab/ocean-step-result/ocean-step-result.component';
import { GeneralApplicationFormResultComponent } from './applicant-detail-v2/applicant-detail-tab-list/hiring-process-tab/general-application-form-result/general-application-form-result.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { BehavioralAnalysisSectionComponent } from './applicant-detail-v2/applicant-detail-tab-list/hiring-process-tab/behavioral-analysis-section/behavioral-analysis-section.component';
import { GeneralApplicationAnswerSidebarComponent } from './applicant-detail-v2/applicant-detail-tab-list/hiring-process-tab/general-application-form-result/general-application-answer-sidebar/general-application-answer-sidebar.component';
import { SuggestApplicantComponentV2 } from './applicant-detail-v2/applicant-header/suggest-applicant/suggest-applicant.component';
import { SharedQuestionDetailModule } from '@app/shared/module/shared/shared-question-detail.module';
import { SharedAnswerDetailModule } from '@app/shared/module/shared/shared-answer-detail-sidebar.module';
import { SharedVinterScoreCardModule } from '@app/shared/module/shared/shared-vinter-score-card.module';
import { ChangeVinterScoreSidebarComponent } from './applicant-detail-v2/applicant-detail-tab-list/hiring-process-tab/change-vinter-score-sidebar/change-vinter-score-sidebar.component';
import { DetailPdfReportComponent } from './applicant-detail-v2/applicant-header/pdf-report/detail-pdf-report/detail-pdf-report.component';
import { SummaryPdfReportComponent } from './applicant-detail-v2/applicant-header/pdf-report/summary-pdf-report/summary-pdf-report.component';
import { PdfReportComponent } from './applicant-detail-v2/applicant-header/pdf-report/pdf-report.component';
import { AiPdfReportComponent } from './applicant-detail-v2/applicant-header/pdf-report/ai-pdf-report/ai-pdf-report.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomCryptedHttpInterceptor } from '@app/core/http/custom-crypted-http.interceptor';
import { CustomHttpInterceptor } from '@app/core';
import { SharedLicensePlanDialogVinterModule } from '@app/shared/module/shared/shared-license-plan-dialog.module';
import { CognitiveTestCardComponent } from './applicant-detail-v2/applicant-header/pdf-report/ai-pdf-report/cognitive-test-card/cognitive-test-card.component';
import { GeneralTestCardComponent } from './applicant-detail-v2/applicant-header/pdf-report/ai-pdf-report/general-test-card/general-test-card.component';
import { ProficiencyTestCardComponent } from './applicant-detail-v2/applicant-header/pdf-report/ai-pdf-report/proficiency-test-card/proficiency-test-card.component';
import { PronunciationTestCardComponent } from './applicant-detail-v2/applicant-header/pdf-report/ai-pdf-report/pronunciation-test-card/pronunciation-test-card.component';
import { StepCardComponent } from './applicant-detail-v2/applicant-header/pdf-report/ai-pdf-report/step-card/step-card.component';
import { TechnicalTestCardComponent } from './applicant-detail-v2/applicant-header/pdf-report/ai-pdf-report/technical-test-card/technical-test-card.component';
import { SharedPdfChartModule } from '@app/shared/module/shared/shared-pdf-chart.module';
import { CompetenceTestCardComponent } from './applicant-detail-v2/applicant-header/pdf-report/ai-pdf-report/competence-test-card/competence-test-card.component';
import { OceanTestCardComponent } from './applicant-detail-v2/applicant-header/pdf-report/ai-pdf-report/ocean-test-card/ocean-test-card.component';
import { ChartModule } from 'primeng/chart';


@NgModule({
  declarations: [
    ApplicantDetailComponent,
    ApplicantInfoComponent,
    ApplicantDetailsComponent,
    ApplicantBehaviourEventLogTypesPipe,
    ApplicantStepQuestionListComponent,
    ApplicantStepLanguageComponent,
    ApplicantStepVideoMeetingComponent,
    StepBehaiviourLogInfo,
    ApplicantAnswerSidebarVideoLanguage,
    ApplicantAnswerSidebarLanguage,
    ApplicantAnswerQuestionKeyword,
    ApplicantStepScoreInfoLanguage,
    ApplicantAnswerSidebarQuestionAreaLanguage,
    ApplicantAnswerSidebarOpenEndedLanguage,
    ApplicantAnswerSidebarMultipleChoiceLanguage,
    ApplicantStepPersonalityOceanComponent,
    ApplicantAnswerRatePopup,
    ApplicanyStepQuestionListLanguage,
    VoiceSpeechSpeedTypesPipe,
    DeviceTypesPipe,
    ApplicantStepOfferComponent,
    ApplicantStepLanguageScoreAvgComponent,
    ApplicantPdfReportComponent,
    SuggestApplicantComponent,
    ApplicantWorkExperienceComponent,
    ApplicantEducationInfoComponent,
    CheatingAnalysisComponent,
    ApplicantGeneralApplicationFormComponent,
    ApplicantGeneralApplicationSidebarComponent,
    QuestionBehaiviourInfoComponent,
    ApplicantDetailV2Component,
    ApplicantHeaderComponent,
    ApplicantProfileComponent,
    ApplicantDetailTabListComponent,
    GeneralApplicationAnswerSidebarComponent,
    NotesTabComponent,
    ResumeTabComponent,
    ApplicantProfileDetailTabComponent,
    HiringProcessTabComponent,
    DateDifferencePipe,
    HiringProcessTabComponent,
    TechnicalStepResultComponent,
    CompetenceStepResultComponent,
    CognitiveStepResultComponent,
    PronunciationStepResultComponent,
    SuggestApplicantComponentV2,
    ChangeVinterScoreSidebarComponent,
    ForeignLanguageStepResultComponent,
    OceanStepResultComponent,
    GeneralApplicationFormResultComponent,
    BehavioralAnalysisSectionComponent,
    DetailPdfReportComponent,
    SummaryPdfReportComponent,
    PdfReportComponent,
    AiPdfReportComponent,
    StepCardComponent,
    TechnicalTestCardComponent,
    CognitiveTestCardComponent,
    ProficiencyTestCardComponent,
    GeneralTestCardComponent,
    PronunciationTestCardComponent,
    CompetenceTestCardComponent,
    OceanTestCardComponent
  ],
  imports: [
    CommonModule,
    PdfViewerModule,
    ApplicantRoutingModule,
    RatingModule,
    FormsModule,
    SharedCustomChipVinterModule,
    SharedRatingVinterModule,
    ReactiveFormsModule,
    AccordionModule,
    TagModule,
    AvatarModule,
    DialogModule,
    TabViewModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    OverlayPanelModule,
    InputTextareaModule,
    AccordionModule,
    SharedLicensePlanDialogVinterModule,
    SkeletonModule,
    ConfirmDialogModule,
    ToastModule,
    TranslateModule,
    KnobModule,
    SliderModule,
    TooltipModule,
    InputSwitchModule,
    ProgressBarModule,
    TimelineModule,
    ChipModule,
    CardModule,
    BadgeModule,
    SidebarModule,
    AvatarModule,
    ImageModule,
    RadioButtonModule,
    ChipModule,
    CardModule,
    CheckboxModule,
    SidebarModule,
    RadioButtonModule,
    BadgeModule,
    ImageModule,
    DropdownModule,
    SharedVinterModule,
    SharedVinterCodeEditorModule,
    TableModule,
    BackgroundMaskModule,
    SharedDialogModule,
    SharedStepQuestionResultListModule,
    SharedQuestionDetailModule,
    SharedAnswerDetailModule,
    SharedVinterScoreCardModule,
    SharedPdfChartModule,
    ChartModule
  ],providers: [{ provide: HTTP_INTERCEPTORS, useClass: CustomCryptedHttpInterceptor, multi: true }
  ]
})
export class ApplicantModule { }
