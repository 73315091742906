import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-pronunciation-test-card',
    templateUrl: './pronunciation-test-card.component.html',
    styleUrls: ['./pronunciation-test-card.component.scss']
})
export class PronunciationTestCardComponent implements OnInit {
    @Input() step: any;
    @Input() report: any;
    @Input() pageNumber: number = 0;
    
    pronunciationScores: any[] = [];

    ngOnInit() {

    }

    ngAfterViewInit(): void {
        this.setPronunciationScore();
    }

    setPronunciationScore(){
        this.pronunciationScores = [
            {name: 'Completeness Score', percentage: this.step.positionStep.questionCollection.averagePronunciationScores.averageCompletenessScore, color: this.getProgressBarColor( this.step.positionStep.questionCollection.averagePronunciationScores.averageCompletenessScore)},
            {name: 'Accuracy Score', percentage: this.step.positionStep.questionCollection.averagePronunciationScores.averageAccuracyScore, color: this.getProgressBarColor( this.step.positionStep.questionCollection.averagePronunciationScores.averageAccuracyScore)},
            {name: 'Fluency Score', percentage: this.step.positionStep.questionCollection.averagePronunciationScores.averageFluencyScore, color: this.getProgressBarColor( this.step.positionStep.questionCollection.averagePronunciationScores.averageFluencyScore)},
            {name: 'Fluency Time Score', percentage: this.step.positionStep.questionCollection.averagePronunciationScores.averageFluencyTimeScore, color: this.getProgressBarColor( this.step.positionStep.questionCollection.averagePronunciationScores.averageFluencyTimeScore)},
            {name: 'Fluency Disruptor Score', percentage: this.step.positionStep.questionCollection.averagePronunciationScores.averageFluencyDisruptorScore, color: this.getProgressBarColor( this.step.positionStep.questionCollection.averagePronunciationScores.averageFluencyDisruptorScore)},
        ];
    }

    getProgressBarColor(score: number): string {
        if (score <= 10) return '#DC2626';
        if (score <= 20) return '#DC2667';
        if (score <= 30) return '#DC2688';
        if (score <= 40) return '#DC269E';
        if (score <= 50) return '#AD26DC';
        if (score <= 60) return '#8126DC';
        if (score <= 70) return '#6026DC';
        if (score <= 80) return '#3F26DC';
        if (score <= 90) return '#1549D6';
        return '#2962FF';
    }
}