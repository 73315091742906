import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CredentialsService, LocalizationService } from '@app/core';
import { VINTERADMIN } from '@app/shared/common/constants';
import { EQuestionCategories, EQuestionCollectionSubCategory, EQuestionContentURLType, EStepDurationType, ESuggestedPackageType, QuestionCollectionCategoryEnum, QuestionExpectedAnswerType } from '@app/shared/common/enums';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { ChoiceContentListItem } from '@app/vinter/models/choice-content-list-item';
import { QuestionCollectionListItem } from '@app/vinter/models/question-collection-list';
import { QuestionCollectionWithQuestionsListItem } from '@app/vinter/models/question-collection-with-questions-list';
import { QuestionContentListItem } from '@app/vinter/models/question-content-list-item';
import { QuestionListItem } from '@app/vinter/models/question-list-item';
import { QuestionCollectionService } from '@app/vinter/services/question-collection.service';
import { QuestionService } from '@app/vinter/services/question.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Panel } from 'primeng/panel';

@Component({
  selector: 'app-position-step-question-list',
  templateUrl: './position-step-question-list.component.html',
  styleUrls: ['./position-step-question-list.component.scss']
})
export class PositionStepQuestionListComponent {


  questions: QuestionListItem[] = [];
  questionsCopy: QuestionListItem[] = [];
  questionStaticFirst: QuestionListItem;
  questionsStaticRemaining: QuestionListItem;
  questionCollectionLanguageList: any[] = [];
  questionCollectionDisplay: QuestionCollectionListItem[] = [];
  questionCollections: QuestionCollectionWithQuestionsListItem[] = [];
  questionExpectedAnswerTypeEnum = QuestionExpectedAnswerType;
  questionCategoryType = EQuestionCategories;
  QuestionContentType = EQuestionContentURLType;
  EQuestionCollectionType = QuestionCollectionCategoryEnum;
  selectedRadioValue: any;
  isVinterAdminLogged: boolean;
  userGroup: string[] = [];
  showMoreText: boolean = false;
  showQuestions: boolean;
  showPositionQuestions: boolean;
  resultQuestionCollection: any;
  isExistCodingQuestion: boolean
  isCodingQuestionLicenseValid = false;
  isCodingQuestionLicensePending = false;
  OCEAN50: number = EQuestionCollectionSubCategory.OCEAN50;
  OCEAN120: number = EQuestionCollectionSubCategory.OCEAN120;
  suggestedPackageType = ESuggestedPackageType.Coding;
  isShowLicensePlanPopup: boolean;
  stepDurationTypeEnum = EStepDurationType;

  @Input() positionStepDescription;
  @Input() positionStep;
  @Input() isStepLangForPositionActive = false;
  @Input() questionList;
  @Input() positionStepQuestionCollectionId;
  @Input() questionCollectionSubCategory;
  @Input() positionStepSubCategory;
  @Input() showDeprecatedQuestions: boolean;
  @Input() generalAppQuestions;
  @Input() positionStepCategory;
  @Output() hasAnyCodingQuestion = new EventEmitter<boolean>();


  constructor(private service: QuestionCollectionService,
    public router: Router,
    public credentialService: CredentialsService,
    public questionService: QuestionService,
    public localizationService: LocalizationService,
    private appInfoService: AppInfoService
  ) {
  }

  ngOnInit(): void {
    this.isCodingQuestionLicenseValid = this.credentialService.getIsExistCodingQuestionLicense();
    this.isCodingQuestionLicensePending = this.credentialService.getIsCodingLicensePending();
    this.questionCollectionLanguageList = this.appInfoService.getQuestionCollectionLanguageList();
    this.userGroup = this.credentialService.getUserGroups().split(',');
    if (this.userGroup.some(s => s.includes(VINTERADMIN)))
      this.isVinterAdminLogged = true;
    else
      this.isVinterAdminLogged = false;

    this.getQuestionsByQuestionCollectionId();
  }

  toggleDisplay(question: any) {
    if(!(!this.isCodingQuestionLicenseValid && question.expectedAnswerType == this.questionExpectedAnswerTypeEnum.Coding)){
    question.showMoreText = !question.showMoreText;
    this.showMoreText = !this.showMoreText;
    }
  }

  getTestCases(jsonData){
    let testcases = JSON.parse(jsonData.toString())?.Test_cases;
    if(testcases ?? false)
      return testcases;
    else
      return [];
  }

  toggleDisplayFirstQuestion(questions: any) {
    questions[0].showMoreText = !questions[0].showMoreText;
  }

  sortChoicesByOrder(question) {
    if (question && question.choiceContents) {
      question.choiceContents.sort((a, b) => a.order - b.order);
    }
    return question.choiceContents; 
  }
  sendCodingQuestionExistInfo() {
    if(this.isExistCodingQuestion){
      this.hasAnyCodingQuestion.emit(true);
    }
  }
  sortChoicesOfGeneralAppQuestionByOrder(question) {
    question.generalApplicationQuestionChoices.sort((a, b) => a.order - b.order);
    return question.generalApplicationQuestionChoices;
  }
  
  setStaticOceanData(){
    this.setOceanStaticQuestions()
      this.setOceanStaticAnswer()
      this.showQuestions = false
  }


  setVinterAdminQuestinListData(){
    if((this.positionStepQuestionCollectionId != null || this.positionStepQuestionCollectionId != undefined)){
      this.service.GetQuestionCollectionWihtQuestionsChoice(this.positionStepQuestionCollectionId).subscribe((result) => {
        this.setCategoryQuestions(result)
      });
    }else{
      this.questions = this.questionList;
      this.showQuestionList();
    }
  }
  openLicensePlanPopup(){
    if(this.isCodingQuestionLicensePending){
      this.router.navigate(['organization/settings'], {
        queryParams: {
          activeTab: 3,
        }
      });
    }
    else{
      this.isShowLicensePlanPopup = !this.isShowLicensePlanPopup;
    }
  }
  checkisOcean(){
    return this.questionCollectionSubCategory === this.OCEAN50
        || this.questionCollectionSubCategory === this.OCEAN120
        || this.positionStep?.questionCollection?.subCategory == this.OCEAN120
        || this.positionStep?.questionCollection?.subCategory == this.OCEAN50
  }

  setHrQuestionListData() {
    if ((this.positionStepQuestionCollectionId != null || this.positionStepQuestionCollectionId != undefined)
        && (this.checkisOcean())) {
      this.setStaticOceanData();
    } 
    else if ((this.positionStepQuestionCollectionId != null || this.positionStepQuestionCollectionId != undefined)
              && (!this.checkisOcean())) {
      this.service.GetQuestionCollectionWihtQuestionsChoice(this.positionStepQuestionCollectionId).subscribe((result) => {
        this.filterAndSetCategoryQuestions(result);
      });
    }
    else {
      if (this.checkisOcean()) {
        this.setStaticOceanData();
      } else {
        this.questions = this.questionList;
        this.showQuestionList();
      }
    }
  }

  getQuestionsByQuestionCollectionId() {
    if(this.isVinterAdminLogged){
      this.setVinterAdminQuestinListData();
    }
    else{
      this.setHrQuestionListData();
    }

  }

  setCategoryQuestions(result: QuestionCollectionWithQuestionsListItem) {
    result.questions = result.questions.sort((a, b) => a.order - b.order);
    this.questionCollections.push(result);
    this.resultQuestionCollection = result;
    this.positionStepCategory = result.questionCollectionCategory;
    this.questions = result.questions;
    if (this.isVinterAdminLogged) {
      let deprecatedItems = this.questions.filter(item => item.isDeprecated);
      this.questions = this.questions.filter(item => !item.isDeprecated);
      this.questions = this.questions.concat(deprecatedItems);
    }
    else {
      if (!result["isDeprecated"] && !this.showDeprecatedQuestions)
        this.questions = this.questions.filter(x => x.isDeprecated != true);
    }

    for (let question of this.questions) {
      question.showMoreText = false
    }

    this.showQuestionList();
  }
  filterAndSetCategoryQuestions(result: QuestionCollectionWithQuestionsListItem) {
    if (!this.isCodingQuestionLicenseValid) {
      result.questions.forEach(question => {
        if (question.expectedAnswerType == this.questionExpectedAnswerTypeEnum.Coding) {
          this.isExistCodingQuestion = true;
          question.questionContent.questionText = this.localizationService.translateText('StaticQuestionText');
          question.questionContent.contentDetail = "";
        }
      });
    }
    this.setCategoryQuestions(result);
  }

  getShortLangText(languageID){
    const keyName = this.questionCollectionLanguageList.find(x=> x.id === languageID)?.name;
    const text = keyName ?? false ? this.localizationService.translatedTextList['QCollectionShort'+keyName] : '';
    return text;
}

  showQuestionList() {
    if(this.isStepLangForPositionActive){
        this.positionStep["langShort"]=this.getShortLangText(this.positionStep?.questionCollectionLanguageId || this.positionStep.questionCollection.languageID);
    }

    if(this.positionStepCategory == this.EQuestionCollectionType.TechnicalTest
        || this.positionStepCategory == this.EQuestionCollectionType.CompetenceTest){
            this.questions.forEach(element => {
                element.langShort = this.getShortLangText(element.languageID);
            });
    }

    if (this.isVinterAdminLogged && (this.questionCollectionSubCategory == this.OCEAN50 || this.questionCollectionSubCategory == this.OCEAN120)) {
      this.showQuestions = true;
    }
    else if (!this.isVinterAdminLogged && (this.questionCollectionSubCategory == this.OCEAN50 || this.questionCollectionSubCategory == this.OCEAN120)) {
      this.questionsCopy = this.questions;
      this.showQuestions = false;
    }
    else
      this.showQuestions = true;
  }

  setOceanStaticQuestions() {
    const questionText = this.localizationService.translateText('ExampleOceanQuestion')
    const createQuestion = (showMoreText: boolean) => {
      const question = new QuestionListItem();
      question.showMoreText = showMoreText;
      question.questionContent = new QuestionContentListItem();
      question.questionContent.questionText = questionText;
      question.isDeprecated = false;
      question.expectedAnswerType = this.questionExpectedAnswerTypeEnum.MultipleChoice;
      return question;
    };

    this.questionStaticFirst = createQuestion(false)
    this.questionsStaticRemaining = createQuestion(false)
  }

  setOceanStaticAnswer() {
    const answerTexts = [
      "OceanTestAnswer1",
      "OceanTestAnswer2",
      "OceanTestAnswer3",
      "OceanTestAnswer4",
      "OceanTestAnswer5"
    ];

    this.questionStaticFirst.choiceContents = answerTexts.map(answer => {
      const choice = new ChoiceContentListItem();
      choice.choiceText = this.localizationService.translateText(answer);
      return choice;
    });
  }

  DeprecateQuestion(event: QuestionListItem) {
    if (this.resultQuestionCollection.isDeprecated)
      return;
    this.questionService.ChangeDeprecatedStatus({ Id: event.id, isDeprecated: true }).subscribe(questionID => {
      if (questionID != null)
        this.getQuestionsByQuestionCollectionId();
    });
  }

  UndoDeprecateQuestion(event: QuestionListItem) {
    if (this.resultQuestionCollection.isDeprecated)
      return;
    this.questionService.ChangeDeprecatedStatus({ Id: event.id, isDeprecated: false }).subscribe(questionID => {
      if (questionID != null)
        this.getQuestionsByQuestionCollectionId();
    });
  }
}
