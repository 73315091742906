<p-sidebar [(visible)]="sidebarVisible" position="right" [showCloseIcon]="false" (onHide)="closeSidebar()">
    <div>
        <p-button [disabled]="selectedPosition === undefined || selectedPosition === null" (click)="suggestApplicant()" class="buttonFont" label="{{'Key.Suggest' | translate}}"></p-button>
    </div>

    <div class="my-4">
        <div class="my-3">
            <span class="font-semibold text-2xl">{{'Key.SuggestSidebarHeader' | translate}}</span>
        </div>

        <div class="flex text-lg" style="color: #686E75;">
            {{'Key.SuggestSidebarText' | translate}}
        </div>
    </div>

    <div>
        <p-table #dt [value]="suggestPositionList()" [columns]="columns" [paginator]="true" [rowHover]="true"
        [tableStyle]="{'min-width': '50vw'}" [rows]="8" [rowsPerPageOptions]="[8,16,32]"
        [showCurrentPageReport]="true" scrollDirection="horizontal" currentPageReportTemplate="{{'Key.Total' | translate}}: {totalRecords}">
            
            <ng-template pTemplate="caption">
                <div class="flex">
                    <span class="p-input-icon-left w-full">
                        <i class="pi pi-search"></i>
                        <input pInputText class="w-full search-input" type="text"
                            (input)="dt.filterGlobal($any($event.target).value, 'contains')"
                            placeholder="{{'Key.Search' | translate}}" />
                    </span>
                </div>
            </ng-template>

            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th scope="header" *ngFor="let col of columns" [pSortableColumn]="col.field" [style.width]="col.width">

                        <div *ngIf="col.header !== '#'">
                            {{'Key.'+col.header | translate}}
                            <ng-container *ngIf="col.field === 'organizationName' || col.field == 'activatedDate' ">
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                            </ng-container>
                        </div>
                        <div *ngIf="col.header === '#'">
                            #
                        </div>

                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-positions let-columns="columns" let-rowIndex="rowIndex">
                <tr style="cursor: pointer;">
                    <td class="text-properties" *ngFor="let col of columns">
                        
                        <div *ngIf="col.field === 'activatedDate'" class="cursor-pointer" >
    
                            <p *ngIf="positions[col.field] !== null" class="mb-0 text-font">{{ positions[col.field] | date:'dd/MM/yy' }}</p>
                            <p *ngIf="positions[col.field] !== null"  class="date-text">{{ positions[col.field] | date:'HH:mm' }}</p>
                            <p *ngIf="positions[col.field] === null"  style="opacity: 0.0;">-</p>
    
                        </div>
                        
                        <div *ngIf="col.header === 'Responsible'" class="cursor-pointer">
                            <div class="flex vertical-align-middle">
                                <app-user-avatar [UserFirstName]="positions.responsibleUser.firstName" [UserSurname]="positions.responsibleUser.lastName" [UserImageURL] = "positions.responsibleUser.userProfile?.imageURL" [UserTitle] = "positions.responsibleUser?.title"></app-user-avatar>
                            </div>
                        </div>
                        <div *ngIf="col.field !== 'activatedDate'
                            && col.field !== 'organizationName'
                            && col.header !== 'Responsible'
                            && col.header !== '#'" class="curssor-pointer">
                            {{positions[col.field]}}
                        </div>
                        <div *ngIf="col.header === '#'" class="cursor-pointer count-font" >
                            <p-radioButton value={{positions.id}} [(ngModel)]="selectedPosition"></p-radioButton>
                        </div>
                        <div *ngIf="col.field === 'organizationName'" >
                            <p-tag [value]="positions[col.field]" class="tag-color cursor-pointer"></p-tag>
    
                        </div>
                    </td>
                </tr>
            </ng-template>

        </p-table>
    </div>
</p-sidebar>

<p-dialog [(visible)]="invitedMessage" [draggable]="false" [modal]="true" showEffect="fade"
    [style]="{width: '30vw'}" [breakpoints]="{'960px': '75vw'}" class="dialogPage"  [closable]="false">
    <div class="grid">
        <div class="col-12" style="display: flex;justify-content: center;">
            <img style="width: 4rem;" src="../../../../../assets/vinter/icons/invited-suggest.svg">
        </div>
        <div class="col-12 complete-dialog-header">
            {{'Key.SuggestedCompletedHeader' | translate}}
        </div>
        <div class="col-12 complete-dialog-text pt-0">
            {{'Key.SuggestedCompletedText' | translate}}
        </div>
        <div class="col-12 complete-dialog-header">
            <div class="col-6 flex justify-content-center">
                <p-button label="{{'Key.Ok' | translate}}" (onClick)="closeDialog()"
                    styleClass="mr-2 mb-2"></p-button>
            </div>
        </div>
    </div>
</p-dialog>