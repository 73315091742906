import { NgModule } from '@angular/core';
import * as ace from "ace-builds";
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ImageModule } from 'primeng/image';
import { BadgeModule } from 'primeng/badge';
import { TagModule } from 'primeng/tag';
import { AnswerBaseComponentComponent } from '@app/shared/components/answer-base-component/answer-base-component.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MultipleChoiceAnswerComponent } from '@app/shared/components/answer-base-component/multiple-choice-answer/multiple-choice-answer.component';
import { OpenEndedAnswerComponent } from '@app/shared/components/answer-base-component/open-ended-answer/open-ended-answer.component';
import { VideoAnswerComponent, VoiceSpeechSpeedTypesPipe } from '@app/shared/components/answer-base-component/video-answer/video-answer.component';
import { CodingAnswerComponent } from '@app/shared/components/answer-base-component/coding-answer/coding-answer.component';
import { ForeignLanguageOpenEndedAnswerComponent } from '@app/shared/components/answer-base-component/foreign-language-open-ended-answer/foreign-language-open-ended-answer.component';
import { ForeignLanguageVideoAnswerComponent } from '@app/shared/components/answer-base-component/foreign-language-video-answer/foreign-language-video-answer.component';
import { PronunciationVideoAnswerComponent } from '@app/shared/components/answer-base-component/pronunciation-video-answer/pronunciation-video-answer.component';
import { RatingModule } from 'primeng/rating';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { AccordionModule } from 'primeng/accordion';
import { AvatarModule } from 'primeng/avatar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KnobModule } from 'primeng/knob';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressBarModule } from 'primeng/progressbar';
import { SidebarModule } from 'primeng/sidebar';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { BackgroundMaskModule } from './background-mask.module';
import { SharedVinterCodeEditorModule } from './shared-code-editor-vinter.module';
import { SharedCustomChipVinterModule } from './shared-custom-chip.module';
import { SharedDialogModule } from './shared-dialog.module';
import { SharedRatingVinterModule } from './shared-rating-vinter.module';
import { SharedVinterModule } from './sharedvinter.module';

@NgModule({
  declarations: [
    AnswerBaseComponentComponent,
    MultipleChoiceAnswerComponent,
    OpenEndedAnswerComponent,
    VideoAnswerComponent,
    CodingAnswerComponent,
    ForeignLanguageOpenEndedAnswerComponent,
    ForeignLanguageVideoAnswerComponent,
    PronunciationVideoAnswerComponent,
    VoiceSpeechSpeedTypesPipe
  ],
  imports: [
    CommonModule,
    RatingModule,
    FormsModule,
    SharedCustomChipVinterModule,
    SharedRatingVinterModule,
    ReactiveFormsModule,
    AccordionModule,
    TagModule,
    DialogModule,
    TabViewModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    OverlayPanelModule,
    InputTextareaModule,
    AccordionModule,
    ConfirmDialogModule,
    ToastModule,
    TranslateModule,
    KnobModule,
    SliderModule,
    TooltipModule,
    InputSwitchModule,
    ProgressBarModule,
    TimelineModule,
    ChipModule,
    CardModule,
    BadgeModule,
    SidebarModule,
    AvatarModule,
    ImageModule,
    RadioButtonModule,
    ChipModule,
    CardModule,
    CheckboxModule,
    SidebarModule,
    RadioButtonModule,
    BadgeModule,
    ImageModule,
    DropdownModule,
    SharedVinterModule,
    SharedVinterCodeEditorModule,
    TableModule,
    BackgroundMaskModule,
    SharedDialogModule
  ],
  exports: [
    AnswerBaseComponentComponent,
    MultipleChoiceAnswerComponent,
    OpenEndedAnswerComponent,
    VideoAnswerComponent,
    CodingAnswerComponent,
    ForeignLanguageOpenEndedAnswerComponent,
    ForeignLanguageVideoAnswerComponent,
    PronunciationVideoAnswerComponent
    ]
})
export class SharedAnswerBaseModule { }
