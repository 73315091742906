<div class="col-12 grid pb-0 pt-0 pl-0 w-auto" style="height: 5.5rem;background-color:#f6f7ff;border-radius: 6px;">
    <div class="col-2 cheating-title pl-0">
        {{'Key.CheatingAnalysis' | translate}}
    </div>
    <div class="vertical-line-rating mt-2 w-auto" style="height: 56px;"></div>
    <div class="col-2 w-auto">
        <h5 class="cheating-subtitle w-auto">
            {{'Key.Device' | translate}}
        </h5>
        <hr class="cheating-hr w-auto">
        <p *ngIf="item.deviceType !== null" class="cheating-subtitle-text">{{item.deviceType |  deviceTypes:deviceTypes }} </p>
        <p *ngIf="item.deviceType === null" class="cheating-subtitle-text">{{'Key.DeviceNotFound' | translate}} </p>

    </div>
    <div class="vertical-line-rating mt-2 w-auto" style="height: 56px;"></div>
    <div class="col-8 w-auto">
        <h5 class="cheating-subtitle w-auto">
            {{'Key.SamePageUsed' | translate}}
        </h5>
        <hr class="cheating-hr w-auto">
        <p *ngIf="stepBehaviourEventLogs.length > 0" class="cheating-subtitle-text" style="color:#F20505"> {{'Key.No' | translate}} </p>
        <p *ngIf="stepBehaviourEventLogs.length === 0" class="cheating-subtitle-text" style="color:#37AC00"> {{'Key.Yes' | translate}}</p>

    </div>

</div>
