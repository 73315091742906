import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { CredentialsService } from './credentials.service';
import { UserAttendanceStatusEnum } from '@app/shared/common/enums';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
    constructor(private router: Router, private credentialsService: CredentialsService) { }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        const isLoggedIn = this.credentialsService.isAuthenticated();
        const isLoginForm = route.routeConfig.path === 'login';
        const isApplicantWelcome = route.routeConfig.path === 'invite';
        const isStartFreeTrial = route.routeConfig.path === 'start-free-trial';
        const isSignupForm = route.routeConfig.path === 'signup';
        const isLogOut = route.routeConfig.path === 'logout';
        const isResetPassword = route.routeConfig.path === 'reset-password';
        const attendanceStatus = (this.credentialsService.credentials != null) ? this.credentialsService.getAttendanceStatus() : undefined;
        const isRegisterForm =  route.routeConfig.path === 'register';
        if (isLogOut) {
            this.credentialsService.setCredentials();
            this.router.navigate(['/login']);
        }

        if (isLoggedIn && isLoginForm) {
            this.router.navigate(['/home']);
            return false;
        }

        if (isLoggedIn && isApplicantWelcome) {
            this.credentialsService.setCredentials();
            return true;
        }

        if (isLoggedIn && isStartFreeTrial) {
            this.credentialsService.setCredentials();
            return true;
        }

        if (isLoggedIn && isSignupForm) {
            this.router.navigate(['/home']);
            return false;
        }

        if (isLoggedIn && isRegisterForm) {
            this.router.navigate(['/home']);
            return false;
        }

        if (isLoggedIn && isResetPassword) {
            this.router.navigate(['/home']);
            return false;
        }

        if (!isLoggedIn && !isLoginForm && !isApplicantWelcome && !isStartFreeTrial && !isSignupForm && !isResetPassword && !isRegisterForm) {
            this.router.navigate(['/login']);
        }

        if (attendanceStatus == UserAttendanceStatusEnum.Break.toString() && route.routeConfig.path != "home") {
            this.router.navigate(['/home']);
        }

        return isLoggedIn || isLoginForm || isLogOut || isApplicantWelcome || isStartFreeTrial || isSignupForm || isResetPassword || isRegisterForm;
    }
}
