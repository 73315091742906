

<div [ngSwitch]="question?.questionContent?.contentURLType">
    <div *ngSwitchCase="!QuestionContentType.Image || !QuestionContentType.Audio || !QuestionContentType.Video">
        <div class="question-container-sideBar-text">
            <div class="flex question-text-label mb-0" [innerHTML]="question?.questionContent.questionText" style="display:block !important">
                <!-- {{questionText}} -->
            </div>

        </div>
    </div>

    <div *ngSwitchCase="QuestionContentType.Image">
        <div class="question-container-sideBar-text">
            <div class="col-12 p-0 flex">
                <div class="col-6 p-0 pr-3">
                    <p-image src={{question?.questionContent.contentURL}} [imageStyle]="{'width': '100%'}"
                        [preview]="true"></p-image>
                </div>
                <div class="col-6 p-0 pl-1 mb-0 question-text-label" [innerHTML]="question?.questionContent.questionText">
                    <!-- {{questionText}} -->
                </div>
                <app-question-keyword-answer-language [questionKeywords]="questionKeywords"></app-question-keyword-answer-language>
            </div>
        </div>
    </div>
    <div *ngSwitchCase="QuestionContentType.Audio">
        <div class="question-container-sideBar-text">
            <div class="col-12 p-0 flex">
                <div class="col-6 p-0 pr-3">
                    <audio src={{question?.questionContent.contentURL}} style="width: 100%;" controls
                        controlslist="nodownload"></audio>
                </div>
                <div class="col-6 p-0 pl-1 mb-0 question-text-label" [innerHTML]="question?.questionContent.questionText">
                    <!-- {{questionText}} -->
                </div>
                <app-question-keyword-answer-language [questionKeywords]="questionKeywords"></app-question-keyword-answer-language>

            </div>

        </div>
    </div>
    <div *ngSwitchCase="QuestionContentType.Video">
        <div class="question-container-sideBar-text">
            <div class="col-12 p-0 flex">
                <div class="col-6 p-0 pr-3">
                    <video src={{question?.questionContent.contentURL}} style="border-radius:1rem;width: 100%;"
                        controls controlslist="nodownload" disablepictureinpicture></video>
                </div>
                <div class="col-6 p-0 pl-1 mb-0 question-text-label" [innerHTML]="question?.questionContent.questionText">
                    <!-- {{questionText}} -->
                </div>
                <app-question-keyword-answer-language [questionKeywords]="questionKeywords"></app-question-keyword-answer-language>
            </div>
        </div>
    </div>
</div>
