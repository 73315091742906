<div class="p-5">
    <h2 class="header-title text-4xl font-bold line-height-4 text-center pb-2">{{'Key.ComparePricing' | translate}}</h2>
    
    <div class="pricing-card overflow-hidden bg-white">
      <p-table [value]="pricingFeatures" class="custom-table w-full">
        <ng-template pTemplate="header">
          <tr>
            <th rowspan="3" class="text-2xl feature-title text-blue-600 font-semibold text-left align-bottom vertical-align-bottom">
              {{'Key.Features' | translate}}
            </th>
            <ng-container *ngFor="let item of LicensePlan">
              <th *ngIf="item.licensePlanType === licensePlanTypeEnum.StandartPlan" class="plan-title text-4xl text-blue-600 text-center">{{ item.name }}</th>
            </ng-container>
          </tr>
      
          <tr>
            <ng-container *ngFor="let item of LicensePlan">
              <th  *ngIf="item.licensePlanType === licensePlanTypeEnum.StandartPlan" class="plan-price text-gray-800 font-medium text-center">
                <ng-container *ngIf="item.name !== 'Enterprise'; else enterprisePrice">
                  € {{ item.price }} / {{ (isAnnual ? 'Key.Annual' : 'Key.Monthly') | translate }}  
                </ng-container>
                <ng-template #enterprisePrice>
                  {{ 'Key.' + item.enterprisePriceTranslation.key | translate }}
                </ng-template>
              </th>
            </ng-container>
          </tr>
      
          <tr>
            <ng-container  *ngFor="let item of LicensePlan">
              <th  *ngIf="item.licensePlanType === licensePlanTypeEnum.StandartPlan" class="plan-price text-gray-800 font-medium text-center"></th>
            </ng-container>
          </tr>
        </ng-template>
          
          
        <ng-template pTemplate="body" let-feature let-rowIndex="rowIndex">
          <tr [ngClass]="{'bg-white': rowIndex % 2 === 0, 'bg-blue-50': rowIndex % 2 !== 0}">
            <td [ngClass]="feature.key === 'credit' ? 'text-blue-500 font-semibold text-2xl':''" class="text-left	pl-5">
              {{ feature.name }}
            </td>
            
            <td>
              <ng-container *ngIf="feature.key === 'credit'; else defaultCheck">
                <span class="plan-detail-text font-semibold text-2xl">{{ feature[basicKey] }}</span>
              </ng-container>
              <ng-template #defaultCheck>
                <img width="20" height="20" *ngIf="feature[basicKey]" src="/assets/vinter/icons/tick-magenta-icon.svg">
              </ng-template>
            </td>
            
            <td>
              <ng-container *ngIf="feature.key === 'credit'; else defaultCheckEssential">
                <span class="plan-detail-text font-semibold text-2xl">{{ feature[essentialKey] }}</span>
              </ng-container>
              <ng-template #defaultCheckEssential>
                <img width="20" height="20" *ngIf="feature[essentialKey]" src="/assets/vinter/icons/tick-magenta-icon.svg">
              </ng-template>
            </td>
            
            <td>
              <ng-container *ngIf="feature.key === 'credit'; else defaultCheckPremium">
                <span class="plan-detail-text font-semibold text-2xl">{{ feature[premiumKey] }}</span>
              </ng-container>
              <ng-template #defaultCheckPremium>
                <img width="20" height="20" *ngIf="feature[premiumKey]" src="/assets/vinter/icons/tick-magenta-icon.svg">
              </ng-template>
            </td>
            
            <td>
              <ng-container *ngIf="feature.key === 'credit'; else defaultCheckEnterprise">
                <span class="plan-detail-text font-semibold text-2xl">{{ feature[enterpriseKey] }}</span>
              </ng-container>
              <ng-template #defaultCheckEnterprise>
                <img width="20" height="20" *ngIf="feature[enterpriseKey]" src="/assets/vinter/icons/tick-magenta-icon.svg">
              </ng-template>
            </td>
          </tr>
        </ng-template>     
      </p-table>
    </div>
  </div>
