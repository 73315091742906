
<div class="flex-nowrap flex gap-2 align-items-center mt-3" *ngIf="questionPageHiddenLog > 0">
        <img src="assets/vinter/icons/cheating-icon.svg">
        <p class="font-medium page-hidden">{{"Key.SwitchTab" | translate: {value: questionPageHiddenLog } }}</p>
</div>

<div class="flex">
    <img src="assets/vinter/icons/text-questioncoktan-secmeli-cevap-icon-arrow-back-up.svg">
    <h5 class="text-justify answer-title font-semibold text-lg ml-2">
        {{"Key.Answer" | translate}}
    </h5>
</div>

<div class="answer-coding-container ml-5 px-0">
    <div class="answer-text-label text-justify">
        <app-vinter-code-editor
            [isSelectionEnabled]="true"
            [dropdownReadonly]="true"
            [template]="answer?.answerContent?.answerText"
            [codeType]="answer?.answerContent?.answerCodeLanguage"
            [height]="500"
            [disabled]="true">
        </app-vinter-code-editor>
    </div>
</div>

<div class="ml-5 flex" *ngIf="answer === null">
    <img src="assets/vinter/icons/not-answered.svg">
    <p class="ml-2 empty-answer-text">{{"Key.QuestionNotAnswered" | translate}}</p>
</div>

<div *ngFor="let testCaseResult of parsedCodingAnalysisResultDetail?.result?.test_case_result; let i = index">
    <div class="grid ml-5 mt-3 w-6 custom-min-width" [ngClass]="{ 'green-grid': testCaseResult.result, 'red-grid': !testCaseResult.result}">
        <div class="col-12 flex gap-2 align-items-center pb-0">
            <i *ngIf="testCaseResult.result" class="pi pi-check check-icon border-round-sm p-1 mr-0"></i>
            <i *ngIf="!testCaseResult.result" class="pi pi-times x-icon border-round-sm p-1 mr-0"></i>
            <div class="font-medium" [ngClass]="{ 'green-custom': testCaseResult.result, 'red-custom': !testCaseResult.result}">
                {{"Key.TestCase" | translate}}{{" "}}{{i + 1}}
            </div>  
        </div>
        <div class="col-12 font-medium text-gray-700 pb-0">
            {{"Key.InputParameters" | translate}}{{testCaseResult.input}}
        </div>
        <div class="col-12 font-medium text-gray-700">
            {{"Key.ExpectedResult" | translate}}{{testCaseResult.output}}
        </div>
    </div>
</div>

<div *ngIf="answer?.answerContent !== null" class="answer-text">
    <div class="flex">
        <h5 class="text-justify font-bold text-blue-800 text-lg ml-2 mb-2 pl-4 m-3">
            {{'Key.VinterComment' | translate}}
        </h5>
    </div>
    <div class="answer-container ml-5 border-1 border-solid border-round-md border-gray-50" [ngClass]="{'justify-content-center': answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.Retry}">
        <img [src]="vinterAICommentIcon">
        <div class="text-blue-800 text-justify mt-2" *ngIf="answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.Succesful">
            {{ answer?.answerContent?.gptEvalText }}
        </div>

        <div class="retry-container border-2 border-solid h-13rem w-15rem bg-white" *ngIf="answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.Retry">
            <div class="grid">
                <div class="col-12 flex justify-content-center">
                    <img src="../../../../../assets/vinter/icons/gpt-sleep.svg" class="w-4rem pt-3" >
                </div>
                <div class="col-12 flex justify-content-center text-gray-700 text-sm font-medium py-0 text-center">
                    {{"Key.GptRetryMessage" | translate}}
                </div>
                <div class="col-12 pt-4 flex justify-content-center">
                    <img src="../../../../../assets/vinter/icons/retry-icon.svg" (click)="setGptStatus()" class="w-1rem mr-2 cursor-pointer">
                    <p class="text-blue-500 cursor-pointer" (click)="setGptStatus()">{{"Key.Retry" | translate}}</p>
                </div>
            </div>
        </div>

        <div class="answer-text-label text-justify" *ngIf="answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.ClientFail">
            {{ answer?.answerContent?.aiFinalLog }}
        </div>

        <div class="answer-text-label text-justify" *ngIf="answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.Fail">
            {{"Key.GptFailStatusMessage" | translate}}
        </div>

        <div class="answer-text-label text-justify" *ngIf="answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.Waiting || answer?.answerContent?.gptEvalStatus === 99">
            {{"Key.WaitForAIComment" | translate}}
        </div>
    </div>
</div>