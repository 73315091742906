import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CredentialsService } from '@app/core';
import { ORGADMIN } from '@app/shared/common/constants';
import { ELicensePaymentStatus, LanguageEnum, LicensePlanEnum, LicensePlanPriceType, LicenseTypeEnum, UserGroupCodeEnum } from '@app/shared/common/enums';
import { OrganizationPlan } from '@app/vinter/models/license-plan-list-item';
import { LicensePlanUpgradeItem } from '@app/vinter/models/license/license-plan-upgrade-item';
import { MailService } from '@app/vinter/services/mail.service';
import { MembershipService } from '@app/vinter/services/membership.service';
import { LicensePlanService } from '@app/vinter/services/license-plan.service';
import { OrganizationService } from '@app/vinter/services/organization.service';
import { AppInfoService } from '@app/shared/services/app-info.services';

@Component({
  selector: 'app-license-plan',
  templateUrl: './license-plan.component.html',
  styleUrls: ['./license-plan.component.scss']
})

export class LicensePlanComponent implements OnInit {
  organizationValidLicensePlans: OrganizationPlan[] = [];
  organizationPendinLicensePlans: OrganizationPlan[] = [];
  priceTypeEnum = LicensePlanPriceType;
  licencePlanEnum = LicensePlanEnum;
  isUpgradePlanDialogVisible = false;
  isUpgradePlanSentDialogVisible = false;
  isGoPaymentDialogVisible = false;
  clickedLicensePlan: LicensePlanUpgradeItem;
  formattedActivationDate: string;
  formattedExpirationDate: string;
  isUserOrgAdmin: boolean = false;
  istemplateVisible: boolean; 
  unPaidLicense: boolean = false;
  noLicense: boolean = false;
  licensePlanDialogVisible: boolean = false;
  dataLoaded: boolean = false;
  currentLanguageId:string;
  LanguageEnum = LanguageEnum;
  isShow = false;
  shownLicenseCount:number;
  licenseOrgInfoId: string;
  isUnsubscriptionSuccessful: boolean
  licenseExpirationDate: Date;
  isCheckoutSuccessful: boolean;
  isTriedPayment: boolean;
  isUnsubscribed: boolean;
  isFreeTrial: boolean;
  isPaymentFailed: boolean;
  licenseOrganizationInfoIds: string[] = [];

    constructor(
    private licenseService: LicensePlanService,
    private mailService: MailService,
    private membershipService: MembershipService,
    private credentialService: CredentialsService,
    private appInfoService: AppInfoService
    ) { }

  ngOnInit(): void {
    this.currentLanguageId = this.credentialService.getLanguage();
    this.isOrgAdmin();
    this.getOrganizationPlan();
  }

  getOrganizationPlan() {
    this.licenseService.getOrganizationLicenseList().subscribe(plans => {
      this.organizationValidLicensePlans = plans.validLicenseList.filter(plan => plan.licenseType === LicenseTypeEnum.MainLicense);;
      this.organizationPendinLicensePlans = plans.pendingLicenseList;
      let validLicenseInfoIds = [];
      this.organizationValidLicensePlans.forEach(element => {
        validLicenseInfoIds.push(element.licenseOrganizationInfoId)
        element.subscriptionTypeName = this.appInfoService.getLicenseTypeName().find(x=>x.id === element.paymentPeriod)?.name;
        this.licenseOrgInfoId = element.licenseOrganizationInfoId;
        this.isUnsubscribed = element.isCustomerUnsubscribed;
        this.isFreeTrial = element.isFreeTrial;
        this.licenseExpirationDate = element.expirationDate;
        });
      this.checkPaymentStatus(validLicenseInfoIds);
      this.organizationPendinLicensePlans.forEach(element => {
        this.licenseOrganizationInfoIds.push(element.licenseOrganizationInfoId)
        element.subscriptionTypeName = this.appInfoService.getLicenseTypeName().find(x=>x.id === element.paymentPeriod)?.name;
        this.licenseOrgInfoId = element.licenseOrganizationInfoId;
        this.isUnsubscribed = element.isCustomerUnsubscribed;
        this.isFreeTrial = element.isFreeTrial;
        this.licenseExpirationDate = element.expirationDate;
        });
        this.checkPaymentStatus(this.licenseOrganizationInfoIds);
        if(this.organizationPendinLicensePlans != null && this.organizationPendinLicensePlans != undefined && this.organizationPendinLicensePlans.length > 0){
              this.unPaidLicense = true;
        }
        if((this.organizationValidLicensePlans == null || this.organizationValidLicensePlans == undefined || this.organizationValidLicensePlans.length == 0) 
        && (this.organizationPendinLicensePlans == null || this.organizationPendinLicensePlans == undefined || this.organizationPendinLicensePlans.length == 0)){
          this.noLicense = true;
        }

        this.numberOfLicensesToBeShown();
        this.dataLoaded = true;
    });
  }

  openLicensePlanDialog(){
    this.licensePlanDialogVisible = true;
  }

  onRefreshPage() {
    window.location.reload(); 
  }

  showLicenseUnsubscribeButton(){
    this.isShow = true;
  }
  onUnsubscribed(event: boolean) {
    this.isUnsubscriptionSuccessful = event;
  }
  templateVisiblity(event){
    this.istemplateVisible = event;
}

numberOfLicensesToBeShown(){
  this.shownLicenseCount = this.organizationValidLicensePlans.filter(x => x.applicantUsage != x.applicantLimit).length;
}

  sendUpgradeLicenseMail() {
    this.mailService.SendUpgradeLicenseMail(this.clickedLicensePlan).subscribe(x => {
      this.isUpgradePlanDialogVisible = false;
      this.isUpgradePlanSentDialogVisible = true;
    });
  }

  openPaymentCheckoutSessionDialog() {
    this.isGoPaymentDialogVisible = true;
  }

  checkPaymentStatus(licenseOrganizationInfoIds: string[]){
    this.membershipService.checkPaymentStatus({licenseOrganizationInfoIds}).subscribe(result => {
      this.isPaymentFailed = result.isFailed;
      this.isTriedPayment = true;
    });
  }

  redirectToStripeCheckoutSession(licenseOrganizationInfoIds: string[]){
    this.membershipService.requestMemberSession({licenseOrganizationInfoIds }).subscribe((session) => {
      this.isCheckoutSuccessful = session.success
      this.membershipService.redirectToCheckout(session);
      this.checkPaymentStatus(licenseOrganizationInfoIds);
  });
  }



  isOrgAdmin(){
    var loginUserUserGroups = this.credentialService.getUserGroups().split(',');
    if(loginUserUserGroups.some(s => s.includes(ORGADMIN)))
        this.isUserOrgAdmin = true;
    else
        this.isUserOrgAdmin = false;
}

}
@Pipe({
  name: 'applicant'
})
export class ApplicantPipe{
    }
  
