import { DropdownModule } from 'primeng/dropdown';
import { NgModule } from '@angular/core';
import { VinterCodeEditor } from '@app/shared/components/vinter-code-editor/vinter-code-editor.component';
import * as ace from "ace-builds";
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    VinterCodeEditor
  ],
  imports: [
    ButtonModule,
    DropdownModule,
    FormsModule,
    CommonModule,
    TranslateModule
  ],
  exports: [
    VinterCodeEditor,
    ]
})
export class SharedVinterCodeEditorModule { }
