import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PictureUploaderComponent } from '@app/vinter/components/uploader/picture-uploader/picture-uploader.component';
import { PictureUploaderSimpleComponent } from '@app/vinter/components/uploader/picture-uploader-simple/picture-uploader-simple.component';
import { VideoUploaderComponent } from '@app/vinter/components/uploader/video-uploader/video-uploader.component';
import { VideoUploaderSimpleComponent } from '@app/vinter/components/uploader/video-uploader-simple/video-uploader-simple.component';
import { AudioUploaderSimpleComponent } from '@app/vinter/components/uploader/audio-uploader-simple/audio-uploader-simple.component';
import { ToastModule } from 'primeng/toast';
import { FileUploadModule } from 'primeng/fileupload';
import { ImageModule } from 'primeng/image';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { OrganizationEditComponent } from '@app/vinter/components/organization/edit-organization/organization-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PositionStepQuestionListComponent } from '@app/vinter/components/position-template/position-preview/position-step-question-list/position-step-question-list.component';
import { SharedCustomChipVinterModule } from './shared-custom-chip.module';
import { TooltipModule } from 'primeng/tooltip';
import { BadgeModule } from 'primeng/badge';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TagModule } from 'primeng/tag';
import { SharedLicensePlanDialogVinterModule } from './shared-license-plan-dialog.module';
import { PositionPreviewComponent } from '@app/vinter/components/position-template/position-preview/position-preview.component';



@NgModule({
  declarations: [
    PositionStepQuestionListComponent,
  ],
  imports: [
    CommonModule,
    DialogModule,
    FormsModule,
    ConfirmDialogModule,
    ToastModule,
    TranslateModule,
    SharedLicensePlanDialogVinterModule,
    TooltipModule,
    ImageModule,
    BadgeModule,
    RadioButtonModule,
    TagModule,
    SharedCustomChipVinterModule,
    ReactiveFormsModule
  ],
  exports: [
    PositionStepQuestionListComponent,
  ]
})
export class SharedPositionStepQuestionListVinterModule { }
