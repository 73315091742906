import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LocalizationService } from '@app/core';
import { QuestionExpectedAnswerType } from '@app/shared/common/enums';
import { GeneralApplicationFormService } from '@app/vinter/services/general-application-form.service';

@Component({
  selector: 'app-applicant-general-application-form',
  templateUrl: './applicant-general-application-form.component.html',
  styleUrls: ['./applicant-general-application-form.component.scss']
})
export class ApplicantGeneralApplicationFormComponent {

  @Input() generalApplicationFormId: string;
  @Input() hidden: boolean;
  generalApplicationForm: any = [];
  selectedQuestionId: any;
  QuestionExpectedAnswerType = QuestionExpectedAnswerType;
  sidebarVisible: boolean = false;
  @Output() generalApplicationTab: EventEmitter<any> = new EventEmitter<any>();
  @Output() generalApplicationCVInfo: EventEmitter<any> = new EventEmitter<any>();
  hiddenClass: string = '';


  constructor(
    private generalApplicationFormService: GeneralApplicationFormService,
  
  ) { }

  ngOnInit(){
    if(this.hidden){
      this.hiddenClass = 'hidden';
    }
    this.generalApplicationFormService.getApplicantFormWithQuestionAndAnswers(this.generalApplicationFormId).subscribe(data => {
      this.generalApplicationForm = data;
      this.generalApplicationCVInfo.emit(data);
    });
    this.generalApplicationTab.emit(true);
  }

  getAnsweredQuestion(id){
    return this.generalApplicationForm.answers.find(x => x.questionId === id).isAnswered;
  }

  getAnswerByQuestionId(index){
    this.selectedQuestionId = this.generalApplicationForm.questions.find(x => x.order === (index+1)).id;
    this.sidebarVisible = true;
  }

  onHideSidebar(event){
    this.sidebarVisible = false;
  }

}
