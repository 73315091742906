import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CredentialsService, LocalizationService } from '@app/core';
import { EGeneralApplicationFormAccountLinkType, LanguageEnum,PositionStepKanbanStaticTypes } from '@app/shared/common/enums';
import { KanbanList } from '@app/vinter/models/shared/kanban';
import { UserAccountLinkItem, UserManagementListItem } from '@app/vinter/models/user-profile-list-item';
import { ApplicationStepService } from '@app/vinter/services/application-step.service';
import { ApplicationService } from '@app/vinter/services/application.service';
import { PositionService } from '@app/vinter/services/position.service';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { InvitationService } from '@app/vinter/services/invitation.service';
import { GeneralApplicationInvitationService } from '@app/vinter/services/general-application-invitation.service';
import { eyeIconOutline } from '@app/shared/common/constants';

@Component({
  selector: 'app-applicant-info',
  templateUrl: './applicant-info.component.html',
  styleUrls: ['./applicant-info.component.scss']
})
export class ApplicantInfoComponent implements OnInit {

  applicant: UserManagementListItem;
  applicantFullName: string;
  responsibleFullName: string;
  applicantEmail: string;
  applicantPhoneNumber: string
  applicantTitle: string;
  responsibleId: string;
  applicantId: string;
  positionId: string;
  positionTitle: string;
  form: FormGroup;
  responsibleFirstName: string;
  responsibleLastName: string;
  responsibleTitle: string = "-";
  responsibleImageURL: string;
  githubUrl: string = "-";
  linkedinProfileURL: string = "-";
  imageURL: string = null;
  imageURLBase64: any = null;
  applicantFirstName: string;
  applicantLastName: string;
  pdfFileName:string;
  isKanbanChanged: boolean = true;
  kanbanChangedMessage: string = "";
  PositionStepKanbanStaticTypes=PositionStepKanbanStaticTypes;
  suggestSidebarVisible: any;
  userAccountLinks:UserAccountLinkItem[] = [];
  EGeneralApplicationFormAccountLinkType = EGeneralApplicationFormAccountLinkType;
  socialPlatformsDialogVisible: boolean = false;
  isLockedLicenseDialogVisible: boolean;
  isLocked: boolean;

  selectedStar = 0;
  popupOpen = false;
  ratings: number[] = [];
  @Input() overallRating: number;
  @Input() currentStepId: string;
  @Input() applicationId: string;
  @Output() positionStepIdOutput: EventEmitter<any> = new EventEmitter<any>();
  @Input() kanbanData: any;
  @Input() applicationStepsData: any[];
  @Input() generalApplicationFormInfo: any;

  storedPreviousStepID: string;
  createdAt: string;
  lang: string;
  lastActivity: Date;
  daysDiff: number;
  isCopied: boolean = false;
  kanbanSteps: any[];
  deviceTypes: any[];
  questionCategories: any[];
  questionCollectionCategories: any[];
  isPdfViewerVisible = false;
  cvButtonHover = false;
  pdfAsLink: string;
  eyeBlueIconPath = eyeIconOutline;
  applicantInvitedPositions: any[] = [];

  constructor(private applicationService: ApplicationService,
    private positionService: PositionService,
    public translate: LocalizationService,
    public route: ActivatedRoute,
    private credentialService: CredentialsService,
    private applicationStepService: ApplicationStepService,
    public router: Router,
    private appInfoService: AppInfoService,
    private invitationService: InvitationService,
    private generalApplicationInvitationService: GeneralApplicationInvitationService,
    ) { }

  ngOnInit(): void {

    this.lang = this.translate.langCode;
    this.getPositionKanbanSteps();
    this.getApplicantInfo();
    this.questionCategories = this.appInfoService.getQuestionCategories();
    this.questionCollectionCategories = this.appInfoService.getQuestionCollectionCategoryArray()
    this.storedPreviousStepID = this.currentStepId;

  }

  getPositionKanbanSteps() {
    const languageId = this.credentialService.getLanguage();
    this.applicationStepService.GetApplicationSteps(this.kanbanData.positionId).subscribe(resp => {
      resp.forEach(element => {
        element.isDisabled = false;
      });
      this.kanbanSteps = resp;

      if (languageId === LanguageEnum.TurkishTurkey) {
        this.kanbanSteps.forEach(element => {
          if (element.id === "-2") {
            element.title = this.translate.translateText("HRArchived");
          }
          else if (element.id === "-3") {
            element.title = this.translate.translateText("HRRejected");
          }
          else if (element.id === "-4") {
            element.title = this.translate.translateText("HROffer");
          }
          else if (element.id === "-5") {
            element.title = this.translate.translateText("Hired");
          }
          else if (element.id === "-6") {
            element.title = this.translate.translateText("ShortList");
          }
        });
      }
    });
  }

  copyText(email: string) {
    this.isCopied = true;
    const textArea = document.createElement('textarea');
    textArea.value = email;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);

    setTimeout(() => {
      this.isCopied = false;
    }, 1000);

  }

  getRating() {
    let rating = this.form.controls['rating'].value;
    return rating;
  }

  getApplicantInfo() {
    this.applicationService.GetDataByFilterWithApplicant({ expectedApplicantId: this.kanbanData.applicantId, positionId: this.kanbanData.positionId }).subscribe(result => {
      this.applicantFullName = result.applicant.firstName + " " + result.applicant.lastName;
      this.applicantFirstName = result?.applicant?.firstName;
      this.applicantLastName = result?.applicant?.lastName;
      this.applicantEmail = result.applicant.email;
      this.applicantPhoneNumber = result.applicant.phone;
      this.githubUrl = result.applicant.userProfile?.gitHubProfileURL;
      this.linkedinProfileURL = result.applicant.userProfile?.linkedinProfileURL;
      this.imageURL = result.applicant.userProfile?.imageURL;

      if(this.imageURL!=null){
        let decodedContent = atob(result.applicant.userProfile?.imageURLBase64);
        let byteArray = new Uint8Array(decodedContent.length);
        for (let i = 0; i < decodedContent.length; i++) {
          byteArray[i] = decodedContent.charCodeAt(i);
        }
        this.imageURLBase64 = URL.createObjectURL(new Blob([byteArray]));
      }

      this.userAccountLinks = result?.applicant?.userAccountLinks;
      this.createdAt = result.createdAt;
      this.lastActivity = new Date(Date.parse(result.applicant.updatedAt));
      this.lastActivityDiff();
      this.responsibleTitle = result.applicationPosition.responsibleUser.title;
      this.responsibleFirstName = result.applicationPosition.responsibleUser.firstName;
      this.responsibleLastName = result.applicationPosition.responsibleUser.lastName;
      this.responsibleFullName = result.applicationPosition.responsibleUser.firstName + " " + result.applicationPosition.responsibleUser.lastName;
      this.responsibleImageURL = result.applicationPosition.responsibleUser.userProfile?.imageURL;

      this.generalApplicationInvitationService.getListByFilter({email: this.applicantEmail }).subscribe((response) => {
        this.applicantInvitedPositions = response;
      });

    });
    this.positionService.getDataByFilter({ Id: this.kanbanData.positionId }).subscribe(position => {
      this.positionTitle = position.title;
    })
  }

  showPopup() {
    this.popupOpen = true;
  }

  rate(rating: number) {
    this.selectedStar = rating;
  }

  lastActivityDiff() {
    let currentDate = new Date();
    let timeDiff = Math.abs(currentDate.getTime() - this.lastActivity.getTime());
    this.daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24));
  }

  closePopup(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (target.classList.contains('popup') || target.classList.contains('p-rating')) {
      return;
    }
    this.popupOpen = false;
  }

  getStepColor(stepId: string): string {
    switch (stepId) {
      case '-1':
        return '#2962FF';
      case '-2':
        return '#7173AC';
      case '-3':
        return '#F20505';
      case '-4':
        return '#E9B500';
      case '-5':
        return '#5ED44A';
      case '-6':
        return '#df739c';
      default:
        return '#2962FF';
    }
  }


  getSelectableSteps(steps: any[], currentStepId: any): any[] {
    if(steps){
      if (currentStepId === PositionStepKanbanStaticTypes.Archived || currentStepId === PositionStepKanbanStaticTypes.Rejected || currentStepId === PositionStepKanbanStaticTypes.Invitation || currentStepId === PositionStepKanbanStaticTypes.Hired) {
        return steps.filter(step=>step.id==currentStepId)
      }
      else if(currentStepId=== PositionStepKanbanStaticTypes.Offer){
          return steps.filter(step=>step.id==PositionStepKanbanStaticTypes.Archived || step.id==PositionStepKanbanStaticTypes.Hired || step.id==PositionStepKanbanStaticTypes.Rejected || step.id==currentStepId)
      }
      else if(currentStepId === PositionStepKanbanStaticTypes.ShortList){
          return steps.filter(step=>step.id==PositionStepKanbanStaticTypes.Archived || step.id==PositionStepKanbanStaticTypes.Offer || step.id==PositionStepKanbanStaticTypes.Rejected || step.id==currentStepId)
      }
      else{
        return steps.filter(step=>{
          if(step.id==PositionStepKanbanStaticTypes.ShortList || step.id==PositionStepKanbanStaticTypes.Archived || step.id==PositionStepKanbanStaticTypes.Rejected)
            return true;
          if(step.id==currentStepId)
            return true;
          const currentOrder = steps.find(step => step.id === currentStepId)?.order || 0;
          if (step.order > 0 && step.order == (currentOrder+1))
            return true;
          return false;
        })
      }
    }
  }

  applicantStepChanged(event) {
    var applicantStepChangeData = {
      previousApplicationStep: {
        Id: this.kanbanData.applicationStepId ?? false ? this.kanbanData.applicationStepId : this.currentStepId,
        applicationId: this.applicationId,
        xmin: this.kanbanData.applicationStepXmin ? this.kanbanData.applicationStepXmin : this.kanbanData.applicationXmin
      },
      nextPositionStepID: event.value,
      isArchived: false,
    }
    this.positionService.changeApplicationStepByKanban(applicantStepChangeData).subscribe(x => {
      this.getPositionKanbanSteps();
      let test = this.applicationStepsData.find(x=> x.positionStep.id === event.value);
      this.positionStepIdOutput.emit({currentStepId:event.value, applicationStepId:test.id});
    },
      err => {
        this.isKanbanChanged = false;
        this.kanbanChangedMessage = err;
      }
    );
  }

  cancelStepChange() {
    this.isKanbanChanged = true;
    this.currentStepId = this.storedPreviousStepID;
  }

  closePdfViewPage(){
    this.isPdfViewerVisible = false;
  }

  onClickSeePdf() {
    const decodedContent = atob(this.generalApplicationFormInfo.cvFile);
      const byteArray = new Uint8Array(decodedContent.length);
      for (let i = 0; i < decodedContent.length; i++) {
        byteArray[i] = decodedContent.charCodeAt(i);
      }
    this.pdfAsLink = URL.createObjectURL(new Blob([byteArray], { type: 'application/pdf' }));
    this.isPdfViewerVisible = true;
    this.pdfFileName = this.generalApplicationFormInfo.firstName + this.generalApplicationFormInfo.lastName + '_CV.pdf';
  }
  openSocialPlatformDialog(){
    this.socialPlatformsDialogVisible = !this.socialPlatformsDialogVisible;
  }
  closeLicenseLockedDialog(){
    this.isLockedLicenseDialogVisible = false;
  }

  showLicenseLockedDialog(){
    this.isLockedLicenseDialogVisible = true;
  }
}
