import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-performance-chart',
  templateUrl: './performance-chart.component.html',
  styleUrls: ['./performance-chart.component.scss']
})
export class PerformanceChartComponent {

  @Input() progressData;
  
  Math = Math;

  getSteps(value: number): number[] {
    return Array(10)
      .fill(0)
      .map((_, index) => index + 1);
  }

}
