<div *ngFor="let trait of oceanTraits" class="mt-2">
    <div class="grid">
        <div class="col-2 ocean-icon-width align-content-center custom-display">
            <p-badge class="{{trait.cssClass}}" size="large" value="{{trait.key.toUpperCase()}}"></p-badge>
        </div>
        <div class="col-10 flex flex-wrap align-content-center font-semibold" [ngClass]="trait.cssClass">
            {{ trait.title | translate }}
        </div>

        <ng-container *ngFor="let progressBarClass of trait.progressBarClasses; let i = index">
            <div class="col-3 px-0 align-content-center custom-display custom-width-18"
                [ngClass]="{'mr-1 ml-4': i === 0, 'mx-1': i > 0}" [style.backgroundColor]="levels[i].backgroundColor">
                <p-progressBar [class]="progressBarClass" [value]="trait.progressBarOceanScore - (i * 100)"
                    [showValue]="false">
                </p-progressBar>
            </div>
        </ng-container>

        <div class="col-1 p-0 ml-1 flex align-content-center flex-wrap" style="width: 3%">
            <p class="font-semibold" [ngClass]="trait.cssClass">{{trait.score}}</p>
        </div>

        <div *ngFor="let level of levels; let i = index" [ngClass]="i === 0 ? 'mr-1 ml-4' : 'mx-1'"
            class="col-3 px-0 flex justify-content-center font-semibold custom-width-18"
            [style.backgroundColor]="level.backgroundColor">
            {{ level.label | translate }}
        </div>

        <div class="no-border-grid grid mt-4 mb-4">
            <div class="left-text-field font-medium line-height-3 p-4" style="width: 37%">
                <p>{{ trait.lowDescription | translate }}</p>
            </div>
            <div style="width: 16%"></div>
            <div class="right-text-field font-medium line-height-3 p-4 text-right" style="width: 37%">
                <p>{{ trait.highDescription | translate }}</p>
            </div>
            <div style="width: 10%"></div>
        </div>

    </div>
</div>


<p-sidebar class="side-bar-width" [(visible)]="openViewSidebar" position="right" [showCloseIcon]="false" [blockScroll]="true">

    <ng-template pTemplate="header">
        <h3 class="ml-2">{{ 'Key.Big5(OCEAN)PersonalityInventoryResults' | translate }}</h3>
        <div class="flex">
            <div class="spacer p-3 -ml-1" style="flex-basis: 35%;"></div>
            <div *ngFor="let level of levels" 
                class="flex flex-wrap mx-1 align-content-center justify-content-center py-3 font-semibold"
                [style.width.%]="12"
                [style.backgroundColor]="level.backgroundColor">
                {{ level.label | translate }}
            </div>
            <div class="spacer p-3 mr-3" style="flex-basis: 5%;"></div>
        </div>

    </ng-template>
    

    <div *ngFor="let trait of oceanTraits">
        <div class="flex analysis-grid mb-2">
            <div class="py-3 px-2" style="width: 5%">
                <p-badge class="{{trait.cssClass}}" size="large" value="{{trait.key.toUpperCase()}}"></p-badge>
            </div>
            <div class="flex flex-wrap align-content-center font-semibold py-3 px-2" [ngClass]="trait.cssClass"
                style="width: 30%">
                {{trait.title | translate}}
            </div>

            <ng-container *ngFor="let progressBarClass of trait.progressBarClasses; let i = index">
                <div class="custom-display align-content-center py-3" style="width: 12%"
                    [ngClass]="{'mr-1': i === 0, 'mx-1': i > 0}" [style.backgroundColor]="levels[i].backgroundColor">
                    <p-progressBar [class]="progressBarClass" [value]="trait.progressBarOceanScore - (i * 100)"
                        [showValue]="false">
                    </p-progressBar>
                </div>
            </ng-container>

            <div class="flex align-content-center flex-wrap py-3 px-2" style="width: 5%">
                <p class="font-semibold" [ngClass]="trait.cssClass">{{trait.score}}</p>
            </div>
        </div>
    </div>

    <div *ngFor="let trait of oceanTraits;  let i = index">
        <div class="flex analysis-grid" [ngClass]="{'mt-2': i > 0}">
            <div class="flex justify-content-center semibold p-3" [ngClass]="trait.key + '-vertical-writing'"
                style="width: 5%">
                {{trait.title | translate}}
            </div>
            <div class="p-2" style="width: 95%">
                <div *ngFor="let item of trait.analysisData" class="flex detail-analysis-grid mt-2">
                    <div class="font-semibold py-2 pl-2 mr-3" style="width: 30%" [ngClass]="trait.cssClass">
                        {{ item.key | translate }}
                    </div>

                    <ng-container *ngFor="let progressBarClass of trait.progressBarClasses; let i = index">
                        <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16%"
                            [ngClass]="{'mr-1': i === 0, 'mx-1': i > 0}"
                            [style.backgroundColor]="levels[i].backgroundColor">
                            <p-progressBar [class]="progressBarClass"
                                [value]="(oceanScore[item.scoreKey] * 5)- (i * 100)" [showValue]="false">
                            </p-progressBar>
                        </div>
                    </ng-container>

                    <div class="flex align-content-center flex-wrap py-2 px-1" style="width: 4.2%">
                        <p class="font-semibold" [ngClass]="trait.cssClass">{{ oceanScore[item.scoreKey] }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</p-sidebar>