import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpiderChartComponent } from '@app/shared/components/spider-chart/spider-chart.component';
import { BarChartComponent } from '@app/shared/components/bar-chart/bar-chart.component';
import { CircleChartComponent } from '@app/shared/components/circle-chart/circle-chart.component';
import { ChartModule } from 'primeng/chart';
import { KnobChartComponent } from '@app/shared/components/knob-chart/knob-chart.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { KnobModule } from 'primeng/knob';
import { GaugeChartComponent } from '@app/shared/components/gauge-chart/gauge-chart.component';
import { PerformanceChartComponent } from '@app/shared/components/performance-chart/performance-chart.component';
import { DotChartComponent } from '@app/shared/components/dot-chart/dot-chart.component';



@NgModule({
  declarations: [
    SpiderChartComponent,
    BarChartComponent,
    CircleChartComponent,
    KnobChartComponent,
    GaugeChartComponent,
    PerformanceChartComponent,
    DotChartComponent
  ],
  imports: [
    CommonModule,
    ChartModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    KnobModule
  ],
  exports: [
    SpiderChartComponent,
    BarChartComponent,
    CircleChartComponent,
    KnobChartComponent,
    GaugeChartComponent,
    PerformanceChartComponent,
    DotChartComponent
  ]
})
export class SharedPdfChartModule { }
