import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocalizationService } from '@app/core';
import { EQuestionCategories, QuestionCollectionCategoryEnum, QuestionExpectedAnswerType } from '@app/shared/common/enums';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { ApplicationStepService } from '@app/vinter/services/application-step.service';

@Component({
  selector: 'app-change-vinter-score-sidebar',
  templateUrl: './change-vinter-score-sidebar.component.html',
  styleUrls: ['./change-vinter-score-sidebar.component.scss']
})
export class ChangeVinterScoreSidebarComponent implements OnInit{
  
  sidebarVisible = true;
  @Output() hideSidebar: EventEmitter<any> = new EventEmitter<any>();

  questionText : any;
  answerText : any;
  imageUrl: string;
  questionType : any;
  order : any;
  QuestionExpectedAnswerType = QuestionExpectedAnswerType;
  choices : any [];
  selectedAnswer: any;
  selectedRadioValue: any;
  questionAnswered : any;
  questionId : any;
  value: number = 20;
  content: string;
  firstName = "Betul";
  lastName = "Kırçıl";
  isEditorHidden: boolean = true;
  text: string = '<div>Hello World!</div><div>PrimeNG <b>Editor</b> Rocks</div><div><br></div>';

  ngOnInit(){
  }

  addTextToEditor(code: string) {
    // if (this.editor) {
    //     const quill = this.editor.quill;
    //     const selection = quill.getSelection(true);

    //     if (selection) {
    //         const position = selection.index + selection.length;
    //         quill.insertText(position, code);
    //     } else {
    //         quill.insertText(0, code);
    //     }
    //     const editorContent = quill.root.innerHTML;
    //     this.content = editorContent;
    // }
}
  hideSidebarEmit(){
    this.hideSidebar.emit(true);
  }

  showEditor(){
    this.isEditorHidden = false;
  }
  isCorrectAnswer(questionChoices, answer){
    return questionChoices.find(x => x.id === answer).isCorrect;
  }

}