import { Component, ElementRef, Input, OnInit, Pipe, PipeTransform, SimpleChanges, ViewChild } from '@angular/core';
import { EApplicantBehaviourEventType, EntityStateEnum, GPTEvalStatus, ProcessStatus, TranscriptStatus } from '@app/shared/common/enums';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { AnswerContentFormItem } from '@app/vinter/models/answer-content-form-item';
import { VideoAnswerItem } from '@app/vinter/models/answer/video-answer-item';
import { AnswerContentService } from '@app/vinter/services/answer-content.service';
import { ApplicantBehaviourEventLogService } from '@app/vinter/services/applicant-behaviour-event-log.service';
import { VgApiService } from '@videogular/ngx-videogular/core';

@Component({
  selector: 'app-video-answer',
  templateUrl: './video-answer.component.html',
  styleUrls: ['./video-answer.component.scss']
})
export class VideoAnswerComponent implements OnInit{

  @Input() answer: VideoAnswerItem;
  gptEvalStatusEnum = GPTEvalStatus;
  subtitles: any[] = [];
  @ViewChild('transcriptDiv') transcriptDiv: ElementRef;
  activeIndex: number = 0;
  @ViewChild('targetVideo') target: ElementRef;
  questionPageHiddenLog: number;
  voiceSpeechSpeedTypes: any[];
  emotionScores: { [emotion: string]: number } = {};
  emotionArray: any[] = [];
  impressionArray: any[] = [];
  voiceArray: any[] = [];
  TranscriptStatus = TranscriptStatus;
  answerContentURL: string;

  speechSpeed = "speechSpeed";

  emotionColors = {
    angry: '#FE5F55',
    disgust: '#3AB1C8',
    fear: '#7ECA9C',
    happy: '#FFA372',
    sad: '#1597E5',
    surprise: '#9E67BF',
    neutral: '#BEBBAD',
    facenotfound: '#FFFFFF'
};

constructor(public api: VgApiService,
  public answerContentService: AnswerContentService,
  private appInfoService: AppInfoService,
  private eventLogService: ApplicantBehaviourEventLogService
){}

ngOnChanges(changes: SimpleChanges): void {
  if(this.answer){
    if(this.answer?.answerContent ?? false){
      if (this.target) {
        this.target.nativeElement.load();
      }
      if (this.answer?.answerContent?.subtitle != null) {
      this.setSubTitles();
    }
    }
    
    this.setEmotionEntries();
    this.setImpressionEntries();
    this.setVoiceEntries();
    this.getPageHiddenLog();
  }
  this.voiceSpeechSpeedTypes = this.appInfoService.getVoiceSpeechSpeedTypes();
}

  ngOnInit(): void {
    if(this.answer){
      if (this.answer?.answerContent?.subtitle != null) {
        this.setSubTitles();
      }
      this.setEmotionEntries();
      this.setImpressionEntries();
      this.setVoiceEntries();
      this.getPageHiddenLog();
    }
    this.voiceSpeechSpeedTypes = this.appInfoService.getVoiceSpeechSpeedTypes();

  }

  highlightSubtitle(target: any) {
    this.subtitles.forEach((subtitle, index) => {
        subtitle.id = 's' + index;
        subtitle.highlighted = target.currentTime >= subtitle.start && target.currentTime < subtitle.end;
    });

    this.highlightSubtitleInView();
}

highlightSubtitleInView() {
  let activeSubtitle = this.subtitles.find(subtitle => subtitle.highlighted);

  if (activeSubtitle) {
      let highlightedElement = this.transcriptDiv.nativeElement.querySelector(`#s${activeSubtitle.id}`);

      if (highlightedElement) {
          highlightedElement.scrollIntoView({
              behavior: "smooth",
              block: "nearest"
          });
      }
  }
}

setSubTitles(){
    let all = (JSON.parse(this.answer?.answerContent?.subtitle.toString()));
    this.subtitles = all.segments;
    const emotionTotals: { [emotion: string]: number } = {};
    const totalSubtitles = this.subtitles.reduce((sum, item) => sum + item.emotion_score, 0);
    this.subtitles.forEach((subtitle) => {
        const emotion = subtitle.emotion.toLowerCase();
        emotionTotals[emotion] = (emotionTotals[emotion] || 0) + subtitle.emotion_score;
    });

    for (const emotion in emotionTotals) {
        if (emotionTotals.hasOwnProperty(emotion)) {//---- kontrol gerekebilir
            this.emotionScores[emotion] = (emotionTotals[emotion] / totalSubtitles) * 100;
        }
    }
}

jumpToSecond(start: number) {
  if (this.api) {
      this.target.nativeElement.currentTime = start + 0.00001;
      this.highlightSubtitle(this.target.nativeElement);
      this.target.nativeElement.play();
  }
}

getColor(emotion: string, score: number): string {
  const alpha = Math.min(1, score / 100);
  const originalColor = this.emotionColors[emotion.toLowerCase()] || '#FFFFFF';
  const computedColor = (score !== -1) ? (originalColor.slice(0, 7) + Math.round(alpha * 255).toString(16).toUpperCase()) : (originalColor.slice(0, 7));
  return computedColor;
}

setGptStatus(){
  let answerContentForm : AnswerContentFormItem = new AnswerContentFormItem();
  answerContentForm = this.answer?.answerContent;
  answerContentForm.gptEvalStatus = this.gptEvalStatusEnum.Waiting;
  answerContentForm.processStatus = ProcessStatus.AIContainerWaiting;
  answerContentForm.entityState = EntityStateEnum.Modified;
  answerContentForm.xmin = this.answer?.answerContent?.xmin;

  this.answerContentService.SaveAsync(answerContentForm).subscribe();
  // this.toggleSidebar();
}

setEmotionEntries(): [string, number][] {
  const emotionAnalysis = this.answer?.emotionAnalysis || {};
  this.emotionArray = Object.entries(emotionAnalysis)
  .filter(([_, value]) => value != null)
  .map(([name, value]) => ({ name, value }));
  return this.emotionArray;
}

setImpressionEntries(): [string, number][] {
  const impressionAnalysis = this.answer?.impressionAnalysis || {};
  this.impressionArray = Object.entries(impressionAnalysis)
  .filter(([_, value]) => value != null)
  .map(([name, value]) => ({ name, value }));
  return this.impressionArray;
}

setVoiceEntries(): [string, any][] {
  const voiceAnalysis = this.answer?.voiceAnalysis || {};
  this.voiceArray = Object.entries(voiceAnalysis)
  .filter(([_, value]) => value != null)
  .map(([name, value]) => ({ name, value }));
  return this.voiceArray;
}

getPageHiddenLog(){
  let eventLogFilter: any = {};
  eventLogFilter.applicationStepId = this.answer.applicationStepId;
  eventLogFilter.eventType = EApplicantBehaviourEventType.PageHidden;
  eventLogFilter.questionId = this.answer.questionId;
  this.eventLogService.getListByFilter(eventLogFilter).subscribe(eventLog => {
      this.questionPageHiddenLog = eventLog.length;
  });
}

}

@Pipe({
  name: 'voiceSpeechSpeedTypes',
})
export class VoiceSpeechSpeedTypesPipe implements PipeTransform {
  transform(row: any, list: any[]): any {
      return list.find(x => x.id === row).name;
  }
}