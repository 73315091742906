import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DepartmentCheckDialogComponent } from "@app/shared/components/department-check-dialog/department-check-dialog.component";
import { LicenseLockedDialogComponent } from "@app/shared/components/license-locked-dialog/license-locked-dialog.component";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonModule } from "primeng/button";
import { DialogModule } from "primeng/dialog";

@NgModule({
    declarations: [
        LicenseLockedDialogComponent,
        DepartmentCheckDialogComponent,
    ],
    imports: [
      CommonModule,
      DialogModule,
      ButtonModule,
      TranslateModule
    ],
    exports: [
        DepartmentCheckDialogComponent,
        LicenseLockedDialogComponent
    ]
  })
  export class SharedDialogModule { }