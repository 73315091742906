import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService, HttpBaseService, LocalizationService, NotifyService } from '@app/core';
import { ApiControllerEnum } from '@app/shared/common/enums';
import { Observable } from 'rxjs';
import {  UserWorkExperience } from '../models/applicant-education-info';

@Injectable({
  providedIn: 'root'
})
export class ApplicantExperienceInfoService extends HttpBaseService {

  constructor(private http: HttpClient
    , private notifyServ: NotifyService
    , private localizationService: LocalizationService
    , private configService: ConfigurationService) {
    super(http, ApiControllerEnum.ApplicantExperienceInfo, notifyServ, localizationService, configService);
  }

  getListApplicantExperienceInfo(filter: any): Observable<UserWorkExperience[]> {
    return this.post("GetListApplicantExperienceInfo", filter, true);
  }
  save(obj: UserWorkExperience): Observable<any> {
    return this.post("SaveAsync", obj, true);
  }
}