<div class="-ml-5 flex" [ngStyle]="{'margin-bottom': (item.positionStep.questionCollection.subCategory === OCEAN120) ? '-0.5rem' : '4.2rem'}">
    <p-button *ngIf="item.positionStep.questionCollection.subCategory === OCEAN120" type="button" class="analysis-button analysis-button-height align-content-center flex mr-4" (onClick)="OceanScoreSideBar = true" label="{{'Key.ShowAnalysis' | translate}}"></p-button>
    <p-sidebar class="side-bar-width" [(visible)]="OceanScoreSideBar" position="right" [showCloseIcon]="false">
        <h3 class="ml-2">{{'Key.Big5(OCEAN)PersonalityInventoryResults' | translate}}</h3>
        <div class="flex">
            <div class="p-3" style="width: 35%"></div>
            <div class="flex flex-wrap align-content-center justify-content-center py-3 mr-1 font-semibold" style="width: 12% ; background-color: #F8F8F8">{{"Key.VeryLow" | translate}}</div>
            <div class="flex flex-wrap align-content-center justify-content-center py-3 mx-1 font-semibold" style="width: 12% ; background-color: #F5F5F5">{{"Key.Low" | translate}}</div>
            <div class="flex flex-wrap align-content-center justify-content-center py-3 mx-1 font-semibold" style="width: 12% ; background-color: #F1F1F1">{{"Key.Medium" | translate}}</div>
            <div class="flex flex-wrap align-content-center justify-content-center py-3 mx-1 font-semibold" style="width: 12% ; background-color: #F5F5F5">{{"Key.High" | translate}}</div>
            <div class="flex flex-wrap align-content-center justify-content-center py-3 ml-1 font-semibold" style="width: 12% ; background-color: #F8F8F8">{{"Key.VeryHigh" | translate}}</div>
            <div class="p-3" style="width: 5%;"></div>
        </div>

        <div class="flex analysis-grid mb-2">
            <div class="py-3 px-2" style="width: 5%">
                <p-badge class="o-color" size="large" value="O"></p-badge>
            </div>
            <div class="flex flex-wrap align-content-center o-color font-semibold py-3 px-2" style="width: 30%">
                {{"Key.OpennessToExperience" | translate}}
            </div>
            <div class="custom-display align-content-center py-3 mr-1" style="width: 12% ; background-color: #F8F8F8">
                <p-progressBar class="o-color-first" [value]="progressBarOceanScoreO" [showValue]="false"></p-progressBar>
            </div>
            <div class="custom-display align-content-center py-3 mx-1" style="width: 12% ; background-color: #F5F5F5">
                <p-progressBar class="o-color-second" [value]="progressBarOceanScoreO - 100" [showValue]="false"></p-progressBar>
            </div>
            <div class="custom-display align-content-center py-3 mx-1" style="width: 12% ; background-color: #F1F1F1">
                <p-progressBar class="o-color-third" [value]="progressBarOceanScoreO - 200" [showValue]="false"></p-progressBar>
            </div>
            <div class="custom-display align-content-center py-3 mx-1" style="width: 12% ; background-color: #F5F5F5">
                <p-progressBar class="o-color-fourth" [value]="progressBarOceanScoreO - 300" [showValue]="false"></p-progressBar>
            </div>
            <div class="custom-display align-content-center py-3 ml-1" style="width: 12% ; background-color: #F8F8F8">
                <p-progressBar class="o-color-fifth" [value]="progressBarOceanScoreO - 400" [showValue]="false"></p-progressBar>
            </div>
            <div class="flex align-content-center flex-wrap py-3 px-2" style="width: 5%">
                <p class="o-color font-semibold">{{oceanScore.o}}</p>
            </div>
        </div>

        <div class="flex analysis-grid mb-2">
            <div class="py-3 px-2" style="width: 5%">
                <p-badge class="c-color" size="large" value="C"></p-badge>
            </div>
            <div class="flex flex-wrap align-content-center c-color font-semibold py-3 px-2" style="width: 30%">
                {{"Key.Conscientiousness" | translate}}
            </div>
            <div class="custom-display align-content-center py-3 mr-1" style="width: 12% ; background-color: #F8F8F8">
                <p-progressBar class="c-color-first" [value]="progressBarOceanScoreC" [showValue]="false"></p-progressBar>
            </div>
            <div class="custom-display align-content-center py-3 mx-1" style="width: 12% ; background-color: #F5F5F5">
                <p-progressBar class="c-color-second" [value]="progressBarOceanScoreC - 100" [showValue]="false"></p-progressBar>
            </div>
            <div class="custom-display align-content-center py-3 mx-1" style="width: 12% ; background-color: #F1F1F1">
                <p-progressBar class="c-color-third" [value]="progressBarOceanScoreC - 200" [showValue]="false"></p-progressBar>
            </div>
            <div class="custom-display align-content-center py-3 mx-1" style="width: 12% ; background-color: #F5F5F5">
                <p-progressBar class="c-color-fourth" [value]="progressBarOceanScoreC - 300" [showValue]="false"></p-progressBar>
            </div>
            <div class="custom-display align-content-center py-3 ml-1" style="width: 12% ; background-color: #F8F8F8">
                <p-progressBar class="c-color-fifth" [value]="progressBarOceanScoreC - 400" [showValue]="false"></p-progressBar>
            </div>
            <div class="flex align-content-center flex-wrap py-3 px-2" style="width: 5%">
                <p class="c-color font-semibold">{{oceanScore.c}}</p>
            </div>
        </div>

        <div class="flex analysis-grid mb-2">
            <div class="py-3 px-2" style="width: 5%">
                <p-badge class="e-color" size="large" value="E"></p-badge>
            </div>
            <div class="flex flex-wrap align-content-center e-color font-semibold py-3 px-2" style="width: 30%">
                {{"Key.Extraversion" | translate}}
            </div>
            <div class="custom-display align-content-center py-3 mr-1" style="width: 12% ; background-color: #F8F8F8">
                <p-progressBar class="e-color-first" [value]="progressBarOceanScoreE" [showValue]="false"></p-progressBar>
            </div>
            <div class="custom-display align-content-center py-3 mx-1" style="width: 12% ; background-color: #F5F5F5">
                <p-progressBar class="e-color-second" [value]="progressBarOceanScoreE - 100" [showValue]="false"></p-progressBar>
            </div>
            <div class="custom-display align-content-center py-3 mx-1" style="width: 12% ; background-color: #F1F1F1">
                <p-progressBar class="e-color-third" [value]="progressBarOceanScoreE - 200" [showValue]="false"></p-progressBar>
            </div>
            <div class="custom-display align-content-center py-3 mx-1" style="width: 12% ; background-color: #F5F5F5">
                <p-progressBar class="e-color-fourth" [value]="progressBarOceanScoreE - 300" [showValue]="false"></p-progressBar>
            </div>
            <div class="custom-display align-content-center py-3 ml-1" style="width: 12% ; background-color: #F8F8F8">
                <p-progressBar class="e-color-fifth" [value]="progressBarOceanScoreE - 400" [showValue]="false"></p-progressBar>
            </div>
            <div class="flex align-content-center flex-wrap py-3 px-2" style="width: 5%">
                <p class="e-color font-semibold">{{oceanScore.e}}</p>
            </div>
        </div>

        <div class="flex analysis-grid mb-2">
            <div class="py-3 px-2" style="width: 5%">
                <p-badge class="a-color" size="large" value="A"></p-badge>
            </div>
            <div class="flex flex-wrap align-content-center a-color font-semibold py-3 px-2" style="width: 30%">
                {{"Key.Agreeableness" | translate}}
            </div>
            <div class="custom-display align-content-center py-3 mr-1" style="width: 12% ; background-color: #F8F8F8">
                <p-progressBar class="a-color-first" [value]="progressBarOceanScoreA" [showValue]="false"></p-progressBar>
            </div>
            <div class="custom-display align-content-center py-3 mx-1" style="width: 12% ; background-color: #F5F5F5">
                <p-progressBar class="a-color-second" [value]="progressBarOceanScoreA - 100" [showValue]="false"></p-progressBar>
            </div>
            <div class="custom-display align-content-center py-3 mx-1" style="width: 12% ; background-color: #F1F1F1">
                <p-progressBar class="a-color-third" [value]="progressBarOceanScoreA - 200" [showValue]="false"></p-progressBar>
            </div>
            <div class="custom-display align-content-center py-3 mx-1" style="width: 12% ; background-color: #F5F5F5">
                <p-progressBar class="a-color-fourth" [value]="progressBarOceanScoreA - 300" [showValue]="false"></p-progressBar>
            </div>
            <div class="custom-display align-content-center py-3 ml-1" style="width: 12% ; background-color: #F8F8F8">
                <p-progressBar class="a-color-fifth" [value]="progressBarOceanScoreA - 400" [showValue]="false"></p-progressBar>
            </div>
            <div class="flex align-content-center flex-wrap py-3 px-2" style="width: 5%">
                <p class="a-color font-semibold">{{oceanScore.a}}</p>
            </div>
        </div>

        <div class="flex analysis-grid mb-2">
            <div class="py-3 px-2" style="width: 5%">
                <p-badge class="n-color" size="large" value="N"></p-badge>
            </div>
            <div class="flex flex-wrap align-content-center n-color font-semibold py-3 px-2" style="width: 30%">
                {{"Key.Neuroticism" | translate}}
            </div>
            <div class="custom-display align-content-center py-3 mr-1" style="width: 12% ; background-color: #F8F8F8">
                <p-progressBar class="n-color-first" [value]="progressBarOceanScoreN" [showValue]="false"></p-progressBar>
            </div>
            <div class="custom-display align-content-center py-3 mx-1" style="width: 12% ; background-color: #F5F5F5">
                <p-progressBar class="n-color-second" [value]="progressBarOceanScoreN - 100" [showValue]="false"></p-progressBar>
            </div>
            <div class="custom-display align-content-center py-3 mx-1" style="width: 12% ; background-color: #F1F1F1">
                <p-progressBar class="n-color-third" [value]="progressBarOceanScoreN - 200" [showValue]="false"></p-progressBar>
            </div>
            <div class="custom-display align-content-center py-3 mx-1" style="width: 12% ; background-color: #F5F5F5">
                <p-progressBar class="n-color-fourth" [value]="progressBarOceanScoreN - 300" [showValue]="false"></p-progressBar>
            </div>
            <div class="custom-display align-content-center py-3 ml-1" style="width: 12% ; background-color: #F8F8F8">
                <p-progressBar class="n-color-fifth" [value]="progressBarOceanScoreN - 400" [showValue]="false"></p-progressBar>
            </div>
            <div class="flex align-content-center flex-wrap py-3 px-2" style="width: 5%">
                <p class="n-color font-semibold">{{oceanScore.n}}</p>
            </div>
        </div>

        <div class="flex analysis-grid">
            <div class="flex justify-content-center semibold o-vertical-writing p-3" style="width: 5%">
                {{"Key.OpennessToExperience" | translate}}
            </div>
            <div class="p-2" style="width: 95%">
                <div class="flex detail-analysis-grid mt-2">
                    <div class="o-color font-semibold py-2 pl-2 mr-3" style="width: 30%">
                        {{"Key.Imagination" | translate}}
                    </div>
                    <div class="custom-display align-content-center py-2 mr-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="o-color-first" [value]="oceanScore.imagination * 5" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="o-color-second" [value]="(oceanScore.imagination * 5) - 100" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F1F1F1">
                        <p-progressBar class="o-color-third" [value]="(oceanScore.imagination * 5) - 200" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="o-color-fourth" [value]="(oceanScore.imagination * 5) - 300" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="o-color-fifth" [value]="(oceanScore.imagination * 5) - 400" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="flex align-content-center flex-wrap py-2 px-1" style="width: 4.2%">
                        <p class="o-color font-semibold">{{oceanScore.imagination}}</p>
                    </div>
                </div>

                <div class="flex detail-analysis-grid mt-2">
                    <div class="o-color font-semibold py-2 pl-2 mr-3" style="width: 30%">
                        {{"Key.ArtisticInterest" | translate}}
                    </div>
                    <div class="custom-display align-content-center py-2 mr-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="o-color-first" [value]="oceanScore.artisticInterests * 5" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="o-color-second" [value]="(oceanScore.artisticInterests * 5) - 100" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F1F1F1">
                        <p-progressBar class="o-color-third" [value]="(oceanScore.artisticInterests * 5) - 200" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="o-color-fourth" [value]="(oceanScore.artisticInterests * 5) - 300" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="o-color-fifth" [value]="(oceanScore.artisticInterests * 5) - 400" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="flex align-content-center flex-wrap py-2 px-1" style="width: 4.2%">
                        <p class="o-color font-semibold">{{oceanScore.artisticInterests}}</p>
                    </div>
                </div>

                <div class="flex detail-analysis-grid mt-2">
                    <div class="o-color font-semibold py-2 pl-2 mr-3" style="width: 30%">
                        {{"Key.Emotionality" | translate}}
                    </div>
                    <div class="custom-display align-content-center py-2 mr-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="o-color-first" [value]="oceanScore.emotionality * 5" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="o-color-second" [value]="(oceanScore.emotionality * 5) - 100" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F1F1F1">
                        <p-progressBar class="o-color-third" [value]="(oceanScore.emotionality * 5) - 200" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="o-color-fourth" [value]="(oceanScore.emotionality * 5) - 300" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="o-color-fifth" [value]="(oceanScore.emotionality * 5) - 400" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="flex align-content-center flex-wrap py-2 px-1" style="width: 4.2%">
                        <p class="o-color font-semibold">{{oceanScore.emotionality}}</p>
                    </div>
                </div>

                <div class="flex detail-analysis-grid mt-2">
                    <div class="o-color font-semibold py-2 pl-2 mr-3" style="width: 30%">
                        {{"Key.Adventurousness" | translate}}
                    </div>
                    <div class="custom-display align-content-center py-2 mr-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="o-color-first" [value]="oceanScore.adventurousness * 5" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="o-color-second" [value]="(oceanScore.adventurousness * 5) - 100" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F1F1F1">
                        <p-progressBar class="o-color-third" [value]="(oceanScore.adventurousness * 5) - 200" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="o-color-fourth" [value]="(oceanScore.adventurousness * 5) - 300" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="o-color-fifth" [value]="(oceanScore.adventurousness * 5) - 400" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="flex align-content-center flex-wrap py-2 px-1" style="width: 4.2%">
                        <p class="o-color font-semibold">{{oceanScore.adventurousness}}</p>
                    </div>
                </div>

                <div class="flex detail-analysis-grid mt-2">
                    <div class="o-color font-semibold py-2 pl-2 mr-3" style="width: 30%">
                        {{"Key.Intellect" | translate}}
                    </div>
                    <div class="custom-display align-content-center py-2 mr-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="o-color-first" [value]="oceanScore.intellect * 5" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="o-color-second" [value]="(oceanScore.intellect * 5) - 100" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F1F1F1">
                        <p-progressBar class="o-color-third" [value]="(oceanScore.intellect * 5) - 200" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="o-color-fourth" [value]="(oceanScore.intellect * 5) - 300" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="o-color-fifth" [value]="(oceanScore.intellect * 5) - 400" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="flex align-content-center flex-wrap py-2 px-1" style="width: 4.2%">
                        <p class="o-color font-semibold">{{oceanScore.intellect}}</p>
                    </div>
                </div>

                <div class="flex detail-analysis-grid my-2">
                    <div class="o-color font-semibold py-2 pl-2 mr-3" style="width: 30%">
                        {{"Key.Liberalism" | translate}}
                    </div>
                    <div class="custom-display align-content-center py-2 mr-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="o-color-first" [value]="oceanScore.liberalism * 5" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="o-color-second" [value]="(oceanScore.liberalism * 5) - 100" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F1F1F1">
                        <p-progressBar class="o-color-third" [value]="(oceanScore.liberalism * 5) - 200" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="o-color-fourth" [value]="(oceanScore.liberalism * 5) - 300" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="o-color-fifth" [value]="(oceanScore.liberalism * 5) - 400" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="flex align-content-center flex-wrap py-2 px-1" style="width: 4.2%">
                        <p class="o-color font-semibold">{{oceanScore.liberalism}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex analysis-grid mt-2">
            <div class="flex justify-content-center semibold c-vertical-writing p-3" style="width: 5%">
                {{"Key.Conscientiousness" | translate}}
            </div>
            <div class="p-2" style="width: 95%">
                <div class="flex detail-analysis-grid mt-2">
                    <div class="c-color font-semibold py-2 pl-2 mr-3" style="width: 30%">
                        {{"Key.SelfEfficacy" | translate}}
                    </div>
                    <div class="custom-display align-content-center py-2 mr-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="c-color-first" [value]="oceanScore.selfEfficacy * 5" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="c-color-second" [value]="(oceanScore.selfEfficacy * 5) - 100" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F1F1F1">
                        <p-progressBar class="c-color-third" [value]="(oceanScore.selfEfficacy * 5) - 200" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="c-color-fourth" [value]="(oceanScore.selfEfficacy * 5) - 300" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="c-color-fifth" [value]="(oceanScore.selfEfficacy * 5) - 400" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="flex align-content-center flex-wrap py-2 px-1" style="width: 4.2%">
                        <p class="c-color font-semibold">{{oceanScore.selfEfficacy}}</p>
                    </div>
                </div>

                <div class="flex detail-analysis-grid mt-2">
                    <div class="c-color font-semibold py-2 pl-2 mr-3" style="width: 30%">
                        {{"Key.Orderliness" | translate}}
                    </div>
                    <div class="custom-display align-content-center py-2 mr-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="c-color-first" [value]="oceanScore.orderliness * 5" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="c-color-second" [value]="(oceanScore.orderliness * 5) - 100" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F1F1F1">
                        <p-progressBar class="c-color-third" [value]="(oceanScore.orderliness * 5) - 200" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="c-color-fourth" [value]="(oceanScore.orderliness * 5) - 300" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="c-color-fifth" [value]="(oceanScore.orderliness * 5) - 400" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="flex align-content-center flex-wrap py-2 px-1" style="width: 4.2%">
                        <p class="c-color font-semibold">{{oceanScore.orderliness}}</p>
                    </div>
                </div>

                <div class="flex detail-analysis-grid mt-2">
                    <div class="c-color font-semibold py-2 pl-2 mr-3" style="width: 30%">
                        {{"Key.Dutifulness" | translate}}
                    </div>
                    <div class="custom-display align-content-center py-2 mr-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="c-color-first" [value]="oceanScore.dutifulness * 5" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="c-color-second" [value]="(oceanScore.dutifulness * 5) - 100" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F1F1F1">
                        <p-progressBar class="c-color-third" [value]="(oceanScore.dutifulness * 5) - 200" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="c-color-fourth" [value]="(oceanScore.dutifulness * 5) - 300" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="c-color-fifth" [value]="(oceanScore.dutifulness * 5) - 400" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="flex align-content-center flex-wrap py-2 px-1" style="width: 4.2%">
                        <p class="c-color font-semibold">{{oceanScore.dutifulness}}</p>
                    </div>
                </div>

                <div class="flex detail-analysis-grid mt-2">
                    <div class="c-color font-semibold py-2 pl-2 mr-3" style="width: 30%">
                        {{"Key.AchievementStriving" | translate}}
                    </div>
                    <div class="custom-display align-content-center py-2 mr-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="c-color-first" [value]="oceanScore.achievementStriving * 5" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="c-color-second" [value]="(oceanScore.achievementStriving * 5) - 100" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F1F1F1">
                        <p-progressBar class="c-color-third" [value]="(oceanScore.achievementStriving * 5) - 200" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="c-color-fourth" [value]="(oceanScore.achievementStriving * 5) - 300" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="c-color-fifth" [value]="(oceanScore.achievementStriving * 5) - 400" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="flex align-content-center flex-wrap py-2 px-1" style="width: 4.2%">
                        <p class="c-color font-semibold">{{oceanScore.achievementStriving}}</p>
                    </div>
                </div>

                <div class="flex detail-analysis-grid mt-2">
                    <div class="c-color font-semibold py-2 pl-2 mr-3" style="width: 30%">
                        {{"Key.SelfDiscipline" | translate}}
                    </div>
                    <div class="custom-display align-content-center py-2 mr-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="c-color-first" [value]="oceanScore.selfDiscipline * 5" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="c-color-second" [value]="(oceanScore.selfDiscipline * 5) - 100" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F1F1F1">
                        <p-progressBar class="c-color-third" [value]="(oceanScore.selfDiscipline * 5) - 200" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="c-color-fourth" [value]="(oceanScore.selfDiscipline * 5) - 300" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="c-color-fifth" [value]="(oceanScore.selfDiscipline * 5) - 400" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="flex align-content-center flex-wrap py-2 px-1" style="width: 4.2%">
                        <p class="c-color font-semibold">{{oceanScore.selfDiscipline}}</p>
                    </div>
                </div>

                <div class="flex detail-analysis-grid my-2">
                    <div class="c-color font-semibold py-2 pl-2 mr-3" style="width: 30%">
                        {{"Key.Cautiousness" | translate}}
                    </div>
                    <div class="custom-display align-content-center py-2 mr-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="c-color-first" [value]="oceanScore.cautiousness * 5" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="c-color-second" [value]="(oceanScore.cautiousness * 5) - 100" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F1F1F1">
                        <p-progressBar class="c-color-third" [value]="(oceanScore.cautiousness * 5) - 200" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="c-color-fourth" [value]="(oceanScore.cautiousness * 5) - 300" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="c-color-fifth" [value]="(oceanScore.cautiousness * 5) - 400" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="flex align-content-center flex-wrap py-2 px-1" style="width: 4.2%">
                        <p class="c-color font-semibold">{{oceanScore.cautiousness}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex analysis-grid mt-2">
            <div class="flex justify-content-center semibold e-vertical-writing p-3" style="width: 5%">
                {{"Key.Extraversion" | translate}}
            </div>
            <div class="p-2" style="width: 95%">
                <div class="flex detail-analysis-grid mt-2">
                    <div class="e-color font-semibold py-2 pl-2 mr-3" style="width: 30%">
                        {{"Key.Friendliness" | translate}}
                    </div>
                    <div class="custom-display align-content-center py-2 mr-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="e-color-first" [value]="oceanScore.friendliness * 5" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="e-color-second" [value]="(oceanScore.friendliness * 5) - 100" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F1F1F1">
                        <p-progressBar class="e-color-third" [value]="(oceanScore.friendliness * 5) - 200" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="e-color-fourth" [value]="(oceanScore.friendliness * 5) - 300" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="e-color-fifth" [value]="(oceanScore.friendliness * 5) - 400" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="flex align-content-center flex-wrap py-2 px-1" style="width: 4.2%">
                        <p class="e-color font-semibold">{{oceanScore.friendliness}}</p>
                    </div>
                </div>

                <div class="flex detail-analysis-grid mt-2">
                    <div class="e-color font-semibold py-2 pl-2 mr-3" style="width: 30%">
                        {{"Key.Assertiveness" | translate}}
                    </div>
                    <div class="custom-display align-content-center py-2 mr-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="e-color-first" [value]="oceanScore.assertiveness * 5" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="e-color-second" [value]="(oceanScore.assertiveness * 5) - 100" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F1F1F1">
                        <p-progressBar class="e-color-third" [value]="(oceanScore.assertiveness * 5) - 200" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="e-color-fourth" [value]="(oceanScore.assertiveness * 5) - 300" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="e-color-fifth" [value]="(oceanScore.assertiveness * 5) - 400" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="flex align-content-center flex-wrap py-2 px-1" style="width: 4.2%">
                        <p class="e-color font-semibold">{{oceanScore.assertiveness}}</p>
                    </div>
                </div>

                <div class="flex detail-analysis-grid mt-2">
                    <div class="e-color font-semibold py-2 pl-2 mr-3" style="width: 30%">
                        {{"Key.Gregariousness" | translate}}
                    </div>
                    <div class="custom-display align-content-center py-2 mr-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="e-color-first" [value]="oceanScore.gregariousness * 5" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="e-color-second" [value]="(oceanScore.gregariousness * 5) - 100" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F1F1F1">
                        <p-progressBar class="e-color-third" [value]="(oceanScore.gregariousness * 5) - 200" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="e-color-fourth" [value]="(oceanScore.gregariousness * 5) - 300" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="e-color-fifth" [value]="(oceanScore.gregariousness * 5) - 400" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="flex align-content-center flex-wrap py-2 px-1" style="width: 4.2%">
                        <p class="e-color font-semibold">{{oceanScore.gregariousness}}</p>
                    </div>
                </div>

                <div class="flex detail-analysis-grid mt-2">
                    <div class="e-color font-semibold py-2 pl-2 mr-3" style="width: 30%">
                        {{"Key.ActivityLevel" | translate}}
                    </div>
                    <div class="custom-display align-content-center py-2 mr-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="e-color-first" [value]="oceanScore.activityLevel * 5" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="e-color-second" [value]="(oceanScore.activityLevel * 5) - 100" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F1F1F1">
                        <p-progressBar class="e-color-third" [value]="(oceanScore.activityLevel * 5) - 200" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="e-color-fourth" [value]="(oceanScore.activityLevel * 5) - 300" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="e-color-fifth" [value]="(oceanScore.activityLevel * 5) - 400" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="flex align-content-center flex-wrap py-2 px-1" style="width: 4.2%">
                        <p class="e-color font-semibold">{{oceanScore.activityLevel}}</p>
                    </div>
                </div>

                <div class="flex detail-analysis-grid mt-2">
                    <div class="e-color font-semibold py-2 pl-2 mr-3" style="width: 30%">
                        {{"Key.ExcitementSeeking" | translate}}
                    </div>
                    <div class="custom-display align-content-center py-2 mr-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="e-color-first" [value]="oceanScore.excitementSeeking * 5" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="e-color-second" [value]="(oceanScore.excitementSeeking * 5) - 100" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F1F1F1">
                        <p-progressBar class="e-color-third" [value]="(oceanScore.excitementSeeking * 5) - 200" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="e-color-fourth" [value]="(oceanScore.excitementSeeking * 5) - 300" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="e-color-fifth" [value]="(oceanScore.excitementSeeking * 5) - 400" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="flex align-content-center flex-wrap py-2 px-1" style="width: 4.2%">
                        <p class="e-color font-semibold">{{oceanScore.excitementSeeking}}</p>
                    </div>
                </div>

                <div class="flex detail-analysis-grid my-2">
                    <div class="e-color font-semibold py-2 pl-2 mr-3" style="width: 30%">
                        {{"Key.Cheerfulness" | translate}}
                    </div>
                    <div class="custom-display align-content-center py-2 mr-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="e-color-first" [value]="oceanScore.cheerfulness * 5" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="e-color-second" [value]="(oceanScore.cheerfulness * 5) - 100" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F1F1F1">
                        <p-progressBar class="e-color-third" [value]="(oceanScore.cheerfulness * 5) - 200" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="e-color-fourth" [value]="(oceanScore.cheerfulness * 5) - 300" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="e-color-fifth" [value]="(oceanScore.cheerfulness * 5) - 400" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="flex align-content-center flex-wrap py-2 px-1" style="width: 4.2%">
                        <p class="e-color font-semibold">{{oceanScore.cheerfulness}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex analysis-grid mt-2">
            <div class="flex justify-content-center semibold a-vertical-writing p-3" style="width: 5%">
                {{"Key.Agreeableness" | translate}}
            </div>
            <div class="p-2" style="width: 95%">
                <div class="flex detail-analysis-grid mt-2">
                    <div class="a-color font-semibold py-2 pl-2 mr-3" style="width: 30%">
                        {{"Key.Trust" | translate}}
                    </div>
                    <div class="custom-display align-content-center py-2 mr-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="a-color-first" [value]="oceanScore.trust * 5" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="a-color-second" [value]="(oceanScore.trust * 5) - 100" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F1F1F1">
                        <p-progressBar class="a-color-third" [value]="(oceanScore.trust * 5) - 200" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="a-color-fourth" [value]="(oceanScore.trust * 5) - 300" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="a-color-fifth" [value]="(oceanScore.trust * 5) - 400" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="flex align-content-center flex-wrap py-2 px-1" style="width: 4.2%">
                        <p class="a-color font-semibold">{{oceanScore.trust}}</p>
                    </div>
                </div>

                <div class="flex detail-analysis-grid mt-2">
                    <div class="a-color font-semibold py-2 pl-2 mr-3" style="width: 30%">
                        {{"Key.Morality" | translate}}
                    </div>
                    <div class="custom-display align-content-center py-2 mr-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="a-color-first" [value]="oceanScore.morality * 5" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="a-color-second" [value]="(oceanScore.morality * 5) - 100" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F1F1F1">
                        <p-progressBar class="a-color-third" [value]="(oceanScore.morality * 5) - 200" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="a-color-fourth" [value]="(oceanScore.morality * 5) - 300" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="a-color-fifth" [value]="(oceanScore.morality * 5) - 400" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="flex align-content-center flex-wrap py-2 px-1" style="width: 4.2%">
                        <p class="a-color font-semibold">{{oceanScore.morality}}</p>
                    </div>
                </div>

                <div class="flex detail-analysis-grid mt-2">
                    <div class="a-color font-semibold py-2 pl-2 mr-3" style="width: 30%">
                        {{"Key.Altruism" | translate}}
                    </div>
                    <div class="custom-display align-content-center py-2 mr-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="a-color-first" [value]="oceanScore.altruism * 5" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="a-color-second" [value]="(oceanScore.altruism * 5) - 100" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F1F1F1">
                        <p-progressBar class="a-color-third" [value]="(oceanScore.altruism * 5) - 200" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="a-color-fourth" [value]="(oceanScore.altruism * 5) - 300" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="a-color-fifth" [value]="(oceanScore.altruism * 5) - 400" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="flex align-content-center flex-wrap py-2 px-1" style="width: 4.2%">
                        <p class="a-color font-semibold">{{oceanScore.altruism}}</p>
                    </div>
                </div>

                <div class="flex detail-analysis-grid mt-2">
                    <div class="a-color font-semibold py-2 pl-2 mr-3" style="width: 30%">
                        {{"Key.Cooperation" | translate}}
                    </div>
                    <div class="custom-display align-content-center py-2 mr-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="a-color-first" [value]="oceanScore.cooperation * 5" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="a-color-second" [value]="(oceanScore.cooperation * 5) - 100" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F1F1F1">
                        <p-progressBar class="a-color-third" [value]="(oceanScore.cooperation * 5) - 200" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="a-color-fourth" [value]="(oceanScore.cooperation * 5) - 300" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="a-color-fifth" [value]="(oceanScore.cooperation * 5) - 400" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="flex align-content-center flex-wrap py-2 px-1" style="width: 4.2%">
                        <p class="a-color font-semibold">{{oceanScore.cooperation}}</p>
                    </div>
                </div>

                <div class="flex detail-analysis-grid mt-2">
                    <div class="a-color font-semibold py-2 pl-2 mr-3" style="width: 30%">
                        {{"Key.Modesty" | translate}}
                    </div>
                    <div class="custom-display align-content-center py-2 mr-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="a-color-first" [value]="oceanScore.modesty * 5" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="a-color-second" [value]="(oceanScore.modesty * 5) - 100" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F1F1F1">
                        <p-progressBar class="a-color-third" [value]="(oceanScore.modesty * 5) - 200" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="a-color-fourth" [value]="(oceanScore.modesty * 5) - 300" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="a-color-fifth" [value]="(oceanScore.modesty * 5) - 400" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="flex align-content-center flex-wrap py-2 px-1" style="width: 4.2%">
                        <p class="a-color font-semibold">{{oceanScore.modesty}}</p>
                    </div>
                </div>

                <div class="flex detail-analysis-grid my-2">
                    <div class="a-color font-semibold py-2 pl-2 mr-3" style="width: 30%">
                        {{"Key.Sympathy" | translate}}
                    </div>
                    <div class="custom-display align-content-center py-2 mr-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="a-color-first" [value]="oceanScore.sympathy * 5" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="a-color-second" [value]="(oceanScore.sympathy * 5) - 100" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F1F1F1">
                        <p-progressBar class="a-color-third" [value]="(oceanScore.sympathy * 5) - 200" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="a-color-fourth" [value]="(oceanScore.sympathy * 5) - 300" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="a-color-fifth" [value]="(oceanScore.sympathy * 5) - 400" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="flex align-content-center flex-wrap py-2 px-1" style="width: 4.2%">
                        <p class="a-color font-semibold">{{oceanScore.sympathy}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex analysis-grid mt-2">
            <div class="flex justify-content-center semibold n-vertical-writing p-3" style="width: 5%">
                {{"Key.Neuroticism" | translate}}
            </div>
            <div class="p-2" style="width: 95%">
                <div class="flex detail-analysis-grid mt-2">
                    <div class="n-color font-semibold py-2 pl-2 mr-3" style="width: 30%">
                        {{"Key.Anxiety" | translate}}
                    </div>
                    <div class="custom-display align-content-center py-2 mr-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="n-color-first" [value]="oceanScore.anxiety * 5" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="n-color-second" [value]="(oceanScore.anxiety * 5) - 100" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F1F1F1">
                        <p-progressBar class="n-color-third" [value]="(oceanScore.anxiety * 5) - 200" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="n-color-fourth" [value]="(oceanScore.anxiety * 5) - 300" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="n-color-fifth" [value]="(oceanScore.anxiety * 5) - 400" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="flex align-content-center flex-wrap py-2 px-1" style="width: 4.2%">
                        <p class="n-color font-semibold">{{oceanScore.anxiety}}</p>
                    </div>
                </div>

                <div class="flex detail-analysis-grid mt-2">
                    <div class="n-color font-semibold py-2 pl-2 mr-3" style="width: 30%">
                        {{"Key.Anger" | translate}}
                    </div>
                    <div class="custom-display align-content-center py-2 mr-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="n-color-first" [value]="oceanScore.anger * 5" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="n-color-second" [value]="(oceanScore.anger * 5) - 100" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F1F1F1">
                        <p-progressBar class="n-color-third" [value]="(oceanScore.anger * 5) - 200" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="n-color-fourth" [value]="(oceanScore.anger * 5) - 300" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="n-color-fifth" [value]="(oceanScore.anger * 5) - 400" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="flex align-content-center flex-wrap py-2 px-1" style="width: 4.2%">
                        <p class="n-color font-semibold">{{oceanScore.anger}}</p>
                    </div>
                </div>

                <div class="flex detail-analysis-grid mt-2">
                    <div class="n-color font-semibold py-2 pl-2 mr-3" style="width: 30%">
                        {{"Key.Depression" | translate}}
                    </div>
                    <div class="custom-display align-content-center py-2 mr-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="n-color-first" [value]="oceanScore.depression * 5" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="n-color-second" [value]="(oceanScore.depression * 5) - 100" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F1F1F1">
                        <p-progressBar class="n-color-third" [value]="(oceanScore.depression * 5) - 200" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="n-color-fourth" [value]="(oceanScore.depression * 5) - 300" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="n-color-fifth" [value]="(oceanScore.depression * 5) - 400" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="flex align-content-center flex-wrap py-2 px-1" style="width: 4.2%">
                        <p class="n-color font-semibold">{{oceanScore.depression}}</p>
                    </div>
                </div>

                <div class="flex detail-analysis-grid mt-2">
                    <div class="n-color font-semibold py-2 pl-2 mr-3" style="width: 30%">
                        {{"Key.SelfConsciousness" | translate}}
                    </div>
                    <div class="custom-display align-content-center py-2 mr-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="n-color-first" [value]="oceanScore.selfConsciousness * 5" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="n-color-second" [value]="(oceanScore.selfConsciousness * 5) - 100" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F1F1F1">
                        <p-progressBar class="n-color-third" [value]="(oceanScore.selfConsciousness * 5) - 200" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="n-color-fourth" [value]="(oceanScore.selfConsciousness * 5) - 300" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="n-color-fifth" [value]="(oceanScore.selfConsciousness * 5) - 400" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="flex align-content-center flex-wrap py-2 px-1" style="width: 4.2%">
                        <p class="n-color font-semibold">{{oceanScore.selfConsciousness}}</p>
                    </div>
                </div>

                <div class="flex detail-analysis-grid mt-2">
                    <div class="n-color font-semibold py-2 pl-2 mr-3" style="width: 30%">
                        {{"Key.Immoderation" | translate}}
                    </div>
                    <div class="custom-display align-content-center py-2 mr-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="n-color-first" [value]="oceanScore.immoderation * 5" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="n-color-second" [value]="(oceanScore.immoderation * 5) - 100" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F1F1F1">
                        <p-progressBar class="n-color-third" [value]="(oceanScore.immoderation * 5) - 200" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="n-color-fourth" [value]="(oceanScore.immoderation * 5) - 300" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="n-color-fifth" [value]="(oceanScore.immoderation * 5) - 400" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="flex align-content-center flex-wrap py-2 px-1" style="width: 4.2%">
                        <p class="n-color font-semibold">{{oceanScore.immoderation}}</p>
                    </div>
                </div>

                <div class="flex detail-analysis-grid my-2">
                    <div class="n-color font-semibold py-2 pl-2 mr-3" style="width: 30%">
                        {{"Key.Vulnerability" | translate}}
                    </div>
                    <div class="custom-display align-content-center py-2 mr-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="n-color-first" [value]="oceanScore.vulnerability * 5" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="n-color-second" [value]="(oceanScore.vulnerability * 5) - 100" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F1F1F1">
                        <p-progressBar class="n-color-third" [value]="(oceanScore.vulnerability * 5) - 200" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F5F5F5">
                        <p-progressBar class="n-color-fourth" [value]="(oceanScore.vulnerability * 5) - 300" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="custom-display align-content-center py-2 mx-1" style="width: 13.16% ; background-color: #F8F8F8">
                        <p-progressBar class="n-color-fifth" [value]="(oceanScore.vulnerability * 5) - 400" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="flex align-content-center flex-wrap py-2 px-1" style="width: 4.2%">
                        <p class="n-color font-semibold">{{oceanScore.vulnerability}}</p>
                    </div>
                </div>
            </div>
        </div>
    </p-sidebar>
</div>
<div class="grid">
    <div class="col-2 ocean-icon-width align-content-center custom-display">
        <p-badge class="o-color" size="large" value="O"></p-badge>
    </div>
    <div class="col-10 flex flex-wrap align-content-center o-color font-semibold">
        {{"Key.OpennessToExperience" | translate}}
    </div>
    <div class="col-3 mr-1 ml-4 px-0 align-content-center custom-display" style="width: 18% ; background-color: #F8F8F8">
        <p-progressBar class="o-color-first" [value]="progressBarOceanScoreO" [showValue]="false"></p-progressBar>
    </div>
    <div class="col-3 mx-1 px-0 align-content-center custom-display" style="width: 18% ; background-color: #F5F5F5">
        <p-progressBar class="o-color-second" [value]="progressBarOceanScoreO - 100" [showValue]="false"></p-progressBar>
    </div>
    <div class="col-3 mx-1 px-0 align-content-center custom-display" style="width: 18% ; background-color: #F1F1F1">
        <p-progressBar class="o-color-third" [value]="progressBarOceanScoreO - 200" [showValue]="false"></p-progressBar>
    </div>
    <div class="col-3 mx-1 px-0 align-content-center custom-display" style="width: 18% ; background-color: #F5F5F5">
        <p-progressBar class="o-color-fourth" [value]="progressBarOceanScoreO - 300" [showValue]="false"></p-progressBar>
    </div>
    <div class="col-3 mx-1 px-0 align-content-center custom-display" style="width: 18% ; background-color: #F8F8F8">
        <p-progressBar class="o-color-fifth" [value]="progressBarOceanScoreO - 400" [showValue]="false"></p-progressBar>
    </div>
    <div class="col-1 p-0 ml-1 flex align-content-center flex-wrap" style="width: 3%">
        <p class="o-color font-semibold">{{oceanScore.o}}</p>
    </div>
    <div class="col-3 mr-1 ml-4 flex justify-content-center font-semibold" style="width: 18% ; background-color: #F8F8F8">{{"Key.VeryLow" | translate}}</div>
    <div class="col-3 mx-1 px-0 flex justify-content-center font-semibold" style="width: 18% ; background-color: #F8F8F8">{{"Key.Low" | translate}}</div>
    <div class="col-3 mx-1 px-0 flex justify-content-center font-semibold" style="width: 18% ; background-color: #F1F1F1">{{"Key.Medium" | translate}}</div>
    <div class="col-3 mx-1 px-0 flex justify-content-center font-semibold" style="width: 18% ; background-color: #F8F8F8">{{"Key.High" | translate}}</div>
    <div class="col-3 mx-1 px-0 flex justify-content-center font-semibold" style="width: 18% ; background-color: #F8F8F8">{{"Key.VeryHigh" | translate}}</div>
    <div class="no-border-grid grid mt-4 mb-4">
        <div class="left-text-field font-medium line-height-3 p-4" style="width: 37%">
            <p>{{"Key.OpennessLowScoreDescription" | translate}}</p>
        </div>
        <div style="width: 16%"></div>
        <div class="right-text-field font-medium line-height-3 p-4 text-right" style="width: 37%">
            <p>{{"Key.OpennessHighScoreDescription" | translate}}</p>
        </div>
        <div style="width: 10%"></div>
    </div>
</div>

<div class="grid">
    <div class="col-2 ocean-icon-width align-content-center custom-display">
        <p-badge class="c-color" size="large" value="C"></p-badge>
    </div>
    <div class="col-10 flex flex-wrap align-content-center c-color font-semibold">
        {{"Key.Conscientiousness" | translate}}
    </div>
    <div class="col-3 mr-1 ml-4 px-0 align-content-center custom-display" style="width: 18% ; background-color: #F8F8F8">
        <p-progressBar class="c-color-first" [value]="progressBarOceanScoreC" [showValue]="false"></p-progressBar>
    </div>
    <div class="col-3 mx-1 px-0 align-content-center custom-display" style="width: 18% ; background-color: #F5F5F5">
        <p-progressBar class="c-color-second" [value]="progressBarOceanScoreC - 100" [showValue]="false"></p-progressBar>
    </div>
    <div class="col-3 mx-1 px-0 align-content-center custom-display" style="width: 18% ; background-color: #F1F1F1">
        <p-progressBar class="c-color-third" [value]="progressBarOceanScoreC - 200" [showValue]="false"></p-progressBar>
    </div>
    <div class="col-3 mx-1 px-0 align-content-center custom-display" style="width: 18% ; background-color: #F5F5F5">
        <p-progressBar class="c-color-fourth" [value]="progressBarOceanScoreC - 300" [showValue]="false"></p-progressBar>
    </div>
    <div class="col-3 mx-1 px-0 align-content-center custom-display" style="width: 18% ; background-color: #F8F8F8">
        <p-progressBar class="c-color-fifth" [value]="progressBarOceanScoreC - 400" [showValue]="false"></p-progressBar>
    </div>
    <div class="col-1 p-0 ml-1 flex align-content-center flex-wrap" style="width: 3%">
        <p class="c-color font-semibold">{{oceanScore.c}}</p>
    </div>
    <div class="col-3 mr-1 ml-4 flex justify-content-center font-semibold" style="width: 18% ; background-color: #F8F8F8">{{"Key.VeryLow" | translate}}</div>
    <div class="col-3 mx-1 px-0 flex justify-content-center font-semibold" style="width: 18% ; background-color: #F8F8F8">{{"Key.Low" | translate}}</div>
    <div class="col-3 mx-1 px-0 flex justify-content-center font-semibold" style="width: 18% ; background-color: #F1F1F1">{{"Key.Medium" | translate}}</div>
    <div class="col-3 mx-1 px-0 flex justify-content-center font-semibold" style="width: 18% ; background-color: #F8F8F8">{{"Key.High" | translate}}</div>
    <div class="col-3 mx-1 px-0 flex justify-content-center font-semibold" style="width: 18% ; background-color: #F8F8F8">{{"Key.VeryHigh" | translate}}</div>
    <div class="no-border-grid grid mt-4 mb-4">
        <div class="left-text-field font-medium line-height-3 p-4" style="width: 37%">
            <p>{{"Key.ConscientiousnessLowScoreDescription" | translate}}</p>
        </div>
        <div style="width: 16%"></div>
        <div class="right-text-field font-medium line-height-3 p-4 text-right" style="width: 37%">
            <p>{{"Key.ConscientiousnessHighScoreDescription" | translate}}</p>
        </div>
        <div style="width: 10%"></div>
    </div>
</div>

<div class="grid">
    <div class="col-2 ocean-icon-width align-content-center custom-display">
        <p-badge class="e-color" size="large" value="E"></p-badge>
    </div>
    <div class="col-10 flex flex-wrap align-content-center e-color font-semibold">
        {{"Key.Extraversion" | translate}}
    </div>
    <div class="col-3 mr-1 ml-4 px-0 align-content-center custom-display" style="width: 18% ; background-color: #F8F8F8">
        <p-progressBar class="e-color-first" [value]="progressBarOceanScoreE" [showValue]="false"></p-progressBar>
    </div>
    <div class="col-3 mx-1 px-0 align-content-center custom-display" style="width: 18% ; background-color: #F5F5F5">
        <p-progressBar class="e-color-second" [value]="progressBarOceanScoreE - 100" [showValue]="false"></p-progressBar>
    </div>
    <div class="col-3 mx-1 px-0 align-content-center custom-display" style="width: 18% ; background-color: #F1F1F1">
        <p-progressBar class="e-color-third" [value]="progressBarOceanScoreE - 200" [showValue]="false"></p-progressBar>
    </div>
    <div class="col-3 mx-1 px-0 align-content-center custom-display" style="width: 18% ; background-color: #F5F5F5">
        <p-progressBar class="e-color-fourth" [value]="progressBarOceanScoreE - 300" [showValue]="false"></p-progressBar>
    </div>
    <div class="col-3 mx-1 px-0 align-content-center custom-display" style="width: 18% ; background-color: #F8F8F8">
        <p-progressBar class="e-color-fifth" [value]="progressBarOceanScoreE - 400" [showValue]="false"></p-progressBar>
    </div>
    <div class="col-1 p-0 ml-1 flex align-content-center flex-wrap" style="width: 3%">
        <p class="e-color font-semibold">{{oceanScore.e}}</p>
    </div>
    <div class="col-3 mr-1 ml-4 flex justify-content-center font-semibold" style="width: 18% ; background-color: #F8F8F8">{{"Key.VeryLow" | translate}}</div>
    <div class="col-3 mx-1 px-0 flex justify-content-center font-semibold" style="width: 18% ; background-color: #F8F8F8">{{"Key.Low" | translate}}</div>
    <div class="col-3 mx-1 px-0 flex justify-content-center font-semibold" style="width: 18% ; background-color: #F1F1F1">{{"Key.Medium" | translate}}</div>
    <div class="col-3 mx-1 px-0 flex justify-content-center font-semibold" style="width: 18% ; background-color: #F8F8F8">{{"Key.High" | translate}}</div>
    <div class="col-3 mx-1 px-0 flex justify-content-center font-semibold" style="width: 18% ; background-color: #F8F8F8">{{"Key.VeryHigh" | translate}}</div>
    <div class="no-border-grid grid mt-4 mb-4">
        <div class="left-text-field font-medium line-height-3 p-4" style="width: 37%">
            <p>{{"Key.ExtraversionLowScoreDescription" | translate}}</p>
        </div>
        <div style="width: 16%"></div>
        <div class="right-text-field font-medium line-height-3 p-4 text-right" style="width: 37%">
            <p>{{"Key.ExtraversionHighScoreDescription" | translate}}</p>
        </div>
        <div style="width: 10%"></div>
    </div>
</div>

<div class="grid">
    <div class="col-2 ocean-icon-width align-content-center custom-display">
        <p-badge class="a-color" size="large" value="A"></p-badge>
    </div>
    <div class="col-10 flex flex-wrap align-content-center a-color font-semibold">
        {{"Key.Agreeableness" | translate}}
    </div>
    <div class="col-3 mr-1 ml-4 px-0 align-content-center custom-display" style="width: 18% ; background-color: #F8F8F8">
        <p-progressBar class="a-color-first" [value]="progressBarOceanScoreA" [showValue]="false"></p-progressBar>
    </div>
    <div class="col-3 mx-1 px-0 align-content-center custom-display" style="width: 18% ; background-color: #F5F5F5">
        <p-progressBar class="a-color-second" [value]="progressBarOceanScoreA - 100" [showValue]="false"></p-progressBar>
    </div>
    <div class="col-3 mx-1 px-0 align-content-center custom-display" style="width: 18% ; background-color: #F1F1F1">
        <p-progressBar class="a-color-third" [value]="progressBarOceanScoreA - 200" [showValue]="false"></p-progressBar>
    </div>
    <div class="col-3 mx-1 px-0 align-content-center custom-display" style="width: 18% ; background-color: #F5F5F5">
        <p-progressBar class="a-color-fourth" [value]="progressBarOceanScoreA - 300" [showValue]="false"></p-progressBar>
    </div>
    <div class="col-3 mx-1 px-0 align-content-center custom-display" style="width: 18% ; background-color: #F8F8F8">
        <p-progressBar class="a-color-fifth" [value]="progressBarOceanScoreA - 400" [showValue]="false"></p-progressBar>
    </div>
    <div class="col-1 ml-1 p-0 flex align-content-center flex-wrap" style="width: 3%">
        <p class="a-color font-semibold">{{oceanScore.a}}</p>
    </div>
    <div class="col-3 mr-1 ml-4 flex justify-content-center font-semibold" style="width: 18% ; background-color: #F8F8F8">{{"Key.VeryLow" | translate}}</div>
    <div class="col-3 mx-1 px-0 flex justify-content-center font-semibold" style="width: 18% ; background-color: #F8F8F8">{{"Key.Low" | translate}}</div>
    <div class="col-3 mx-1 px-0 flex justify-content-center font-semibold" style="width: 18% ; background-color: #F1F1F1">{{"Key.Medium" | translate}}</div>
    <div class="col-3 mx-1 px-0 flex justify-content-center font-semibold" style="width: 18% ; background-color: #F8F8F8">{{"Key.High" | translate}}</div>
    <div class="col-3 mx-1 px-0 flex justify-content-center font-semibold" style="width: 18% ; background-color: #F8F8F8">{{"Key.VeryHigh" | translate}}</div>
    <div class="no-border-grid grid mt-4 mb-4">
        <div class="left-text-field font-medium line-height-3 p-4" style="width: 37%">
            <p>{{"Key.AgreeablenessLowScoreDescription" | translate}}</p>
        </div>
        <div style="width: 16%"></div>
        <div class="right-text-field font-medium line-height-3 p-4 text-right" style="width: 37%">
            <p>{{"Key.AgreeablenessHighScoreDescription" | translate}}</p>
        </div>
        <div style="width: 10%"></div>
    </div>
</div>

<div class="grid">
    <div class="col-2 ocean-icon-width align-content-center custom-display">
        <p-badge class="n-color" size="large" value="N"></p-badge>
    </div>
    <div class="col-10 flex flex-wrap align-content-center e-color font-semibold">
        {{"Key.Neuroticism" | translate}}
    </div>
    <div class="col-3 mr-1 ml-4 px-0 align-content-center custom-display" style="width: 18% ; background-color: #F8F8F8">
        <p-progressBar class="n-color-first" [value]="progressBarOceanScoreN" [showValue]="false"></p-progressBar>
    </div>
    <div class="col-3 mx-1 px-0 align-content-center custom-display" style="width: 18% ; background-color: #F5F5F5">
        <p-progressBar class="n-color-second" [value]="progressBarOceanScoreN - 100" [showValue]="false"></p-progressBar>
    </div>
    <div class="col-3 mx-1 px-0 align-content-center custom-display" style="width: 18% ; background-color: #F1F1F1">
        <p-progressBar class="n-color-third" [value]="progressBarOceanScoreN - 200" [showValue]="false"></p-progressBar>
    </div>
    <div class="col-3 mx-1 px-0 align-content-center custom-display" style="width: 18% ; background-color: #F5F5F5">
        <p-progressBar class="n-color-fourth" [value]="progressBarOceanScoreN - 300" [showValue]="false"></p-progressBar>
    </div>
    <div class="col-3 mx-1 px-0 align-content-center custom-display" style="width: 18% ; background-color: #F8F8F8">
        <p-progressBar class="n-color-fifth" [value]="progressBarOceanScoreN - 400" [showValue]="false"></p-progressBar>
    </div>
    <div class="col-1 p-0 ml-1 flex align-content-center flex-wrap" style="width: 3%">
        <p class="n-color font-semibold">{{oceanScore.n}}</p>
    </div>
    <div class="col-3 mr-1 ml-4 flex justify-content-center font-semibold" style="width: 18% ; background-color: #F8F8F8">{{"Key.VeryLow" | translate}}</div>
    <div class="col-3 mx-1 px-0 flex justify-content-center font-semibold" style="width: 18% ; background-color: #F8F8F8">{{"Key.Low" | translate}}</div>
    <div class="col-3 mx-1 px-0 flex justify-content-center font-semibold" style="width: 18% ; background-color: #F1F1F1">{{"Key.Medium" | translate}}</div>
    <div class="col-3 mx-1 px-0 flex justify-content-center font-semibold" style="width: 18% ; background-color: #F8F8F8">{{"Key.High" | translate}}</div>
    <div class="col-3 mx-1 px-0 flex justify-content-center font-semibold" style="width: 18% ; background-color: #F8F8F8">{{"Key.VeryHigh" | translate}}</div>
    <div class="no-border-grid grid mt-4 mb-4">
        <div class="left-text-field font-medium line-height-3 p-4" style="width: 37%">
            <p>{{"Key.NeuroticismLowScoreDescription" | translate}}</p>
        </div>
        <div style="width: 16%"></div>
        <div class="right-text-field font-medium line-height-3 p-4 text-right" style="width: 37%">
            <p>{{"Key.NeuroticismHighScoreDescription" | translate}}</p>
        </div>
        <div style="width: 10%"></div>
    </div>
</div>

