import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-knob-chart',
  templateUrl: './knob-chart.component.html',
  styleUrls: ['./knob-chart.component.scss'],
})
export class KnobChartComponent {

  @Input() skillLevels: any;

  
}
