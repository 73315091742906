import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { VinterAICommentIcon } from '@app/shared/common/constants';
import { EApplicantBehaviourEventType, EntityStateEnum, GPTEvalStatus, ProcessStatus } from '@app/shared/common/enums';
import { AnswerContentFormItem } from '@app/vinter/models/answer-content-form-item';
import { OpenEndedAnswerItem } from '@app/vinter/models/answer/open-ended-answer-item';
import { AnswerContentService } from '@app/vinter/services/answer-content.service';
import { ApplicantBehaviourEventLogService } from '@app/vinter/services/applicant-behaviour-event-log.service';

@Component({
  selector: 'app-open-ended-answer',
  templateUrl: './open-ended-answer.component.html',
  styleUrls: ['./open-ended-answer.component.scss']
})
export class OpenEndedAnswerComponent implements OnInit {
  @Input() answer: OpenEndedAnswerItem;
  gptEvalStatusEnum = GPTEvalStatus;
  processStatusEnum = ProcessStatus;
  questionPageHiddenLog: number;
  vinterAICommentIcon = VinterAICommentIcon;

  constructor(private answerContentService: AnswerContentService,
    private eventLogService: ApplicantBehaviourEventLogService
  ){}

  ngOnChanges(changes: SimpleChanges): void {
    if(this.answer){
      this.getPageHiddenLog();
    }
  }

  ngOnInit(): void {
    if(this.answer){
      this.getPageHiddenLog();
    }
  }

  setGptStatus(){
    let answerContentForm : AnswerContentFormItem = new AnswerContentFormItem();
    answerContentForm = this.answer?.answerContent;
    answerContentForm.gptEvalStatus = this.gptEvalStatusEnum.Waiting;
    answerContentForm.processStatus = this.processStatusEnum.AIContainerWaiting;
    answerContentForm.entityState = EntityStateEnum.Modified;
    answerContentForm.xmin = this.answer?.answerContent.xmin;

    this.answerContentService.SaveAsync(answerContentForm).subscribe();
  }

  getPageHiddenLog(){
    let eventLogFilter: any = {};
    eventLogFilter.applicationStepId = this.answer.applicationStepId;
    eventLogFilter.eventType = EApplicantBehaviourEventType.PageHidden;
    eventLogFilter.questionId = this.answer.questionId;
    this.eventLogService.getListByFilter(eventLogFilter).subscribe(eventLog => {
        this.questionPageHiddenLog = eventLog.length;
    });
  }

}
