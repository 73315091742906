import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService, HttpBaseService, LocalizationService, NotifyService } from '@app/core';
import { ApiControllerEnum } from '@app/shared/common/enums';
import { IntegrationAndPositionInfo } from '../models/integration/integration-and-position-info';
import { Observable } from 'rxjs';
import { IntegrationApplicantListItem } from '../models/integration/Integration-applicant-list-item';

@Injectable({
  providedIn: 'root'
})
export class IntegrationApplicantService extends HttpBaseService  {

  constructor(private http: HttpClient
    , private notifyServ: NotifyService
    , private localizationService: LocalizationService
    , private configService: ConfigurationService) {
      super(http, ApiControllerEnum.IntegrationApplicant, notifyServ, localizationService, configService);
    }

    getIntegrationApplicantsAsync(integrationAndPositionInfo: IntegrationAndPositionInfo): Observable<IntegrationApplicantListItem[]> {
      return this.post("GetIntegrationApplicantsAsync", integrationAndPositionInfo, true,false);
    }

}
