import { Component, Input } from '@angular/core';
import { EApplicantBehaviourEventType} from '@app/shared/common/enums';

@Component({
  selector: 'app-question-behaiviour-info',
  templateUrl: './question-behaiviour-info.component.html',
  styleUrls: ['./question-behaiviour-info.component.scss']
})
export class QuestionBehaiviourInfoComponent {
  @Input() question: any;
  @Input() cheatingQuestionLog: any;
  EApplicantBehaviourEventType: any;
  ngOnInit(): void {
    this.EApplicantBehaviourEventType = EApplicantBehaviourEventType
  }

  checkIfQuestionLogExists(eventType): boolean {
    const questionLog = this.cheatingQuestionLog?.find(item => item.questionID === this.question.question.id);
    return questionLog ? questionLog.eventTypes.includes(eventType) : false;
  }
  

}
