import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { LocalizationService } from "@app/core/localization.service";
import { ELicensePlanName, ELicensePlanType } from "@app/shared/common/enums";
import { OrganizationPlan } from "@app/vinter/models/license-plan-list-item";

@Component({
  selector: 'app-license-plan-compare-pricing',
  templateUrl: './license-plan-compare-pricing.component.html',
  styleUrls: ['./license-plan-compare-pricing.component.scss']
})
export class LicensePlanComparePricingComponent implements OnInit {

  @Input() LicensePlan: any;
  @Input() PackageFuture: any;
  @Input() isAnnual: boolean;
  licenseFeatures: any[] = [];
  licensePlanTypeEnum = ELicensePlanType;

  constructor(private localizationService: LocalizationService)
  {}
  ngOnInit(): void {
    this.updatePricingFeatures()
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isAnnual']) {
      this.getUpdatedApplicantCounts();
    }
  }
  pricingFeatures = [
    { key: "credit", name: this.localizationService.translateText('Credits'), basicmonthly: null, basicannual: null, essentialmonthly: null, essentialannual: null, premiummonthly: null, premiumannual: null, enterprisemonthly: '+1000', enterpriseannual: '+1000' },
    { key: "competencyLibrary", name: this.localizationService.translateText('CompetencyCognitiveLibrary'), basicmonthly: true, basicannual: true, essentialmonthly: true, essentialannual: true, premiummonthly: true, premiumannual: true, enterprisemonthly: true, enterpriseannual: true },
    { key: "personalityTestLibrary", name: this.localizationService.translateText('PersonelTestLibrary'), basicmonthly: true, basicannual: true, essentialmonthly: true, essentialannual: true, premiummonthly: true, premiumannual: true, enterprisemonthly: true, enterpriseannual: true },
    { key: "technicalInterviewLibrary", name: this.localizationService.translateText('TechnicalInterviewLibrary'), basicmonthly: true, basicannual: true, essentialmonthly: true, essentialannual: true, premiummonthly: true, premiumannual: true, enterprisemonthly: true, enterpriseannual: true },
    { key: "customQuestions", name: this.localizationService.translateText('CustomQuestions'), basicmonthly: true, basicannual: true, essentialmonthly: true, essentialannual: true, premiummonthly: true, premiumannual: true, enterprisemonthly: true, enterpriseannual: true },
    { key: "antiCheat", name: this.localizationService.translateText('AntiCheat'), basicmonthly: true, basicannual: true, essentialmonthly: true, essentialannual: true, premiummonthly: true, premiumannual: true, enterprisemonthly: true, enterpriseannual: true },
    { key: "ATS Integration", name: this.localizationService.translateText('ATSIntegration'), basicmonthly: null, basicannual: null, essentialmonthly: null, essentialannual: null, premiummonthly: null, premiumannual: null, enterprisemonthly: null, enterpriseannual: null },
    { key: "Coding Questions", name:  this.localizationService.translateText('CodingLibrary'), basicmonthly: null, basicannual: null, essentialmonthly: null, essentialannual: null, premiummonthly: null, premiumannual: null, enterprisemonthly: null, enterpriseannual: null },
    { key: "onboardingTraining", name: this.localizationService.translateText('OnboardingTraining') , basicmonthly: false, basicannual: false, essentialmonthly: false, essentialannual: false, premiummonthly: true, premiumannual: true, enterprisemonthly: true, enterpriseannual: true },
    { key: "Automation", name:  this.localizationService.translateText('Automation'), basicmonthly: null, basicannual: null, essentialmonthly: null, essentialannual: null, premiummonthly: null, premiumannual: null, enterprisemonthly: null, enterpriseannual: null },
    { key: "AI Pdf Report", name: this.localizationService.translateText('ComprehensiveAIReports') , basicmonthly: null, basicannual: null, essentialmonthly: null, essentialannual: null, premiummonthly: null, premiumannual: null, enterprisemonthly: null, enterpriseannual: null },
    { key: "CV Parser", name:  this.localizationService.translateText('CvParsingCandidatePool'), basicmonthly: null, basicannual: null, essentialmonthly: null, essentialannual: null, premiummonthly: null, premiumannual: null, enterprisemonthly: null, enterpriseannual: null },
    { key: "dedicatedCss", name:  this.localizationService.translateText('DedicatedCssPerson'), basicmonthly: false, basicannual: false, essentialmonthly: false, essentialannual: false, premiummonthly: false, premiumannual: false, enterprisemonthly: true, enterpriseannual: true },
    { key: "tailormadeDevelopments", name:  this.localizationService.translateText('TailormadeDevelopments'), basicmonthly: false, basicannual: false, essentialmonthly: false, essentialannual: false, premiummonthly: false, premiumannual: false, enterprisemonthly: true, enterpriseannual: true }
  ];

  getApplicantStandartPlanCounts(): { licenseCode: string, applicantCount: number }[] {
    return this.LicensePlan
      .filter(plan => plan.licensePlanType === this.licensePlanTypeEnum.StandartPlan)  
      .map(plan => {
        const formattedLicenseCode = this.convertLicenseCodeToKeyFormat(plan.licenseCode);
        
        return {
          licenseCode: formattedLicenseCode,
          applicantCount: plan.applicantCount || 0
        };
      });
  }
  
  convertLicenseCodeToKeyFormat(licenseCode: string): string {
    return licenseCode
      .toLowerCase()
      .replace(/_/g, '');
  }

  getUpdatedApplicantCounts() {
    let applicantCountList = this.getApplicantStandartPlanCounts();
    let filteredPricingFeature = this.pricingFeatures.filter(x => x.key === "credit");
    if (filteredPricingFeature.length > 0) {
      let creditFeature = filteredPricingFeature[0];
  
      if (this.isAnnual) {
        const basicAnnual = applicantCountList.find(x => x.licenseCode === ELicensePlanName.BasicAnnual);
        if (basicAnnual) {
          creditFeature.basicannual = basicAnnual.applicantCount || 0;
        }
  
        const essentialAnnual = applicantCountList.find(x => x.licenseCode === ELicensePlanName.EssentialAnnual);
        if (essentialAnnual) {
          creditFeature.essentialannual = essentialAnnual.applicantCount || 0;
        }
  
        const premiumAnnual = applicantCountList.find(x => x.licenseCode === ELicensePlanName.PremiumAnnual);
        if (premiumAnnual) {
          creditFeature.premiumannual = premiumAnnual.applicantCount || 0;
        }
  
      } else {
  
        const basicMonthly = applicantCountList.find(x => x.licenseCode === ELicensePlanName.BasicMonthly);
        if (basicMonthly) {
          creditFeature.basicmonthly = basicMonthly.applicantCount || 0;
        }
  
        const essentialMonthly = applicantCountList.find(x => x.licenseCode === ELicensePlanName.EssentialMonthly);
        if (essentialMonthly) {
          creditFeature.essentialmonthly = essentialMonthly.applicantCount || 0;
        }
  
        const premiumMonthly = applicantCountList.find(x => x.licenseCode === ELicensePlanName.Premiummonthly);
        if (premiumMonthly) {
          creditFeature.premiummonthly = premiumMonthly.applicantCount || 0;
        }
      }
    }
  }
  
  
  
  
  updatePricingFeatures() {
    const updatedPricingFeatures = this.pricingFeatures.map(feature => {
      const backendFeature = this.PackageFuture.find(item => item.featureName.toLowerCase() === feature.key.toLowerCase());

      if (backendFeature) {
        Object.keys(backendFeature.plans).forEach(plan => {
          const formattedPlanKey = plan.toLowerCase().replace(/\s/g, '');
          if (feature[formattedPlanKey] !== undefined) {
            if (backendFeature.plans[plan] !== null) {
              feature[formattedPlanKey] = backendFeature.plans[plan];
            }
          }
        });
      }
      return feature; 
    });
  }

  get basicKey(): string {
    return this.isAnnual ? 'basicannual' : 'basicmonthly';
  }

  get essentialKey(): string {
    return this.isAnnual ? 'essentialannual' : 'essentialmonthly';
  }

  get premiumKey(): string {
    return this.isAnnual ? 'premiumannual' : 'premiummonthly';
  }

  get enterpriseKey(): string {
    return this.isAnnual ? 'enterpriseannual' : 'enterprisemonthly';
  }

}
