<div class="grid p-4">
    <div class="col-12 text-xl font-semibold -mb-3 text-gray-700">{{"Key.DidOfferAccepted" | translate}}</div>
    <div class="col-12 text-base font-normal text-gray-300">{{"Key.ApplicantAnswer" | translate}}</div>
    <div class="col-1 flex text-base font-medium mt-4 text-gray-700">
        <p-radioButton [value]="true" [(ngModel)]="selectedAnswer" (onClick)="radioButtonCheck()"></p-radioButton>
        <label class="ml-2">{{"Key.Yes" | translate}}</label>
    </div>
    <div class="col-1 flex text-base font-medium mt-4 text-gray-700">
        <p-radioButton [value]="false" [(ngModel)]="selectedAnswer" (onClick)="radioButtonCheck()"></p-radioButton>
        <label class="ml-2">{{"Key.No" | translate}}</label>
    </div>
    <div class="col-10"></div>

    <div *ngIf="selectedAnswer === false" class="col-4 text-lg font-semibold mt-4 -mb-3 custom-display text-gray-700">{{"Key.IfAnswerIsNo" | translate}}</div>
    <div *ngIf="selectedAnswer === false" class="col-8"></div>
    <div *ngIf="selectedAnswer === false" class="col-4 text-sm font-normal custom-display text-gray-700">{{"Key.Cause" | translate}}</div>
    <div *ngIf="selectedAnswer === false" class="col-8"></div>
    <div *ngIf="selectedAnswer === false" class="col-4 custom-display">
        <label class="font-medium text-blue-400">{{"Key.Reason" | translate}}</label>
        <p-dropdown [options]="rejectCategories" optionLabel="name" optionValue="id" [(ngModel)]="selectedReason" placeholder="{{'Key.SelectReason' | translate}}"></p-dropdown>
    </div>
    <div *ngIf="selectedAnswer === false" class="col-8"></div>
    <div *ngIf="selectedAnswer === false" class="col-4 custom-display">
        <div class="w-fulll custom-display">
            <label class="font-medium text-blue-400">{{"Key.Note" | translate}}</label>
        </div>
        <div class="w-full">
            <textarea class="mt-2" rows="5" cols="30" pInputTextarea [(ngModel)]="reasonNote" placeholder="{{'Key.Note' | translate}}"></textarea>
        </div>
    </div>
    <div *ngIf="selectedAnswer === false" class="col-8"></div>
    <div class="col-4 flex justify-content-start mt-4">
        <p-button label="{{'Key.Save' | translate}}" (onClick)="save()" [disabled]="selectedAnswer === null || selectedAnswer === undefined || (selectedAnswer === false && selectedReason === null) || (selectedAnswer === false && selectedReason === undefined)"></p-button>
    </div>
    <div class="col-8"></div>
</div>
