import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService, HttpBaseService, LocalizationService, NotifyService } from '@app/core';
import { ApiControllerEnum } from '@app/shared/common/enums';
import { Observable } from 'rxjs';
import { ApplicationListItem, ApplicationWithApplicantListItem } from '../models/application-list-item';
import { ApplicationDetailListItem } from '../models/application-detail-list-item';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService extends HttpBaseService {

  constructor(private http: HttpClient
    , private notifyServ: NotifyService
    , private localizationService: LocalizationService
    , private configService: ConfigurationService) {
    super(http, ApiControllerEnum.Application, notifyServ, localizationService, configService);
  }

  GetDataByFilterWithApplicant(filter: any): Observable<ApplicationWithApplicantListItem> {
    return this.post("GetDataByFilterWithApplicant", filter, true);
  }

  IncreaseUsageAndUpdatePaymentStatus(applicationID: string): Observable<any> {
    return this.get("IncreaseUsageAndUpdatePaymentStatus?applicationID=" + applicationID);
  }

  AIBasedReportRequest(filter: any): Observable<any> {
    return this.post("AIBasedReportRequest", filter, true);
  }

  GetApplicationUserInfoData(filter: any): Observable<any> {
    return this.post("GetApplicationUserInfoData", filter, true);
  }

  GetAIBasedStepReportButtonStatus(filter: any): Observable<any> {
    return this.post("GetAIBasedStepReportButtonStatus", filter, true);
  }

  GetCurrentApplicationStepId(filter: any): Observable<any> {
    return this.post("GetCurrentApplicationStepId", filter, true);
  }

  GetDecryptedResultPageApplication(filter: any): Observable<any> {
    return this.post("GetDecryptedResultPageApplication", filter, true);
  }

  GetAIBasedStepReport(filter: any): Observable<any> {
    return this.post("GetAIBasedStepReport", filter, true);
  }



}
