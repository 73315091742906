import { Component, Input, OnInit } from '@angular/core';
import { LocalizationService } from '@app/core';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { ApplicantBehaviourEventLogService } from '@app/vinter/services/applicant-behaviour-event-log.service';
import { ApplicationStepService } from '@app/vinter/services/application-step.service';
import { QuestionService } from '@app/vinter/services/question.service';
import { RatingService } from '@app/vinter/services/rating.service';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-applicant-step-video-meeting',
  templateUrl: './applicant-step-video-meeting.component.html',
  styleUrls: ['./applicant-step-video-meeting.component.scss']
})
export class ApplicantStepVideoMeetingComponent implements OnInit {
  @Input() item: any;

  constructor(
    private applicationStepService: ApplicationStepService,
    private eventLogService:ApplicantBehaviourEventLogService,
    private messageService: MessageService,
    private localizationService: LocalizationService,
    private appInfoService: AppInfoService,
    private ratingService: RatingService,
    private questionService: QuestionService,
    public api: VgApiService) { }

  ngOnInit(): void {
    if(this.item.averageRating ?? false){
      this.item.averageRating = 0;
    }
  }
}
