import { Component, Input, OnInit } from '@angular/core';
import { LocalizationService } from '@app/core';
import { EQuestionCollectionSubCategory } from '@app/shared/common/enums';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { StepPerTestResultListItem } from '@app/vinter/models/Step-per-test-result-list-item';
import { ApplicationStepService } from '@app/vinter/services/application-step.service';

@Component({
  selector: 'app-ocean-step-result',
  templateUrl: './ocean-step-result.component.html',
  styleUrls: ['./ocean-step-result.component.scss']
})
export class OceanStepResultComponent implements OnInit{
  

  @Input() applicationStepId: string;
  @Input() openViewSidebar: boolean;

  stepInfo: any;
  oceanScore: StepPerTestResultListItem = new StepPerTestResultListItem();
  questionCollectionSubCategory = EQuestionCollectionSubCategory;

  oceanKeys = ['o', 'c', 'e', 'a', 'n'];
  oceanTitles = {
    o: 'Key.OpennessToExperience',
    c: 'Key.Conscientiousness',
    e: 'Key.Extraversion',
    a: 'Key.Agreeableness',
    n: 'Key.Neuroticism',
  };

  oceanLowDescriptions = {
    o: 'Key.OpennessLowScoreDescription',
    c: 'Key.ConscientiousnessLowScoreDescription',
    e: 'Key.ExtraversionLowScoreDescription',
    a: 'Key.AgreeablenessLowScoreDescription',
    n: 'Key.NeuroticismLowScoreDescription',
  };

  oceanHighDescriptions = {
    o: 'Key.OpennessHighScoreDescription',
    c: 'Key.ConscientiousnessHighScoreDescription',
    e: 'Key.ExtraversionHighScoreDescription',
    a: 'Key.AgreeablenessHighScoreDescription',
    n: 'Key.NeuroticismHighScoreDescription',
  };

  progressBarOceanScores = {
    O: 0,
    C: 0,
    E: 0,
    A: 0,
    N: 0,
  };

  levels = [
    { label: 'Key.VeryLow', backgroundColor: '#FCFCFC' },
    { label: 'Key.Low', backgroundColor: '#F8F8F8' },
    { label: 'Key.Medium', backgroundColor: '#F3F3F3' },
    { label: 'Key.High', backgroundColor: '#F8F8F8' },
    { label: 'Key.VeryHigh', backgroundColor: '#FCFCFC' }
  ];

  analysisData = {
    o: [
      { key: 'Key.Imagination', scoreKey: 'imagination' },
      { key: 'Key.ArtisticInterest', scoreKey: 'artisticInterests' },
      { key: 'Key.Emotionality', scoreKey: 'emotionality' },
      { key: 'Key.Adventurousness', scoreKey: 'adventurousness' },
      { key: 'Key.Intellect', scoreKey: 'intellect' },
      { key: 'Key.Liberalism', scoreKey: 'liberalism' }
    ],
    c: [
      { key: 'Key.SelfEfficacy', scoreKey: 'selfEfficacy' },
      { key: 'Key.Orderliness', scoreKey: 'orderliness' },
      { key: 'Key.Dutifulness', scoreKey: 'dutifulness' },
      { key: 'Key.AchievementStriving', scoreKey: 'achievementStriving ' },
      { key: 'Key.SelfDiscipline', scoreKey: 'selfDiscipline' },
      { key: 'Key.Cautiousness', scoreKey: 'cautiousness' }
    ],
    e: [
      { key: 'Key.Friendliness', scoreKey: 'friendliness' },
      { key: 'Key.Assertiveness', scoreKey: 'assertiveness' },
      { key: 'Key.Gregariousness', scoreKey: 'gregariousness' },
      { key: 'Key.ActivityLevel', scoreKey: 'activityLevel' },
      { key: 'Key.ExcitementSeeking', scoreKey: 'excitementSeeking' },
      { key: 'Key.Cheerfulness', scoreKey: 'cheerfulness' }
    ],
    a: [
      { key: 'Key.Trust', scoreKey: 'trust' },
      { key: 'Key.Morality', scoreKey: 'morality' },
      { key: 'Key.Altruism', scoreKey: 'altruism' },
      { key: 'Key.Cooperation', scoreKey: 'cooperation' },
      { key: 'Key.Modesty', scoreKey: 'modesty' },
      { key: 'Key.Sympathy', scoreKey: 'sympathy' }
    ],
    n: [
      { key: 'Key.Anxiety', scoreKey: 'anxiety' },
      { key: 'Key.Anger', scoreKey: 'anger' },
      { key: 'Key.Depression', scoreKey: 'depression' },
      { key: 'Key.SelfConsciousness', scoreKey: 'selfConsciousness' },
      { key: 'Key.Immoderation', scoreKey: 'immoderation' },
      { key: 'Key.Vulnerability', scoreKey: 'vulnerability' }
    ],
  };

  

  oceanTraits: Array<any> = [];

  constructor(private applicationStepService :ApplicationStepService
   ) {
  }
  
  ngOnInit(): void {
    this.getResultData();
  }

  getResultData(){
    this.applicationStepService.GetApplicationStepResult({ id: this.applicationStepId }).subscribe(result => {
      this.stepInfo = result;
      this.oceanScore = result.stepPerTestResult;
      this.setOceanProgressBar();
    });
  }

  initializeOceanTraits(): void {
    this.oceanTraits = this.oceanKeys.map((key) => {
      const cssClass = key + '-color';
      return {
        key,
        title: this.oceanTitles[key],
        cssClass,
        score: this.oceanScore[key],
        progressBarOceanScore: this.progressBarOceanScores[key.toUpperCase()],
        lowDescription: this.oceanLowDescriptions[key],
        highDescription: this.oceanHighDescriptions[key],
        progressBarClasses: [
          `${cssClass}-first`,
          `${cssClass}-second`,
          `${cssClass}-third`,
          `${cssClass}-fourth`,
          `${cssClass}-fifth`,
        ],
        analysisData: this.analysisData[key] || {}
      };
    });
  }

  setOceanProgressBar(){

    if (this.stepInfo.questionCollectionResult.subCategory === this.questionCollectionSubCategory.OCEAN50) {
      this.applyMultiplier(this.oceanScore, 2.5, ['o', 'c', 'e', 'a', 'n']);
    }

    this.roundScores(this.oceanScore, ['o', 'c', 'e', 'a', 'n']);

    if (this.stepInfo.questionCollectionResult.subCategory === this.questionCollectionSubCategory.OCEAN120) {
      this.roundScores(this.oceanScore, [
          'achievementStriving', 'activityLevel', 'adventurousness', 'altruism', 'anger', 'anxiety',
          'artisticInterests', 'assertiveness', 'cautiousness', 'cheerfulness', 'cooperation', 'depression',
          'dutifulness', 'emotionality', 'excitementSeeking', 'friendliness', 'gregariousness', 'imagination',
          'immoderation', 'intellect', 'liberalism', 'modesty', 'morality', 'orderliness', 'selfConsciousness',
          'selfDiscipline', 'selfEfficacy', 'sympathy', 'trust', 'vulnerability'
      ]);
    }

    this.calculateProgressBarScores(this.oceanScore, ['o', 'c', 'e', 'a', 'n'], 5);
    this.initializeOceanTraits();
  }

  applyMultiplier(scores: any, multiplier: number, keys: string[]): void {
    keys.forEach(key => {
        if (scores[key] != null) {
            scores[key] *= multiplier;
        }
    });
  }

  roundScores(scores: any, keys: string[]): void {
    keys.forEach(key => {
        if (scores[key] != null) {
            scores[key] = Math.round(scores[key]);
        }
    });
  }

  calculateProgressBarScores(scores: any, keys: string[], multiplier: number): void {
    keys.forEach(key => {
        if (scores[key] != null) {
            this.progressBarOceanScores[key.toUpperCase()] = scores[key] * multiplier;
        }
    });
  }
  
}
