<div class="flex gap-3 flex-wrap mt-3">
    <div class="flex-nowrap flex gap-2 align-items-center" *ngIf="questionPageHiddenLog > 0">
        <img src="assets/vinter/icons/cheating-icon.svg">
        <p class="font-medium page-hidden">{{"Key.SwitchTab" | translate: {value: questionPageHiddenLog } }}</p>
    </div>
    <div *ngIf="answer?.answerContent?.isFaceDetected" class="flex-nowrap flex gap-2 align-items-center">
        <img src="assets/vinter/icons/face-detection-icon.svg" class="detect-img">
        <p class="font-medium face-detected">{{"Key.FaceDetected" | translate }}</p>
    </div>
    <div *ngIf="answer?.answerContent?.isMultiFaceDetected" class="flex-nowrap flex gap-2 align-items-center">
        <img src="assets/vinter/icons/multiface-detected-icon.svg" class="detect-img">
        <p class="font-medium multiface-detected">{{"Key.MultiFaceDetected" | translate }}</p>
    </div>
    <div *ngIf="answer?.answerContent?.improprietyAnalysis?.isImproprietySpeech" class="flex-nowrap flex gap-2 align-items-center">
        <img src="assets/vinter/icons/impropriety-speech-warning.svg" class="detect-img">
        <p class="font-medium impropriety-speech">{{"Key.ImproprietySpeechAnalysis" | translate }}</p>
    </div>
</div>

<div class="flex">
    <img src="assets/vinter/icons/text-questioncoktan-secmeli-cevap-icon-arrow-back-up.svg">
    <h5 class="text-justify answer-title font-semibold text-lg ml-2">
        {{"Key.Answer" | translate}}
    </h5>
</div>

<div class="ml-5 flex" *ngIf="answer === null">
    <img src="assets/vinter/icons/not-answered.svg">
    <p class="ml-2 empty-answer-text">{{"Key.ThisTypeQuestionNotAnswered" | translate}}</p>
</div>

<div *ngIf="answer?.answerContent" class="grid lg:ml-4 lg:-mr-2">
    <div class="col-12 md:col-12 lg:col-6 lg:px-2">
        <div class="flex flex-column answer-container border-1 border-solid border-gray-50 border-round-md">
            <div class="flex height-fit">
                <vg-player *ngIf="answer?.answerContent.contentURL ?? false" style="border-radius: 15px; background-color:white">
                    <video #speakingVideo [vgMedia]="$any(speakingMedia)" #speakingMedia id="speakingVideo" preload="auto"
                        controls (timeupdate)="highlightSpeaking($event.target)">
                        <source [src]="answer?.answerContent.contentURL" type="video/webm">
                        <source [src]="answer?.answerContent.contentURL" type="video/mp4">
                    </video>
                </vg-player>
            </div>
            <span class="text-gray-900 font-semibold mt-4 mb-2 text-lg">{{"Key.Text" | translate}}</span>
            <div *ngIf="answer?.answerContent?.gptEvalStatus !== GPTEvalStatusEnum.Succesful" class="w-h-min p-5 bg-white flex align-items-center justify-content-center flex-wrap">
                <img [src]="AICommentPathIcon" width="25px" height="25px">
                <span class="text-gray-700 font-bold text-center pt-2">
                    <div [ngSwitch]="answer?.answerContent.gptEvalStatus">
                        <div *ngSwitchCase="GPTEvalStatusEnum.ClientFail">
                            {{answer?.answerContent.aiFinalLog}}
                        </div>
                        <div *ngSwitchCase="GPTEvalStatusEnum.Fail">
                            {{"Key.AIFail" | translate}}
                        </div>
                        <div *ngSwitchCase="GPTEvalStatusEnum.Waiting">
                            {{"Key.WaitForAIComment" | translate}}
                        </div>
                        <div *ngSwitchDefault>
                            {{'Key.ErrorNotFound' | translate}}
                        </div>
                    </div>
                </span>
            </div>
            <div *ngIf="answer?.answerContent.gptEvalStatus === GPTEvalStatusEnum.Succesful" class="text-gray-700 line-height-4">
                {{answer?.answerContent.transcriptText}}
            </div>
        </div>
    </div>
    

    <div class="col-12 md:col-12 lg:col-6 lg:px-2">
        <div class="w-full flex flex-column align-items-center justify-content-center w-full custom-display border-round-lg border-1 border-solid border-blue-50 px-3 py-2">
            <div *ngIf="answer?.answerContent.gptEvalStatus === GPTEvalStatusEnum.Succesful && answer?.speechAnalysis != null && answer?.speechAnalysis.finalScore != null" class="w-full flex">
                    <div class="col-6 pl-0 pr-0 text-lg font-semibold text-gray-900">
                        {{'Key.TotalScore' | translate}}
                    </div>
                    <div class="col-6 flex align-items-center justify-content-end gap-2 pr-0 white-space-nowrap">
                        <p-progressBar class="w-full"
                            [value]="answer?.speechAnalysis ?? false ? answer?.speechAnalysis.finalScore : 0"
                            [showValue]="false"></p-progressBar>
                        <span class="font-bold text-blue-700 text-xs">% {{(answer?.speechAnalysis.finalScore ?? false) ? answer?.speechAnalysis.finalScore?.toFixed(0):0}}
                        </span>
                    </div>
            </div>

            <div *ngIf="answer?.answerContent.gptEvalStatus === GPTEvalStatusEnum.Succesful" class="col-12 p-0">
                <p-accordion [multiple]="true" iconPos="start">
                    <p-accordionTab>
                        <ng-template pTemplate="header">
                            <div class="flex align-items-center w-full">
                                <div class="flex align-items-center col-6 gap-2">
                                    <span class="text-sm text-gray-700 font-medium">{{"Key.VocabularyAccuracyScore" | translate}}</span>
                                    <img src="assets/vinter/icons/info-fill.svg"  pTooltip="{{'Key.VocabAccInfo' | translate}}" class="info-fill-icon">
                                </div>
                                <div class="flex align-items-center justify-content-end col-6 gap-2 pr-0 white-space-nowrap">
                                    <p-progressBar class="w-full progress-sub"
                                        [value]="this.answer?.speechAnalysis.vocabAccRangeScore"
                                        [showValue]="false"></p-progressBar>
                                    <span class="font-bold text-xs text-blue-700">% {{answer?.speechAnalysis.vocabAccRangeScore.toFixed(2)}}</span>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <p class="m-0 text-gray-500 text-sm text-justify">
                                {{vocabAccEval}}
                            </p>
                        </ng-template>
                    </p-accordionTab>
                    <p-accordionTab>
                        <ng-template pTemplate="header">
                            <div class="flex align-items-center w-full">
                                <div class="flex align-items-center col-6 gap-2">
                                    <span class="text-sm text-gray-700 font-medium">{{"Key.GrammarScore" | translate}}</span>
                                    <img src="assets/vinter/icons/info-fill.svg"  pTooltip="{{'Key.GrammarAccInfo' | translate}}" class="info-fill-icon">
                                </div>
                                <div class="flex align-items-center justify-content-end col-6 gap-2 pr-0 white-space-nowrap">
                                    <p-progressBar class="w-full progress-sub"
                                        [value]="this.answer?.speechAnalysis.grammarAccRangeScore"
                                        [showValue]="false"></p-progressBar>
                                    <span class="font-bold text-xs text-blue-700">% {{answer?.speechAnalysis.grammarAccRangeScore.toFixed(2)}}</span>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <p class="m-0 text-gray-500 text-sm text-justify">
                                {{grammarEval}}
                            </p>
                        </ng-template>
                    </p-accordionTab>                       
                    <p-accordionTab>
                        <ng-template pTemplate="header">
                            <div class="flex align-items-center w-full">
                                <div class="flex align-items-center col-6 gap-2">
                                    <span class="text-sm text-gray-700 font-medium">{{"Key.DiscourseOrganizationScore" | translate}}</span>
                                    <img src="assets/vinter/icons/info-fill.svg"  pTooltip="{{'Key.DiscourseOrganizationInfo' | translate}}" class="info-fill-icon">
                                </div>
                                <div class="flex align-items-center justify-content-end col-6 gap-2 pr-0 white-space-nowrap">
                                    <p-progressBar class="w-full progress-sub"
                                        [value]="this.answer?.speechAnalysis.discourseOrganizationScore"
                                        [showValue]="false"></p-progressBar>
                                    <span class="font-bold text-xs text-blue-700">% {{answer?.speechAnalysis.discourseOrganizationScore.toFixed(2)}}</span>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <p class="m-0 text-gray-500 text-sm text-justify">
                                {{discourseOrgEval}}
                            </p>
                        </ng-template>
                    </p-accordionTab>        
                    <p-accordionTab>
                        <ng-template pTemplate="header">
                            <div class="flex align-items-center w-full">
                                <div class="flex align-items-center col-6 gap-2">
                                    <span class="text-sm text-gray-700 font-medium">{{"Key.FluencyScore" | translate}}</span>
                                    <img src="assets/vinter/icons/info-fill.svg"  pTooltip="{{'Key.FluencyInfo' | translate}}" class="info-fill-icon">
                                </div>
                                <div class="flex align-items-center justify-content-end col-6 gap-2 pr-0 white-space-nowrap">
                                    <p-progressBar class="w-full progress-sub"
                                        [value]="this.answer?.speechAnalysis.fluencyScore"
                                        [showValue]="false"></p-progressBar>
                                    <span class="font-bold text-xs text-blue-700">% {{answer?.speechAnalysis.fluencyScore.toFixed(2)}}</span>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <p class="m-0 text-gray-500 text-sm text-justify">
                                {{fluencyEval}}
                            </p>
                        </ng-template>
                    </p-accordionTab>
                    <p-accordionTab>
                        <ng-template pTemplate="header">
                            <div class="flex align-items-center w-full">
                                <div class="flex align-items-center col-6 gap-2">
                                    <span class="text-sm text-gray-700 font-medium">{{"Key.PronunciationScore" | translate}}</span>
                                    <img src="assets/vinter/icons/info-fill.svg"  pTooltip="{{'Key.PronunciationInfo' | translate}}" class="info-fill-icon">
                                </div>
                                <div class="flex align-items-center justify-content-end col-6 gap-2 pr-0 white-space-nowrap">
                                    <p-progressBar class="w-full progress-sub"
                                        [value]="this.answer?.speechAnalysis.pronunciationScore"
                                        [showValue]="false"></p-progressBar>
                                    <span class="font-bold text-xs text-blue-700">% {{answer?.speechAnalysis.pronunciationScore.toFixed(2)}}</span>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <p class="m-0 text-gray-500 text-sm text-justify">
                                {{pronunciationEval}}
                            </p>
                        </ng-template>
                    </p-accordionTab>
                </p-accordion>
            </div>

            <div  *ngIf="answer?.answerContent?.gptEvalStatus === GPTEvalStatusEnum.ClientFail" class="col-12 flex flex-column gap-3 justify-content-center align-items-center">
                <img src="../../../../../assets/vinter/icons/text-grammar-error-regular.svg" class="w-2rem pt-3" >
                <span class="answer-text-label text-center text-base font-medium">{{ answer?.answerContent.aiFinalLog }}</span>
            </div>

        </div>
    </div>
</div>