import { isPlatformBrowser } from '@angular/common';
import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, inject, Input, PLATFORM_ID, ViewChild} from '@angular/core';
import { EQuestionCategories } from '@app/shared/common/enums';
import { Chart, registerables } from 'chart.js';

@Component({
    selector: 'app-general-test-card',
    templateUrl: './general-test-card.component.html',
    styleUrls: ['./general-test-card.component.scss']
})
export class GeneralTestCardComponent implements AfterViewInit {
    @Input() step: any;
    @Input() report: any;
    @Input() pageNumber: number = 0;
    @Input() chartData: any;
    @Input() languageAnsweredQuestions: any;

    spiderChartData: number[] = [];
    spiderChartLabels: string[] = ['Overall Score', 'L','R','G/V','W'];
    skillLevel: any[] = [];

    firstPartReportText: string = '';
    secondPartReportText: string = '';
    applicantPercentage: number = 0;
    reportTextChar:number = 0;

    questionCategories = EQuestionCategories;

    ngOnInit() {

    }
    
    ngAfterViewInit(): void {
        this.splitReportText();
        this.calculatePercentage();
        this.setKnobChartValue();
    }

    calculatePercentage(){
        this.applicantPercentage = (this.report.currentOrder / this.report.currentApplicantCount ) * 100;
    }
    
    splitReportText() {
        const maxChars = 340;
        this.reportTextChar = this.report.reportText.length;
        if (this.report.reportText.length > maxChars) {
            let splitIndex = this.report.reportText.lastIndexOf(' ', maxChars);
            if (splitIndex === -1) {
                splitIndex = maxChars;
            }
            this.firstPartReportText = this.report.reportText.slice(0, maxChars);
            this.secondPartReportText = this.report.reportText.slice(maxChars).trim();
        } 
        else {
            this.firstPartReportText = this.report.reportText;
            this.secondPartReportText = '';
        }
    }

    setKnobChartValue(){
        let writingLevel = this.languageAnsweredQuestions.find(x => x.id === this.questionCategories.Writing).averageScores;
        let readingLevel = this.languageAnsweredQuestions.find(x => x.id === this.questionCategories.Reading).averageScores;
        let listeningLevel = this.languageAnsweredQuestions.find(x => x.id === this.questionCategories.Listening).averageScores;
        let grammerLevel = this.languageAnsweredQuestions.find(x => x.id === this.questionCategories.Grammer).averageScores;
        let vocabLevel = this.languageAnsweredQuestions.find(x => x.id === this.questionCategories.Vocabulary).averageScores;
        let speakingLevel = this.languageAnsweredQuestions.find(x => x.id === this.questionCategories.Speaking).averageScores;

        let vg = ((grammerLevel*100) + (vocabLevel*100))/2;

        this.skillLevel = [
            { id: this.questionCategories.Writing, color: 'pink', name: 'Writing', percentage: writingLevel, level: this.step.generalEnglishTestResult.writingLevel },
            { id: this.questionCategories.Listening, color: 'purple', name: 'Listening', percentage: listeningLevel*100, level: this.step.generalEnglishTestResult.listeningLevel  },
            { id: this.questionCategories.Reading, color: 'dark-blue', name: 'Reading', percentage: readingLevel*100, level: this.step.generalEnglishTestResult.readingLevel  },
            { id: this.questionCategories.Grammer, color: 'red', name: 'Grammar/ Vocabulary', percentage: vg, level: this.step.generalEnglishTestResult.grammarVocabularyLevel  },
            { id: this.questionCategories.Speaking, color: 'blue', name: 'Speaking', percentage: speakingLevel, level: this.step.generalEnglishTestResult.speakingLevel  }
        ];
    }
}