import { Component, EventEmitter, Input, Output, Pipe, PipeTransform } from '@angular/core';
import { EDeviceType } from '@app/shared/common/enums';
import { AppInfoService } from '@app/shared/services/app-info.services';

@Component({
  selector: 'app-cheating-analysis',
  templateUrl: './cheating-analysis.component.html',
  styleUrls: ['./cheating-analysis.component.scss']
})
export class CheatingAnalysisComponent {

  @Input() item: any;
  
  DeviceTypeEnum = EDeviceType;
  backGroundVisible = false;

  constructor( 
    private appInfoService: AppInfoService,) { }

  ngOnInit(): void {
  }
  setBackgroundDarkness(event:boolean)
  {
    this.backGroundVisible = event;
   
  }


}

@Pipe({
  name: 'deviceTypes',
})
export class DeviceTypesPipe implements PipeTransform {
  transform(row: any, list: any[]): any {
    return list.find(x => x.id === row).name;
  }
  
}
