<div class="flex justify-content-center align-items-center flex-column">
    <div class=" flex flex-row">
        <span class="text-center font-bold text-5xl line-height-4 text-blue-500"> {{ "Key.UpgradeLicensePlan" |
            translate}}</span>
        <div class="flex justify-content-center align-items-center">
            <img src="assets/vinter/icons/crown-yellow-icon.svg" alt="crown-yellow-icon" [width]="20" [height]="20"
                class="ml-2" />
        </div>
    </div>
    <div>
        <div *ngIf="suggestedPackageType !== null"
            class="customLetterSpacingPopupText mb-3 text-center font-semibold line-height-3 text-gray-500">{{ ("Key." +
            getKeyName()) | translate }} </div>
    </div>
</div>

<div
    class="align-items-center pt-1 justify-content-center flex gap-3 Statikposition h-4rem	 sticky top-0 pr-1  justify-content-center flex align-items-center ">
    <div class="text-center font-medium text-xl	" [ngClass]="!isAnnual ? 'switch-text-color' : 'text-gray-700'">
        {{ "Key.Monthly" | translate}}
    </div>
    <div>
        <p-inputSwitch [(ngModel)]="isAnnual" (ngModelChange)="isAnnualChanged()"></p-inputSwitch>
    </div>
    <div class="text-center font-medium text-xl	" [ngClass]="isAnnual ? 'switch-text-color' : 'text-gray-700 '">
        {{ "Key.Annual" | translate}}
    </div>
</div>
<div class="flex-wrap card-container grid mt-2 flex justify-content-center">
    <ng-template ngFor let-item [ngForOf]="organizationPlans?.licensePlans" let-i="index">
        <div class="col-12 md:col-6 lg:col-3 justify-content-center border-round-2xl plan-card-border border-1 m-3 pt-3 pb-4 pr-4 pl-4"
            *ngIf="item.licensePlanType === licencePlanTypeEnum.StandartPlan" [ngClass]="{'plan-card-current-border' : organizationPlans.licensePlanCode === item.licenseCode,
                'suggestedPlan': isSuggestedPlan(item.licenseCode),'opacity-30' : item.compareValue}">
            <div class="flex card-header align-items-start">
                <h5 [ngClass]="{ 'text-green-500': organizationPlans.licensePlanCode === item.licenseCode}"
                    class="font-semibold text-lg title-content text-blue-500">
                    {{ item.name }}
                </h5>
            </div>

            <div class="flex-column flex align-items-start pt-2">
                <p *ngIf="item.licenseCode !== licensePlanCodeEnum.Enterprise && item.licenseCode !== licensePlanCodeEnum.EnterpriseAnnual"
                    class="mb-0 licence-detail gray-800 font-bold text-3xl">
                    € {{ item.price }}
                </p>
                <p *ngIf="!isAnnual && item.licenseCode !== licensePlanCodeEnum.Enterprise && item.licenseCode !== licensePlanCodeEnum.EnterpriseAnnual"
                    class="pt-2 text-base text-blue-300">
                    {{ "Key.Monthly" | translate}}
                </p>
                <p *ngIf="isAnnual && item.licenseCode !== licensePlanCodeEnum.Enterprise && item.licenseCode !== licensePlanCodeEnum.EnterpriseAnnual"
                class="pt-2 text-base text-blue-300">
                    {{ "Key.Annual" | translate}}
            </p>
                <p *ngIf="item.licenseCode === licensePlanCodeEnum.Enterprise || item.licenseCode === licensePlanCodeEnum.EnterpriseAnnual"
                    class="plan-detail flex p-3 w-full">
                    <span class="needHelp-text font-semibold blue-500">{{'Key.'+item.enterprisePriceTranslation.key |
                        translate }}</span>
                </p>
            </div>
            <div *ngIf="item.licenseCode !== licensePlanCodeEnum.Enterprise && item.licenseCode !== licensePlanCodeEnum.EnterpriseAnnual"
                class="my-3 line"></div>
            <div *ngIf="item.licenseCode === licensePlanCodeEnum.Enterprise || item.licenseCode === licensePlanCodeEnum.EnterpriseAnnual "
                class="my-3 line line-enterprise"></div>
            <div *ngIf="item.licenseCode !== licensePlanCodeEnum.Enterprise && item.licenseCode !== licensePlanCodeEnum.EnterpriseAnnual "
                class="justify-content-start flex-column flex">
                <p class="plan-detail align-items-center p-2 flex">
                    <img src="assets/vinter/images/tik-rounded-icon.svg" alt="vinter" class="mr-2 block tik-icon" />
                    <span
                        class="plan-detail-text font-medium text-sm text-center">{{'Key.'+item.descriptionTranslations[0].key|
                        translate: {applicantCount: item.applicantCount} }}</span>
                </p>
                <p *ngFor="let translation of item.descriptionTranslations.slice(1); let i = index"
                    class="plan-detail align-items-center p-2 flex">
                    <img src="assets/vinter/images/tik-rounded-icon.svg" alt="vinter" class="mr-2 block tik-icon" />
                    <span class="plan-detail-text font-medium text-sm text-center">{{'Key.' + translation.key |
                        translate}}</span>
                </p>
            </div>
            <div
                *ngIf="item.licenseCode === licensePlanCodeEnum.Enterprise || item.licenseCode === licensePlanCodeEnum.EnterpriseAnnual ">

                <div class="Enterprise-custom-height">
                    <p class=" flex ">
                        <span class="callMe-text text-sm">{{'Key.'+item.enterpriseDescriptionTranslation.key| translate
                            }}</span>
                    </p>
                </div>

            </div>
            <div class="mt-7 flex justify-content-center w-full">
                <div class="w-full">
                    <p-button [disabled]="true" *ngIf="organizationPlans.licensePlanCode === item.licenseCode"
                        label="{{'Key.CurrentPlan' | translate}}" icon="pi pi-check" iconPos="right"
                        class="current-plan"></p-button>
                    <p-button
                        *ngIf="!item.compareValue && item.licenseCode !== licensePlanCodeEnum.Enterprise && item.licenseCode !== licensePlanCodeEnum.EnterpriseAnnual && organizationPlans.licensePlanCode !== item.licenseCode"
                        label="{{ 'Key.UpgradePlan' | translate }}"
                        [styleClass]="isSuggestedPlan(item.licenseCode) ? '' : 'p-button-outlined'"
                        icon="pi pi-arrow-up-right" iconPos="right"
                        (onClick)="redirectToStripeCheckoutSessionUpgradePlan(item.licensePlanId)"></p-button>
                    <p-button
                        *ngIf="organizationPlans.licensePlanCode !== item.licenseCode && (item.licenseCode === licensePlanCodeEnum.Enterprise || item.licenseCode === licensePlanCodeEnum.EnterpriseAnnual)"
                        label="{{ 'Key.ContactUs' | translate }}" styleClass="p-button-outlined mt-1"
                        (click)="redirectToVinterMe()"></p-button>
                </div>
            </div>
        </div>
    </ng-template>

    <div class="flex flex-column w-full px-5">
        <div *ngIf="(organizationPlans?.licensePlanCode === licensePlanCodeEnum.Essential 
        || organizationPlans?.licensePlanCode === licensePlanCodeEnum.EssentialAnnual 
        || organizationPlans?.licensePlanCode === licensePlanCodeEnum.Premium
        || organizationPlans?.licensePlanCode === licensePlanCodeEnum.PremiumAnnual )"
            class="flex justify-content-center align-items-center flex-column pt-5">
            <span class="text-center font-semibold text-lg line-height-4 text-blue-500"> {{ "Key.Or" |
                translate}}</span>
            <span class="text-center font-bold text-4xl line-height-4 header-title"> {{ "Key.SelectExtraPackage" |
                translate}}</span>
        </div>
        <ng-template class="w-full" ngFor let-item [ngForOf]="organizationPlans?.licensePlans" let-i="index">
            <div class="pl-4 col-12 md:col-6 lg:col-5 justify-content-center border-round-2xl additionalLicense-plan-card-border border-1 m-3"
                *ngIf="(item.compareValue) && (organizationPlans.licenseOrganizationInfoId != null)
                   && (organizationPlans.licensePlanCode === licensePlanCodeEnum.Essential || organizationPlans.licensePlanCode === licensePlanCodeEnum.EssentialAnnual || organizationPlans.licensePlanCode === licensePlanCodeEnum.Premium || organizationPlans.licensePlanCode === licensePlanCodeEnum.PremiumAnnual ) && item.licensePlanType === licencePlanTypeEnum.ExtraPackage && ((!isAnnual && item.licensePlanId === licencePlanEnum.ATSOneShotMonthly) || (isAnnual && item.licensePlanId === licencePlanEnum.ATSOneShotAnnual))"
                [ngClass]="{'suggestedPlan': isSuggestedPackage(item.licenseCode)}">
                <div class="flex flex-row justify-content-between">
                    <div class="flex card-header align-items-start">
                        <h5 class="font-semibold text-lg title-content text-blue-500">
                            {{ 'Key.' + item.name | translate }}
                        </h5>
                    </div>
                    <div class="flex pt-3 pr-3">
                        <div class="pb-0 justify-content-start flex-column flex">
                            <p class="plan-detail align-items-center p-2 flex">
                                <img src="assets/vinter/images/tik-rounded-icon.svg" alt="vinter"
                                    class="mr-2 block tik-icon" />
                                <span class="plan-detail-text font-medium text-sm text-center">
                                    {{ 'Key.' + item.descriptionTranslations[0]?.key | translate }}
                                </span>
                            </p>
                        </div>

                        <div class="pl-2 flex justify-content-center">
                            <div class="w-full">
                                <p-button
                                    *ngIf="!isPlanValid(item)"
                                    label="{{ 'Key.Buy' | translate }}"
                                    (onClick)="redirectToStripeCheckoutSessionAdditionalPlan(item.licensePlanId)"
                                    [styleClass]="isSuggestedPlan(item.licenseCode) ? '' : 'p-button-outlined'"></p-button>
                                    <p-button 
                                    [disabled]="true"
                                    *ngIf="isPlanValid(item)"
                                    label="{{ 'Key.CurrentPlan' | translate }}" 
                                    icon="pi pi-check" 
                                    iconPos="right" 
                                    class="current-plan">
                                  </p-button>                                  
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex-column flex align-items-start">
                    <p class="mb-0 licence-detail gray-800 font-bold text-3xl">
                        € {{ item.price }}
                    </p>

                </div>
            </div>
        </ng-template>

        <app-license-plan-compare-pricing [isAnnual]="isAnnual"
            *ngIf="organizationPlans != null && packageFeature != null " [LicensePlan]="inputLicensePlans"
            [PackageFuture]="packageFeature"></app-license-plan-compare-pricing>
    </div>

</div>

<p-dialog [(visible)]="isUpgradePlanDialogVisible" [draggable]="false" [modal]="true" showEffect="fade"
    [style]="{width: '30vw'}" [breakpoints]="{'960px': '75vw'}" class="dialogPage" [closable]="false">
    <div class="grid p-3 px-6">
        <div class="col-12 flex justify-content-center">
            <img src="assets/vinter/images/licence-plan-icon.svg" alt="vinter-license"
                class="mr-2 mt-2 block upgrade-icon" width="100%" />
        </div>
        <div class="grid">
            <div class="col-12 flex justify-content-center">
                <p class="upgrade-header text-center text-4xl font-bold blue-500">{{"Key.UpgradeLicenseInfoHeader" |
                    translate: {
                    value1:
                    clickedLicensePlan?.licensePlanName } }}</p>
            </div>
            <div class="col-12 flex justify-content-center">
                <p class="upgrade-text text-center text-xl gray-500">{{"Key.UpgradeLicenseInfoText" | translate}}</p>
            </div>
        </div>
    </div>
    <div class="grid px-6 mt-3">
        <div class="col-6">
            <p-button label="{{'Key.SendRequest' | translate}}" (onClick)="sendUpgradeLicenseMail()"
                styleClass="mr-2 mb-2 p-button"></p-button>
        </div>
        <div class="col-6">
            <p-button label="{{'Key.Cancel' | translate}}" (onClick)="isUpgradePlanDialogVisible = false"
                styleClass="mr-2 mb-2 p-button p-button-outlined"></p-button>
        </div>
    </div>
</p-dialog>

<p-dialog [(visible)]="isUpgradePlanSentDialogVisible" [draggable]="false" [modal]="true" showEffect="fade"
    [style]="{width: '30vw'}" [breakpoints]="{'960px': '75vw'}" class="dialogPage" [closable]="false">
    <div class="grid p-3 px-6">
        <div class="col-12 flex justify-content-center">
            <img style="width: 9rem;" src="assets/vinter/gifs/invite-gif.gif">
        </div>
        <div class="grid">
            <div class="col-12  flex justify-content-center">
                <p class="upgrade-header text-center text-4xl font-bold blue-500">{{"Key.UpgradeLicenseSentInfoHeader" |
                    translate}}</p>
            </div>
            <div class="col-12 flex justify-content-center">
                <p class="upgrade-text text-center text-xl gray-500">{{"Key.UpgradeLicenseSentInfoText" | translate}}
                </p>
            </div>
        </div>
    </div>
    <div class="grid px-6 mt-3">
        <div class="col-12">
            <p-button label="{{'Key.GoToPlans' | translate}}" (onClick)="isUpgradePlanSentDialogVisible = false"
                styleClass="mr-2 mb-2 p-button"></p-button>
        </div>
    </div>
</p-dialog>