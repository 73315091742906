<app-applicant-general-application-form *ngIf="generalApplicationFormId ?? false" (generalApplicationTab)="generalApplicationTab($event)" (generalApplicationCVInfo)="generalApplicationCvInfo($event)" [generalApplicationFormId]="generalApplicationFormId" [hidden]="true" ></app-applicant-general-application-form>
<div class="card custom-padding">
    <div style="min-height: 55vh;">
        <p-tabView [scrollable]="true" (onClose)="resetAnswarPage()" (activeIndexChange)="changeActiveIndex($event)"
            [activeIndex]="activeIndex">
            <ng-template pTemplate="previousicon">
                <div id="nextButton" class="flex" [ngClass]="checkPrevIconVisibility()"><img
                        class="img-hor-vert align-self-center ml-2 w-1rem h-1rem"
                        src="/assets/vinter/icons/right-arrow-icon.svg" /></div>
            </ng-template>
            <ng-template pTemplate="nexticon">
                <div id="nextButton" class="flex" [ngClass]="{'hidden':checkNextIconVisibility()==='hidden', 'flex':checkNextIconVisibility()!=='hidden'}"><img
                        class="align-self-center mr-2 w-1rem h-1rem" src="/assets/vinter/icons/right-arrow-icon.svg" />
                </div>
            </ng-template>

            <ng-container *ngFor="let item of applicationSteps; let i = index">

                <p-tabPanel *ngIf="i === 0 && item.generalApplicationFormId ?? false && generalApplicationFormQuestions.length > 0" header="{{'Key.GeneralApplication' | translate}}">
                    <ng-template pTemplate="header">
                        <span>{{'Key.PreAssessment' | translate}}</span>
                        <div *ngIf="item.generalApplicationAutomationExecutionAt!=null"
                        style="display: flex;justify-content: flex-start;align-items: center;">
                        <img pTooltip="{{'Key.AutomationExecutionAt' | translate}}:{{item.generalApplicationAutomationExecutionAt | date:'dd/MM/yy HH:mm'}}"
                            tooltipPosition="top" width="14px" style="margin-left: 10px;"
                            src="../../../../../assets/vinter/icons/Vector.svg">
                    </div>
                        <div class="outer-ring-completed">
                            <p-chip class="tabview" icon="pi pi-circle-fill"
                                [style]="{'background-color': '#CFF0BF', 'color': '#37AC00'}"></p-chip>
                        </div>
                    </ng-template>
                    <app-applicant-general-application-form (generalApplicationTab)="generalApplicationTab($event)" (generalApplicationCVInfo)="generalApplicationCvInfo($event)" [generalApplicationFormId]="generalApplicationFormId" ></app-applicant-general-application-form>
                </p-tabPanel>
                <p-tabPanel>
                    <ng-template pTemplate="header">
                        <span>{{item.positionStep.title}}</span>
                        <div *ngIf="item.automationExecutionAt!=null"
                            style="display: flex;justify-content: flex-start;align-items: center;">
                            <img pTooltip="{{'Key.AutomationExecutionAt' | translate}}:{{item.automationExecutionAt | date:'dd/MM/yy HH:mm'}}"
                                tooltipPosition="top" style="width: 14px;margin-left: 10px;"
                                src="../../../../../assets/vinter/icons/Vector.svg">
                        </div>
                        <div *ngIf="item.status === applicationStepStatus.HRCompleted || item.status === applicationStepStatus.ApplicantCompleted"
                            class="outer-ring-completed">
                            <p-chip class="tabview" icon="pi pi-circle-fill"
                                [style]="{'background-color': '#CFF0BF', 'color': '#37AC00'}"></p-chip>
                        </div>
                        <div *ngIf="item.status === applicationStepStatus.InProgress || item.status === applicationStepStatus.Ready"
                            class="outer-ring-inProgress">
                            <p-chip class="tabview" icon="pi pi-circle-fill"
                                [style]="{'background-color': '#CFDBF9', 'color': '#2962FF'}"></p-chip>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div>
                            <div class="w-full flex col-12 ml-3 pt-0 pr-0 completed-tab-margin">
                                <div class="col pt-0 pl-2 flex pr-0 completed-tab-display">
                                    <div *ngIf="item.status === applicationStepStatus.HRCompleted || item.status === applicationStepStatus.ApplicantCompleted"
                                        class="flex pb-3 p-3 align-items-center" style="background: #CFF0BF; border-radius: 6px; color: #37AC00">

                                        <i class="pi pi-circle-fill" style="color: #37AC00"></i>
                                        <p class="completed-date-text flex align-items-center justify-content-center mb-0">{{'Key.Completed' | translate}} </p>
                                        <p class="completed-date-text flex align-items-center justify-content-center mb-0 ml-1" style="font-size: 12px;">- {{ item.completedDate | date:'dd.MM.yy' }}</p>

                                        <img *ngIf="item.positionStep.questionCollection.questionCollectionCategory === oceanEnum && item.isTimesUp"
                                                class="step-ocean-error" pTooltip="{{'Key.TimesUp' | translate}}" tooltipPosition="top" width="18px" height="18px" src="../../../../../assets/vinter/icons/message-rounded-error.svg" />


                                    </div>
                                    <div *ngIf=" item.status === applicationStepStatus.Ready" class="flex pb-3 p-3 align-items-center"
                                        style="background: #CFDBF9; border-radius: 6px; color: #2962FF">
                                        <i class="pi pi-circle-fill" style="color: #2962FF"></i>
                                        <p class="completed-date-text flex align-items-center justify-content-center mb-0" style="color: #2962FF">{{'Key.StepPending' | translate}} </p>

                                    </div>
                                    <div *ngIf="item.status === applicationStepStatus.InProgress"  [ngClass]="{'pr-0': item.isStepHasError}" class="flex pb-3 p-3 align-items-center"
                                        style="background: #CFDBF9; border-radius: 6px; color: #2962FF">

                                        <i class="pi pi-circle-fill" style="color: #2962FF"></i>
                                        <p class="completed-date-text flex align-items-center justify-content-center mb-0" style="color: #2962FF">{{'Key.StepInProgress' | translate}} </p>

                                        <img *ngIf="item.isStepHasError"  pTooltip="{{'Key.StepHasError' | translate}}" tooltipPosition="top" class="step-has-error" width="18px" height="18px" src="../../../../../assets/vinter/icons/message-rounded-error.svg" />
                                        <!-- <div class="flex justify-content-center align-items-center pl-1 ">
                                            <img *ngIf="item.isStepHasError" width="18px" height="18px"
                                                src="../../../../../assets/vinter/icons/message-rounded-error.svg" />
                                        </div> -->

                                        <!-- <p class="completed-date-text flex align-items-center justify-content-center" style="font-size: 12px;"> - {{ item.startedDate | date:'dd.MM.yy' }}</p> -->
                                    </div>
                                    <div *ngIf="item.status === applicationStepStatus.NotStarted" class="flex pb-3 p-3 align-items-center"
                                    style="background: #E6E7E8; border-radius: 6px; color: #9B9EA3">
                                        <i class="pi pi-circle-fill" style="color: #9B9EA3"></i>
                                        <p class="completed-date-text flex align-items-center justify-content-center mb-0" style="color: #9B9EA3">{{'Key.NotStarted' | translate}} </p>

                                    </div>
                                </div>
                                <div *ngIf="this.applicationStepId === this.applicationSteps[i].id && item.positionStep.questionCollection.questionCollectionCategory !== this.CustomStepEnum"
                                    class="col-4 ml-3 w-auto flex justify-content-end height-fit-content redo-step">
                                    <img (click)="showRedoPopup()" class="custom-svg-question-icon"
                                        style="cursor: pointer; margin-right: 0.3rem; height:18px"
                                        src="/assets/vinter/icons/redoStep.svg" />
                                    <p (click)="showRedoPopup()" class="redo-step-text height-fit-content">{{'Key.StepRedo' | translate}}
                                    </p>
                                </div>
                            </div>

                            <div *ngIf="item.status !== applicationStepStatus.NotStarted && item.order !== -4">

                                <div *ngIf="!(item.completedDate ?? false)">
                                    <div class="flex flex-wrap justify-content-center align-items-center mt-8">
                                        <div
                                            class="col-12 flex justify-content-center align-items-center empty-title pl-0">
                                            <img class="empty-icon"
                                                src="../../../../../assets/vinter/icons/empty-result-list-svg.svg" />
                                        </div>
                                        <div class="col-12 flex flex-wrap justify-content-center text-align-center empty-title pl-0 text-lg font-semibold">
                                            <div class="col-6 flex justify-content-center align-items-center text-center empty-col">
                                                <span>
                                                    {{'Key.PendingStepMessage1' | translate}}
                                                    <span class="text-blue-400 font-semibold text-lg">
                                                        &nbsp;{{"’"+ item.positionStep.title + "’"}}&nbsp;
                                                    </span>
                                                    {{"Key.PendingStepMessage2" | translate}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="(item.completedDate ?? false)">
                                    <div
                                        *ngIf="item !== null && positionStepTypes[i]?.id=== QuestionListEnum">
                                        <div *ngIf="item.positionStep.questionCollection.subCategory === 0 ||
                                                    item.positionStep.questionCollection.subCategory === NumericalCognitiveAbility ||
                                                    item.positionStep.questionCollection.subCategory === VerbalCognitiveAbility">
                                            <app-applicant-step-question-list  [item]="setItemData(item)" [applicantId]="applicantId"
                                                [applicationId]="applicationId"
                                                (sendAverageRating)="setAvgRating($event)"></app-applicant-step-question-list>
                                        </div>
                                        <div *ngIf="item.positionStep.questionCollection.subCategory === OCEAN50 || item.positionStep.questionCollection.subCategory === OCEAN120">
                                            <div *ngIf="item.isTimesUp">
                                                <div class="flex flex-wrap justify-content-center align-items-center mt-8">
                                                    <div class="col-12 flex justify-content-center align-items-center empty-title pl-0">
                                                        <img class="empty-icon"
                                                            src="../../../../../assets/vinter/icons/empty-result-list-svg.svg" />
                                                    </div>
                                                    <div class="col-12 flex flex-wrap justify-content-center text-align-center empty-title pl-0 text-lg font-semibold">
                                                        {{'Key.IncompleteStepMessage' | translate}}
                                                    </div>
                                                    <div class="col-12 flex flex-wrap justify-content-center text-align-center empty-timesup-title pl-0 text-lg font-semibold">
                                                        {{'Key.TimesUpMessage' | translate}}

                                                    </div>
                                                </div>

                                            </div>
                                            <div *ngIf="!item.isTimesUp">
                                                <app-applicant-step-personality-ocean *ngIf="(item.stepPerTestResult ?? false)"
                                                    [item]="setItemData(item)"
                                                    [applicantID]="applicantId"
                                                    [oceanScore]="item.stepPerTestResult"></app-applicant-step-personality-ocean>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="item !== null && positionStepTypes[i]?.id=== VideoMeetingEnum">
                                        <app-applicant-step-video-meeting
                                            [item]="setItemData(item)"></app-applicant-step-video-meeting>
                                    </div>

                                    <!-- <div *ngIf="item !== null && positionStepTypes[i]?.id=== QuestionListEnum
                                    && item.positionStep.questionCollection.questionCollectionCategory === foreignLanguageEnum">
                                        <app-applicant-step-language [item]="setItemData(item)" [applicantId]="applicantId"
                                            [applicationId]="applicationId"
                                            (sendAverageRating)="setAvgRating($event)"></app-applicant-step-language>

                                    </div> -->

                                </div>
                            </div>
                            <div *ngIf="item.status !== applicationStepStatus.NotStarted && item.order === -4">
                                <div *ngIf="currentStepId === OfferStateEnum">
                                    <!-- <app-applicant-step-offer
                                        [applicationId]="applicationId"></app-applicant-step-offer> -->
                                </div>
                            </div>
                        </div>

                        <div *ngIf="item.status === applicationStepStatus.NotStarted">
                            <div class="col-12 flex justify-content-center mt-8">
                                <img src="assets/vinter/icons/application-step-pending.svg">
                            </div>
                            <div class="col-12 flex justify-content-center text-lg font-semibold">
                                <div class="col-6 flex justify-content-center align-items-center text-center " style="width: 500px;">
                                    <span>
                                        {{"Key.PendingStepMessage1" | translate}}
                                        <span class="text-blue-400 font-semibold text-lg">
                                            &nbsp;{{"’"+ item.positionStep.title + "’"}}&nbsp;
                                        </span>
                                        {{"Key.PendingStepMessage2" | translate}}
                                    </span>
                            </div>
                            </div>
                        </div>

                        <p-dialog (onHide)="closeRedoPopup()" [(visible)]="popupOpen" [modal]="true"
                            [baseZIndex]="10000" [style]="{ width: '35rem'}">
                            <div class="row flex justify-content-center" style="margin-bottom: 1rem;">
                                <img class="custom-svg-question-icon align-self-center"
                                    src="/assets/vinter/icons/redoStep-big.svg" />
                            </div>
                            <div class="justify-content-center">
                                <div class="redo-step-title-warning">{{'Key.StepRedoTitle' | translate}}</div>
                                <div class="redo-step-title-warning">{{'Key.StepRedoConfirm' | translate}}</div>
                            </div>
                            <div class="justify-content-center">
                                <div class="redo-step-text-warning">{{"’"+ item.positionStep.title + "’"}}
                                    {{'Key.StepRedoDescription' | translate}}</div>
                            </div>
                            <div class="flex justify-content-center">
                                <div style="padding-right: 0.7rem;">
                                    <p-button label="{{'Key.Reset' | translate}}" styleClass="p-button"
                                        (click)="confirmRedo()"></p-button>
                                </div>
                                <div style="padding-left: 0.7rem;">
                                    <p-button label="{{'Key.GoBack' | translate}}" styleClass="p-button-outlined"
                                        (click)="closeRedoPopup()"></p-button>
                                </div>
                            </div>
                        </p-dialog>

                    </ng-template>
                </p-tabPanel>
            </ng-container>
        </p-tabView>
    </div>

</div>
