<div class="flex flex-column">
    <ng-container *ngIf="questionList.questionCollectionQuestionRelations.length > 0">
        <div *ngFor="let relation of questionList.questionCollectionQuestionRelations; let i = index"
            class="question-container col-12 mx-2 flex pb-0 align-items-center justify-content-center">
            <div class="grid flex col pb-0 pl-4 pr-5 question-text-size">
                <div class="text-gray-300 col-1 mr-2 pt-0 px-0 order-width">
                    {{ relation.order }}
                </div>

                <div class="col-1 pt-1 order-icon-width ml-0 h-3rem block"
                    [ngClass]="questionList.questionCollectionCategory == questionCollectionCategories.ForeignLanguage ? 'pr-7' : ''">
                    <div class="grid">
                        <div class="col-12 p-0 flex flex-nowrap">
                            <div *ngIf="relation.question.expectedAnswerType === QuestionExpectedAnswerType.Video">
                                <img class="custom-svg-question-icon block align-self-center"
                                    src="/assets/vinter/icons/videoQuestion.svg" />
                            </div>
                            <div *ngIf="relation.question.expectedAnswerType === QuestionExpectedAnswerType.OpenEnded">
                                <img class="custom-svg-question-icon block align-self-center"
                                    src="/assets/vinter/icons/openEndedQuestion.svg" />

                            </div>
                            <div
                                *ngIf="relation.question.expectedAnswerType === QuestionExpectedAnswerType.MultipleChoice">
                                <img class="custom-svg-question-icon block align-self-center"
                                    src="/assets/vinter/icons/Quiz.svg" />
                            </div>
                            <div *ngIf="relation.question.expectedAnswerType === QuestionExpectedAnswerType.Coding">
                                <img class="custom-svg-question-icon block align-self-center"
                                    src="/assets/vinter/icons/codingQuestionIcon.svg" />
                            </div>

                            <div class="english-icon"
                                *ngIf="questionList.questionCollectionCategory == questionCollectionCategories.ForeignLanguage">
                                <app-custom-chip [svgUrl]="relation.question.questionCategorySvgPath"
                                    [alt]="relation.question.svgAlt" [bgColorClass]="'bg-transparent'"
                                    [imgSize]="'23px'"></app-custom-chip>
                            </div>

                        </div>

                        <div class="col-12 p-0 flex justif-content-center">
                            <div *ngIf="relation.isAnyCheatLogExist">
                                <app-step-result-question-behaviour-info [isFaceDetected]="relation.isFaceDetected"
                                    [isImproprietySpeech]="relation.isImproprietySpeech"
                                    [isMultipleFaceDetected]="relation.isMultipleFaceDetected"
                                    [isSwitchedTab]="relation.isSwitchedTab">
                                </app-step-result-question-behaviour-info>

                            </div>

                        </div>
                    </div>
                </div>

                <div class="pr-5 col h-4rem -mt-2 cursor-pointer" (click)="openAnswerSidebar(relation)">
                    <div class="flex flex-nowrap">
                        <span *ngIf="questionList.isTechnicalOrCompetenceTest"
                            class="font-medium text-sm mr-2 pb-3 custom-margin-top text-blue-800">
                            {{ relation?.question?.shortLangText}}
                        </span>
                        <div *ngIf="questionList.isTechnicalOrCompetenceTest"
                            class="border-right-2 ml-2 mr-2 pb-3 h-1rem custom-margin-top border-blue-50"></div>
                        <span
                            class="custom-margin-top pb-3 overflow-hidden text-overflow-ellipsis text-sm text-blue-600">
                            {{ relation.question.questionConcept?.name}}
                        </span>
                        <div *ngIf="questionList.isTechnicalOrCompetenceTest"
                            class="border-right-2 ml-2 mr-2 pb-3 h-1rem custom-margin-top border-blue-50"></div>
                        <div *ngIf="questionList.isTechnicalOrCompetenceTest"
                            class="flex flex-nowrap pb-3 custom-margin-top">
                            <img alt="" [src]="questionWeightIconPath">
                            <span class="text-blue-800 font-medium text-sm ml-2">{{relation.weight}}</span>
                        </div>
                    </div>
                    <div class="question-content text-gray-900 font-medium custom-margin-top truncate-text white-space-normal text-overflow-ellipsis overflow-hidden ml-0"
                        [innerHTML]="relation.question.questionContent.questionText">
                    </div>
                </div>

            </div>

            <div class="col-4 flex p-0 justify-content-end align-items-center">

                <div class="col flex align-items-center justify-content-end">
                    <div class="progress-container border-round-lg mr-3">
                        <div class="progress-bar border-round-md"
                            [style.width.%]="relation?.question.gptScore !== 0 ? relation?.question.gptScore : 2"
                            [style.background-color]="relation?.question.progressBarColor"></div>
                    </div>
                    <span class="flex font-bold text-gray-900 align-items-baseline" style="width: 45px;">
                        <span class="big">{{ relation.question.scoreIntPart }}</span>
                        <span class="text-sm">.{{ relation.question.scoreDecPart }}</span>
                    </span>
                </div>
                <div class="col-1 w-auto pr-2 flex">
                    <div *ngIf="relation.question.isAnswered">
                        <img src="/assets/vinter/icons/answered-square.svg" />
                    </div>
                    <div *ngIf="!relation.question.isAnswered">
                        <img src="/assets/vinter/icons/not-answered-square.svg" />
                    </div>

                </div>

            </div>

        </div>
    </ng-container>
</div>
<app-answer-detail-sidebar *ngIf="answerSidebarVisible" [applicationStepId]="applicationStepId"
    [selectedQuestion]="selectedQuestion" [questionCollectionCategoryId]="questionList.questionCollectionCategory"
    [sidebarVisible]="answerSidebarVisible" (getPreviousOrNextQuestion)="getPreviousOrNextQuestion($event)"
    (onHideAnswerSidebar)="onHideAnswerSidebar($event)"></app-answer-detail-sidebar>