import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationStart, ParamMap, Router } from '@angular/router';
import { CredentialsService } from '@app/core';
import { PositionStepKanbanStaticTypes } from '@app/shared/common/enums';
import { ApplicationStepService } from '@app/vinter/services/application-step.service';
import { ApplicationService } from '@app/vinter/services/application.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-applicant-detail',
  templateUrl: './applicant-detail.component.html',
  styleUrls: ['./applicant-detail.component.scss']
})
export class ApplicantDetailComponent implements OnInit {

  applicantId: string;
  positionId: string;
  applicationId: string;
  avgRating: number = 0;
  kanbanList: any;
  currentStepId: string;
  applicationStepId: string;
  applicationStepXmin: string;
  applicationXmin: string;
  applicationStepsDataPdf: any[]
  oceanScoreReport: any;
  generalApplicationForm: any;
  showBackgroundLayer: boolean = false;

  


  constructor(public route: ActivatedRoute,
    private applicationStepService: ApplicationStepService,
    private applicationService: ApplicationService,
    public router: Router) {
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(
      (params: ParamMap) => {
        this.applicationId = this.route.snapshot.queryParamMap.get('applicationId');
        this.currentStepId = this.route.snapshot.queryParamMap.get('currentPositionStep');
        this.applicationStepId = this.route.snapshot.queryParamMap.get('applicationStepId');
        this.getApplicationStep();
      }
    );

  }


  getApplicationStep() {
    const applicationObs = this.applicationService.GetDataByFilterWithApplicant({ id: this.applicationId });
    if(this.applicationStepId === "null" || this.applicationStepId === PositionStepKanbanStaticTypes.Offer || this.applicationStepId === PositionStepKanbanStaticTypes.ShortList){
        applicationObs.subscribe(respApplication => {
            this.applicantId = respApplication.applicantId;
            this.positionId = respApplication.positionId;
            this.applicationXmin = respApplication.xmin;

            this.kanbanList = {
                applicantId: this.applicantId,
                positionId: this.positionId,
                currentStepId: this.currentStepId,
                applicationStepId: this.applicationStepId,
                applicationStepXmin: this.applicationStepXmin,
                applicationXmin: this.applicationXmin
              };
        });
    }
    else{
        const applicationStepObs = this.applicationStepService.GetDataByFilter({ id: this.applicationStepId, applicationId: this.applicationId });
        forkJoin([applicationObs, applicationStepObs]).subscribe(
          ([respApplication, respApplicationStep]) => {
            this.applicantId = respApplication.applicantId;
            this.positionId = respApplication.positionId;
            this.applicationXmin = respApplication.xmin;
            this.applicationStepXmin = respApplicationStep.xmin;

            this.kanbanList = {
              applicantId: this.applicantId,
              positionId: this.positionId,
              currentStepId: this.currentStepId,
              applicationStepId: this.applicationStepId,
              applicationStepXmin: this.applicationStepXmin,
              applicationXmin: this.applicationXmin
            };
          }
        );
    }
  }

  getUpdatedPositionStepId(event: any){
    this.router.navigate(['/applicant/detail'], {
        queryParams: {
            applicationId: this.applicationId,
            currentPositionStep: event.currentStepId,
            applicationStepId: event.applicationStepId
        }
    }).then(() => {
        window.location.reload();
      });
  }

  setAvgRating(rating: number) {
    this.avgRating = rating;
  }

  getApplicationStepsData(event: any[]){
    this.applicationStepsDataPdf = event;
  }

  getOceanScore(event: any){
    this.oceanScoreReport = event;
  }

  getGeneralApplicationCvInfo(event: any){
    this.generalApplicationForm = event;
  }
}
