import { HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpInterceptor } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, from, Observable, of, throwError } from "rxjs";
import { catchError, filter, switchMap, take } from "rxjs/operators";
import { AuthenticationService } from "../authentication/authentication.service";
import { CredentialsService } from "../authentication/credentials.service";
import { LocalizationService } from "../localization.service";

/// Configuration is created based on:
/// Mostly Backend:  https://code-maze.com/using-refresh-tokens-in-asp-net-core-authentication
/// Mostly Frontend: https://medium.com/volosoft/refresh-token-implamentation-1875f92a90cc
@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        private credentialsService: CredentialsService,
        private injector: Injector,
        private router: Router,
        private localizationService: LocalizationService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const configUrl = 'assets/';
        let modifiedRequest;
 
        if(!request.url.includes(configUrl)){
            modifiedRequest = this.addTokenToRequest(request, false);
        }
        else{
            modifiedRequest = request;
        }
 
        return next.handle(modifiedRequest)
            .pipe(
                catchError(error => {
                    if (error instanceof HttpErrorResponse && error.status === 401 && error.headers.get('token-expired') == "true") {
                        return this.tryAuthWithRefreshToken(request, next, error);
                    } else {
                        return this.handleErrorResponse(error);
                    }
                })
            );
    }

    private handle401Error() {
        this.isRefreshing = false;
        this.credentialsService.setCredentials();
        this.router.navigate(["login"]);
    }

    private tryAuthWithRefreshToken(request: HttpRequest<any>, next: HttpHandler, error: any) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            return this.tryGetRefreshTokenService().pipe(
                switchMap((authResult: boolean) => {
                    this.isRefreshing = false;
                    if (authResult) {
                        this.refreshTokenSubject.next(authResult);
                        let modifiedRequest = this.addTokenToRequest(request, false);
                        return next.handle(modifiedRequest);
                    } else {
                        return this.handleErrorResponse(error);
                    }
                }));
        } else {
            return this.refreshTokenSubject.pipe(
                filter(authResult => authResult != null),
                take(1),
                switchMap(authResult => {
                    let modifiedRequest = this.addTokenToRequest(request, false);
                    return next.handle(modifiedRequest);
                }));
        }
    }

    private tryGetRefreshTokenService(): Observable<boolean> {
        let _refreshTokenService = this.injector.get(AuthenticationService, null);

        if (_refreshTokenService) {
            return from(_refreshTokenService.refreshToken(true).then(() => { return true }));
        }
        return of(false);
    }

    private addTokenToRequest(request: HttpRequest<any>, refreshToken: boolean): HttpRequest<any> {
        if (!refreshToken) {
            // add authorization header with jwt token if available
            let currentUser = this.credentialsService.credentials;
            if(!request.headers['Authorization']){
                if (currentUser && currentUser.token) {
                    request = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${currentUser.token}`,
                            'Cache-Control': 'no-cache, no-store, must-revalidate',
                            'Pragma': 'no-cache',
                            'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
                        }
                    });
                }else{
                    request = request.clone({
                        setHeaders: {
                            'Cache-Control': 'no-cache, no-store, must-revalidate',
                            'Pragma': 'no-cache',
                            'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
                        }
                    });
                }
            }else{
                return request;
            }

        }

        return request;
    }

    private handleErrorResponse(error: any): Observable<never> {
        if (error instanceof HttpErrorResponse) {
            let isResourceKey: string;
            if (error.error != null) {
                isResourceKey = error.error.isResourceKey == undefined ? error.error.IsResourceKey : error.error.isResourceKey;
            }
            switch (error.status) {
                case 0: { return throwError(() => this.localizationService.translateText("ConnectionError")); } // !!! localization eklenmeli
                case 400: {
                    if (isResourceKey) {
                        return throwError(() => this.localizationService.translateText(error.error.message)); // !!! localization eklenmeli
                    }
                    else {
                        if (error.error.errors != undefined) { return throwError(() => error.error.errors.status); } // ? // !!! localization eklenmeli
                        else if (error.error == "803") {
                            this.handle401Error();
                            return throwError(() => this.localizationService.translateText(error.error)); // !!! localization eklenmeli
                        } // unauth error when refresh token is not valid
                        else { return throwError(() => error.error.message); } // Custom Ldap Error
                    }
                }
                case 401: {
                    const applicationError = error.headers.get('Application-Error');

                    if (applicationError) {
                        return throwError(() => isResourceKey ? this.localizationService.translateText(applicationError) : applicationError); // !!! localization eklenmeli
                    }

                    return throwError(() => error.statusText);
                }
                case 404: { return throwError(() => error.message); } // cannot find api controller
                case 547: { // foreign key error
                    let errorMessage = error.headers.get('Application-Error');
                    let startIndex = errorMessage.indexOf('"FK_') + 1;
                    let endIndex = errorMessage.indexOf('"', startIndex);
                    if (startIndex > 0) errorMessage = errorMessage.substring(startIndex, endIndex);
                    return throwError(() => this.localizationService.translateText("recordMayBeInUse"));
                }
                default: {
                    const applicationError = error.headers.get('Application-Error');
                    if (applicationError) {
                        return throwError(() => isResourceKey ? this.localizationService.translateText(applicationError) : applicationError);
                    }
                    const serverError = error.error;
                    let modalStateErrors = '';
                    if (serverError.errors && typeof serverError.errors === 'object') {
                        for (const key in serverError.errors) {
                            if (serverError.errors[key]) {
                                modalStateErrors += serverError.errors[key] + '\n';
                            }
                        }
                    }
                    return throwError(() => modalStateErrors || serverError || 'Server Error');
                }
            }
        }
    }
}
