import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { InviteApplicantViaEmailComponent } from './invite-applicant-via-email/invite-applicant-via-email.component';
import { InviteApplicantViaFileComponent } from './invite-applicant-via-file/invite-applicant-via-file.component';
import { Router } from '@angular/router';
import { InviteApplicantViaIntegrationComponent } from './invite-applicant-via-integration/invite-applicant-via-integration.component';
import { IntegrationPositionService } from '@app/vinter/services/integration-position.service';
import { OrganizationIntegrationItem } from '@app/vinter/models/integration/organization-integration-item';
import { PositionStepKanbanStaticTypes } from '@app/shared/common/enums';
import { IntegrationAndPositionInfo } from '@app/vinter/models/integration/integration-and-position-info';
import { IntegrationApplicantService } from '@app/vinter/services/integration-applicant.service';
import { IntegrationApplicantListItem } from '@app/vinter/models/integration/Integration-applicant-list-item';

@Component({
    selector: 'app-invite-applicant',
    templateUrl: './invite-applicant.component.html',
    styleUrls: ['./invite-applicant.component.scss'],
})
export class InviteApplicantComponent {
    visible: boolean;
    items: MenuItem[] | undefined;
    activeTabIndex: number = 0;
    activeItem: MenuItem | undefined;
    sendInvitationData: boolean = false;
    sendMailInvitationData: boolean = false;
    sendIntegrationInvitationData: boolean = false;
    invitedDialogVisible: boolean = false;
    isCopied: boolean;
    positionIntegrationList : OrganizationIntegrationItem[] = [];
    PositionStepKanbanStaticTypes = PositionStepKanbanStaticTypes;
    
    integrationApplicantList : IntegrationApplicantListItem[] = [];
    integrationApplicantListCopy : IntegrationApplicantListItem[] = [];
    integrationApplicantListSearchCopy : IntegrationApplicantListItem[] = [];
    fetchedApplicantData : boolean = false;

    @Input() positionId: string;
    @Input() organizationId: string;
    @Input() generalApplicationLink: string;
    @Input() inviteType: string;
    @ViewChild(InviteApplicantViaEmailComponent) inviteApplicantEmailChild: InviteApplicantViaEmailComponent;
    @ViewChild(InviteApplicantViaFileComponent) inviteApplicantViaFileChild: InviteApplicantViaFileComponent;
    @ViewChild(InviteApplicantViaIntegrationComponent) inviteApplicantViaIntegrationChild: InviteApplicantViaIntegrationComponent;

    constructor(
        private router: Router,
        private integrationPositionService: IntegrationPositionService,
        private integrationApplicantService: IntegrationApplicantService,
        private cdr: ChangeDetectorRef

    ) { }

    ngOnInit(): void {
        if(this.positionId){
           this.getPositionIntegrationList();
        }
        this.setItems();
        this.activeItem = this.items[0];
    }

    getPositionIntegrationList(){
        this.integrationPositionService.getPositionIntegrationList({positionID:this.positionId}).subscribe(resp => {
            this.positionIntegrationList = resp;
        });
    }

    getApplicantListOfChoosedIntegrations(isRefresh : boolean){
        let choosedOrgIntegrationIdList : string[] = [];
        if(isRefresh){
            this.positionIntegrationList.filter(x=> x.isChoosed).forEach(x => x.hasRequested = false)
        }
        var choosedOrgIntegration = this.positionIntegrationList.filter(x => !x.hasRequested && x.isChoosed);
        choosedOrgIntegrationIdList = choosedOrgIntegration.map(x => x.id);
    
        var filter = new IntegrationAndPositionInfo();
        filter.positionID = this.positionId;
        filter.choosedOrganizationIntegrationIdList = choosedOrgIntegrationIdList;
    
        this.integrationApplicantService.getIntegrationApplicantsAsync(filter).subscribe({
            next: (resp) => {
                resp.forEach(x => {
                    x.isChoosed = false;
                    x.mailItem = { mail: x.applicantEmail, dataValidation: true, errorMessage: "", isMailHasError: false ,organizationIntegrationID: x.organizationIntegrationID};
                });
                if(isRefresh){
                  this.integrationApplicantListCopy = JSON.parse(JSON.stringify(this.integrationApplicantListCopy.filter(x=> !choosedOrgIntegrationIdList.includes(x.organizationIntegrationID)).concat(resp)));
                  this.integrationApplicantList = this.integrationApplicantList.filter(x=> !choosedOrgIntegrationIdList.includes(x.organizationIntegrationID)).concat(resp); 
                  this.integrationApplicantListSearchCopy = this.integrationApplicantListSearchCopy.filter(x=> !choosedOrgIntegrationIdList.includes(x.organizationIntegrationID)).concat(resp); 
                  var values = [];
                  this.receiveIntegrationInvitationData(values);
                } 
                else {
                    this.integrationApplicantList = this.integrationApplicantList.concat(resp);
                    this.integrationApplicantListSearchCopy = this.integrationApplicantListSearchCopy.concat(resp);
                    this.integrationApplicantListCopy = JSON.parse(JSON.stringify(this.integrationApplicantListCopy.concat(resp)));  
                }
                choosedOrgIntegration.forEach(x => x.hasRequested = true);
                this.fetchedApplicantData = true;
                this.inviteApplicantViaIntegrationChild.sortApplicantList(this.integrationApplicantList);
            },
            error: (err) => {
                this.inviteApplicantViaIntegrationChild.showMailErrorMessage(err)
            }
        });
    }

    receiveData(data) {
        this.sendInvitationData = data;
    }

    receiveMailData(data) {
        if (data.length > 0) {
            this.sendMailInvitationData = true;
        }
        else {
            this.sendMailInvitationData = false;
        }
    }

    receiveIntegrationInvitationData(data) {
        var isAnyError = data.some(x => x.isMailHasError);
        if (data.length > 0 && !isAnyError) {
            this.sendIntegrationInvitationData = true;
        }
        else if(data.length == 0 || isAnyError) {
            this.sendIntegrationInvitationData = false;
        }
    }

    setItems() {
        this.items = [{ label: 'via Email Adress' }, { label: 'via File' }];
    }

    closeDialog() {
        this.visible = false;
        this.inviteApplicantEmailChild.invitationList = [];
    }

    toogleInviteDialog() {
        this.visible = false;
        this.invitedDialogVisible = !this.invitedDialogVisible;
    }

    close() {
        if (this.inviteApplicantEmailChild ?? false) {
            this.inviteApplicantEmailChild.values = [];
            this.inviteApplicantEmailChild.invitationList = [];
        }
        if (this.inviteApplicantViaIntegrationChild ?? false) {
            this.inviteApplicantViaIntegrationChild.values = [];
            this.inviteApplicantViaIntegrationChild.invitationList = [];
        }
        if (this.inviteApplicantViaFileChild ?? false) {
            this.inviteApplicantViaFileChild.isFileSelected = false;
            this.inviteApplicantViaFileChild.excelResultFromDb = [];
            this.inviteApplicantViaFileChild.uploadedFiles = [];
            this.inviteApplicantViaFileChild.isDisabled = true;
        }
        this.activeTabIndex = 0;
        this.sendInvitationData = false;

    }

    sendInvite(index) {
        if (index === 0) {
            this.inviteApplicantEmailChild.sendInvitation(this.inviteType);
        }
        else if(index === 1) {
            this.inviteApplicantViaFileChild.submit(this.inviteType);
        }
        else if(index === 2){
            this.inviteApplicantViaIntegrationChild.sendInvitation(this.inviteType);
        }
    }

    goAllPositions() {
        this.router.navigate(["position/list"]);
        this.invitedDialogVisible = false;
    }

    copyText() {
        this.isCopied = true;
        const textArea = document.createElement('textarea');
        textArea.value = this.generalApplicationLink;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);

        setTimeout(() => {
            this.isCopied = false;
        }, 2500);
    }
}
