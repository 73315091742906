<p-dialog [blockScroll]="true" showEffect="fade" [draggable]="false" [resizable]="false" [closable]="false"
    [visible]="dialogVisiblity" [modal]="true" [style]="{ width: '70vw' }">

    <div class="flex justify-content-center align-items-center flex-column">
        <div class=" flex flex-row">
            <span class="text-center font-bold text-2xl line-height-4 text-blue-500"> {{ "Key.WelcomeVinter" |
                translate}}</span>
            <div class="flex justify-content-center align-items-center ">
                <span class="text-center font-bold text-2xl line-height-4 text-gray-800 ml-1">{{
                    "Key.YouMustDoTextHeader" |translate}}</span>
            </div>
        </div>
        <div class=" flex flex-row">

            <div class="flex justify-content-center align-items-center">
                <span class="text-center  text-lg line-height-4 text-gray-500">{{ "Key.YouMustDoTextDetail"
                    |translate}}</span>
            </div>
        </div>
    </div>

    <div *ngIf="showAllPlans"
        class="align-items-center pt-1 justify-content-center flex gap-3 Statikposition h-4rem	 sticky top-0 pr-1  justify-content-center flex align-items-center ">
        <div class="text-center font-medium text-xl	" [ngClass]="!isAnnual ? 'switch-text-color' : 'text-gray-700'">
            {{ "Key.Monthly" | translate}}
        </div>
        <div>
            <p-inputSwitch [(ngModel)]="isAnnual" (ngModelChange)="isAnnualChanged()"></p-inputSwitch>
        </div>
        <div class="text-center font-medium text-xl	" [ngClass]="isAnnual ? 'switch-text-color' : 'text-gray-700 '">
            {{ "Key.Annual" | translate}}
        </div>
    </div>
    <div class="flex-wrap flex-column card-container grid mt-2 flex justify-content-center">
        <ng-template *ngIf="showAllPlans" ngFor let-item [ngForOf]="organizationPlans?.licensePlans" let-i="index">
            <div class="w-11 flex flex-row justify-content-between border-round-2xl plan-card-border border-1 mx-7 my-2 pt-3 pb-3 px-4 cursor-pointer"
                *ngIf="item.licensePlanType === licencePlanTypeEnum.StandartPlan" [ngClass]="{'plan-card-current-border' : organizationPlans.licensePlanCode === item.licenseCode,
                     'suggestedPlan': selectedLicensePlan === item.licenseCode,'opacity-30' : item.compareValue}"
                (click)="selectPlan(item.licenseCode)">
                <div class="flex-row flex align-items-center w-2 ">
                    <div class="flex align-items-center mr-3">
                        <p-radioButton name="licensePlan" [(ngModel)]="selectedLicensePlan" [value]="item.licenseCode">
                        </p-radioButton>
                    </div>
                    <div class="flex card-header align-items-start flex-column w-12">
                        <span class="font-bold text-lg title-content text-blue-500" [ngClass]="{
                                'text-green-500': selectedLicensePlan === item.licenseCode
                            }">
                            {{ item.name }}
                        </span>
                        <span class="text-gray-500 ">{{ "Key.Package" | translate}}</span>
                    </div>
                </div>

                <div *ngIf="item.licenseCode !== licensePlanCodeEnum.Enterprise && item.licenseCode !== licensePlanCodeEnum.EnterpriseAnnual "
                    class=" w-3 justify-content-start flex-column flex">
                    <p class="plan-detail align-items-center p-2 pb-1 flex">
                        <img src="assets/vinter/images/tik-rounded-icon.svg" alt="vinter" class="mr-2 block tik-icon" />
                        <span
                            class="plan-detail-text font-medium text-sm text-center">{{'Key.'+item.descriptionTranslations[0].key|
                            translate: {applicantCount: item.applicantCount} }}</span>
                    </p>
                    <p *ngFor="let translation of item.descriptionTranslations.slice(1); let i = index"
                        class="plan-detail align-items-center p-2 pb-1 flex">
                        <img src="assets/vinter/images/tik-rounded-icon.svg" alt="vinter" class="mr-2 block tik-icon" />
                        <span class="plan-detail-text font-medium text-sm text-center">{{'Key.' + translation.key |
                            translate}}</span>
                    </p>
                </div>
                <div class="w-3 flex align-items-center "
                    *ngIf="item.licenseCode === licensePlanCodeEnum.Enterprise || item.licenseCode === licensePlanCodeEnum.EnterpriseAnnual ">

                    <div class=" Enterprise-custom-height">
                        <p class=" flex ">
                            <span class="callMe-text text-sm">{{'Key.'+item.enterpriseDescriptionTranslation.key|
                                translate
                                }}</span>
                        </p>
                    </div>

                </div>
                <div class="w-3 flex align-items-end flex-column justify-content-end ">

                    <div class="flex-column flex align-items-start pt-2 pb-0 w-full ">
                        <p *ngIf="item.licenseCode !== licensePlanCodeEnum.Enterprise && item.licenseCode !== licensePlanCodeEnum.EnterpriseAnnual"
                            class="mb-0 licence-detail gray-800 font-bold text-3xl">
                            € {{ item.price }}
                        </p>
                        <p *ngIf="!isAnnual && item.licenseCode !== licensePlanCodeEnum.Enterprise && item.licenseCode !== licensePlanCodeEnum.EnterpriseAnnual"
                            class="pt-2 text-base text-blue-300">
                            / {{ "Key.Monthly" | translate}}
                        </p>
                        <p *ngIf="isAnnual && item.licenseCode !== licensePlanCodeEnum.Enterprise && item.licenseCode !== licensePlanCodeEnum.EnterpriseAnnual"
                            class="pt-2 text-base text-blue-300">
                            / {{ "Key.Annual" | translate}}
                        </p>
                        <p *ngIf="item.licenseCode === licensePlanCodeEnum.Enterprise || item.licenseCode === licensePlanCodeEnum.EnterpriseAnnual"
                            class="plan-detail flex p-3 ">
                            <span
                                class="needHelp-text font-semibold blue-500">{{'Key.'+item.enterprisePriceTranslation.key
                                |
                                translate }}</span>
                        </p>
                    </div>
                    <div class=" w-full flex justify-content-center">
                        <div class="w-full pb-3">
                            <p-button
                                *ngIf="!item.compareValue && item.licenseCode !== licensePlanCodeEnum.Enterprise && item.licenseCode !== licensePlanCodeEnum.EnterpriseAnnual && organizationPlans.licensePlanCode !== item.licenseCode"
                                label="{{ 'Key.Buy' | translate }}" icon="pi pi-arrow-up-right" iconPos="right"
                                (onClick)="redirectToStripeCheckoutSessionUpgradePlan(item.licensePlanId)"></p-button>
                            <p-button
                                *ngIf="organizationPlans.licensePlanCode !== item.licenseCode && (item.licenseCode === licensePlanCodeEnum.Enterprise || item.licenseCode === licensePlanCodeEnum.EnterpriseAnnual)"
                                label="{{ 'Key.ContactUs' | translate }}" styleClass="p-button-outlined mt-1"
                                (click)="redirectToVinterMe()"></p-button>
                        </div>
                    </div>
                </div>

            </div>
        </ng-template>

        <div class="flex w-11 flex-row justify-content-between border-round-2xl plan-card-border border-1 mx-7 my-2 pt-3 pb-3 px-4"
            *ngIf="webSiteSelectedPlan && !showAllPlans && webSiteSelectedPlan != null && webSiteSelectedPlan.licensePlanType === licencePlanTypeEnum.StandartPlan"
            [ngClass]="{
                'plan-card-current-border': organizationPlans.licensePlanCode === webSiteSelectedPlan.licenseCode,
                'suggestedPlan': true,
                'opacity-30': webSiteSelectedPlan.compareValue
            }">
            <div class=" w-2 flex-row flex align-items-center">
                <div class="flex align-items-center mr-3">
                    <p-radioButton name="licensePlan" [(ngModel)]="selectedLicensePlan"
                        [value]="webSiteSelectedPlan.licenseCode">
                    </p-radioButton>
                </div>
                <div class="flex card-header align-items-start flex-column">
                    <span
                        class="font-semibold text-lg title-content text-green-500">
                        {{ webSiteSelectedPlan.name }}
                    </span>
                    <span class="text-gray-500 ">{{ "Key.Package" | translate}}</span>
                </div>
            </div>

            <div *ngIf="webSiteSelectedPlan.licenseCode !== licensePlanCodeEnum.Enterprise && webSiteSelectedPlan.licenseCode !== licensePlanCodeEnum.EnterpriseAnnual"
                class=" w-3 justify-content-start flex-column flex">
                <p class="plan-detail align-items-center p-2 pb-1 flex">
                    <img src="assets/vinter/images/tik-rounded-icon.svg" alt="vinter" class="mr-2 block tik-icon" />
                    <span class="plan-detail-text font-medium text-sm text-center">
                        {{ 'Key.' + webSiteSelectedPlan.descriptionTranslations[0].key | translate: {applicantCount:
                        webSiteSelectedPlan.applicantCount} }}
                    </span>
                </p>
                <p *ngFor="let translation of webSiteSelectedPlan.descriptionTranslations.slice(1)"
                    class="plan-detail align-items-center p-2 pb-1 flex">
                    <img src="assets/vinter/images/tik-rounded-icon.svg" alt="vinter" class="mr-2 block tik-icon" />
                    <span class="plan-detail-text font-medium text-sm text-center">
                        {{ 'Key.' + translation.key | translate }}
                    </span>
                </p>
            </div>

            <div class="w-3 flex align-items-center"
                *ngIf="webSiteSelectedPlan.licenseCode === licensePlanCodeEnum.Enterprise || webSiteSelectedPlan.licenseCode === licensePlanCodeEnum.EnterpriseAnnual">
                <div>
                    <p class="flex">
                        <span class="callMe-text text-sm">
                            {{ 'Key.' + webSiteSelectedPlan.enterpriseDescriptionTranslation.key | translate }}
                        </span>
                    </p>
                </div>
            </div>

            <div class=" w-3 flex align-items-end flex-column justify-content-end">
                <div class="flex-column flex align-items-start pt-2 pb-0 w-full">
                    <p *ngIf="webSiteSelectedPlan.licenseCode !== licensePlanCodeEnum.Enterprise && webSiteSelectedPlan.licenseCode !== licensePlanCodeEnum.EnterpriseAnnual"
                        class="mb-0 licence-detail gray-800 font-bold text-3xl">
                        € {{ webSiteSelectedPlan.price }}
                    </p>
                    <p *ngIf="webSiteSelectedPlan.paymentPeriod == 1 && webSiteSelectedPlan.licenseCode !== licensePlanCodeEnum.Enterprise && webSiteSelectedPlan.licenseCode !== licensePlanCodeEnum.EnterpriseAnnual"
                        class="pt-2 text-base text-blue-300">
                        / {{ "Key.Monthly" | translate }}
                    </p>
                    <p *ngIf="webSiteSelectedPlan.paymentPeriod == 2 && webSiteSelectedPlan.licenseCode !== licensePlanCodeEnum.Enterprise && webSiteSelectedPlan.licenseCode !== licensePlanCodeEnum.EnterpriseAnnual"
                        class="pt-2 text-base text-blue-300">
                        / {{ "Key.Annual" | translate }}
                    </p>
                    <p *ngIf="webSiteSelectedPlan.licenseCode === licensePlanCodeEnum.Enterprise || webSiteSelectedPlan.licenseCode === licensePlanCodeEnum.EnterpriseAnnual"
                        class="plan-detail flex p-3">
                        <span class="needHelp-text font-semibold blue-500">
                            {{ 'Key.' + webSiteSelectedPlan.enterprisePriceTranslation.key | translate }}
                        </span>
                    </p>
                </div>
                <div class="w-full flex justify-content-center">
                    <div class="w-full pb-3">
                        <p-button
                            *ngIf="!webSiteSelectedPlan.compareValue && webSiteSelectedPlan.licenseCode !== licensePlanCodeEnum.Enterprise && webSiteSelectedPlan.licenseCode !== licensePlanCodeEnum.EnterpriseAnnual && organizationPlans.licensePlanCode !== webSiteSelectedPlan.licenseCode"
                            label="{{ 'Key.Buy' | translate }}" icon="pi pi-arrow-up-right" iconPos="right"
                            (onClick)="redirectToStripeCheckoutSessionUpgradePlan(webSiteSelectedPlan.licensePlanId)"></p-button>
                            <p-button
                            *ngIf="organizationPlans.licensePlanCode !== webSiteSelectedPlan.licenseCode && (webSiteSelectedPlan.licenseCode === licensePlanCodeEnum.Enterprise || webSiteSelectedPlan.licenseCode === licensePlanCodeEnum.EnterpriseAnnual)"
                            label="{{ 'Key.ContactUs' | translate }}" styleClass="p-button-outlined mt-1"
                            (click)="redirectToVinterMe()"></p-button>
                    </div>
                </div>
            </div>
        </div>
        
        <div *ngIf="webSiteSelectedPlan != null" class="w-12 align-items-center flex justify-content-center mt-4 cursor-pointer">
            <span (click)="toggleShowAllPlans()" class="text-blue-500 font-medium line-height-3  text-lg">
                {{ showAllPlans ? ('Key.SeeLess' | translate) : ('Key.SeeMore' | translate) }}
            </span>
        </div>

    </div>
</p-dialog>