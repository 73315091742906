import { EntityStateEnum } from "@app/shared/common/enums";

export class RatingFormItem {
  id: string;
  ratingScore : number;
  comment: string;
  type: string;
  contentURL: string;
  answerId: string;
  applicationId: string;
  applicationStepId: string;
  ratingType: number;
  entityState: EntityStateEnum;
  xmin:number;
}