<div class="mt-0 pt-0" [ngClass]="">
    <div class="flex flex-column m-3 mt-2">
        <ng-container>
            <div *ngFor="let question of generalApplicationForm.questions; let i = index"
                class="question-container col-12 flex pb-0" [ngClass]="{ 'active': selectedQuestionId == question.id }">
                <div class="grid flex col-8 pb-0 h-4rem">
                    <div class="question-number question-number-color col-1 mr-0 mb-2 pr-0 flex ml-2 align-items-center flex-nowrap"
                        style="width: 20px;">
                        {{ question.order }}
                    </div>
                    <div class="col-1 pt-2 order-icon-width h-5rem block flex flex-nowrap align-items-center">
                        <div class="grid">
                            <div class="col-12 p-0 flex flex-nowrap">
                                <div *ngIf="question.type === QuestionExpectedAnswerType.Video">
                                    <img class="custom-svg-question-icon align-self-center"
                                        src="/assets/vinter/icons/videoQuestion.svg" />
                                </div>
                                <div *ngIf="question.type === QuestionExpectedAnswerType.OpenEnded">
                                    <img class="custom-svg-question-icon align-self-center"
                                        src="/assets/vinter/icons/openEndedQuestion.svg" />

                                </div>
                                <div *ngIf="question.type === QuestionExpectedAnswerType.MultipleChoice">
                                    <img class="custom-svg-question-icon align-self-center"
                                        src="/assets/vinter/icons/Quiz.svg" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="question-container-text col pr-5 pb-0 h-3rem flex flex-nowrap align-items-center"
                        (click)="getAnswerByQuestionId(i)">
                        <p class="question-content truncate-text ml-0" [innerHTML]="question.questionText">
                        </p>
                    </div>

                </div>

                <div class="col-4 flex pb-0 justify-content-end align-items-center" style="width: 36%;">
                    <div class="col-1 flex pt-0 mb-2">

                        <div *ngIf="question.isAnswered">
                            <i class="pi pi-circle-fill" style="color: #3CBC00"></i>

                        </div>
                        <div *ngIf="!question.isAnswered">
                            <i class="pi pi-circle-fill" style="color: #E6E7E8"></i>
                        </div>

                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<app-general-application-answer-sidebar *ngIf="sidebarVisible" [generalApplicationForm]="generalApplicationForm"
    (hideSidebar)="onHideSidebar($event)"
    [selectedQuestionId]="selectedQuestionId"></app-general-application-answer-sidebar>