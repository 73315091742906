import { Component, Input } from '@angular/core';
import { QuestionExpectedAnswerType } from '@app/shared/common/enums';
import { GeneralApplicationFormService } from '@app/vinter/services/general-application-form.service';

@Component({
  selector: 'app-general-application-form-result',
  templateUrl: './general-application-form-result.component.html',
  styleUrls: ['./general-application-form-result.component.scss']
})
export class GeneralApplicationFormResultComponent {
  @Input() applicationId: string;
  generalApplicationForm: any = [];
  selectedQuestionId: any;
  sidebarVisible: boolean = false;
  QuestionExpectedAnswerType = QuestionExpectedAnswerType;

  

  constructor(
    private generalApplicationFormService: GeneralApplicationFormService,
  
  ) { }

  ngOnInit(): void {
    this.getFormWithQuestionsAndAnswers(this.applicationId);
  }

  getFormWithQuestionsAndAnswers(applicationId){
    this.generalApplicationFormService.getApplicationFormWithQuestionAndAnswers(applicationId).subscribe(data => {
      this.generalApplicationForm = data;
    });
  }

  getAnsweredQuestion(id){
    return this.generalApplicationForm.answers.find(x => x.questionId === id).isAnswered;
  }

  getAnswerByQuestionId(index){
    this.selectedQuestionId = this.generalApplicationForm.questions.find(x => x.order === (index+1)).id;
    this.sidebarVisible = true;
  }

  onHideSidebar(event){
    this.sidebarVisible = false;
  }

}
