<div class = "grid">
    <div class = "flex flex-column col-12 md:col-8 lg:col-6 box-width">
        <div class="flex flex-nowrap justify-content-between align-items-center bg-color h-4rem border-round-lg">
            <div class="flex justify-content-between">
                <span class="text-gray-800 text-lg font-semibold ml-4">{{'Key.WorkExperience' | translate}}</span>
            </div>
        </div>
        
        <div *ngIf="!dataLoaded" class="pb-3 mt-3">
            <p-skeleton class="w-full" height="30rem"></p-skeleton> 
        </div>
        
        <div *ngIf="dataLoaded" class="mt-3 overflow-x-hidden px-4 border-1 border-solid border-gray-50 border-round-lg py-2" [ngClass]="{'h-30rem':experienceList.length == 0}">
            <p-timeline *ngIf="experienceList.length > 0" [value]="experienceList">
                <ng-template pTemplate="content" let-experience>
                    <div class="w-full -mt-3">
                        <div class="experience-content mb-4 p-2 flex gap-2 flex-column">
                            <div class="flex flex justify-content-between">
                                <div class="flex gap-2 align-items-center w-10 flex-wrap">
                                    <span class="text-gray-900 font-bold text-xl">{{experience.company}}</span>
                                    <div class="flex align-items-center overflow-x-hidden">
                                        <span *ngIf="experience?.position" class="position-badge bg-blue-50 text-blue-500 white-space-nowrap text-overflow-ellipsis
                                            font-semibold text-xs h-2rem overflow-hidden p-2 uppercase">{{experience.position}}</span>
                                    </div>                    
                                    <div class="flex align-items-center" *ngIf="experience.type == EUserWorkExperienceType.Internship">
                                        <span class="position-badge bg-purple-50 text-purple-500 font-semibold text-xs h-2rem p-2">{{'Key.Intern' | translate}}</span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="experience.formattedStartDate" class="flex gap-1 font-medium text-sm align-items-center flex-wrap">
                                <div class="flex gap-1 text-gray-800">
                                    <span>{{experience.formattedStartDate}} -</span>
                                    <span *ngIf="experience.formattedEndDate">{{experience.formattedEndDate}}</span>   
                                    <span *ngIf="!experience.formattedEndDate">{{'Key.Currently' | translate}}</span>
                                </div>
                                <span class="text-blue-600" *ngIf="experience.startDate && (experience.startDate | dateDifference:experience.endDate) !== ''">( <span>{{ experience.startDate | dateDifference:experience.endDate }}</span> )</span>
                            </div>
                            <div class="text-gray-600 text-sm font-italic job-definition" [innerHTML]="experience.jobDefinition"></div>
                        </div>
                    </div>
                </ng-template>
            </p-timeline>
        
            <div *ngIf="experienceList.length == 0" class="flex w-full justify-content-center align-items-center h-full">
                <div class="flex flex-column align-items-center justify-content-center gap-2 w-6 mb-8">
                    <img src="../../../../../../../../assets/vinter/gifs/cannot-found-past-position.gif" class="copy-icon" />
                    <span class="text-gray-900 text-lg font-semibold text-center">{{'Key.NoExperienceTitle' | translate}}</span>
                </div> 
            </div>
        </div>
    </div>
</div>