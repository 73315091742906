import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ELicensePlanType, ESuggestedPackageType, LicensePlanCodeEnum, LicensePlanEnum, LicensePlanPriceType, PaymentPeriodEnum } from '@app/shared/common/enums';
import { OrganizationPlan } from '@app/vinter/models/license-plan-list-item';
import { LicensePlanService } from '@app/vinter/services/license-plan.service';
import { MembershipService } from '@app/vinter/services/membership.service';

@Component({
  selector: 'app-license-plan-paywall',
  templateUrl: './license-plan-paywall.component.html',
  styleUrls: ['./license-plan-paywall.component.scss']
})
export class LicensePlanPaywallComponent implements OnInit {
  isAnnual: boolean = false;
  organizationPlans: OrganizationPlan;
  organizationPlansBase: OrganizationPlan;
  inputLicensePlans: any;
  licensePlanCodeEnum = LicensePlanCodeEnum;
  licencePlanTypeEnum = ELicensePlanType;
  selectedLicensePlan: any;
  webSiteSelectedPlan:any = null;
  showAllPlans: boolean = false; 
  dialogVisiblity: boolean;

  @Input() webLicensePlanID: string = null;


  constructor(
    private licenseService: LicensePlanService, 
    private membershipService: MembershipService,

  ) { }

  ngOnInit(): void {
    this.getOrganizationPlan();
  }
  isAnnualChanged() {
    this.switchChange();
  }

  switchChange() {
    if (!this.isAnnual) {
      this.organizationPlans.licensePlans = this.organizationPlansBase.licensePlans.filter(x => x.paymentPeriod !== LicensePlanPriceType.Yearly);
      this.inputLicensePlans = this.organizationPlans.licensePlans
    }

    if (this.isAnnual) {
      this.organizationPlans.licensePlans = this.organizationPlansBase.licensePlans.filter(x => x.paymentPeriod === LicensePlanPriceType.Yearly || x.paymentPeriod === LicensePlanPriceType.OneShot);
      this.inputLicensePlans = this.organizationPlans.licensePlans
    }
  }

  getOrganizationPlan() {
    this.licenseService.GetLicensePlanList().subscribe(plans => {
      this.organizationPlans = plans;
      this.organizationPlans.licensePlans.forEach(element => {
        element.compareValue = false
      });
      this.organizationPlansBase = JSON.parse(JSON.stringify(plans));
      const selectedLicensePlanId = this.webLicensePlanID;
      this.webSiteSelectedPlan = this.organizationPlans.licensePlans.find(
        plan => plan.licensePlanId === selectedLicensePlanId
      );
      if(this.webSiteSelectedPlan != null)
        {
          this.selectedLicensePlan = this.webSiteSelectedPlan.licenseCode;
          this.webSiteSelectedPlan.paymentPeriod == PaymentPeriodEnum.Annual ? this.isAnnual = true : this.isAnnual = false ;
        }
      else {this.showAllPlans = true};

      this.switchChange();
      this.dialogVisiblity = true
    });
  }
 
  isSuggestedPlan(licenseCode: string): boolean {
    if (this.webSiteSelectedPlan != null && !this.showAllPlans) {
      return (
        licenseCode === this.webSiteSelectedPlan.licenseCode
      );
    }
    return false;
  }
  toggleShowAllPlans()
  {
     if(this.webSiteSelectedPlan == null)
    {
      this.showAllPlans = true
      return
    }

    this.showAllPlans = !this.showAllPlans
    this.selectedLicensePlan = this.webSiteSelectedPlan?.licenseCode;

  }
  selectPlan(licenseCode: string): void {
    this.selectedLicensePlan = licenseCode;
}
  redirectToStripeCheckoutSessionUpgradePlan(licensePlanId){
    this.membershipService.requestMemberSessionUpgradePlan({licensePlanID:licensePlanId}).subscribe((session) => {
      this.membershipService.redirectToCheckout(session);
  });
  }
  redirectToVinterMe() {
    window.open('https://vinter.me/contact', '_blank');
    }

}
