<p-sidebar [(visible)]="sidebarVisible" (onHide)="hideSidebarEmit()" position="right" [showCloseIcon]="false">

    <div class="col-12 flex pl-0 pb-0">
        <div class="col-6 flex pl-0 pt-1">
            <p-button class="back-button" icon="pi pi-arrow-left" (onClick)="getAnswerByQuestionId(order - 1)"></p-button>
            <p-button class="next-button" icon="pi pi-arrow-right" (onClick)="getAnswerByQuestionId(order + 1)"></p-button>
        </div>
    </div>

    <ng-container *ngIf="questionType === QuestionExpectedAnswerType.OpenEnded">
        <div class="col-12 flex pt-0 pb-0 pl-0">
            <div class="col-6 pt-0 pl-0 pb-0 flex">
                <div class="col-2 pt-0 pr-0 pl-0 question-icon-width">
                    <img class="custom-svg-question-icon align-self-center"
                        src="/assets/vinter/icons/openEndedQuestion.svg" />
                </div>
                <div class="col-7 pt-0 pl-0">
                    <h5 style="margin-top: 0.6rem">
                        {{"Key.QuestionOrder" | translate: { value1: order } }}
                    </h5>

                </div>
            </div>
        </div>

        <div class="question-container-sideBar-text">
            <div class="flex question-text-label mb-0" style="display:block !important" [innerHTML]="questionText">
            </div>

        </div>

        <div class="answer-text">
            <ng-container>
                <div class="flex">
                    <img src="assets/vinter/icons/text-questioncoktan-secmeli-cevap-icon-arrow-back-up.svg">
                    <h5 class="text-justify answer-title text-lg ml-2 mb-2 m-3 h5-font">
                        {{'Key.Answer' | translate}}
                    </h5>
                </div>

                <div *ngIf="answerText ?? false" class="answer-container">
                    <div class="answer-text-label">
                        {{ answerText }}
                    </div>
                </div>

                <div class="ml-5 flex" *ngIf="questionAnswered == false">
                    <img src="assets/vinter/icons/not-answered.svg">
                    <p class="ml-2" style="color: #686E75;">{{"Key.QuestionNotAnswered" | translate}}</p>
                </div>
            </ng-container>
        </div>
    </ng-container>


    <ng-container *ngIf="questionType ===QuestionExpectedAnswerType.MultipleChoice">
        <div class="col-12 flex pt-0 pb-0 pl-0">
            <div class="col-6 flex pt-0 pb-0 pl-0">
                <div class="col-2 pt-0 pr-0 pl-0 question-icon-width">
                    <img class="custom-svg-question-icon align-self-center" src="/assets/vinter/icons/Quiz.svg" />
                </div>
                <div class="col-7 pt-0 pl-0">
                    <h5 style="margin-top: 0.6rem">
                        {{"Key.QuestionOrder" | translate: { value1: order } }}
                    </h5>

                </div>
            </div>
        </div>
        <div>
            <div class="question-container-sideBar-text flex">
                <div class="question-text-label" [innerHTML]="questionText">
                </div>
            </div>

            <div class="answer-text">
                <div class="flex">
                    <img src="assets/vinter/icons/text-questioncoktan-secmeli-cevap-icon-arrow-back-up.svg">
                    <h5 class="text-justify answer-title text-lg ml-2 m-3 h5-font">
                        {{"Key.Answer" | translate}}
                    </h5>
                </div>
                <div class="answer-container">
                    <div class="flex">
                        <div class="flex flex-column gap-2">
                            <div *ngFor="let choice of choices; let i = index"
                                        class="radio-button-card field-checkbox"
                                        [ngClass]="{
                                                    'background-white': choice.id !== selectedAnswer,
                                                    'background-blue': choice.id === selectedAnswer
                                                   }">
                                        <div class="flex align-items-center">
                                            <p-radioButton [disabled]="true" [value]="choice.id"
                                            [ngModel]="selectedAnswer" ></p-radioButton>
                                            <label [for]="choice.choiceText" 
                                            class="ml-2">{{ choice.choiceText }}</label>
                                        </div>
                                    </div>
                            
                        </div>
                    </div>
                </div>

                <div class="ml-5">
                    <div class="flex" *ngIf="questionAnswered == false">
                        <img src="assets/vinter/icons/not-answered.svg">
                        <p class="ml-2" style="color: #686E75;">{{"Key.QuestionNotAnswered" | translate}}</p>
                    </div>
                </div>

            </div>


        </div>
    </ng-container>


</p-sidebar>