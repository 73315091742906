import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-vinter-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent {
    @Input() value: number = 0;
    @Input() starNumber: number = 5;
    @Input() size: string = 'sm';
    @Output() onRate: EventEmitter<number> = new EventEmitter<number>();

    height = 17.5;
    width = 17.5;
    marginLeftCss = 'width-set-sm-left';
    marginRightCss = 'width-set-sm-right';

    @Input() disabled: boolean = false;
    starItem = [];
    ngOnInit(){
        this.setSizeOfImage();
        for (let index = 0; index < this.starNumber*2; index++) {
            this.starItem[index]= {id:index+1, src:'../../../../assets/vinter/icons/star-half-solid-left-disabled.svg'
            , class: index%2 === 1 ? 'img-hor-vert ' + this.marginRightCss: this.marginLeftCss}
        }
        this.resetColorOfStars();
    }

    ngOnChanges(){
        this.resetColorOfStars();
    }

    setSizeOfImage(){
        if(this.size === 'sm'){
            this.height = 17;
            this.width = 10;
        }else if(this.size === 'md'){
            this.height = 21;
            this.width = 13;
        }else if(this.size === 'lg'){
            this.height = 35;
            this.width = 22;
            this.marginLeftCss ='width-set-lg-left';
            this.marginRightCss ='width-set-lg-right';
        }
    }


    changeColorOfStars(id){
        if(!this.disabled){
            this.starItem.forEach(element => {
                element.src = element.id > id ?  '../../../../assets/vinter/icons/star-half-solid-left-disabled.svg' : '../../../../assets/vinter/icons/star-half-solid-left.svg'
              });
        }
    }

    resetColorOfStars(){
        this.starItem.forEach(element => {
            element.src = element.id > Math.round(this.value*2) ?  '../../../../assets/vinter/icons/star-half-solid-left-disabled.svg' : '../../../../assets/vinter/icons/star-half-solid-left.svg'
        });
    }

    starClicked(id){
        if(!this.disabled){
            let ratingValue = id/2;
            return this.onRate.emit(ratingValue);
        }
    }

}
