import { Component, Input, OnInit } from '@angular/core';
import { LocalizationService } from '@app/core';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { TimeService } from '@app/shared/services/time.service';
import { UserEducationInfo } from '@app/vinter/models/applicant-education-info';
import { ApplicantEducationInfoService } from '@app/vinter/services/applicant-education-info.service';

@Component({
  selector: 'app-applicant-education-info',
  templateUrl: './applicant-education-info.component.html',
  styleUrls: ['./applicant-education-info.component.scss']
})
export class ApplicantEducationInfoComponent implements OnInit{
  educationList: UserEducationInfo[] = [];
  currentUserEducationInfo: UserEducationInfo;
  dataLoaded: boolean = false;
  @Input() applicationId: string;
  
  constructor(
    private applicantEducationInfoService: ApplicantEducationInfoService,
    private timeService: TimeService,
    private localizationService: LocalizationService,
    public appInfoService: AppInfoService
  ) { }

  ngOnInit(): void {
    this.getApplicantEducationInfo();
  }

  getApplicantEducationInfo(){
    this.applicantEducationInfoService.getListApplicantEducationInfo({ applicationId: this.applicationId }).subscribe((response: any) => {
      let educations = response.map((education: any, index: number) => {
        education.startDate = education.startDate ? new Date(education.startDate) : null;
        education.endDate = education.endDate ? new Date(education.endDate) : null;
        education.degreeName = this.localizationService.translateText(this.appInfoService.getApplicantEducationDegreeArray().find(x => x.id === education.degreeType)?.name);
        education.formattedStartDate =  education.startDate ? this.localizationService.translateText(this.timeService.getMonthNameAndYear(education.startDate).monthNameStringValue) + " " + this.timeService.getMonthNameAndYear(education.startDate).yearStringValue.toString() : null;
        education.formattedEndDate =  education.endDate ? this.localizationService.translateText(this.timeService.getMonthNameAndYear(education.endDate).monthNameStringValue) + " " + this.timeService.getMonthNameAndYear(education.endDate).yearStringValue.toString() : null;
        return education;
      });
  
      educations.sort((a, b) => {
        if (a.startDate === null) return 1;
        if (b.startDate === null) return -1;
        return b.startDate.getTime() - a.startDate.getTime();
      });
      this.educationList = educations;
      this.dataLoaded = true;
    });
  }

}
