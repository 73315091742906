<div class="step-card-container">
  <ng-container [ngSwitch]="step.positionStep.questionCollection.questionCollectionCategory">
    <app-technical-test-card *ngSwitchCase="QuestionCollectionCategoryEnum.TechnicalTest" [step]="step"></app-technical-test-card>
     <app-cognitive-test-card *ngSwitchCase="QuestionCollectionCategoryEnum.CognitiveAbility" [step]="step" [report]="report" [pageNumber]="pageNumber" [answeredQuestionsWithId]="answeredQuestionsWithId"></app-cognitive-test-card>
       
    <ng-container *ngIf="step.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.ForeignLanguage && step.positionStep.questionCollection.subCategory !== QuestionCollectionSubCategory.GeneralEnglish">
      <app-proficiency-test-card  [step]="step" [report]="report" [pageNumber]="pageNumber" [chartData]="chartData" [languageAnsweredQuestions]="languageAnsweredQuestions"></app-proficiency-test-card>
    </ng-container>
    <ng-container *ngIf="step.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.ForeignLanguage && step.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.GeneralEnglish">
      <app-general-test-card  [step]="step" [report]="report" [pageNumber]="pageNumber" [chartData]="chartData" [languageAnsweredQuestions]="languageAnsweredQuestions"></app-general-test-card>
    </ng-container> 

    <app-pronunciation-test-card *ngSwitchCase="QuestionCollectionCategoryEnum.Pronunciation" [step]="step" [report]="report" [pageNumber]="pageNumber"></app-pronunciation-test-card> 
    <app-competence-test-card *ngSwitchCase="QuestionCollectionCategoryEnum.CompetenceTest" [step]="step"></app-competence-test-card>
    <app-ocean-test-card *ngSwitchCase="QuestionCollectionCategoryEnum.PersonalityTest" [step]="step" [report]="report"></app-ocean-test-card> 

  </ng-container> 
</div>