<div class="dashboard">
  <div class="category" *ngFor="let skill of skillLevels">
    <span class="skill-name">{{ skill.name }}</span>
    <div class="progress-circle">
      <svg viewBox="0 0 36 36" class="circular-chart">
        <path
          class="circle-bg"
          d="M18 2.0845
             a 15.9155 15.9155 0 0 1 0 31.831
             a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          class="circle"
          [attr.stroke-dasharray]="skill.percentage + ', 100'"
          d="M18 2.0845
             a 15.9155 15.9155 0 0 1 0 31.831
             a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <text x="18" y="20.35" class="percentage">{{ skill.level }}</text>
      </svg>
    </div>
    <div class="label">
      <div class="score">{{ skill.percentage.toFixed(0) }}</div>
    </div>
  </div>
</div>
