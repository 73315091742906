<div class="progress-value-container">
    <div class="circular-progress-container">
        <div class="circular-progress">
        <svg viewBox="0 0 100 100" class="progress-circle">
            <circle cx="50" cy="50" r="45" class="circle-background"></circle>
            <circle
            cx="50"
            cy="50"
            r="45"
            class="circle-progress"
            [style.strokeDashoffset]="calculatedProgressValue"
            [style.strokeDasharray]="strokeDasharray"
            [style.stroke]="strokeColor"

            ></circle>
        </svg> 
        <div class="progress-value">{{ progressValue}}</div>
    </div>
</div>