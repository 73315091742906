<div class="container flex flex-column p-2 justify-content-center bg-primary-50 border-round-xl hover-container">
    <div class="flex align-items-start justify-content-start px-2 py-1">
        <span class="flex  align-items-baseline">
            <span class="font-medium text-gray-800 text-sm">{{ 'Key.' + labelKey | translate }}</span>
            <ng-container *ngIf="englishLevel">
                <span class="pl-2 font-bold text-gray-900">/ {{ englishLevel }}</span>
            </ng-container>
        </span>
        <div class="pl-2 flex flex-wrap h-full" *ngIf="showTooltip">
            <img src="assets/vinter/icons/info-fill.svg" tooltipPosition="top"
                pTooltip="{{'Key.TotalVinterScoreInfo' | translate}}" class="info-fill-icon">
        </div>
    </div>
    <div class="flex align-items-center justify-content-end px-2 py-1">
        <div class="progress-container border-round-lg mr-3">
            <div class="progress-bar border-round-md" [style.width.%]="score ? Math.min(score, 100) : 0"
                [style.background-color]="getProgressBarColor(score || 0)">
            </div>
        </div>
        <span class="flex font-bold text-gray-900 align-items-baseline" style="width: 45px;">
            <ng-container *ngIf="score !== null && score !== undefined; else noScore">
                <span class="big">{{ getIntegerPart(score) }}</span>
                <span class="text-sm">.{{ getDecimalPart(score) }}</span>
            </ng-container>
            <ng-template #noScore>
                <span class="big">-- --</span>
            </ng-template>
        </span>
    </div>
</div>