import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import * as ace from "ace-builds";

@Component({
  selector: 'app-vinter-code-editor',
  templateUrl: './vinter-code-editor.component.html',
  styleUrls: ['./vinter-code-editor.component.scss']
})
export class VinterCodeEditor implements AfterViewInit, OnChanges{

    @ViewChild("editor") private editor: ElementRef<HTMLElement>;
    @Input() fontSize: number = 15;
    @Input() width: number;
    @Input() height: number =450;
    @Input() disabled=false;
    @Input() codeType="python";
    @Input() template="";
    @Input() isSelectionEnabled=false;
    @Input() dropdownReadonly=false;
    @Output() onCodeInputChanged: EventEmitter<any> = new EventEmitter<any>();

    codeModes=[{name:'Python', code:'python'},
                {name:'Javascript', code:'javascript'},
                {name:'CSharp', code:'csharp'},
                {name:'Java', code:'java'},
                {name:'Php', code:'php'}    ];

    codeLanguagesModes=[{name:'Python', code:'84377a79-8bf8-431a-9a72-eaee4e860566'},
                {name:'Javascript', code:'7c34ac08-05d1-4174-a76b-5ee3e201a247'},
                {name:'CSharp', code:'1232fec2-83f2-4dda-8e5c-e32e379c7c92'},
                {name:'Java', code:'49bdab69-a16a-4f09-82f2-0e2707555c9b'},
                {name:'Php', code:'23b2ccef-c0a2-42ca-86f2-1b556808ad39'}    ];

    selectedCodeMode=this.codeModes?.find(x=> x.code === this.codeType);
    ngAfterViewInit(): void {
        if(this.codeType != null && this.codeType != "" && this.codeType != undefined){
            let selectedCodeLanguage = this.codeLanguagesModes?.find(x=> x.code === this.codeType).name;
            this.selectedCodeMode=this.codeModes?.find(x=> x.code === selectedCodeLanguage.toLowerCase());
        }
        this.setEditorData();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.setEditorData();
        if(changes.disabled || changes.dropdownReadonly){
            this.updateDisabledStatus();
        }

        if(changes.fontSize){
            this.updateFontSizeStatus();
        }

        if(changes.codeType){
            this.updateCodeLanguage();
        }
    }

    setEditorData(){
        ace.config.set("fontSize", this.fontSize+"px");
        ace.config.set(
            "basePath",
            "/node_modules/ace-builds/src-min-noconflict"
        );

        ace.require("ace/ext/language_tools");
        if(this.editor ?? false){
            const aceEditor = ace.edit(this.editor.nativeElement);
            aceEditor.setOptions({
                enableBasicAutocompletion: true,
                enableSnippets: true,
                enableLiveAutocompletion: false,
                cursorStyle:"smooth"
            });
            aceEditor.session.setValue(this.template);
            aceEditor.setTheme("ace/theme/twilight");
            aceEditor.session.setMode('ace/mode/'+this.selectedCodeMode?.code);
            aceEditor.setReadOnly(this.disabled);

            aceEditor.on("change", () => {
              this.onCodeInputChanged.emit({codeText:aceEditor.getValue(), codeLanguage: this.selectedCodeMode});
            });
        }
    }

    updateDisabledStatus(){
        if(this.editor?.nativeElement ?? false){
            const aceEditor = ace.edit(this.editor.nativeElement);
            aceEditor.setReadOnly(this.disabled);
        }

    }

    updateFontSizeStatus(){
        if(this.editor?.nativeElement ?? false){
            const aceEditor = ace.edit(this.editor.nativeElement);
            aceEditor.setFontSize(this.fontSize+"px")
        }

    }

    updateCodeLanguage(){
        if(this.editor?.nativeElement ?? false){
            let selectedCodeLanguage = this.codeLanguagesModes?.find(x=> x.code === this.codeType)?.name;
            this.selectedCodeMode=this.codeModes?.find(x=> x.code === selectedCodeLanguage?.toLowerCase());
            const aceEditor = ace.edit(this.editor.nativeElement);
            aceEditor.getSession().setMode('ace/mode/'+this.selectedCodeMode.code);
        }
    }

    onCodeLanguageChanged(event){
        this.codeType = this.codeLanguagesModes?.find(x=> x.name === event.code).code;
        this.selectedCodeMode=this.codeModes?.find(x=> x.code === event.code.toLowerCase());
        const aceEditor = ace.edit(this.editor.nativeElement);
        aceEditor.getSession().setMode('ace/mode/'+event.code);
        this.onCodeInputChanged.emit({codeText:aceEditor.getValue(), codeLanguage: this.selectedCodeMode});
    }

    reset(){
        const aceEditor = ace.edit(this.editor.nativeElement);
        aceEditor.session.setValue(this.template);
    }
}
