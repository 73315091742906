import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService, HttpBaseService, LocalizationService, NotifyService } from '@app/core';
import { ApiControllerEnum } from '@app/shared/common/enums';
import { AnswerContentHRScoreItems, AnswerContentHRScoreResponse } from '../models/answer-content-form-item';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnswerContentHRScoreService extends HttpBaseService{

  constructor(private http: HttpClient
    , private notifyServ: NotifyService
    , private localizationService: LocalizationService
    , private configService: ConfigurationService) {
    super(http, ApiControllerEnum.AnswerContentHRScore, notifyServ, localizationService, configService);
  }
  setAsnwerContentHRScoreChange(obj: AnswerContentHRScoreItems): Observable<AnswerContentHRScoreResponse> {
    return this.post("SetAsnwerContentHRScoreChange", obj, true, false);
  }
  getChangedScoreData(filter: any): Observable<AnswerContentHRScoreResponse[]> {
    return this.post("getChangedScoreData", filter, true,false);
  }
}
