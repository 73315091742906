
<div class="mt-0 pt-0">
    <div class="grid flex ">
        <ng-container *ngIf="item.positionStep.questionCollection.questionCollectionQuestionRelations.length > 0">
            <div *ngFor="let question of filteredList; let i = index"
                class="question-container col-12 flex pb-0"
                [ngClass]="{ 'active': selectedId == question.question.id }">
                <div class="grid flex col-8 pb-0 h-4rem">
                    <div class="question-number question-number-color col-1 mr-0 pr-0" style="width: 30px;">
                        {{ question.order }}
                    </div>
                    <div class="col-1 pt-2 order-icon-width h-5rem block">
                        <div class="grid">
                            <div class="col-12 p-0 flex flex-nowrap">
                                <div *ngIf="question.question.expectedAnswerType === QuestionExpectedAnswerType.Video">
                                    <img class="custom-svg-question-icon align-self-center"
                                        src="/assets/vinter/icons/videoQuestion.svg" />
                                </div>
                                <div *ngIf="question.question.expectedAnswerType === QuestionExpectedAnswerType.OpenEnded">
                                    <img class="custom-svg-question-icon align-self-center"
                                        src="/assets/vinter/icons/openEndedQuestion.svg" />

                                </div>
                                <div *ngIf="question.question.expectedAnswerType === QuestionExpectedAnswerType.MultipleChoice">
                                    <img class="custom-svg-question-icon align-self-center"
                                        src="/assets/vinter/icons/Quiz.svg" />
                                </div>
                                <div class="english-icon" *ngIf="question.question.questionCategory === questionCategoryType.Writing">
                                    <app-custom-chip  [svgUrl]="'assets/vinter/icons/writing-icon-circled.svg'" [alt]="'Writing'" [bgColorClass]="'bg-transparent'" [imgSize]="'23px'"></app-custom-chip>
                                </div>
                                <div class="english-icon" *ngIf="question.question.questionCategory === questionCategoryType.Listening">
                                    <app-custom-chip [svgUrl]="'assets/vinter/icons/listening-icon-circled.svg'" [alt]="'Listening'" [bgColorClass]="'bg-transparent'" [imgSize]="'23px'"></app-custom-chip>
                                </div>
                                <div class="english-icon" *ngIf="question.question.questionCategory === questionCategoryType.Reading">
                                    <app-custom-chip [svgUrl]="'assets/vinter/icons/reading-icon-circled.svg'" [alt]="'Reading'" [bgColorClass]="'bg-transparent'" [imgSize]="'23px'"></app-custom-chip>
                                </div>
                                <div class="english-icon" *ngIf="question.question.questionCategory === questionCategoryType.Vocabulary">
                                    <app-custom-chip [svgUrl]="'assets/vinter/icons/vocabulary-icon-circled.svg'" [alt]="'Vocabulary'" [bgColorClass]="'bg-transparent'" [imgSize]="'23px'"></app-custom-chip>
                                </div>
                                <div class="english-icon" *ngIf="question.question.questionCategory === questionCategoryType.Grammer">
                                    <app-custom-chip [svgUrl]="'assets/vinter/icons/grammer-icon-circled.svg'" [alt]="'Grammer'" [bgColorClass]="'bg-transparent'" [imgSize]="'23px'"></app-custom-chip>
                                </div>
                                <div class="english-icon" *ngIf="question.question.questionCategory === questionCategoryType.Speaking">
                                    <app-custom-chip [svgUrl]="'assets/vinter/icons/speaking-icon-circled.svg'" [alt]="'speaking'" [bgColorClass]="'bg-transparent'" [imgSize]="'23px'"></app-custom-chip>
                                </div>
                            </div>
                            <div class="col-12 p-0 flex justif-content-center">
                                <div *ngIf="question.isFaceDetected || question.isMultipleFaceDetected || question.question.isImproprietySpeech || checkIfQuestionLogExists(question.question.id)">
                                    <app-question-behaiviour-info [cheatingQuestionLog]="cheatingQuestionLog" [question]="question"></app-question-behaiviour-info>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div class="question-container-text col pr-5 pl-5 pb-0 h-3rem" (click)="getAnswerByQuestionId(i)">
                        <p class="question-content truncate-text ml-0" [innerHTML]="question.question.questionContent.questionText">
                        </p>
                    </div>

                </div>

                <div class="col-4 flex p-0 justify-content-end align-items-center" style="width: 36%;">

                    <div *ngIf="question.question.expectedAnswerType !== 1 && question.question.gptEvalStatus !== 1 && question.question.gptEvalStatus !== 3 && question.question.isAnswered"
                        class="col-4 flex question-container-rating-list mr-0">
                        <div *ngIf="question?.question.ratingType !== RatingType.VinterTrust">
                            <img src='{{vinterLogo}}' alt="Vinter logo" class="img-list" (click)="getAnswerForRatingByQuestionId(question.question, false, RatingType.VinterTrust)"
                            pTooltip='{{"Key.TrustVinter" | translate}}'
                            tooltipPosition="top">
                            <h5 class="h5-font-list mt-1 trust-vinter-text-list ml-3 cursor-pointer"
                                (click)="getAnswerForRatingByQuestionId(question.question,false, RatingType.VinterTrust)">{{'Key.TrustVinter' | translate}}</h5>
                        </div>
                        <div *ngIf="question?.question.ratingType === RatingType.VinterTrust">
                            <i class="pi pi-check img-list text-2xl ml-6" style="color: #2962FF" pTooltip='{{"Key.VinterTrusted" | translate}}'
                            tooltipPosition="top" ></i>
                            <h5 class="h5-font-list mt-1 trusted-vinter-text-list ml-1">{{'Key.VinterTrusted' | translate}}</h5>
                        </div>
                    </div>
                    <div *ngIf="question.question.expectedAnswerType !== 1 && question.question.gptEvalStatus !== 1 && question.question.gptEvalStatus !== 3 && question.question.isAnswered" class="vertical-line-rating-list"></div>
                    <div *ngIf="question.question.expectedAnswerType !== 1 && question.question.gptEvalStatus !== 1 && question.question.gptEvalStatus !== 3 && question.question.isAnswered" class="col-3 question-container-rating-list mr-0">
                            <app-vinter-rating
                                tooltipPosition="top"
                                pTooltip='{{"Key.HRPoint" | translate}}'
                                [starNumber]="1"
                                [disabled]="true" size="sm" [value]="question?.question.ratingScore === 0 ? 0 : 1"
                                (click)="getAnswerForRatingByQuestionId(question.question,true, RatingType.HrPoint)"></app-vinter-rating>

                                <h5 *ngIf="question?.question.ratingScore === 0" class="h5-font-list mt-2 trust-vinter-text-list flex rate-text-list justify-content-center"
                                (click)="getAnswerForRatingByQuestionId(question.question,true, RatingType.HrPoint)"
                                style="color: #363D47;">
                                {{'Key.Rate' | translate}}
                                </h5>
                                <h5 *ngIf="question?.question.ratingScore !== 0" class="h5-font-list mt-2 trust-vinter-text-list rate-text-list flex justify-content-center" style="color: #363D47;">{{question?.question.ratingScore}}
                                </h5>
                    </div>
                    <div *ngIf="question.question.expectedAnswerType !== 1 && question.question.gptEvalStatus !== 1" class="vertical-line-rating-list"></div>
                    <div class="col-4 question-container-rating-list pt-3 pb-0 mr-0">
                        <div class="progress-list">
                            <div class="progress-bar-list" [style.width]="question?.question.gptScore + '%'"
                                [style.backgroundColor]="getProgressBarColor(question?.question.gptScore)"></div>
                        </div>
                        <h5 class="h5-font flex justify-content-center mt-2 vinter-score-list trust-vinter-text-list" style="color: #363D47;">
                            {{question.question.gptScore.toFixed(2)}}</h5>
                    </div>
                    <div class="col-1 flex mb-2">

                        <div *ngIf="question.question.isAnswered">
                            <p-radioButton class="answered" [disabled]="true" [value]="question.question.isAnswered"
                                [(ngModel)]="question.question.isAnswered"></p-radioButton>

                        </div>
                        <div *ngIf="!question.question.isAnswered">
                            <p-radioButton class="not-answered" [disabled]="true" [value]="question.question.isAnswered"
                                [(ngModel)]="question.question.isAnswered"></p-radioButton>

                        </div>

                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<app-applicant-answer-rate-popup *ngIf="popupOpen" (onPopupClose)="closePopup()" (onRated)="onRated($event)" [answer]="answer"></app-applicant-answer-rate-popup>

<!-- <app-applicant-answer-sidebar-language *ngIf="sidebarVisible"
(hideSidebar)="onHideSidebar($event)"
[applicantId]="applicantId"
[applicationId]="applicationId"
[questionCollectionId]="this.item?.positionStep?.questionCollection"
[applicationStepId]="item.id"
[filteredData]="originalList"
[questionData]="selectedQuestion"
[behaviourEventLogs]="cheatingQuestionLog"></app-applicant-answer-sidebar-language> -->


<!-- <app-answer-detail-sidebar *ngIf="sidebarVisible && answer" [answerId]="answer?.id" [questionId]="selectedQuestion.id"
 [questionCollectionCategoryId]="item.positionStep.questionCollection.questionCollectionCategory" [applicationStepId]="item.id"
  [sidebarVisible]="sidebarVisible" (onHideAnswerSidebar)="onHideSidebar($event)"></app-answer-detail-sidebar> -->