<div class="bar-chart-container">
    <div class="progress-bar" *ngFor="let progress of progressData">
        <span class="font-medium">{{ progress.label }}</span>
        <div class="flex align-items-center">
            <div class="bar">
                <div class="box" *ngFor="let step of getSteps(progress.value)" [ngClass]="{ 'active': step <= Math.ceil(progress.value / 10) }"      
                    [style.backgroundColor]="step <= Math.ceil(progress.value / 10) ? progress.color : '#E6E7E8'"
                ></div>
            </div>
            <div class="flex text-width font-semibold">
                <span [style.color]="progress.color">%{{ progress.value }}</span>
            </div>
        </div>  
    </div>
</div>
    
     