<div class="flex gap-3 flex-wrap mt-3">
    <div class="flex-nowrap flex gap-2 align-items-center" *ngIf="questionPageHiddenLog > 0">
        <img src="assets/vinter/icons/cheating-icon.svg">
        <p class="font-medium page-hidden">{{"Key.SwitchTab" | translate: {value: questionPageHiddenLog } }}</p>
    </div>
    <div *ngIf="answer?.answerContent?.isFaceDetected" class="flex-nowrap flex gap-2 align-items-center">
        <img src="assets/vinter/icons/face-detection-icon.svg" class="detect-img">
        <p class="font-medium face-detected">{{"Key.FaceDetected" | translate }}</p>
    </div>
    <div *ngIf="answer?.answerContent?.isMultiFaceDetected" class="flex-nowrap flex gap-2 align-items-center">
        <img src="assets/vinter/icons/multiface-detected-icon.svg" class="detect-img">
        <p class="font-medium multiface-detected">{{"Key.MultiFaceDetected" | translate }}</p>
    </div>
    <div *ngIf="answer?.answerContent?.improprietyAnalysis?.isImproprietySpeech" class="flex-nowrap flex gap-2 align-items-center">
        <img src="assets/vinter/icons/impropriety-speech-warning.svg" class="detect-img">
        <p class="font-medium impropriety-speech">{{"Key.ImproprietySpeechAnalysis" | translate }}</p>
    </div>
</div>

<div class="flex">
    <img src="assets/vinter/icons/text-questioncoktan-secmeli-cevap-icon-arrow-back-up.svg">
    <h5 class="text-justify answer-title font-semibold text-lg ml-2">
        {{"Key.Answer" | translate}}
    </h5>
</div>

<div class="flex ml-5" *ngIf="answer == null">
    <img src="assets/vinter/icons/not-answered.svg">
    <p class="ml-2 empty-answer-text">{{"Key.QuestionNotAnswered" | translate}}</p>
</div>

<div *ngIf="answer?.answerContent && answer?.answerContentPronunciationAnalysis ?? false" class="grid lg:ml-4">
    <div class="col-12 md:col-12 lg:col-6 lg:px-2">
        <div class="flex flex-wrap answer-container border-round-md border-1 border-solid border-gray-50">
            <div class="flex height-fit">
                <vg-player *ngIf="answer?.answerContent.contentURL ?? false" style="border-radius: 15px;">
                    <video #pronunciationVideo [vgMedia]="$any(pronunciationMedia)" #pronunciationMedia id="pronunciationVideo" preload="auto"
                        controls (timeupdate)="highlightPronunciation($event.target)">
                        <source [src]="answer?.answerContent.contentURL" type="video/webm">
                        <source [src]="answer?.answerContent.contentURL" type="video/mp4">
                    </video>
                </vg-player>
            </div>

            <div class="flex flex-column">
                <div *ngIf="answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.Succesful" class="col-12 px-0 pt-4 text-gray-900 font-semibold text-lg">
                    {{"Key.RecognizedSpeech" | translate}}
                </div>
                <div *ngIf="answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.Succesful">
                    <ng-container  *ngIf="answer?.answerContentPronunciationAnalysis!=null && answer?.answerContentPronunciationAnalysis.analysisResultDetail !=null">
                        <div #pronunciationDiv class="overflow-y-auto speech-text">
                            <div *ngIf="pronunciationWords.length > 0" class="text-gray-400 text-justify cursor-pointer pr-2">
                                <span *ngFor="let word of pronunciationWords" [id]="'p' + word.index"
                                    (click)="jumpToSecondPronunciation(word.start);" class="pronunciationText"
                                    [ngClass]="word.styleClass"
                                    [ngStyle]="
                                    (word.highlighted)?
                                    { 'background-color': 'var(--gray-500)','color': '#fff'} :
                                    (word.errorType=='Mispronunciation' && !showMispronunciation) ?
                                    { 'background-color': 'unset','color': 'var(--gray-400)'} :
                                    (word.errorType=='Omission' && !showOmission) ?
                                    { 'background-color': 'unset','color': 'var(--gray-400)'} :
                                    (word.errorType=='Insertion' && !showInsertion) ?
                                    { 'background-color': 'unset','color': 'var(--gray-400)'} :
                                    null
                                    "
                                    (click)="jumpToSecondPronunciation(word.start);"
                                    tooltipPosition="top"
                                    [pTooltip]="getPronunciationTooltip(word)"
                                    tooltipStyleClass="pronunciationTooltip"
                                    [escape]="false"
                                    >
                                    {{ word.word }}
                                </span>
                            </div>
                        </div>
                </ng-container>
                </div>

                <div class="answer-text-label text-justify" *ngIf="answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.Fail">
                    {{"Key.GptFailStatusMessage" | translate}}
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 md:col-12 lg:col-6 lg:pl-2">
        <div class="flex flex-column pr-0 align-items-center justify-content-center w-full pb-3 pl-3 border-1 border-solid border-gray-50 border-round-lg custom-display">
            <div *ngIf="answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.Succesful && answer?.answerContentPronunciationAnalysis != null" class="w-full flex p-0">
                <div class="col-6 px-0 text-lg text-gray-900 font-semibold pb-2 flex align-items-center gap-2">
                    {{"Key.PronunciationScore" | translate}}
                    <img class="rating-info" src="assets/vinter/icons/info-icon.svg"
                        pTooltip="{{'Key.PronunciationScoreInfo' | translate}}" tooltipPosition="top"/>
                </div>
                <div class="flex align-items-center justify-content-end col-6 gap-2 white-space-nowrap pb-2">
                    <p-progressBar class="w-full progress-header"
                    [value]="answer?.answerContentPronunciationAnalysis.pronunciationScore" [showValue]="false"></p-progressBar>
                    <span class="font-bold text-xs text-blue-600">{{answer?.answerContentPronunciationAnalysis.pronunciationScore.toFixed(2)}}</span>
                </div>
            </div>

            <div *ngIf="answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.Succesful && answer?.answerContentPronunciationAnalysis != null" class="w-full">
                <div class="flex align-items-center w-full" *ngFor="let pronunciationEntry of pronunciationArray">
                    <div class="flex align-items-center col-6 gap-2 pl-0 pb-2">
                        <span class="text-sm text-gray-700 font-medium"> {{"Key." + pronunciationEntry.name | translate}}</span>
                        <img src="assets/vinter/icons/info-fill.svg" [pTooltip]="'Key.' + pronunciationEntry.name | translate" class="info-fill-icon">
                    </div>
                    <div class="flex align-items-center justify-content-end col-6 gap-2 white-space-nowrap pb-0">
                        <p-progressBar class="w-full progress-sub"
                        [value]="pronunciationEntry.value" [showValue]="false"></p-progressBar>
                        <span class="font-bold text-xs text-blue-500">{{ pronunciationEntry.value.toFixed(2) }}</span>
                    </div>
                </div>
            </div>   

            <div class="retry-container border-2 border-solid h-13rem w-15rem bg-white" *ngIf="answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.Retry">
                <div class="grid">
                    <div class="col-12 flex justify-content-center">
                        <img src="../../../../../assets/vinter/icons/gpt-sleep.svg" class="w-4rem pt-3">
                    </div>
                    <div class="col-12 flex justify-content-center text-gray-700 text-sm font-medium py-0 text-center">
                        {{"Key.GptRetryMessage" | translate}}
                    </div>
                    <div class="col-12 pt-4 flex justify-content-center">
                        <img src="../../../../../assets/vinter/icons/retry-icon.svg" (click)="setGptStatus()" class="w-1rem mr-2 cursor-pointer">
                        <p class="text-blue-500 cursor-pointer" (click)="setGptStatus()">{{"Key.Retry" | translate}}</p>
                    </div>
                </div>
            </div>

            <div *ngIf="answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.ClientFail">
                <div class="client-fail-container border-2 border-solid w-15rem bg-white">
                    <div class="grid">
                        <div class="col-12 pb-0 flex justify-content-center">
                            <img src="../../../../../assets/vinter/icons/text-grammar-error-regular.svg" class="w-2rem pt-3" >
                        </div>

                        <div class="col-12 flex justify-content-center answer-text-label text-justify text-center text-base font-medium">
                            {{ answer?.answerContent.aiFinalLog }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="answer-text-label text-justify" *ngIf="answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.Fail">
                {{"Key.GptFailStatusMessage" | translate}}
            </div>
            <ng-container *ngIf="answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.Waiting || answer?.answerContent?.gptEvalStatus === 99">
                {{"Key.WaitForAIComment" | translate}}
            </ng-container>
        </div>

        <div *ngIf="answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.Succesful && answer?.answerContentPronunciationAnalysis.analysisResultDetail ?? false" class="flex flex-column p-3 mt-3 gap-3">
            <span class="text-gray-900 text-lg font-semibold">{{"Key.PronunciationErrors" | translate}}</span>
            <div class="flex w-full justify-content-between align-items-center gap-2">
                <div class="flex align-items-center gap-2">
                    <div class="bg-red-400 text-center flex align-items-center justify-content-center text-white">
                        {{mispronunciationCount}}
                    </div>
                    <span class="text-gray-600 font-medium">{{"Key.Mispronunciation" | translate}}</span>
                    <img class="rating-info" src="assets/vinter/icons/info-icon.svg"
                        pTooltip="{{'Key.MispronunciationInfo' | translate}}" tooltipPosition="top"/>
                </div>
                <div class="flex align-items-center font-semibold text-gray-500">
                    <p-inputSwitch [(ngModel)]="showMispronunciation" [style]="{'margin-left':'1rem'}"></p-inputSwitch>
                </div>
            </div>
            <div class="flex w-full justify-content-between align-items-center gap-2">
                <div class="flex align-items-center gap-2">
                    <div class="bg-blue-200 text-center flex align-items-center justify-content-center text-white">
                        {{omissionCount}}
                    </div>
                    <span class="text-gray-600 font-medium">{{"Key.Omission" | translate}}</span>
                    <img class="rating-info" src="assets/vinter/icons/info-icon.svg"
                        pTooltip="{{'Key.OmissionInfo' | translate}}" tooltipPosition="top"/>
                </div>
                <div class="flex align-items-center font-semibold text-gray-500">
                    <p-inputSwitch [(ngModel)]="showOmission" [style]="{'margin-left':'1rem'}"></p-inputSwitch>
                </div>
            </div>
            <div class="flex w-full justify-content-between align-items-center gap-2">
                <div class="flex align-items-center gap-2">
                    <div class="bg-yellow-500 text-center flex align-items-center justify-content-center text-white">
                        {{insertionCount}}
                    </div>
                    <span class="text-gray-600 font-medium">{{"Key.Insertion" | translate}}</span>
                    <img class="rating-info" src="assets/vinter/icons/info-icon.svg"
                        pTooltip="{{'Key.InsertionInfo' | translate}}" tooltipPosition="top"/>
                </div>
                <div class="flex align-items-center font-semibold text-gray-500">
                    <p-inputSwitch [(ngModel)]="showInsertion" [style]="{'margin-left':'1rem'}"></p-inputSwitch>
                </div>
            </div>
        </div>
    </div>
</div>
