import { EntityStateEnum } from "@app/shared/common/enums";

export class AnswerContentFormItem {
  id: string;
  answerText: string;
  contentURLType: number;
  contentURL: string;
  answerId: string;
  gptEvalCost: number;
  gptEvalScore: number;
  gptEvalStatus: number;
  gptEvalText: string;
  transcriptStatus: number;
  transcriptText: string;
  subtitle:string;
  isFaceDetected?: boolean;
  isMultiFaceDetected?: boolean;
  answerCodeLanguage: string;
  entityState: EntityStateEnum;
  xmin:string;
  impressionAnalysis:any;
  emotionAnalysis:any;
  voiceAnalysis:any;
  answerContentPronunciationAnalysis:any;
  codingAnalysis: any;
  improprietyAnalysis:any;
  processStatus: number;
}

export class AnswerContentHRScoreItems {
  applicantID: string;
  scoreChange: number;
  hrComment: string;
  answerContentID: string; 
}

export class AnswerContentHRScoreResponse{
  createdAt: Date
  hrFirstname: string
  hrLastname: string
  scoreChange: number
  hrDepartment: string
  hrComment: string
  hrImageUrl: string
}
