import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AICommentsIconPath, SpeakingIcon } from '@app/shared/common/constants';
import { EQuestionCategories, EQuestionContentURLType, GPTEvalStatus, QuestionExpectedAnswerType, TranscriptStatus } from '@app/shared/common/enums';
import { VgApiService } from '@videogular/ngx-videogular/core';

@Component({
  selector: 'app-applicant-answer-sidebar-video-language',
  templateUrl: './applicant-answer-sidebar-video.component.html',
  styleUrls: ['./applicant-answer-sidebar-video.component.scss']
})
export class ApplicantAnswerSidebarVideoLanguage implements OnInit{
    @Input() question: any;
    @Input() answerContent: any;
    QuestionContentType = EQuestionContentURLType;
    QuestionCategoryType = EQuestionCategories;
    GPTEvalStatusEnum = GPTEvalStatus;
    TranscriptStatusEnum = TranscriptStatus;
    SpeakingIconPath = SpeakingIcon;
    speakingWords = [];
    discourseOrgEval: string;
    fluencyEval: string;
    grammarEval: string;
    pronunciationEval: string;
    vocabAccEval: string;
    AICommentPathIcon = AICommentsIconPath;

    @ViewChild('speakingDiv') speakingDiv: ElementRef;
    @ViewChild('speakingVideo') speakingTarget: ElementRef;

    constructor(
    public api: VgApiService) { }

    ngOnInit(): void {
        if (this.answerContent?.speechAnalysis != null && this.answerContent.speechAnalysis.analysisResultDetail) {
            let all = (JSON.parse(this.answerContent.speechAnalysis.analysisResultDetail.toString()));
            this.discourseOrgEval = all.eval_texts.discourse_org_eval;
            this.fluencyEval = all.eval_texts.fluency_eval;
            this.grammarEval = all.eval_texts.grammar_eval;
            this.pronunciationEval = all.eval_texts.pronunciation_eval;
            this.vocabAccEval = all.eval_texts.vocab_acc_eval;

            let transcriptArray = this.answerContent.transcriptText;
            let wordArray = [];
            let wordIndex = 0;
        }
    }

    highlightSpeaking(target: any) {
        this.speakingWords.forEach((word, index) => {
            word.index = 'p' + index;
            word.highlighted = target.currentTime >= word.start && target.currentTime < word.end;
        });

        this.highlightSpeakingInView();
    }

    highlightSpeakingInView() {
        let activeWord = this.speakingWords.find(word => word.highlighted);

        if (activeWord) {
            let highlightedElement = this.speakingDiv.nativeElement.querySelector(`#p${activeWord.index}`);

            if (highlightedElement) {
                highlightedElement.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest"
                });
            }
        }
    }

    jumpToSecondSpeaking(start: number) {
        if (this.api && start) {
            this.speakingTarget.nativeElement.currentTime = start;
            this.highlightSpeaking(this.speakingTarget.nativeElement);
            this.speakingTarget.nativeElement.play();
        }
    }

}
