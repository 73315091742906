import { Component, Input, OnInit } from '@angular/core';
import { LocalizationService } from '@app/core';
import { EApplicantBehaviourEventType, EQuestionCategories, EQuestionContentURLType, EntityStateEnum, GPTEvalStatus, ProcessStatus, QuestionExpectedAnswerType, WringErrorColorEnum, WritingAnalysisMap } from '@app/shared/common/enums';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { AnswerContentFormItem } from '@app/vinter/models/answer-content-form-item';
import { AnswerContentService } from '@app/vinter/services/answer-content.service';
import { VgApiService } from '@videogular/ngx-videogular/core';

@Component({
  selector: 'app-applicant-answer-sidebar-open-ended-language',
  templateUrl: './answer-sidebar-open-ended.component.html',
  styleUrls: ['./answer-sidebar-open-ended.component.scss']
})
export class ApplicantAnswerSidebarOpenEndedLanguage implements OnInit{

    answerType: any;
    questionOrder: any;
    QuestionExpectedAnswerType = QuestionExpectedAnswerType;
    @Input() question: any;
    @Input() questionKeywords: any;
    @Input() answerContent: any;
    @Input() behaviourEventLogs: any[]=[];
    writingAnalysisMap = [];
    eQuestionCategories = EQuestionCategories;
    QuestionContentType= EQuestionContentURLType;
    selectedAnswerChoice: any;
    selectedAnswer:string;
    selectedRadioValue: any;
    writingSubtitles: any[]=[];
    erroredDataText: any[]=[];
    filterErrorArray: any[]=[];
    jsonData: any;
    errorPopupData: any;
    isErrorNotFound=false;
    isWritingAnalysisEmpty=true;
    questionPageHiddenLog: number;
    questionMouseOutLog: number;
    gptEvalStatusEnum = GPTEvalStatus;
    processStatusEnum=ProcessStatus;

  constructor(
    public appInfoService: AppInfoService,
    public api: VgApiService,
    private localizationService: LocalizationService,
    public answerContentService: AnswerContentService) { }

  ngOnInit(): void {
    if((this.answerContent?.writingAnalysis?.analysisResultDetail ?? false)){
        this.jsonData = JSON.parse(this.answerContent?.writingAnalysis?.analysisResultDetail);
    }
    this.questionMouseOutLog = this.checkIfQuestionLog(EApplicantBehaviourEventType.MouseOut,this.question.id);
    this.questionPageHiddenLog = this.checkIfQuestionLog(EApplicantBehaviourEventType.PageHidden,this.question.id);
    this.setWritingSubtitles();
    this.getErrorSwitchButtonArray();
    this.getErroredAnswerText();


  }

  setWritingSubtitles(){
    //analysisResultDetail
    this.writingAnalysisMap = this.appInfoService.getWritingAnalysisMap();
    let writingSubtitles = [];
    if(this.jsonData ?? false){
        this.writingAnalysisMap.forEach(element => {
            var obj = {
                score: this.jsonData?.gpt_assessment.parts_of_eval_text[element.id]?.score,
                evalText:this.jsonData?.gpt_assessment?.parts_of_eval_text[element.id]?.eval_text,
                title: element?.name+" Score",
                info:this.localizationService.translateText(element.id+"Info")
            }
            writingSubtitles.push(obj);
       });
       this.isWritingAnalysisEmpty = false;
    }else{
        this.writingAnalysisMap.forEach(element => {
            var obj = {
                score: 0,
                evalText:"Empty Data",
                title: element?.name+" Score"
            }
            writingSubtitles.push(obj);
       });
        this.isWritingAnalysisEmpty = true;
    }
   this.writingSubtitles = writingSubtitles;
  }

  getErrorWithConflictingErrors(error, errorList){
    let resolvedError= [];
    errorList.forEach(otherError => {
        //other error inside the this error
        if(otherError.error_start_index >= error.error_start_index && otherError.error_finish_index <= error.error_finish_index
            && otherError.error_start_index <= error.error_finish_index){
            otherError.isAdded = true;
            resolvedError.push(otherError)
        }else if(otherError.error_start_index >= error.error_start_index &&  otherError.error_finish_index > error.error_finish_index
            && otherError.error_start_index <= error.error_finish_index){
            otherError.isAdded = false;
            otherError.error_start_index = error.error_finish_index;
            resolvedError.push(otherError)
        }
    });
    return resolvedError;
  }

  setGptStatus(){
    let answerContentForm : AnswerContentFormItem = new AnswerContentFormItem();
    answerContentForm = this.answerContent;
    answerContentForm.gptEvalStatus = this.gptEvalStatusEnum.Waiting;
    answerContentForm.processStatus = this.processStatusEnum.AIContainerWaiting;
    answerContentForm.entityState = EntityStateEnum.Modified;
    answerContentForm.xmin = this.answerContent.xmin;

    this.answerContentService.SaveAsync(answerContentForm).subscribe();
}

  getErroredAnswerText(){
    let emptyString = [];
    let errorList= [];
    var errorListTemp = [];
    var answerText=this.answerContent ?? false ? this.answerContent?.answerText:"";
    if(this.jsonData ?? false){
        answerText = this.jsonData.error_detail.answer_text ?? false ? this.jsonData.error_detail.answer_text: JSON.parse(JSON.stringify(this.answerContent.answerText));

        errorList = this.jsonData.error_detail.subtitle.matches;
        errorList=errorList.filter(x=> this.filterErrorArray.some(y=> y.category === x.category && y.isSelected));
        if(errorList.length > 0){
            let lastErrorFinishIndex = 0;
            errorList = errorList.sort((a, b) => a.error_start_index - b.error_start_index);
            errorList.forEach(element => {
                errorListTemp.push({...element, isAdded: false, errorData: []});
            });
            for (let index = 0; index < errorListTemp.length; index++) {
                if(!errorListTemp[index].isAdded){
                    if( lastErrorFinishIndex < errorListTemp[index].error_start_index){
                        emptyString.push(
                            {word:answerText.substring(lastErrorFinishIndex,errorListTemp[index].error_start_index)}
                        );
                    }
                    let resolvedError = this.getErrorWithConflictingErrors(errorListTemp[index],errorListTemp);
                    emptyString.push({word:answerText.substring(errorListTemp[index].error_start_index, errorListTemp[index].error_finish_index)
                        ,class:WringErrorColorEnum[errorListTemp[index].category], isError:true, error: resolvedError})

                    lastErrorFinishIndex = errorListTemp[index].error_finish_index;
                }
            }
            emptyString.push(
                {word:answerText.substring(errorListTemp[errorList.length - 1].error_finish_index)}
            );
            this.erroredDataText=emptyString;

        }else{
            this.erroredDataText=[{word: answerText}];
        }
    }else{
        this.erroredDataText=[{word: answerText}];
    }
  }

  getErrorSwitchButtonArray(){
    let errorList =[];
    let switchArray=[];
    this.isErrorNotFound=false;
    if(this.jsonData ?? false){
        errorList = this.jsonData.error_detail.subtitle.matches;

        if(errorList.length > 0){
            const unique = [...new Set(errorList.map(item => item.category))];
            unique.forEach(x=>{
                switchArray.push({isSelected: true, label: x, color: WringErrorColorEnum[x], category: x, number: errorList.filter(y=> y.category === x).length})
            })
        }else{
            const errorList = this.appInfoService.getWritingErrorListMap();
            errorList.slice(0, 5).forEach(x=>{
                switchArray.push({isSelected: false, label: x.name, color: x.id, category: x.name, number: 0})
            })
            this.isErrorNotFound=true;
        }
    }
    this.filterErrorArray=switchArray;
  }

  errorPopup(word,op,event){
    if(word.isError){
        this.errorPopupData = word.error.map(x=> { return {
            errorCategory: x.category,
            wrongText:x.error,
            description:x.message,
            ruleID:x.ruleId
        }});
        op.toggle(event);
    }
  }

  checkIfQuestionLog(eventType, id) {
    const questionLog = this.behaviourEventLogs.find(item => item.questionID === id);
    if(eventType === EApplicantBehaviourEventType.PageHidden){
      return (questionLog ?? false) ? questionLog.eventTypes.filter(eventType => eventType === EApplicantBehaviourEventType.PageHidden).length : 0;
    }
    if(eventType === EApplicantBehaviourEventType.MouseOut){
      return (questionLog ?? false) ? questionLog.eventTypes.filter(eventType => eventType === EApplicantBehaviourEventType.MouseOut).length : 0;
    }
  }
}

