<div class="flex align-items-center justify-content-center ">
    <div class="flex">
        <ng-template ngFor let-item [ngForOf]="starItem" let-i="index">
            <img (click)="starClicked(item.id)" class="custom-cursor"
            [ngClass]="item.class" (mouseleave)="resetColorOfStars()" (mouseenter)="changeColorOfStars(item.id)" [src]="item.src"
            [height]="height" [width]="width" />
        </ng-template>


    </div>
</div>

