<p-sidebar [(visible)]="sidebarVisible" (onHide)="hideSidebarEmit()" position="right" [showCloseIcon]="false" class = "px-2">

    <div class="col-12 flex pl-0 pb-0">
        <div class="col-6 flex pl-0 pt-1 gap-3">
            <p-button class="back-button" icon="pi pi-arrow-left"></p-button>
            <p-button class="next-button">{{"Key.SaveProfile" | translate}}</p-button>
        </div>
    </div>
    <p class="text-gray-900 font-bold text-4xl changeScoreText mt-5">{{"Key.VinterScore" | translate}}</p>
    <div class="flex flex-column justify-content-center align-items-center border-1 border-solid border-gray-50 border-round-lg pt-5 pb-2 changeScoreEditorStyle">
        <p class="text-blue-500 font-bold text-2xl changeScoreText flex justify-content-center">{{"Key.ChangeScoreText" | translate}}</p>
        <div class="card flex justify-content-center border-round-lg align-items-center bg-white px-5 pb-5">
            <p-slider [(ngModel)]="value" styleClass="w-25rem h-1rem" class=""/>
            <p class="text-lg font-bold text-gray-900 ml-2">{{value}}</p>
        </div>
        <p class="text-blue-400 custom-label" (click)="showEditor()" *ngIf="isEditorHidden">{{'Key.AddNoteEditor' | translate}}</p>
        <div class="col-12 md:col-6 border-gray-50 pl-4 mt-6" *ngIf="!isEditorHidden">
            <label class="text-blue-400 custom-label">{{'Key.AddNoteEditor' | translate}}</label>
            <p-editor #editor [(ngModel)]="content" [style]="{ height: '150px', 'background-color': 'var(--gray-50)' }">
                <ng-template pTemplate="header">
                    <span class="ql-formats">
                        <button type="button" class="ql-bold" aria-label="Bold"></button>
                        <button type="button" class="ql-italic" aria-label="Italic"></button>
                        <button type="button" class="ql-underline" aria-label="Underline"></button>
                    </span>
                </ng-template>
            </p-editor>
            <!-- <ng-container *ngFor="let attribute of attributeList">
                <p-chip styleClass="p-1 py-2 m-1 cursor-pointer" (click)="addTextToEditor(attribute.code)">
                    <span class="ml-2 text-sm font-semibold">{{ attribute.name }}</span>
                    <span class="flex align-items-center justify-content-center ml-4">
                        <img src="../../../../../assets/vinter/icons/add-attribute.svg" alt="add-attirbute">
                    </span>
                </p-chip>
            </ng-container> -->
        </div>
    </div>
    <p class="text-blue-500 changeScoreText font-medium mt-4">{{"Key.History" | translate}}</p>
    <div class="flex flex-column justify-content-center align-items-center border-1 border-solid border-gray-50 border-round-lg hrCommentBox">
        <div class="flex justify-content-end align-items-center w-full mt-3 mr-5 mb-1">
            <p class="text-blue-900 changeScoreText font-medium m-0 mr-2">
                {{"Key.Sort" | translate}}
            </p>
            <img src="assets/vinter/icons/sort-arrow.svg" width="18px" height="18px" />
        </div>
        <div class="flex flex-column justify-content-center align-items-center border-1 border-solid border-gray-50 border-round-lg bg-white hrComment mb-3">
            <div class = "flex justify-content-start w-full">
                <div class="col-1 w-min p-0 ml-3 my-3">
                    <p-avatar *ngIf="!(imageUrl ?? false)" class="responsibleAvatar-blue" shape="circle"
                        styleClass="w-6rem h-6rem border-4 border-blue-200 ">

                        <span class="text-5xl text-blue-900 font-semibold">{{firstName[0]?.toUpperCase() +
                            lastName[0]?.toUpperCase()}}</span>
                    </p-avatar>
                    <p-avatar *ngIf="imageUrl ?? false" class="responsibleAvatar-blue ml-2"
                        [image]="imageUrl" shape="circle"
                        styleClass="w-6rem h-6rem border-4 border-blue-200">
                    </p-avatar>
                </div>
                <div class = "mt-4 ml-2">
                    <p class="text-gray-600 font-medium m-0 text-lg">
                        {{firstName + lastName}}
                    </p>
                    <p class="text-gray-300 m-0 text-sm">
                        {{"Key.HumanResources" | translate}}
                    </p>
                </div>
            </div>
            <div class = "flex justify-content-start ml-6 w-full items-center">
                <p class="text-gray-800 mr-4">
                    {{"Key.Score" | translate}}
                </p>
                <p class="text-gray-800 font-bold">{{"Key.score1" | translate}}</p>
                <img src="assets/vinter/icons/lines.svg" width="50px" height="25px" class="px-2"/>
                <p class="text-blue-500 font-bold">{{"Key.score2" | translate}}</p>
            </div>
            <p class="flex justify-content-start w-full text-gray-800 mt-2 ml-6">{{"Key.HRComment" | translate}}</p>
            <div class="flex justify-content-start w-full items-center pl-3 pr-3 pb-3">
                <img src="assets/vinter/icons/history-clock.svg" width="18px" height="18px" />
                <p class="text-gray-300 text-sm ml-1">{{"Key.lastHistory" | translate}}</p>
            </div>
        </div>
    </div>

</p-sidebar>