<div class="question-card flex col p-2">
    <div class="grid m-0 p-2" [ngClass]="columnCount > 16 ? 'col-6' : columnCount > 6 ? 'col-5' : columnCount <= 5 ? 'col-2' : 'col-2'">
        <div
            class="circle"
            *ngFor="let question of questions"
            [ngClass]="{
                'correct': question === 'correct',
                'incorrect': question === 'incorrect',
                'not-answer': question === 'not-answer'
            }"
        ></div>
    </div>

    <div class="vertical-line mx-2" style="height: 59px;"></div>

    
    <div class="col px-0 flex flex-column justify-content-start ">
        <span class="questions">Questions</span>
        <div class="question-summary flex">
            <span class="correct mr-2">
                <span class="dot-icon green"></span> 
                <span class="mr-1 greenc">{{ correctCount }} </span>
                correct
            </span>
             <span class="incorrect mr-2">
                <span class="dot-icon red"></span>
                <span class="mr-1 redc">{{ incorrectCount }} </span>
                incorrect
            </span>
             <span class="not-answered">
                <span class="dot-icon gray"></span> 
                <span class="mr-1 grayc">{{ notAnswerCount }} </span>
                not answered
            </span>
        </div>
    </div>
</div>
