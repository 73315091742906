<div class="col-12 flex pt-0 pb-0 pl-0">
    <div class="col-6 pt-0 pl-0 pb-0 flex">
        <div class="col-2 pt-0 pr-0 pl-0 question-icon-width flex flex-nowrap mr-4">
            <div class="flex">
                <img class="custom-svg-question-icon align-self-center" src="/assets/vinter/icons/videoQuestion.svg" />
                <img *ngIf="question?.questionCategory === QuestionCategoryType.Speaking" width="30px" height="30px" class="align-self-center ml-2" [src]="SpeakingIconPath" />
            </div>
        </div>
        <div class="col-7 pt-0  pl-0">
            <h5 style="margin-top: 0.6rem">
                {{"Key.QuestionOrder" | translate : {value1: question.order} }}
            </h5>
        </div>
    </div>
</div>
<div [ngSwitch]="question.questionContent.contentURLType">
    <div *ngSwitchCase="!QuestionContentType.Image || !QuestionContentType.Audio || !QuestionContentType.Video">
        <div class="question-container-sideBar-text">
            <div class="question-text-label mb-0" [innerHTML]="question.questionContent.questionText">
                <!-- {{questionText}} -->
            </div>
        </div>
    </div>
    <div *ngSwitchCase="QuestionContentType.Image">
        <div class="question-container-sideBar-text">
            <div class="col-12 p-0 flex">
                <div class="col-6 p-0">
                    <p-image src={{question.questionContent.contentURL}} [imageStyle]="{'width': '100%'}"
                        [preview]="true"></p-image>
                </div>
                <div class="col-6 pt-0 pr-0">
                    <div class="question-text-label mb-0"  [innerHTML]="question.questionContent.questionText"></div>
                    <!-- {{questionText}} -->
                </div>
            </div>
        </div>
    </div>
    <div *ngSwitchCase="QuestionContentType.Audio">
        <div class="question-container-sideBar-text">
            <div class="col-12 p-0 flex">
                <div class="col-6 p-0">
                    <audio src={{question.questionContent.contentURL}} style="width: 100%;" controls
                        controlslist="nodownload"></audio>
                </div>
                <div class="col-6 pt-0 pr-0">
                    <div class="question-text-label mb-0"  [innerHTML]="question.questionContent.questionText"></div>
                </div>
            </div>
        </div>
    </div>
    <div *ngSwitchCase="QuestionContentType.Video">
        <div class="question-container-sideBar-text">
            <div class="col-12 p-0 flex">
                <div class="col-6 p-0">
                    <video src={{question.questionContent.contentURL}} style="border-radius:1rem;width: 100%;"
                        controls controlslist="nodownload" disablepictureinpicture></video>
                </div>
                <div class="col-6 pt-0 pr-0">
                    <div class="question-text-label mb-0"  [innerHTML]="question.questionContent.questionText"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="answer-text">
    <div class="flex">
        <img src="assets/vinter/icons/text-questioncoktan-secmeli-cevap-icon-arrow-back-up.svg">
        <h5 class="text-justify answer-title text-lg ml-2 m-3 h5-font">
            {{"Key.Answer" | translate}}
        </h5>
    </div>
</div>
<ng-container *ngIf="answerContent !== null">
    <ng-container [ngSwitch]="question.questionCategory">
        <div *ngSwitchCase="QuestionCategoryType.Speaking" class="answer-container">
            <div class="grid flex flex-wrap p-4">
                <div class="custom-col-6 flex height-fit">
                    <div>
                        <ng-container *ngIf="answerContent ?? false">
                            <vg-player *ngIf="answerContent.contentURL ?? false" style="border-radius: 15px; background-color:white">
                                <video #speakingVideo [vgMedia]="$any(speakingMedia)" #speakingMedia id="speakingVideo" preload="auto"
                                    controls (timeupdate)="highlightSpeaking($event.target)">
                                    <source [src]="answerContent.contentURL" type="video/webm">
                                    <source [src]="answerContent.contentURL" type="video/mp4">
                                </video>
                            </vg-player>
                        </ng-container>
                        <div *ngIf="answerContent === null" class="no-questions-message">
                            {{"Key.NoAnswer" | translate}}
                        </div>
                    </div>
                </div>
                <div class="custom-col-6 flex flex-column pr-0">
                    <p-tabView>
                        <p-tabPanel header="{{'Key.Transcript' | translate}}" class="flex justify-content-center">
                            <div *ngIf="answerContent?.gptEvalStatus !== GPTEvalStatusEnum.Succesful" class="w-9 w-h-min p-5 bg-white flex align-items-center justify-content-center flex-wrap">
                                <img [src]="AICommentPathIcon" width="25px" height="25px">
                                <span class="text-gray-700 font-bold text-center pt-2">
                                    <div [ngSwitch]="answerContent.gptEvalStatus">
                                        <div *ngSwitchCase="GPTEvalStatusEnum.ClientFail">
                                            {{answerContent.aiFinalLog}}
                                        </div>
                                        <div *ngSwitchCase="GPTEvalStatusEnum.Fail">
                                            {{"Key.AIFail" | translate}}
                                        </div>
                                        <div *ngSwitchCase="GPTEvalStatusEnum.Waiting">
                                            {{"Key.WaitForAIComment" | translate}}
                                        </div>
                                        <div *ngSwitchDefault>
                                            {{'Key.ErrorNotFound' | translate}}
                                        </div>
                                    </div>
                                </span>
                            </div>
                            <div *ngIf="answerContent.gptEvalStatus === GPTEvalStatusEnum.Succesful" class="text-sm text-gray-400 line-height-4">
                                {{answerContent.transcriptText}}
                            </div>
                        </p-tabPanel>
                        <!-- <p-tabPanel header="{{'Key.VinterComment' | translate}}" class="flex justify-content-center">
                            <div *ngIf="answerContent.gptEvalStatus !== GPTEvalStatusEnum.Succesful" class="w-9 w-h-min p-5 bg-white flex align-items-center justify-content-center flex-wrap">
                                <img [src]="AICommentPathIcon" width="25px" height="25px">
                                <span class="text-gray-700 font-bold text-center pt-2">
                                    <div [ngSwitch]="answerContent.gptEvalStatus">
                                        <div *ngSwitchCase="GPTEvalStatusEnum.ClientFail">
                                            {{answerContent.aiFinalLog}}
                                        </div>
                                        <div *ngSwitchCase="GPTEvalStatusEnum.Fail">
                                            {{"Key.AIFail" | translate}}
                                        </div>
                                        <div *ngSwitchCase="GPTEvalStatusEnum.Waiting">
                                            {{"Key.WaitForAIComment" | translate}}
                                        </div>
                                        <div *ngSwitchDefault>
                                            {{'Key.ErrorNotFound' | translate}}
                                        </div>
                                    </div>
                                </span>
                            </div>
                            <div *ngIf="answerContent.gptEvalStatus === GPTEvalStatusEnum.Succesful" class="text-sm text-gray-400 line-height-4">
                                {{answerContent.gptEvalText}}
                            </div>
                        </p-tabPanel> -->
                    </p-tabView>
                </div>
            </div>
            <div class="mt-3 flex" *ngIf="question.questionPageHiddenLog >0">
                <img src="assets/vinter/icons/cheating-icon.svg">
                <p class="ml-2" style="color: #F2A105;">{{"Key.SwitchTab" | translate: {value: question.questionPageHiddenLog} }}</p>
            </div>
            <div *ngIf="answerContent.isFaceDetected" class="mt-2 flex-nowrap flex">
                <img src="assets/vinter/icons/face-detection-icon.svg" width="18px" height="18px">
                <p class="ml-2 -mt-1 font-medium" style="color: var(--danger-main, #F20505);">{{"Key.FaceDetected" | translate }}</p>
            </div>
            <div *ngIf="answerContent.isMultiFaceDetected" class="mt-2 -mb-2 flex-nowrap flex">
                <img src="assets/vinter/icons/multiface-detected-icon.svg" width="18px" height="18px">
                <p class="ml-2 -mt-1 font-medium" style="color: #F28505">{{"Key.MultiFaceDetected" | translate }}</p>
            </div>
            <div class="grid flex flex-wrap p-4">
                <div class="custom-col-6 flex" style="flex-direction: column;">
                    <div class="custom-col-6 flex flex-column pr-0 align-items-center justify-content-center w-full">
                        <div *ngIf="answerContent.gptEvalStatus === GPTEvalStatusEnum.Succesful" class="w-full" style="display: flex;padding: 0;" class="col-12 pr-3">
                            <ng-container  *ngIf="this.answerContent.speechAnalysis != null && this.answerContent.speechAnalysis.finalScore != null">
                                <div class="col-6 text-gray-700 font-semibold px-0 text-sm flex align-items-center">
                                    {{"Key.TotalScore" | translate}}
                                </div>
                                <div class="col-5 justify-content-center pl-2" style="padding-left: 0;">
                                    <p-progressBar class="total speakingAnalysisProgressBar" [showValue]="false" [value]="this.answerContent.speechAnalysis.finalScore"></p-progressBar>
                                </div>
                                <div class="col-1 emotion-value pl-0 pr-0 font-semibold text-xs" style="color:#2D4ADE;">
                                    %{{this.answerContent.speechAnalysis.finalScore.toFixed(2)}}
                                </div>
                            </ng-container>
                        </div>
                        <div *ngIf="answerContent.gptEvalStatus === GPTEvalStatusEnum.Succesful" style="padding: 0;" class="col-12">
                            <p-accordion [multiple]="true" iconPos="start">
                                <p-accordionTab>
                                    <ng-template pTemplate="header">
                                        <div class="w-full" style="display: flex;padding: 0;"  *ngIf="this.answerContent.speechAnalysis != null && this.answerContent.speechAnalysis.vocabAccRangeScore != null">
                                            <div class="col-6 text-gray-600 font-medium p-0 text-xs flex align-items-center">
                                                {{"Key.VocabularyAccuracyScore" | translate}}
                                                <img class="rating-info ml-2" src="assets/vinter/icons/info-icon.svg" width="15px" height="15px" pTooltip="{{'Key.VocabAccInfo' | translate}}" tooltipPosition="top"/>
                                            </div>
                                            <div class="col-5 justify-content-center" style="padding:0;padding-right: 1rem">
                                                <p-progressBar class="speakingAnalysisProgressBar" [showValue]="false" [value]="this.answerContent.speechAnalysis.vocabAccRangeScore"></p-progressBar>
                                            </div>
                                            <div class="col-1 emotion-value font-semibold text-xs" style="color:#7489F6;padding:0">
                                                %{{this.answerContent.speechAnalysis.vocabAccRangeScore.toFixed(2)}}
                                            </div>
                                        </div>
                                    </ng-template>
                                    <p class="m-0">
                                        {{vocabAccEval}}
                                    </p>
                                </p-accordionTab>
                                <p-accordionTab>
                                    <ng-template pTemplate="header">
                                        <div class="w-full" style="display: flex;padding: 0;"  *ngIf="this.answerContent.speechAnalysis != null && this.answerContent.speechAnalysis.grammarAccRangeScore != null">
                                            <div class="col-6 text-gray-600 font-medium p-0 text-xs flex align-items-center">
                                                {{"Key.GrammarScore" | translate}}
                                                <img class="rating-info ml-2" src="assets/vinter/icons/info-icon.svg" width="15px" height="15px" pTooltip="{{'Key.GrammarAccInfo' | translate}}" tooltipPosition="top"/>
                                            </div>
                                            <div class="col-5 justify-content-center" style="padding:0;padding-right: 1rem">
                                                <p-progressBar class="speakingAnalysisProgressBar" [showValue]="false" [value]="this.answerContent.speechAnalysis.grammarAccRangeScore"></p-progressBar>
                                            </div>
                                            <div class="col-1 emotion-value font-semibold text-xs" style="color:#7489F6;padding:0">
                                                %{{this.answerContent.speechAnalysis.grammarAccRangeScore.toFixed(2)}}
                                            </div>
                                        </div>
                                    </ng-template>
                                    <p class="m-0">
                                        {{grammarEval}}
                                    </p>
                                </p-accordionTab>
                                <p-accordionTab>
                                    <ng-template pTemplate="header">
                                        <div class="w-full" style="display: flex;padding: 0;"  *ngIf="this.answerContent.speechAnalysis != null && this.answerContent.speechAnalysis.discourseOrganizationScore != null">
                                            <div class="col-6 text-gray-600 font-medium p-0 text-xs flex align-items-center">
                                                {{"Key.DiscourseOrganizationScore" | translate}}
                                                <img class="rating-info ml-2" src="assets/vinter/icons/info-icon.svg"  width="15px" height="15px" pTooltip="{{'Key.DiscourseOrganizationInfo' | translate}}" tooltipPosition="top"/>
                                            </div>
                                            <div class="col-5 justify-content-center" style="padding:0;padding-right: 1rem">
                                                <p-progressBar class="speakingAnalysisProgressBar" [showValue]="false" [value]="this.answerContent.speechAnalysis.discourseOrganizationScore"></p-progressBar>
                                            </div>
                                            <div class="col-1 emotion-value font-semibold text-xs" style="color:#7489F6;padding:0">
                                                %{{this.answerContent.speechAnalysis.discourseOrganizationScore.toFixed(2)}}
                                            </div>
                                        </div>
                                    </ng-template>
                                    <p class="m-0">
                                        {{discourseOrgEval}}
                                    </p>
                                </p-accordionTab>
                                <p-accordionTab>
                                    <ng-template pTemplate="header">
                                        <div class="w-full" style="display: flex;padding: 0;"  *ngIf="this.answerContent.speechAnalysis != null && this.answerContent.speechAnalysis.fluencyScore != null">
                                            <div class="col-6 text-gray-600 font-medium p-0 text-xs flex align-items-center">
                                                {{"Key.FluencyScore" | translate}}
                                                <img class="rating-info ml-2" src="assets/vinter/icons/info-icon.svg" width="15px" height="15px" pTooltip="{{'Key.FluencyInfo' | translate}}" tooltipPosition="top"/>
                                            </div>
                                            <div class="col-5 justify-content-center" style="padding:0;padding-right: 1rem">
                                                <p-progressBar class="speakingAnalysisProgressBar" [showValue]="false" [value]="this.answerContent.speechAnalysis.fluencyScore"></p-progressBar>
                                            </div>
                                            <div class="col-1 emotion-value font-semibold text-xs" style="color:#7489F6;padding:0">
                                                %{{this.answerContent.speechAnalysis.fluencyScore.toFixed(2)}}
                                            </div>
                                        </div>
                                    </ng-template>
                                    <p class="m-0">
                                        {{fluencyEval}}
                                    </p>
                                </p-accordionTab>
                                <p-accordionTab>
                                    <ng-template pTemplate="header">
                                        <div class="w-full" style="display: flex;padding: 0;"  *ngIf="this.answerContent.speechAnalysis != null && this.answerContent.speechAnalysis.pronunciationScore != null">
                                            <div class="col-6 text-gray-600 font-medium p-0 text-xs min-w-max flex align-items-center">
                                                {{"Key.PronunciationScore" | translate}}
                                                <img class="rating-info ml-2" src="assets/vinter/icons/info-icon.svg" width="15px" height="15px" pTooltip="{{'Key.PronunciationInfo' | translate}}" tooltipPosition="top"/>
                                            </div>
                                            <div class="col-5 justify-content-center" style="padding:0;padding-right: 1rem">
                                                <p-progressBar class="speakingAnalysisProgressBar" [showValue]="false" [value]="this.answerContent.speechAnalysis.pronunciationScore"></p-progressBar>
                                            </div>
                                            <div class="col-1 emotion-value font-semibold text-xs" style="color:#7489F6;padding:0">
                                                %{{this.answerContent.speechAnalysis.pronunciationScore.toFixed(2)}}
                                            </div>
                                        </div>
                                    </ng-template>
                                    <p class="m-0">
                                        {{pronunciationEval}}
                                    </p>
                                </p-accordionTab>
                            </p-accordion>
                        </div>
                    </div>
                    <div *ngIf="answerContent.gptEvalStatus === GPTEvalStatusEnum.ClientFail">
                        <div class="client-fail-container">
                            <div class="grid">
                                <div class="col-12 pb-0 flex justify-content-center">
                                    <img src="../../../../../assets/vinter/icons/text-grammar-error-regular.svg" class="w-2rem pt-3" >
                                </div>

                                <div class="col-12 flex justify-content-center answer-text-label text-center text-base font-medium">
                                    {{ answerContent.aiFinalLog }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
<div class="ml-5 flex" *ngIf="answerContent === null">
    <img src="assets/vinter/icons/not-answered.svg">
    <p class="ml-2" style="color: #686E75;">{{"Key.ThisTypeQuestionNotAnswered" | translate}}</p>
</div>
