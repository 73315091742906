import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EQuestionCollectionSubCategory } from '@app/shared/common/enums';
import { StepPerTestResultListItem } from '@app/vinter/models/Step-per-test-result-list-item';
import { ApplicantBehaviourEventLogService } from '@app/vinter/services/applicant-behaviour-event-log.service';

@Component({
  selector: 'app-applicant-step-personality-ocean',
  templateUrl: './applicant-step-personality-ocean.component.html',
  styleUrls: ['./applicant-step-personality-ocean.component.scss']
})
export class ApplicantStepPersonalityOceanComponent {
  OceanScoreSideBar: boolean = false;
  progressBarOceanScoreO: number;
  progressBarOceanScoreC: number;
  progressBarOceanScoreE: number;
  progressBarOceanScoreA: number;
  progressBarOceanScoreN: number;
  applicationStepId: string;
  stepBehaviourEventLogs = [];
  cheatingQuestionLog: any[];
  behaviourEventLogs = [];
  OCEAN120: number = EQuestionCollectionSubCategory.OCEAN120;
  OCEAN50: number = EQuestionCollectionSubCategory.OCEAN50;

  @Input() item: any;
  @Input() applicantID: string;
  @Input() oceanScore: StepPerTestResultListItem = new StepPerTestResultListItem();

  constructor(
    private eventLogService: ApplicantBehaviourEventLogService,
  ) { }


  ngOnInit(): void {

    this.applicationStepId = this.item.id;
    this.getApplicantBehaviourEventLog(this.applicationStepId);
    if(this.item.positionStep.questionCollection.subCategory === this.OCEAN50){
        this.oceanScore.o = this.oceanScore.o * 2.5;
        this.oceanScore.c = this.oceanScore.c * 2.5;
        this.oceanScore.e = this.oceanScore.e * 2.5;
        this.oceanScore.a = this.oceanScore.a * 2.5;
        this.oceanScore.n = this.oceanScore.n * 2.5;
    }

    this.oceanScore.o = Math.round(this.oceanScore.o);
    this.oceanScore.c = Math.round(this.oceanScore.c);
    this.oceanScore.e = Math.round(this.oceanScore.e);
    this.oceanScore.a = Math.round(this.oceanScore.a);
    this.oceanScore.n = Math.round(this.oceanScore.n);
    if(this.item.positionStep.questionCollection.subCategory === this.OCEAN120){
        this.oceanScore.achievementStriving = Math.round(this.oceanScore.achievementStriving);
        this.oceanScore.activityLevel = Math.round(this.oceanScore.activityLevel);
        this.oceanScore.adventurousness = Math.round(this.oceanScore.adventurousness);
        this.oceanScore.altruism = Math.round(this.oceanScore.altruism);
        this.oceanScore.anger = Math.round(this.oceanScore.anger);
        this.oceanScore.anxiety = Math.round(this.oceanScore.anxiety);
        this.oceanScore.artisticInterests = Math.round(this.oceanScore.artisticInterests);
        this.oceanScore.assertiveness = Math.round(this.oceanScore.assertiveness);
        this.oceanScore.cautiousness = Math.round(this.oceanScore.cautiousness);
        this.oceanScore.cheerfulness = Math.round(this.oceanScore.cheerfulness);
        this.oceanScore.cooperation = Math.round(this.oceanScore.cooperation);
        this.oceanScore.depression = Math.round(this.oceanScore.depression);
        this.oceanScore.dutifulness = Math.round(this.oceanScore.dutifulness);
        this.oceanScore.emotionality = Math.round(this.oceanScore.emotionality);
        this.oceanScore.excitementSeeking = Math.round(this.oceanScore.excitementSeeking);
        this.oceanScore.friendliness = Math.round(this.oceanScore.friendliness);
        this.oceanScore.gregariousness = Math.round(this.oceanScore.gregariousness);
        this.oceanScore.imagination = Math.round(this.oceanScore.imagination);
        this.oceanScore.immoderation = Math.round(this.oceanScore.immoderation);
        this.oceanScore.intellect = Math.round(this.oceanScore.intellect);
        this.oceanScore.liberalism = Math.round(this.oceanScore.liberalism);
        this.oceanScore.modesty = Math.round(this.oceanScore.modesty);
        this.oceanScore.morality = Math.round(this.oceanScore.morality);
        this.oceanScore.orderliness = Math.round(this.oceanScore.orderliness);
        this.oceanScore.selfConsciousness = Math.round(this.oceanScore.selfConsciousness);
        this.oceanScore.selfDiscipline = Math.round(this.oceanScore.selfDiscipline);
        this.oceanScore.selfEfficacy = Math.round(this.oceanScore.selfEfficacy);
        this.oceanScore.sympathy = Math.round(this.oceanScore.sympathy);
        this.oceanScore.trust = Math.round(this.oceanScore.trust);
        this.oceanScore.vulnerability = Math.round(this.oceanScore.vulnerability);
    }
    this.progressBarOceanScoreO = this.oceanScore.o * 5;
    this.progressBarOceanScoreC = this.oceanScore.c * 5;
    this.progressBarOceanScoreE = this.oceanScore.e * 5;
    this.progressBarOceanScoreA = this.oceanScore.a * 5;
    this.progressBarOceanScoreN = this.oceanScore.n * 5;
  }

  getApplicantBehaviourEventLog(applicantStepId) {
    this.stepBehaviourEventLogs = [];
    let eventLogFilter: any = {};
    eventLogFilter.applicationStepId = applicantStepId;
    this.eventLogService.getListByFilter(eventLogFilter).subscribe((logResult) => {
      this.stepBehaviourEventLogs = logResult;

      let questionCollectionRelation = this.item.positionStep.questionCollection.questionCollectionQuestionRelations;
      this.cheatingQuestionLog = questionCollectionRelation.filter(item1 => this.stepBehaviourEventLogs.some(item2 => item1.questionID === item2.questionId));
    });
  }
}
