import { Component, EventEmitter, Input, Output } from '@angular/core';
import { QuestionExpectedAnswerType } from '@app/shared/common/enums';

@Component({
  selector: 'app-general-application-answer-sidebar',
  templateUrl: './general-application-answer-sidebar.component.html',
  styleUrls: ['./general-application-answer-sidebar.component.scss']
})
export class GeneralApplicationAnswerSidebarComponent {

  @Input() generalApplicationForm: any;
  @Input() selectedQuestionId: any;

  sidebarVisible = true;
  @Output() hideSidebar: EventEmitter<any> = new EventEmitter<any>();

  questionText : any;
  answerText : any;
  questionType : any;
  order : any;
  QuestionExpectedAnswerType = QuestionExpectedAnswerType;
  choices : any [];
  selectedAnswer: any;
  selectedRadioValue: any;
  questionAnswered : any;
  questionId : any;




  ngOnInit(){
    this.getAnswerByQuestionId(null);
  }

  getAnswerByQuestionId(questionOrderChangeIndex){

    let questionData = null;
    if(questionOrderChangeIndex ?? false){
      const filteredQuestion = this.generalApplicationForm.questions.find(x=> x.order === questionOrderChangeIndex);
      if(filteredQuestion ?? false){
          questionData = this.generalApplicationForm.questions.find(x=> x.order === questionOrderChangeIndex);
      }
  }
    let question = (questionData ?? false) ? questionData : this.generalApplicationForm.questions.find(x => x.id === this.selectedQuestionId) ;
    this.questionId = question.id;
    this.questionText = question.questionText;
    this.questionType = question.type;
    this.order = question.order;
    this.questionAnswered = question.isAnswered;

    let answer = this.generalApplicationForm.answers.find(x => x.questionId === question.id);
    if(this.questionType === this.QuestionExpectedAnswerType.OpenEnded){
      this.answerText = answer?.answerText;
    }
    else{
      this.choices = question.choices;
      this.selectedAnswer = answer?.choiceId;
    }
  }

  hideSidebarEmit(){
    this.hideSidebar.emit(true);
  }

  isCorrectAnswer(questionChoices, answer){
    return questionChoices.find(x => x.id === answer).isCorrect;
  }

}
