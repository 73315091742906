import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApplicantDetailComponent } from './applicant-detail/applicant-detail.component';
import { AuthenticationGuard, CustomHttpInterceptor } from '@app/core';
import { ApplicantDetailV2Component } from './applicant-detail-v2/applicant-detail-v2.component';
import { EncryptedAuthenticationGuard } from '@app/core/authentication/encrypted-authentication.guard';
import { OrAuthenticationGuard } from '@app/core/authentication/or-authenticate.guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomCryptedHttpInterceptor } from '@app/core/http/custom-crypted-http.interceptor';

const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forChild([
    { path: 'detail', component: ApplicantDetailV2Component}
  ])],
  exports: [RouterModule]
})
export class ApplicantRoutingModule { }
