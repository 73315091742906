import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { VinterScoreCardComponent } from '@app/shared/components/vinter-score-card/vinter-score-card.component';
import { TooltipModule } from 'primeng/tooltip';
@NgModule({
  declarations: [
    VinterScoreCardComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ButtonModule,
    TooltipModule
  ],
  exports: [
    VinterScoreCardComponent
    ]
})
export class SharedVinterScoreCardModule { }
