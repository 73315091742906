import { Component, Input, OnInit } from '@angular/core';
import { EQuestionContentURLType, QuestionExpectedAnswerType } from '@app/shared/common/enums';
import { VgApiService } from '@videogular/ngx-videogular/core';

@Component({
  selector: 'app-applicant-answer-sidebar-question-area-language',
  templateUrl: './answer-sidebar-question-area.component.html',
  styleUrls: ['./answer-sidebar-question-area.component.scss']
})
export class ApplicantAnswerSidebarQuestionAreaLanguage implements OnInit{

    QuestionExpectedAnswerType = QuestionExpectedAnswerType;
    @Input() question: any;
    @Input() questionKeywords: any;
    @Input() behaviourEventLogs: any[]=[];
    QuestionContentType= EQuestionContentURLType;
    selectedAnswerChoice: any;
    selectedAnswer:string;
    selectedRadioValue: any;
  constructor(

    public api: VgApiService) { }

  ngOnInit(): void {
  }

}
