<p-dialog [visible]="true" [draggable]="false" [modal]="true" showEffect="fade" [style]="{width: '25vw'}"
    [breakpoints]="{'960px': '75vw'}" class="dialogPage" [closable]="false">
    <div class="grid">
        <div class="col-12" style="display: flex;justify-content: center;">
            <img style="width: 4rem;" src="../../../../../assets/vinter/icons/warning-icon.svg">
        </div>
        <div class="col-12 complete-dialog-header p-0 mb-4">
            {{'Key.NoDepartmentHeader' | translate}}
        </div>
        <div class="col-12 complete-dialog-text pt-0">
            {{'Key.NoDepartmentAlert' | translate}}
        </div>
        <div class="col-12 complete-dialog-header">
            <div class="col-6 flex justify-content-center">
                <p-button label="{{'Key.Ok' | translate}}" (onClick)="closeDialog()"
                    styleClass="mr-2 mb-2 p-button-outlined min-w-max mr-4"></p-button>
                <p-button label="{{'Key.GoToSettings' | translate}}" (onClick)="goToSettings()"
                    styleClass="mr-2 mb-2 p-button-primary min-w-max"></p-button>
            </div>
        </div>
    </div>
</p-dialog>
