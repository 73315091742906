<div *ngIf="!isGeneralEnglishTest" class="grid flex languageStepAvgScoreDiv pt-2">
    <div class="md:col sm:col-4 col-6 languageStepAvgScoreElementDiv border writingBorderColor">
        <img src="assets/vinter/icons/pdfReport/writingCategoryColor.svg" width="28px" height="28px">
        <div class="progress">
            <div class="progress-bar" [style.width]="writingScore + '%'"
                [style.backgroundColor]="'#2D4ADE'"></div>
        </div>
        <div class="writeTextColor w-5rem font-semibold">
            % {{writingScore.toFixed(0)}}
        </div>
        
    </div>

    <div class="md:col sm:col-4 col languageStepAvgScoreElementDiv border listeningBorderColor">
        <img src="assets/vinter/icons/pdfReport/listeningCategoryColor.svg" width="28px" height="28px">
        <div class="progress">
            <div class="progress-bar" [style.width]="listeningScore + '%'"
                [style.backgroundColor]="'#9B2DDE'"></div>
        </div>
        <div class="listeningTextColor w-5rem font-semibold">
            % {{listeningScore.toFixed(0)}}
        </div>
        
    </div>

    <div class="md:col sm:col col-6 languageStepAvgScoreElementDiv border readingBorderColor" >
        <img src="assets/vinter/icons/pdfReport/readingCategoryColor.svg" width="28px" height="28px">
        <div class="progress">
            <div class="progress-bar" [style.width]="readingScore + '%'"
                [style.backgroundColor]="'#6931DF'"></div>
        </div>
        <div class="readingTextColor w-5rem font-semibold">
            % {{readingScore.toFixed(0)}}
        </div>
        
    </div>

    <div class="md:col sm:col-6 col languageStepAvgScoreElementDiv border grammerBorderColor">
        <img src="assets/vinter/icons/pdfReport/grammerCategoryColor.svg" width="28px" height="28px">
        <div class="progress">
            <div class="progress-bar" [style.width]="grammerScore + '%'"
                [style.backgroundColor]="'#2E75FF'"></div>
        </div>
        <div class="grammaryTextColor w-5rem font-semibold" >
            % {{grammerScore.toFixed(0)}}
        </div>
        
    </div>

    <div class="md:col sm:col col languageStepAvgScoreElementDiv border vocabularyBorderColor">
        <img src="assets/vinter/icons/pdfReport/vocabularyCategoryColor.svg" width="28px" height="28px">
        <div class="progress">
            <div class="progress-bar" [style.width]="vocabularyScore + '%'"
                [style.backgroundColor]="'#7752FE'"></div>
        </div>
        <div class="vocabularyTextColor w-5rem font-semibold">
            % {{vocabularyScore.toFixed(0)}}
        </div>
    </div>
</div>



<div *ngIf="isGeneralEnglishTest" class="grid flex languageStepAvgScoreDiv">

    <div class="lg:col md:col-6 languageStepAvgScoreElementDiv border writeBorderColor">
        <img src="assets/vinter/icons/pdfReport/writingCategoryColor.svg" width="28px" height="28px">
        <div class="progress mx-0">
            <div class="progress-bar" [style.width]="generalEnglishWritingScore + '%'"
                [style.backgroundColor]="'#2D4ADE'"></div>
        </div>
        <div class="min-w-max flex justify-content-between align-items-center mr-2">
            <div *ngIf="item.generalEnglishTestResult?.writingLevel ?? false" class="font-semibold writeTextColor">
                {{item.generalEnglishTestResult?.writingLevel}} /
            </div>
            <div class="font-semibold scoreTextColor">
                 {{generalEnglishWritingScore.toFixed(0)}}
            </div>
        </div>

    </div>

    <div class="lg:col md:col-6 languageStepAvgScoreElementDiv border listeningBorderColor">
        <img src="assets/vinter/icons/pdfReport/listeningCategoryColor.svg" width="28px" height="28px">
        <div class="progress mx-0">
            <div class="progress-bar" [style.width]="generalEnglishListeningScore + '%'"
                [style.backgroundColor]="'#9B2DDE'"></div>
        </div>
        <div class="min-w-max flex justify-content-between align-items-center mr-2">
            <div *ngIf="item.generalEnglishTestResult?.listeningLevel ?? false" class="font-semibold listeningTextColor">
                {{item.generalEnglishTestResult?.listeningLevel}} /
            </div>
            <div class="font-semibold scoreTextColor">
                 {{generalEnglishListeningScore.toFixed(0)}}
            </div>
        </div>

    </div>

    <div class="lg:col md:col-6 languageStepAvgScoreElementDiv border readingBorderColor" >
        <img src="assets/vinter/icons/pdfReport/readingCategoryColor.svg" width="28px" height="28px">
        <div class="progress mx-0">
            <div class="progress-bar" [style.width]="generalEnglishReadingScore + '%'"
                [style.backgroundColor]="'#6931DF'"></div>
        </div>
        <div class="min-w-max flex justify-content-between align-items-center mr-2">
            <div *ngIf="item.generalEnglishTestResult?.readingLevel ?? false" class="font-semibold readingTextColor">
                {{item.generalEnglishTestResult?.readingLevel}} /
            </div>
            <div class="font-semibold scoreTextColor" >
                 {{generalEnglishReadingScore.toFixed(0)}}
            </div>
        </div>

    </div>

    <div class="lg:col md:col-6 languageStepAvgScoreElementDiv border grammaryBorderColor">
        <img src="assets/vinter/icons/pdfReport/grammerCategoryColor.svg" width="28px" height="28px">
        <img src="assets/vinter/icons/pdfReport/vocabularyCategoryColor.svg" width="28px" height="28px" style="margin-left: -0.65rem;">

        <div class="progress mx-0">
            <div class="progress-bar" [style.width]="generalEnglishGrammerVocabularyScore + '%'"
                [style.backgroundColor]="'#2E75FF'"></div>
        </div>
        <div class="min-w-max flex justify-content-between align-items-center mr-2">
            <div *ngIf="item.generalEnglishTestResult?.grammarVocabularyLevel ?? false" class="font-semibold grammaryTextColor">
                {{item.generalEnglishTestResult?.grammarVocabularyLevel}} /
            </div>
            <div class="font-semibold scoreTextColor">
                 {{generalEnglishGrammerVocabularyScore.toFixed(0)}}
            </div>
        </div>
    </div>

    <div *ngIf="checkSpeakingCount > 0" class="lg:col md:col-6 languageStepAvgScoreElementDiv border speakingBorderColor">
        <img src="assets/vinter/icons/speakingCategoryColor.svg" width="28px" height="28px">
        <div class="progress mx-0">
            <div class="progress-bar" [style.width]="generalEnglishSpeakingScore + '%'"
                [style.backgroundColor]="'#D11ABF'"></div>
        </div>
        <div class="min-w-max flex justify-content-between align-items-center mr-2">
            <div *ngIf="item.generalEnglishTestResult?.speakingLevel ?? false" class="font-semibold speakingTextColor">
                {{item.generalEnglishTestResult?.speakingLevel}} /
            </div>
            <div class="font-semibold scoreTextColor">
                 {{generalEnglishSpeakingScore.toFixed(0)}}
            </div>
        </div>

    </div>
</div>
