import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { LocalizationService } from '@app/core';
import { QuestionWeightToolTipIcon } from '@app/shared/common/constants';
import { EQuestionCollectionSubCategory } from '@app/shared/common/enums';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { ApplicantBehaviourEventLogService } from '@app/vinter/services/applicant-behaviour-event-log.service';
import { ApplicationStepService } from '@app/vinter/services/application-step.service';
import { QuestionService } from '@app/vinter/services/question.service';
import { RatingService } from '@app/vinter/services/rating.service';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-applicant-step-score-info-language',
  templateUrl: './applicant-step-score-info-language.component.html',
  styleUrls: ['./applicant-step-score-info-language.component.scss']
})
export class ApplicantStepScoreInfoLanguage implements OnInit{

  @Input() item: any;
  checkedOpenEnded: boolean = false;
  checkedMultipleChoice: boolean = false;
  isGeneralEnglishTest:boolean=false;
  checkedVideo: boolean = false;
  filteredList: any[] = []; // Filtrelenmiş liste için kullanılacak
  originalList: any[] = []; // Filtrelemeden önceki orijinal liste
  questionWeightToolTipIconPath = QuestionWeightToolTipIcon;
  @Output() onFilterItems: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private applicationStepService: ApplicationStepService,
    private eventLogService:ApplicantBehaviourEventLogService,
    private messageService: MessageService,
    private localizationService: LocalizationService,
    private appInfoService: AppInfoService,
    private ratingService: RatingService,
    private questionService: QuestionService,
    public api: VgApiService) { }

  ngOnInit(): void {
    if(this.item?.positionStep?.questionCollection?.subCategory==EQuestionCollectionSubCategory.GeneralEnglish)
      this.isGeneralEnglishTest=true;
    if(this.item.averageRating === null || this.item.averageRating === undefined){
      this.item.averageRating = 0;
    }
    this.originalList = this.item.positionStep.questionCollection.questionCollectionQuestionRelations;
  }


  filterList() {
    this.filteredList = this.originalList.filter(item => {
      if (
        (!this.checkedOpenEnded && !this.checkedMultipleChoice && !this.checkedVideo) ||
        (this.checkedOpenEnded && item.question.expectedAnswerType === 2) ||
        (this.checkedMultipleChoice && item.question.expectedAnswerType === 1) ||
        (this.checkedVideo && item.question.expectedAnswerType === 3)
      ) {
        return true;
      }
    }).sort(x=> x.Order);
    this.onFilterItems.emit(this.filteredList);

  }

getProgressBarColor(score: number): string {
    if (score <= 10) return '#DC2626';
    if (score <= 20) return '#DC2667';
    if (score <= 30) return '#DC2688';
    if (score <= 40) return '#DC269E';
    if (score <= 50) return '#AD26DC';
    if (score <= 60) return '#8126DC';
    if (score <= 70) return '#6026DC';
    if (score <= 80) return '#3F26DC';
    if (score <= 90) return '#1549D6';
    return '#2962FF';
  }

  getWeightAvarageScoreTooltip() {
    let html =
        "<div style='display:flex;flex-direction: column;'>" +
            "<div class='col-12' style='padding-top: 0;padding-left: 0;padding-right: 0;padding-bottom: 0; max-width:25rem;'>" +
                "<div style='color: #ffffff;font-weight: 700; font-size: 1.25rem;'>" + this.localizationService.translatedTextList.HowToCalculateScoreAndRating + "</div>" +
                "<div style='color: var(--blue-100); margin-top: 0.5rem'; display:flex; flex-wrap: wrap;>" + this.localizationService.translatedTextList.ExplanationOfCalculatingScoreAndRating + "</div>" +
            "</div></div>";
    return html;
}

}
