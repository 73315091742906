import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { LocalizationService } from '@app/core';
import { invitationTemplateFilePath } from '@app/shared/common/constants';
import { EntityStateEnum, PositionStepKanbanStaticTypes, UserGroupEnum } from '@app/shared/common/enums';
import { SpinnerHandlerService } from '@app/shared/components/loader/spinner-handler.service';
import { GeneralApplicationInvitationPostItem } from '@app/vinter/models/general-application-invitation-post-item';
import { InvitationPostItem } from '@app/vinter/models/Invitation/invitation-post-item';
import { GeneralApplicationInvitationService } from '@app/vinter/services/general-application-invitation.service';
import { InvitationService } from '@app/vinter/services/invitation.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { read, utils } from 'xlsx';

@Component({
  selector: 'app-invite-applicant-via-file',
  templateUrl: './invite-applicant-via-file.component.html',
  styleUrls: ['./invite-applicant-via-file.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class InviteApplicantViaFileComponent {

  items: any[] = [];
  uploadedFiles: any[] = [];
  generalInvitationPostList: GeneralApplicationInvitationPostItem[] = [];
  invitationPostList: InvitationPostItem[] = [];
  excelResultFromDb: any[] = [];
  userGroupID: string = UserGroupEnum.APPLICANT;
  isFileSelected: boolean = false;
  isDisabled: boolean = true;
  invitationTemplateFilePath: string = invitationTemplateFilePath;

  @Output() callCloseDialog: EventEmitter<any> = new EventEmitter<any>();
  @Input() positionId: string;
  @Input() organizationId: string;
  @ViewChild('fileUpload') fileUpload: FileUpload;
  @Output() sendDataValidation: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendInvite: EventEmitter<any> = new EventEmitter<any>();
  @Output() receiveData: EventEmitter<any> = new EventEmitter<any>();

  isDragDropFile: boolean = false;
  isFileChosen: boolean = false;
  isWarningVisible = false;


  constructor(private spinnerService: SpinnerHandlerService,
    private generalApplicationInvitationService: GeneralApplicationInvitationService,
    private confirmationService: ConfirmationService,
    private localizationService: LocalizationService,
    private messageService: MessageService,
    private invitationService: InvitationService
  ) { }

  handleImport() {
    const files = this.uploadedFiles[0];
    if (files) {
      this.spinnerService.show();
      const file = files;
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          this.items = rows.map(x => { return { name: x[Object.keys(x)[0]], email: x[Object.keys(x)[1]] } });
          this.items.forEach(element => {
            element.positionID = this.positionId;
          });
          this.generalApplicationInvitationService.checkInvitationOnDB(this.items).subscribe(x => {
            this.excelResultFromDb = x;
            this.excelResultFromDb.forEach(x => { x.errorMessage = this.getTranslate(x.errorMessage) });
            if (this.excelResultFromDb.find(x => x.dataValidation)) {
              this.isWarningVisible = true;
              this.isDisabled = false;
            }
            this.isFileChosen = true;
            this.sendDataToParent(true);
          });
        }
        this.spinnerService.hide();
      }
      reader.readAsArrayBuffer(file);
      this.confirmationService.close();
    }
  }

  sendDataToParent(flag) {
    this.sendDataValidation.emit(flag);
  }

  onDragover(event: DragEvent): void {
    this.isDragDropFile = true;
    event.preventDefault();
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    this.onSelect(event.dataTransfer.files[0]);
    this.isDragDropFile = false;
  }

  onSelect(event) {
    this.uploadedFiles = [];
    this.isFileSelected = true;
    if (this.isDragDropFile === true) {
      this.uploadedFiles.push(event);
    }
    else {
      this.uploadedFiles.push(event.target.files[0]);
    }

  }
  deleteCard(index) {
    this.excelResultFromDb.splice(index, 1);
  }
  onRemove() {
    this.uploadedFiles = [];
    this.isFileSelected = false;
    this.excelResultFromDb = [];
  }

  setGeneralInvitationPostList() {
    this.excelResultFromDb.forEach(element => {
      if (element.dataValidation) {
        let invitationPostDto: GeneralApplicationInvitationPostItem = {
          entityState: EntityStateEnum.Added,
          email: element.email,
          positionID: this.positionId,
        }
        this.generalInvitationPostList.push(invitationPostDto);
      }
    });
    this.generalApplicationInvitationService.createGeneralApplicationInvitation(this.generalInvitationPostList).subscribe((data) => {
      this.messageService.add({
        severity: 'success',
        summary: this.localizationService.translatedTextList.Successful,
        detail: this.localizationService.translatedTextList.AddSuccess,
        life: 3000
      });
      this.sendInvite.emit(true);
      this.isFileSelected = false;
      this.excelResultFromDb = [];
      this.uploadedFiles = [];
      this.isDisabled = true;
      this.fileUpload.clear();
      this.callCloseDialog.emit(null);
    });
  }

  setInvitationPostList() {
    this.excelResultFromDb.forEach(element => {
      if (element.dataValidation) {
        let invitationPostDto: InvitationPostItem = {
          entityState: EntityStateEnum.Added,
          email: element.email,
          positionID: this.positionId,
          organizationID: this.organizationId,
          userGroupID: this.userGroupID
        }
        this.invitationPostList.push(invitationPostDto);
      }
    });
    this.invitationService.createMultipleApplicantInvitation(this.invitationPostList).subscribe((data) => {
      this.messageService.add({
        severity: 'success',
        summary: this.localizationService.translatedTextList.Successful,
        detail: this.localizationService.translatedTextList.AddSuccess,
        life: 3000
      });
      this.sendInvite.emit(true);
      this.isFileSelected = false;
      this.excelResultFromDb = [];
      this.uploadedFiles = [];
      this.isDisabled = true;
      this.fileUpload.clear();
      this.callCloseDialog.emit(null);
    });
  }

  submit(inviteType:string) {
    if(inviteType == PositionStepKanbanStaticTypes.GeneralApplication){
      this.setGeneralInvitationPostList();
  }
  else if(inviteType == PositionStepKanbanStaticTypes.Invitation){
    this.setInvitationPostList();
  }
  }

  getTranslate(value: string) {
    return this.localizationService.translatedTextList[value];
  }


}
