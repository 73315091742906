
<app-background-mask [maskActive]=backGroundVisible></app-background-mask>
<div class="col-12 grid pb-0 pt-0 pl-0 cheating-title-width" style="width: fit-content; height: 3.5rem;background-color:#f6f7ff;border-radius: 6px;" >

    <div class="col-12 cheating-title flex pr-0">
        <p style="margin-bottom: 0.75rem; font-size: 1.15rem;" class="ml-2 font-semibold mail-text flex align-items-center justify-content-center">{{'Key.BehavioralAnalysis' | translate}}</p>

        <hr class="cheating-hr flex align-items-center justify-content-center" style="margin-top: 0.75rem;">
        

        <div class="flex align-items-center justify-content-center">
            <div *ngIf="item.deviceType !== null">
                <img *ngIf="item.deviceType === DeviceTypeEnum.Desktop" src="assets/vinter/icons/desktop-device-icon.svg" width="18px" height="18px">
                <img *ngIf="item.deviceType === DeviceTypeEnum.Mobile" src="assets/vinter/icons/mobile-device-icon.svg" width="18px" height="18px">
                <img *ngIf="item.deviceType === DeviceTypeEnum.Tablet" src="assets/vinter/icons/tablet-device-icon.svg" width="18px" height="18px">
            </div> 
    
        </div>

        <div class="vertical-line-rating w-auto" style="height: 20px;"></div>

        <div class="flex align-items-center justify-content-center">
    
            <div *ngIf="item.isSwitchedTab">
                <img src="assets/vinter/icons/cheating-icon.svg" width="18px" height="18px">
            </div> 
            <div *ngIf="item.isSwitchedTab" class="vertical-line-rating w-auto" style="height: 20px;"></div>
    
            <div *ngIf="item.isFaceDetectedLog">
                <img src="assets/vinter/icons/face-detection-icon.svg" width="18px" height="18px">
            </div>
            <div *ngIf="item.isFaceDetectedLog" class="vertical-line-rating w-auto" style="height: 20px;"></div>
    
            <div *ngIf="item.isMultipleFaceLog">
                <img src="assets/vinter/icons/multiface-detected-icon.svg" width="18px" height="18px">
            </div>
            <div *ngIf="item.isMultipleFaceLog" class="vertical-line-rating w-auto" style="height: 20px;"></div>

            <div *ngIf="item.isImproprietySpeechLog">
                <img src="assets/vinter/icons/impropriety-speech-warning.svg" width="18px" height="18px">
            </div>   
        </div>

        <div name="showDetail" class="pl-3 flex align-items-center justify-content-center mb-2 cheating-button-margin zindex"  >
            <span  (mouseover)="setBackgroundDarkness(true); op.show($event);" (mouseout)="op.hide(); setBackgroundDarkness(false)"  class="cursor-pointer mr-2 fontcolor" >{{"Key.ShowDetail" | translate}}</span>
        </div>
        
    </div>

</div>

<p-overlayPanel #op class="background-overlay" >

    <div style="width: 28rem;">
        <div class="col-12 grid pb-0 pt-0 w-auto" style="height: 3rem;background-color:#f6f7ff;border-radius: 6px;">
            <span class="font-semibold mt-3" style="color:#002075; font-size: 1rem">{{'Key.CheatingAnalysis' |
                translate}}</span>
        </div>
        <div class="flex justify-content-between flex-wrap my-2">
            <div class="flex align-items-center justify-content-center mail-text" style="color: #686E75;">
                {{'Key.Device' | translate}}
            </div>
            <div class="flex align-items-center justify-content-center font-semibold mail-text">
                <div *ngIf="item.deviceType !== null">
                    <img *ngIf="item.deviceType === DeviceTypeEnum.Desktop" src="assets/vinter/icons/desktop-device-icon.svg" width="18px" height="18px">
                    <img *ngIf="item.deviceType === DeviceTypeEnum.Mobile" src="assets/vinter/icons/mobile-device-icon.svg" width="18px" height="18px">
                    <img *ngIf="item.deviceType === DeviceTypeEnum.Tablet" src="assets/vinter/icons/tablet-device-icon.svg" width="18px" height="18px">
                </div>
            </div>
        </div>
        <hr class="my-1">

        <div class="flex justify-content-between flex-wrap my-2">
            <div class="flex align-items-center justify-content-center mail-text" style="color: #686E75;">
                {{'Key.SamePageUsed' | translate}}
                <img src="assets/vinter/icons/cheating-icon.svg" class="ml-2" width="16px" height="16px">

            </div>
            <div *ngIf="!item.isSwitchedTab" class="flex align-items-center justify-content-center font-semibold mail-text"
                style="color:#37AC00">
                {{'Key.Yes' | translate}}
            </div>
            <div *ngIf="item.isSwitchedTab" class="flex align-items-center justify-content-center font-semibold mail-text"
                style="color:#F20505">
                {{'Key.No' | translate}}
            </div>
        </div>
        <hr class="my-1">

        <div class="flex justify-content-between flex-wrap my-2">
            <div class="flex align-items-center justify-content-center mail-text" style="color: #686E75;">
                {{'Key.FaceDetectedLog' | translate}}
                <img src="assets/vinter/icons/face-detection-icon.svg" class="ml-2" width="16px" height="16px">

            </div>
            <div *ngIf="item.isFaceDetectedLog"
                class="flex align-items-center justify-content-center font-semibold mail-text" style="color:#F20505">
                {{'Key.Yes' | translate}}
            </div>
            <div *ngIf="!item.isFaceDetectedLog"
                class="flex align-items-center justify-content-center font-semibold mail-text" style="color:#37AC00">
                {{'Key.No' | translate}}
            </div>
        </div>
        <hr class="my-1">

        <div class="flex justify-content-between flex-wrap my-2">
            <div class="flex align-items-center justify-content-center mail-text" style="color: #686E75;">
                {{'Key.MultiFaceDetectedLog' | translate}}
                <img src="assets/vinter/icons/multiface-detected-icon.svg" class="ml-2" width="16px" height="16px">

            </div>
            <div *ngIf="item.isMultipleFaceLog"
                class="flex align-items-center justify-content-center font-semibold mail-text" style="color:#F20505">
                {{'Key.Yes' | translate}}
            </div>
            <div *ngIf="!item.isMultipleFaceLog"
                class="flex align-items-center justify-content-center font-semibold mail-text" style="color:#37AC00">
                {{'Key.No' | translate}}
            </div>
        </div>
        <hr class="my-1">

        <div class="flex justify-content-between flex-wrap my-2">
            <div class="flex align-items-center justify-content-center mail-text" style="color: #686E75;">
                {{'Key.LanguageInappropriateness' | translate}}
                <img src="assets/vinter/icons/impropriety-speech-warning.svg" class="ml-2" width="16px" height="16px">

            </div>
            <div *ngIf="item.isImproprietySpeechLog"
                class="flex align-items-center justify-content-center font-semibold mail-text" style="color:#F20505">
                {{'Key.Yes' | translate}}
            </div>
            <div *ngIf="!item.isImproprietySpeechLog"
                class="flex align-items-center justify-content-center font-semibold mail-text" style="color:#37AC00">
                {{'Key.No' | translate}}
            </div>
        </div>

    </div>

</p-overlayPanel>