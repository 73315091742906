<div *ngIf="stepType !== QuestionCollectionCategoryEnum.Pronunciation" class="bar-chart-container">
    <div *ngFor="let item of barData; let i = index" class="bar-item">
        <div class="bar-name">{{ item.name }}</div>
        <div class="flex align-items-center">
            <div class="bar-container">
                <div class="bar" [style.width.%]="item.percentage" [style.background]="item.color"></div>
            </div>
            <div class="bar-percentage" *ngIf="showPercentage" [style.color]="item.color">
                %{{item.percentage.toFixed(0)}}
            </div>
        </div>  
    </div>
</div>

<div *ngIf="stepType === QuestionCollectionCategoryEnum.Pronunciation" class="bar-chart-container">
    <div *ngFor="let item of barData; let i = index" class="bar-item">
        <div class="bar-name">{{ item.name }}</div>
        <div class="flex align-items-center">
            <div class="bar-container-pronun">
                <div class="bar-pronun" [style.width.%]="item.percentage" [style.background]="item.color"></div>
                <div class="dot color" [style.left.%]="item.percentage > 97 ? 97 : item.percentage" [style.border-color]="item.color" ></div>
            </div>
            <div class="bar-percentage" *ngIf="showPercentage" [style.color]="item.color">
                %{{item.percentage.toFixed(0)}}
            </div>
        </div>  
    </div>
</div>