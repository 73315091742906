    <app-video-answer *ngIf="answerType === AnswerTypeEnum.Video && questionCollectionCategoryId !== QuestionCollectionCategoryEnum.ForeignLanguage &&
    questionCollectionCategoryId !== QuestionCollectionCategoryEnum.Pronunciation && videoAnswer !== undefined" [answer]="videoAnswer"></app-video-answer>

    <app-open-ended-answer *ngIf="answerType === AnswerTypeEnum.OpenEnded && questionCollectionCategoryId !== QuestionCollectionCategoryEnum.ForeignLanguage && openEndedAnswer !== undefined" [answer]="openEndedAnswer"></app-open-ended-answer>

    <app-multiple-choice-answer *ngIf="answerType === AnswerTypeEnum.MultipleChoice && multipleChoiceAnswer !== undefined && question" [answer]="multipleChoiceAnswer"
     [question]="question"></app-multiple-choice-answer>

    <app-coding-answer *ngIf="answerType === AnswerTypeEnum.Coding && codingAnswer !== undefined" [answer]="codingAnswer"></app-coding-answer>

    <app-foreign-language-open-ended-answer *ngIf="answerType === AnswerTypeEnum.OpenEnded && questionCollectionCategoryId === QuestionCollectionCategoryEnum.ForeignLanguage &&
     writingAnswer !== undefined" [answer]="writingAnswer"></app-foreign-language-open-ended-answer>

    <app-foreign-language-video-answer *ngIf="answerType === AnswerTypeEnum.Video && questionCollectionCategoryId === QuestionCollectionCategoryEnum.ForeignLanguage && speakingAnswer !== undefined"
     [answer]="speakingAnswer"></app-foreign-language-video-answer>

    <app-pronunciation-video-answer *ngIf="answerType === AnswerTypeEnum.Video && questionCollectionCategoryId === QuestionCollectionCategoryEnum.Pronunciation && pronunciationVideoAnswer !== undefined"
     [answer]="pronunciationVideoAnswer"></app-pronunciation-video-answer>