<p-dialog  [(visible)]="popupOpen" (onHide)="closePopup()" [modal]="true" [baseZIndex]="10000"
[style]="{ width: '35rem'}">
<div  *ngIf="selectedStar !== null && selectedStar !== undefined" class="row flex justify-content-center mb-3">
    <app-vinter-rating [starNumber]="5" (onRate)="rate($event)" [disabled]="false" size="lg" [value]="selectedStar"></app-vinter-rating>
</div>
<div class="rating-comment">
    <span class="p-float-label">
        <textarea rows="5" cols="30" pInputTextarea [(ngModel)]="ratingComment"></textarea>
        <label for="float-input">{{"Key.AddComment" | translate}}</label>
    </span>
</div>
<div class="grid mt-4">
    <div class="col-6">
        <p-button label="{{'Key.CancelRate' | translate}}" styleClass="p-button-secondary"
            (click)="closePopup()"></p-button>
    </div>
    <div class="col-6 text-right">
        <p-button label="{{'Key.Rate' | translate}}" styleClass="p-button-outlined"
            (click)="onRateEnded()"></p-button>
    </div>
</div>
</p-dialog>
