<p-dialog [draggable]="false" [modal]="true" [(visible)]="isShow"
        [style]="{width: '37rem', overflow: 'hidden', borderWidth: '3px', 
        borderColor: 'var(--surface-100)', 
        borderStyle: 'solid',
        borderRadius: '1.5rem'}"[breakpoints]="{'960px': '75vw'}" (onHide)="closeUnsubscriptionDialog()">
  <div class="grid">
  <div class="flex flex-column w-full align-items-center justify-content-center h-full px-5 col-12 md:col-6 lg:col-6">
    <img src="../../../../../../../assets/vinter/icons/unsubscribe-icon.svg" alt="unsubscribe-icon" class="mb-5">
    <div class="custom-title font-bold text-3xl w-full text-center px-4">{{ "Key.SureForUnsubscriptionPopupTitle" | translate }}</div>
    <div class="custom-description text-lg text-center mt-4 px-3 mb-1">{{ "Key.UnsubscriptionWarning" | translate }}</div>
    <div class="text-sm font-medium text-blue-400 text-start w-full mt-4 mb-2 px-3">{{ "Key.UnsubscriptionCause" | translate }}</div>
    <div class="flex flex-wrap w-full px-3 h-full">
        <p-dropdown class="w-full flex" [options]="unsubscriptionReasonList"
        [(ngModel)]="selectedReason" (onChange)="onReasonChange($event)" appendTo="body"
        optionLabel="reason"
        [showClear]="true" [filter]="false" [style]="{'width':'100%', 'height': '100%'}"
        placeholder="{{'Key.UnsubscriptionDropdownPlaceholder' | translate}}">
                            <ng-template let-reason pTemplate="item">
                                <div class="flex align-items-center reason-style">
                                    <div>{{ reason.reason }}</div>
                                </div>
                            </ng-template>
                    </p-dropdown>
    </div>
    <div class="flex justify-content-center gap-4 my-5 w-full px-7">
      <button (click)="unsubscribe()" pButton 
      pRipple 
      class="p-button-danger" [disabled]="!selectedReason"><p class="text-sm w-full text-center">{{'Key.UnsubscribeButton' | translate}}</p></button>  
      <button (click)="closeUnsubscriptionDialog()" pButton 
      pRipple 
      class="p-button-outlined"><p class="text-sm font-medium w-full text-center">{{'Key.CancelSubscription' | translate}}</p></button>
    </div>
  </div>
</div>
</p-dialog>

<p-dialog [draggable]="false" [modal]="true" [(visible)]="isClicked"
        [style]="{width: '37rem', overflow: 'hidden', borderWidth: '3px', 
        borderColor: 'var(--surface-100)', 
        borderStyle: 'solid',
        borderRadius: '1.5rem'}"[breakpoints]="{'960px': '75vw'}" (onHide)="closeSuccessfullPopup()">
  <div class="grid">
    <div class="flex flex-column w-full align-items-center justify-content-center h-full px-6 my-3 col-12 md:col-6 lg:col-6">
      <img src="../../../../../../../assets/vinter/icons/unsubscribe-icon.svg" alt="unsubscribe-icon" class="mb-4">
      <div *ngIf="!isFailed" class="custom-title font-bold text-3xl w-full text-center">{{ "Key.UnsubscriptionSuccessfulTitle" | translate }}</div>
      <div *ngIf="isFailed" class="custom-title font-bold text-3xl w-full text-center">{{ "Key.UnsubscriptionFailedTitle" | translate }}</div>
      <div *ngIf="!isFailed && currentLanguageId === LanguageEnum.EnglishUnitedStates" class="custom-description text-lg text-center mt-4 px-1 mb-5"> You can continue to use your license until <span class="text-blue-400 px-1">{{expirationDate | date:'dd/MM/yy'}}.</span></div>
      <div *ngIf="!isFailed && currentLanguageId === LanguageEnum.TurkishTurkey" class="custom-description text-lg text-center mt-4 px-1 mb-5">Lisansınızı <span class="text-blue-400 px-1">{{expirationDate | date:'dd/MM/yy'}}</span> tarihine kadar kullanmaya devam edebilirsiniz. </div>
      <div *ngIf="isFailed" class="custom-description text-lg text-center mt-4 px-1 mb-5">{{ "Key.UnsubscriptionFailedDescription" | translate }}</div>
      <div class="flex justify-content-center mt-8 gap-4 w-full px-7 pt-7">
        <button *ngIf="isFailed" (click)="unsubscribe()" pButton 
        pRipple 
        class="p-button-danger w-5" [disabled]="!selectedReason"><p class="text-sm w-full text-center">{{'Key.TryAgain' | translate}}</p></button>
        <button (click)="closeSuccessfullPopup()" pButton 
        pRipple 
        class="p-button-outlined w-5"><p class="text-sm font-medium text-center w-full"  [ngStyle]="isFailed ? {'width': '100%'} : {}">{{'Key.Close' | translate}}</p></button>
      </div>
    </div>
  </div>
</p-dialog>
