import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { LocalizationService } from '@app/core';
import { ExistingEmail, ExistingManager, FetchApplicantsIcon, LicenseUsageError, RefreshIcon, UserFilledFormAlready } from '@app/shared/common/constants';
import { EntityStateEnum, PositionStepKanbanStaticTypes, UserGroupEnum } from '@app/shared/common/enums';
import { GeneralApplicationInvitationPostItem } from '@app/vinter/models/general-application-invitation-post-item';
import { IntegrationAndPositionInfo } from '@app/vinter/models/integration/integration-and-position-info';
import { IntegrationApplicantListItem } from '@app/vinter/models/integration/Integration-applicant-list-item';
import { OrganizationIntegrationItem } from '@app/vinter/models/integration/organization-integration-item';
import { InvitationPostItem } from '@app/vinter/models/Invitation/invitation-post-item';
import { GeneralApplicationInvitationService } from '@app/vinter/services/general-application-invitation.service';
import { IntegrationApplicantService } from '@app/vinter/services/integration-applicant.service';
import { IntegrationPositionService } from '@app/vinter/services/integration-position.service';
import { InvitationService } from '@app/vinter/services/invitation.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-invite-applicant-via-integration',
  templateUrl: './invite-applicant-via-integration.component.html',
  styleUrls: ['./invite-applicant-via-integration.component.scss'],
  providers: [MessageService]
})

export class InviteApplicantViaIntegrationComponent implements OnInit {
  values: any[] | undefined = [];
  invitationList: GeneralApplicationInvitationPostItem[] = [];
  onlyInvitationList: InvitationPostItem[] = [];
  userGroupID: string = UserGroupEnum.APPLICANT;
  regexp = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$');

  @Input() positionId: string;
  @Input() organizationId: string;
  @Output() callCloseDialog: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendInvite: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendMailDataValidation: EventEmitter<any> = new EventEmitter<any>();

  isWarningVisible = false;
  fetchApplicantsIcon = FetchApplicantsIcon;

  @Input() integrationApplicantList;
  @Input() integrationApplicantListCopy;
  @Input() integrationApplicantListSearchCopy;
  @Input() fetchedApplicantData;
  @Input() positionIntegrationList;
  @Output() getApplicantListEmitter: EventEmitter<any> = new EventEmitter<any>();

  isAllChoosed : boolean = false;
  refreshIcon = RefreshIcon;
  anyChoosedOrgIntegration : boolean = false;
  searchTerm: string = '';

  constructor(
      private service: GeneralApplicationInvitationService,
      private messageService: MessageService,
      private localizationService: LocalizationService,
      private invitationService: InvitationService,
      private integrationPositionService: IntegrationPositionService,
  ) { }

  ngOnInit(): void {
    this.checkAnyChoosedIntegration();
  }

  getApplicantList(isRefresh : boolean){
    this.getApplicantListEmitter.emit(isRefresh);
  }

  checkAnyChoosedIntegration(){
    if(this.positionIntegrationList)
        this.anyChoosedOrgIntegration = this.positionIntegrationList.some(integration => integration.isChoosed === true);
  }

  checkIfAnyIntegrationChoosed(organizationIntegration : OrganizationIntegrationItem) {
    if(this.positionIntegrationList)
    this.checkAnyChoosedIntegration();
    if(this.fetchedApplicantData){
        if(organizationIntegration.hasRequested){
            if(organizationIntegration.isChoosed){
                var choosedIntegrationApplicantList = this.integrationApplicantListCopy.filter(x => x.organizationIntegrationID == organizationIntegration.id);
                this.integrationApplicantList = this.integrationApplicantList.concat(choosedIntegrationApplicantList);
                this.integrationApplicantListSearchCopy = this.integrationApplicantListSearchCopy.concat(choosedIntegrationApplicantList);
            }
            else{
                var unChoosedApplicants = this.integrationApplicantList.filter(x => x.organizationIntegrationID == organizationIntegration.id);
                unChoosedApplicants.forEach(applicant =>{
                    applicant.isChoosed = false;
                    var duplicatedEmails = this.integrationApplicantList.filter(x => x.applicantEmail == applicant.applicantEmail && x.isChoosed);
                    if(duplicatedEmails.length == 1){
                        duplicatedEmails[0].mailItem.isMailHasError  = !this.addEvent(applicant.applicantEmail);
                        if (duplicatedEmails[0].mailItem.isMailHasError) {
                             duplicatedEmails[0].mailItem.mail = duplicatedEmails[0].applicantEmail;
                             duplicatedEmails[0].mailItem.dataValidation= false;
                             duplicatedEmails[0].mailItem.errorMessage = this.localizationService.translatedTextList.NotValidEmail;
                             duplicatedEmails[0].mailItem.isMailHasError = true;
                        }
                        else{
                            duplicatedEmails[0].mailItem.mail = duplicatedEmails[0].applicantEmail;
                            duplicatedEmails[0].mailItem.dataValidation= true;
                            duplicatedEmails[0].mailItem.errorMessage = "";
                            duplicatedEmails[0].mailItem.isMailHasError = false;
                        }
                        this.removeMail(duplicatedEmails[0].applicantEmail,organizationIntegration.id);
                        this.values.push(duplicatedEmails[0].mailItem);

                    }
                    applicant.mailItem.mail = applicant.applicantEmail;
                    applicant.mailItem.dataValidation= true;
                    applicant.mailItem.errorMessage = "";
                    applicant.mailItem.isMailHasError = false;

                    this.removeMail(applicant.applicantEmail,organizationIntegration.id);
                    this.sendEmailToParent();
                });
                this.integrationApplicantList = this.integrationApplicantList.filter(x => x.organizationIntegrationID != organizationIntegration.id);
                this.integrationApplicantListSearchCopy =  this.integrationApplicantListSearchCopy.filter(x => x.organizationIntegrationID != organizationIntegration.id);
            }
        }
        else{
            this.getApplicantListEmitter.emit(false);
        }
    }
    this.onSearchChange();
  }


  getFirstAndLastLetter(inputString: string): string {
    inputString = inputString.trim();
    const firstLetter = inputString.charAt(0);
    const lastSpaceIndex = inputString.lastIndexOf(' ');
    if (lastSpaceIndex !== -1) {
        const charAfterLastSpace = inputString.charAt(lastSpaceIndex + 1);
        return `${firstLetter.toUpperCase()}${charAfterLastSpace.toUpperCase()}`;
    } else {
        return firstLetter.toUpperCase();
    }
}

onSearchChange(){
    if(this.searchTerm !== undefined && this.searchTerm !== ""){
      let searchString = this.searchTerm.normalize("NFKD").replace(/[\u0300-\u036f]/g, "").replace(/[\u0131]/g, "i").toLowerCase();
      this.integrationApplicantList = this.integrationApplicantListSearchCopy.filter(x=>
        x.applicantName.normalize("NFKD").replace(/[\u0300-\u036f]/g, "").replace(/[\u0131]/g, "i").toLowerCase().includes(searchString)
    || x.applicantEmail.normalize("NFKD").replace(/[\u0300-\u036f]/g, "").replace(/[\u0131]/g, "i").toLowerCase().includes(searchString));

    }else{
      this.integrationApplicantList = this.integrationApplicantListSearchCopy;
    }
    this.sortApplicantList(this.integrationApplicantList);
    this.isAllChoosed = this.integrationApplicantList.every(item => item.isChoosed === true) && this.integrationApplicantList.length > 0;
  }

  sortApplicantList(applicantList: any) {
    applicantList.sort((a, b) => {
        if (a.mailItem?.isMailHasError === true && b.mailItem?.isMailHasError === false) {
            return -1;
        }
        if (a.mailItem?.isMailHasError === false && b.mailItem?.isMailHasError === true) {
            return 1;
        }

        if (a.mailItem?.isMailHasError === false && b.mailItem?.isMailHasError === false) {
            if (a.isChoosed === true && b.isChoosed === false) {
                return -1;
            }
            if (a.isChoosed === false && b.isChoosed === true) {
                return 1;
            }
        }

        if (a.applicantName.toLowerCase() < b.applicantName.toLowerCase()) {
            return -1;
        }
        if (a.applicantName.toLowerCase() > b.applicantName.toLowerCase()) {
            return 1;
        }

        return 0;
    });
}



  addItem(applicant : IntegrationApplicantListItem) {
      if (applicant.applicantEmail) {
        if(applicant.isChoosed){
            applicant.mailItem.isMailHasError = !this.addEvent(applicant.applicantEmail);
            if (applicant.mailItem.isMailHasError) {
                applicant.mailItem.mail = applicant.applicantEmail;
                applicant.mailItem.dataValidation = false;
                applicant.mailItem.errorMessage = this.localizationService.translatedTextList.NotValidEmail;
                applicant.mailItem.isMailHasError = true;
                this.values.push(applicant.mailItem);
            }
            else {
                var duplicatedEmails = this.integrationApplicantList.filter(x => x.applicantEmail == applicant.applicantEmail && x.isChoosed);
                if(duplicatedEmails.length > 1){
                    duplicatedEmails.forEach(x =>{
                        x.mailItem.mail = applicant.applicantEmail;
                        x.mailItem.dataValidation = false;
                        x.mailItem.errorMessage = this.localizationService.translatedTextList["DuplicatedEmailOnList"];
                        x.mailItem.isMailHasError = true;
                        this.removeMail(x.mailItem.mail,x.organizationIntegrationID);
                        this.values.push(x.mailItem);
                    });
                }
                else{
                    this.values.push(applicant.mailItem);
                }
            }
            this.sendEmailToParent();
        }
        else{
            var applicantsWithSameEmails = this.integrationApplicantList.filter(x => x.applicantEmail == applicant.applicantEmail && x.isChoosed);
            if(applicantsWithSameEmails.length == 1){
                applicantsWithSameEmails[0].mailItem.isMailHasError = !this.addEvent(applicant.applicantEmail);
                if (applicantsWithSameEmails[0].mailItem.isMailHasError) {
                    applicantsWithSameEmails[0].mailItem.mail = applicantsWithSameEmails[0].applicantEmail;
                    applicantsWithSameEmails[0].mailItem.dataValidation = false;
                    applicantsWithSameEmails[0].mailItem.errorMessage = this.localizationService.translatedTextList.NotValidEmail;
                    applicantsWithSameEmails[0].mailItem.isMailHasError = true;
                }
                else{
                    applicantsWithSameEmails[0].mailItem.mail = applicantsWithSameEmails[0].applicantEmail;
                    applicantsWithSameEmails[0].mailItem.dataValidation = true;
                    applicantsWithSameEmails[0].mailItem.errorMessage = "";
                    applicantsWithSameEmails[0].mailItem.isMailHasError = false;
                }
                this.removeMail(applicantsWithSameEmails[0].mailItem.mail,applicantsWithSameEmails[0].organizationIntegrationID);
                this.values.push(applicantsWithSameEmails[0].mailItem);
            }
            applicant.mailItem.mail = applicant.applicantEmail;
            applicant.mailItem.dataValidation = true;
            applicant.mailItem.errorMessage = "";
            applicant.mailItem.isMailHasError = false;
            this.removeMail(applicant.applicantEmail,applicant.organizationIntegrationID);
            this.sendEmailToParent();
        }
    }
    this.isAllChoosed = this.integrationApplicantList.every(item => item.isChoosed === true);
    this.sortApplicantList(this.integrationApplicantList);
  }

  chooseAllApplicant(){
    var unChoosedApplicants = this.integrationApplicantList.filter(x => x.isChoosed == false);
    unChoosedApplicants.forEach(item =>{
        item.isChoosed = true;
        this.addItem(item);
    });
  }

  removeAllApplicant(){
    var choosedApplicants = this.integrationApplicantList.filter(x => x.isChoosed == true);
    choosedApplicants.forEach(item =>{
        item.isChoosed = false;
        this.addItem(item);
    });
  }

  sendEmailToParent() {
      this.sendMailDataValidation.emit(this.values);
  }

  deleteCard(index) {
      this.values.splice(index, 1);
      this.sendEmailToParent();
  }

  sendInvitation(inviteType:string) {
      if(inviteType == PositionStepKanbanStaticTypes.GeneralApplication){
          this.generalApplicationsSendInvitation();
      }
      else if(inviteType == PositionStepKanbanStaticTypes.Invitation){
          this.sendInvitationForHyundai();
      }
  }


  generalApplicationsSendInvitation() {
      this.invitationList = [];
      let isAllMailValid = true;
      this.values.forEach(element => {
          const isValid = this.regexp.test(element.mail)
          if (!isValid) {
              isAllMailValid = false;
              return;
          }
      })
      if (!isAllMailValid) {
          this.messageService.add({
              severity: 'error',
              summary: this.localizationService.translatedTextList.Error,
              detail: this.localizationService.translatedTextList.NotValidEmail,
              life: 3000
          });
          return
      }
      this.values.forEach(element => {
          let generalApplicationInvitationPostItem: GeneralApplicationInvitationPostItem = {
              entityState: EntityStateEnum.Added,
              email: element.mail,
              positionID: this.positionId,
          }
          this.invitationList.push(generalApplicationInvitationPostItem);
      });
      this.service.createGeneralApplicationInvitation(this.invitationList).subscribe({
          next: (response) => {
              this.isWarningVisible = false;
              this.messageService.add({
                  severity: 'success',
                  summary: this.localizationService.translatedTextList.Success,
                  detail: this.localizationService.translatedTextList.OperationCompleted,
                  life: 3000
              });
              this.sendInvite.emit(true);
          },
          error: (err) => {
              this.isWarningVisible = true;
              const existingEmails = err.substring(4).split(", ");
              this.existingHrEmails(existingEmails);
          }
      });
  }


  sendInvitationForHyundai() {
      this.onlyInvitationList = [];
      let isAllMailValid = true;
      this.values.forEach(element => {
          const isValid = this.regexp.test(element.mail)
          if (!isValid) {
              isAllMailValid = false;
              return;
          }
      })
      if (!isAllMailValid) {
          this.messageService.add({
              severity: 'error',
              summary: this.localizationService.translatedTextList.Error,
              detail: this.localizationService.translatedTextList.NotValidEmail,
              life: 3000
          });
          return
      }
      this.values.forEach(element => {
          let invitationPostItem: InvitationPostItem = {
              entityState: EntityStateEnum.Added,
              email: element.mail,
              positionID: this.positionId,
              organizationID: this.organizationId,
              userGroupID: this.userGroupID
          }
          this.onlyInvitationList.push(invitationPostItem);
      });
      this.invitationService.createMultipleApplicantInvitation(this.onlyInvitationList).subscribe({
          next: (response) => {
              this.isWarningVisible = false;
              this.messageService.add({
                  severity: 'success',
                  summary: this.localizationService.translatedTextList.Success,
                  detail: this.localizationService.translatedTextList.OperationCompleted,
                  life: 3000
              });
              this.sendInvite.emit(true);
          },
          error: (err) => {
              this.isWarningVisible = true;
              const existingEmails = err.substring(4).split(", ");
              this.existingHrEmails(existingEmails);
          }
      });
  }



  existingHrEmails(existingEmails: string[]): void {
      const validationErrors: { [key: string]: string } = {};

      existingEmails.forEach((elem) => {
          const [mail, message] = elem.split('/');
          validationErrors[mail] = message;
      });

      this.values.forEach(element => {
          if (validationErrors[element.mail]) {
              if (validationErrors[element.mail] == ExistingEmail) {
                element.errorMessage = this.localizationService.translatedTextList.EmailOrganizationMustBeUniqueMessage;
              }
              if (validationErrors[element.mail] == LicenseUsageError) {
                element.errorMessage = this.localizationService.translatedTextList.LicenseUsageErrorMessage;
              }
              if (validationErrors[element.mail] == ExistingManager) {
                element.errorMessage = this.localizationService.translatedTextList.ExistingManagerMessage;
              }
              if (validationErrors[element.mail] == UserFilledFormAlready) {
                element.errorMessage = this.localizationService.translatedTextList.UserFilledFormAlready;
              }
              element.dataValidation = false;
              element.isMailHasError = true;
          }
      });
      this.sendEmailToParent();
      if(this.values.some(x => validationErrors.hasOwnProperty(x.mail))){
        this.showMailErrorMessage(this.localizationService.translatedTextList.CheckEmailsWithErrors)
      }
  }

  showMailErrorMessage(errorMessage : any){
    this.messageService.add({
        severity: 'error',
        summary: this.localizationService.translatedTextList.Error,
        detail: errorMessage,
        life: 3000
    });
  }

  removeMail(event: any,organizationIntegrationID : string) {
      var index = this.values.findIndex(x => x.mail == event && x.organizationIntegrationID == organizationIntegrationID);
      if(index != -1)
      this.values.splice(index, 1);
  }

  addEvent(event: any) {
      var isEmail = this.regexp.test(event);
      if (!isEmail) {
          return false;
      }
      else {
          return true;
      }
  }
}
