import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuestionCollectionFormComponent } from '@app/vinter/components/question-collection/question-collection-create/question-collection-form/question-collection-form.component';
import { InputTextModule } from 'primeng/inputtext';
import { CardModule } from 'primeng/card';
import { ToolbarModule } from 'primeng/toolbar';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { TableModule } from 'primeng/table';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TranslateModule } from '@ngx-translate/core';
import { TagModule } from 'primeng/tag';
import { DialogModule } from 'primeng/dialog';
import { StepsModule } from 'primeng/steps';
import { PickListModule } from 'primeng/picklist';
import { ChipsModule } from 'primeng/chips';
import { CheckboxModule } from 'primeng/checkbox';
import { YourQuestionListPreviewComponent } from '@app/vinter/components/question-collection/question-collection-create/your-question-list-preview/your-question-list-preview.component';
import { PositionPreviewComponent } from '@app/vinter/components/position-template/position-preview/position-preview.component';
import { PositionStepEnglishExamComponent } from '@app/vinter/components/position-template/position-preview/position-step-english-exam/position-step-english-exam.component';
import { PositionStepVideoMeetingComponent } from '@app/vinter/components/position-template/position-preview/position-step-video-meeting/position-step-video-meeting.component';
import { InviteApplicantComponent } from '@app/vinter/components/invite-applicant/invite-applicant/invite-applicant.component';
import { InviteApplicantViaEmailComponent } from '@app/vinter/components/invite-applicant/invite-applicant/invite-applicant-via-email/invite-applicant-via-email.component';
import { InviteApplicantViaFileComponent } from '@app/vinter/components/invite-applicant/invite-applicant/invite-applicant-via-file/invite-applicant-via-file.component';
import { TabViewModule } from 'primeng/tabview';
import { FileUploadModule } from 'primeng/fileupload';
import { ListboxModule } from 'primeng/listbox';
import { ChipModule } from 'primeng/chip';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputMaskModule } from 'primeng/inputmask';
import { AvatarModule } from 'primeng/avatar';
import { ImageModule } from 'primeng/image';
import { PasswordModule } from 'primeng/password';
import { OrderListModule } from 'primeng/orderlist';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SpeedDialModule } from 'primeng/speeddial';
import { MultiSelectModule } from 'primeng/multiselect';
import { EditorModule } from 'primeng/editor';
import { ViewQuestionsOfVinterLibraryComponent } from '@app/vinter/components/question-collection/question-collection-vinter-library-list/view-questions-of-vinter-library/view-questions-of-vinter-library.component';
import { QuestionCollectionViewQuestionComponent } from '@app/vinter/components/question-collection/question-collection-view-question/question-collection-view-question.component';
import { ApplicantPipe } from '@app/vinter/components/organization/organization-settings/license-plan/license-plan.component';
import { KnobModule } from 'primeng/knob';
import { StepCountComponent } from '@app/shared/components/step-count/step-count.component';
import { MenuModule } from 'primeng/menu';
import { SidebarModule } from 'primeng/sidebar';
import { PanelModule } from 'primeng/panel';
import { BadgeModule } from 'primeng/badge';
import { PositionStepPersonalityTestComponent } from '@app/vinter/components/position-template/position-preview/position-step-personality-test/position-step-personality-test.component';
import { OrganizationLibraryListComponent } from '@app/vinter/components/position-template/organization-library-list/organization-library-list.component';
import { PaginatorModule } from 'primeng/paginator';
import { PositionTemplateListComponent } from '@app/vinter/components/position-template/position-template-list/position-template-list.component';
import { VinterLibraryListComponent } from '@app/vinter/components/position-template/vinter-library-list/vinter-library-list.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { UserAvatarComponent } from '@app/shared/components/user-avatar/user-avatar.component';
import { TimelineModule } from 'primeng/timeline';
import { CustomTimelineComponent } from '@app/shared/components/custom-timeline/custom-timeline.component';
import { CustomProgressbarComponent } from '@app/shared/components/custom-progressbar/custom-progressbar.component';
import { OceanProgressbarComponent } from '@app/shared/components/ocean-progressbar/ocean-progressbar.component';
import { CustomPdfViewerComponent } from '@app/shared/components/pdf-viewer/custom-pdf-viewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DepartmentCheckDialogComponent } from '@app/shared/components/department-check-dialog/department-check-dialog.component';
import { OrganizationAvatarComponent } from '@app/shared/components/organization-avatar/organization-avatar.component';
import { SharedCustomChipVinterModule } from './shared-custom-chip.module';
import { SharedPositionStepQuestionListVinterModule } from './shared-position-step-question-list.module';
import { LicenseLockedDialogComponent } from '@app/shared/components/license-locked-dialog/license-locked-dialog.component';
import { InviteApplicantViaIntegrationComponent } from '@app/vinter/components/invite-applicant/invite-applicant/invite-applicant-via-integration/invite-applicant-via-integration.component';
import { SharedStepQuestionResultListModule } from './shared-step-question-result-list.module';
import { SharedLicensePlanDialogVinterModule } from './shared-license-plan-dialog.module';


@NgModule({
  declarations: [
    QuestionCollectionFormComponent,
    YourQuestionListPreviewComponent,
    PositionPreviewComponent,
    PositionStepEnglishExamComponent,
    PositionStepVideoMeetingComponent,
    InviteApplicantComponent,
    InviteApplicantViaEmailComponent,
    InviteApplicantViaFileComponent,
    InviteApplicantViaIntegrationComponent,
    QuestionCollectionViewQuestionComponent,
    ApplicantPipe,
    StepCountComponent,
    PositionStepPersonalityTestComponent,
    OrganizationLibraryListComponent,
    PositionTemplateListComponent,
    VinterLibraryListComponent,
    UserAvatarComponent,
    CustomTimelineComponent,
    CustomProgressbarComponent,
    OceanProgressbarComponent,
    CustomPdfViewerComponent,
    OrganizationAvatarComponent,
  ],
  imports: [
    CommonModule,
    InputTextModule,
    CardModule,
    ToolbarModule,
    DropdownModule,
    FormsModule,
    ButtonModule,
    ConfirmDialogModule,
    ToastModule,
    TooltipModule,
    TableModule,
    InputTextareaModule,
    TranslateModule,
    ReactiveFormsModule,
    TagModule,
    SharedCustomChipVinterModule,
    DialogModule,
    StepsModule,
    PickListModule,
    ChipsModule,
    CheckboxModule,
    TabViewModule,
    SidebarModule,
    FileUploadModule,
    ListboxModule,
    ChipModule,
    RadioButtonModule,
    InputNumberModule,
    InputMaskModule,
    AvatarModule,
    ImageModule,
    SharedLicensePlanDialogVinterModule,
    PasswordModule,
    SelectButtonModule,
    OrderListModule,
    InputSwitchModule,
    SpeedDialModule,
    MultiSelectModule,
    EditorModule,
    SharedPositionStepQuestionListVinterModule,
    KnobModule,
    MenuModule,
    SidebarModule,
    PanelModule,
    BadgeModule,
    PaginatorModule,
    TimelineModule,
    PdfViewerModule
  ],
  exports: [
    QuestionCollectionFormComponent,
    YourQuestionListPreviewComponent,
    PositionPreviewComponent,
    PositionStepEnglishExamComponent,
    PositionStepVideoMeetingComponent,
    InviteApplicantComponent,
    InviteApplicantViaEmailComponent,
    InviteApplicantViaFileComponent,
    InviteApplicantViaIntegrationComponent,
    QuestionCollectionViewQuestionComponent,
    StepCountComponent,
    PositionStepPersonalityTestComponent,
    OrganizationLibraryListComponent,
    PositionTemplateListComponent,
    VinterLibraryListComponent,
    UserAvatarComponent,
    CustomTimelineComponent,
    CustomProgressbarComponent,
    OceanProgressbarComponent,
    CustomPdfViewerComponent,
    OrganizationAvatarComponent,
    ]
})
export class SharedVinterModule { }
