import { Component, Input, OnInit } from '@angular/core';
import { EApplicantBehaviourEventType, EQuestionCategories, EQuestionContentURLType, QuestionExpectedAnswerType } from '@app/shared/common/enums';
import { VgApiService } from '@videogular/ngx-videogular/core';

@Component({
  selector: 'app-applicant-answer-sidebar-multiple-choice-language',
  templateUrl: './answer-sidebar-multiple-choice.component.html',
  styleUrls: ['./answer-sidebar-multiple-choice.component.scss']
})
export class ApplicantAnswerSidebarMultipleChoiceLanguage implements OnInit{

    answerType: any;
    questionOrder: any;
    QuestionExpectedAnswerType = QuestionExpectedAnswerType;
    @Input() question: any;
    @Input() questionKeywords: any;
    @Input() choiceContentId: any;
    @Input() choiceAnswers: any;
    @Input() behaviourEventLogs: any[]=[];
    QuestionContentType= EQuestionContentURLType;
    selectedAnswerChoice: any;
    selectedAnswer:string;
    selectedRadioValue: any;
    questionCategoryType = EQuestionCategories;
    questionPageHiddenLog: number;
    questionMouseOutLog: number;

  constructor(
    public api: VgApiService) { }

  ngOnInit(): void {
    this.setSelectedAnswer();
    this.questionMouseOutLog = this.checkIfQuestionLog(EApplicantBehaviourEventType.MouseOut,this.question.id);
    this.questionPageHiddenLog = this.checkIfQuestionLog(EApplicantBehaviourEventType.PageHidden,this.question.id);
  }


  isCorrectAnswer(choiceContentId: string): boolean {
    this.selectedAnswer = this.choiceAnswers?.choiceContentId;
    const correctChoice = this.question?.choiceContents?.find(choice => choice.id === this.selectedAnswer);
    this.selectedRadioValue = correctChoice ? correctChoice.choiceText : null;
    return this.choiceAnswers && this.choiceAnswers.choiceContentId === choiceContentId && this.choiceAnswers.isCorrect;
  }

  isWrongAnswer(choiceContentId: string): boolean {
    return this.choiceAnswers && this.choiceAnswers.choiceContentId === choiceContentId && !this.choiceAnswers.isCorrect;
  }

  checkAnswer(choiceContentId: string): boolean {
    let result = false;
    if(choiceContentId === null || choiceContentId === undefined){
      result = false;
    }
    else{
      if(this.choiceAnswers.choiceContentId === choiceContentId && this.choiceAnswers.isCorrect){
        result = true;
      }
      else{
        result = false;
      }
    }
    return result
  }

  setSelectedAnswer(){
    var choiceContents = this.question?.choiceContents;
    var selectedAnswerIndex = choiceContents.findIndex(x=> x.id == this.choiceContentId);
    this.selectedAnswerChoice = choiceContents[selectedAnswerIndex];
  }

  checkIfQuestionLog(eventType, id) {
    const questionLog = this.behaviourEventLogs.find(item => item.questionID === id);
    if(eventType === EApplicantBehaviourEventType.PageHidden){
      return (questionLog ?? false) ? questionLog.eventTypes.filter(eventType => eventType === EApplicantBehaviourEventType.PageHidden).length : 0;
    }
    if(eventType === EApplicantBehaviourEventType.MouseOut){
      return (questionLog ?? false) ? questionLog.eventTypes.filter(eventType => eventType === EApplicantBehaviourEventType.MouseOut).length : 0;
    }
  }
}
