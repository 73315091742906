import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { LocalizationService } from '@app/core';
import { vinterPureLogoRotatedPath } from '@app/shared/common/constants';
import { EQuestionCategories, ERatingType, EntityStateEnum, QuestionExpectedAnswerType } from '@app/shared/common/enums';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { AnswerWithContentAndChoiceListItem } from '@app/vinter/models/answer-list-item';
import { RatingFormItem } from '@app/vinter/models/rating-form-item';
import { AnswerService } from '@app/vinter/services/answer.service';
import { ApplicantBehaviourEventLogService } from '@app/vinter/services/applicant-behaviour-event-log.service';
import { ApplicationStepService } from '@app/vinter/services/application-step.service';
import { QuestionService } from '@app/vinter/services/question.service';
import { RatingService } from '@app/vinter/services/rating.service';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-applicant-step-question-list-language',
  templateUrl: './applicant-step-question-list-language.component.html',
  styleUrls: ['./applicant-step-question-list-language.component.scss']
})
export class ApplicanyStepQuestionListLanguage implements OnInit{

  @Input() item: any;
  @Input() filteredList: any[] = []; // Filtrelenmiş liste için kullanılacak
  @Input() originalList: any[] = []; // Filtrelenmiş liste için kullanılacak
  @Input() cheatingQuestionLog  : any[] = []; // Filtrelenmiş liste için kullanılacak
  QuestionExpectedAnswerType = QuestionExpectedAnswerType;
  selectedId: string;
  RatingType = ERatingType;
  vinterLogo = vinterPureLogoRotatedPath;
  @Input() applicantId: any;
  @Input() applicationId: any;
  sidebarVisible=false;
  selectedQuestion: any = null;
  answer: AnswerWithContentAndChoiceListItem;
  popupOpen = false;
  selectedStar = 0;
  @Output() sendAverageRating: EventEmitter<number> = new EventEmitter<number>();
  questionCategoryType = EQuestionCategories;

  constructor(
    private answerService: AnswerService,
    private messageService: MessageService,
    private localizationService: LocalizationService,
    public ratingService: RatingService,
    public api: VgApiService) { }

  ngOnInit(): void {

  }

  onHideSidebar(event){
    this.sidebarVisible = false;
  }

  getAnswerByQuestionId(index) {
    this.selectedQuestion = {order: this.filteredList[index].order,...this.filteredList[index].question};
    this.answerService.getDataByFilter({ questionId: this.selectedQuestion.id, applicantID: this.applicantId, applicationStepID: this.item.id}).subscribe(result => {
      this.answer = result;
      this.sidebarVisible = true;
    });
  }
getProgressBarColor(score: number): string {
    if (score <= 10) return '#DC2626';
    if (score <= 20) return '#DC2667';
    if (score <= 30) return '#DC2688';
    if (score <= 40) return '#DC269E';
    if (score <= 50) return '#AD26DC';
    if (score <= 60) return '#8126DC';
    if (score <= 70) return '#6026DC';
    if (score <= 80) return '#3F26DC';
    if (score <= 90) return '#1549D6';
    return '#2962FF';
  }


  getAnswerForRatingByQuestionId(question: any,isPopUpAction, ratingType) {
    this.answerService.getDataByFilter({ questionId: question.id, applicantID: this.applicantId, applicationStepID: this.item.id}).subscribe(result => {
      this.selectedQuestion = question;
      this.answer = result;
      this.openRatingPopup(isPopUpAction, ratingType);
    });
  }

  openRatingPopup(isPopUpAction, ratingType){
        if(isPopUpAction){
            this.popupOpen = true;
        }else if(ratingType === this.RatingType.VinterTrust){
            this.trustVinter(this.answer?.answerContent?.id );
        }
  }

  closePopup(){
    this.popupOpen=false;
  }

  trustVinter(contentId) {
    let selectedStar = 0;
    if (contentId != null) {
      selectedStar= Math.round(this.answer?.answerContent?.gptEvalScore / 10)/2;
    }
    let rating = this.answer?.rating;
    if(!(rating ?? false))
        rating = new RatingFormItem();
    rating.ratingScore = selectedStar;
    rating.ratingType=this.RatingType.VinterTrust;
    this.onFormSubmit(true, rating);
  }


  onRated(ratingInfo){
    this.onFormSubmit(false,ratingInfo);
  }


  patchRatingInfoFormControl(isGPTEval: boolean,ratingInfoPop) {
    let ratingInfo = new RatingFormItem();
    ratingInfo.comment = !isGPTEval ? ratingInfoPop?.comment : this.answer?.answerContent?.gptEvalText;
    ratingInfo.ratingScore = ratingInfoPop?.ratingScore;
      ratingInfo.answerId = this.answer ?? false ? this.answer?.id : this.selectedQuestion.answerId ;
      ratingInfo.applicationStepId = this.item.id;
      ratingInfo.ratingType = isGPTEval ? this.RatingType.VinterTrust:this.RatingType.HrPoint;
    ratingInfo.applicationId = this.applicationId;
    return ratingInfo;
  }


  onFormSubmit(isGPTEval: boolean, ratingInfoPop: any) {
    if (ratingInfoPop?.ratingScore <= 0 && (ratingInfoPop?.ratingScore ?? false)) return;
    let ratingInfo = this.patchRatingInfoFormControl(isGPTEval,ratingInfoPop);
    if (ratingInfo.ratingScore != null && ratingInfo.ratingScore != 0) {
      if (ratingInfoPop?.id !== null && ratingInfoPop?.id !== undefined) {
        ratingInfo.id = ratingInfoPop?.id;
        ratingInfo.xmin = ratingInfoPop?.xmin;
        ratingInfo.entityState = EntityStateEnum.Modified;
        var item = {
          rating: ratingInfo,
          questionCollectionId: (this.item.positionStep.questionCollection ?? false) ? this.item.positionStep.questionCollection.id : null,
          applicationStepId: this.item.id
        };
        this.ratingService.updateRatingWithAvg(item).subscribe((rating) => {
          if(rating ?? false){
            this.selectedQuestion.ratingScore = rating.rating;
            this.item.averageRating = rating.stepRating;
            this.selectedQuestion.ratingType=isGPTEval?this.RatingType.VinterTrust:this.RatingType.HrPoint;
            this.popupOpen=false;
            this.updateAverageSteps();
          }
          else{
            this.updateAverageSteps();
          }
        });
      } else {
        ratingInfo.entityState = EntityStateEnum.Added;
        var item = {
          rating: ratingInfo,
          questionCollectionId: (this.item.positionStep.questionCollection ?? false) ? this.item.positionStep.questionCollection.id : null,
          applicationStepId: this.item.id
        };
        this.ratingService.addRatingWithAvg(item).subscribe((rating) => {
          if(rating ?? false){
            this.selectedQuestion.ratingScore = rating.rating;
            this.item.averageRating = rating.stepRating;
            this.selectedQuestion.ratingType=isGPTEval?this.RatingType.VinterTrust:this.RatingType.HrPoint;
            this.popupOpen=false;
            this.updateAverageSteps();
          }
          else{
            this.updateAverageSteps();
          }
        });
      }
    }
    else {
      this.messageService.add({ severity: 'error', summary: this.localizationService.translatedTextList.Error, detail: this.localizationService.translatedTextList.RatingScoreNotNull, life: 3000 });
    }
  }

  updateAverageSteps() {
    this.sendAverageRating.emit(0);
  }

  checkIfQuestionLogExists(questionId): boolean {
    if(this.cheatingQuestionLog ?? false){
      return this.cheatingQuestionLog.some(item => item.questionID === questionId);
    }
    else{
      return false;
    }

  }

}
