import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService, HttpBaseService, LocalizationService, NotifyService } from '@app/core';
import { ApiControllerEnum } from '@app/shared/common/enums';
import { ApplicationOfferItem } from '../models/application-offer-item';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApplicationOfferService extends HttpBaseService {

    constructor(private http: HttpClient
        , private notifyServ: NotifyService
        , private localizationService: LocalizationService
        , private configService: ConfigurationService) {
        super(http, ApiControllerEnum.ApplicationOffer, notifyServ, localizationService, configService);
      }

      GetDataByFilter(filter: any): Observable<ApplicationOfferItem> {
        return this.post("GetDataByFilter", filter, true);
      }
      SaveAsync(filter: ApplicationOfferItem): Observable<ApplicationOfferItem> {
        return this.post("SaveAsync", filter, true);
      }
    }
