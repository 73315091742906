import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { LocalizationService } from '@app/core';
import { ApplicationStepStatus, EQuestionCollectionSubCategory,QuestionCollectionCategoryEnum, PositionStepKanbanStaticTypes, PositionStepType } from '@app/shared/common/enums';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { AnswerContentListItem } from '@app/vinter/models/answer-content-list-item';
import { AnswerWithContentAndChoiceListItem } from '@app/vinter/models/answer-list-item';
import { ChoiceAnswerListItem } from '@app/vinter/models/choice-answer-list-item';
import { ApplicationStepService } from '@app/vinter/services/application-step.service';
import { GeneralApplicationQuestion } from '@app/vinter/services/generalApplicationQuestion.service';
import { VgApiService } from '@videogular/ngx-videogular/core';

@Component({
  selector: 'app-applicant-details',
  templateUrl: './applicant-details.component.html',
  styleUrls: ['./applicant-details.component.scss']
})
export class ApplicantDetailsComponent implements OnInit, AfterViewChecked {

  answer: AnswerWithContentAndChoiceListItem;
  answerID: string;
  answerContent: AnswerContentListItem;
  foreignLanguageEnum= QuestionCollectionCategoryEnum.ForeignLanguage;
  oceanEnum= QuestionCollectionCategoryEnum.PersonalityTest;
  answerText: string;
  answerType: number;
  positionStepType: number;
  applicationSteps: any[] = [];
  positionStepTypes: any[] = [];
  positionStepTypeEnum: any[] = [];
  answerId: string;
  choiceAnswers: ChoiceAnswerListItem;
  applicationId: string;
  applicantId: string;
  applicationStepId: string;
  activeIndex = 0;
  lang: string;
  avgRating: number = 0;
  averageSteps: number = 0;
  applicationStepStatus = ApplicationStepStatus;
  popupOpen = false;
  lastMatchingIndex = 0;
  currentStepId: string;
  oceanScore: any;
  OCEAN50: number = EQuestionCollectionSubCategory.OCEAN50;
  OCEAN120: number = EQuestionCollectionSubCategory.OCEAN120;
  NumericalCognitiveAbility: number = EQuestionCollectionSubCategory.NumericalCognitiveAbility;
  VerbalCognitiveAbility: number = EQuestionCollectionSubCategory.VerbalCognitiveAbility;
  QuestionListEnum: number = PositionStepType.QuestionList;
  VideoMeetingEnum: number = PositionStepType.VideoMeeting;
  EnglishExamEnum: number = PositionStepType.EnglishExam;
  OfferStateEnum: string = PositionStepKanbanStaticTypes.Offer;
  CustomStepEnum: string = QuestionCollectionCategoryEnum.Custom;
  showBackgroundLayer: boolean = false;


  @Output() sendAverageRating: EventEmitter<number> = new EventEmitter<number>();
  @Output() applicationStepsData: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() applicationStepOceanScore: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeActiveStepInfo: EventEmitter<any> = new EventEmitter<any>();
  @Output() generalApplicationCvInfoOutput: EventEmitter<any> = new EventEmitter<any>();



  @Input() kanbanData: any;
  generalApplicationFormId : any;
  generalApplicationTabVisible: boolean = false;
  generalApplicationFormQuestions: any = [];




  constructor(private applicationStepService: ApplicationStepService,
    private localizationService: LocalizationService,
    private appInfoService: AppInfoService,
    public route: ActivatedRoute,
    public router: Router,
    private cdr: ChangeDetectorRef,
    public api: VgApiService,
    private elem: ElementRef,
    private generalApplicationQuestionService: GeneralApplicationQuestion) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(
      (params: ParamMap) => {
        this.applicationId = this.route.snapshot.queryParamMap.get('applicationId');
        this.currentStepId = this.route.snapshot.queryParamMap.get('currentPositionStep');
      }
    );
    this.applicantId = this.kanbanData.applicantId;
    this.applicationStepId = this.kanbanData.applicationStepId;
    this.positionStepTypeEnum = this.appInfoService.getPositionStepTypeArray();
    this.getApplicationSteps(0);


    this.lang = this.localizationService.langCode;
  }

  setItemData(item){
    item.averageRating = item.averageRating ?? false ? item.averageRating :  0;
    return item;
  }

  generalApplicationTab(event){
    this.generalApplicationTabVisible = event;
  }

  generalApplicationCvInfo(event){
    let item = event;
    this.generalApplicationCvInfoOutput.emit(item);
  }

  getGeneralApplicationFormId(){
    return this.applicationSteps.find(x => x.generalApplicationFormId !== null)?.generalApplicationFormId;
  }

  setAvgRating(rating: any) {
    this.updateAverageSteps();
  }

  changeActiveIndex(event) {
    this.activeIndex = (event);
    this.changeActiveStepInfo.emit(this.applicationSteps[this.activeIndex].id);
  }

  checkNextIconVisibility(): string {
    var result = document.getElementsByClassName("p-tabview-nav");
    var resultBtn = document.getElementsByClassName("p-tabview-nav-next");

    if (!(((result.item(0) as HTMLElement) ?? false) && ((resultBtn.item(0) as HTMLElement) ?? false))) {
      return 'hidden';
    }
    let lastChildrenElement = (result[0]?.children[result[0]?.children.length-2]  as HTMLElement);
    let parentElement = (result[0]?.parentElement.parentElement  as HTMLElement);

    if(lastChildrenElement){
        let tabToDisplay = lastChildrenElement.getBoundingClientRect();
        let button = (resultBtn.item(0) as HTMLElement).getBoundingClientRect();

        let elements = this.elem.nativeElement.querySelector('.p-tabview-nav-next');
        if ((tabToDisplay.right >= button.right+1 && button.right !== 0) ||
           (button.right === 1 && ((parentElement.offsetLeft + parentElement.offsetWidth + 1) < tabToDisplay.right))) {
            if (elements.classList.contains('bg-transparent')) {
                elements.classList.remove('bg-transparent');
            }

            if (elements.classList.contains('hidden')) {
                elements.classList.remove('hidden');
            }
            return '';
        }

        if (!elements.classList.contains('bg-transparent')) {
            elements.classList.add('bg-transparent');
        }

        if (!elements.classList.contains('hidden')) {
            elements.classList.add('hidden');
        }
        return 'hidden';
    }
    return 'hidden';
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.checkNextIconVisibility();
    this.checkPrevIconVisibility();

  }


  checkPrevIconVisibility(): string {
    var result = document.getElementsByClassName("p-tabview-nav-content")
    var resultBtn = document.getElementsByClassName("p-tabview-nav-prev");

    if (!(((result.item(0) as HTMLElement) ?? false) && ((resultBtn.item(0) as HTMLElement) ?? false))) {
      return 'hidden';
    }
    let tabToDisplay = (result.item(0) as HTMLElement).getBoundingClientRect();


    let button = (resultBtn.item(0) as HTMLElement).getBoundingClientRect();

    let elements = this.elem.nativeElement.querySelector('.p-tabview-nav-prev');

    if (tabToDisplay.left >= button.left) {
      if (elements.classList.contains('bg-transparent')) {
        elements.classList.remove('bg-transparent');
      }
      return '';
    }

    if (!elements.classList.contains('bg-transparent')) {
      elements.classList.add('bg-transparent');
    }
    return 'hidden';
  }

  resetAnswarPage() {
    this.answerType = null;
    this.answerContent = null;
    this.answerID = null;
    this.answerId = null;
    this.answerText = null;
    this.answer = null;
    this.choiceAnswers = null;
  }

  getTranslate(value: string) {
    return this.localizationService.translatedTextList[value];
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }


  getApplicationSteps(activeIndex) {
    if (this.applicationId ?? false) {
      this.activeIndex = activeIndex;
      this.applicationStepService.GetApplicationStepsForPosition({ applicationId: this.applicationId }).subscribe(result => {
        this.applicationSteps = result;
        if (this.currentStepId == this.OfferStateEnum) {
          const newApplicationStep = {
            order: -4,
            positionStep: {
              id: -4,
              order: -4,
              title: this.getTranslate("Offer")
            }
          };
          this.applicationSteps.push(newApplicationStep);
        }
        this.updateAverageSteps();
        this.positionStepTypes = result.map(x => {
          return this.positionStepTypeEnum.find(type => type.id === x.positionStep.type)
        });
        let index = this.applicationSteps.findIndex(element => element.id === this.applicationStepId);

        if (this.currentStepId == this.OfferStateEnum)
          index = this.applicationSteps.findIndex(element => element.order.toString() === this.OfferStateEnum);

        if (index != -1) {
          this.activeIndex = index;
        }
        this.applicationStepsData.emit(this.applicationSteps);

        this.generalApplicationFormId = this.getGeneralApplicationFormId();

        this.generalApplicationQuestionService.getListByPositionID(this.kanbanData.positionId).subscribe(data => {
          this.generalApplicationFormQuestions = data;
        });
      });
    }
  }

  updateAverageSteps() {
    let sum = 0;
    const avgCount = this.applicationSteps.filter(x => x.averageRating !== null
        && x.averageRating !== undefined
        && (x.status === ApplicationStepStatus.ApplicantCompleted || x.status === ApplicationStepStatus.HRCompleted)
        && (x.completedDate ?? false)
        ).length;
    this.applicationSteps.filter(x => x.averageRating !== null && x.averageRating !== undefined
        && (x.status === ApplicationStepStatus.ApplicantCompleted || x.status === ApplicationStepStatus.HRCompleted)
        && (x.completedDate ?? false)).forEach(x => {
      sum += x.averageRating;
    });
    this.averageSteps = (sum !== 0 || avgCount !== 0) ? sum / avgCount : 0;
    this.sendAverageRating.emit(this.averageSteps);
  }

  showRedoPopup() {
    this.popupOpen = true;
  }

  closeRedoPopup() {
    this.popupOpen = false
  }

  confirmRedo() {
    this.applicationStepService.redoStep({ id: this.applicationSteps[this.activeIndex].id }).subscribe(x => {
      this.closeRedoPopup();
      setTimeout(() => {
        window.location.reload();
      }, 500);
      // this.router.navigate(['/position/detail', this.kanbanData.positionId]);
    });
  }

  setBackgroundDarkness(event:boolean){
    this.showBackgroundLayer=event;
}

}
