import { Component, Input, Pipe, PipeTransform} from '@angular/core';
import { LocalizationService } from '@app/core/localization.service';
import { EUserWorkExperienceType } from '@app/shared/common/enums';
import { TimeService } from '@app/shared/services/time.service';
import { UserWorkExperience } from '@app/vinter/models/applicant-education-info';
import { ApplicantExperienceInfoService } from '@app/vinter/services/applicant-experience-info.service';

@Component({
  selector: 'app-applicant-work-experience',
  templateUrl: './applicant-work-experience.component.html',
  styleUrls: ['./applicant-work-experience.component.scss']
})
export class ApplicantWorkExperienceComponent {
  experienceList: UserWorkExperience[] = [];
  EUserWorkExperienceType = EUserWorkExperienceType;
  formDialogVisible: boolean = false;
  currentUserWorkExperience: UserWorkExperience;
  entityState: number = 0;
  dataLoaded: boolean = false;

  @Input() applicationId: string;

  constructor(
    private applicantExperienceInfoService: ApplicantExperienceInfoService,
    private localizationService: LocalizationService,
    private timeService: TimeService
  ) { }

  ngOnInit(): void {
    this.getApplicantWorkExperience();
  }

getApplicantWorkExperience(){
  this.applicantExperienceInfoService.getListApplicantExperienceInfo({ applicationId: this.applicationId }).subscribe((response: any) => {
    let experiences = response.map((experience: any, index: number) => {
      experience.startDate = experience.startDate ? new Date(experience.startDate) : null;
      experience.endDate = experience.endDate ? new Date(experience.endDate) : null;
      experience.formattedStartDate =  experience.startDate ? this.localizationService.translateText(this.timeService.getMonthNameAndYear(experience.startDate).monthNameStringValue) + " " + this.timeService.getMonthNameAndYear(experience.startDate).yearStringValue.toString() : null;
      experience.formattedEndDate =  experience.endDate ? this.localizationService.translateText(this.timeService.getMonthNameAndYear(experience.endDate).monthNameStringValue) + " " + this.timeService.getMonthNameAndYear(experience.endDate).yearStringValue.toString() : null;
      return experience;
    });

    experiences.sort((a, b) => {
      if (a.startDate === null) return 1;
      if (b.startDate === null) return -1;
      return b.startDate.getTime() - a.startDate.getTime();
    });

    this.experienceList = experiences;
    this.dataLoaded = true;
  });
}

}

@Pipe({
  name: 'dateDifference'
})
export class DateDifferencePipe implements PipeTransform {
  constructor(
    private localizationService: LocalizationService
  ) { }

  transform(startDate: Date, endDate: Date): string {
    const start = new Date(startDate);
    let end = new Date();
    if(endDate != null){
       end = new Date(endDate);
    }
   

    let yearDiff = end.getFullYear() - start.getFullYear();
    let monthDiff = end.getMonth() - start.getMonth();

    if (monthDiff < 0) {
      yearDiff--;
      monthDiff += 12;
    }

    let monthInfo = '';
    let yearInfo = '';
    let signBetween = '';

    if(yearDiff > 0){
      if(yearDiff > 1){
        yearInfo = `${yearDiff} ${this.localizationService.translateText("Years")}`
      }
      else{
        yearInfo = `${yearDiff} ${this.localizationService.translateText("Year")}`
      }
    }
    else{
      yearInfo = '';
    }

    if(yearDiff > 0 && monthDiff > 0){
      signBetween = ', ';
    }
    else{
      signBetween = '';
    }

    if(monthDiff > 0){
      if(monthDiff > 1){
        monthInfo = `${monthDiff} ${this.localizationService.translateText("Months")}`
      }
      else{
        monthInfo = `${monthDiff} ${this.localizationService.translateText("Month")}`
      }
    }
    else{
      monthInfo = '';
    }

    return `${yearInfo}${signBetween}${monthInfo}`;
  }
}

