<p-sidebar [(visible)]="visible" [blockScroll]="true" position="right" (onHide)="close()">
    <div class="w-full flex pt-3 pr-2">
        <div class="ml-3 col-3">
            <p-button *ngIf="positionId && (!useButtonHide || useButtonHide === null || useButtonHide === undefined)"
                        icon="pi pi-plus" label="{{ 'Key.Use' | translate }}" styleClass="buttonFont mr-2 mb-2"
                (onClick)="onButtonClick(positionId)"></p-button>
        </div>
        <div *ngIf="!showVinterLogo && !hideUseButton && isEditable" class="col-9">
            <div class="flex justify-content-center">
                <div class="w-full flex justify-content-end cursor-pointer" (click)="editTemplate()">
                    <img [src]="pencilIconPath" class="mr-2" width="18"/>
                    <p class="text-gray-600 font-medium" >{{'Key.Edit' | translate}}</p>
                </div>
                <div class="border-right-2 mx-3 mt-1 h-1rem border-gray-200"></div>
                <div class="w-auto flex justify-content-end  cursor-pointer" (click)="openDeleteDialog()">
                    <img [src]="deleteIconPath" class="mr-2" width="18"/>
                    <p class="text-red-500 font-medium" >{{'Key.Delete' | translate}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="content-wrapper mt-2 ml-3">
        <h5 data-cy="label-position-title" class="h5-font mt-0">
            {{ positionTitle }}
        </h5>
    </div>
    <div class="content-wrapper position-card-content mt-2 ml-3 mb-5" [innerHTML]="positionDescription">
    </div>
    <div class="flex ml-3">
        <div class="cardSidebar flex grid">
            <span class="flex col-12 p-0 pb-1 justify-content-center align-items-center sideBarFont">
                <img class="custom-svg-icon pr-2" src="/assets/vinter/icons/timerGray.svg" height="25px" width="25px" />
                <span style="padding-top: 0.2rem">{{"Key.TestDuration" | translate}}</span>
            </span>
            <div class="flex col-12 p-0 justify-content-center">
                <div class="lineSidebar col-12 p-0"></div>
            </div>
            <span class="flex col-12 p-0 pt-1 justify-content-center sideBarTimer">
                <span *ngIf="questionCollectionDurationInput != 0 && (questionCollectionDurationInput ?? false)"
                        class="pr-1">{{ questionCollectionDurationInput }}
                </span>
                <span *ngIf="questionCollectionDuration != 0 && (questionCollectionDuration ?? false)"
                        class="pr-1">{{ questionCollectionDuration }}
                </span>
                <span>{{ "Key.Minute" | translate }}</span>
            </span>
        </div>
        <div class="cardSidebar flex grid">
            <span class="flex col-12 p-0 pb-1 justify-content-center align-items-center sideBarFont">
                <img class="custom-svg-icon pr-2" src="/assets/vinter/icons/positionDetaillanguage.svg" height="25px"
                    width="25px" />
                <span style="padding-top: 0.2rem">{{"Key.Language" | translate }}</span>
            </span>
            <div class="flex col-12 p-0 justify-content-center">
                <div class="lineSidebar col-12 p-0"></div>
            </div>
            <span class="flex col-12 pt-1 p-0 justify-content-center sideBarTimer">
                <ng-container *ngIf="positionLanguageId === language.English">
                    {{ "Key.English" | translate }}
                </ng-container>
                <ng-container *ngIf="positionLanguageId === language.Turkish">
                    {{ "Key.Turkish" | translate }}
                </ng-container>
                <ng-template *ngIf="positionLanguageId != language.Turkish && positionLanguageId != language.English">
                    Unknown Language
                </ng-template>
            </span>
        </div>
        <div class="cardSidebar flex grid">
            <span class="flex col-12 p-0 pb-1 justify-content-center align-items-center sideBarFont">
                <img class="custom-svg-icon pr-2" src="/assets/vinter/icons/stepsIcon.svg" height="25px" width="25px" />
                <span style="padding-top: 0.2rem">{{"Key.Steps" | translate}}</span>
            </span>
            <div class="flex col-12 p-0 justify-content-center">
                <div class="lineSidebar col-12 p-0"></div>
            </div>
            <span class="flex col-12 p-0 pt-1 justify-content-center sideBarTimer">
                <span class="pr-1">{{ stepCount }} </span>
            </span>
        </div>
    </div>
    <div class="content-wrapper pt-2 mt-2 ml-3">
        <h5 class="weolcome-title">{{ "Key.WelcomeMessage" | translate }}</h5>
    </div>
    <div *ngIf="videoURL ?? false" class="grid mb-4 mt-2 ml-3">
        <div class="col-4 pl-0">
            <video src="{{ videoURL }}" style="border-radius: 6px; width: 100%" controls controlslist="nodownload"
                disablepictureinpicture></video>
        </div>
        <div class="col-8 content-wrapper" [innerHTML]="welcomeText">
        </div>
    </div>
    <div *ngIf="!isCodingQuestionLicenseValid && (expectedAnswerCodingCount > 0)" class="no-license-warning border-round-lg flex p-2 text-align-start mx-3 mb-5">
        <img src="/assets/vinter/icons/yellow-warning-icon.svg" alt="warning-icon" class= "warning-icon-style">
        <p *ngIf="isTurkish && !isCodingQuestionLicensePending" class="text-color ml-2">Bu soru listesinde coding sorusu bulunmaktadır. Planınız dahilinde o soruyu kullanmazsınız. Kod sorusunu görmek ve kullanınmak için <span class="text-blue-500 underline font-medium cursor-pointer" (click)="openLicensePlanPopup()">Planını Yükselt! <img src="/assets/vinter/icons/crown.svg" alt="" class="imgStyle"></span></p>
        <p *ngIf="!isTurkish && !isCodingQuestionLicensePending" class="text-color ml-2">There is a coding question in this question list. You will not use that question in your plan. <span class="text-blue-500 underline font-medium cursor-pointer" (click)="openLicensePlanPopup()"> Upgrade your plan <img src="/assets/vinter/icons/crown.svg" alt="" class="imgStyle"></span> to see and use the coding question!</p>
        <p *ngIf="isTurkish && isCodingQuestionLicensePending" class="text-color ml-2">Bu soru listesinde coding sorusu bulunmaktadır. Planınız dahilinde o soruyu kullanmazsınız. Kod sorusunu görmek ve kullanınmak için <span class="text-blue-500 underline font-medium cursor-pointer" (click)="openLicensePlanPopup()">Ödeme Yap! <img src="/assets/vinter/icons/crown.svg" alt="" class="imgStyle"></span></p>
        <p *ngIf="!isTurkish && isCodingQuestionLicensePending" class="text-color ml-2">There is a coding question in this question list. You will not use that question in your plan. <span class="text-blue-500 underline font-medium cursor-pointer" (click)="openLicensePlanPopup()"> Make a payment <img src="/assets/vinter/icons/crown.svg" alt="" class="imgStyle"></span> to see and use the coding question!</p>
    </div>
    <div *ngIf="!(videoURL ?? false)" class="mb-4 mt-2 ml-3">
        <div class="content-wrapper" [innerHTML]="welcomeText">
        </div>
    </div>
    <div class="pl-3" *ngIf="positionStepList?.length > 0">
        <p-tabView (activeIndexChange)="changeActiveIndex($event)" [activeIndex]="activeIndexStepTab">
            <p-tabPanel *ngIf="generalAppQuestions?.length > 0" header="{{ 'Key.GeneralApplication' | translate }}">
                <app-position-step-question-list
                    [generalAppQuestions]="generalAppQuestionsDisplay"></app-position-step-question-list>
            </p-tabPanel>
            <ng-container *ngFor="let step of positionStepList; let i = index">
                <p-tabPanel header="{{ step.title }}">
                    <ng-template pTemplate="content">
                        <div *ngIf="
                                (step.questionCollectionID !== null &&
                                    step.questionCollectionID !== undefined) ||
                                step.questionCollectionCategory !== undefined ">
                            <app-position-step-question-list
                                [isStepLangForPositionActive]="true"
                                [positionStepQuestionCollectionId]="step.questionCollectionID"
                                [positionStepDescription]="step.description"
                                [positionStep]="step"
                                [questionList]="step.questions"
                                [positionStepSubCategory]="step.subCategory"
                                [questionCollectionSubCategory]="step.subCategory"
                                [positionStepCategory]="step.questionCollectionCategory">
                            </app-position-step-question-list>
                        </div>
                        <div *ngIf="
                                (step.questionCollectionID === null ||
                                    step.questionCollectionID === undefined) &&
                                step.questionCollectionCategory === undefined
                            " class="overflow-y-auto max-h-30rem">
                            <ng-container>
                                <div class="question-container">
                                    <div class="question-content">
                                        {{ step.description }}
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-template>
                </p-tabPanel>
            </ng-container>
        </p-tabView>
    </div>
    <div *ngIf="positionStepList.length === 0" class="no-questions-message">
        {{ "Key.NoPositionStep" | translate }}
    </div>
</p-sidebar>



<p-dialog [(visible)]="deleteDialogVisible" [draggable]="false" [modal]="true" showEffect="fade"
    [style]="{width: '30vw'}" [breakpoints]="{'960px': '75vw'}" class="dialogPage"  [closable]="false">
    <div class="grid">
        <div class="col-12 flex justify-content-center">
            <img style="width: 4rem;" src="/assets/vinter/icons/delete-icons-red.svg">
        </div>
        <div class="col-12 flex justify-content-center text-center text-2xl font-bold text-red-500">
            {{'Key.TemplateDeleteHeaderMessage' | translate}}
        </div>
        <div class="col-12 flex justify-content-center text-gray-400 text-center pt-0">
            {{'Key.TemplateDeleteMessage' | translate}}
        </div>
        <div class="col-12 flex justify-content-center text-center font-bold">
            <div class="col-6 flex justify-content-end">
                <p-button class="deleteButton" label="{{'Key.Delete' | translate}}" (onClick)="deleteTemplatePosition()"
                    styleClass="mr-2 mb-2"></p-button>
            </div>

            <div class="col-6 flex justify-content-start">
                <p-button label="{{'Key.Cancel' | translate}}" (onClick)="cancel()"
                    styleClass="mr-2 mb-2 p-button-outlined"></p-button>
            </div>

        </div>
    </div>
</p-dialog>

<p-dialog 
    class="license-plan-popup" 
    [blockScroll]="true" 
    showEffect="fade" 
    [draggable]="false" 
    [resizable]="false" 
    [(visible)]="isShowLicensePlanPopup" 
    [modal]="false"
    [style]="{width: '38rem', height: '75rem'}" 
    [contentStyle]="{overflow: 'auto'}">
    <app-license-plan-dialog [suggestedPackageType]="suggestedPackageType"></app-license-plan-dialog>
</p-dialog>
