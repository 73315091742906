import { Component, Input, OnInit } from '@angular/core';
import { LocalizationService } from '@app/core';
import { EQuestionCategories, EQuestionCollectionSubCategory, QuestionCollectionCategoryEnum, QuestionExpectedAnswerType } from '@app/shared/common/enums';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { ApplicationStepService } from '@app/vinter/services/application-step.service';

@Component({
  selector: 'app-foreign-language-step-result',
  templateUrl: './foreign-language-step-result.component.html',
  styleUrls: ['./foreign-language-step-result.component.scss']
})
export class ForeignLanguageStepResultComponent implements OnInit{
  
  @Input() applicationStepId: string;

  stepInfo: any;
  filteredStepInfo: any;

  iconPath = 'assets/vinter/icons/';
  iconPathEnding = '-icon-circled.svg';
  questionCollectionLanguageList: any[] = [];
  questionCollectionCategories = QuestionCollectionCategoryEnum;
  questionExpectedAnswerType = QuestionExpectedAnswerType;
  questionCollectionSubCategory = EQuestionCollectionSubCategory;
  languageQuestionCategories = EQuestionCategories;
  sidebarVisible: boolean = false;
  tempquestionCollectionQuestionRelations: any;

  scores: { [key: string]: number } = {};
  scoreList: any[] = [];
  isGeneralEnglishTest: boolean = false;
  checkSpeakingCount: number = 0;
  categoryValidation: {} = null;

  selectedFilters = [
    this.languageQuestionCategories.Grammer,
    this.languageQuestionCategories.Listening,
    this.languageQuestionCategories.Pronunciation,
    this.languageQuestionCategories.Reading,
    this.languageQuestionCategories.Speaking,
    this.languageQuestionCategories.Vocabulary,
    this.languageQuestionCategories.Writing,
  ];
  
  constructor(private applicationStepService :ApplicationStepService,
    private localizationService: LocalizationService,
    private appInfoService: AppInfoService
   ) {
  }

  ngOnInit(): void {
    this.questionCollectionLanguageList = this.appInfoService.getQuestionCollectionLanguageList();
    this.getResultData();
  }

  getResultData(){
    this.applicationStepService.GetApplicationStepResult({ id: this.applicationStepId }).subscribe(result => {
      this.stepInfo = result;
      this.stepInfo.questionCollectionResult.questionCollectionQuestionRelations.forEach(element => {
        element.question["questionCategorySvgPath"] = this.iconPath + this.getQuestionTypeSvgIconRef(element.question.questionCategory) + this.iconPathEnding; 
        element.question["svgAlt"] = this.getQuestionTypeSvgIconRef(element.question.questionCategory);
        element.question["shortLangText"] = this.getShortLangText(element.question.languageID);
        element.question["progressBarColor"] = this.getProgressBarColor(element.question.gptScore);
        element.question["scoreIntPart"] = this.getIntegerPart(element.question.gptScore);
        element.question["scoreDecPart"] = this.getDecimalPart(element.question.gptScore);
        element["isAnyCheatLogExist"] = this.checkIfQuestionLogExists(element);
      });
      this.stepInfo.questionCollectionResult["isTechnicalOrCompetenceTest"] = this.checkTechnicalOrCompetenceTest(this.stepInfo.questionCollectionResult.questionCollectionCategory);
      this.filteredStepInfo = this.stepInfo;
      this.tempquestionCollectionQuestionRelations = this.stepInfo.questionCollectionResult.questionCollectionQuestionRelations;
      this.setList();
      this.initializeCategoryValidation();


    });
  }

  setList(){
    if (this.filteredStepInfo?.questionCollectionResult?.subCategory === this.questionCollectionSubCategory.GeneralEnglish) {
      this.initializeCategoryValidation();
      this.isGeneralEnglishTest = true;

      this.scoreList = [
        { key: 'generalEnglishWriting', iconKey: 'writing', icon: 'writingCategoryColor.svg', color: '#2D4ADE', levelKey: 'writingLevel' },
        { key: 'generalEnglishListening', iconKey: 'listening', icon: 'listeningCategoryColor.svg', color: '#9B2DDE', levelKey: 'listeningLevel' },
        { key: 'generalEnglishReading', iconKey: 'reading', icon: 'readingCategoryColor.svg', color: '#6931DF', levelKey: 'readingLevel' },
        { key: 'generalEnglishGrammerVocabulary', iconKey: 'grammer', icon: 'grammerCategoryColor.svg', color: '#2E75FF', levelKey: 'grammarVocabularyLevel' },
        { key: 'generalEnglishSpeaking', iconKey: 'speaking', icon: 'speakingCategoryColor.svg', color: '#D11ABF', levelKey: 'speakingLevel' }
      ];
    } else {
      this.scoreList = [
        { key: 'writing', icon: 'writing', color: '#2D4ADE' },
        { key: 'listening', icon: 'listening', color: '#9B2DDE' },
        { key: 'reading', icon: 'reading', color: '#6931DF' },
        { key: 'grammer', icon: 'grammer', color: '#2E75FF' },
        { key: 'vocabulary', icon: 'vocabulary', color: '#7752FE' }
      ];
    }

    this.calculateScores();
  }

  private calculateScores(): void {
    const qRelations = this.filteredStepInfo?.questionCollectionResult?.questionCollectionQuestionRelations || [];
    const scoreData: { [key: string]: { count: number, total: number } } = {};

    // Tüm kategoriler için skorları topla
    this.scoreList.forEach(score => {
      scoreData[score.key] = { count: 0, total: 0 };
    });

    qRelations.forEach(element => {
      const category = this.getCategoryKey(element.question.questionCategory);
      if (category && scoreData[category]) {
        scoreData[category].count++;
        scoreData[category].total += element.question.gptScore;
      }
    });

    this.checkSpeakingCount = scoreData['speaking']?.count || 0;

    // Ortalama skor hesapla
    Object.keys(scoreData).forEach(key => {
      const { count, total } = scoreData[key];
      this.scores[key] = count > 0 ? total / count : 0;
    });

    // Genel İngilizce gramer/vocabulary için özel hesaplama
    if (this.isGeneralEnglishTest && (scoreData['grammer'].count + scoreData['vocabulary'].count > 0)) {
      this.scores['generalEnglishGrammerVocabulary'] =
        (scoreData['grammer'].total + scoreData['vocabulary'].total) /
        (scoreData['grammer'].count + scoreData['vocabulary'].count);
    }
  }

  private getCategoryKey(category: EQuestionCategories): string | null {
    switch (category) {
      case EQuestionCategories.Writing: return this.isGeneralEnglishTest ? 'generalEnglishWriting' : 'writing';
      case EQuestionCategories.Listening: return this.isGeneralEnglishTest ? 'generalEnglishListening' : 'listening';
      case EQuestionCategories.Reading: return this.isGeneralEnglishTest ? 'generalEnglishReading' : 'reading';
      case EQuestionCategories.Grammer: return this.isGeneralEnglishTest ? 'generalEnglishGrammer' : 'grammer';
      case EQuestionCategories.Vocabulary: return this.isGeneralEnglishTest ? 'generalEnglishVocabulary' : 'vocabulary';
      case EQuestionCategories.Speaking: return 'generalEnglishSpeaking';
      default: return null;
    }
  }

  checkIfQuestionLogExists(relation){
    return !!(relation?.isFaceDetected || 
      relation?.isMultipleFaceDetected || 
      relation?.isImproprietySpeech || 
      relation?.isSwitchedTab || 
      relation?.isMultipleMonitor);
  }

  checkTechnicalOrCompetenceTest(category){
    return (category === this.questionCollectionCategories.TechnicalTest || category == this.questionCollectionCategories.CompetenceTest) ? true: false;
  }

  getQuestionTypeSvgIconRef(type){
    return (type ?? false) ? Object.keys(EQuestionCategories)[Object.values(EQuestionCategories).indexOf(type)].toLowerCase() : null;
  }

  getShortLangText(languageID){
    const keyName = this.questionCollectionLanguageList.find(x=> x.id === languageID)?.name;
    const text = keyName ?? false ? this.localizationService.translatedTextList['QCollectionShort'+keyName] : '';
    return text;
  }

  getProgressBarColor(score: number): string {
    if (score <= 10) return '#F20505';
    if (score <= 20) return '#F55E06';
    if (score <= 30) return '#FF8307';
    if (score <= 40) return '#FDBA04';
    if (score <= 50) return '#FBDF0B';
    if (score <= 60) return '#D7EE06';
    if (score <= 70) return '#AFE705';
    if (score <= 80) return '#6FE819';
    if (score <= 90) return '#01CE2D';
    return '#16BA13';
  }

  getIntegerPart(score: number): string {
    let value = "00";
    if(score > 0)
      value = Math.floor(score).toString();
    return value;
  }

  getDecimalPart(score: number): string {
    const decimalPart = score.toFixed(2).split('.')[1];
    return decimalPart || '00'; 
  }

  filterListByAnswerType(answerType) {
    const index = this.selectedFilters.indexOf(answerType);
    
    if (index > -1) {
      this.selectedFilters.splice(index, 1);
    } else {
      this.selectedFilters.push(answerType);
    }
  
    this.filteredStepInfo.questionCollectionResult.questionCollectionQuestionRelations =this.tempquestionCollectionQuestionRelations
      .filter(item => this.selectedFilters.includes(item.question.questionCategory))
      .sort((a, b) => a.Order - b.Order);
  }
  
  initializeCategoryValidation() {
    this.categoryValidation = {};
    Object.values(this.languageQuestionCategories).forEach(category => {
      this.categoryValidation[category] = false;
    });
    const relations = this.stepInfo?.questionCollectionResult?.questionCollectionQuestionRelations || [];
    relations.forEach(relation => {
      const category = relation.question?.questionCategory;
      if (category && Object.values(this.languageQuestionCategories).includes(category)) {
        this.categoryValidation[category] = true;
      }
    });
  }
  
}
