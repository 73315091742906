import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { LocalizationService } from "@app/core";

@Injectable({ providedIn: 'root' })
export class TimeService {

    constructor(private localizationService: LocalizationService) {
    }
    translateTimeSpan(timeSpanString: string): string {
        const timeParts = timeSpanString.split(':');

        const days = parseInt(timeParts[0], 10);
        const hours = parseInt(timeParts[1], 10);
        const minutes = parseInt(timeParts[2], 10);

        let formattedString = '';

        if (days > 0) {
            const daysTranslation = this.localizationService.translateText('Days');
            formattedString += `${days} ${daysTranslation} `;
        }

        if (hours > 0) {
            const hoursTranslation = this.localizationService.translateText('Hours');
            formattedString += `${hours} ${hoursTranslation} `;
        }

        if (minutes > 0) {
            const minutesTranslation = this.localizationService.translateText('Minutes');
            formattedString += `${minutes} ${minutesTranslation}`;
        }

        return formattedString.trim();
    }
    getMonthNameAndYear(dateString: string): any {
        const date = new Date(dateString);
        var pipe =  new DatePipe("en-EN");
        const monthNameStringValue = pipe.transform(date, 'MMMM');  
        const yearStringValue = pipe.transform(date, 'yyyy');
        return {monthNameStringValue,yearStringValue}
      }
}
