<div *ngIf="applicationId !== null">
    <app-applicant-info *ngIf="(kanbanList ?? false) && (applicationStepsDataPdf ?? false)" [overallRating]="avgRating"
        [applicationStepsData]="applicationStepsDataPdf" [currentStepId]="currentStepId"
        (positionStepIdOutput)="getUpdatedPositionStepId($event)" [applicationId]="applicationId"
        [kanbanData]="kanbanList" [generalApplicationFormInfo]="generalApplicationForm" ></app-applicant-info>
    <app-applicant-details *ngIf="kanbanList ?? false" (sendAverageRating)="setAvgRating($event)"
        [kanbanData]="kanbanList" (applicationStepsData)="getApplicationStepsData($event)"
        (applicationStepOceanScore)="getOceanScore($event)"
        (generalApplicationCvInfoOutput)="getGeneralApplicationCvInfo($event)"></app-applicant-details>
</div>
