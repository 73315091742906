import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { LocalizationService } from '@app/core';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { ApplicantBehaviourEventLogService } from '@app/vinter/services/applicant-behaviour-event-log.service';
import { ApplicationStepService } from '@app/vinter/services/application-step.service';
import { QuestionService } from '@app/vinter/services/question.service';
import { RatingService } from '@app/vinter/services/rating.service';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-step-behaiviour-log-info',
  templateUrl: './step-behaiviour-log-info.component.html',
  styleUrls: ['./step-behaiviour-log-info.component.scss']
})
export class StepBehaiviourLogInfo implements OnInit{

  @Input() item: any;
  @Input() stepBehaviourEventLogs=[];
  @Input() cheatingQuestionLog: any[]
  @Input() selectedStars: any[];
  deviceTypes:any[];
  @Output() onCheatingQuestionLogSetted: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private applicationStepService: ApplicationStepService,
    private eventLogService:ApplicantBehaviourEventLogService,
    private messageService: MessageService,
    private localizationService: LocalizationService,
    private appInfoService: AppInfoService,
    private ratingService: RatingService,
    private questionService: QuestionService,
    public api: VgApiService) { }

  ngOnInit(): void {
    this.deviceTypes = this.appInfoService.getDeviceTypes();
  }


}
