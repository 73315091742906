<div *ngIf="dataLoaded" class="grid">
    <div *ngIf="!unPaidLicense && !noLicense" class="flex flex-wrap grid licenseBorder">
        <div class="flex flex-column p-3 m-5 border-1 border-gray-100 w-full border-round-md gap-3">
            <div class="flex align-items-center justify-content-between w-full gap-6">
                <div class="flex gap-5 align-items-center">
                    <div class="pt-0 flex-row flex align-items-center">
                        <img src="assets/vinter/images/licence-plan-green-icon.svg" alt="vinter"
                            class="mr-2 ml-2 block header-icon" />
                        <p class="mb-0 plan-name">{{ organizationValidLicensePlans[0]?.licenceName }}</p>
                    </div>
                    <span class="border-right-1 h-3rem border-gray-50"></span>
                    <div class="justify-content-start flex-column flex">
                        <p class="card-text relative">
                            <span>{{'Key.Subscription' | translate}}</span>
                            <span class="card-line"></span>
                        </p>
                        <p *ngIf="organizationValidLicensePlans[0]?.applicantLimit !== null"
                            class="text-gray-400 font-semibold">
                            <span class="text-blue-300">{{organizationValidLicensePlans[0]?.subscriptionTypeName}}
                            </span>
                        </p>
                    </div>
                </div>

                <p-button
                    *ngIf="(isUserOrgAdmin && !organizationValidLicensePlans[0]?.isCustomerUnsubscribed && !organizationValidLicensePlans[0]?.isFreeTrial)"
                    (click)="openLicensePlanDialog()" label="{{'Key.Upgrade' | translate}}" styleClass="buttonFont"
                    icon="pi pi-arrow-up-right" iconPos="right"></p-button>
                <!-- free trialdan customera gecis yapmıssa da upgrade ve endSubscription butonu gorunmeli -->
                <p-button
                    *ngIf="(isUserOrgAdmin && organizationValidLicensePlans[0]?.isCustomerUnsubscribed) || organizationValidLicensePlans[0]?.isFreeTrial"
                    (click)="openLicensePlanDialog()" label="{{'Key.ChoosePlan' | translate}}" styleClass="buttonFont"
                    icon="pi pi-arrow-up-right" iconPos="right"></p-button>

            </div>

            <div class="border-top-1 border-gray-50 w-full"></div>

            <div class="flex align-items-center w-full border-round-md licenseInfo gap-4 p-2">
                <div class="justify-content-start flex-column flex">
                    <p class="card-text relative">
                        <span class="white-space-nowrap	">{{ "Key.PackageStart" | translate }}</span>
                        <span class="card-line"></span>
                    </p>
                    <p *ngIf="organizationValidLicensePlans[0]?.activationDate !== null"
                        class="text-gray-400 font-semibold">
                        {{ organizationValidLicensePlans[0]?.activationDate | date:'dd/MM/yy' }}
                    </p>
                    <p *ngIf="organizationValidLicensePlans[0]?.activationDate === null"
                        class="text-gray-400 font-semibold">
                        -
                    </p>
                </div>
                <span *ngIf="organizationValidLicensePlans[0]?.expirationDate !== null"
                    class="border-right-1 h-3rem border-gray-50"></span>
                <div *ngIf="organizationValidLicensePlans[0]?.expirationDate !== null"
                    class="justify-content-start flex-column flex">
                    <p class="card-text relative">
                        <span class="white-space-nowrap	">{{ "Key.EndDate" | translate }}</span>
                        <span class="card-line"></span>
                    </p>
                    <p *ngIf="organizationValidLicensePlans[0]?.expirationDate !== null"
                        class="text-gray-400 font-semibold">
                        {{ organizationValidLicensePlans[0]?.expirationDate | date:'dd/MM/yy' }}
                    </p>
                    <p *ngIf="organizationValidLicensePlans[0]?.expirationDate === null"
                        class="text-gray-400 font-semibold">
                        -
                    </p>
                </div>
                <span class="border-right-1 h-3rem border-gray-50"></span>
                <div class="w-full flex">
                    <div *ngFor="let license of organizationValidLicensePlans let i = index; last as isLast"
                        [ngClass]="{'hidden': license.applicantUsage === license.applicantLimit && i !== 0,
                        'flex': !(license.applicantUsage === license.applicantLimit && i !== 0),'gap-7': shownLicenseCount === 2}" class="align-items-center gap-5 w-full justify-content-end">
                        <div [ngClass]="{'ml-4': i !== 0}" class="flex flex-row gap-3 align-items-center">
                            <div class="p-knob-element">
                                <p-knob [size]="60" class="knob" [(ngModel)]="license.applicantRate"
                                    valueTemplate="{value}%" [readonly]="true"></p-knob>
                            </div>
                            <div class="justify-content-start flex-column flex">
                                <p class="card-text relative">
                                    <span class="white-space-nowrap	">{{ "Key.Applicants" | translate }}<span
                                            *ngIf="i !== 0">*</span></span>
                                    <span class="card-line"></span>
                                </p>

                                <p class="text-blue-500 font-semibold">{{license.applicantUsage}}<span
                                        class="text-gray-400">/{{license.applicantLimit}}</span></p>
                            </div>
                        </div>
                        <span
                            *ngIf="!isLast && organizationValidLicensePlans[i+1].applicantUsage !== organizationValidLicensePlans[i+1].applicantLimit"
                            class="text-gray-100 text-2xl">+</span>
                    </div>
                </div>

            </div>
            <div *ngIf="organizationValidLicensePlans[0]?.isCustomerUnsubscribed && currentLanguageId === LanguageEnum.EnglishUnitedStates"
                class="flex justify-content-start text-sm text-gray-400 pl-1">
                *You can use your license until <span class="text-blue-500 px-1"> {{licenseExpirationDate |
                    date:'dd/MM/yy'}} </span></div>
            <div *ngIf="organizationValidLicensePlans[0]?.isCustomerUnsubscribed && currentLanguageId === LanguageEnum.TurkishTurkey"
                class="flex justify-content-start text-sm text-gray-400 pl-1">
                *Lisansınızı <span class="text-blue-500 px-1"> {{licenseExpirationDate | date:'dd/MM/yy'}} </span>
                tarihine kadar kullanabilirsiniz.</div>
            <div *ngIf="!organizationValidLicensePlans[0]?.isCustomerUnsubscribed && !organizationValidLicensePlans[0]?.isFreeTrial"
                class="mt-3 flex justify-content-end underline text-sm unsubscribe-text font-medium cursor-pointer"
                (click)="showLicenseUnsubscribeButton()">{{ "Key.Unsubscribe" | translate }}</div>

            <ng-container *ngFor="let license of organizationValidLicensePlans let i = index;">
                <div
                    *ngIf="license.activationDate !== null && license.expirationDate !== null && i !== 0 && license.applicantUsage !== license.applicantLimit">
                    <p *ngIf="currentLanguageId === LanguageEnum.EnglishUnitedStates"
                        class="-mt-6 text-gray-400 font-normal	text-xs">
                        *Your <span class="text-blue-400">{{license.licenceName}}</span> package has applicant credits
                        remaining valid from <span class="text-blue-500">{{license.activationDate | date:'dd/MM/yy'}} to
                            {{license.expirationDate | date:'dd/MM/yy'}}.</span>
                    </p>
                    <p *ngIf="currentLanguageId === LanguageEnum.TurkishTurkey"
                        class="-mt-6 text-gray-400 font-normal text-xs">
                        <span class="text-blue-400">*{{license.licenceName}}</span> paketinizin <span
                            class="text-blue-500">{{license.activationDate | date:'dd/MM/yy'}} to
                            {{license.expirationDate | date:'dd/MM/yy'}}</span> tarihleri arasında geçerli başvuru
                        kredileri bulunmaktadır.
                    </p>
                </div>
            </ng-container>
        </div>
    </div>

    <div *ngIf="unPaidLicense && !noLicense" class="flex flex-wrap grid licenseBorder">
        <div class="flex flex-column p-3 m-5 border-1 border-gray-100 w-full border-round-md gap-3">
            <div class="flex align-items-center justify-content-between w-full gap-6">
                <div class="flex gap-5 align-items-center">
                    <div class="pt-0 flex-row flex align-items-center">
                        <img src="assets/vinter/images/licence-plan-green-icon.svg" alt="vinter"
                            class="mr-2 ml-2 block header-icon" />
                        <p class="mb-0 plan-name">{{ organizationPendinLicensePlans[0].licenceName }}</p>
                    </div>
                    <span class="border-right-1 h-3rem border-gray-50"></span>
                    <div class="justify-content-start flex-column flex">
                        <p class="card-text relative">
                            <span>{{'Key.Subscription' | translate}}</span>
                            <span class="card-line"></span>
                        </p>
                        <p *ngIf="organizationPendinLicensePlans[0].applicantLimit !== null"
                            class="text-gray-400 font-semibold">
                            <span class="text-blue-300">{{organizationPendinLicensePlans[0].subscriptionTypeName}}
                            </span>
                        </p>
                    </div>
                </div>

                <p-button *ngIf="isUserOrgAdmin" (click)="redirectToStripeCheckoutSession(licenseOrganizationInfoIds)"
                    label="{{'Key.GoCheckOut' | translate}}" styleClass="buttonFont" icon="pi pi-arrow-up-right"
                    iconPos="right"></p-button>
            </div>

            <div class="border-top-1 border-gray-50 w-full"></div>

            <div class="flex align-items-center w-full border-round-md licenseInfo pendingLicense gap-4 p-2">
                <div class="justify-content-start flex-column flex">
                    <p class="card-text relative">
                        <span class="white-space-nowrap	">{{ "Key.PackageStart" | translate }}</span>
                        <span class="card-line"></span>
                    </p>
                    <p *ngIf="organizationPendinLicensePlans[0].activationDate !== null"
                        class="text-gray-400 font-semibold">
                        {{ organizationPendinLicensePlans[0].activationDate | date:'dd/MM/yy' }}
                    </p>
                    <p *ngIf="organizationPendinLicensePlans[0].activationDate === null"
                        class="text-gray-400 font-semibold">
                        -
                    </p>
                </div>
                <span class="border-right-1 h-3rem border-gray-50"></span>
                <div class="justify-content-start flex-column flex">
                    <p class="card-text relative">
                        <span class="white-space-nowrap	">{{ "Key.EndDate" | translate }}</span>
                        <span class="card-line"></span>
                    </p>
                    <p *ngIf="organizationPendinLicensePlans[0].expirationDate !== null"
                        class="text-gray-400 font-semibold">
                        {{ organizationPendinLicensePlans[0].expirationDate | date:'dd/MM/yy' }}
                    </p>
                    <p *ngIf="organizationPendinLicensePlans[0].expirationDate === null"
                        class="text-gray-400 font-semibold">
                        -
                    </p>
                </div>
                <span class="border-right-1 h-3rem border-gray-50"></span>
                <div class="flex align-items-center gap-4 w-full justify-content-end">
                    <div class="flex flex-row gap-3 align-items-center">
                        <div class="p-knob-element">
                            <p-knob [size]="60" class="knob"
                                [(ngModel)]="organizationPendinLicensePlans[0].applicantRate" valueTemplate="{value}%"
                                [readonly]="true"></p-knob>
                        </div>
                        <div class="justify-content-start flex-column flex">
                            <p class="card-text relative">
                                <span class="white-space-nowrap	">{{ "Key.Applicants" | translate }}</span>
                                <span class="card-line"></span>
                            </p>
                            <p class="text-blue-500 font-semibold">
                                {{organizationPendinLicensePlans[0].applicantUsage}}<span
                                    class="text-gray-400">/{{organizationPendinLicensePlans[0].applicantLimit}}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!isTriedPayment && !isPaymentFailed">
                <p class="text-gray-400 font-normal	text-xs">
                    {{ "Key.PendingLicenseDescription" | translate }}
                </p>
            </div>
            <div *ngIf="isTriedPayment && isPaymentFailed">
                <div class="flex justify-content-start align-items-center">
                    <img src="../../../../../../assets/vinter/icons/stripeCheckoutFailed.svg" alt="checkout-failed">
                    <div class="flex justify-content-start ml-2 mt-1">
                        <p class="checkout-failed-warning-text font-semibold m-0">
                            {{ "Key.CheckoutFailedWarningTitle" | translate }}
                        </p>
                        <p class="checkout-failed-warning-text text-sm ml-1 checkoutNeededText">
                            {{ "Key.CheckoutFailedWarningDescription" | translate }}
                        </p>
                    </div>
                              
                </div>
            </div>
            <div *ngIf="!isCheckoutSuccessful && isTriedPayment" class="flex justify-content-start align-items-center">
                <img src="../../../../../../assets/vinter/icons/stripeCheckoutFailed.svg" alt="checkout-failed">
                <div class="flex justify-content-start ml-2 mt-1">
                    <p class="checkout-failed-warning-text font-semibold m-0">
                        {{ "Key.CheckoutFailedWarningTitle" | translate }}
                    </p>
                    <p class="checkout-failed-warning-text text-sm ml-1 checkoutNeededText">
                        {{ "Key.CheckoutFailedWarningDescription" | translate }}
                    </p>
                </div>
            </div>
            <div *ngIf="!isUnsubscriptionSuccessful && (!isCheckoutSuccessful && isTriedPayment) && isPaymentFailed"
                class="flex justify-content-end underline text-sm unsubscribe-text font-medium cursor-pointer"
                (click)="showLicenseUnsubscribeButton()">{{ "Key.Unsubscribe" | translate }}</div>

        </div>
    </div>


    <div *ngIf="noLicense" class="flex flex-wrap grid licenseBorder">
        <div class="flex flex-column p-3 m-5 border-1 border-gray-100 w-full border-round-md gap-3">
            <div class="flex align-items-center justify-content-between w-full gap-6">
                <div class="flex gap-5 align-items-center">
                    <div class="flex-row flex align-items-center no-license-background">
                        <img src="assets/vinter/icons/yellow-warning-icon.svg" alt="vinter"
                            class="mr-2 block warning-icon" />
                        <p class="mb-0 no-license text-base font-semibold">{{ "Key.NoLicensePlan" | translate }}</p>
                    </div>
                </div>

                <p-button *ngIf="isUserOrgAdmin" (click)="openLicensePlanDialog()"
                    label="{{'Key.ChoosePlan' | translate}}" styleClass="buttonFont" icon="pi pi-arrow-up-right"
                    iconPos="right"></p-button>
            </div>

            <div class="border-top-1 border-gray-50 w-full"></div>

            <div>
                <p class="text-gray-400 font-normal	text-sm">
                    {{ "Key.NoLicenseDescription" | translate }}
                </p>
            </div>
        </div>
    </div>



</div>

<p-dialog class="license-plan-popup" [blockScroll]="true" showEffect="fade" [draggable]="false" [resizable]="false"
    [(visible)]="licensePlanDialogVisible" [modal]="true">
    <app-license-plan-dialog (dialogVisibiltyEmitter)="templateVisiblity($event)"></app-license-plan-dialog>
</p-dialog>

<app-license-plan-unsubscribe-dialog [(isShow)]="isShow" [licenseOrgInfoId]="licenseOrgInfoId"
    (isUnsubscriptionSuccessful)="onUnsubscribed($event)"
    (refreshPage)="onRefreshPage()"></app-license-plan-unsubscribe-dialog>