import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {QuestionCollectionCategoryEnum, QuestionExpectedAnswerType} from '@app/shared/common/enums';
import { CodingAnswerItem } from '@app/vinter/models/answer/coding-answer-item';
import { ForeignLanguageOpenEndedAnswerItem } from '@app/vinter/models/answer/foreign-language-open-ended-asnwer-item';
import { ForeignLanguageVideoAnswerItem } from '@app/vinter/models/answer/foreign-language-video-answer-item';
import { MultipleChoiceAnswerItem } from '@app/vinter/models/answer/multiple-choice-answer-item';
import { OpenEndedAnswerItem } from '@app/vinter/models/answer/open-ended-answer-item';
import { PronunciationVideoAnswerItem } from '@app/vinter/models/answer/pronunciation-video-answer-item';
import { VideoAnswerItem } from '@app/vinter/models/answer/video-answer-item';
import { QuestionListItem } from '@app/vinter/models/question-list-item';
import { AnswerService } from '@app/vinter/services/answer.service';

@Component({
  selector: 'app-answer-base-component',
  templateUrl: './answer-base-component.component.html',
  styleUrls: ['./answer-base-component.component.scss']
})
export class AnswerBaseComponentComponent implements OnInit,OnChanges{
  
 @Input() question: QuestionListItem;
 @Input() codingAnswer: CodingAnswerItem;
 @Input() multipleChoiceAnswer: MultipleChoiceAnswerItem;
 @Input() openEndedAnswer: OpenEndedAnswerItem;
 @Input() videoAnswer: VideoAnswerItem;
 @Input() speakingAnswer: ForeignLanguageVideoAnswerItem;
 @Input() pronunciationVideoAnswer: PronunciationVideoAnswerItem;
 @Input() writingAnswer: ForeignLanguageOpenEndedAnswerItem;

 answerType: number;
 @Input() questionCollectionCategoryId: string;

 AnswerTypeEnum = QuestionExpectedAnswerType;
 QuestionCollectionCategoryEnum = QuestionCollectionCategoryEnum;

  constructor(private answerService: AnswerService) { }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.question){
      this.answerType = this.question?.expectedAnswerType;
    }
  }


  ngOnInit(): void {
    if(this.question){
      this.answerType = this.question?.expectedAnswerType;
    }
  }

}
