import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-department-check-dialog',
  templateUrl: './department-check-dialog.component.html',
  styleUrls: ['./department-check-dialog.component.scss']
})
export class DepartmentCheckDialogComponent {

  constructor(private router: Router, private route: ActivatedRoute) { }

  @Output() dialogVisibiltyEmitter: EventEmitter<void> = new EventEmitter<void>();

  closeDialog() {
    this.dialogVisibiltyEmitter.emit();
  }
  goToSettings() {
    this.router.navigate([this.route.snapshot.queryParams.redirect || '/organization/settings'], {
      replaceUrl: true, queryParams: {
        activeTab: 1,
      }
    });
  }
}
