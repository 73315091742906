import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { CredentialsService, LocalizationService } from '@app/core';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { PositionStepService } from '@app/vinter/services/position-step.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PositionStepListItem } from '@app/vinter/models/position-step-list';
import { PositionService } from '@app/vinter/services/position.service';
import { ELanguages, ESuggestedPackageType, EntityStateEnum, LanguageEnum, PositionFormEditModeEnum, PositionStepType, PositionStepTypeEnum, QuestionExpectedAnswerType } from '@app/shared/common/enums';
import { vinterPureLogoRotatedPath } from '@app/shared/common/constants';
import { GeneralApplicationQuestionListItem } from '@app/vinter/models/generalApplicationQuestion-list-item';
import { PositionFormItem } from '@app/vinter/models/position-form-item';

@Component({
  selector: 'app-position-preview',
  templateUrl: './position-preview.component.html',
  styleUrls: ['./position-preview.component.scss']
})
export class PositionPreviewComponent {
  visible: boolean =true;
  positionStepList: any []=[];
  activeIndex = 0;
  activeIndexStepTab = 0;
  positionStepTypeEnum = PositionStepTypeEnum;
  vinterLogoPath: string = vinterPureLogoRotatedPath;
  questionCollectionDuration:number = 0;
  language=ELanguages;
  questionContentTypes: any[] = [];
  questionExpectedAnswerTypeEnum = QuestionExpectedAnswerType;
  generalAppQuestionsDisplay: GeneralApplicationQuestionListItem[] = [];;
  useButtonHide: boolean = false;
  isCodingQuestionLicenseValid = false;
  isCodingQuestionLicensePending = false;
  deleteDialogVisible: boolean = false;
  languageId: string
  isTurkish: boolean
  pencilIconPath ="/assets/vinter/icons/position-preview-edit.svg";
  deleteIconPath ="/assets/vinter/icons/delete-icons-red.svg";
  isShowLicensePlanPopup: boolean
  suggestedPackageType = ESuggestedPackageType.Coding;
  expectedAnswerCodingCount : number = 0;
  @Input() positionId;
  @Input() positionTitle;
  @Input() welcomeText;
  @Input() isEditable = false;
  @Input() positionDescription;
  @Input() showVinterLogo;
  @Input() positionLanguageId;
  @Input() stepCount;
  @Input() videoURL;
  @Input() stepList;
  @Input() questionCollectionDurationInput;
  @Input() generalAppQuestions;
  @Input() hideUseButton;
  @Input() deletedItem;
  @Output() dialogVisible: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private positionStepService: PositionStepService,
    private positionService: PositionService,
    private credentialService: CredentialsService,
    private appInfoService: AppInfoService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.isCodingQuestionLicenseValid = this.credentialService.getIsExistCodingQuestionLicense();
    this.isCodingQuestionLicensePending = this.credentialService.getIsCodingLicensePending();
    this.useButtonHide = this.hideUseButton;
    this.languageId = this.credentialService.getLanguage();
    this.isTurkish = this.languageId === LanguageEnum.TurkishTurkey;
    this.questionContentTypes = this.appInfoService.getPositionStepTypeEnumArray().map(item=>item.name);
    this.setStepList();
    this.generalAppQuestionsDisplay = this.generalAppQuestions?.filter(x => x.entityState != EntityStateEnum.Deleted);
  }

  onActiveIndexChange(event: number) {
    this.activeIndex = event;
  }
  editTemplate(){
    this.router.navigate(['/position/edit'], {
        queryParams: {
            positionId: this.positionId,
            positionEditFormMode: PositionFormEditModeEnum.TemplateEditMode
        }
      });
  }

  setStepList(){
    if(this.positionId != null || this.positionId != undefined){
      this.positionStepService.getListByFilter({positionId: this.positionId}).subscribe(data => {
        this.positionStepList=data;
        this.expectedAnswerCodingCount =  this.positionStepList[0]?.expectedAnswerCodingCount;
        this.positionStepList.forEach(element => {
          this.questionCollectionDuration += element.duration;
        });
      });
    }
    else{
      this.positionStepList = this.stepList;
      this.expectedAnswerCodingCount =  this.positionStepList[0]?.expectedAnswerCodingCount;
    }
  }

  setActiveIndex(selectedItem){
    this.activeIndex = selectedItem;
  }
  openLicensePlanPopup(){
    if(this.isCodingQuestionLicensePending){
      this.router.navigate(['organization/settings'], {
        queryParams: {
          activeTab: 3,
        }
      });
    }
    else{
      this.isShowLicensePlanPopup = !this.isShowLicensePlanPopup;
    }
  }

  close(){
    this.visible = false
    this.activeIndex = 0;
    this.dialogVisible.emit(false);
  }

  onButtonClick(positionId ) {
    this.router.navigate(['/position/edit'], {
      queryParams: {
        positionId: positionId,
        positionEditFormMode: PositionFormEditModeEnum.UseFromMode,
        isVinter: this.showVinterLogo,
      }
    });
  }
  changeActiveIndex(event) {
    this.activeIndexStepTab = (event);
    this.expectedAnswerCodingCount =  this.positionStepList[event]?.expectedAnswerCodingCount;
  }

  openDeleteDialog(){
    this.deleteDialogVisible = true;
  }

  deleteTemplatePosition(){
    this.positionService.deleteTemplate({id : this.positionId}).subscribe(data => {
      if(data !== null){
        this.cancel();
        window.location.reload();
      }

    });
  }

  cancel() {
    this.deleteDialogVisible = !this.deleteDialogVisible;
  }
}
