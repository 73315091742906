import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { vinterPureLogoRotatedPath } from '@app/shared/common/constants';
import { EQuestionCategories, EQuestionContentURLType, ERatingType, EntityStateEnum, GPTEvalStatus, QuestionExpectedAnswerType } from '@app/shared/common/enums';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { AnswerContentListItem } from '@app/vinter/models/answer-content-list-item';
import { ChoiceAnswerListItem } from '@app/vinter/models/choice-answer-list-item';
import { ChoiceContentListItem } from '@app/vinter/models/choice-content-list-item';
import { QuestionContentListItem } from '@app/vinter/models/question-content-list-item';
import { RatingFormItem } from '@app/vinter/models/rating-form-item';
import { AnswerService } from '@app/vinter/services/answer.service';
import { RatingService } from '@app/vinter/services/rating.service';
import { VgApiService } from '@videogular/ngx-videogular/core';

@Component({
  selector: 'app-applicant-answer-sidebar-language',
  templateUrl: './applicant-answer-sidebar-language.component.html',
  styleUrls: ['./applicant-answer-sidebar-language.component.scss']
})
export class ApplicantAnswerSidebarLanguage implements OnInit{

    vinterLogo = vinterPureLogoRotatedPath;
    sidebarVisible = true;
    @Input() questionData:any;
    @Output() hideSidebar: EventEmitter<any> = new EventEmitter<any>();
    @Input() filteredData:any;
    @Input() applicantId:any;
    @Input() applicationId: any;
    @Input() applicationStepId:any;
    @Input() questionCollectionId:any;
    @Input() behaviourEventLogs: any[];
    voiceSpeechSpeedTypes:any[];
    questionCategory:any;
    answer:any;
    questionOrder:any;
    questionKeywords: any;
    choiceContentId: any;
    subtitles: any[] = [];
    questionText: string;
    questionContent: QuestionContentListItem;
    answerContent: AnswerContentListItem;
    QuestionContentType=EQuestionContentURLType;
    transcriptStatus: number;
    answerText: string;
    answerType: number;
    popupOpen=false;
    gptEvalScore: number;
    choiceAnswers: ChoiceAnswerListItem;
    choiceContents: ChoiceContentListItem[] = [];
    selectedAnswerChoice: any;
    selectedAnswer:string;
    QuestionExpectedAnswerType = QuestionExpectedAnswerType;
    gptEvalStatusEnum = GPTEvalStatus;
    RatingType = ERatingType;
    selectedRadioValue: any;
    QuestionCategories=EQuestionCategories;
    selectedQuestion:any;
    emotionColors = {
        angry: '#FE5F55',
        disgust: '#3AB1C8',
        fear: '#7ECA9C',
        happy: '#FFA372',
        sad: '#1597E5',
        surprise: '#9E67BF',
        neutral: '#BEBBAD',
        facenotfound: '#FFFFFF'
      };

  constructor(
    private answerService: AnswerService,
    private appInfoService: AppInfoService,
    private ratingService: RatingService,
    public api: VgApiService) { }

  ngOnInit(): void {
    this.voiceSpeechSpeedTypes = this.appInfoService.getVoiceSpeechSpeedTypes();
    this.getAnswerByQuestionId(null);
  }

  hideSidebarEmit(){
    this.hideSidebar.emit(true);
  }
  getProgressBarColor(score: number): string {
    if (score <= 10) return '#DC2626';
    if (score <= 20) return '#DC2667';
    if (score <= 30) return '#DC2688';
    if (score <= 40) return '#DC269E';
    if (score <= 50) return '#AD26DC';
    if (score <= 60) return '#8126DC';
    if (score <= 70) return '#6026DC';
    if (score <= 80) return '#3F26DC';
    if (score <= 90) return '#1549D6';
    return '#2962FF';
  }

  trustVinter(contentId) {
    let selectedStar = 0;
    if (contentId != null) {
      selectedStar= Math.round(this.answer?.answerContent?.gptEvalScore / 10)/2;
    }
    let rating = this.answer?.rating;
    rating.ratingScore = selectedStar;
    this.onFormSubmit(true, rating);
  }

  patchRatingInfoFormControl(isGPTEval: boolean,ratingInfoPop) {
    let ratingInfo = new RatingFormItem();
    ratingInfo.comment = !isGPTEval ? ratingInfoPop?.comment : this.answer?.answerContent?.gptEvalText;
    ratingInfo.ratingScore = ratingInfoPop?.ratingScore;
      ratingInfo.answerId = this.answer ?? false ? this.answer?.id : this.selectedQuestion.answerId ;
      ratingInfo.applicationStepId = this.applicationStepId;
      ratingInfo.ratingType = ratingInfoPop?.ratingType;
    ratingInfo.applicationId = this.applicationId;
    return ratingInfo;
  }

  onFormSubmit(isGPTEval: boolean, ratingInfoPop: any) {
    const collectionId=(this.questionCollectionId ?? false) ? this.questionCollectionId : null;
    if (ratingInfoPop?.ratingScore <= 0 && (ratingInfoPop?.ratingScore ?? false)) return;
    let ratingInfo = this.patchRatingInfoFormControl(isGPTEval,ratingInfoPop);
    if (ratingInfo.ratingScore != null && ratingInfo.ratingScore != 0) {
      if (ratingInfoPop?.id !== null && ratingInfoPop?.id !== undefined) {
        ratingInfo.id = ratingInfoPop?.id;
        ratingInfo.xmin = ratingInfoPop?.xmin;
        ratingInfo.entityState = EntityStateEnum.Modified;
        var item = {
          rating: ratingInfo,
          questionCollectionId: collectionId,
          applicationStepId: this.applicationStepId
        };
        this.ratingService.updateRatingWithAvg(item).subscribe((rating) => {
        });
      } else {
        ratingInfo.entityState = EntityStateEnum.Added;
        var item = {
          rating: ratingInfo,
          questionCollectionId: collectionId,
          applicationStepId: this.applicationStepId
        };
        this.ratingService.addRatingWithAvg(item).subscribe((rating) => {
        });
      }
    }
  }

  getAnswerForRatingByQuestionId(question: any,isPopUpAction, ratingType) {
    this.answerService.getDataByFilter({ questionId: question.id, applicantID: this.applicantId, applicationStepID: this.applicationStepId}).subscribe(result => {
      this.selectedQuestion = question;
      this.answer = result;
      this.openRatingPopup(isPopUpAction, ratingType);

    });
  }

  openRatingPopup(isPopUpAction, ratingType){
    if(isPopUpAction){
        this.popupOpen = true;
    }else if(ratingType === this.RatingType.VinterTrust){
        this.trustVinter(this.answer?.answerContent?.id );
    }
}



  getAnswerByQuestionId(questionOrderChangeIndex) {
    if(questionOrderChangeIndex ?? false){
        const filteredQuestion = this.filteredData.find(x=> x.order === questionOrderChangeIndex);
        if(filteredQuestion ?? false){
            this.questionData = {order: filteredQuestion.order, ...filteredQuestion.question}
        }
    }

    let questionID = this.questionData.id;
    let questionContent = this.questionData.questionContent;
    let keywords = this.questionData.keywords;
    let expectedAnswerType = this.questionData.expectedAnswerType;
    let order = this.questionData.order;
    this.questionCategory=this.questionData.questionCategory;
    this.questionOrder = order;
    this.questionKeywords = keywords;
    this.questionText = questionContent.questionText;
    this.questionContent = questionContent;


    this.answerService.getDataByFilter({ questionId: questionID, applicantID: this.applicantId, applicationStepID: this.applicationStepId}).subscribe(result => {
      this.answer = result;
      this.choiceContentId = result?.choiceAnswers?.choiceContentId;
      this.answerType = result?.answerType;

    if(result!== null && result.id !== null && result.answerContent !== null){
        this.answerContent = result.answerContent;
        this.answerText = result.answerContent.answerText;
        this.gptEvalScore = result.answerContent.gptEvalScore;
    }else if (result != null && result.id != null) {
          this.choiceAnswers = result?.choiceAnswers;
      }
      else {
        this.questionText = questionContent.questionText;
        this.gptEvalScore = 0;
        this.questionContent = questionContent;
        this.answerType = expectedAnswerType;
        this.answerText = null;
        this.answerContent = null;
      }

    });
  }



  getColor(emotion: string, score: number): string {
    const alpha = Math.min(1, score / 100);
    const originalColor = this.emotionColors[emotion.toLowerCase()] || '#FFFFFF';
    const computedColor = (score !== -1) ? (originalColor.slice(0, 7) + Math.round(alpha * 255).toString(16).toUpperCase()) : (originalColor.slice(0, 7));
    return computedColor;
  }

}
