<p-dialog header="{{'Key.InviteApplicants' | translate}}" [breakpoints]="{'960px': '75vw'}" [(visible)]="visible"
    [modal]="true" (onHide)="close()" [resizable]="false" [draggable]="false">
    <ng-template pTemplate="header">
        <div class="flex w-full mr-4">
            <div class="col-8 pb-0 text-left p-dialog-title">{{'Key.InviteApplicants' | translate}}</div>
            <div *ngIf="generalApplicationLink != null && inviteType == PositionStepKanbanStaticTypes.GeneralApplication" class="col-4 py-0 mb-1 cursor-pointer">
                <div class="flex justify-content-center float-right" pTooltip='{{"Key.Copy" | translate}}' tooltipPosition="top"
                    (click)="copyText()" *ngIf="!isCopied">
                    <img src="assets/vinter/icons/link-bold.svg" alt="Copy Link" />
                    <span class="link-text ml-2 m-3">
                        {{"Key.CopyLink" | translate}}
                    </span>
                </div>
                <div class="flex justify-content-center float-right" pTooltip='{{"Key.Copied" | translate}}' tooltipPosition="top"
                    (click)="copyText()" *ngIf="isCopied">
                    <img src="assets/vinter/icons/uil_check.svg" alt="Copy Link" />
                    <span class="link-text ml-2 m-3">
                        {{"Key.LinkCopied" | translate}}
                    </span>
                </div>
            </div>
        </div>
    </ng-template>
    <hr>
    <p-tabView [(activeIndex)]="activeTabIndex">
        <p-tabPanel header="{{'Key.viaEmail' | translate}}">
            <app-invite-applicant-via-email *ngIf="activeTabIndex === 0" [positionId]="positionId"
                [organizationId]="organizationId" (callCloseDialog)="closeDialog()" (sendInvite)="toogleInviteDialog()"
                (sendMailDataValidation)="receiveMailData($event)"></app-invite-applicant-via-email>
        </p-tabPanel>
        <p-tabPanel header="{{'Key.viaFile' | translate}}">
            <app-invite-applicant-via-file *ngIf="activeTabIndex === 1" [positionId]="positionId"
                [organizationId]="organizationId" (callCloseDialog)="closeDialog()"
                (sendDataValidation)="receiveData($event)" (sendInvite)="toogleInviteDialog()">
            </app-invite-applicant-via-file>
        </p-tabPanel>
        <p-tabPanel header="{{'Key.viaIntegration' | translate}}" *ngIf="positionIntegrationList.length > 0">
            <app-invite-applicant-via-integration *ngIf="activeTabIndex === 2" [positionId]="positionId" [integrationApplicantList]="integrationApplicantList" [integrationApplicantListCopy]="integrationApplicantListCopy"
                [integrationApplicantListSearchCopy]="integrationApplicantListSearchCopy" [fetchedApplicantData]="fetchedApplicantData" [positionIntegrationList]="positionIntegrationList"
                [organizationId]="organizationId" (callCloseDialog)="closeDialog()" (sendInvite)="toogleInviteDialog()" (getApplicantListEmitter)="getApplicantListOfChoosedIntegrations($event)"
                (sendMailDataValidation)="receiveIntegrationInvitationData($event)"></app-invite-applicant-via-integration>
        </p-tabPanel>
    </p-tabView>
    <p-footer class="grid justify-content-between flex ">
        <div class=" line col-12 p-0 m-0 pt-3"></div>

        <p-button *ngIf="activeTabIndex === 0" [disabled]="!sendMailInvitationData" class="clear"
            label="{{'Key.ClearAll' | translate}}" styleClass="p-button-danger p-button-text"
            [style]="{color: '#F20505'}" (onClick)="close()"></p-button>
        <p-button *ngIf="activeTabIndex === 0" styleClass="p-button-primary" [disabled]="!sendMailInvitationData"
            (onClick)="sendInvite(activeTabIndex)">
            <span class="mr-2">{{'Key.SendInvite' | translate}}</span>
            <img alt="logo" src="assets/vinter/icons/button-send.svg" style="width: 1.5rem">
        </p-button>

        <p-button *ngIf="activeTabIndex === 1" [disabled]="!sendInvitationData" class="clear"
            label="{{'Key.ClearAll' | translate}}" styleClass="p-button-danger p-button-text"
            [style]="{color: '#F20505'}" (onClick)="close()"></p-button>
        <p-button *ngIf="activeTabIndex === 1" [disabled]="!sendInvitationData" styleClass="p-button-primary"
            (onClick)="sendInvite(activeTabIndex)">
            <span class="mr-2">{{'Key.SendInvite' | translate}}</span>
            <img alt="logo" src="assets/vinter/icons/button-send.svg" style="width: 1.5rem">
        </p-button>
        
        <div class="w-full flex justify-content-end">
            <p-button *ngIf="activeTabIndex === 2" styleClass="p-button-primary" [disabled]="!sendIntegrationInvitationData"
                (onClick)="sendInvite(activeTabIndex)">
                <span class="mr-2">{{'Key.SendInvite' | translate}}</span>
                <img alt="logo" src="assets/vinter/icons/button-send.svg" style="width: 1.5rem">
            </p-button>
        </div>
       
    </p-footer>
</p-dialog>


<p-dialog [(visible)]="invitedDialogVisible" [draggable]="false" [modal]="true" showEffect="fade"
    [breakpoints]="{'960px': '75vw'}" class="dialogPage" [closable]="false">
    <div class="grid">
        <div class="col-12" style="display: flex;justify-content: center;margin-top:6rem">
            <img style="width: 9rem;" src="assets/vinter/gifs/invite-gif.gif">
        </div>
        <div class="col-12 complete-dialog-header">
            {{'Key.InviteApplicantHeader' | translate}}
        </div>
        <div class="col-12 complete-dialog-text pt-0">
            {{'Key.InviteApplicantBody' | translate}}
        </div>
        <div class="col-12 complete-dialog-header" style="margin-top:1rem">
            <p-button label="{{'Key.GoPositions' | translate}}" (onClick)="goAllPositions()" styleClass="mr-2 mb-2">
            </p-button>
        </div>
    </div>
</p-dialog>
