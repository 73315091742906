import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { CredentialsService, LocalizationService } from '@app/core';
import { PositionFormEditModeEnum, QuestionCollectionCategoryEnum } from '@app/shared/common/enums';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { QuestionCollectionFormItem } from '@app/vinter/models/question-collection-form';
import { UserManagementListItem } from '@app/vinter/models/user-profile-list-item';
import { UserManagementService } from '@app/vinter/services/user-management.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import _ from 'lodash';
import { VINTERADMIN } from '@app/shared/common/constants';

@Component({
    selector: 'app-question-collection-form',
    templateUrl: './question-collection-form.component.html',
    styleUrls: ['./question-collection-form.component.scss'],
    providers: [ConfirmationService, MessageService]
})
export class QuestionCollectionFormComponent {
    form: FormGroup;
    @Output() onQuestionCollectionInfoSave: EventEmitter<any> = new EventEmitter<any>();
    @Output() onCustomStepSave: EventEmitter<any> = new EventEmitter<any>();
    @Output() infoChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() resetQuestionList: EventEmitter<any> = new EventEmitter<any>();
    @Output() selectedLanguageId: EventEmitter<string> = new EventEmitter<string>();
    @Input() questionCollectionInfo:any;

    categories: any[] = [];
    stepCategoryNames: any[] = [];
    isFormSetCompleted = false;
    yesNoCategory: any[] = [];
    categoryEnum=QuestionCollectionCategoryEnum;
    selectedCategory = '';
    isDisabledNext = false;
    users: UserManagementListItem[] = [];
    isFirstLoad = true;
    isFirstChanges = true;
    @Output() enableNextButton: EventEmitter<boolean> = new EventEmitter<boolean>();
    isVinterAdminLogged: boolean;
    userGroup: string[] = [];
    constructor(
        private credentialService: CredentialsService,
        private localizationService: LocalizationService,
        private userService: UserManagementService,
        private appInfoService: AppInfoService,

    ) { }

    filterCategories(){
        if (!this.isVinterAdminLogged) {
            this.categories = this.categories.filter(x=> x.id === QuestionCollectionCategoryEnum.TechnicalTest
                || x.id === QuestionCollectionCategoryEnum.CompetenceTest);
        }
        else if ( this.isVinterAdminLogged) {
            this.categories = this.categories.filter(x=> x.id === QuestionCollectionCategoryEnum.TechnicalTest
                || x.id === QuestionCollectionCategoryEnum.CompetenceTest
                || x.id === QuestionCollectionCategoryEnum.ForeignLanguage
                || x.id === QuestionCollectionCategoryEnum.Pronunciation
                || x.id === QuestionCollectionCategoryEnum.CognitiveAbility);
        }
    }

    ngOnInit(): void {

        this.userGroup = this.credentialService.getUserGroups().split(',');
        if(this.userGroup.some(s => s.includes(VINTERADMIN)))
            this.isVinterAdminLogged = true;
        else
            this.isVinterAdminLogged = false;
        this.isFirstLoad=true;
        this.categories = this.appInfoService.getQuestionCollectionCategories();
        this.stepCategoryNames = this.appInfoService.getStepNameByCategories();
        this.filterCategories();

        this.setFormValidator();
        this.yesNoCategory = this.appInfoService.getYesNoArray();
        this.subscribeFormChanges();
        this.setUserList();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.questionCollectionInfo && changes.questionCollectionInfo.currentValue && !this.isFirstChanges) {
            setTimeout(() => { this.setFormData() }, 1000 * 0.2)


        }
        this.isFirstChanges=false;
      }

    setFormData(){
        this.isFormSetCompleted = false;
        if((this.questionCollectionInfo ?? false)  || this.questionCollectionInfo.questionCollectionCategory === QuestionCollectionCategoryEnum.Custom){

            this.form.controls['description'].setValue(this.questionCollectionInfo.description);
            this.form.controls['title'].setValue(this.questionCollectionInfo.title);
            this.form.controls['duration'].setValue(this.questionCollectionInfo.duration);
            this.form.controls['questionCollectionCategory'].setValue(this.questionCollectionInfo.questionCollectionCategory);

        }
        this.isFormSetCompleted = true;
    }

    setUserList() {
        this.userService.getUserListForSelect({}).subscribe(resp => {
            this.users = resp;
            setTimeout(() => { this.setFormData() }, 1000 * 0.2)
        });
    }

    subscribeFormChanges() {

        this.form.controls['duration'].valueChanges.subscribe(newValue => {
            this.infoChange.emit({ title:this.form.controls['title'].value, description:this.form.controls['description'].value, duration: this.form.controls['duration'].value });
        });

        this.form.controls['questionCollectionCategory'].valueChanges.subscribe(newValue => {

            if(!this.isFirstLoad){
                this.resetQuestionList.emit(null);
            }

            if(newValue === this.categoryEnum.PersonalityTest){
                this.form.controls["duration"].setValidators([]);
                this.form.controls['duration'].updateValueAndValidity()

            }else{
                this.form.controls["duration"].setValidators([Validators.required]);

                if(!(this.form.controls['duration'].value ?? false)){

                    this.form.controls['duration'].setValue(0);
                }
            }

            this.isFirstLoad = false;
        });

        this.form.controls['title'].valueChanges.subscribe(newValue => {
            this.infoChange.emit({ title: this.form.controls['title'].value, duration: null });
        });

        this.form.controls.questionCollectionCategory.valueChanges.subscribe(x => {
           this.selectedCategory = x;
        })
        this.form.valueChanges.subscribe(x => {
            if(this.form.controls['title'].value !== ""
            && this.form.controls['questionCollectionCategory'].value !== ""
            && this.form.controls['description'].value !== ""
            && this.form.controls['duration'].value !== null)
                this.enableNextButton.emit(true);
            else
                this.enableNextButton.emit(false);
        });

    }

    setFormValidator() {
        this.form = new FormGroup({});
        this.form.addControl('description', new FormControl(''));
        this.form.addControl('questionCollectionCategory', new FormControl('', [Validators.required]));
        this.form.addControl('organizationID', new FormControl(''));
        this.form.addControl('duration', new FormControl('', [Validators.required]));
        this.form.addControl('title', new FormControl(''));
        this.form.addControl('title', new FormControl('', [Validators.required, this.trimValidator]));
    }

    checkFormValidity(formControlName: string) {
        if (this.form.get(formControlName).invalid && (this.form.get(formControlName).dirty || this.form.get(formControlName).touched)) {
            return true;
        }
        return false;
    }

    getFormValidityMessage(formControlName: string) {

        if (this.form.get(formControlName).touched && this.form.get(formControlName).errors?.['error']) {
            return this.localizationService.translateTextWithoutKey(this.form.get('lastName').errors?.['error'].value);
        }
        else if (this.form.get(formControlName).touched && this.form.get(formControlName).errors?.['required']) {
            return this.localizationService.translateText('RequiredMassage');
        }
    }

    trimValidator: ValidatorFn = (control: FormControl) => {
        if (control.value !== null && control.value.startsWith(' ')) {
            return { error: { value: 'Key.StartsWithErr' } };
        }
        if (control.value !== null && control.value.endsWith(' ')) {
            return { error: { value: 'Key.EndsWithErr' } };
        }
        return null;
    }

    resetForm() {
        this.form.reset();
    }

    saveQuestionListInfoForEditMode()
    {      
        var questionCollectionInfoForEditMode = new QuestionCollectionFormItem();
        questionCollectionInfoForEditMode.questionCollectionCategory = this.form.controls['questionCollectionCategory'].value;
        questionCollectionInfoForEditMode.filteredQuestionCollectionCategory = this.form.controls['questionCollectionCategory'].value;
        questionCollectionInfoForEditMode.description = this.form.controls['description'].value;
        return questionCollectionInfoForEditMode;
    }

  saveQuestionListInfo() {
      if (this.form.invalid) return null;
      var questionCollectionInfo = new QuestionCollectionFormItem();
      questionCollectionInfo.questionCollectionCategory = this.form.controls['questionCollectionCategory'].value;
      questionCollectionInfo.filteredQuestionCollectionCategory = this.form.controls['questionCollectionCategory'].value;
      questionCollectionInfo.organizationID = this.credentialService.getMainOrganizationID();
      questionCollectionInfo.duration = this.form.controls['duration'].value;
      questionCollectionInfo.title = this.form.controls['title']?.value
      questionCollectionInfo.description = this.form.controls['description'].value;
      this.selectedLanguageId.emit(questionCollectionInfo.languageId);
      return questionCollectionInfo;
  }
    goNextPage() {
        let questionCollectionInfo = this.saveQuestionListInfo();
        let qestionCollectionInfoForEditMode = this.saveQuestionListInfoForEditMode();
        this.onQuestionCollectionInfoSave.emit(questionCollectionInfo);
    }

    saveStepInfo() {
        let questionCollectionInfo = this.saveQuestionListInfo();
        this.onCustomStepSave.emit({ stepInfo: questionCollectionInfo, questions:[] });
    }

    getInitials(fullName: string): string {
        const [name, surname] = fullName.split(" ");
        return `${name[0].toUpperCase()}${surname[0].toUpperCase()}`;
    }
}
