import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { EApplicantBehaviourEventType, EQuestionContentURLType, QuestionCollectionCategoryEnum, QuestionExpectedAnswerType } from '@app/shared/common/enums';
import { MultipleChoiceAnswerItem } from '@app/vinter/models/answer/multiple-choice-answer-item';
import { QuestionListItem } from '@app/vinter/models/question-list-item';
import { ApplicantBehaviourEventLogService } from '@app/vinter/services/applicant-behaviour-event-log.service';

@Component({
  selector: 'app-multiple-choice-answer',
  templateUrl: './multiple-choice-answer.component.html',
  styleUrls: ['./multiple-choice-answer.component.scss']
})
export class MultipleChoiceAnswerComponent implements OnInit {

  @Input() answer: MultipleChoiceAnswerItem;
  @Input() question: QuestionListItem;

  QuestionExpectedAnswerType = QuestionExpectedAnswerType;
  QuestionContentType = EQuestionContentURLType;
  questionCollectionCategories = QuestionCollectionCategoryEnum;
  selectedAnswerChoice: any
  selectedRadioValue: any;
  questionPageHiddenLog: number;

  constructor( private eventLogService: ApplicantBehaviourEventLogService){}

  ngOnChanges(changes: SimpleChanges): void {
    if(this.question){
      var selectedAnswerIndex = this.question.choiceContents.findIndex(x => x.id == this.answer?.choiceAnswer?.choiceContentId);
      this.selectedAnswerChoice = this.question.choiceContents[selectedAnswerIndex];
    }
    if(this.answer){
      this.getPageHiddenLog();
    }
  }

  ngOnInit(): void {
    if(this.question){
      var selectedAnswerIndex = this.question.choiceContents.findIndex(x => x.id == this.answer?.choiceAnswer?.choiceContentId);
      this.selectedAnswerChoice = this.question.choiceContents[selectedAnswerIndex];
    }
    if(this.answer){
      this.getPageHiddenLog();
    }
  }

isCorrectAnswer(choiceContentId: string): boolean {
  const correctChoice = this.question?.choiceContents?.find(choice => choice.id === this.answer?.choiceAnswer?.choiceContentId);
  this.selectedRadioValue = correctChoice ? correctChoice.choiceText : null;
  return this.answer?.choiceAnswer && this.answer?.choiceAnswer?.choiceContentId === choiceContentId && this.answer?.choiceAnswer?.isCorrect;
}

isWrongAnswer(choiceContentId: string): boolean {
  return this.answer?.choiceAnswer && this.answer?.choiceAnswer?.choiceContentId === choiceContentId && !this.answer?.choiceAnswer?.isCorrect;

}

checkAnswer(choiceContentId: string): boolean {
    let result = false;
    if (choiceContentId === null || choiceContentId === undefined) {
        result = false;
    }
    else {
        if (this.answer?.choiceAnswer?.choiceContentId === choiceContentId && this.answer?.choiceAnswer?.isCorrect) {
            result = true;
        }
        else {
            result = false;
        }
    }
    return result
}

getPageHiddenLog(){
  let eventLogFilter: any = {};
  eventLogFilter.applicationStepId = this.answer.applicationStepId;
  eventLogFilter.eventType = EApplicantBehaviourEventType.PageHidden;
  eventLogFilter.questionId = this.answer.questionId;
  this.eventLogService.getListByFilter(eventLogFilter).subscribe(eventLog => {
      this.questionPageHiddenLog = eventLog.length;
  });
}

}
