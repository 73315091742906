import { Component, Input } from '@angular/core';
import { EQuestionCollectionSubCategory, QuestionCollectionCategoryEnum } from '@app/shared/common/enums';

@Component({
    selector: 'app-step-card',
    templateUrl: './step-card.component.html',
    styleUrls: ['./step-card.component.scss']
})
export class StepCardComponent {
    @Input() step: any;
    @Input() report: any;
    @Input() pageNumber:number = 0;
    @Input() chartData: any;
    @Input() languageAnsweredQuestions: any;
    @Input() answeredQuestionsWithId: any;

      
    QuestionCollectionCategoryEnum = QuestionCollectionCategoryEnum;
    QuestionCollectionSubCategory = EQuestionCollectionSubCategory;
      
    
}