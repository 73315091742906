import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-gauge-chart',
  templateUrl: './gauge-chart.component.html',
  styleUrls: ['./gauge-chart.component.scss']
})
export class GaugeChartComponent {
  @Input() value: number;
  @Input() label: string;
  @Input() gaugeClass: string = 'gray';
  @Input() width: number = 160;
  @Input() score: string;


}
