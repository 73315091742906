import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepQuestionResultListComponent } from '@app/shared/components/step-question-result-list/step-question-result-list.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SharedCustomChipVinterModule } from './shared-custom-chip.module';
import { StepResultQuestionBehaviourInfoComponent } from '@app/shared/components/step-result-question-behaviour-info/step-result-question-behaviour-info.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { OverlayModule } from 'primeng/overlay';
import { TranslateModule } from '@ngx-translate/core';
import { SharedAnswerDetailModule } from './shared-answer-detail-sidebar.module';
import { SharedVinterScoreCardModule } from './shared-vinter-score-card.module';



@NgModule({
  declarations: [
    StepQuestionResultListComponent,
    StepResultQuestionBehaviourInfoComponent,
  ],
  imports: [
    CommonModule,
    RadioButtonModule,
    SharedCustomChipVinterModule,
    OverlayPanelModule,
    OverlayModule,
    TranslateModule,
    SharedAnswerDetailModule
    
  ],
  exports: [
    StepQuestionResultListComponent,
    StepResultQuestionBehaviourInfoComponent,
  ]
})
export class SharedStepQuestionResultListModule { }
