<div class="col-12 flex pl-0 pb-0">
    <div class="col-6 flex pl-0 pt-1 gap-3">
        <p-button class="back-button" icon="pi pi-arrow-left" (click)="previousButton()"></p-button>
        <p-button class="next-button" [disabled]="!isDataChanged" (click)="saveButton()">{{"Key.SaveProfile" | translate}}</p-button>
    </div>
</div>
<p class="text-gray-900 font-bold text-4xl changeScoreText mt-5">{{"Key.VinterScore" | translate}}</p>
<div class="flex flex-column justify-content-center align-items-center border-1 border-solid border-gray-50 border-round-lg pt-5 pb-2 changeScoreEditorStyle">
    <p class="text-blue-500 font-bold text-2xl changeScoreText flex justify-content-center">{{"Key.ChangeScoreText" | translate}}</p>
    <div class="col-12 md:col-8 flex justify-content-center border-round-lg align-items-center bg-white py-5">
        <p-slider [(ngModel)]="value" styleClass="h-1rem custom-slider" class="" (onChange)="onSliderChange($event)"/>
        <p class="text-lg font-bold text-gray-900 ml-2 flex flex-nowrap">
            {{ value }}
            <span [ngClass]="{'text-green-500': difference > 0, 'text-red-500': difference < 0, 'text-white': difference == 0}">
                ({{ difference > 0 ? '+' : '' }}{{ difference | number: '0.0-0' }})
            </span>
        </p>
    </div>
    <p class="text-blue-400 custom-label mt-7 cursor-pointer" (click)="showEditor()" *ngIf="isEditorHidden">{{'Key.AddNoteEditor' | translate}}</p>
    <div class="col-12 md:col-8 border-gray-50 p-0 mb-4 mt-6" *ngIf="!isEditorHidden">
        <label class="text-blue-400 custom-label pointer-events-none">{{'Key.AddNoteEditor' | translate}}</label>
        <p-editor data-cy="input-welcome-message" [(ngModel)]="content" (ngModelChange)="checkEditorContent()"
            [formats]="formats" [style]="{ height: '150px', 'background-color': 'var(--gray-50)' }">
            <ng-template pTemplate="header">
                <span class="ql-formats">
                    <button type="button" class="ql-bold" aria-label="Bold"></button>
                    <button type="button" class="ql-italic" aria-label="Italic"></button>
                    <button type="button" class="ql-underline" aria-label="Underline"></button>
                    <span class="ql-formats">
                        <button value="ordered" aria-label="Ordered List" type="button"
                            class="ql-list"></button>
                        <button value="bullet" aria-label="Unordered List" type="button"
                            class="ql-list"></button>
                    </span>
                </span>
            </ng-template>
        </p-editor>
    </div>
</div>
<p *ngIf="hrScoreDetails.length > 0" class="text-blue-500 changeScoreText font-medium mt-4">{{"Key.History" | translate}}</p>
<div *ngIf="hrScoreDetails" class="flex flex-column justify-content-center align-items-center border-1 border-solid border-gray-50 border-round-lg hrCommentBox">
    <div class="flex justify-content-end align-items-center w-full mt-3 mr-5 mb-1 cursor-pointer" (click)="sortByCreatedAt()">
        <p class="text-blue-900 changeScoreText font-medium m-0 mr-2">
            {{"Key.Sort" | translate}}
        </p>
        <img src="assets/vinter/icons/sort-arrow.svg" width="18px" height="18px" />
    </div>
    <div *ngFor="let detail of hrScoreDetails" class="flex flex-column justify-content-center align-items-center border-1 border-solid border-gray-50 border-round-lg bg-white hrComment mb-3">
        <div class = "flex justify-content-start w-full">
            <div class="col-1 w-min p-0 ml-3 my-3">
                <p-avatar *ngIf="!(detail.hrImageUrl ?? false)" class="responsibleAvatar-blue" shape="circle"
                    styleClass="w-6rem h-6rem border-4 border-blue-200 ">

                    <span class="text-5xl text-blue-900 font-semibold">{{detail.hrFirstname[0]?.toUpperCase() +
                        detail.hrLastname[0]?.toUpperCase()}}</span>
                </p-avatar>
                <p-avatar *ngIf="detail.hrImageUrl ?? false" class="responsibleAvatar-blue ml-2"
                    [image]="detail.hrImageUrl" shape="circle"
                    styleClass="w-6rem h-6rem border-4 border-blue-200">
                </p-avatar>
            </div>
            <div class = "mt-4 ml-3">
                <p class="text-gray-600 font-medium m-0 text-lg">
                    {{detail.hrFirstname + " " + detail.hrLastname}}
                </p>
                <p class="text-gray-300 m-0 text-sm">
                    {{detail.hrDepartment.charAt(0).toUpperCase() + detail.hrDepartment.slice(1)}}
                </p>
            </div>
        </div>
        <div class = "flex justify-content-start ml-6 w-full items-center">
            <p class="text-gray-800 mr-4">
                {{"Key.Score" | translate}}
            </p>
            <p class="text-gray-800 font-bold">{{firstAIScore}}</p>
            <img src="assets/vinter/icons/lines.svg" width="50px" height="25px" class="px-2"/>
            <img *ngIf="firstAIScore > (firstAIScore + detail.scoreChange - this.fractionValue)" src="assets/vinter/icons/low-arrow.svg" width="50px" height="25px" class="-mx-2 -mt-1"/>
            <img *ngIf="firstAIScore === (firstAIScore + detail.scoreChange - this.fractionValue)" src="assets/vinter/icons/equal-arrow.svg" width="50px" height="25px" class="-mx-2 -mt-1"/>
            <img *ngIf="firstAIScore < (firstAIScore + detail.scoreChange - this.fractionValue)" src="assets/vinter/icons/high-arrow.svg" width="50px" height="25px" class="-mx-2 -mt-1"/>
            <p class="text-blue-500 font-bold">{{ calculateValue(detail.scoreChange) | number: '0.0-0' }}</p>
        </div>
        <p class="flex justify-content-start w-full text-gray-800 mt-2 ml-6">{{detail.hrComment}}</p>
        <div class="flex justify-content-start w-full items-center pl-3 pr-3 pb-3">
            <img src="assets/vinter/icons/history-clock.svg" width="18px" height="18px" />
            <p class="text-gray-300 text-sm ml-1">{{detail.createdAt| date: 'dd-MM-yyyy'}}</p>
        </div>
    </div>
</div>
