import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AuthenticationService, ConfigurationService, CredentialsService, LocalizationService } from '@app/core';
import { MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { SpinnerHandlerService } from '../loader/spinner-handler.service';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Language } from '@app/shared/common/interfaces';
import { flagENPath, flagTRPath, languageKey, vinterLogoWithoutTextPath, vinterPureLogoRotatedPath, passwordMinCharcount, UserConsentTypeStrings, APPLICANT, ORGADMIN, HR, VINTERADMIN } from '@app/shared/common/constants';
import { LanguageService } from '@app/shared/services/language.service';
import { ELanguages, EUserConsentType, LanguageEnum } from '@app/shared/common/enums';
import * as intlTelInput from 'intl-tel-input';
import { CountryService } from '@app/vinter/services/country.service';
import { RecaptchaComponent } from 'ng-recaptcha';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  vinterLogoWithoutTextPath: string = vinterLogoWithoutTextPath;
  vinterPureLogoRotatedPath: string = vinterPureLogoRotatedPath;
  flagTRPath: string = flagTRPath;
  flagENPath: string = flagENPath;
  passwordMinCount = passwordMinCharcount;
  userName: string;
  firstName: string;
  lastName: string;
  password: string;
  form: FormGroup;
  showFormValidation: boolean;
  language: string;
  languageDS: Language[] = [];
  selectedLanguage: Language = {};
  defaultLanguageID?: string;
  fromPageLoad: boolean;
  isTurkishSelected: boolean = true;
  countryCode: number;
  phoneNumber: number;
  iti: any;
  userAgreementVisible: boolean = false;
  disclosureAgreement: boolean;
  TEIOMAgreement: boolean;
  GDPRAgreement: boolean;
  mailAgreement: boolean;
  company: string;
  country: string;
  EUserConsentType = EUserConsentType;
  selectedConsentType: EUserConsentType;
  selectedConsentName: string;
  countries: any[];
  filteredCountries: any[];
  LanguageEnum = ELanguages;
  licensePlanId: string;
  isRecaptchaResolved: boolean = false;
  resolvedRecaptcha: any;
  recaptchaKey: string = '';
  isRecaptchaActive = true;
  @ViewChild('invisible') invisibleCaptcha: any;
  @ViewChild('captchaRef') captchaElem: RecaptchaComponent;
  isRegisterAction = false;
    
  constructor(
    private service: AuthenticationService,
    private messageService: MessageService,
    private localizationService: LocalizationService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: SpinnerHandlerService,
    private languageService: LanguageService,
    private countryService: CountryService,
    private credentialsService: CredentialsService,
    private authenticationService: AuthenticationService,
    private configService: ConfigurationService,
  ) {
  }

  ngOnInit(): void {
    this.recaptchaKey = this.configService.config.reCaptchaKey;
    this.isRecaptchaActive = this.configService.config.isRecaptchaActive;

    this.isRecaptchaResolved = false;

    this.getCountryDatas(this.LanguageEnum.English);

    const inputElement = document.querySelector('#phone');
    if(inputElement){
      this.iti =  intlTelInput(inputElement,{
        preferredCountries: ["tr", "us"],
        showSelectedDialCode: true,
        initialCountry: "",
        utilsScript: 'assets/vinter/js/regexUtils.js',
        separateDialCode:true
      });
    }
    const countryData = this.iti?.getSelectedCountryData();
    this.countryCode = countryData?.dialCode;
    const number = this.iti.getNumber();
    this.iti.setCountry(countryData?.iso2);
    
    this.route.queryParamMap.subscribe(
      (params: ParamMap) => {
        this.licensePlanId = this.route.snapshot.queryParamMap.get('licensePlanId');
      }
    );

    lastValueFrom(this.languageService.getLanguagesFromApi()).then(data => {
      this.languageDS = data;
      this.fromPageLoad = true;
      this.selectedLanguage = this.languageDS.find(x => x.id === LanguageEnum.EnglishUnitedStates);
      this.isTurkishSelected = false;
      this.languageOnSelectionChanged(this.selectedLanguage);

      this.localizationService.initTranslate(null);
      if(this.isTurkishSelected){
        this.setCheckboxValidatorsToTurkish();
      }
      else{
        this.setCheckboxValidatorsToEnglish();
      }
    })
    this.setFormGroup();
    this.form.controls['company'].clearValidators();
    this.form.controls['country'].clearValidators();
    
  }

  getCountryDatas(selectedLang: string){
    this.countryService.getCountries(selectedLang).then((countries) => {
      this.countries = countries;
  });
  }

  filterCountry(event) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.countries.length; i++) {
        let country = this.countries[i];
        if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(country);
        }
    }
    this.filteredCountries = filtered;
  }

  toggleUserAgreementDialog(consentType: EUserConsentType) {
    this.selectedConsentName = UserConsentTypeStrings[consentType];
    this.selectedConsentType = consentType;
    this.userAgreementVisible = true;
  }

  setFormGroup() {
    this.form = new FormGroup(
      {
        email: new FormControl('',{
          validators: [
              Validators.required,
              this.trimValidator,
              Validators.pattern(
                  '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$'
              ),
          ],
          updateOn: 'change',
      }),
        company: new FormControl(this.company, [
          Validators.required,
          this.trimValidator,
        ]),
        country: new FormControl('', [
          Validators.required,
        ]),
        phone: new FormControl('', [
          Validators.required,
          Validators.pattern('[- +()0-9]{5,15}')
        ]),
        firstName: new FormControl('', [
          Validators.required,
        ]),
        lastName: new FormControl('', [
          Validators.required,
        ]),
        postalCode: new FormControl('', [
          Validators.required,
        ]),
        city: new FormControl('', [
          Validators.required,
        ]),
        adressLine: new FormControl('', [
          Validators.required,
        ]),
        password: new FormControl('', [
          Validators.required,
          this.trimValidator,
          this.passwordValidator()
        ]),
        passwordAgain: new FormControl('', [
          Validators.required,
          this.trimValidator,
          this.passwordValidator()
        ]),
        acceptDisclosure: new FormControl(this.disclosureAgreement),
        acceptTEIOM: new FormControl(this.TEIOMAgreement),
        acceptGDPR: new FormControl(this.GDPRAgreement),
        acceptMail: new FormControl(this.mailAgreement),
      },
      { validators: this.matchValidator('password', 'passwordAgain') }
    );
  }

  matchValidator(source: string, target: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const sourceCtrl = control.get(source);
      const targetCtrl = control.get(target);

      return sourceCtrl && targetCtrl && sourceCtrl.value !== targetCtrl.value
        ? { mismatch: true }
        : null;
    };
  }

  trimValidator: ValidatorFn = (control: FormControl) => {
    if (control.value !== null && control.value.startsWith(' ')) {
      return {
        error: { value: 'Key.StartsWithErr' },
      };
    }
    if (control.value !== null && control.value.endsWith(' ')) {
      return {
        error: { value: 'Key.EndsWithErr' },
      };
    }
    return null;
  }

  validateInput(event: any) {
    if (!event.target.value.trim()) {
      this.showFormValidation = true;
    } else if (this.iti.isValidNumberPrecise()) {
      this.showFormValidation = false;
    } else {
      this.showFormValidation = true;
    }
}

  validateKeydown(event: any) {
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'];

    if (!allowedKeys.includes(event.key) && isNaN(parseInt(event.key, 10))) {
      event.preventDefault();
    }
  }

  checkFormValidity(formControlName: string) {
    if (
      (this.form.get(formControlName).touched &&
        this.form.get(formControlName).errors) ||
      this.form.errors?.['mismatch']
    ) {
      return true;
    }
    return false;
  }

  getFormValidityMessage(formControlName: string) {
    if (
      this.form.get(formControlName).touched &&
      this.form.get(formControlName).errors?.['error']
    ) {
      return this.localizationService.translateTextWithoutKey(
        this.form.get(formControlName).errors?.['error'].value
      );
    } else if (
      this.form.get(formControlName).touched &&
      this.form.get(formControlName).errors?.['required']
    ) {
    }
    else if (
      (this.form.get(formControlName).touched &&
        this.form.errors?.['mismatch']) &&
      (formControlName == 'password' || formControlName == 'passwordAgain') &&
      this.form.get('password').value &&
      this.form.get('passwordAgain').value
    ) {
      return this.localizationService.translateText('MismatchMessage');
    }
    else if (
      this.form.get(formControlName).touched &&
      this.form.get(formControlName).errors?.['invalidPassword']
    ) {
      return 'Key.InvalidRegistirationPassword';
    }
    else if (
      this.form.get(formControlName).touched &&
      this.form.get(formControlName).errors?.pattern
    ) {
      return 'Key.InvalidPhonePattern';
    }
  }

  passwordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      const hasUpperCase = /[A-Z]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasNumeric = /\d/.test(value);
      const isLengthValid = value?.length >= passwordMinCharcount;

      const passwordValid = hasUpperCase && hasLowerCase && hasNumeric && isLengthValid;

      return !passwordValid ? { invalidPassword: true } : null;
    };
  }

  onLanguageToggle(isClicked: boolean = false) {
    if(isClicked){
      this.form.controls['acceptDisclosure'].setValue(false);
      this.form.controls['acceptTEIOM'].setValue(false);
      this.form.controls['acceptGDPR'].setValue(false);
      this.form.controls['acceptMail'].setValue(false);
    }
    const langCode = this.isTurkishSelected ? 'tr-TR' : 'en-US';
    const newLanguage = this.languageDS.find(x => x.languageCode === langCode);
    this.getCountryDatas(newLanguage.id);
    this.languageOnSelectionChanged(newLanguage);
    if(this.isTurkishSelected){
      this.setCheckboxValidatorsToTurkish();
    }
    else{
      this.setCheckboxValidatorsToEnglish();
    }
  }

  languageOnSelectionChanged(e) {
    localStorage.setItem(languageKey, JSON.stringify({ id: e.id, code: e.languageCode }));
    if (!this.fromPageLoad) {
      this.fromPageLoad = false;
    }
    this.localizationService.initTranslate(null);
    this.fromPageLoad = true;
  }

  setCheckboxValidatorsToEnglish(){
    this.form.controls['acceptDisclosure'].clearValidators();
    this.form.controls['acceptTEIOM'].clearValidators();
    this.form.controls['acceptGDPR'].setValidators([Validators.requiredTrue]);
    this.form.controls['acceptDisclosure'].updateValueAndValidity();
    this.form.controls['acceptTEIOM'].updateValueAndValidity();
    this.form.controls['acceptGDPR'].updateValueAndValidity();
  }

  setCheckboxValidatorsToTurkish(){
    this.form.controls['acceptDisclosure'].setValidators([Validators.requiredTrue]);
    this.form.controls['acceptTEIOM'].setValidators([Validators.requiredTrue]);
    this.form.controls['acceptGDPR'].clearValidators();
    this.form.controls['acceptDisclosure'].updateValueAndValidity();
    this.form.controls['acceptTEIOM'].updateValueAndValidity();
    this.form.controls['acceptGDPR'].updateValueAndValidity();

  }

  resolved(event) {
    if (this.isRegisterAction === true) {
        if (this.form.valid) {
            this.resolvedRecaptcha = event;
            this.isRecaptchaResolved = true;
            this.register();
        } else {
            this.isRegisterAction = false;
            this.captchaElem.reset();
            this.isRecaptchaResolved = false;
            this.resolvedRecaptcha = null;
        }
    }
  }

  checkRecaptchaAndRegister(captchaRef) {
    if (this.isRecaptchaActive) {
        this.isRegisterAction = true;
        captchaRef.execute();
    } else {
        this.register();
    }
  }

  register() {
    if (this.form.invalid) return;
    if (!this.isRecaptchaResolved && this.isRecaptchaActive) return;
    if (this.isTurkishSelected) {
      this.selectedLanguage = this.languageDS.find(x => x.id === LanguageEnum.TurkishTurkey);
    }
    else {
      this.selectedLanguage = this.languageDS.find(x => x.id === LanguageEnum.EnglishUnitedStates);
    }
    this.onLanguageToggle();
    const response$ = this.service.registerAndLogin({
      email: this.form.controls['email'].value,
      phone: this.iti.getNumber(),
      firstName: this.form.controls['firstName'].value,
      lastName: this.form.controls['lastName'].value,
      password: this.form.controls['password'].value,
      defaultLanguageID: this.selectedLanguage.id,
      company: this.form.controls['company'].value,
      country: this.form.controls['country'].value.code,
      mailPermission: this.isTurkishSelected ? true : this.form.controls['acceptMail'].value,
      languageCode: this.selectedLanguage.languageCode,
      postalCode: this.form.controls['postalCode'].value,
      adressLine: this.form.controls['adressLine'].value,
      city: this.form.controls['city'].value,
      recaptchaKey: this.resolvedRecaptcha,
    });
   lastValueFrom(response$)
    .then((data) => {
        this.localizationService.langId = this.selectedLanguage.id;
        this.authenticationService.setCredentials(data);
        var userGroups = this.credentialsService
            .getUserGroups()
            .split(',');
      if (userGroups.some((s) => s.includes(ORGADMIN))) {
            this.router.navigate(
              [
                  this.route.snapshot.queryParams.redirect ||
                      'dashboard/hr-dashboard',
              ],
              {
                  replaceUrl: true,
                  queryParams: { licensePlanId: this.licensePlanId },
              }
          );
        } 
    })
    .catch((error) => {
        if (this.isRecaptchaActive) {
            this.captchaElem.reset();
            this.isRecaptchaResolved = false;
            this.resolvedRecaptcha = null;
            this.isRegisterAction = false;
        }
        this.spinner.hide();
    });
  }

}
