import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from '@app/core/configuration.service';
import { HttpBaseService } from '@app/core/http/http-base.service';
import { LocalizationService } from '@app/core/localization.service';
import { NotifyService } from '@app/core/notify.service';
import { ApiControllerEnum, EntityStateEnum } from '@app/shared/common/enums';
import { Observable } from 'rxjs/internal/Observable';
import { GeneralApplicationQuestionListItem } from '../models/generalApplicationQuestion-list-item';


@Injectable({
  providedIn: 'root'
})
export class GeneralApplicationQuestion extends HttpBaseService {

  constructor(private http: HttpClient
    , private notifyServ: NotifyService
    , private localizationService: LocalizationService
    , private configService: ConfigurationService) {
    super(http, ApiControllerEnum.GeneralApplicationQuestion, notifyServ, localizationService, configService);
  }

  getListByPositionID(positionID: string): Observable<GeneralApplicationQuestionListItem[]>{
    return this.get("GetListByPositionID?positionID=" + positionID);
  }

}
