import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalizationService } from '@app/core';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { AnswerService } from '@app/vinter/services/answer.service';
import { ApplicantBehaviourEventLogService } from '@app/vinter/services/applicant-behaviour-event-log.service';
import { QuestionService } from '@app/vinter/services/question.service';
import { RatingService } from '@app/vinter/services/rating.service';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { MessageService } from 'primeng/api';
import { positionStepListIcon, positionStepTextIcon, positionStepVideoIcon, vinterPureLogoRotatedPath, positionStepCodingQuestionIcon, HowToCalculateScoreAndRating, ExplanationOfCalculatingScoreAndRating, QuesitonWeightIcon, QuestionWeightToolTipIcon } from '@app/shared/common/constants';
import { AnswerWithContentAndChoiceListItem } from '@app/vinter/models/answer-list-item';
import { AnswerContentListItem } from '@app/vinter/models/answer-content-list-item';
import { ChoiceAnswerListItem } from '@app/vinter/models/choice-answer-list-item';
import { ChoiceContentListItem } from '@app/vinter/models/choice-content-list-item';
import { FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { QuestionContentListItem } from '@app/vinter/models/question-content-list-item';
import { EApplicantBehaviourEventType, EQuestionCategories, EQuestionContentURLType, ERatingType, EntityStateEnum, GPTEvalStatus, ProcessStatus, QuestionCollectionCategoryEnum, QuestionExpectedAnswerType } from '@app/shared/common/enums';
import { RatingFormItem } from '@app/vinter/models/rating-form-item';
import { AnswerContentService } from '@app/vinter/services/answer-content.service';
import { AnswerContentFormItem } from '@app/vinter/models/answer-content-form-item';

@Component({
    selector: 'app-applicant-step-question-list',
    templateUrl: './applicant-step-question-list.component.html',
    styleUrls: ['./applicant-step-question-list.component.scss']
})
export class ApplicantStepQuestionListComponent implements OnInit {

    @Input() item: any;
    @Input() applicantId: any;
    @Input() applicationId: any;

    @ViewChild('targetVideo') target: ElementRef;
    @ViewChild('pronunciationVideo') pronunciationTarget: ElementRef;

    positionStepListIcon = positionStepListIcon;
    positionStepTextIcon = positionStepTextIcon;
    positionStepVideoIcon = positionStepVideoIcon;
    positionStepCodingQuestionIcon = positionStepCodingQuestionIcon

    RatingType = ERatingType;

    checkedOpenEnded: boolean = false;
    checkedMultipleChoice: boolean = false;
    checkedVideo: boolean = false;
    checkedCodingQuestion: boolean = false
    filteredList: any[] = []; // Filtrelenmiş liste için kullanılacak
    originalList: any[] = []; // Filtrelemeden önceki orijinal liste

    selectedId: string;
    selectedRadioValue: any;
    vinterLogo = vinterPureLogoRotatedPath;
    QuestionExpectedAnswerType = QuestionExpectedAnswerType;
    GPTEvalStatus = GPTEvalStatus;
    selectedSideBarId: string;

    checked: boolean;
    answer: AnswerWithContentAndChoiceListItem;
    questionPageHiddenLog: number;
    questionMouseOutLog: number;
    stepBehaviourEventLogs=[];
    subtitles: any[] = [];
    emotionScores: { [emotion: string]: number } = {};
    answerContent: AnswerContentListItem;
    answerText: string;
    answerType: number;
    gptEvalScore: number;
    transcriptStatus: number;
    gptEvalStatus: number;
    choiceAnswers: ChoiceAnswerListItem;
    choiceContents: ChoiceContentListItem[] = [];
    questionText: string;
    sidebarVisible: boolean = false;
    ratingId: string;
    ratingXmin: number;
    selectedStar = 0;
    isVinterScore: boolean = false;
    ratingComment: string;
    applicationStepId: string;
    isQuestionAnswered: boolean = false;
    popupOpen = false;
    form: FormGroup;
    questionKeywords: any[] = [];
    lang:string;
    eventlogTypes:any[];
    questionContent: QuestionContentListItem;
    QuestionContentType=EQuestionContentURLType;
    QuestionCategories=EQuestionCategories;
    selectedQuestion: any = null;
    questionConcept: string;
    questionWeight: number;
    selectedAnswer: string;
    isRatingType: number;

    averageSteps: number;

    voiceSpeechSpeedTypes: any[];
    deviceTypes: any[];
    questionCollectionLanguageList: any[] = [];

    cheatingQuestionLog: any[]
    questionOrder: number;

    choiceContentId: any;
    selectedAnswerChoice: any
    activeIndex: number = 0;
    parsedCodingAnalysisResultDetail: any;

    @Output() sendAverageRating: EventEmitter<number> = new EventEmitter<number>();
    @ViewChild('transcriptDiv') transcriptDiv: ElementRef;
    @ViewChild('pronunciationDiv') pronunciationDiv: ElementRef;


    sidebarVisibles : boolean = false;
    answerSidebarVisible : boolean = false;


    selectedStars: any[];
    questionRatingType: any = 0;

    gptEvalStatusEnum = GPTEvalStatus;
    processStatusEnum=ProcessStatus;
    questionCollectionCategories = QuestionCollectionCategoryEnum;
    questionWeightIconPath = QuesitonWeightIcon;
    questionWeightToolTipIconPath = QuestionWeightToolTipIcon;

    emotionColors = {
        angry: '#FE5F55',
        disgust: '#3AB1C8',
        fear: '#7ECA9C',
        happy: '#FFA372',
        sad: '#1597E5',
        surprise: '#9E67BF',
        neutral: '#BEBBAD',
        facenotfound: '#FFFFFF'
    };

    questionCategory: any;
    showMispronunciation: boolean = true;
    showOmission: boolean = true;
    showInsertion: boolean = true;
    mispronunciationCount = 0;
    omissionCount = 0;
    insertionCount = 0;
    pronunciationWords = [];


    constructor(private answerService: AnswerService,
        private eventLogService: ApplicantBehaviourEventLogService,
        private messageService: MessageService,
        private localizationService: LocalizationService,
        private appInfoService: AppInfoService,
        private ratingService: RatingService,
        private questionService: QuestionService,
        public route: ActivatedRoute,
        public router: Router,
        public api: VgApiService,
        public answerContentService: AnswerContentService) { }

    ngOnInit(): void {
        this.item.positionStep.questionCollection;
        this.activeIndex = 0;
        this.lang = this.localizationService.langCode;
        this.questionCollectionLanguageList = this.appInfoService.getQuestionCollectionLanguageList();

        this.eventlogTypes = this.appInfoService.getApplicantBehaviourEventTypes();
        this.voiceSpeechSpeedTypes = this.appInfoService.getVoiceSpeechSpeedTypes();
        this.deviceTypes = this.appInfoService.getDeviceTypes();

        this.applicationStepId = this.item.id;
        this.getApplicantBehaviourEventLog(this.applicationStepId);
        this.originalList = this.item.positionStep.questionCollection.questionCollectionQuestionRelations;
        this.filterList();

        this.form = new FormGroup({
            comment: new FormControl('', [this.trimValidator]),
        });
    }

    onHideAnswerSidebar(isScoreChanged){
        this.answerSidebarVisible = false;
        if(isScoreChanged){
            window.location.reload();
        }
    }

    getShortLangText(languageID){
        const keyName = this.questionCollectionLanguageList.find(x=> x.id === languageID)?.name;
        const text = keyName ?? false ? this.localizationService.translatedTextList['QCollectionShort'+keyName] : '';
        return text;
    }

    filterList() {
        this.filteredList = this.originalList
        .filter(item => {
            if (
                (!this.checkedOpenEnded && !this.checkedMultipleChoice && !this.checkedVideo && !this.checkedCodingQuestion) ||
                (this.checkedOpenEnded && item.question.expectedAnswerType === 2) ||
                (this.checkedMultipleChoice && item.question.expectedAnswerType === 1) ||
                (this.checkedVideo && item.question.expectedAnswerType === 3) ||
                (this.checkedCodingQuestion && item.question.expectedAnswerType === 4)
            ) {
                return true;
            }
        })
        .sort(x => x.Order);
        this.filteredList.forEach(element => {
            element.question.langShort = this.getShortLangText(element.question.languageID);
        })
    }


    getApplicantBehaviourEventLog(applicantStepId) {
        this.stepBehaviourEventLogs = [];
        let eventLogFilter: any = {};
        eventLogFilter.applicationStepId = applicantStepId;
        this.eventLogService.getListByFilter(eventLogFilter).subscribe((logResult) => {
            this.stepBehaviourEventLogs = logResult;

            let questionCollectionRelation = this.item.positionStep.questionCollection.questionCollectionQuestionRelations;
            this.cheatingQuestionLog = questionCollectionRelation
                .map(item1 => ({
                    ...item1,
                    eventTypes: this.stepBehaviourEventLogs
                        .filter(item2 => item1.questionID === item2.questionId && (item2.eventType === EApplicantBehaviourEventType.PageHidden))
                        .map(log => log.eventType)
                }))
                .filter(item => item.eventTypes.length > 0);
            this.selectedStars = this.item.positionStep.questionCollection.questionCollectionQuestionRelations.map(item => {
                return {
                    questionId: item.questionID,
                    answerId: item.question.answerId,
                    score: item.question.ratingScore
                }
            });

        });
    }

    checkIfQuestionLogExists(questionId): boolean {
        if (this.cheatingQuestionLog ?? false) {
            return this.cheatingQuestionLog.some(item => item.questionID === questionId);
        }
        else {
            return false;
        }

    }

    trimValidator: ValidatorFn = (control: FormControl) => {
        if (control?.value !== null && control?.value.startsWith(' ')) {
            return {
                error: { value: 'Key.StartsWithErr' },
            };
        }
        if (control?.value !== null && control?.value.endsWith(' ')) {
            return {
                error: { value: 'Key.EndsWithErr' },
            };
        }
        return null;
    };

    getRatingScoreByQuestionId(questionId: string) {
        let questionRate = this.selectedStars.find(item => item.questionId == questionId);
        let result = questionRate?.score;
        return result;
    }

    toggleSidebar(): void {
        this.sidebarVisible = !this.sidebarVisible;
    }

  checkIfQuestionLog(eventType, id) {
    const questionLog = this.cheatingQuestionLog.find(item => item.questionID === id);
    if(eventType === EApplicantBehaviourEventType.PageHidden){
      return (questionLog ?? false) ? questionLog.eventTypes.filter(eventType => eventType === EApplicantBehaviourEventType.PageHidden).length : 0;
    }
    if(eventType === EApplicantBehaviourEventType.MouseOut){
      return (questionLog ?? false) ? questionLog.eventTypes.filter(eventType => eventType === EApplicantBehaviourEventType.MouseOut).length : 0;
    }
  }

    getAnswerByQuestionId(index) {
        if(index === this.filteredList.length){
            index = 0;
        }
        else if(index === -1){
            index = this.filteredList.length-1;
        }
        this.resetQuestionSidebar();
        this.selectedQuestion = this.filteredList[index].question;
        let questionID = this.filteredList[index].question.id;
        this.questionWeight = this.filteredList[index].weight;
        let questionConcept = this.filteredList[index].question.questionConcept;
        let questionContent = this.filteredList[index].question.questionContent;
        let keywords = this.filteredList[index].question.keywords;
        let expectedAnswerType = this.filteredList[index].question.expectedAnswerType;
        let order = this.filteredList[index].order;
        this.isQuestionAnswered = this.filteredList[index].question.isAnswered;
        this.questionCategory = this.filteredList[index].question.questionCategory;
        this.sidebarVisible = true;


        this.selectedId = questionID;
        this.selectedStar = this.getRatingScoreByQuestionId(questionID);
        this.answerService.getDataByFilter({ questionId: questionID, applicantID: this.applicantId, applicationStepID: this.applicationStepId }).subscribe(result => {
            this.answer = result;
            this.questionOrder = order;
            this.questionKeywords = keywords;
            this.choiceContentId = result?.choiceAnswers?.choiceContentId;

            this.questionPageHiddenLog = this.checkIfQuestionLog(EApplicantBehaviourEventType.PageHidden,questionID);


            if (result != null && result.id != null && result.answerContent != null) {
                if (result.answerContent.subtitle != null) {
                    let all = (JSON.parse(result.answerContent.subtitle.toString()));
                    this.subtitles = all.segments;
                    const emotionTotals: { [emotion: string]: number } = {};
                    const totalSubtitles = this.subtitles.reduce((sum, item) => sum + item.emotion_score, 0);
                    this.subtitles.forEach((subtitle) => {
                        const emotion = subtitle.emotion.toLowerCase();
                        emotionTotals[emotion] = (emotionTotals[emotion] || 0) + subtitle.emotion_score;
                    });

                    for (const emotion in emotionTotals) {
                        if (emotionTotals.hasOwnProperty(emotion)) {//---- kontrol gerekebilir
                            this.emotionScores[emotion] = (emotionTotals[emotion] / totalSubtitles) * 100;
                        }
                    }

                }

                if (result.answerContent.answerContentPronunciationAnalysis != null && result.answerContent.answerContentPronunciationAnalysis.analysisResultDetail) {
                    let all = (JSON.parse(result.answerContent.answerContentPronunciationAnalysis.analysisResultDetail.toString()));
                    let respArray = all.response;

                    let wordArray = [];
                    let wordIndex = 0;
                    for (let i = 0; i < respArray.length; i++) {
                        const pItem = respArray[i];
                        for (let n = 0; n < pItem.NBest.length; n++) {
                            const nItem = pItem.NBest[n];
                            for (let w = 0; w < nItem.Words.length; w++) {
                                const wItem = nItem.Words[w];
                                let wordObj: any = {};
                                wordObj.index = wordIndex++;
                                wordObj.word = wItem.Word;
                                wordObj.score = wItem.PronunciationAssessment.AccuracyScore;
                                wordObj.errorType = wItem.PronunciationAssessment.ErrorType;


                                if (wordObj.errorType == "Omission") {
                                    wordObj.styleClass = "Omission";
                                    this.omissionCount++;
                                }
                                else {
                                    wordObj.start = wItem.Offset / 10000000;
                                    wordObj.end = wordObj.start + wItem.Duration / 10000000;
                                    wordObj.start = wordObj.start.toFixed(2);
                                    wordObj.end = wordObj.end.toFixed(2);

                                    if (wordObj.errorType == "Mispronunciation") {
                                        wordObj.styleClass = "Mispronunciation";
                                        this.mispronunciationCount++;
                                    }
                                    else if (wordObj.errorType == "Insertion") {
                                        wordObj.styleClass = "Insertion";
                                        this.insertionCount++;
                                    }
                                }


                                if (wItem.Phonemes != null) {
                                    wordObj.phonemes = wItem.Phonemes.map(wi => (
                                        {
                                            phoneme: wi.Phoneme,
                                            score: wi.PronunciationAssessment.AccuracyScore
                                        }
                                    ))
                                }
                                wordArray.push(wordObj);
                            }
                        }
                    }
                    this.pronunciationWords = wordArray;

                }
                if (this.answer?.answerContent?.codingAnalysis ?? false) {
                    this.parsedCodingAnalysisResultDetail = (JSON.parse(this.answer.answerContent.codingAnalysis.analysisResultDetail.toString()));
                }
                this.questionText = questionContent.questionText;
                this.questionConcept = questionConcept?.name;
                this.questionContent = questionContent;
                this.answerContent = result.answerContent;
                this.gptEvalStatus = result.answerContent.gptEvalStatus;
                this.transcriptStatus = result.answerContent.transcriptStatus;
                this.answerText = result.answerContent.answerText;
                this.answerType = expectedAnswerType;
                this.gptEvalScore = result.answerContent.gptEvalScore;
                this.getRating(false, 1, false, false);
                if (this.questionCategory == EQuestionCategories.Pronunciation)
                    this.playVideoPronunciation(this.answerContent?.contentURL);
                else
                    this.playVideo(this.answerContent?.contentURL);



            }
            else if (result != null && result.id != null) {
                this.answerType = expectedAnswerType;
                this.choiceAnswers = result.choiceAnswers;
                this.questionService.getDataByFilterWithChoiceContent({ id: result.questionId }).subscribe(question => {
                    this.answerSidebarVisible = true;
                    this.choiceContents = question.choiceContents;
                    this.questionConcept = questionConcept?.name;
                    this.questionContent = question.questionContent;
                    this.questionText = questionContent.questionText;

                    var selectedAnswerIndex = this.choiceContents.findIndex(x => x.id == this.choiceContentId);
                    this.selectedAnswerChoice = this.choiceContents[selectedAnswerIndex];
                    this.getRating(false, 1, false, false);
                });
            }
            else {
                this.questionText = questionContent.questionText;
                this.gptEvalScore = 0;
                this.questionContent = questionContent;
                this.questionConcept = questionConcept?.name;
                this.answerType = expectedAnswerType;
                this.answerText = null;
                this.answerContent = null;
                if (this.answerType === 1) {
                    this.questionService.getDataByFilterWithChoiceContent({ id: questionID }).subscribe(question => {
                        this.answerSidebarVisible = true;
                        this.choiceContents = question.choiceContents;
                        this.questionContent = question.questionContent;
                        this.questionText = questionContent.questionText;

                        this.getRating(false, 1, false, false);
                    });
                }
                else {
                    this.getRating(false, 1, false, false);
                }
            }
            this.answerSidebarVisible = true;
        });
    }

    setGptStatus(){
        let answerContentForm : AnswerContentFormItem = new AnswerContentFormItem();
        answerContentForm = this.answerContent;
        answerContentForm.gptEvalStatus = this.gptEvalStatusEnum.Waiting;
        answerContentForm.processStatus = this.processStatusEnum.AIContainerWaiting;
        answerContentForm.entityState = EntityStateEnum.Modified;
        answerContentForm.xmin = this.answerContent.xmin;

        this.answerContentService.SaveAsync(answerContentForm).subscribe();
        this.toggleSidebar();
    }

    resetQuestionSidebar(){
        this.parsedCodingAnalysisResultDetail = null;
        this.isQuestionAnswered = null;
        this.questionCategory = null;
        this.activeIndex = 0;
        this.showMispronunciation = true;
        this.showOmission = true;
        this.showInsertion = true;
        this.mispronunciationCount = 0;
        this.omissionCount = 0;
        this.insertionCount = 0;
        this.pronunciationWords = [];

    }

    getAnswerForRatingByQuestionId(question: any) {

        this.answerService.getDataByFilter({ questionId: question.id, applicantID: this.applicantId, applicationStepID: this.applicationStepId }).subscribe(result => {
            this.selectedQuestion = question;
            this.answer = result;
            if (result != null && result.id != null && result.answerContent != null) {
                this.answerContent = result.answerContent;
                this.gptEvalStatus = result.answerContent.gptEvalStatus;
                this.gptEvalScore = result.answerContent.gptEvalScore;
            }
            else if (result === null) {
                this.answerContent = null;
            }
            this.getRating(false, 1, false, true);

        });
    }

    getRating(closeAfter, ratingType, isPopUpAction, isVinterTrustClicked) {
        //answer
        let answer = this.selectedQuestion?.answerId;
        this.isRatingType = ratingType;
        this.ratingService.getDataByFilter({ answerId: answer }).subscribe(rating => {
            if (rating != null) {
                this.ratingId = rating.id;
                this.ratingXmin = rating.xmin;
                this.selectedStar = rating.ratingScore;
                this.ratingComment = rating.comment;
                this.questionRatingType = rating.ratingType;
            }
            else {
                this.ratingId = null;
                this.selectedStar = 0;
                this.ratingComment = "";
                this.questionRatingType = 0;

            }
            if (isPopUpAction) {
                this.popupOpen = true;
            } else if (ratingType === this.RatingType.VinterTrust && isVinterTrustClicked) {
                this.trustVinter(this.answerContent?.id);
            }
            if (closeAfter) {
                this.closePopup()
            }
        });
    }

    isCorrectAnswer(choiceContentId: string): boolean {
        this.selectedAnswer = this.choiceAnswers?.choiceContentId;
        const correctChoice = this.choiceContents.find(choice => choice.id === this.selectedAnswer);
        this.selectedRadioValue = correctChoice ? correctChoice.choiceText : null;
        return this.choiceAnswers && this.choiceAnswers.choiceContentId === choiceContentId && this.choiceAnswers.isCorrect;
    }

    isWrongAnswer(choiceContentId: string): boolean {
        return this.choiceAnswers && this.choiceAnswers.choiceContentId === choiceContentId && !this.choiceAnswers.isCorrect;
    }

    checkAnswer(choiceContentId: string): boolean {
        let result = false;
        if (choiceContentId === null || choiceContentId === undefined) {
            result = false;
        }
        else {
            if (this.choiceAnswers.choiceContentId === choiceContentId && this.choiceAnswers.isCorrect) {
                result = true;
            }
            else {
                result = false;
            }
        }
        return result
    }

    highlightSubtitle(target: any) {
        this.subtitles.forEach((subtitle, index) => {
            subtitle.id = 's' + index;
            subtitle.highlighted = target.currentTime >= subtitle.start && target.currentTime < subtitle.end;
        });

        this.highlightSubtitleInView();
    }

    highlightPronunciation(target: any) {
        this.pronunciationWords.forEach((word, index) => {
            word.index = 'p' + index;
            word.highlighted = target.currentTime >= word.start && target.currentTime < word.end;
        });

        this.highlightPronunciationInView();
    }

    highlightSubtitleInView() {
        let activeSubtitle = this.subtitles.find(subtitle => subtitle.highlighted);

        if (activeSubtitle) {
            let highlightedElement = this.transcriptDiv.nativeElement.querySelector(`#s${activeSubtitle.id}`);

            if (highlightedElement) {
                highlightedElement.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest"
                });
            }
        }
    }

    highlightPronunciationInView() {
        let activeWord = this.pronunciationWords.find(word => word.highlighted);

        if (activeWord) {
            let highlightedElement = this.pronunciationDiv.nativeElement.querySelector(`#p${activeWord.index}`);

            if (highlightedElement) {
                highlightedElement.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest"
                });
            }
        }
    }


    jumpToSecond(start: number) {
        if (this.api) {
            this.target.nativeElement.currentTime = start + 0.00001;
            this.highlightSubtitle(this.target.nativeElement);
            this.target.nativeElement.play();
        }
    }

    jumpToSecondPronunciation(start: number) {
        if (this.api && start) {
            this.pronunciationTarget.nativeElement.currentTime = start;
            this.highlightPronunciation(this.pronunciationTarget.nativeElement);
            this.pronunciationTarget.nativeElement.play();
        }
    }


    getColor(emotion: string, score: number): string {
        const alpha = Math.min(1, score / 100);
        const originalColor = this.emotionColors[emotion.toLowerCase()] || '#FFFFFF';
        const computedColor = (score !== -1) ? (originalColor.slice(0, 7) + Math.round(alpha * 255).toString(16).toUpperCase()) : (originalColor.slice(0, 7));
        return computedColor;
    }

    closePopup() {
        this.popupOpen = false
        if (this.ratingId == null) {
            this.selectedStar = 0;
            this.resetForm();
        }
    }

    trustVinter(contentId) {
        if (contentId != null) {
            this.selectedStar = Math.round(this.answerContent.gptEvalScore / 10) / 2;
        }

        this.isVinterScore = true;
        this.onFormSubmit(true, 1);
    }

    showPopup(event, question) {
        this.selectedQuestion = question;
        this.getRating(false, 2, true, false);
    }


    playVideo(url: string) {
        if (this.target) {
            this.target.nativeElement.src = url ?? false ? url : '';
            this.target.nativeElement.load();
        }
    }

    playVideoPronunciation(url: string) {
        if (this.pronunciationTarget) {
            this.pronunciationTarget.nativeElement.src = url ?? false ? url : '';
            this.pronunciationTarget.nativeElement.load();
        }
    }


    resetForm() {
        this.ratingId = null;
        this.ratingXmin = null;
        this.form.reset();
    }

    rate(rating: any) {
        this.selectedStar = rating;
    }

    updateAverageSteps() {
        this.sendAverageRating.emit(0);
    }

    patchRatingInfoFormControl(isGPTEval: boolean, ratingType) {
        let ratingInfo = new RatingFormItem();
        ratingInfo.comment = !isGPTEval ? this.ratingComment : this.answerContent.gptEvalText;
        ratingInfo.ratingScore = this.selectedStar;
        ratingInfo.answerId =  this.selectedQuestion.answerId;
        ratingInfo.applicationStepId = null;
        ratingInfo.ratingType = ratingType;
        ratingInfo.applicationId = this.applicationId;
        return ratingInfo;
    }

    updateRatingScoreByAnswerId(answerId: string, newScore) {
        let questionRate = this.selectedStars.find(item => item.answerId === answerId);
        let result = questionRate?.score;
        result = newScore;
    }

    onFormSubmit(isGPTEval: boolean, ratingType: number) {
        if (this.form.invalid) return;
        let ratingInfo = this.patchRatingInfoFormControl(isGPTEval, ratingType);
        if (ratingInfo.ratingScore != null && ratingInfo.ratingScore != 0) {
            if (this.ratingId !== null && this.ratingId !== undefined) {
                ratingInfo.id = this.ratingId;
                ratingInfo.xmin = this.ratingXmin;
                ratingInfo.entityState = EntityStateEnum.Modified;
                var item = {
                    rating: ratingInfo,
                    questionCollectionId: (this.item.positionStep.questionCollection ?? false) ? this.item.positionStep.questionCollection.id : null,
                    applicationStepId: this.item.id
                };
                this.ratingService.updateRatingWithAvg(item).subscribe((rating) => {
                    if (rating ?? false) {
                        this.selectedQuestion.ratingScore = rating.rating;
                        this.item.averageRating = rating.stepRating;
                        this.selectedQuestion.ratingType = ratingType;
                    }
                    this.updateAverageSteps();
                    this.getRating(true, ratingType, false, false);
                    this.updateRatingScoreByAnswerId(ratingInfo.answerId, ratingInfo.ratingScore);

                });
            } else {
                ratingInfo.entityState = EntityStateEnum.Added;
                var item = {
                    rating: ratingInfo,
                    questionCollectionId: (this.item.positionStep.questionCollection ?? false) ? this.item.positionStep.questionCollection.id : null,
                    applicationStepId: this.item.id
                };
                this.ratingService.addRatingWithAvg(item).subscribe((rating) => {
                    if (rating ?? false) {
                        this.selectedQuestion.ratingScore = rating.rating;
                        this.item.averageRating = rating.stepRating;
                        this.selectedQuestion.ratingType = ratingType;
                    }
                    this.updateAverageSteps();
                    this.getRating(true, ratingType, false, false);
                    this.updateRatingScoreByAnswerId(ratingInfo.answerId, ratingInfo.ratingScore);

                });
            }
        }
        else {
            this.messageService.add({ severity: 'error', summary: this.localizationService.translatedTextList.Error, detail: this.localizationService.translatedTextList.RatingScoreNotNull, life: 3000 });
            this.isVinterScore = false;
        }
    }

    getProgressBarColor(score: number): string {
        if (score <= 10) return '#DC2626';
        if (score <= 20) return '#DC2667';
        if (score <= 30) return '#DC2688';
        if (score <= 40) return '#DC269E';
        if (score <= 50) return '#AD26DC';
        if (score <= 60) return '#8126DC';
        if (score <= 70) return '#6026DC';
        if (score <= 80) return '#3F26DC';
        if (score <= 90) return '#1549D6';
        return '#2962FF';
    }

    getPronunciationTooltip(wordObj) {
        let html = "<div style='display:flex;flex-direction: column;'>" +
            "<div class='col-12' style='padding-top: 0;padding-left: 0;padding-right: 0;'>" +
            "<span style='color: var(--gray-600);font-weight: 700;'>" +
            wordObj.word +
            ":</span>" +
            "<span style='color: var(--blue-500);margin-left: 10px;'>" +
            wordObj.score +
            "</span></div>" +
            "<div class='col-12 flex' style='padding: 0;'>";

        let phonemes = "";
        if (wordObj.phonemes) {
            wordObj.phonemes.forEach(p => {
                phonemes += "<div style='margin-right: 8px;text-align: center;'><div style='margin-bottom: 5px;'>" + p.phoneme + "</div><div style='color: var(--blue-300);'>" + p.score + "</div></div>"
            });
        }
        html += phonemes + "</div>" + "</div>"

        return html;
    }

    getWeightAvarageScoreTooltip() {
        let html =
            "<div style='display:flex;flex-direction: column;'>" +
                "<div class='col-12' style='padding-top: 0;padding-left: 0;padding-right: 0;padding-bottom: 0; max-width:25rem;'>" +
                    "<div style='color: #ffffff;font-weight: 700; font-size: 1.25rem;'>" + this.localizationService.translatedTextList.HowToCalculateScoreAndRating + "</div>" +
                    "<div style='color: var(--blue-100); margin-top: 0.5rem'; display:flex; flex-wrap: wrap;>" + this.localizationService.translatedTextList.ExplanationOfCalculatingScoreAndRating + "</div>" +
                "</div></div>";
        return html;
    }
}

@Pipe({
    name: 'applicantBehaviourEventLogTypes',
})
export class ApplicantBehaviourEventLogTypesPipe implements PipeTransform {
    transform(row: any, list: any[]): any {
        return list.find(x => x.id === row).name;
    }
}

@Pipe({
    name: 'voiceSpeechSpeedTypes',
})
export class VoiceSpeechSpeedTypesPipe implements PipeTransform {
    transform(row: any, list: any[]): any {
        return list.find(x => x.id === row).name;
    }
}

@Pipe({
    name: 'deviceTypes',
})
export class DeviceTypesPipe implements PipeTransform {
    transform(row: any, list: any[]): any {
        return list.find(x => x.id === row).name;
    }
}
