import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService, HttpBaseService, LocalizationService, NotifyService } from '@app/core';
import { ApiControllerEnum } from '@app/shared/common/enums';
import { Observable } from 'rxjs';
import { ApplicationListItem } from '../models/application-list-item';
import { ApplicationStepListItem } from '../models/application-step-list-item';
import { KanbanStepList } from '../models/position-applicants-kanban-item';

@Injectable({
  providedIn: 'root'
})
export class ApplicationStepService extends HttpBaseService {

  constructor(private http: HttpClient
    , private notifyServ: NotifyService
    , private localizationService: LocalizationService
    , private configService: ConfigurationService) {
    super(http, ApiControllerEnum.ApplicationStep, notifyServ, localizationService, configService);
  }

  GetApplicationStepsForPosition(filter: any): Observable<any[]> {
    return this.post("GetApplicationStepsForPosition", filter, true);
  }

  GetApplicationStepsForApplicantDetail(filter: any): Observable<any[]> {
    return this.post("GetApplicationStepsForApplicantDetail", filter, true);
  }

  GetApplicationStepResult(filter: any): Observable<any> {
    return this.post("GetApplicationStepResult", filter, true);
  }

  GetListPdfReportInfo(filter: any): Observable<any[]> {
    return this.post("GetListPdfReportInfo", filter, true);
  }

  GetApplicationSteps(positionID: any): Observable<KanbanStepList[]> {
    let params = new HttpParams();
    params = params.append('positionID', positionID);

    return this.getWithParams("GetApplicationSteps", params);
  }

  GetDataByFilter(filter: any): Observable<any> {
    return this.post("getDataByFilter", filter, true);
  }


  redoStep(filter: any): Observable<any>
  {
    return this.post("RedoPositionStep",filter, true);
  }

  saveAsync(obj: any): Observable<any> {
    return this.postWithoutSpinner("SaveAsync", obj, true);
  }

}
