import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CredentialsService } from '@app/core';
import { UserGroupCodeEnum, UserGroupEnum } from '@app/shared/common/enums';

@Component({
  selector: 'app-license-locked-dialog',
  templateUrl: './license-locked-dialog.component.html',
  styleUrls: ['./license-locked-dialog.component.scss']
})
export class LicenseLockedDialogComponent implements OnInit {
  showDialog: boolean = true;
  isHr: boolean;

  constructor(private router: Router,
     private route: ActivatedRoute,
     private credentialsService: CredentialsService) { }

  ngOnInit(): void {
    this.isHr = this.credentialsService.getUserGroups() === UserGroupCodeEnum.HR;
  }

  @Output() dialogVisibiltyEmitter: EventEmitter<void> = new EventEmitter<void>();

  closeDialog() {
    this.dialogVisibiltyEmitter.emit();
  }
  goToPlans() {
    this.router.navigate([this.route.snapshot.queryParams.redirect || '/organization/settings'], {
      replaceUrl: true, queryParams: {
        activeTab: 3,
      }
    });
  }
}
