import { EntityStateEnum} from "@app/shared/common/enums";

export class ApplicationOfferItem {
    id: string;
    offerAccepted?: boolean;
    rejectionReason: number;
    note: string;
    applicationID: string;
    entityState: EntityStateEnum;
    xmin: string;
}
