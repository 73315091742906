import { Component, Input } from '@angular/core';
import { EQuestionCollectionSubCategory } from '@app/shared/common/enums';

@Component({
  selector: 'app-ocean-test-card',
  templateUrl: './ocean-test-card.component.html',
  styleUrls: ['./ocean-test-card.component.scss']
})
export class OceanTestCardComponent {
  
  @Input() step: any;
  @Input() report: any;

  QuestionCollectionSubCategory = EQuestionCollectionSubCategory;

}
