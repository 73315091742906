<div class="col-12 flex pt-0">
    <div class="col-8 flex align-items-center">
        <div class="flex justify-content-center p-0">
            <p-button styleClass="p-button-rounded p-button-secondary p-button-text" (onClick)="onButtonClick()"
                icon="pi pi-arrow-left"></p-button>
        </div>
        <div class="backToButton">
            {{"Key.BackToKanbanMessage1" | translate}}
            <span class="highlight">{{positionTitle}}</span>
            {{"Key.BackToKanbanMessage2" | translate}}
        </div>
    </div>
    <div class=" col-4 flex justify-content-end">
        <div *ngIf="isCompletedAnyApplicationStepStatus(this.applicationStepsData)"
            class="flex align-items-center justify-content-center">
            <span class="cursor-pointer mr-2 mt-1 backToButton" (click)="toggle()">{{"Key.Download" | translate}}</span>
            <img class="cursor-pointer" src="assets/vinter/icons/download-pdf-icon.svg" (click)="toggle()" />
            <p-overlay [(visible)]="overlayVisible" contentStyleClass="surface-overlay shadow-2 border-round p-3 pb-1"
                (onAnimationStart)="onBeforeShowHandler($event)">
                <a class="w-full flex mb-3 cursor-pointer backToButton" (click)="checkLicenseLockAndGeneratePdf()">
                    {{'Key.DetailPdfDownload' | translate}}
                    <img class="icon-width" src="assets/vinter/icons/download-pdf-icon.svg"></a>
                <a class="w-full flex mb-3 cursor-pointer backToButton" (click)="checkLicenseLockAndGenerateSummaryPdf()">
                    {{'Key.SummaryPdfDownload' | translate}}
                    <img class="icon-width" src="assets/vinter/icons/download-pdf-icon.svg"></a>

                <a *ngIf="showRequestAIReportBtn" class="w-full flex mb-3 cursor-pointer backToButton" (click)="checkLicenseLockAndRequestAIBasedPdf()">
                    {{'Key.AIBasedRequest' | translate}}
                    </a>

                <a *ngIf="showPendingAIReportBtn" class="w-full flex mb-3 cursor-pointer backToButton" style="cursor: default !important;">
                    {{'Key.AIBasedPending' | translate}}
                    </a>

                <a *ngIf="showDownloadAIReportBtn" class="w-full flex mb-3 cursor-pointer backToButton"  (click)="checkLicenseLockAndDownloadAIBasedPdf()">
                    {{'Key.AIBasedDownload' | translate}}
                    <img class="icon-width" src="assets/vinter/icons/download-pdf-icon.svg"></a>



            </p-overlay>
        </div>
        <div class="vertical-line mx-5" style="height: 25px;"></div>
        <div *ngIf="!suggestSidebarVisible" class="flex align-items-center justify-content-center">
            <span class="cursor-pointer mr-2 mt-1 backToButton" (click)="suggestApplicant()">{{"Key.Suggest" |
                translate}}</span>
            <img class="cursor-pointer mt-1" src="assets/vinter/icons/suggest_icon.svg" (click)="suggestApplicant()" />
        </div>
    </div>
</div>
<div>
    <app-suggest-applicant [applicantMail]="applicantEmail" [applicantCurrentPosition]="positionId"
        [applicantInvitedPositions]="applicantInvitedPositions" (suggestedApplicant)="suggestedApplicant($event)">
    </app-suggest-applicant>
</div>
<div *ngIf="isPdfOpen" class="hidden" id="parentDivId">
    <div #applicantInfoPdf id="applicantInfoPdf" style="width: 575px; height: auto; ">
        <div #pdfCoverPage class="report">
            <div class="background">
                <img src="../../../../../assets/vinter/icons/pdfReport/pdf-background-gradient.png" alt="Gradient Background" class="background-image">
                <div class="blue-section">
                    <div class="report-footer">
                        <div class="line1"></div>
                        <h3 class="mx-3 my-0">{{'Key.ReportName' | translate}} </h3>
                        <div class="line2"></div>
                    </div>

                </div>
            </div>

            <div class="content">
                <div class="header">
                    <img class="align-items-center" src="../../../../../assets/vinter/icons/pdfReport/vinter-logo-pdf.svg" alt="Vinter Logo" class="logo">
                </div>
                <div class="position">
                    <h3>{{'Key.PositionNameTitle' | translate}} </h3>
                    <h1>{{positionTitle}}</h1>
                </div>
                <div class="candidate-info">
                    <h2>{{applicantFullName}}</h2>
                    <p *ngIf="isCompletedPosition" class="completed-date-text -mb-2 mt-3">{{stepCompletedDate | date:'dd/MM/yy'}}</p>
                    <div *ngIf="isCompletedPosition" class="flex align-items-center completed-position-container" >
                        <i class="pi pi-circle-fill" style="color: #37AC00"></i>
                        <p class="completed-text flex align-items-center justify-content-center mb-0">{{'Key.Completed' | translate}} </p>
                    </div>

                    <hr class="custom-hr m-0 -mb-2">

                    <div>
                        <div class="flex rate">
                        {{'Key.PdfRate' | translate}}
                    </div>
                    <div class="flex align-items-center overall-rate">
                        <span>{{overallRating?.toFixed(1)}}</span>
                    </div>

                    </div>

                    <div class="contact-info">
                        <div class="label flex">
                            <img src="assets/vinter/icons/pdf-mail.svg" style="width: 1rem; height: 1rem;" class="mt-4">
                            <div class="text-justify ml-2 mt-4">
                                {{applicantEmail}}
                            </div>
                        </div>
                        <div  class="label flex">
                            <img src="assets/vinter/icons/pdf-phone.svg" style="width: 1rem; height: 1rem;" class="mt-2">
                            <div class="text-justify ml-2 mt-2">
                                {{applicantPhoneNumber}}
                            </div>
                        </div>
                        <div *ngIf="linkedinProfileURL"  class="label flex">
                            <img src="assets/vinter/icons/pdf-linkedin.svg" style="width: 1rem; height: 1rem;" class="mt-2">
                            <div class="text-justify ml-2 mt-2">
                                {{linkedinProfileURL}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div #area class="avoid">



            <div class="mt-7 ml-7 mb-5 text-justify pdf-text-size" style="width: 33rem;">
                <p class="mb-3">
                    {{'Key.PdfInfoPart1' | translate}}
                </p>
                <p class="mb-3">
                    {{'Key.PdfInfoPart2' | translate}}
                </p>
                <p class="mb-3">
                </p>

            </div>

            <hr class="custom-hr my-7" style="width: 33rem;">
        </div>

        <div *ngFor="let item of applicationStepsData; let i = index">
            <div #area *ngIf="(item.completedDate ?? false)" class="mx-7 avoid">
                <div>
                    <span class="font-semibold text-lg my-5">{{item.positionStep.title}}</span>

                    <div *ngIf="item.completedDate ?? false" class="py-2">
                        <div class="flex">
                            <i class="pi pi-clock" style="color: #37AC00; font-size: 0.6rem;">
                                <span class="status-text font-semibold" style="font-size: 0.6rem;">
                                    {{'Key.Completed' | translate}}
                                </span>
                            </i>

                            <div *ngIf="item.startedDate ?? false" class="flex">
                                <span style="color: #37AC00; font-size: 0.6rem;">
                                    {{'Key.CompletedStepTime' | translate: { value: completedStepTime(item.startedDate,
                                    item.completedDate).toFixed(0)} }}
                                </span>
                                <span style="color: #9B9EA3; font-size: 0.6rem;">
                                    /{{'Key.CompletedStepTime' | translate: { value: item.positionStep.duration} }}
                                </span>
                            </div>

                        </div>

                    </div>

                    <div class="mb-4 text-justify pdf-text-size" style="width: 32rem;">
                        <p
                            *ngIf="item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.PersonalityTest && item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.OCEAN50">
                            {{'Key.PdfOceanInfo50' | translate}}
                        </p>
                        <p
                            *ngIf="item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.PersonalityTest  && item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.OCEAN120">
                            {{'Key.PdfOceanInfo120' | translate}}
                        </p>
                        <p
                            *ngIf="item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.ForeignLanguage">
                            {{'Key.PdfLanguageInfo' | translate}}
                        </p>
                        <p
                            *ngIf="item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.Pronunciation">
                            {{'Key.PronunciationPdfDesc' | translate}}
                        </p>
                        <p
                            *ngIf="item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.CompetenceTest">
                            {{'Key.PdfCompetenceInfo' | translate}}
                        </p>

                        <p
                            *ngIf="item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.TechnicalTest">
                            {{'Key.PdfTechnicalInfo' | translate}}
                        </p>

                        <p
                            *ngIf="item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.CognitiveAbility && item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.NumericalCognitiveAbility">
                            {{'Key.PdfNumericalCognitiveAbilityInfo' | translate}}
                        </p>
                        <div *ngFor="let category of answeredQuestionsWithId">
                            <p *ngIf="item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.CognitiveAbility
                            && item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.VerbalCognitiveAbility
                            && currentLanguageId === LanguageEnum.EnglishUnitedStates
                            && (category.answeredQuestionsCount + category.notAnsweredQuestionsCount > 0)">
                            Candidate <span>{{applicantFullName}}</span> has completed the Verbal Cognitive Ability Test. This test measures the candidate's ability to comprehend and analyze
                            complex texts, visual attention/visual interpretation, verbal reasoning, application of grammar rules, verbal reasoning, critical thinking, synthesizing information,
                            listening comprehension, summarizing and reconstructing, vocabulary and solving verbal patterns, and grammatical knowledge. The test aims to comprehensively assess the candidate's
                            verbal abilities through a variety of question types. The candidate answered <span>{{category.answeredQuestionsCount}}</span> questions in this test consisting of
                            <span>{{category.answeredQuestionsCount + category.notAnsweredQuestionsCount}}</span> questions. The candidate answered <span>{{category.correctAnsweredQuestionsCount}}</span> questions correctly,
                            <span>{{category.incorrectAnsweredQuestionsCount}}</span> questions incorrectly and <span>{{category.notAnsweredQuestionsCount}}</span> questions blank.
                            Accordingly, the score of the candidate was evaluated in the category '<span>{{category.totalScores}}</span>'.
                        </p>
                    </div>

                        <div *ngFor="let category of answeredQuestionsWithId">
                        <p
                            *ngIf="item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.CognitiveAbility
                            && item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.VerbalCognitiveAbility
                            && currentLanguageId === LanguageEnum.TurkishTurkey
                            && (category.answeredQuestionsCount + category.notAnsweredQuestionsCount > 0)">
                            Aday <span class="">{{applicantFullName}}</span> Sözel Bilişsel Yetenek Testini tamamlamıştır.Bu test, adayın karmaşık metinleri anlama ve analiz etme, görsel dikkat/görsel
                            yorumlama, sözel muhakeme yapma, dil bilgisi kurallarını uygulayabilme, sözel mantık yürütme, eleştirel düşünme, bilgileri sentezleme, dinlediğini anlama, özetleme ve yeniden
                            yapılandırma, kelime bilgisi ve sözel kalıpları çözme yeteneklerini ve gramer bilgisini ölçmektedir. Test, içerdiği çeşitli soru türleri ile adayın sözel yeteneklerini kapsamlı
                            bir şekilde değerlendirmeyi hedeflemektedir. Aday <span class="">{{category.answeredQuestionsCount + category.notAnsweredQuestionsCount}}</span> sorudan oluşan bu testte <span class="">{{category.answeredQuestionsCount}}</span>
                            soruyu yanıtlamıştır. Aday sorulardan <span class="">{{category.correctAnsweredQuestionsCount}}</span> tane soruyu doğru, <span class="">{{category.incorrectAnsweredQuestionsCount}}</span>
                            tane soruyu yanlış cevaplamış ve <span class="">{{category.notAnsweredQuestionsCount}}</span> tane soruyu boş bırakmıştır. Bu doğrultuda adayın aldığı puan
                            '<span class="">{{category.totalScores}}</span>' kategorisinde değerlendirilmiştir.
                        </p>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="my-4" style="width: 32rem">
                        <span class="font-semibold text-sm my-5" style="color:#002075;">{{'Key.CheatingAnalysis' |
                            translate}}</span>
                        <div class="flex justify-content-between flex-wrap my-2">
                            <div class="flex align-items-center justify-content-center pdf-text-size"
                                style="color: #686E75;">
                                {{'Key.Device' | translate}}
                            </div>
                            <div class="flex align-items-center justify-content-center font-semibold text-xs">
                                <p *ngIf="item.deviceType !== null">{{item.deviceType | deviceTypes:deviceTypes }} </p>
                                <p *ngIf="item.deviceType === null">{{'Key.DeviceNotFound' | translate}} </p>
                            </div>
                        </div>

                        <div class="flex justify-content-between flex-wrap my-2">
                            <div class="flex align-items-center justify-content-center pdf-text-size"
                                style="color: #686E75;">
                                {{'Key.SamePageUsed' | translate}}
                            </div>
                            <div *ngIf="!item.isSwitchedTab"
                                class="flex align-items-center justify-content-center font-semibold text-xs"
                                style="color:#37AC00">
                                {{'Key.Yes' | translate}}
                            </div>
                            <div *ngIf="item.isSwitchedTab"
                                class="flex align-items-center justify-content-center font-semibold text-xs"
                                style="color:#F20505">
                                {{'Key.No' | translate}}
                            </div>
                        </div>

                        <div class="flex justify-content-between flex-wrap my-2">
                            <div class="flex align-items-center justify-content-center pdf-text-size"
                                style="color: #686E75;">
                                {{'Key.FaceDetectedLog' | translate}}
                            </div>
                            <div *ngIf="item.isFaceDetectedLog"
                                class="flex align-items-center justify-content-center font-semibold text-xs"
                                style="color:#F20505">
                                {{'Key.Yes' | translate}}
                            </div>
                            <div *ngIf="!item.isFaceDetectedLog"
                                class="flex align-items-center justify-content-center font-semibold text-xs"
                                style="color:#37AC00">
                                {{'Key.No' | translate}}
                            </div>
                        </div>

                        <div class="flex justify-content-between flex-wrap my-2">
                            <div class="flex align-items-center justify-content-center pdf-text-size"
                                style="color: #686E75;">
                                {{'Key.MultiFaceDetectedLog' | translate}}
                            </div>
                            <div *ngIf="item.isMultipleFaceLog"
                                class="flex align-items-center justify-content-center font-semibold text-xs"
                                style="color:#F20505">
                                {{'Key.Yes' | translate}}
                            </div>
                            <div *ngIf="!item.isMultipleFaceLog"
                                class="flex align-items-center justify-content-center font-semibold text-xs"
                                style="color:#37AC00">
                                {{'Key.No' | translate}}
                            </div>
                        </div>

                        <div class="flex justify-content-between flex-wrap my-2">
                            <div class="flex align-items-center justify-content-center pdf-text-size"
                                style="color: #686E75;">
                                {{'Key.LanguageInappropriateness' | translate}}
                            </div>
                            <div *ngIf="item.isImproprietySpeechLog"
                                class="flex align-items-center justify-content-center font-semibold text-xs"
                                style="color:#F20505">
                                {{'Key.Yes' | translate}}
                            </div>
                            <div *ngIf="!item.isImproprietySpeechLog"
                                class="flex align-items-center justify-content-center font-semibold text-xs"
                                style="color:#37AC00">
                                {{'Key.No' | translate}}
                            </div>
                        </div>

                        <div class="flex justify-content-between flex-wrap my-2">
                            <div class="flex align-items-center justify-content-center pdf-text-size"
                                style="color: #686E75;">
                                {{'Key.MultipleMonitor' | translate}}
                            </div>
                            <div *ngIf="!item.isMultipleMonitor"
                                class="flex align-items-center justify-content-center font-semibold text-xs"
                                style="color:#37AC00">
                                {{'Key.No' | translate}}
                            </div>
                            <div *ngIf="item.isMultipleMonitor"
                                class="flex align-items-center justify-content-center font-semibold text-xs"
                                style="color:#F20505">
                                {{'Key.Yes' | translate}}
                            </div>
                        </div>

                    </div>

                    <!-- area class="avoid" 1 rating score-->
                    <div
                        *ngIf="(item.completedDate ?? false) && (item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.ForeignLanguage)">
                        <div class="my-4">
                            <div style=" width: 32rem;" class="my-4 mr-3 avoid">
                                <div class="flex justify-content-between flex-wrap my-2">
                                    <div class="flex align-items-center justify-content-center font-medium text-xs ">
                                        {{'Key.PdfVinterScore' | translate}}
                                    </div>
                                    <div class="flex align-items-center justify-content-center font-semibold">
                                        <div class="custom-display w-12rem flex">
                                            <div class="progress-bar-container mt-2 mr-2">
                                                <div class="progress-bar" [style.width]="item.averageVinterScore + '%'"
                                                    [style.backgroundColor]="'#3F26DC'"></div>
                                            </div>
                                            <div
                                                *ngIf="item.positionStep.questionCollection.subCategory !== QuestionCollectionSubCategory.GeneralEnglish">
                                                <p class="text-xs ">{{item.averageVinterScore?.toFixed(2)}}</p>
                                            </div>
                                            <div *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.GeneralEnglish"
                                                class="flex">
                                                <p class="text-xs font-medium">
                                                    {{item.generalEnglishTestResult?.generalLevel}}</p>
                                                <p class="text-xs font-medium" style="color: #686E75;">
                                                    /{{item.averageVinterScore?.toFixed(2)}}</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="flex justify-content-between flex-wrap my-2">
                                    <div class="flex align-items-center justify-content-center font-medium text-xs ">
                                        {{'Key.PdfRate' | translate}}
                                    </div>
                                    <div class="flex align-items-center justify-content-center font-semibold">
                                        <p class="text-xs ">{{item.averageRating?.toFixed(2)}}</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>



                    <!-- area class="avoid" 2 rating score-->
                    <div
                        *ngIf="(item.completedDate ?? false) && (item.positionStep.questionCollection.questionCollectionCategory !== QuestionCollectionCategoryEnum.PersonalityTest) &&
                        (item.positionStep.questionCollection.questionCollectionCategory !== QuestionCollectionCategoryEnum.ForeignLanguage) &&
                        (item.positionStep.questionCollection.questionCollectionCategory !== QuestionCollectionCategoryEnum.Pronunciation)">
                        <div class="my-4">
                            <div style="width: 32rem;" class="my-4 mr-3 avoid">
                                <div class="flex justify-content-between flex-wrap my-2">
                                    <div class="flex align-items-center justify-content-center font-semibold text-xs ">
                                        {{'Key.PdfVinterScore' | translate}}
                                    </div>
                                    <div class="flex align-items-center justify-content-center font-semibold">
                                        <div class="custom-display w-12rem flex">
                                            <div class="progress-bar-container mt-2 mr-2">
                                                <div class="progress-bar" [style.width]="item.averageVinterScore + '%'"
                                                    [style.backgroundColor]="'#3F26DC'"></div>
                                            </div>
                                            <p class="text-xs ">{{item.averageVinterScore?.toFixed(2)}}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="flex justify-content-between flex-wrap my-2">
                                    <div class="flex align-items-center justify-content-center font-semibold text-xs ">
                                        {{'Key.PdfRate' | translate}}
                                    </div>
                                    <div class="flex align-items-center justify-content-center font-semibold">
                                        <p class="text-xs ">{{item.averageRating?.toFixed(2)}}</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                *ngIf="(item.completedDate ?? false) && (item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.PersonalityTest)">
                <div class="mx-7" id="ocean">
                    <div class="my-4">
                        <div #area style="width: 32rem; " class="mb-3 avoid">
                            <div class="ocean-grid">
                                <div class="grid flex pb-3" style="border: none;">
                                    <div class="col-1 ocean-icon-width align-content-center custom-display">
                                        <img class="mr-1" src="../../../../../assets/vinter/icons/pdfReport/o-icon.svg"
                                            width="22px">

                                    </div>
                                    <div
                                        class="col-5 pl-1 pr-0 font-medium text-sm flex flex-wrap align-content-center o-text-color w-auto">
                                        {{"Key.OpennessToExperience" | translate}}
                                    </div>
                                </div>

                                <app-ocean-progressbar [progressBarOceanScore]="item?.stepPerTestResult?.o"
                                    [oceanCategory]="'o'" [oceanScore]="item?.stepPerTestResult?.o?.toFixed(0)"
                                    [oceanParent]="true"></app-ocean-progressbar>

                                <div *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.OCEAN120"
                                    class="flex justify-content-between">
                                    <div
                                        class="ocean-report-grid grid mt-3 ml-1 flex align-items-center justify-content-center">
                                        <p class="pdf-text-size h-full mt-2">
                                            {{"Key.OpennessLowScoreDescription" | translate}}
                                        </p>

                                    </div>
                                    <div
                                        class="ocean-report-grid grid mt-3 flex align-items-center justify-content-center">
                                        <p class="pdf-text-size text-right h-full mt-2">
                                            {{"Key.OpennessHighScoreDescription" | translate}}
                                        </p>

                                    </div>
                                </div>
                            </div>
                            <div class="grid mx-0 pb-4 mt-0">
                                <p class="text-justify pdf-text-size">
                                    {{item?.stepPerTestResult?.reportTextO}}
                                </p>
                            </div>

                            <div
                                *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.OCEAN120">
                                <div class="grid flex flex-nowrap p-0" style="border: none;">
                                    <div
                                        class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center o-text-color">
                                        {{"Key.Imagination" | translate}}
                                    </div>
                                    <div class="col-9 pb-0  pt-2 align-content-center custom-display">
                                        <div class="w-18rem ml-7">
                                            <app-ocean-progressbar
                                                [progressBarOceanScore]="item?.stepPerTestResult?.imagination"
                                                [oceanCategory]="'o'"
                                                [oceanScore]="item?.stepPerTestResult?.imagination?.toFixed(0)"
                                                [oceanParent]="false"></app-ocean-progressbar>

                                        </div>

                                    </div>
                                </div>
                                <div class="grid flex flex-nowrap p-0" style="border: none;">
                                    <div
                                        class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center o-text-color">
                                        {{"Key.ArtisticInterest" | translate}}
                                    </div>
                                    <div class="col-9 pb-0  pt-2 align-content-center custom-display">
                                        <div class="w-18rem ml-7">
                                            <app-ocean-progressbar
                                                [progressBarOceanScore]="item?.stepPerTestResult?.artisticInterests"
                                                [oceanCategory]="'o'"
                                                [oceanScore]="item?.stepPerTestResult?.artisticInterests?.toFixed(0)"
                                                [oceanParent]="false"></app-ocean-progressbar>
                                        </div>

                                    </div>
                                </div>
                                <div class="grid flex flex-nowrap p-0" style="border: none;">
                                    <div
                                        class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center o-text-color">
                                        {{"Key.Emotionality" | translate}}
                                    </div>
                                    <div class="col-9 pb-0  pt-2 align-content-center custom-display">
                                        <div class="w-18rem ml-7">
                                            <app-ocean-progressbar
                                                [progressBarOceanScore]="item?.stepPerTestResult?.emotionality"
                                                [oceanCategory]="'o'"
                                                [oceanScore]="item?.stepPerTestResult?.emotionality?.toFixed(0)"
                                                [oceanParent]="false"></app-ocean-progressbar>
                                        </div>

                                    </div>
                                </div>
                                <div class="grid flex flex-nowrap p-0" style="border: none;">
                                    <div
                                        class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center o-text-color">
                                        {{"Key.Adventurousness" | translate}}
                                    </div>
                                    <div class="col-9 pb-0  pt-2 align-content-center custom-display">
                                        <div class="w-18rem ml-7">
                                            <app-ocean-progressbar
                                                [progressBarOceanScore]="item?.stepPerTestResult?.adventurousness"
                                                [oceanCategory]="'o'"
                                                [oceanScore]="item?.stepPerTestResult?.adventurousness?.toFixed(0)"
                                                [oceanParent]="false"></app-ocean-progressbar>
                                        </div>

                                    </div>
                                </div>
                                <div class="grid flex flex-nowrap p-0" style="border: none;">
                                    <div
                                        class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center o-text-color">
                                        {{"Key.Intellect" | translate}}
                                    </div>
                                    <div class="col-9 pb-0  pt-2 align-content-center custom-display">
                                        <div class="w-18rem ml-7">
                                            <app-ocean-progressbar
                                                [progressBarOceanScore]="item?.stepPerTestResult?.intellect"
                                                [oceanCategory]="'o'"
                                                [oceanScore]="item?.stepPerTestResult?.intellect?.toFixed(0)"
                                                [oceanParent]="false"></app-ocean-progressbar>
                                        </div>

                                    </div>
                                </div>
                                <div class="grid flex flex-nowrap p-0" style="border: none;">
                                    <div
                                        class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center o-text-color">
                                        {{"Key.Liberalism" | translate}}
                                    </div>
                                    <div class="col-9 pb-0  pt-2 align-content-center custom-display">
                                        <div class="w-18rem ml-7">
                                            <app-ocean-progressbar
                                                [progressBarOceanScore]="item?.stepPerTestResult?.liberalism"
                                                [oceanCategory]="'o'"
                                                [oceanScore]="item?.stepPerTestResult?.liberalism?.toFixed(0)"
                                                [oceanParent]="false"></app-ocean-progressbar>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div #area style="width: 32rem; " class="mb-3 avoid">
                            <div style="" class="ocean-grid">
                                <div class="grid flex pb-3" style="border: none;">
                                    <div class="col-1 ocean-icon-width align-content-center custom-display">
                                        <img class="mr-1" src="../../../../../assets/vinter/icons/pdfReport/c-icon.svg"
                                            width="22px">
                                    </div>
                                    <div
                                        class="col-4 pl-1 pr-0 font-medium text-sm  flex flex-wrap align-content-center c-text-color w-auto">
                                        {{"Key.Conscientiousness" | translate}}
                                    </div>
                                </div>

                                <app-ocean-progressbar [progressBarOceanScore]="item?.stepPerTestResult?.c"
                                    [oceanCategory]="'c'" [oceanScore]="item?.stepPerTestResult?.c?.toFixed(0)"
                                    [oceanParent]="true"></app-ocean-progressbar>

                                <div *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.OCEAN120"
                                    class="flex justify-content-between">

                                    <div
                                        class="ocean-report-grid grid mt-3 ml-1 flex align-items-center justify-content-center">
                                        <p class="pdf-text-size h-full mt-2">
                                            {{"Key.ConscientiousnessLowScoreDescription" | translate}}
                                        </p>

                                    </div>
                                    <div
                                        class="ocean-report-grid grid mt-3 flex align-items-center justify-content-center">
                                        <p class="pdf-text-size text-right h-full mt-2">
                                            {{"Key.ConscientiousnessHighScoreDescription" | translate}}
                                        </p>

                                    </div>
                                </div>
                            </div>
                            <div style="" class="grid mx-0 pb-4 mt-0">
                                <p class="text-justify pdf-text-size">
                                    {{item?.stepPerTestResult?.reportTextC}}
                                </p>
                            </div>

                            <div *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.OCEAN120"
                                style="">
                                <div class="grid flex flex-nowrap p-0" style="border: none;">
                                    <div
                                        class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center c-text-color">
                                        {{"Key.SelfEfficacy" | translate}}
                                    </div>
                                    <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                        <div class="w-18rem ml-7">
                                            <app-ocean-progressbar
                                                [progressBarOceanScore]="item?.stepPerTestResult?.selfEfficacy"
                                                [oceanCategory]="'c'"
                                                [oceanScore]="item?.stepPerTestResult?.selfEfficacy?.toFixed(0)"
                                                [oceanParent]="false"></app-ocean-progressbar>

                                        </div>

                                    </div>
                                </div>
                                <div class="grid flex flex-nowrap p-0" style="border: none;">
                                    <div
                                        class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center c-text-color">
                                        {{"Key.Orderliness" | translate}}
                                    </div>
                                    <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                        <div class="w-18rem ml-7">
                                            <app-ocean-progressbar
                                                [progressBarOceanScore]="item?.stepPerTestResult?.orderliness"
                                                [oceanCategory]="'c'"
                                                [oceanScore]="item?.stepPerTestResult?.orderliness?.toFixed(0)"
                                                [oceanParent]="false"></app-ocean-progressbar>
                                        </div>

                                    </div>
                                </div>
                                <div class="grid flex flex-nowrap p-0" style="border: none;">
                                    <div
                                        class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center c-text-color">
                                        {{"Key.Dutifulness" | translate}}
                                    </div>
                                    <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                        <div class="w-18rem ml-7">
                                            <app-ocean-progressbar
                                                [progressBarOceanScore]="item?.stepPerTestResult?.dutifulness"
                                                [oceanCategory]="'c'"
                                                [oceanScore]="item?.stepPerTestResult?.dutifulness?.toFixed(0)"
                                                [oceanParent]="false"></app-ocean-progressbar>
                                        </div>

                                    </div>
                                </div>
                                <div class="grid flex flex-nowrap p-0" style="border: none;">
                                    <div
                                        class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center c-text-color">
                                        {{"Key.AchievementStriving" | translate}}
                                    </div>
                                    <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                        <div class="w-18rem ml-7">
                                            <app-ocean-progressbar
                                                [progressBarOceanScore]="item?.stepPerTestResult?.achievementStriving"
                                                [oceanCategory]="'c'"
                                                [oceanScore]="item?.stepPerTestResult?.achievementStriving?.toFixed(0)"
                                                [oceanParent]="false"></app-ocean-progressbar>
                                        </div>

                                    </div>
                                </div>
                                <div class="grid flex flex-nowrap p-0" style="border: none;">
                                    <div
                                        class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center c-text-color">
                                        {{"Key.SelfDiscipline" | translate}}
                                    </div>
                                    <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                        <div class="w-18rem ml-7">
                                            <app-ocean-progressbar
                                                [progressBarOceanScore]="item?.stepPerTestResult?.selfDiscipline"
                                                [oceanCategory]="'c'"
                                                [oceanScore]="item?.stepPerTestResult?.selfDiscipline?.toFixed(0)"
                                                [oceanParent]="false"></app-ocean-progressbar>
                                        </div>

                                    </div>
                                </div>
                                <div class="grid flex flex-nowrap p-0" style="border: none;">
                                    <div
                                        class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center c-text-color">
                                        {{"Key.Cautiousness" | translate}}
                                    </div>
                                    <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                        <div class="w-18rem ml-7">
                                            <app-ocean-progressbar
                                                [progressBarOceanScore]="item?.stepPerTestResult?.cautiousness"
                                                [oceanCategory]="'c'"
                                                [oceanScore]="item?.stepPerTestResult?.cautiousness?.toFixed(0)"
                                                [oceanParent]="false"></app-ocean-progressbar>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div #area style="width: 32rem; " class="mb-3 avoid">
                            <div class="ocean-grid">
                                <div class="grid flex pb-3" style="border: none;">
                                    <div class="col-1 ocean-icon-width align-content-center custom-display">
                                        <img class="mr-1" src="../../../../../assets/vinter/icons/pdfReport/e-icon.svg"
                                            width="22px">
                                    </div>
                                    <div
                                        class="col-4 pl-1 pr-0 font-medium text-sm  flex flex-wrap align-content-center blue-600 w-auto">
                                        {{"Key.Extraversion" | translate}}
                                    </div>
                                </div>

                                <app-ocean-progressbar [progressBarOceanScore]="item?.stepPerTestResult?.e"
                                    [oceanCategory]="'e'" [oceanScore]="item?.stepPerTestResult?.e?.toFixed(0)"
                                    [oceanParent]="true"></app-ocean-progressbar>

                                <div *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.OCEAN120"
                                    class="flex justify-content-between">
                                    <div
                                        class="ocean-report-grid grid mt-3 ml-1 flex align-items-center justify-content-center">
                                        <p class="pdf-text-size h-full mt-2">
                                            {{"Key.ExtraversionLowScoreDescription" | translate}}
                                        </p>

                                    </div>
                                    <div
                                        class="ocean-report-grid grid mt-3 flex align-items-center justify-content-center">
                                        <p class="pdf-text-size text-right h-full mt-2">
                                            {{"Key.ExtraversionHighScoreDescription" | translate}}
                                        </p>

                                    </div>
                                </div>
                            </div>
                            <div class="grid mx-0 pb-4 mt-0">
                                <p class="text-justify pdf-text-size">
                                    {{item?.stepPerTestResult?.reportTextE}}
                                </p>
                            </div>

                            <div
                                *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.OCEAN120">
                                <div class="grid flex flex-nowrap p-0" style="border: none;">
                                    <div
                                        class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center blue-600">
                                        {{"Key.Friendliness" | translate}}
                                    </div>
                                    <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                        <div class="w-18rem ml-7">
                                            <app-ocean-progressbar
                                                [progressBarOceanScore]="item?.stepPerTestResult?.friendliness"
                                                [oceanCategory]="'e'"
                                                [oceanScore]="item?.stepPerTestResult?.friendliness?.toFixed(0)"
                                                [oceanParent]="false"></app-ocean-progressbar>

                                        </div>

                                    </div>
                                </div>
                                <div class="grid flex flex-nowrap p-0" style="border: none;">
                                    <div
                                        class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center blue-600">
                                        {{"Key.Assertiveness" | translate}}
                                    </div>
                                    <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                        <div class="w-18rem ml-7">
                                            <app-ocean-progressbar
                                                [progressBarOceanScore]="item?.stepPerTestResult?.assertiveness"
                                                [oceanCategory]="'e'"
                                                [oceanScore]="item?.stepPerTestResult?.assertiveness?.toFixed(0)"
                                                [oceanParent]="false"></app-ocean-progressbar>
                                        </div>

                                    </div>
                                </div>
                                <div class="grid flex flex-nowrap p-0" style="border: none;">
                                    <div
                                        class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center blue-600">
                                        {{"Key.Gregariousness" | translate}}
                                    </div>
                                    <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                        <div class="w-18rem ml-7">
                                            <app-ocean-progressbar
                                                [progressBarOceanScore]="item?.stepPerTestResult?.gregariousness"
                                                [oceanCategory]="'e'"
                                                [oceanScore]="item?.stepPerTestResult?.gregariousness?.toFixed(0)"
                                                [oceanParent]="false"></app-ocean-progressbar>
                                        </div>

                                    </div>
                                </div>
                                <div class="grid flex flex-nowrap p-0" style="border: none;">
                                    <div
                                        class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center blue-600">
                                        {{"Key.ActivityLevel" | translate}}
                                    </div>
                                    <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                        <div class="w-18rem ml-7">
                                            <app-ocean-progressbar
                                                [progressBarOceanScore]="item?.stepPerTestResult?.activityLevel"
                                                [oceanCategory]="'e'"
                                                [oceanScore]="item?.stepPerTestResult?.activityLevel?.toFixed(0)"
                                                [oceanParent]="false"></app-ocean-progressbar>
                                        </div>

                                    </div>
                                </div>
                                <div class="grid flex flex-nowrap p-0" style="border: none;">
                                    <div
                                        class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center blue-600">
                                        {{"Key.ExcitementSeeking" | translate}}
                                    </div>
                                    <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                        <div class="w-18rem ml-7">
                                            <app-ocean-progressbar
                                                [progressBarOceanScore]="item?.stepPerTestResult?.excitementSeeking"
                                                [oceanCategory]="'e'"
                                                [oceanScore]="item?.stepPerTestResult?.excitementSeeking?.toFixed(0)"
                                                [oceanParent]="false"></app-ocean-progressbar>
                                        </div>

                                    </div>
                                </div>
                                <div class="grid flex flex-nowrap p-0" style="border: none;">
                                    <div
                                        class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center blue-600">
                                        {{"Key.Cheerfulness" | translate}}
                                    </div>
                                    <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                        <div class="w-18rem ml-7">
                                            <app-ocean-progressbar
                                                [progressBarOceanScore]="item?.stepPerTestResult?.cheerfulness"
                                                [oceanCategory]="'e'"
                                                [oceanScore]="item?.stepPerTestResult?.cheerfulness?.toFixed(0)"
                                                [oceanParent]="false"></app-ocean-progressbar>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div #area  style=" width: 32rem; " class="mb-3 avoid">
                            <div class="ocean-grid">
                                <div class="grid flex pb-3" style="border: none;">
                                    <div class="col-1 ocean-icon-width align-content-center custom-display">
                                        <img class="mr-1" src="../../../../../assets/vinter/icons/pdfReport/a-icon.svg"
                                            width="22px">
                                    </div>
                                    <div
                                        class="col-4 pl-1 pr-0 font-medium text-sm  flex flex-wrap align-content-center a-text-color w-auto">
                                        {{"Key.Agreeableness" | translate}}
                                    </div>
                                </div>

                                <app-ocean-progressbar [progressBarOceanScore]="item?.stepPerTestResult?.a"
                                    [oceanCategory]="'a'" [oceanScore]="item?.stepPerTestResult?.a?.toFixed(0)"
                                    [oceanParent]="true"></app-ocean-progressbar>

                                <div *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.OCEAN120"
                                    class="flex justify-content-between">
                                    <div
                                        class="ocean-report-grid grid mt-3 ml-1 flex align-items-center justify-content-center">
                                        <p class="pdf-text-size h-full mt-2">
                                            {{"Key.AgreeablenessLowScoreDescription" | translate}}
                                        </p>

                                    </div>
                                    <div
                                        class="ocean-report-grid grid mt-3 flex align-items-center justify-content-center">
                                        <p class="pdf-text-size text-right h-full mt-2">
                                            {{"Key.AgreeablenessHighScoreDescription" | translate}}
                                        </p>

                                    </div>
                                </div>
                            </div>
                            <div class="grid mx-0 pb-4 mt-0">
                                <p class="text-justify pdf-text-size">
                                    {{item?.stepPerTestResult?.reportTextA}}
                                </p>
                            </div>

                            <div
                                *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.OCEAN120">
                                <div class="grid flex flex-nowrap p-0" style="border: none;">
                                    <div
                                        class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center a-text-color">
                                        {{"Key.Trust" | translate}}
                                    </div>
                                    <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                        <div class="w-18rem ml-7">
                                            <app-ocean-progressbar
                                                [progressBarOceanScore]="item?.stepPerTestResult?.trust"
                                                [oceanCategory]="'a'"
                                                [oceanScore]="item?.stepPerTestResult?.trust?.toFixed(0)"
                                                [oceanParent]="false"></app-ocean-progressbar>

                                        </div>

                                    </div>
                                </div>
                                <div class="grid flex flex-nowrap p-0" style="border: none;">
                                    <div
                                        class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center a-text-color">
                                        {{"Key.Morality" | translate}}
                                    </div>
                                    <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                        <div class="w-18rem ml-7">
                                            <app-ocean-progressbar
                                                [progressBarOceanScore]="item?.stepPerTestResult?.morality"
                                                [oceanCategory]="'a'"
                                                [oceanScore]="item?.stepPerTestResult?.morality?.toFixed(0)"
                                                [oceanParent]="false"></app-ocean-progressbar>
                                        </div>

                                    </div>
                                </div>
                                <div class="grid flex flex-nowrap p-0" style="border: none;">
                                    <div
                                        class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center a-text-color">
                                        {{"Key.Altruism" | translate}}
                                    </div>
                                    <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                        <div class="w-18rem ml-7">
                                            <app-ocean-progressbar
                                                [progressBarOceanScore]="item?.stepPerTestResult?.altruism"
                                                [oceanCategory]="'a'"
                                                [oceanScore]="item?.stepPerTestResult?.altruism?.toFixed(0)"
                                                [oceanParent]="false"></app-ocean-progressbar>
                                        </div>

                                    </div>
                                </div>
                                <div class="grid flex flex-nowrap p-0" style="border: none;">
                                    <div
                                        class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center a-text-color">
                                        {{"Key.Cooperation" | translate}}
                                    </div>
                                    <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                        <div class="w-18rem ml-7">
                                            <app-ocean-progressbar
                                                [progressBarOceanScore]="item?.stepPerTestResult?.cooperation"
                                                [oceanCategory]="'a'"
                                                [oceanScore]="item?.stepPerTestResult?.cooperation?.toFixed(0)"
                                                [oceanParent]="false"></app-ocean-progressbar>
                                        </div>

                                    </div>
                                </div>
                                <div class="grid flex flex-nowrap p-0" style="border: none;">
                                    <div
                                        class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center a-text-color">
                                        {{"Key.Modesty" | translate}}
                                    </div>
                                    <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                        <div class="w-18rem ml-7">
                                            <app-ocean-progressbar
                                                [progressBarOceanScore]="item?.stepPerTestResult?.modesty"
                                                [oceanCategory]="'a'"
                                                [oceanScore]="item?.stepPerTestResult?.modesty?.toFixed(0)"
                                                [oceanParent]="false"></app-ocean-progressbar>
                                        </div>

                                    </div>
                                </div>
                                <div class="grid flex flex-nowrap p-0" style="border: none;">
                                    <div
                                        class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center a-text-color">
                                        {{"Key.Sympathy" | translate}}
                                    </div>
                                    <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                        <div class="w-18rem ml-7">
                                            <app-ocean-progressbar
                                                [progressBarOceanScore]="item?.stepPerTestResult?.sympathy"
                                                [oceanCategory]="'a'"
                                                [oceanScore]="item?.stepPerTestResult?.sympathy?.toFixed(0)"
                                                [oceanParent]="false"></app-ocean-progressbar>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div #area style="width: 32rem; " class="mb-3 avoid">
                            <div class="ocean-grid">
                                <div class="grid flex pb-3" style="border: none;">
                                    <div class="col-1 ocean-icon-width align-content-center custom-display">
                                        <img class="mr-1" src="../../../../../assets/vinter/icons/pdfReport/n-icon.svg"
                                            width="22px">
                                    </div>
                                    <div
                                        class="col-4 pl-1 pr-0 font-medium text-sm  flex flex-wrap align-content-center n-text-color w-auto">
                                        {{"Key.Neuroticism" | translate}}
                                    </div>
                                </div>

                                <app-ocean-progressbar [progressBarOceanScore]="item?.stepPerTestResult?.n"
                                    [oceanCategory]="'n'" [oceanScore]="item?.stepPerTestResult?.n?.toFixed(0)"
                                    [oceanParent]="true"></app-ocean-progressbar>

                                <div *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.OCEAN120"
                                    class="flex justify-content-between">
                                    <div
                                        class="ocean-report-grid grid mt-3 ml-1 flex align-items-center justify-content-center">
                                        <p class="pdf-text-size h-full mt-2">
                                            {{"Key.NeuroticismLowScoreDescription" | translate}}
                                        </p>

                                    </div>
                                    <div
                                        class="ocean-report-grid grid mt-3 flex align-items-center justify-content-center">
                                        <p class="pdf-text-size text-right h-full mt-2">
                                            {{"Key.NeuroticismHighScoreDescription" | translate}}
                                        </p>

                                    </div>
                                </div>
                            </div>
                            <div class="grid mx-0 pb-4 mt-0">
                                <p class="text-justify pdf-text-size">
                                    {{item?.stepPerTestResult?.reportTextN}}
                                </p>
                            </div>

                            <div
                                *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.OCEAN120">
                                <div class="grid flex flex-nowrap p-0" style="border: none;">
                                    <div
                                        class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center n-text-color">
                                        {{"Key.Anxiety" | translate}}
                                    </div>
                                    <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                        <div class="w-18rem ml-7">
                                            <app-ocean-progressbar
                                                [progressBarOceanScore]="item?.stepPerTestResult?.anxiety"
                                                [oceanCategory]="'n'"
                                                [oceanScore]="item?.stepPerTestResult?.anxiety?.toFixed(0)"
                                                [oceanParent]="false"></app-ocean-progressbar>

                                        </div>

                                    </div>
                                </div>
                                <div class="grid flex flex-nowrap p-0" style="border: none;">
                                    <div
                                        class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center n-text-color">
                                        {{"Key.Anger" | translate}}
                                    </div>
                                    <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                        <div class="w-18rem ml-7">
                                            <app-ocean-progressbar
                                                [progressBarOceanScore]="item?.stepPerTestResult?.anger"
                                                [oceanCategory]="'n'"
                                                [oceanScore]="item?.stepPerTestResult?.anger?.toFixed(0)"
                                                [oceanParent]="false"></app-ocean-progressbar>
                                        </div>

                                    </div>
                                </div>
                                <div class="grid flex flex-nowrap p-0" style="border: none;">
                                    <div
                                        class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center n-text-color">
                                        {{"Key.Depression" | translate}}
                                    </div>
                                    <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                        <div class="w-18rem ml-7">
                                            <app-ocean-progressbar
                                                [progressBarOceanScore]="item?.stepPerTestResult?.depression"
                                                [oceanCategory]="'n'"
                                                [oceanScore]="item?.stepPerTestResult?.depression?.toFixed(0)"
                                                [oceanParent]="false"></app-ocean-progressbar>
                                        </div>

                                    </div>
                                </div>
                                <div class="grid flex flex-nowrap p-0" style="border: none;">
                                    <div
                                        class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center n-text-color">
                                        {{"Key.SelfConsciousness" | translate}}
                                    </div>
                                    <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                        <div class="w-18rem ml-7">
                                            <app-ocean-progressbar
                                                [progressBarOceanScore]="item?.stepPerTestResult?.selfConsciousness"
                                                [oceanCategory]="'n'"
                                                [oceanScore]="item?.stepPerTestResult?.selfConsciousness?.toFixed(0)"
                                                [oceanParent]="false"></app-ocean-progressbar>
                                        </div>

                                    </div>
                                </div>
                                <div class="grid flex flex-nowrap p-0" style="border: none;">
                                    <div
                                        class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center n-text-color">
                                        {{"Key.Immoderation" | translate}}
                                    </div>
                                    <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                        <div class="w-18rem ml-7">
                                            <app-ocean-progressbar
                                                [progressBarOceanScore]="item?.stepPerTestResult?.immoderation"
                                                [oceanCategory]="'n'"
                                                [oceanScore]="item?.stepPerTestResult?.immoderation?.toFixed(0)"
                                                [oceanParent]="false"></app-ocean-progressbar>
                                        </div>

                                    </div>
                                </div>
                                <div class="grid flex flex-nowrap p-0" style="border: none;">
                                    <div
                                        class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center n-text-color">
                                        {{"Key.Vulnerability" | translate}}
                                    </div>
                                    <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                        <div class="w-18rem ml-7">
                                            <app-ocean-progressbar
                                                [progressBarOceanScore]="item?.stepPerTestResult?.vulnerability"
                                                [oceanCategory]="'n'"
                                                [oceanScore]="item?.stepPerTestResult?.vulnerability?.toFixed(0)"
                                                [oceanParent]="false"></app-ocean-progressbar>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="custom-hr ml-0 my-7" style="width: 32rem;">
                        </div>
                    </div>
                </div>

            </div>

            <div
                *ngIf="(item.completedDate ?? false) && (item.positionStep.questionCollection.questionCollectionCategory !== QuestionCollectionCategoryEnum.PersonalityTest) &&
                        (item.positionStep.questionCollection.questionCollectionCategory !== QuestionCollectionCategoryEnum.ForeignLanguage) &&
                        (item.positionStep.questionCollection.questionCollectionCategory !== QuestionCollectionCategoryEnum.Pronunciation)">
                <div class="mx-7">
                    <div class="my-4">
                        <div *ngIf="item.positionStep.questionCollection.expectedAnswerOpenEndedCount !== 0"
                            class="my-5">
                            <div class="avoid" style=" width: 32rem; ">
                                <div class="flex">
                                    <img class="mr-1"
                                        src="../../../../../assets/vinter/icons/pdfReport/openEndedPdf.svg"
                                        width="24px">
                                    <div class="mr-2 align-item-center align-self-center detailSubFont font-semibold"
                                        style="color:#2885F1; font-size: 12px;">
                                        {{item.positionStep.questionCollection.expectedAnswerOpenEndedCount}}
                                    </div>

                                    <div class="flex align-items-center justify-content-center font-semibold text-sm">
                                        {{'Key.OpenEndedQuestions' | translate}}
                                    </div>

                                </div>
                                <div class="my-3 text-justify pdf-text-size" style="width: 32rem;">
                                    <p>
                                        {{'Key.PdfOpenEndedInfo' | translate}}
                                    </p>
                                </div>
                                <div class="mr-3">
                                    <div class="flex justify-content-between flex-wrap my-2">
                                        <div
                                            class="flex align-items-center justify-content-center font-semibold text-xs ">
                                            {{'Key.PdfVinterScore' | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <div class="custom-display w-12rem flex">
                                                <div class="progress-bar-container mt-2 mr-2">
                                                    <div class="progress-bar"
                                                        [style.width]="item.positionStep.questionCollection.openEndedQuestionsAverageGptScore+ '%'"
                                                        [style.backgroundColor]="'#3F26DC'"></div>
                                                </div>
                                                <p class="text-xs ">
                                                    {{item?.positionStep?.questionCollection?.openEndedQuestionsAverageGptScore?.toFixed(2)}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex justify-content-between flex-wrap my-2">
                                        <div
                                            class="flex align-items-center justify-content-center font-semibold text-xs ">
                                            {{'Key.PdfRate' | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <p class="text-xs ">
                                                {{item.positionStep?.questionCollection?.openEndedQuestionsAverageRatingScore?.toFixed(2)}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div *ngIf="item.positionStep.questionCollection.expectedAnswerCodingCount !== 0" class="my-5">
                            <div class="avoid" style=" width: 32rem; ">
                                <div class="flex">
                                    <img class="mr-1"
                                        src="../../../../../assets/vinter/icons/pdfReport/codingQuestionIcon.svg"
                                        width="24px">
                                    <div class="mr-2 align-item-center text-blue-800 align-self-center detailSubFont font-semibold"
                                        style="font-size: 12px;">
                                        {{item.positionStep.questionCollection.expectedAnswerCodingCount}}
                                    </div>

                                    <div class="flex align-items-center justify-content-center font-semibold text-sm">
                                        {{'Key.PdfCodingQuestions' | translate}}
                                    </div>

                                </div>
                                <div class="my-3 text-justify pdf-text-size" style="width: 32rem;">
                                    <p>
                                        {{'Key.PdfCodingInfo' | translate}}
                                    </p>
                                </div>
                                <div class="mr-3">
                                    <div class="flex justify-content-between flex-wrap my-2">
                                        <div
                                            class="flex align-items-center justify-content-center font-semibold text-xs ">
                                            {{'Key.PdfVinterScore' | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <div class="custom-display w-12rem flex">
                                                <div class="progress-bar-container mt-2 mr-2">
                                                    <div class="progress-bar"
                                                        [style.width]="item.positionStep.questionCollection.codingQuestionsAverageGptScore+ '%'"
                                                        [style.backgroundColor]="'#3F26DC'"></div>
                                                </div>
                                                <p class="text-xs ">
                                                    {{item?.positionStep?.questionCollection?.codingQuestionsAverageGptScore?.toFixed(2)}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex justify-content-between flex-wrap my-2">
                                        <div
                                            class="flex align-items-center justify-content-center font-semibold text-xs ">
                                            {{'Key.PdfRate' | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <p class="text-xs ">
                                                {{item.positionStep?.questionCollection?.codingQuestionsAverageRatingScore?.toFixed(2)}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>






                        <div *ngIf="item.positionStep.questionCollection.expectedAnswerMultipleChoiceCount !== 0"
                            class="my-5">
                            <div class="avoid" style=" width: 32rem;  ">
                                <div class="flex">
                                    <img class="mr-1"
                                        src="../../../../../assets/vinter/icons/pdfReport/multiChoicePdf.svg"
                                        width="24px">
                                    <div class="mr-2 align-item-center align-self-center detailSubFont font-semibold"
                                        style="color:#722DDE; font-size: 12px;">
                                        {{item.positionStep.questionCollection.expectedAnswerMultipleChoiceCount}}
                                    </div>

                                    <div class="flex align-items-center justify-content-center font-semibold text-sm">
                                        {{'Key.MultiChoiceQuestions' | translate}}
                                    </div>
                                </div>
                                <div class="my-3 text-justify pdf-text-size" style="width: 32rem;">
                                    <p>
                                        {{'Key.PdfMultiChoiceInfo' | translate}}
                                    </p>

                                </div>

                                <div>
                                    <app-custom-progressbar [answeredQuestionsWithId]="answeredQuestionsWithId"
                                        [itemId]="item.positionStep.questionCollection.questionCollectionCategory">
                                    </app-custom-progressbar>
                                </div>
                            </div>

                        </div>

                        <div *ngIf="item.positionStep.questionCollection.expectedAnswerVideoCount !== 0" class="my-5">
                            <div class="avoid" style="width: 32rem;  ">
                                <div class="flex">
                                    <img class="mr-1" src="../../../../../assets/vinter/icons/pdfReport/videoPdf.svg"
                                        width="24px">
                                    <div class="mr-2 align-item-center align-self-center detailSubFont font-semibold"
                                        style="color:#6255F3; font-size: 12px;">
                                        {{item.positionStep.questionCollection.expectedAnswerVideoCount}}
                                    </div>

                                    <div class="flex align-items-center justify-content-center font-semibold text-sm">
                                        {{'Key.VideoQuestions' | translate}}
                                    </div>

                                </div>

                                <div class="my-3 text-justify pdf-text-size" style="width: 32rem;">
                                    <p>
                                        {{'Key.PdfVideoInfo' | translate}}
                                    </p>

                                </div>

                                <div class="mr-3">
                                    <div class="flex justify-content-between flex-wrap my-2">
                                        <div
                                            class="flex align-items-center justify-content-center font-semibold text-xs ">
                                            {{'Key.PdfVinterScore' | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <div class="custom-display w-12rem flex">
                                                <div class="progress-bar-container mt-2 mr-2">
                                                    <div class="progress-bar"
                                                        [style.width]="item.positionStep.questionCollection.videoQuestionsAverageGptScore + '%'"
                                                        [style.backgroundColor]="'#3F26DC'"></div>
                                                </div>
                                                <p class="text-xs ">
                                                    {{item?.positionStep?.questionCollection?.videoQuestionsAverageGptScore?.toFixed(2)}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex justify-content-between flex-wrap my-2">
                                        <div
                                            class="flex align-items-center justify-content-center font-semibold text-xs ">
                                            {{'Key.PdfRate' | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <p class="text-xs ">
                                                {{item.positionStep?.questionCollection?.videoQuestionsAverageRatingScore?.toFixed(2)}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <hr class="custom-hr my-7" style="width: 32rem;">
            </div>

            <div
                *ngIf="(item.completedDate ?? false) && (item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.ForeignLanguage)">
                <div class="mx-7">
                    <div class="my-4">
                        <div *ngIf="item.positionStep.questionCollection.expectedAnswerOpenEndedCount !== 0"
                            class="my-7">
                            <div
                                *ngIf="getCurrentStepQuestions(EQuestionCategories.Writing, item.positionStep.id)?.questionCount > 0">
                                <div #area class="avoid" style="width: 32rem;  ">
                                    <div class="flex">
                                        <img class="mr-1"
                                            src="../../../../../assets/vinter/icons/pdfReport/general-english-writing-pdf-badge.svg"
                                            width="24px">
                                        <div class="mr-2 align-item-center align-self-center detailSubFont font-semibold"
                                            style="color:#2D4ADE; font-size: 12px;">
                                            {{getCurrentStepQuestions(EQuestionCategories.Writing,
                                            item.positionStep.id)?.questionCount}}
                                        </div>

                                        <div
                                            class="flex align-items-center justify-content-center font-semibold text-sm">
                                            {{'Key.Writing' | translate}}
                                        </div>
                                    </div>

                                    <div class="my-3 text-justify pdf-text-size" style="width: 32rem;">
                                        <p>
                                            {{'Key.WritingPdfDesc' | translate}}
                                        </p>
                                    </div>

                                    <div class="my-5">
                                        <div class="flex justify-content-between flex-wrap my-2">
                                            <div class="flex align-items-center justify-content-center pdf-text-size"
                                                style="color: #686E75;">
                                                {{'Key.WritingScore' | translate}}
                                            </div>
                                            <div class="flex align-items-center justify-content-center font-semibold">
                                                <div class="custom-display flex"
                                                    style="margin-right: 1rem; width:14.3rem;">
                                                    <div class="progress-bar-container mt-2 mr-2">
                                                        <div class="progress-bar"
                                                            [style.width]="(getCurrentStepQuestions(EQuestionCategories.Writing,item.positionStep.id)?.averageScores )+ '%'"
                                                            [style.backgroundColor]="'#2D4ADE'"></div>
                                                    </div>
                                                    <div class="flex">
                                                        <p class="text-xs font-normal mr-1" style="color: #2D4ADE;">
                                                            {{item.generalEnglishTestResult?.writingLevel}}/ </p>
                                                        <p class="text-xs font-normal w-max" style="color: #686E75;">
                                                            %{{(getCurrentStepQuestions(EQuestionCategories.Writing,
                                                            item.positionStep.id)?.averageScores)?.toFixed(0)}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="item.positionStep.questionCollection.expectedAnswerMultipleChoiceCount !== 0"
                            class="my-7">

                            <div *ngIf="getCurrentStepQuestions(EQuestionCategories.Listening, item.positionStep.id)?.questionCount > 0"
                                #area class="avoid">
                                <div #area class="avoid" style=" width: 32rem;">
                                    <div class="flex">
                                        <img class="mr-1"
                                            src="../../../../../assets/vinter/icons/pdfReport/general-english-listening-pdf-badge.svg"
                                            width="24px">
                                        <div class="mr-2 align-item-center align-self-center detailSubFont font-semibold"
                                            style="color:#9B2DDE; font-size: 12px;">
                                            {{getCurrentStepQuestions(EQuestionCategories.Listening,
                                            item.positionStep.id)?.questionCount}}
                                        </div>

                                        <div
                                            class="flex align-items-center justify-content-center font-semibold text-sm">
                                            {{'Key.Listening' | translate}}
                                        </div>
                                    </div>

                                    <div class="my-3 text-justify pdf-text-size" style="width: 32rem;">
                                        <p>
                                            {{'Key.ListeningPdfDesc' | translate}}
                                        </p>
                                    </div>

                                    <div class="my-5">
                                        <div *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.GeneralEnglish"
                                            class="flex justify-content-between flex-wrap">
                                            <div class="flex mt-1 justify-content-center pdf-text-size"
                                                style="color: #686E75;">
                                                {{'Key.ListeningScore' | translate}}
                                            </div>
                                            <div class="flex align-items-center justify-content-center font-semibold">
                                                <div class="custom-display flex"
                                                    style="margin-right: 1rem; width:14.3rem;">
                                                    <div class="progress-bar-container mt-2 mr-2">
                                                        <div class="progress-bar"
                                                            [style.width]="(getCurrentStepQuestions(EQuestionCategories.Listening, item.positionStep.id)?.averageScores*100 )+ '%'"
                                                            [style.backgroundColor]="'#CE4FBA'"></div>
                                                    </div>
                                                    <div class="flex">
                                                        <p class="text-xs font-normal mr-1" style="color: #CE4FBA;">
                                                            {{item.generalEnglishTestResult?.listeningLevel}}/ </p>
                                                        <p class="text-xs font-normal w-max" style="color: #686E75;">
                                                            %{{(getCurrentStepQuestions(EQuestionCategories.Listening,
                                                            item.positionStep.id)?.averageScores*100)?.toFixed(0)}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <app-custom-progressbar
                                            [answeredQuestionsWithId]="[getCurrentStepQuestions(EQuestionCategories.Listening, item.positionStep.id)]"
                                            [itemId]="EQuestionCategories.Listening"></app-custom-progressbar>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="getCurrentStepQuestions(EQuestionCategories.Reading, item.positionStep.id)?.questionCount > 0"
                                class="avoid" style="width: 32rem;  ">
                                <div class="flex">
                                    <img class="mr-1" src="../../../../../assets/vinter/icons/pdfReport/general-english-reading-pdf-badge.svg"
                                        width="24px">
                                    <div class="mr-2 align-item-center align-self-center detailSubFont font-semibold"
                                        style="color:#6931DF; font-size: 12px;">
                                        {{getCurrentStepQuestions(EQuestionCategories.Reading,
                                        item.positionStep.id)?.questionCount}}
                                    </div>

                                    <div class="flex align-items-center justify-content-center font-semibold text-sm">
                                        {{'Key.Reading' | translate}}
                                    </div>
                                </div>

                                <div class="my-3 text-justify pdf-text-size" style="width: 33rem;">
                                    <p>
                                        {{'Key.ReadingPdfDesc' | translate}}
                                    </p>
                                </div>

                                <div class="my-5">
                                    <div *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.GeneralEnglish"
                                        class="flex justify-content-between flex-wrap">
                                        <div class="flex mt-1 justify-content-center pdf-text-size"
                                            style="color: #686E75;">
                                            {{'Key.ReadingScore' | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <div class="custom-display flex" style="margin-right: 1rem; width:14.3rem;">
                                                <div class="progress-bar-container mt-2 mr-2">
                                                    <div class="progress-bar"
                                                        [style.width]="(getCurrentStepQuestions(EQuestionCategories.Reading, item.positionStep.id)?.averageScores*100) + '%'"
                                                        [style.backgroundColor]="'#2DC9DE'"></div>
                                                </div>
                                                <div class="flex">
                                                    <p class="text-xs font-normal mr-1" style="color: #2DC9DE;">
                                                        {{item.generalEnglishTestResult?.readingLevel}}/ </p>
                                                    <p class="text-xs font-normal w-max" style="color: #686E75;">
                                                        %{{(getCurrentStepQuestions(EQuestionCategories.Reading,
                                                        item.positionStep.id)?.averageScores*100)?.toFixed(0)}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <app-custom-progressbar
                                        [answeredQuestionsWithId]="[getCurrentStepQuestions(EQuestionCategories.Reading, item.positionStep.id)]"
                                        [itemId]="EQuestionCategories.Reading"></app-custom-progressbar>
                                </div>

                            </div>

                            <div
                                *ngIf="(item.positionStep.questionCollection.subCategory !== QuestionCollectionSubCategory.GeneralEnglish) && getCurrentStepQuestions(EQuestionCategories.Grammer, item.positionStep.id)?.questionCount > 0">
                                <div class="avoid" style="width: 32rem;  ">
                                    <div class="flex">
                                        <img class="mr-1"
                                            src="../../../../../assets/vinter/icons/pdfReport/general-english-grammer-pdf-badge.svg"
                                            width="24px">
                                        <div class="mr-2 align-item-center align-self-center detailSubFont font-semibold"
                                            style="color:#2E75FF; font-size: 12px;">
                                            {{getCurrentStepQuestions(EQuestionCategories.Grammer,
                                            item.positionStep.id)?.questionCount}}
                                        </div>

                                        <div
                                            class="flex align-items-center justify-content-center font-semibold text-sm">
                                            {{'Key.Grammer' | translate}}
                                        </div>
                                    </div>

                                    <div class="my-3 text-justify pdf-text-size" style="width: 32rem;">
                                        <p>
                                            {{'Key.GrammerPdfDesc' | translate}}
                                        </p>
                                    </div>

                                    <div class="my-5">
                                        <app-custom-progressbar
                                            [answeredQuestionsWithId]="[getCurrentStepQuestions(EQuestionCategories.Grammer, item.positionStep.id)]"
                                            [itemId]="EQuestionCategories.Grammer"></app-custom-progressbar>
                                    </div>
                                </div>
                            </div>

                            <div
                                *ngIf="(item.positionStep.questionCollection.subCategory !== QuestionCollectionSubCategory.GeneralEnglish) && getCurrentStepQuestions(EQuestionCategories.Vocabulary, item.positionStep.id)?.questionCount > 0">
                                <div class="avoid" style=" width: 32rem;">
                                    <div class="flex">
                                        <img class="mr-1"
                                            src="../../../../../assets/vinter/icons/pdfReport/general-english-vocabulary-pdf-badge.svg"
                                            width="24px">
                                        <div class="mr-2 align-item-center align-self-center detailSubFont font-semibold"
                                            style="color:#7752FE; font-size: 12px;">
                                            {{getCurrentStepQuestions(EQuestionCategories.Vocabulary,
                                            item.positionStep.id)?.questionCount}}
                                        </div>

                                        <div
                                            class="flex align-items-center justify-content-center font-semibold text-sm">
                                            {{'Key.Vocabulary' | translate}}
                                        </div>
                                    </div>

                                    <div class="my-3 text-justify pdf-text-size" style="width: 32rem;">
                                        <p>
                                            {{'Key.VocabularyPdfDesc' | translate}}
                                        </p>
                                    </div>

                                    <div class="my-5">
                                        <app-custom-progressbar
                                            [answeredQuestionsWithId]="[getCurrentStepQuestions(EQuestionCategories.Vocabulary, item.positionStep.id)]"
                                            [itemId]="EQuestionCategories.Vocabulary"></app-custom-progressbar>
                                    </div>
                                </div>
                            </div>

                            <div
                                *ngIf="(item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.GeneralEnglish)">
                                <div #area class="avoid" style="width: 32rem; ">
                                    <div class="flex">
                                        <img class="mr-1"
                                            src="../../../../../assets/vinter/icons/pdfReport/general-english-grammer-pdf-badge.svg"
                                            width="24px">
                                        <img class="mr-1"
                                            src="../../../../../assets/vinter/icons/pdfReport/general-english-vocabulary-pdf-badge.svg"
                                            width="24px">
                                        <div class="mr-2 align-item-center align-self-center detailSubFont font-semibold"
                                            style="color:#7752FE; font-size: 12px;">
                                            {{getCurrentStepQuestions(EQuestionCategories.Vocabulary,
                                            item.positionStep.id)?.questionCount +
                                            getCurrentStepQuestions(EQuestionCategories.Grammer,
                                            item.positionStep.id)?.questionCount}}
                                        </div>

                                        <div
                                            class="flex align-items-center justify-content-center font-semibold text-sm">
                                            {{'Key.Grammer' | translate}} / {{'Key.Vocabulary' | translate}}
                                        </div>
                                    </div>

                                    <div class="my-3 text-justify pdf-text-size" style="width: 32rem;">
                                        <p>
                                            {{'Key.VocabularyPdfDesc' | translate}}
                                        </p>
                                    </div>

                                    <div class="my-5">
                                        <div *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.GeneralEnglish"
                                            class="flex justify-content-between flex-wrap">
                                            <div class="flex mt-1 justify-content-center pdf-text-size"
                                                style="color: #686E75;">
                                                {{'Key.GrammerVocabularyScore' | translate}}
                                            </div>
                                            <div class="flex align-items-center justify-content-center font-semibold">
                                                <div class="custom-display flex"
                                                    style="margin-right: 1rem; width:14.3rem;">
                                                    <div class="progress-bar-container mt-2 mr-2">
                                                        <div class="progress-bar"
                                                            [style.width]="(getCurrentStepQuestions(EQuestionCategories.Vocabulary, item.positionStep.id)?.totalScores + getCurrentStepQuestions(EQuestionCategories.Grammer, item.positionStep.id)?.totalScores)
                                                                                                /(getCurrentStepQuestions(EQuestionCategories.Vocabulary, item.positionStep.id)?.questionCount + getCurrentStepQuestions(EQuestionCategories.Grammer, item.positionStep.id)?.questionCount) + '%'"
                                                            [style.backgroundColor]="'#0E9776'"></div>
                                                    </div>
                                                    <div class="flex">
                                                        <p class="text-xs font-normal mr-1" style="color: #0E9776;">
                                                            {{item.generalEnglishTestResult?.grammarVocabularyLevel}}/
                                                        </p>
                                                        <p class="text-xs font-normal w-max" style="color: #686E75;">
                                                            %{{(getCurrentStepQuestions(EQuestionCategories.Vocabulary,
                                                            item.positionStep.id)?.totalScores +
                                                            getCurrentStepQuestions(EQuestionCategories.Grammer,
                                                            item.positionStep.id)?.totalScores)
                                                            /(getCurrentStepQuestions(EQuestionCategories.Vocabulary,
                                                            item.positionStep.id)?.questionCount +
                                                            getCurrentStepQuestions(EQuestionCategories.Grammer,
                                                            item.positionStep.id)?.questionCount).toFixed(0)}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <app-custom-progressbar [answeredQuestionsWithId]="languageAnsweredQuestions"
                                            [isGrammarVocabulary]="true" [positionStepId]="item.positionStep.id"
                                            [itemId]="1"></app-custom-progressbar>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="getCurrentStepQuestions(EQuestionCategories.Speaking, item.positionStep.id)?.questionCount > 0"
                    class="avoid ml-7 my-1" style="width: 32rem;">
                    <div class="flex">
                        <img class="mr-1" src="../../../../../assets/vinter/icons/pdfReport/general-english-speaking-pdf-badge.svg"
                            width="24px">
                        <div class="mr-2 align-item-center align-self-center detailSubFont font-semibold"
                            style="color:#D11ABF; font-size: 12px;">
                            {{getCurrentStepQuestions(EQuestionCategories.Speaking,
                            item.positionStep.id)?.questionCount}}
                        </div>

                        <div class="flex align-items-center justify-content-center font-semibold text-sm">
                            {{'Key.Speaking' | translate}}
                        </div>
                    </div>

                    <div class="my-3 text-justify pdf-text-size" style="width: 33rem;">
                        <p>
                            {{'Key.SpeakingPdfDesc' | translate}}
                        </p>
                    </div>

                    <div class="my-5">
                        <div *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.GeneralEnglish"
                            class="flex justify-content-between flex-wrap">
                            <div class="flex mt-1 justify-content-center pdf-text-size"
                                style="color: #686E75;">
                                {{'Key.SpeakingScore' | translate}}
                            </div>
                            <div class="flex align-items-center justify-content-center font-semibold">
                                <div class="custom-display flex" style="margin-right: 1rem; width:14.3rem;">
                                    <div class="progress-bar-container mt-2 mr-2">
                                        <div class="progress-bar"
                                            [style.width]="(getCurrentStepQuestions(EQuestionCategories.Speaking, item.positionStep.id)?.averageScores*100) + '%'"
                                            [style.backgroundColor]="'#2DC9DE'"></div>
                                    </div>
                                    <div class="flex">
                                        <p class="text-xs font-normal mr-1" style="color: #2DC9DE;">
                                            {{item.generalEnglishTestResult?.speakingLevel}}/ </p>
                                        <p class="text-xs font-normal w-max" style="color: #686E75;">
                                            %{{(getCurrentStepQuestions(EQuestionCategories.Speaking,
                                            item.positionStep.id)?.averageScores*100)?.toFixed(0)}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <app-custom-progressbar
                            [answeredQuestionsWithId]="[getCurrentStepQuestions(EQuestionCategories.Speaking, item.positionStep.id)]"
                            [itemId]="EQuestionCategories.Speaking"></app-custom-progressbar>
                    </div>

                </div>
                <hr class="custom-hr my-7" style="width: 32rem;">
            </div>

            <div
                *ngIf="(item.completedDate ?? false) && (item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.Pronunciation)">
                <div class="mx-7">
                    <div class="my-4">
                        <div class="flex pdf-text-size" style="color: #686E75;">
                            <p>
                                {{"Key.Question" | translate}}
                                {{item.positionStep.questionCollection.expectedAnswerVideoCount}}
                            </p>
                        </div>

                        <div class="my-4 mr-3">
                            <div class="flex justify-content-between flex-wrap my-2">
                                <div class="flex align-items-center justify-content-center font-medium text-xs ">
                                    {{"Key.AveragePronunciationScore" | translate}}
                                </div>
                                <div class="flex align-items-center justify-content-center font-semibold">
                                    <div class="custom-display w-12rem flex">
                                        <div class="progress-bar-container mt-2 mr-2">
                                            <div class="progress-bar" [style.width]="item.averageVinterScore + '%'"
                                                [style.backgroundColor]="'#3F26DC'"></div>
                                        </div>
                                        <p class="text-xs blue-600">{{item.averageVinterScore?.toFixed(2)}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div #area class="my-4 mr-3 ml-3 avoid" style=" width: 32rem; ">
                            <div class="flex font-medium text-xs mb-3">
                                {{"Key.ScoreBreakdown" | translate}}
                            </div>

                            <div class="flex justify-content-between flex-wrap my-1">
                                <div class="flex align-items-center justify-content-center pdf-text-size "
                                    style="color: #686E75;">
                                    {{"Key.Completeness" | translate}}
                                </div>
                                <div class="flex align-items-center justify-content-center font-semibold">
                                    <div class="custom-display w-12rem flex">
                                        <div class="progress-bar-container mt-2 mr-2">
                                            <div class="progress-bar"
                                                [style.width]="item.positionStep.questionCollection.averagePronunciationScores.averageCompletenessScore+ '%'"
                                                [style.backgroundColor]="'#3F26DC'"></div>
                                        </div>
                                        <p class="text-xs blue-600">
                                            {{item.positionStep.questionCollection.averagePronunciationScores.averageCompletenessScore?.toFixed(2)}}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="flex justify-content-between flex-wrap my-1">
                                <div class="flex align-items-center justify-content-center pdf-text-size "
                                    style="color: #686E75;">
                                    {{"Key.Accuracy" | translate}}
                                </div>
                                <div class="flex align-items-center justify-content-center font-semibold">
                                    <div class="custom-display w-12rem flex">
                                        <div class="progress-bar-container mt-2 mr-2">
                                            <div class="progress-bar"
                                                [style.width]="item.positionStep.questionCollection.averagePronunciationScores.averageAccuracyScore+ '%'"
                                                [style.backgroundColor]="'#3F26DC'"></div>
                                        </div>
                                        <p class="text-xs blue-600">
                                            {{item.positionStep.questionCollection.averagePronunciationScores.averageAccuracyScore?.toFixed(2)}}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="flex justify-content-between flex-wrap my-1">
                                <div class="flex align-items-center justify-content-center pdf-text-size"
                                    style="color: #686E75;">
                                    {{"Key.FluencyScore" | translate}}
                                </div>
                                <div class="flex align-items-center justify-content-center font-semibold">
                                    <div class="custom-display w-12rem flex">
                                        <div class="progress-bar-container mt-2 mr-2">
                                            <div class="progress-bar"
                                                [style.width]="item.positionStep.questionCollection.averagePronunciationScores.averageFluencyScore+ '%'"
                                                [style.backgroundColor]="'#3F26DC'"></div>
                                        </div>
                                        <p class="text-xs blue-600">
                                            {{item.positionStep.questionCollection.averagePronunciationScores.averageFluencyScore?.toFixed(2)}}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="flex justify-content-between flex-wrap my-1">
                                <div class="flex align-items-center justify-content-center pdf-text-size"
                                    style="color: #686E75;">
                                    {{"Key.FluencyTimeScore" | translate}}
                                </div>
                                <div class="flex align-items-center justify-content-center font-semibold">
                                    <div class="custom-display w-12rem flex">
                                        <div class="progress-bar-container mt-2 mr-2">
                                            <div class="progress-bar"
                                                [style.width]="item.positionStep.questionCollection.averagePronunciationScores.averageFluencyTimeScore+ '%'"
                                                [style.backgroundColor]="'#3F26DC'"></div>
                                        </div>
                                        <p class="text-xs blue-600">
                                            {{item.positionStep.questionCollection.averagePronunciationScores.averageFluencyTimeScore?.toFixed(2)}}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="flex justify-content-between flex-wrap my-1">
                                <div class="flex align-items-center justify-content-center pdf-text-size"
                                    style="color: #686E75;">
                                    {{"Key.FluencyDisruptorScore" | translate}}
                                </div>
                                <div class="flex align-items-center justify-content-center font-semibold">
                                    <div class="custom-display w-12rem flex">
                                        <div class="progress-bar-container mt-2 mr-2">
                                            <div class="progress-bar"
                                                [style.width]="item.positionStep.questionCollection.averagePronunciationScores.averageFluencyDisruptorScore+ '%'"
                                                [style.backgroundColor]="'#3F26DC'"></div>
                                        </div>
                                        <p class="text-xs blue-600">
                                            {{item.positionStep.questionCollection.averagePronunciationScores.averageFluencyDisruptorScore?.toFixed(2)}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <hr class="custom-hr ml-0 my-7" style="width: 32rem;">
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div *ngIf="isPdfOpen" class="hidden" id="parentSummaryDivId" >
    <div id="applicantInfoSummaryPdf" style="width: 575px; height: auto;">
        <div id="info">

            <div #pdfCoverPage class="report">
                <div class="background">
                    <img src="../../../../../assets/vinter/icons/pdfReport/pdf-background-gradient.png" alt="Gradient Background" class="background-image">
                    <div class="blue-section">
                        <div class="report-footer">
                            <div class="line1"></div>
                            <h3 class="mx-3 my-0">{{'Key.ReportName' | translate}} </h3>
                            <div class="line2"></div>
                        </div>

                    </div>
                </div>

                <div class="content">
                    <div class="header">
                        <img class="align-items-center" src="../../../../../assets/vinter/icons/pdfReport/vinter-logo-pdf.svg" alt="Vinter Logo" class="logo">
                    </div>
                    <div class="position">
                        <h3>{{'Key.PositionNameTitle' | translate}} </h3>
                        <h1>{{positionTitle}}</h1>
                    </div>
                    <div class="candidate-info">
                        <h2>{{applicantFullName}}</h2>
                        <p *ngIf="isCompletedPosition" class="completed-date-text -mb-2 mt-3">{{stepCompletedDate | date:'dd/MM/yy'}}</p>
                        <div *ngIf="isCompletedPosition" class="flex align-items-center completed-position-container" >
                            <i class="pi pi-circle-fill" style="color: #37AC00"></i>
                            <p class="completed-text flex align-items-center justify-content-center mb-0">{{'Key.Completed' | translate}} </p>
                        </div>

                        <hr class="custom-hr m-0 -mb-2">

                        <div>
                            <div class="flex rate">
                            {{'Key.PdfRate' | translate}}
                        </div>
                        <div class="flex align-items-center overall-rate">
                            <span>{{overallRating?.toFixed(1)}}</span>
                        </div>

                        </div>

                        <div class="contact-info">
                            <div class="label flex">
                                <img src="assets/vinter/icons/pdf-mail.svg" style="width: 1rem; height: 1rem;" class="mt-4">
                                <div class="text-justify ml-2 mt-4">
                                    {{applicantEmail}}
                                </div>
                            </div>
                            <div  class="label flex">
                                <img src="assets/vinter/icons/pdf-phone.svg" style="width: 1rem; height: 1rem;" class="mt-2">
                                <div class="text-justify ml-2 mt-2">
                                    {{applicantPhoneNumber}}
                                </div>
                            </div>
                            <div *ngIf="linkedinProfileURL"  class="label flex">
                                <img src="assets/vinter/icons/pdf-linkedin.svg" style="width: 1rem; height: 1rem;" class="mt-2">
                                <div class="text-justify ml-2 mt-2">
                                    {{linkedinProfileURL}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="applicant-pdf-container">
                <div class="flex justify-content-between flex-wrap">

                    <div *ngFor="let item of applicationStepsData; let i = index">
                        <div *ngIf="(item.completedDate ?? false)" class="mr-7">
                            <div #area class="avoid">
                                <span class="font-semibold text-lg my-5">{{item.positionStep.title}}</span>
                            </div>

                            <div #area class="my-4 text-justify pdf-text-size avoid" style="width: 31rem;">
                                <p
                                    *ngIf="item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.PersonalityTest && item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.OCEAN50">
                                    {{'Key.PdfOceanInfo50' | translate}}
                                </p>
                                <p
                                    *ngIf="item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.PersonalityTest  && item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.OCEAN120">
                                    {{'Key.PdfOceanInfo120' | translate}}
                                </p>
                                <p
                                    *ngIf="item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.ForeignLanguage">
                                    {{'Key.PdfLanguageInfo' | translate}}
                                </p>
                                <p
                                    *ngIf="item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.Pronunciation">
                                    {{'Key.PronunciationPdfDesc' | translate}}
                                </p>
                                <p
                                    *ngIf="item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.CompetenceTest">
                                    {{'Key.PdfCompetenceInfo' | translate}}
                                </p>

                                <p
                                    *ngIf="item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.TechnicalTest">
                                    {{'Key.PdfTechnicalInfo' | translate}}
                                </p>

                                <p
                                    *ngIf="item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.CognitiveAbility && item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.NumericalCognitiveAbility">
                                    {{'Key.PdfNumericalCognitiveAbilityInfo' | translate}}
                                </p>

                                <div *ngFor="let category of answeredQuestionsWithId">
                                    <p *ngIf="item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.CognitiveAbility
                                    && item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.VerbalCognitiveAbility
                                    && currentLanguageId === LanguageEnum.EnglishUnitedStates
                                    && (category.answeredQuestionsCount + category.notAnsweredQuestionsCount > 0)">
                                    Candidate <span>{{applicantFullName}}</span> has completed the Verbal Cognitive Ability Test. This test measures the candidate's ability to comprehend and analyze
                                    complex texts, visual attention/visual interpretation, verbal reasoning, application of grammar rules, verbal reasoning, critical thinking, synthesizing information,
                                    listening comprehension, summarizing and reconstructing, vocabulary and solving verbal patterns, and grammatical knowledge. The test aims to comprehensively assess the candidate's
                                    verbal abilities through a variety of question types. The candidate answered <span>{{category.answeredQuestionsCount}}</span> questions in this test consisting of
                                    <span>{{category.answeredQuestionsCount + category.notAnsweredQuestionsCount}}</span> questions. The candidate answered <span>{{category.correctAnsweredQuestionsCount}}</span> questions correctly,
                                    <span>{{category.incorrectAnsweredQuestionsCount}}</span> questions incorrectly and <span>{{category.notAnsweredQuestionsCount}}</span> questions blank.
                                    Accordingly, the score of the candidate was evaluated in the category '<span>{{category.totalScores}}</span>'.
                                </p>
                            </div>

                                <div *ngFor="let category of answeredQuestionsWithId">
                                <p
                                    *ngIf="item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.CognitiveAbility
                                    && item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.VerbalCognitiveAbility
                                    && currentLanguageId === LanguageEnum.TurkishTurkey
                                    && (category.answeredQuestionsCount + category.notAnsweredQuestionsCount > 0)">
                                    Aday <span class="">{{applicantFullName}}</span> Sözel Bilişsel Yetenek Testini tamamlamıştır.Bu test, adayın karmaşık metinleri anlama ve analiz etme, görsel dikkat/görsel
                                    yorumlama, sözel muhakeme yapma, dil bilgisi kurallarını uygulayabilme, sözel mantık yürütme, eleştirel düşünme, bilgileri sentezleme, dinlediğini anlama, özetleme ve yeniden
                                    yapılandırma, kelime bilgisi ve sözel kalıpları çözme yeteneklerini ve gramer bilgisini ölçmektedir. Test, içerdiği çeşitli soru türleri ile adayın sözel yeteneklerini kapsamlı
                                    bir şekilde değerlendirmeyi hedeflemektedir. Aday <span class="">{{category.answeredQuestionsCount + category.notAnsweredQuestionsCount}}</span> sorudan oluşan bu testte <span class="">{{category.answeredQuestionsCount}}</span>
                                    soruyu yanıtlamıştır. Aday sorulardan <span class="">{{category.correctAnsweredQuestionsCount}}</span> tane soruyu doğru, <span class="">{{category.incorrectAnsweredQuestionsCount}}</span>
                                    tane soruyu yanlış cevaplamış ve <span class="">{{category.notAnsweredQuestionsCount}}</span> tane soruyu boş bırakmıştır. Bu doğrultuda adayın aldığı puan
                                    '<span class="">{{category.totalScores}}</span>' kategorisinde değerlendirilmiştir.
                                </p>
                                </div>
                            </div>

                        </div>

                        <div
                            *ngIf="(item.completedDate ?? false) && (item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.PersonalityTest)">
                            <div #area class="mr-7 avoid" id="ocean">
                                <div class="ocean-grid">

                                    <div id="oceanReportO" class="flex justify-content-between flex-wrap pb-3">

                                        <div class="flex justify-content-center">
                                            <img class="mr-1"
                                                src="../../../../../assets/vinter/icons/pdfReport/o-icon.svg"
                                                width="22px">


                                            <div
                                                class="flex align-items-center justify-content-center font-medium text-sm o-text-color">
                                                {{"Key.OpennessToExperience" | translate}}
                                            </div>

                                        </div>

                                        <div class="flex align-items-center justify-content-center pt-3 pr-3">
                                            <div style="width:18rem;">
                                                <app-ocean-progressbar
                                                    [progressBarOceanScore]="item?.stepPerTestResult?.o"
                                                    [oceanCategory]="'o'"
                                                    [oceanScore]="item?.stepPerTestResult?.o?.toFixed(0)"
                                                    [oceanParent]="false"></app-ocean-progressbar>
                                            </div>

                                        </div>
                                    </div>

                                    <div id="oceanReportC" class="flex justify-content-between flex-wrap pb-3">

                                        <div class="flex justify-content-center">
                                            <img class="mr-1"
                                                src="../../../../../assets/vinter/icons/pdfReport/c-icon.svg"
                                                width="22px">

                                            <div
                                                class="flex align-items-center justify-content-center font-medium text-sm c-text-color">
                                                {{"Key.Conscientiousness" | translate}}
                                            </div>

                                        </div>

                                        <div class="flex align-items-center justify-content-center pt-3 pr-3">
                                            <div style="width:18rem;">
                                                <app-ocean-progressbar
                                                    [progressBarOceanScore]="item?.stepPerTestResult?.c"
                                                    [oceanCategory]="'c'"
                                                    [oceanScore]="item?.stepPerTestResult?.c?.toFixed(0)"
                                                    [oceanParent]="false"></app-ocean-progressbar>
                                            </div>

                                        </div>
                                    </div>

                                    <div id="oceanReportE" class="flex justify-content-between flex-wrap pb-3">
                                        <div class="flex justify-content-center">
                                            <img class="mr-1"
                                                src="../../../../../assets/vinter/icons/pdfReport/e-icon.svg"
                                                width="22px">

                                            <div
                                                class="flex align-items-center justify-content-center font-medium text-sm blue-600">
                                                {{"Key.Extraversion" | translate}}
                                            </div>

                                        </div>

                                        <div class="flex align-items-center justify-content-center pt-3 pr-3">
                                            <div style="width:18rem;">
                                                <app-ocean-progressbar
                                                    [progressBarOceanScore]="item?.stepPerTestResult?.e"
                                                    [oceanCategory]="'e'"
                                                    [oceanScore]="item?.stepPerTestResult?.e?.toFixed(0)"
                                                    [oceanParent]="false"></app-ocean-progressbar>
                                            </div>

                                        </div>
                                    </div>

                                    <div id="oceanReportA" class="flex justify-content-between flex-wrap pb-3">

                                        <div class="flex justify-content-center">
                                            <img class="mr-1"
                                                src="../../../../../assets/vinter/icons/pdfReport/a-icon.svg"
                                                width="22px">

                                            <div
                                                class="flex align-items-center justify-content-center font-medium text-sm a-text-color">
                                                {{"Key.Agreeableness" | translate}}
                                            </div>

                                        </div>

                                        <div class="flex align-items-center justify-content-center pt-3 pr-3">
                                            <div style="width:18rem;">
                                                <app-ocean-progressbar
                                                    [progressBarOceanScore]="item?.stepPerTestResult?.a"
                                                    [oceanCategory]="'a'"
                                                    [oceanScore]="item?.stepPerTestResult?.a?.toFixed(0)"
                                                    [oceanParent]="false"></app-ocean-progressbar>
                                            </div>

                                        </div>

                                    </div>

                                    <div id="oceanReportN" class="flex justify-content-between flex-wrap pb-3">
                                        <div class="flex justify-content-center">
                                            <img class="mr-1"
                                                src="../../../../../assets/vinter/icons/pdfReport/n-icon.svg"
                                                width="22px">

                                            <div
                                                class="flex align-items-center justify-content-center font-medium text-sm n-text-color">
                                                {{"Key.Neuroticism" | translate}}
                                            </div>

                                        </div>

                                        <div class="flex align-items-center justify-content-center pt-3 pr-3">
                                            <div style="width:18rem;">
                                                <app-ocean-progressbar
                                                    [progressBarOceanScore]="item?.stepPerTestResult?.n"
                                                    [oceanCategory]="'n'"
                                                    [oceanScore]="item?.stepPerTestResult?.n?.toFixed(0)"
                                                    [oceanParent]="false"></app-ocean-progressbar>
                                            </div>

                                        </div>


                                    </div>

                                </div>


                            </div>
                        </div>

                        <div
                            *ngIf="(item.completedDate ?? false) && (item.positionStep.questionCollection.questionCollectionCategory !== QuestionCollectionCategoryEnum.PersonalityTest) &&
                        (item.positionStep.questionCollection.questionCollectionCategory !== QuestionCollectionCategoryEnum.ForeignLanguage) &&
                        (item.positionStep.questionCollection.questionCollectionCategory !== QuestionCollectionCategoryEnum.Pronunciation)">

                            <div #area class="my-4 mr-7 avoid">
                                <div class="flex justify-content-between flex-wrap my-2">
                                    <div class="flex align-items-center justify-content-center font-semibold text-xs ">
                                        {{'Key.PdfVinterScore' | translate}}
                                    </div>
                                    <div class="flex align-items-center justify-content-center font-semibold">
                                        <div class="custom-display w-12rem flex">
                                            <div class="progress-bar-container mt-2 mr-2">
                                                <div class="progress-bar" [style.width]="item.averageVinterScore + '%'"
                                                    [style.backgroundColor]="'#3F26DC'"></div>
                                            </div>
                                            <p class="text-xs ">{{item.averageVinterScore?.toFixed(2)}}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="flex justify-content-between flex-wrap my-2">
                                    <div class="flex align-items-center justify-content-center font-semibold text-xs ">
                                        {{'Key.PdfRate' | translate}}
                                    </div>
                                    <div class="flex align-items-center justify-content-center font-semibold">
                                        <p class="text-xs ">{{item.averageRating?.toFixed(2)}}</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div
                            *ngIf="(item.completedDate ?? false) && (item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.ForeignLanguage)">
                            <div class="mr-7">
                                <div #area class="my-4 avoid">
                                    <div class="flex justify-content-between flex-wrap my-2">
                                        <div
                                            class="flex align-items-center justify-content-center font-semibold text-xs ">
                                            {{'Key.PdfVinterScore' | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <div class="custom-display w-12rem flex">
                                                <div class="progress-bar-container mt-2 mr-2">
                                                    <div class="progress-bar"
                                                        [style.width]="item.averageVinterScore + '%'"
                                                        [style.backgroundColor]="'#3F26DC'"></div>
                                                </div>
                                                <div
                                                    *ngIf="item.positionStep.questionCollection.subCategory !== QuestionCollectionSubCategory.GeneralEnglish">
                                                    <p class="text-xs ">{{item.averageVinterScore?.toFixed(2)}}</p>
                                                </div>
                                                <div *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.GeneralEnglish"
                                                    class="flex">
                                                    <p class="text-xs font-medium mb-0">
                                                        {{item.generalEnglishTestResult?.generalLevel}}</p>
                                                    <p class="text-xs font-medium" style="color: #686E75;">
                                                        /{{item.averageVinterScore?.toFixed(2)}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex justify-content-between flex-wrap my-2">
                                        <div
                                            class="flex align-items-center justify-content-center font-semibold text-xs ">
                                            {{'Key.PdfRate' | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <p class="text-xs ">{{item.averageRating?.toFixed(2)}}</p>
                                        </div>
                                    </div>

                                </div>

                                <div *ngIf="item.positionStep.questionCollection.expectedAnswerOpenEndedCount !== 0">

                                    <div #area
                                        *ngIf="getCurrentStepQuestions(EQuestionCategories.Writing, item.positionStep.id)?.questionCount > 0"
                                        class="my-3 avoid">
                                        <div class="flex justify-content-between flex-wrap">
                                            <div class="flex justify-content-center">
                                                <img class="mr-1"
                                                    src="../../../../../assets/vinter/icons/pdfReport/general-english-writing-pdf-badge.svg"
                                                    width="24px">

                                                <div
                                                    class="flex align-items-center justify-content-center font-semibold text-sm">
                                                    {{'Key.Writing' | translate}}
                                                </div>
                                            </div>

                                            <div class="flex align-items-center justify-content-center font-semibold">
                                                <div class="custom-display flex" style="width:14.3rem;">
                                                    <div class="progress-bar-container mt-2 mr-2">
                                                        <div class="progress-bar"
                                                            [style.width]="getCurrentStepQuestions(EQuestionCategories.Writing, item.positionStep.id)?.averageScores + '%'"
                                                            [style.backgroundColor]="'#2D4ADE'"></div>
                                                    </div>
                                                    <div class="flex">
                                                        <p *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.GeneralEnglish"
                                                            class="text-xs font-normal mb-0 mr-1"
                                                            style="color: #2D4ADE;">
                                                            {{item.generalEnglishTestResult?.writingLevel}}/ </p>
                                                        <p class="text-xs font-normal w-max" style="color: #686E75;">
                                                            {{(getCurrentStepQuestions(EQuestionCategories.Writing,
                                                            item.positionStep.id)?.averageScores)?.toFixed(0)}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div
                                    *ngIf="item.positionStep.questionCollection.expectedAnswerMultipleChoiceCount !== 0">

                                    <div #area
                                        *ngIf="getCurrentStepQuestions(EQuestionCategories.Listening, item.positionStep.id)?.questionCount > 0"
                                        class="my-3 avoid">
                                        <div class="flex justify-content-between flex-wrap">
                                            <div class="flex justify-content-center">
                                                <img class="mr-1"
                                                    src="../../../../../assets/vinter/icons/pdfReport/general-english-listening-pdf-badge.svg"
                                                    width="24px">

                                                <div
                                                    class="flex align-items-center justify-content-center font-semibold text-sm">
                                                    {{'Key.Listening' | translate}}
                                                </div>
                                            </div>

                                            <div class="flex align-items-center justify-content-center font-semibold">
                                                <div class="custom-display flex" style="width:14.3rem;">
                                                    <div class="progress-bar-container mt-2 mr-2">
                                                        <div class="progress-bar"
                                                            [style.width]="(getCurrentStepQuestions(EQuestionCategories.Listening, item.positionStep.id)?.averageScores*100 )+ '%'"
                                                            [style.backgroundColor]="'#CE4FBA'"></div>
                                                    </div>
                                                    <div class="flex">
                                                        <p *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.GeneralEnglish"
                                                            class="text-xs font-normal mb-0 mr-1"
                                                            style="color: #CE4FBA;">
                                                            {{item.generalEnglishTestResult?.listeningLevel}}/ </p>
                                                        <p class="text-xs font-normal w-max" style="color: #686E75;">
                                                            %{{(getCurrentStepQuestions(EQuestionCategories.Listening,
                                                            item.positionStep.id)?.averageScores*100)?.toFixed(0)}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div #area
                                        *ngIf="getCurrentStepQuestions(EQuestionCategories.Reading, item.positionStep.id)?.questionCount > 0"
                                        class="my-3 avoid">
                                        <div class="flex justify-content-between flex-wrap">
                                            <div class="flex justify-content-center">
                                                <img class="mr-1"
                                                    src="../../../../../assets/vinter/icons/pdfReport/general-english-reading-pdf-badge.svg"
                                                    width="24px">

                                                <div
                                                    class="flex align-items-center justify-content-center font-semibold text-sm">
                                                    {{'Key.Reading' | translate}}
                                                </div>
                                            </div>

                                            <div class="flex align-items-center justify-content-center font-semibold">
                                                <div class="custom-display flex" style="width:14.3rem;">
                                                    <div class="progress-bar-container mt-2 mr-2">
                                                        <div class="progress-bar"
                                                            [style.width]="(getCurrentStepQuestions(EQuestionCategories.Reading, item.positionStep.id)?.averageScores*100 )+ '%'"
                                                            [style.backgroundColor]="'#2DC9DE'"></div>
                                                    </div>
                                                    <div class="flex">
                                                        <p *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.GeneralEnglish"
                                                            class="text-xs font-normal mb-0 mr-1"
                                                            style="color: #6931DF;">
                                                            {{item.generalEnglishTestResult?.readingLevel}}/ </p>
                                                        <p class="text-xs font-normal w-max" style="color: #686E75;">
                                                            %{{(getCurrentStepQuestions(EQuestionCategories.Reading,
                                                            item.positionStep.id)?.averageScores*100)?.toFixed(0)}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div #area
                                        *ngIf="(item.positionStep.questionCollection.subCategory !== QuestionCollectionSubCategory.GeneralEnglish) && getCurrentStepQuestions(EQuestionCategories.Grammer, item.positionStep.id)?.questionCount > 0"
                                        class="my-3 avoid">
                                        <div class="flex justify-content-between flex-wrap">
                                            <div class="flex justify-content-center">
                                                <img class="mr-1"
                                                    src="../../../../../assets/vinter/icons/pdfReport/general-english-grammer-pdf-badge.svg"
                                                    width="24px">

                                                <div
                                                    class="flex align-items-center justify-content-center font-semibold text-sm">
                                                    {{'Key.Grammer' | translate}}
                                                </div>
                                            </div>

                                            <div class="flex align-items-center justify-content-center font-semibold">
                                                <div class="custom-display flex" style="width:14.3rem;">
                                                    <div class="progress-bar-container mt-2 mr-2">
                                                        <div class="progress-bar"
                                                            [style.width]="(getCurrentStepQuestions(EQuestionCategories.Grammer, item.positionStep.id)?.averageScores*100 )+ '%'"
                                                            [style.backgroundColor]="'#0E9776'"></div>
                                                    </div>
                                                    <div class="flex">
                                                        <p class="text-xs font-normal w-max" style="color: #686E75;">
                                                            %{{(getCurrentStepQuestions(EQuestionCategories.Grammer,
                                                            item.positionStep.id)?.averageScores*100)?.toFixed(0)}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div #area
                                        *ngIf="(item.positionStep.questionCollection.subCategory !== QuestionCollectionSubCategory.GeneralEnglish) && getCurrentStepQuestions(EQuestionCategories.Vocabulary, item.positionStep.id)?.questionCount > 0"
                                        class="my-3 avoid">
                                        <div class="flex justify-content-between flex-wrap">
                                            <div class="flex justify-content-center">
                                                <img class="mr-1"
                                                    src="../../../../../assets/vinter/icons/pdfReport/general-english-vocabulary-pdf-badge.svg"
                                                    width="24px">

                                                <div
                                                    class="flex align-items-center justify-content-center font-semibold text-sm">
                                                    {{'Key.Vocabulary' | translate}}
                                                </div>
                                            </div>

                                            <div class="flex align-items-center justify-content-center font-semibold">
                                                <div class="custom-display flex" style="width:14.3rem;">
                                                    <div class="progress-bar-container mt-2 mr-2">
                                                        <div class="progress-bar"
                                                            [style.width]="(getCurrentStepQuestions(EQuestionCategories.Vocabulary, item.positionStep.id)?.averageScores*100 )+ '%'"
                                                            [style.backgroundColor]="'#D71773'"></div>
                                                    </div>
                                                    <div class="flex">
                                                        <p class="text-xs font-normal w-max" style="color: #686E75;">
                                                            %{{(getCurrentStepQuestions(EQuestionCategories.Vocabulary,
                                                            item.positionStep.id)?.averageScores*100)?.toFixed(0)}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div #area
                                        *ngIf="(item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.GeneralEnglish)"
                                        class="my-3 avoid">
                                        <div class="flex justify-content-between flex-wrap">
                                            <div class="flex justify-content-center">
                                                <img class="mr-1"
                                                    src="../../../../../assets/vinter/icons/pdfReport/general-english-grammer-pdf-badge.svg"
                                                    width="24px">
                                                <img class="mr-1"
                                                    src="../../../../../assets/vinter/icons/pdfReport/general-english-vocabulary-pdf-badge.svg"
                                                    width="24px">
                                                <div class="mr-2 align-item-center align-self-center detailSubFont font-semibold"
                                                    style="color:#7752FE; font-size: 12px;">
                                                    {{getCurrentStepQuestions(EQuestionCategories.Vocabulary,
                                                    item.positionStep.id)?.questionCount +
                                                    getCurrentStepQuestions(EQuestionCategories.Grammer,
                                                    item.positionStep.id)?.questionCount}}
                                                </div>

                                                <div
                                                    class="flex align-items-center justify-content-center font-semibold text-sm">
                                                    {{'Key.Grammer' | translate}} / {{'Key.Vocabulary' | translate}}
                                                </div>
                                            </div>

                                            <div class="flex align-items-center justify-content-center font-semibold">
                                                <div class="custom-display flex" style="width:14.3rem;">
                                                    <div class="progress-bar-container mt-2 mr-2">
                                                        <div class="progress-bar"
                                                            [style.width]="(getCurrentStepQuestions(EQuestionCategories.Vocabulary, item.positionStep.id)?.totalScores + getCurrentStepQuestions(EQuestionCategories.Grammer, item.positionStep.id)?.totalScores)
                                                                                    /(getCurrentStepQuestions(EQuestionCategories.Vocabulary, item.positionStep.id)?.questionCount + getCurrentStepQuestions(EQuestionCategories.Grammer, item.positionStep.id)?.questionCount) + '%'"
                                                            [style.backgroundColor]="'#0E9776'"></div>
                                                    </div>
                                                    <div class="flex">
                                                        <p class="text-xs font-normal mb-0 mr-1"
                                                            style="color: #0E9776;">
                                                            {{item.generalEnglishTestResult?.grammarVocabularyLevel}}/
                                                        </p>
                                                        <p class="text-xs font-normal w-max" style="color: #686E75;">
                                                            %{{(getCurrentStepQuestions(EQuestionCategories.Vocabulary,
                                                            item.positionStep.id)?.totalScores +
                                                            getCurrentStepQuestions(EQuestionCategories.Grammer,
                                                            item.positionStep.id)?.totalScores)
                                                            /(getCurrentStepQuestions(EQuestionCategories.Vocabulary,
                                                            item.positionStep.id)?.questionCount +
                                                            getCurrentStepQuestions(EQuestionCategories.Grammer,
                                                            item.positionStep.id)?.questionCount).toFixed(0)}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div #area
                                        *ngIf="getCurrentStepQuestions(EQuestionCategories.Speaking, item.positionStep.id)?.questionCount > 0"
                                        class="my-3 avoid">
                                        <div class="flex justify-content-between flex-wrap">
                                            <div class="flex justify-content-center">
                                                <img class="mr-1"
                                                    src="../../../../../assets/vinter/icons/pdfReport/general-english-speaking-pdf-badge.svg"
                                                    width="24px">

                                                <div
                                                    class="flex align-items-center justify-content-center font-semibold text-sm">
                                                    {{'Key.Speaking' | translate}}
                                                </div>
                                            </div>

                                            <div class="flex align-items-center justify-content-center font-semibold">
                                                <div class="custom-display flex" style="width:14.3rem;">
                                                    <div class="progress-bar-container mt-2 mr-2">
                                                        <div class="progress-bar"
                                                            [style.width]="(getCurrentStepQuestions(EQuestionCategories.Speaking, item.positionStep.id)?.averageScores*100 )+ '%'"
                                                            [style.backgroundColor]="'#2DC9DE'"></div>
                                                    </div>
                                                    <div class="flex">
                                                        <p *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.GeneralEnglish"
                                                            class="text-xs font-normal mb-0 mr-1"
                                                            style="color: #CE4FBA;">
                                                            {{item.generalEnglishTestResult?.speakingLevel}}/ </p>
                                                        <p class="text-xs font-normal w-max" style="color: #686E75;">
                                                            %{{(getCurrentStepQuestions(EQuestionCategories.Speaking,
                                                            item.positionStep.id)?.averageScores*100)?.toFixed(0)}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div  #area class="avoid"
                            *ngIf="(item.completedDate ?? false) && (item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.Pronunciation)">
                            <div #area class="mr-7 avoid">
                                <div class="my-4">
                                    <div class="flex justify-content-between flex-wrap my-2">
                                        <div
                                            class="flex align-items-center justify-content-center font-medium text-xs ">
                                            {{"Key.AveragePronunciationScore" | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <div class="custom-display w-12rem flex">
                                                <div class="progress-bar-container mt-2 mr-2">
                                                    <div class="progress-bar"
                                                        [style.width]="item.averageVinterScore + '%'"
                                                        [style.backgroundColor]="'#3F26DC'"></div>
                                                </div>
                                                <p class="text-xs blue-600">{{item.averageVinterScore?.toFixed(2)}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div  class="my-4 ml-3">
                                    <div class="flex font-medium text-xs mb-3">
                                        {{"Key.ScoreBreakdown" | translate}}
                                    </div>

                                    <div class="flex justify-content-between flex-wrap my-1">
                                        <div class="flex align-items-center justify-content-center pdf-text-size "
                                            style="color: #686E75;">
                                            {{"Key.Completeness" | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <div class="custom-display w-12rem flex">
                                                <div class="progress-bar-container mt-2 mr-2">
                                                    <div class="progress-bar"
                                                        [style.width]="item.positionStep.questionCollection.averagePronunciationScores.averageCompletenessScore+ '%'"
                                                        [style.backgroundColor]="'#3F26DC'"></div>
                                                </div>
                                                <p class="text-xs blue-600">
                                                    {{item.positionStep.questionCollection.averagePronunciationScores.averageCompletenessScore?.toFixed(2)}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex justify-content-between flex-wrap my-1">
                                        <div class="flex align-items-center justify-content-center pdf-text-size "
                                            style="color: #686E75;">
                                            {{"Key.Accuracy" | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <div class="custom-display w-12rem flex">
                                                <div class="progress-bar-container mt-2 mr-2">
                                                    <div class="progress-bar"
                                                        [style.width]="item.positionStep.questionCollection.averagePronunciationScores.averageAccuracyScore+ '%'"
                                                        [style.backgroundColor]="'#3F26DC'"></div>
                                                </div>
                                                <p class="text-xs blue-600">
                                                    {{item.positionStep.questionCollection.averagePronunciationScores.averageAccuracyScore?.toFixed(2)}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex justify-content-between flex-wrap my-1">
                                        <div class="flex align-items-center justify-content-center pdf-text-size"
                                            style="color: #686E75;">
                                            {{"Key.FluencyScore" | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <div class="custom-display w-12rem flex">
                                                <div class="progress-bar-container mt-2 mr-2">
                                                    <div class="progress-bar"
                                                        [style.width]="item.positionStep.questionCollection.averagePronunciationScores.averageFluencyScore+ '%'"
                                                        [style.backgroundColor]="'#3F26DC'"></div>
                                                </div>
                                                <p class="text-xs blue-600">
                                                    {{item.positionStep.questionCollection.averagePronunciationScores.averageFluencyScore?.toFixed(2)}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex justify-content-between flex-wrap my-1">
                                        <div class="flex align-items-center justify-content-center pdf-text-size"
                                            style="color: #686E75;">
                                            {{"Key.FluencyTimeScore" | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <div class="custom-display w-12rem flex">
                                                <div class="progress-bar-container mt-2 mr-2">
                                                    <div class="progress-bar"
                                                        [style.width]="item.positionStep.questionCollection.averagePronunciationScores.averageFluencyTimeScore+ '%'"
                                                        [style.backgroundColor]="'#3F26DC'"></div>
                                                </div>
                                                <p class="text-xs blue-600">
                                                    {{item.positionStep.questionCollection.averagePronunciationScores.averageFluencyTimeScore?.toFixed(2)}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex justify-content-between flex-wrap my-1">
                                        <div class="flex align-items-center justify-content-center pdf-text-size"
                                            style="color: #686E75;">
                                            {{"Key.FluencyDisruptorScore" | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <div class="custom-display w-12rem flex">
                                                <div class="progress-bar-container mt-2 mr-2">
                                                    <div class="progress-bar"
                                                        [style.width]="item.positionStep.questionCollection.averagePronunciationScores.averageFluencyDisruptorScore+ '%'"
                                                        [style.backgroundColor]="'#3F26DC'"></div>
                                                </div>
                                                <p class="text-xs blue-600">
                                                    {{item.positionStep.questionCollection.averagePronunciationScores.averageFluencyDisruptorScore?.toFixed(2)}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr *ngIf="(item.completedDate ?? false)" class="custom-hr-summary my-4 mr-7">
                    </div>
                    <div #area class="my-4 mr-3 avoid" style="width: 33rem;">
                        <span class="font-semibold text-sm my-5" style="color:#002075;">{{'Key.CheatingAnalysis' |
                            translate}}</span>
                        <div class="flex justify-content-between flex-wrap my-2">
                            <div class="flex align-items-center justify-content-center pdf-text-size"
                                style="color: #686E75;">
                                {{'Key.SamePageUsed' | translate}}
                            </div>
                            <div *ngIf="!isSwitchedTab"
                                class="flex align-items-center justify-content-center font-semibold text-xs"
                                style="color:#37AC00">
                                {{'Key.Yes' | translate}}
                            </div>
                            <div *ngIf="isSwitchedTab"
                                class="flex align-items-center justify-content-center font-semibold text-xs"
                                style="color:#F20505">
                                {{'Key.No' | translate}}
                            </div>
                        </div>

                        <div class="flex justify-content-between flex-wrap my-2">
                            <div class="flex align-items-center justify-content-center pdf-text-size"
                                style="color: #686E75;">
                                {{'Key.FaceDetectedLog' | translate}}
                            </div>
                            <div *ngIf="isFaceDetectedLog"
                                class="flex align-items-center justify-content-center font-semibold text-xs"
                                style="color:#F20505">
                                {{'Key.Yes' | translate}}
                            </div>
                            <div *ngIf="!isFaceDetectedLog"
                                class="flex align-items-center justify-content-center font-semibold text-xs"
                                style="color:#37AC00">
                                {{'Key.No' | translate}}
                            </div>
                        </div>

                        <div class="flex justify-content-between flex-wrap my-2">
                            <div class="flex align-items-center justify-content-center pdf-text-size"
                                style="color: #686E75;">
                                {{'Key.MultiFaceDetectedLog' | translate}}
                            </div>
                            <div *ngIf="isMultipleFaceLog"
                                class="flex align-items-center justify-content-center font-semibold text-xs"
                                style="color:#F20505">
                                {{'Key.Yes' | translate}}
                            </div>
                            <div *ngIf="!isMultipleFaceLog"
                                class="flex align-items-center justify-content-center font-semibold text-xs"
                                style="color:#37AC00">
                                {{'Key.No' | translate}}
                            </div>
                        </div>

                        <div class="flex justify-content-between flex-wrap my-2">
                            <div class="flex align-items-center justify-content-center pdf-text-size"
                                style="color: #686E75;">
                                {{'Key.LanguageInappropriateness' | translate}}
                            </div>
                            <div *ngIf="isImproprietySpeechLog"
                                class="flex align-items-center justify-content-center font-semibold text-xs"
                                style="color:#F20505">
                                {{'Key.Yes' | translate}}
                            </div>
                            <div *ngIf="!isImproprietySpeechLog"
                                class="flex align-items-center justify-content-center font-semibold text-xs"
                                style="color:#37AC00">
                                {{'Key.No' | translate}}
                            </div>
                        </div>

                        <div class="flex justify-content-between flex-wrap my-2">
                            <div class="flex align-items-center justify-content-center pdf-text-size"
                                style="color: #686E75;">
                                {{'Key.LanguageInappropriateness' | translate}}
                            </div>
                            <div *ngIf="!isMultipleMonitor"
                                class="flex align-items-center justify-content-center font-semibold text-xs"
                                style="color:#37AC00">
                                {{'Key.No' | translate}}
                            </div>
                            <div *ngIf="isMultipleMonitor"
                                class="flex align-items-center justify-content-center font-semibold text-xs"
                                style="color:#F20505">
                                {{'Key.Yes' | translate}}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isAIPdfOpen" class="hidden" id="parentAIReportDivId" >
    <div id="applicantAIBasedPdf" style="width: 575px; height: auto;">
        <div id="info">

            <div #pdfCoverPage class="report">
                <div class="background">
                    <img src="../../../../../assets/vinter/icons/pdfReport/pdf-background-gradient.png" alt="Gradient Background" class="background-image">
                    <div class="blue-section">
                        <div class="report-footer">
                            <div class="line1"></div>
                            <h3 class="mx-3 my-0">{{'Key.ReportName' | translate}} </h3>
                            <div class="line2"></div>
                        </div>

                    </div>
                </div>

                <div class="content">
                    <div class="header">
                        <img class="align-items-center" src="../../../../../assets/vinter/icons/pdfReport/vinter-logo-pdf.svg" alt="Vinter Logo" class="logo">
                    </div>
                    <div class="position">
                        <h3>{{'Key.PositionNameTitle' | translate}} </h3>
                        <h1>{{positionTitle}}</h1>
                    </div>
                    <div class="candidate-info">
                        <h2>{{applicantFullName}}</h2>
                        <p *ngIf="isCompletedPosition" class="completed-date-text -mb-2 mt-3">{{aiReportData?.datetimeNow | date:'dd/MM/yy'}}</p>
                        <div *ngIf="isCompletedPosition" class="flex align-items-center completed-position-container" >
                            <i class="pi pi-circle-fill" style="color: #37AC00"></i>
                            <p class="completed-text flex align-items-center justify-content-center mb-0">{{'Key.Completed' | translate}} </p>
                        </div>

                        <hr class="custom-hr m-0 -mb-2">

                        <div>
                            <div class="flex rate">
                            {{'Key.PdfRate' | translate}}
                        </div>
                        <div class="flex align-items-center overall-rate">
                            <span>{{overallRating?.toFixed(1)}}</span>
                        </div>

                        </div>

                        <div class="contact-info">
                            <div class="label flex">
                                <img src="assets/vinter/icons/pdf-mail.svg" style="width: 1rem; height: 1rem;" class="mt-4">
                                <div class="text-justify ml-2 mt-4">
                                    {{applicantEmail}}
                                </div>
                            </div>
                            <div  class="label flex">
                                <img src="assets/vinter/icons/pdf-phone.svg" style="width: 1rem; height: 1rem;" class="mt-2">
                                <div class="text-justify ml-2 mt-2">
                                    {{applicantPhoneNumber}}
                                </div>
                            </div>
                            <div *ngIf="linkedinProfileURL"  class="label flex">
                                <img src="assets/vinter/icons/pdf-linkedin.svg" style="width: 1rem; height: 1rem;" class="mt-2">
                                <div class="text-justify ml-2 mt-2">
                                    {{linkedinProfileURL}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="applicant-pdf-container">

                <div #area *ngIf="aiReportData?.applicationReportText!=null" class="mt-7 mb-5 text-justify pdf-text-size avoid" style="width: 33rem;">
                    <p class="mb-3">
                        {{aiReportData?.applicationReportText}}
                    </p>
                </div>

                <div class="flex justify-content-between flex-wrap">

                    <div *ngFor="let item of applicationStepsData; let i = index" #area class="avoid">
                        <div *ngIf="(item.completedDate ?? false)" class="mr-7">
                            <div #area class="avoid">
                                <span class="font-semibold text-lg my-5">{{item.positionStep.title}}</span>
                            </div>

                            <div #area class="my-4 text-justify pdf-text-size avoid" style="width: 31rem;">
                                <p *ngIf="item.reportText!=null">
                                    {{item.reportText}}
                                </p>
                            </div>

                        </div>

                        <!-- <div
                            *ngIf="(item.completedDate ?? false) && (item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.PersonalityTest)">
                            <div #area class="mr-7 avoid" id="ocean">
                                <div class="ocean-grid">

                                    <div id="oceanReportO" class="flex justify-content-between flex-wrap pb-3">

                                        <div class="flex justify-content-center">
                                            <img class="mr-1"
                                                src="../../../../../assets/vinter/icons/pdfReport/o-icon.svg"
                                                width="22px">


                                            <div
                                                class="flex align-items-center justify-content-center font-medium text-sm o-text-color">
                                                {{"Key.OpennessToExperience" | translate}}
                                            </div>

                                        </div>

                                        <div class="flex align-items-center justify-content-center pt-3 pr-3">
                                            <div style="width:18rem;">
                                                <app-ocean-progressbar
                                                    [progressBarOceanScore]="item?.stepPerTestResult?.o"
                                                    [oceanCategory]="'o'"
                                                    [oceanScore]="item?.stepPerTestResult?.o?.toFixed(0)"
                                                    [oceanParent]="false"></app-ocean-progressbar>
                                            </div>

                                        </div>
                                    </div>

                                    <div id="oceanReportC" class="flex justify-content-between flex-wrap pb-3">

                                        <div class="flex justify-content-center">
                                            <img class="mr-1"
                                                src="../../../../../assets/vinter/icons/pdfReport/c-icon.svg"
                                                width="22px">

                                            <div
                                                class="flex align-items-center justify-content-center font-medium text-sm c-text-color">
                                                {{"Key.Conscientiousness" | translate}}
                                            </div>

                                        </div>

                                        <div class="flex align-items-center justify-content-center pt-3 pr-3">
                                            <div style="width:18rem;">
                                                <app-ocean-progressbar
                                                    [progressBarOceanScore]="item?.stepPerTestResult?.c"
                                                    [oceanCategory]="'c'"
                                                    [oceanScore]="item?.stepPerTestResult?.c?.toFixed(0)"
                                                    [oceanParent]="false"></app-ocean-progressbar>
                                            </div>

                                        </div>
                                    </div>

                                    <div id="oceanReportE" class="flex justify-content-between flex-wrap pb-3">
                                        <div class="flex justify-content-center">
                                            <img class="mr-1"
                                                src="../../../../../assets/vinter/icons/pdfReport/e-icon.svg"
                                                width="22px">

                                            <div
                                                class="flex align-items-center justify-content-center font-medium text-sm blue-600">
                                                {{"Key.Extraversion" | translate}}
                                            </div>

                                        </div>

                                        <div class="flex align-items-center justify-content-center pt-3 pr-3">
                                            <div style="width:18rem;">
                                                <app-ocean-progressbar
                                                    [progressBarOceanScore]="item?.stepPerTestResult?.e"
                                                    [oceanCategory]="'e'"
                                                    [oceanScore]="item?.stepPerTestResult?.e?.toFixed(0)"
                                                    [oceanParent]="false"></app-ocean-progressbar>
                                            </div>

                                        </div>
                                    </div>

                                    <div id="oceanReportA" class="flex justify-content-between flex-wrap pb-3">

                                        <div class="flex justify-content-center">
                                            <img class="mr-1"
                                                src="../../../../../assets/vinter/icons/pdfReport/a-icon.svg"
                                                width="22px">

                                            <div
                                                class="flex align-items-center justify-content-center font-medium text-sm a-text-color">
                                                {{"Key.Agreeableness" | translate}}
                                            </div>

                                        </div>

                                        <div class="flex align-items-center justify-content-center pt-3 pr-3">
                                            <div style="width:18rem;">
                                                <app-ocean-progressbar
                                                    [progressBarOceanScore]="item?.stepPerTestResult?.a"
                                                    [oceanCategory]="'a'"
                                                    [oceanScore]="item?.stepPerTestResult?.a?.toFixed(0)"
                                                    [oceanParent]="false"></app-ocean-progressbar>
                                            </div>

                                        </div>

                                    </div>

                                    <div id="oceanReportN" class="flex justify-content-between flex-wrap pb-3">
                                        <div class="flex justify-content-center">
                                            <img class="mr-1"
                                                src="../../../../../assets/vinter/icons/pdfReport/n-icon.svg"
                                                width="22px">

                                            <div
                                                class="flex align-items-center justify-content-center font-medium text-sm n-text-color">
                                                {{"Key.Neuroticism" | translate}}
                                            </div>

                                        </div>

                                        <div class="flex align-items-center justify-content-center pt-3 pr-3">
                                            <div style="width:18rem;">
                                                <app-ocean-progressbar
                                                    [progressBarOceanScore]="item?.stepPerTestResult?.n"
                                                    [oceanCategory]="'n'"
                                                    [oceanScore]="item?.stepPerTestResult?.n?.toFixed(0)"
                                                    [oceanParent]="false"></app-ocean-progressbar>
                                            </div>

                                        </div>


                                    </div>

                                </div>


                            </div>
                        </div> -->

                        <div
                            *ngIf="(item.completedDate ?? false) && (item.positionStep.questionCollection.questionCollectionCategory !== QuestionCollectionCategoryEnum.PersonalityTest) &&
                        (item.positionStep.questionCollection.questionCollectionCategory !== QuestionCollectionCategoryEnum.ForeignLanguage) &&
                        (item.positionStep.questionCollection.questionCollectionCategory !== QuestionCollectionCategoryEnum.Pronunciation)">

                            <div #area class="my-4 mr-7 avoid">
                                <div class="flex justify-content-between flex-wrap my-2">
                                    <div class="flex align-items-center justify-content-center font-semibold text-xs ">
                                        {{'Key.PdfVinterScore' | translate}}

                                    </div>
                                    <div class="flex align-items-center justify-content-center font-semibold">
                                        <div class="custom-display w-12rem flex">
                                            <div class="progress-bar-container mt-2 mr-2">
                                                <div class="progress-bar" [style.width]="item.averageVinterScore + '%'"
                                                    [style.backgroundColor]="'#3F26DC'"></div>
                                            </div>
                                            <p class="text-xs ">{{item.averageVinterScore?.toFixed(2)}}</p>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="item.currentOrder!=null && item.currentApplicantCount!=null"
                                    class="flex justify-content-between flex-wrap my-2">
                                    <div class="flex align-items-center justify-content-center font-semibold text-xs ">
                                        {{'Key.PdfVinterScoreRank' | translate}}
                                    </div>
                                    <div class="flex align-items-center justify-content-center font-semibold">
                                        <div class="custom-display w-12rem flex justify-content-end">
                                            <div class="mt-2 mr-2 text-xs" >
                                                <div class="bg-primary border-round-lg pr-2 pl-2 inline-block text-white text-center" style="margin-right: 1px;padding-bottom: 2px;">{{item.currentOrder}}</div>...{{item.currentApplicantCount}}
                                            </div>
                                            <img src="assets/vinter/icons/persons.svg" style="width: 1rem; height: 1rem;"
                                            class="mt-2">
                                        </div>
                                    </div>
                                </div>

                                <div class="flex justify-content-between flex-wrap my-2">
                                    <div class="flex align-items-center justify-content-center font-semibold text-xs ">
                                        {{'Key.PdfRate' | translate}}
                                    </div>
                                    <div class="flex align-items-center justify-content-center font-semibold">
                                        <p class="text-xs ">{{item.averageRating?.toFixed(2)}}</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div
                            *ngIf="(item.completedDate ?? false) && (item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.ForeignLanguage)">
                            <div class="mr-7">
                                <div #area class="my-4 avoid">
                                    <div class="flex justify-content-between flex-wrap my-2">
                                        <div
                                            class="flex align-items-center justify-content-center font-semibold text-xs ">
                                            {{'Key.PdfVinterScore' | translate}}

                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <div class="custom-display w-12rem flex">
                                                <div class="progress-bar-container mt-2 mr-2">
                                                    <div class="progress-bar"
                                                        [style.width]="item.averageVinterScore + '%'"
                                                        [style.backgroundColor]="'#3F26DC'"></div>
                                                </div>
                                                <div
                                                    *ngIf="item.positionStep.questionCollection.subCategory !== QuestionCollectionSubCategory.GeneralEnglish">
                                                    <p class="text-xs ">{{item.averageVinterScore?.toFixed(2)}}</p>
                                                </div>
                                                <div *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.GeneralEnglish"
                                                    class="flex">
                                                    <p class="text-xs font-medium mb-0">
                                                        {{item.generalEnglishTestResult?.generalLevel}}</p>
                                                    <p class="text-xs font-medium" style="color: #686E75;">
                                                        /{{item.averageVinterScore?.toFixed(2)}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="item.currentOrder!=null && item.currentApplicantCount!=null"
                                        class="flex justify-content-between flex-wrap my-2">
                                        <div class="flex align-items-center justify-content-center font-semibold text-xs ">
                                            {{'Key.PdfVinterScoreRank' | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <div class="custom-display w-12rem flex justify-content-end">
                                                <div class="mt-2 mr-2 text-xs" >
                                                    <div class="bg-primary border-round-lg pr-2 pl-2 inline-block text-white text-center" style="margin-right: 1px;padding-bottom: 2px;">{{item.currentOrder}}</div>...{{item.currentApplicantCount}}
                                                </div>
                                                <img src="assets/vinter/icons/persons.svg" style="width: 1rem; height: 1rem;"
                                                class="mt-2">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex justify-content-between flex-wrap my-2">
                                        <div
                                            class="flex align-items-center justify-content-center font-semibold text-xs ">
                                            {{'Key.PdfRate' | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <p class="text-xs ">{{item.averageRating?.toFixed(2)}}</p>
                                        </div>
                                    </div>

                                </div>

                                <!-- <div *ngIf="item.positionStep.questionCollection.expectedAnswerOpenEndedCount !== 0">

                                    <div #area
                                        *ngIf="getCurrentStepQuestions(EQuestionCategories.Writing, item.positionStep.id)?.questionCount > 0"
                                        class="my-3 avoid">
                                        <div class="flex justify-content-between flex-wrap">
                                            <div class="flex justify-content-center">
                                                <img class="mr-1"
                                                    src="../../../../../assets/vinter/icons/pdfReport/writingPdf.svg"
                                                    width="24px">

                                                <div
                                                    class="flex align-items-center justify-content-center font-semibold text-sm">
                                                    {{'Key.Writing' | translate}}
                                                </div>
                                            </div>

                                            <div class="flex align-items-center justify-content-center font-semibold">
                                                <div class="custom-display flex" style="width:14.3rem;">
                                                    <div class="progress-bar-container mt-2 mr-2">
                                                        <div class="progress-bar"
                                                            [style.width]="getCurrentStepQuestions(EQuestionCategories.Writing, item.positionStep.id)?.averageScores + '%'"
                                                            [style.backgroundColor]="'#2D4ADE'"></div>
                                                    </div>
                                                    <div class="flex">
                                                        <p *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.GeneralEnglish"
                                                            class="text-xs font-normal mb-0 mr-1"
                                                            style="color: #2D4ADE;">
                                                            {{item.generalEnglishTestResult?.writingLevel}}/ </p>
                                                        <p class="text-xs font-normal w-max" style="color: #686E75;">
                                                            {{(getCurrentStepQuestions(EQuestionCategories.Writing,
                                                            item.positionStep.id)?.averageScores)?.toFixed(0)}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div
                                    *ngIf="item.positionStep.questionCollection.expectedAnswerMultipleChoiceCount !== 0">

                                    <div #area
                                        *ngIf="getCurrentStepQuestions(EQuestionCategories.Listening, item.positionStep.id)?.questionCount > 0"
                                        class="my-3 avoid">
                                        <div class="flex justify-content-between flex-wrap">
                                            <div class="flex justify-content-center">
                                                <img class="mr-1"
                                                    src="../../../../../assets/vinter/icons/pdfReport/Listening.svg"
                                                    width="24px">

                                                <div
                                                    class="flex align-items-center justify-content-center font-semibold text-sm">
                                                    {{'Key.Listening' | translate}}
                                                </div>
                                            </div>

                                            <div class="flex align-items-center justify-content-center font-semibold">
                                                <div class="custom-display flex" style="width:14.3rem;">
                                                    <div class="progress-bar-container mt-2 mr-2">
                                                        <div class="progress-bar"
                                                            [style.width]="(getCurrentStepQuestions(EQuestionCategories.Listening, item.positionStep.id)?.averageScores*100 )+ '%'"
                                                            [style.backgroundColor]="'#CE4FBA'"></div>
                                                    </div>
                                                    <div class="flex">
                                                        <p *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.GeneralEnglish"
                                                            class="text-xs font-normal mb-0 mr-1"
                                                            style="color: #CE4FBA;">
                                                            {{item.generalEnglishTestResult?.listeningLevel}}/ </p>
                                                        <p class="text-xs font-normal w-max" style="color: #686E75;">
                                                            %{{(getCurrentStepQuestions(EQuestionCategories.Listening,
                                                            item.positionStep.id)?.averageScores*100)?.toFixed(0)}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div #area
                                        *ngIf="getCurrentStepQuestions(EQuestionCategories.Reading, item.positionStep.id)?.questionCount > 0"
                                        class="my-3 avoid">
                                        <div class="flex justify-content-between flex-wrap">
                                            <div class="flex justify-content-center">
                                                <img class="mr-1"
                                                    src="../../../../../assets/vinter/icons/pdfReport/readingPdf.svg"
                                                    width="24px">

                                                <div
                                                    class="flex align-items-center justify-content-center font-semibold text-sm">
                                                    {{'Key.Reading' | translate}}
                                                </div>
                                            </div>

                                            <div class="flex align-items-center justify-content-center font-semibold">
                                                <div class="custom-display flex" style="width:14.3rem;">
                                                    <div class="progress-bar-container mt-2 mr-2">
                                                        <div class="progress-bar"
                                                            [style.width]="(getCurrentStepQuestions(EQuestionCategories.Reading, item.positionStep.id)?.averageScores*100 )+ '%'"
                                                            [style.backgroundColor]="'#2DC9DE'"></div>
                                                    </div>
                                                    <div class="flex">
                                                        <p *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.GeneralEnglish"
                                                            class="text-xs font-normal mb-0 mr-1"
                                                            style="color: #2DC9DE;">
                                                            {{item.generalEnglishTestResult?.readingLevel}}/ </p>
                                                        <p class="text-xs font-normal w-max" style="color: #686E75;">
                                                            %{{(getCurrentStepQuestions(EQuestionCategories.Reading,
                                                            item.positionStep.id)?.averageScores*100)?.toFixed(0)}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div #area
                                        *ngIf="(item.positionStep.questionCollection.subCategory !== QuestionCollectionSubCategory.GeneralEnglish) && getCurrentStepQuestions(EQuestionCategories.Grammer, item.positionStep.id)?.questionCount > 0"
                                        class="my-3 avoid">
                                        <div class="flex justify-content-between flex-wrap">
                                            <div class="flex justify-content-center">
                                                <img class="mr-1"
                                                    src="../../../../../assets/vinter/icons/pdfReport/grammerPdf.svg"
                                                    width="24px">

                                                <div
                                                    class="flex align-items-center justify-content-center font-semibold text-sm">
                                                    {{'Key.Grammer' | translate}}
                                                </div>
                                            </div>

                                            <div class="flex align-items-center justify-content-center font-semibold">
                                                <div class="custom-display flex" style="width:14.3rem;">
                                                    <div class="progress-bar-container mt-2 mr-2">
                                                        <div class="progress-bar"
                                                            [style.width]="(getCurrentStepQuestions(EQuestionCategories.Grammer, item.positionStep.id)?.averageScores*100 )+ '%'"
                                                            [style.backgroundColor]="'#0E9776'"></div>
                                                    </div>
                                                    <div class="flex">
                                                        <p class="text-xs font-normal w-max" style="color: #686E75;">
                                                            %{{(getCurrentStepQuestions(EQuestionCategories.Grammer,
                                                            item.positionStep.id)?.averageScores*100)?.toFixed(0)}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div #area
                                        *ngIf="(item.positionStep.questionCollection.subCategory !== QuestionCollectionSubCategory.GeneralEnglish) && getCurrentStepQuestions(EQuestionCategories.Vocabulary, item.positionStep.id)?.questionCount > 0"
                                        class="my-3 avoid">
                                        <div class="flex justify-content-between flex-wrap">
                                            <div class="flex justify-content-center">
                                                <img class="mr-1"
                                                    src="../../../../../assets/vinter/icons/pdfReport/vocabularyPdf.svg"
                                                    width="24px">

                                                <div
                                                    class="flex align-items-center justify-content-center font-semibold text-sm">
                                                    {{'Key.Vocabulary' | translate}}
                                                </div>
                                            </div>

                                            <div class="flex align-items-center justify-content-center font-semibold">
                                                <div class="custom-display flex" style="width:14.3rem;">
                                                    <div class="progress-bar-container mt-2 mr-2">
                                                        <div class="progress-bar"
                                                            [style.width]="(getCurrentStepQuestions(EQuestionCategories.Vocabulary, item.positionStep.id)?.averageScores*100 )+ '%'"
                                                            [style.backgroundColor]="'#D71773'"></div>
                                                    </div>
                                                    <div class="flex">
                                                        <p class="text-xs font-normal w-max" style="color: #686E75;">
                                                            %{{(getCurrentStepQuestions(EQuestionCategories.Vocabulary,
                                                            item.positionStep.id)?.averageScores*100)?.toFixed(0)}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div #area
                                        *ngIf="(item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.GeneralEnglish)"
                                        class="my-3 avoid">
                                        <div class="flex justify-content-between flex-wrap">
                                            <div class="flex justify-content-center">
                                                <img class="mr-1"
                                                    src="../../../../../assets/vinter/icons/pdfReport/grammerPdf.svg"
                                                    width="24px">
                                                <img class="mr-1"
                                                    src="../../../../../assets/vinter/icons/pdfReport/vocabularyPdf.svg"
                                                    width="24px">
                                                <div class="mr-2 align-item-center align-self-center detailSubFont font-semibold"
                                                    style="color:#0E9776; font-size: 12px;">
                                                    {{getCurrentStepQuestions(EQuestionCategories.Vocabulary,
                                                    item.positionStep.id)?.questionCount +
                                                    getCurrentStepQuestions(EQuestionCategories.Grammer,
                                                    item.positionStep.id)?.questionCount}}
                                                </div>

                                                <div
                                                    class="flex align-items-center justify-content-center font-semibold text-sm">
                                                    {{'Key.Grammer' | translate}} / {{'Key.Vocabulary' | translate}}
                                                </div>
                                            </div>

                                            <div class="flex align-items-center justify-content-center font-semibold">
                                                <div class="custom-display flex" style="width:14.3rem;">
                                                    <div class="progress-bar-container mt-2 mr-2">
                                                        <div class="progress-bar"
                                                            [style.width]="(getCurrentStepQuestions(EQuestionCategories.Vocabulary, item.positionStep.id)?.totalScores + getCurrentStepQuestions(EQuestionCategories.Grammer, item.positionStep.id)?.totalScores)
                                                                                    /(getCurrentStepQuestions(EQuestionCategories.Vocabulary, item.positionStep.id)?.questionCount + getCurrentStepQuestions(EQuestionCategories.Grammer, item.positionStep.id)?.questionCount) + '%'"
                                                            [style.backgroundColor]="'#0E9776'"></div>
                                                    </div>
                                                    <div class="flex">
                                                        <p class="text-xs font-normal mb-0 mr-1"
                                                            style="color: #0E9776;">
                                                            {{item.generalEnglishTestResult?.grammarVocabularyLevel}}/
                                                        </p>
                                                        <p class="text-xs font-normal w-max" style="color: #686E75;">
                                                            %{{(getCurrentStepQuestions(EQuestionCategories.Vocabulary,
                                                            item.positionStep.id)?.totalScores +
                                                            getCurrentStepQuestions(EQuestionCategories.Grammer,
                                                            item.positionStep.id)?.totalScores)
                                                            /(getCurrentStepQuestions(EQuestionCategories.Vocabulary,
                                                            item.positionStep.id)?.questionCount +
                                                            getCurrentStepQuestions(EQuestionCategories.Grammer,
                                                            item.positionStep.id)?.questionCount).toFixed(0)}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div  #area class="avoid"
                            *ngIf="(item.completedDate ?? false) && (item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.Pronunciation)">
                            <div #area class="mr-7 avoid">
                                <div class="my-4">
                                    <div class="flex justify-content-between flex-wrap my-2">
                                        <div
                                            class="flex align-items-center justify-content-center font-semibold text-xs ">
                                            {{"Key.AveragePronunciationScore" | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <div class="custom-display w-12rem flex">
                                                <div class="progress-bar-container mt-2 mr-2">
                                                    <div class="progress-bar"
                                                        [style.width]="item.averageVinterScore + '%'"
                                                        [style.backgroundColor]="'#3F26DC'"></div>
                                                </div>
                                                <p class="text-xs blue-600">{{item.averageVinterScore?.toFixed(2)}}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="item.currentOrder!=null && item.currentApplicantCount!=null"
                                        class="flex justify-content-between flex-wrap my-2">
                                        <div class="flex align-items-center justify-content-center font-semibold text-xs ">
                                            {{'Key.PdfVinterScoreRank' | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <div class="custom-display w-12rem flex justify-content-end">
                                                <div class="mt-2 mr-2 text-xs" >
                                                    <div class="bg-primary border-round-lg pr-2 pl-2 inline-block text-white text-center" style="margin-right: 1px;padding-bottom: 2px;">{{item.currentOrder}}</div>...{{item.currentApplicantCount}}
                                                </div>
                                                <img src="assets/vinter/icons/persons.svg" style="width: 1rem; height: 1rem;"
                                                class="mt-2">
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div  class="my-4 ml-3">
                                    <div class="flex font-medium text-xs mb-3">
                                        {{"Key.ScoreBreakdown" | translate}}
                                    </div>

                                    <div class="flex justify-content-between flex-wrap my-1">
                                        <div class="flex align-items-center justify-content-center pdf-text-size "
                                            style="color: #686E75;">
                                            {{"Key.Completeness" | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <div class="custom-display w-12rem flex">
                                                <div class="progress-bar-container mt-2 mr-2">
                                                    <div class="progress-bar"
                                                        [style.width]="item.positionStep.questionCollection.averagePronunciationScores.averageCompletenessScore+ '%'"
                                                        [style.backgroundColor]="'#3F26DC'"></div>
                                                </div>
                                                <p class="text-xs blue-600">
                                                    {{item.positionStep.questionCollection.averagePronunciationScores.averageCompletenessScore?.toFixed(2)}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex justify-content-between flex-wrap my-1">
                                        <div class="flex align-items-center justify-content-center pdf-text-size "
                                            style="color: #686E75;">
                                            {{"Key.Accuracy" | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <div class="custom-display w-12rem flex">
                                                <div class="progress-bar-container mt-2 mr-2">
                                                    <div class="progress-bar"
                                                        [style.width]="item.positionStep.questionCollection.averagePronunciationScores.averageAccuracyScore+ '%'"
                                                        [style.backgroundColor]="'#3F26DC'"></div>
                                                </div>
                                                <p class="text-xs blue-600">
                                                    {{item.positionStep.questionCollection.averagePronunciationScores.averageAccuracyScore?.toFixed(2)}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex justify-content-between flex-wrap my-1">
                                        <div class="flex align-items-center justify-content-center pdf-text-size"
                                            style="color: #686E75;">
                                            {{"Key.FluencyScore" | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <div class="custom-display w-12rem flex">
                                                <div class="progress-bar-container mt-2 mr-2">
                                                    <div class="progress-bar"
                                                        [style.width]="item.positionStep.questionCollection.averagePronunciationScores.averageFluencyScore+ '%'"
                                                        [style.backgroundColor]="'#3F26DC'"></div>
                                                </div>
                                                <p class="text-xs blue-600">
                                                    {{item.positionStep.questionCollection.averagePronunciationScores.averageFluencyScore?.toFixed(2)}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex justify-content-between flex-wrap my-1">
                                        <div class="flex align-items-center justify-content-center pdf-text-size"
                                            style="color: #686E75;">
                                            {{"Key.FluencyTimeScore" | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <div class="custom-display w-12rem flex">
                                                <div class="progress-bar-container mt-2 mr-2">
                                                    <div class="progress-bar"
                                                        [style.width]="item.positionStep.questionCollection.averagePronunciationScores.averageFluencyTimeScore+ '%'"
                                                        [style.backgroundColor]="'#3F26DC'"></div>
                                                </div>
                                                <p class="text-xs blue-600">
                                                    {{item.positionStep.questionCollection.averagePronunciationScores.averageFluencyTimeScore?.toFixed(2)}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex justify-content-between flex-wrap my-1">
                                        <div class="flex align-items-center justify-content-center pdf-text-size"
                                            style="color: #686E75;">
                                            {{"Key.FluencyDisruptorScore" | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <div class="custom-display w-12rem flex">
                                                <div class="progress-bar-container mt-2 mr-2">
                                                    <div class="progress-bar"
                                                        [style.width]="item.positionStep.questionCollection.averagePronunciationScores.averageFluencyDisruptorScore+ '%'"
                                                        [style.backgroundColor]="'#3F26DC'"></div>
                                                </div>
                                                <p class="text-xs blue-600">
                                                    {{item.positionStep.questionCollection.averagePronunciationScores.averageFluencyDisruptorScore?.toFixed(2)}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr *ngIf="(item.completedDate ?? false)" class="custom-hr-summary my-4 mr-7">
                    </div>
                    <!-- <div #area class="my-4 mr-3 avoid" style="width: 33rem;">
                        <span class="font-semibold text-sm my-5" style="color:#002075;">{{'Key.CheatingAnalysis' |
                            translate}}</span>
                        <div class="flex justify-content-between flex-wrap my-2">
                            <div class="flex align-items-center justify-content-center pdf-text-size"
                                style="color: #686E75;">
                                {{'Key.SamePageUsed' | translate}}
                            </div>
                            <div *ngIf="!isSwitchedTab"
                                class="flex align-items-center justify-content-center font-semibold text-xs"
                                style="color:#37AC00">
                                {{'Key.Yes' | translate}}
                            </div>
                            <div *ngIf="isSwitchedTab"
                                class="flex align-items-center justify-content-center font-semibold text-xs"
                                style="color:#F20505">
                                {{'Key.No' | translate}}
                            </div>
                        </div>

                        <div class="flex justify-content-between flex-wrap my-2">
                            <div class="flex align-items-center justify-content-center pdf-text-size"
                                style="color: #686E75;">
                                {{'Key.FaceDetectedLog' | translate}}
                            </div>
                            <div *ngIf="isFaceDetectedLog"
                                class="flex align-items-center justify-content-center font-semibold text-xs"
                                style="color:#F20505">
                                {{'Key.Yes' | translate}}
                            </div>
                            <div *ngIf="!isFaceDetectedLog"
                                class="flex align-items-center justify-content-center font-semibold text-xs"
                                style="color:#37AC00">
                                {{'Key.No' | translate}}
                            </div>
                        </div>

                        <div class="flex justify-content-between flex-wrap my-2">
                            <div class="flex align-items-center justify-content-center pdf-text-size"
                                style="color: #686E75;">
                                {{'Key.MultiFaceDetectedLog' | translate}}
                            </div>
                            <div *ngIf="isMultipleFaceLog"
                                class="flex align-items-center justify-content-center font-semibold text-xs"
                                style="color:#F20505">
                                {{'Key.Yes' | translate}}
                            </div>
                            <div *ngIf="!isMultipleFaceLog"
                                class="flex align-items-center justify-content-center font-semibold text-xs"
                                style="color:#37AC00">
                                {{'Key.No' | translate}}
                            </div>
                        </div>

                        <div class="flex justify-content-between flex-wrap my-2">
                            <div class="flex align-items-center justify-content-center pdf-text-size"
                                style="color: #686E75;">
                                {{'Key.LanguageInappropriateness' | translate}}
                            </div>
                            <div *ngIf="isImproprietySpeechLog"
                                class="flex align-items-center justify-content-center font-semibold text-xs"
                                style="color:#F20505">
                                {{'Key.Yes' | translate}}
                            </div>
                            <div *ngIf="!isImproprietySpeechLog"
                                class="flex align-items-center justify-content-center font-semibold text-xs"
                                style="color:#37AC00">
                                {{'Key.No' | translate}}
                            </div>
                        </div>

                        <div class="flex justify-content-between flex-wrap my-2">
                            <div class="flex align-items-center justify-content-center pdf-text-size"
                                style="color: #686E75;">
                                {{'Key.LanguageInappropriateness' | translate}}
                            </div>
                            <div *ngIf="!isMultipleMonitor"
                                class="flex align-items-center justify-content-center font-semibold text-xs"
                                style="color:#37AC00">
                                {{'Key.No' | translate}}
                            </div>
                            <div *ngIf="isMultipleMonitor"
                                class="flex align-items-center justify-content-center font-semibold text-xs"
                                style="color:#F20505">
                                {{'Key.Yes' | translate}}
                            </div>
                        </div>
                    </div> -->

                </div>
            </div>
        </div>
    </div>
</div>


<p-dialog [(visible)]="showAiReportAddedNewStepInfo" [draggable]="false" [modal]="true" showEffect="fade"
    [style]="{width: '30vw'}" [breakpoints]="{'960px': '75vw'}" class="dialogPage" [closable]="false">
    <div class="grid">
        <div class="col-12 flex justify-content-center">
            <img class="w-4rem" src="../../../../../assets/vinter/icons/AIBasedPdfDialog.svg">
        </div>
        <div class="col-12 complete-dialog-header">
            {{'Key.AiReportAddedNewStepInfoHeader' | translate}}
        </div>

        <div class="col-12 complete-dialog-text pt-0">
            {{'Key.AiReportAddedNewStepInfoMessage' | translate}}
        </div>
        <div class="col-12 complete-dialog-header">
            <div class="col-6 flex justify-content-center">
                <p-button label="{{'Key.Ok' | translate}}" (onClick)="toggleShowAiReportAddedNewStepInfo()" styleClass="mr-2 mb-2">
                </p-button>
            </div>

        </div>
    </div>
</p-dialog>

<p-dialog [(visible)]="showAiReportPendingInfo" [draggable]="false" [modal]="true" showEffect="fade"
    [style]="{width: '30vw'}" [breakpoints]="{'960px': '75vw'}" class="dialogPage" [closable]="false">
    <div class="grid">
        <div class="col-12 flex justify-content-center">
            <img class="w-4rem" src="../../../../../assets/vinter/icons/AIBasedPdfDialog.svg">
        </div>
        <div class="col-12 complete-dialog-header">
            {{'Key.AiReportPendingInfoHeader' | translate}}
        </div>

        <div class="col-12 complete-dialog-text pt-0">
            {{'Key.AiReportPendingInfoMessage' | translate}}
        </div>
        <div class="col-12 complete-dialog-header">
            <div class="col-6 flex justify-content-center">
                <p-button label="{{'Key.Ok' | translate}}" (onClick)="toggleShowAiReportPendingInfo()" styleClass="mr-2 mb-2">
                </p-button>
            </div>

        </div>
    </div>
</p-dialog>
