<div *ngIf="userCVAndNameInfo?.userCvFileUrl != null || userCVAndNameInfo?.userCvFileUrl != undefined">
    <div class="col-12 xl:col-7 p-4 border-round-lg pdf-background-container mb-3">
        <div class="flex flex-row align-items-center justify-content-between">
            <div class="flex align-items-center justify-content-center">
                <img src="../../../../../assets/vinter/icons/copy-file-icon.svg" />
                <span class="pl-3 font-medium text-sm text-gray-900">{{formattedCvFileName}}</span>
            </div>
            <div
                class="flex align-items-center">
                <img (click)="pdf2Image2Pdf(userCVAndNameInfo?.userCvFileUrl)" src="../../../../../assets/vinter/icons/download-pdf-icon.svg" style="cursor: pointer;" />
            </div>
        </div>
    </div>

    <pdf-viewer *ngIf="userCVAndNameInfo?.userCvFileUrl != null" [src]="userCVAndNameInfo?.userCvFileUrl"
        [render-text]="true" class="pdf-viewer-container col-12 xl:col-7 h-screen block border-round-lg overflow-hidden	">
    </pdf-viewer>

</div>
<div *ngIf="userCVAndNameInfo?.userCvFileUrl == null && userCVAndNameInfo?.userCvFileUrl == undefined"
    class="no-position justify-content-center flex flex-column align-items-center">
    <img class="no-position-gift" [width]="219" height="219" src="assets/vinter/gifs/cannot-found-past-position.gif">
    <span class="justify-content-start font-semibold text-center flex text-gray-800	">{{"Key.NoUserCV" |
        translate}}</span>
</div>