import { Component, Input, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { EDeviceType } from '@app/shared/common/enums';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-behavioral-analysis-section',
  templateUrl: './behavioral-analysis-section.component.html',
  styleUrls: ['./behavioral-analysis-section.component.scss']
})
export class BehavioralAnalysisSectionComponent {
  @Input() item: any;
  @ViewChild('op') op!: OverlayPanel;

  DeviceTypeEnum = EDeviceType;
  backGroundVisible = false;

  constructor() { }

  ngOnInit(): void {
  }

  handleMouseOver(event: MouseEvent): void {
    this.setBackgroundDarkness(true);
    this.op.show(event);
  }
  
  handleMouseOut(): void {
    this.op.hide();
    this.setBackgroundDarkness(false);
  }
  
  setBackgroundDarkness(event:boolean)
  {
    this.backGroundVisible = event;
  }

}

@Pipe({
  name: 'deviceTypes',
})
export class DeviceTypesPipe implements PipeTransform {
  transform(row: any, list: any[]): any {
    return list.find(x => x.id === row).name;
  }
}
