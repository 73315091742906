import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RatingComponent } from '@app/shared/components/rating/rating.component';
import { ICONS_PATH } from '@app/shared/components/icon/icons-path';



@NgModule({
    declarations: [
        RatingComponent,

    ],
    imports: [
      CommonModule,
      TranslateModule,
      FormsModule,
      ReactiveFormsModule
    ],
    exports: [
        RatingComponent,
      ],
    providers: [
    {
        provide: ICONS_PATH,
        useValue: "assets"
    }
    ]
  })
export class SharedRatingVinterModule { }
