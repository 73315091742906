import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dot-chart',
  templateUrl: './dot-chart.component.html',
  styleUrls: ['./dot-chart.component.scss']
})
export class DotChartComponent implements OnInit  {

  @Input() totalQuestions: number;
  @Input() correctCount: number;
  @Input() incorrectCount: number;
  @Input() notAnswerCount: number;
  questions: string[] = []; 
  gridTemplateColumns: string = '';

  columnCount: number = 0;

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.initializeQuestions();
    this.calculateGrid();
}
 
  initializeQuestions(): void {
    this.questions = [
      ...Array(this.correctCount).fill('correct'),
      ...Array(this.incorrectCount).fill('incorrect'),
      ...Array(this.notAnswerCount).fill('not-answer')
    ];
  }

  calculateColumnCount(totalQuestions: number) {
    let columns: number;
   
    if (totalQuestions <= 5) {
      columns = 5;
    } else if (totalQuestions <= 24) {
      columns = 6; 
    } else if (totalQuestions > 24 && totalQuestions <= 60) {
      columns = 15; 
    } else if (totalQuestions > 60) {
      columns = 17; 
    } else {
      columns = Math.ceil(Math.sqrt(totalQuestions)); 
    }
    return columns ;
  }
 
  calculateGrid(): void {
    this.columnCount =this.calculateColumnCount(this.totalQuestions);
    this.gridTemplateColumns = `repeat(${this.columnCount}, 1fr)`;
 
    document.documentElement.style.setProperty(
      '--grid-template-columns',
      this.gridTemplateColumns
    );
  }

}
