import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService, HttpBaseService, LocalizationService, NotifyService } from '@app/core';
import { ApiControllerEnum, EntityStateEnum } from '@app/shared/common/enums';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApplicantBehaviourEventLogService extends HttpBaseService  {

  constructor(private http: HttpClient
    , private notifyServ: NotifyService
    , private localizationService: LocalizationService
    , private configService: ConfigurationService) {
      super(http, ApiControllerEnum.ApplicantBehaviourEventLog, notifyServ, localizationService, configService);
    }

  getListByFilter(filter: any): Observable<any[]> {
      return this.post("GetListByFilter", filter, true,false);
  }

}

